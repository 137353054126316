/* eslint-disable @typescript-eslint/naming-convention */
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { FacturasService } from 'src/app/services/facturas.service';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { UsuariosService } from 'src/app/services/usuarios.service';

@Component({
  selector: 'app-facturas-singenerar',
  templateUrl: './facturas-singenerar.component.html',
  styleUrls: ['./facturas-singenerar.component.scss']
})
export class FacturasSingenerarComponent implements OnInit {
  form = this.fb.group({
    date_from: [''],
    date_until: [''],
    embarque: ['']
  });

  length: number;
  pageSize = 10;
  pageIndex = 1;

  email = new FormControl('', [Validators.required, Validators.email]);
  displayedColumns: string[] = ['embarque', 'cuenta', 'guia', 'descripcion', 'estado', 'fecha', 'clase', 'opciones'];
  dataSource = [];
  selection = new SelectionModel<any>(true, []);

  constructor(
    public usuariosService: UsuariosService,
    private paqueteService: PaquetesService,
    private facturasService: FacturasService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getFacturas();
  }

  getFacturas() {
    this.paqueteService.facturasPorGenerar(this.form.value).subscribe((data: any) => {
      this.dataSource = data;
      this.length = data.length;
    });

  }

  crearFactura(paqueteId) {
    const data = {
      paquete: paqueteId,
      completada: false,
      logged_user: this.authService.getUser().id,
    };


    this.facturasService.crearFactura(data).subscribe((data: any) => {
      this.openDialog('Factura Generada');
      this.getFacturas();
    }, (error: any) => {
      //console.log(error);
      this.openDialog('Error al Generar Factura: ' + error.error);
    });

  }

  public cleanFilters() {
    this.form.reset();
  }

  paginate(event: any) {
    //console.log(event);
    this.pageIndex = event;
  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 5000);
    });


    dialogRef.afterClosed().subscribe(result => {

    });
  }
}


