import { Color } from 'ng2-charts';
import { DatePipe } from '@angular/common';
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { AuthService } from 'src/app/services/auth.service';
import { EmbarquesService } from 'src/app/services/embarques.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { UsuariosService } from 'src/app/services/usuarios.service';

@Component({
  selector: 'app-guia',
  templateUrl: './guia.component.html',
  styleUrls: ['./guia.component.scss']
})
export class GuiaComponent implements OnInit {

  embarque;

  currentFormValues = [];

  form = new FormGroup({
    HandlingInformation: new FormControl(),
    AuthorizedAgent: new FormControl(),
    Flight: new FormControl(),
    FlightDate: new FormControl(),
    Pieces: new FormControl(),
    PiecesT: new FormControl(),
    GrossWeight: new FormControl(),
    GrossWeightT: new FormControl(),
    Rate: new FormControl(),
    ChargeableWeight: new FormControl(),
    TotalCharge: new FormControl(),
    TotalChargeT: new FormControl(),
    Description: new FormControl(),
    Prepaid: new FormControl(),
    OtherCharges: new FormControl(),
    TotalPrepaid: new FormControl(),
    OrderNumber: new FormControl(),
    Fuel: new FormControl(),
    Screen: new FormControl(),
    FWB: new FormControl(),
    Liba: new FormControl(),
    CreatedBy: new FormControl(),
    Embarque: new FormControl(),
  });

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    private embarqueService: EmbarquesService,
    private navigation: NavigationService,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private usuarioService: UsuariosService
  ) { }

  ngOnInit(): void {

    this.embarque = this.embarqueService.embarqueData;

    if (this.embarque) {

      this.embarqueService.getGuiaAerea(this.embarque.id).subscribe(res => {
        this.form.patchValue(res);

      }, (error) => {
        this.usuarioService.get_user_by_id(this.authService.getUser().id).subscribe(usuario => {
          this.form.patchValue({
            HandlingInformation: '',
            AuthorizedAgent: this.usuarioService.get_fullname(usuario),
            Flight: this.embarque.travel_identifier,
            FlightDate: this.embarque.date,
            Pieces: this.embarque.bultos,
            PiecesT: this.embarque.bultos,
            GrossWeight: this.embarque.gross_weight,
            GrossWeightT: this.embarque.gross_weight,
            Rate: 0.95,
            ChargeableWeight: this.embarque.gross_weight,
            TotalCharge: this.embarque.gross_weight * 0.95,
            TotalChargeT: this.embarque.gross_weight * 0.95,
            Description: '',
            Prepaid: this.embarque.gross_weight * 0.95,
            OtherCharges: 0,
            TotalPrepaid: this.embarque.gross_weight * 0.95,
            OrderNumber: '',
            Fuel: 0,
            Screen: 0,
            FWB: 0,
            Liba: 0,
            CreatedBy: this.authService.getUser().id,
            Embarque: this.embarque.id
          });
        });
      });

      this.form.controls.Pieces.valueChanges.subscribe(res => {
        this.form.controls.PiecesT.setValue(res);
      });

      this.form.controls.GrossWeight.valueChanges.subscribe(res => {
        const GrossWeight = res;
        const Rate = this.form.value.Rate;
        const Fuel = this.form.value.Fuel;
        const Screen = this.form.value.Screen;
        const Liba = this.form.value.Liba;
        const FWB = this.form.value.FWB;
        const TotalCharge = parseFloat(GrossWeight) * parseFloat(Rate);
        const OtherCharges = parseFloat(Fuel) + parseFloat(Screen) + parseFloat(FWB) + parseFloat(Liba);
        const TotalPrepaid = TotalCharge + OtherCharges;

        this.form.patchValue({
          GrossWeightT: GrossWeight,
          ChargeableWeight: GrossWeight,
          TotalCharge: TotalCharge.toFixed(2),
          TotalChargeT: TotalCharge.toFixed(2),
          Prepaid: TotalCharge.toFixed(2),
          OtherCharges: OtherCharges.toFixed(2),
          TotalPrepaid: TotalPrepaid.toFixed(2),
        });

      });

      this.form.controls.Rate.valueChanges.subscribe(res => {
        const GrossWeight = this.form.value.GrossWeight;
        const Rate = res;
        const Fuel = this.form.value.Fuel;
        const Screen = this.form.value.Screen;
        const Liba = this.form.value.Liba;
        const FWB = this.form.value.FWB;
        const TotalCharge = parseFloat(GrossWeight) * parseFloat(Rate);
        const OtherCharges = parseFloat(Fuel) + parseFloat(Screen) + parseFloat(FWB) + parseFloat(Liba);
        const TotalPrepaid = TotalCharge + OtherCharges;

        this.form.patchValue({
          TotalCharge: TotalCharge.toFixed(2),
          TotalChargeT: TotalCharge.toFixed(2),
          Prepaid: TotalCharge.toFixed(2),
          OtherCharges: OtherCharges.toFixed(2),
          TotalPrepaid: TotalPrepaid.toFixed(2),
        });

      });

      this.form.controls.Fuel.valueChanges.subscribe(res => {
        const GrossWeight = this.form.value.GrossWeight;
        const Rate = this.form.value.Rate;
        const Fuel = res;
        const Screen = this.form.value.Screen;
        const Liba = this.form.value.Liba;
        const FWB = this.form.value.FWB;
        const TotalCharge = parseFloat(GrossWeight) * parseFloat(Rate);
        const OtherCharges = parseFloat(Fuel) + parseFloat(Screen) + parseFloat(FWB) + parseFloat(Liba);
        const TotalPrepaid = TotalCharge + OtherCharges;

        this.form.patchValue({
          TotalCharge: TotalCharge.toFixed(2),
          TotalChargeT: TotalCharge.toFixed(2),
          Prepaid: TotalCharge.toFixed(2),
          OtherCharges: OtherCharges.toFixed(2),
          TotalPrepaid: TotalPrepaid.toFixed(2),
        });

      });

      this.form.controls.Screen.valueChanges.subscribe(res => {
        const GrossWeight = this.form.value.GrossWeight;
        const Rate = this.form.value.Rate;
        const Fuel = this.form.value.Fuel;
        const Screen = res;
        const Liba = this.form.value.Liba;
        const FWB = this.form.value.FWB;
        const TotalCharge = parseFloat(GrossWeight) * parseFloat(Rate);
        const OtherCharges = parseFloat(Fuel) + parseFloat(Screen) + parseFloat(FWB) + parseFloat(Liba);
        const TotalPrepaid = TotalCharge + OtherCharges;

        this.form.patchValue({
          TotalCharge: TotalCharge.toFixed(2),
          TotalChargeT: TotalCharge.toFixed(2),
          Prepaid: TotalCharge.toFixed(2),
          OtherCharges: OtherCharges.toFixed(2),
          TotalPrepaid: TotalPrepaid.toFixed(2),
        });

      });

      this.form.controls.FWB.valueChanges.subscribe(res => {
        const GrossWeight = this.form.value.GrossWeight;
        const Rate = this.form.value.Rate;
        const Fuel = this.form.value.Fuel;
        const Screen = this.form.value.Screen;
        const Liba = this.form.value.Liba;
        const FWB = res;
        const TotalCharge = parseFloat(GrossWeight) * parseFloat(Rate);
        const OtherCharges = parseFloat(Fuel) + parseFloat(Screen) + parseFloat(FWB) + parseFloat(Liba);
        const TotalPrepaid = TotalCharge + OtherCharges;

        this.form.patchValue({
          TotalCharge: TotalCharge.toFixed(2),
          TotalChargeT: TotalCharge.toFixed(2),
          Prepaid: TotalCharge.toFixed(2),
          OtherCharges: OtherCharges.toFixed(2),
          TotalPrepaid: TotalPrepaid.toFixed(2),
        });

      });

      this.form.controls.Liba.valueChanges.subscribe(res => {
        const GrossWeight = this.form.value.GrossWeight;
        const Rate = this.form.value.Rate;
        const Fuel = this.form.value.Fuel;
        const Screen = this.form.value.Screen;
        const Liba = res;
        const FWB = this.form.value.FWB;
        const TotalCharge = parseFloat(GrossWeight) * parseFloat(Rate);
        const OtherCharges = parseFloat(Fuel) + parseFloat(Screen) + parseFloat(FWB) + parseFloat(Liba);
        const TotalPrepaid = TotalCharge + OtherCharges;

        this.form.patchValue({
          TotalCharge: TotalCharge.toFixed(2),
          TotalChargeT: TotalCharge.toFixed(2),
          Prepaid: TotalCharge.toFixed(2),
          OtherCharges: OtherCharges.toFixed(2),
          TotalPrepaid: TotalPrepaid.toFixed(2),
        });
      });

    } else {
      this.back();
    }
  }

  async flattenForm() {

    this.embarqueService.putGuiaAerea(this.embarque.id, this.form.value).subscribe(async (response: any) => {

      console.log(response);

      const pages = [
        'ORIGINAL 1 (ISSUING CARRIER)',
        'ORIGINAL 2 (CONSIGNEE)',
        'ORIGINAL 3 (SHIPPER)',
        'COPY 4 (DELIVERY RECEIPT)',
        'COPY 5 (EXTRA COPY)',
        'COPY 6 (EXTRA COPY)'
      ];

      const newpdfDoc = await PDFDocument.create();

      pages.forEach(async (page, index, array) => {

        const formUrl = '/assets/template/GuiaTemplate.pdf';
        const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer());

        const pdfDoc = await PDFDocument.load(formPdfBytes);

        const form = pdfDoc.getForm();

        const fields = form.getFields();

        const formValues = this.form.value;

        fields.forEach(async (e, i, a) => {
          console.log(e.getName());

          if (formValues[e.getName()] instanceof Date) {
            const value = this.datePipe.transform(formValues[e.getName()], 'mediumDate', '', 'en-US');
            form.getTextField(e.getName()).setText(value);

          } else {
            form.getTextField(e.getName()).setText(String(formValues[e.getName()]));
          }

          if (e.getName() === 'FlightDate') {
            let value = this.datePipe.transform(formValues[e.getName()], 'mediumDate', '', 'en-US');
            console.log(value);
            value = formValues.Flight + ' ' + value;
            form.getTextField(e.getName()).setText(value);
          }

          if (e.getName() === 'CreatedAt') {
            form.getTextField('CreatedAt').setText(this.datePipe.transform(response.CreatedAt, 'mediumDate', '', 'en-US'));
          }

          if (i === a.length - 1) {
            form.flatten();

            const [firstPage] = await newpdfDoc.copyPages(pdfDoc, [0]);
            const [secondPage] = await newpdfDoc.copyPages(pdfDoc, [1]);

            const newPage = newpdfDoc.addPage(firstPage);

            newPage.drawText(page, { x: (newPage.getWidth() / 2) - 96, y: 12, size: 12, color: rgb(1, 0, 0), });

            newpdfDoc.addPage(secondPage);

            if (index === array.length - 1) {
              const pdfBytes = await newpdfDoc.save();
              this.file(pdfBytes);
            }
          }
        });
      });
    });
  }

  file(data: any) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
      throw Error('check if your browser block windows');
    }
  }


  back() {
    this.navigation.back();
  }

}


