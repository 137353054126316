/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { GeneralDialogComponent } from '../../../../common/dialogs/general-dialog/general-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NewPackageComponent } from '../../../components/newpackage/newpackage.component';
import { ConfirmarComponent } from 'src/app/modules/common/dialogs/confirmar/confirmar.component';
import { PackageLabelComponent } from '../../../components/package-label/package-label.component';
import { GuiasComponent } from '../../../components/guias/guias.component';
import { StateTypeEnum } from 'src/app/core/data/stateTypes';

export interface Actions {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-capturar-paquetes',
  templateUrl: './capturar-paquetes.component.html',
  styleUrls: ['./capturar-paquetes.component.scss']
})
export class CapturarPaquetesComponent implements OnInit {
  @ViewChild('listapaquetes') selectionList: MatSelectionList;

  length: number;
  pageSize = 50;
  pageIndex = 1;
  loading = false;

  selectedAction = '1';
  qrNoExiste = false;
  noResults = false;
  packageDelete = false;

  estadosPaquetes: any;

  estado_nuevo;

  paquetes: any = [];

  tracking = '';
  descripcion = '';
  guia = '';

  estado_invalido = false;

  notificar = true;

  fontSize = 16;

  allowOpening = false;

  tipo = 0;

  prealertados = 0;
  sinprealerta = 0;

  paquetePendientes;

  showGuias = false;

  constructor(
    public authService: AuthService,
    private paquetesService: PaquetesService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private router: Router,
    private elRef: ElementRef,
    private packageLabel: PackageLabelComponent,
    private activatedRoute: ActivatedRoute,
  ) { }

  playAudioWrong() {
    const audio = new Audio();
    audio.src = '../../../assets/wrong.wav';
    audio.load();
    audio.play();
  }

  playAudioCorrect() {
    const audio = new Audio();
    audio.src = '../../../assets/correct.wav';
    audio.load();
    audio.play();
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.length = 0;
      this.pageIndex = 1;

      this.showGuias = false;
      if (params.tipo) {
        this.paquetes = [];

        this.tipo = params.tipo;
        //console.log('Tipo: ', this.tipo);

        if (this.tipo == 1 || this.tipo == 3) {
          this.allowOpening = false;
          this.getEstadosPaquetes();
        }

        if (this.tipo == 2) {
          this.allowOpening = true;
          this.getPaquetesPendientes();
        }

        //console.log('Open Modal', this.allowOpening);

        this.prealertados = 0;
        this.sinprealerta = 0;
      }
    });

    this.fontSize = this.authService.isMiami ? 20 : 16;
    this.elRef.nativeElement.style.setProperty('--font-size', this.fontSize + 'px');
  }

  getPaquetesPendientes() {
    this.paquetesService.getByStatusSimple(2, this.pageIndex).subscribe((data: any) => {
      const items = {
        length: data.count,
        pendientes: [],
        data: []
      };

      const guiasPendientes = [];
      const guiasPendientesData = [];
      data.results.forEach(element => {
        this.loading = false;
        guiasPendientes.push(element.numero_de_guia);
        guiasPendientesData.push(element);
      });

      items.pendientes = guiasPendientes;
      items.data = guiasPendientesData;

      this.paquetePendientes = items;
      //console.log(this.paquetePendientes);

      if (this.paquetePendientes.length === 0) {
        this.showGuias = false;
      }
      this.length = data.count;
    });

  }

  changeSize(fontSize) {
    this.fontSize = fontSize;
    this.elRef.nativeElement.style.setProperty('--font-size', this.fontSize + 'px');
  }

  getEstadosPaquetes() {
    const rol = this.authService.activeRol;

    if (rol !== 'ALMAC') {
      this.paquetesService.getEstadosPaquetes().subscribe((data: any) => {
        this.estadosPaquetes = data;
      });
    }

    if (rol === 'ALMAC') {
      this.paquetesService.getEstadosPaquetesMiami().subscribe((data: any) => {

        this.estadosPaquetes = data.filter((item) => item.id == 2);
        this.selectedAction = this.estadosPaquetes[0].id;
        this.estado_nuevo = this.estadosPaquetes[0].id;
      });

    }
  }

  // Busqueda Manual
  findByManualInput() {

    this.noResults = false;
    this.packageDelete = false;
    if (this.tracking.length == 0) {
      console.log('No tracking specified');
      return new Promise(resolve => {
        resolve('No tracking specified');
      });
    }

    const trackings = this.tracking.split(/[, ]+/); // split string on comma space or both
    const promises = trackings.map(tracking => new Promise(resolve => {

      this.spinner.show();

      tracking = tracking.toLocaleUpperCase();
      if (this.authService.activeRol === 'ALMAC') {

        this.tracking = '';
        this.guia = '';

        this.paquetesService.findTrackingOrGuia(tracking).subscribe((data: any) => {

          const filter = this.paquetes.filter((el) => el.tracking.toLocaleUpperCase() === tracking || el.numero_de_guia === tracking);
          if (data.length > 0 && filter.length === 0) {

            if (data.length > 1) { //Multiple results
              const dialogRef = this.dialog.open(GuiasComponent, {
                data: { guias: data },
                panelClass: 'general'
              });

              dialogRef.afterClosed().subscribe(index => {
                if (index >= 0) {
                  data[index].valid = true;
                  this.paquetes.push(data[index]);
                  this.prealertados = this.prealertados + 1;

                  if (this.allowOpening) {
                    this.openDialogNuevoPaquete(tracking, false, data[index].id, null, false, false);
                  } else {
                    this.cambiarEstadoPaquete(data[index]);
                  }
                  this.spinner.hide();
                  resolve('ok');
                }

                if (index == -1) {
                  const item = {
                    tracking,
                    valid: false
                  };

                  this.newPackages(tracking).then(value => {
                    const id = value;

                    if (this.allowOpening === false) {
                      this.paquetes.push(item);
                      this.sinprealerta = this.sinprealerta + 1;
                    }
                    this.spinner.hide();
                    resolve('ok');
                  });
                }


              });

            } else {
              if (data[0].estado_actual.id == StateTypeEnum.Eliminado) {
                this.packageDelete = true;
                this.spinner.hide();
              }
              else {
                data[0].valid = true;
                this.paquetes.push(data[0]);
                this.prealertados = this.prealertados + 1;

                if (this.allowOpening) {
                  this.openDialogNuevoPaquete(tracking, false, data[0].id, null, false, false);
                } else {
                  this.cambiarEstadoPaquete(data[0]);
                }

                resolve('ok');
              }

            }
          } else {

            if (tracking.length < 10) {
              this.noResults = true;
            } else {
              const item = {
                tracking,
                valid: false
              };

              this.newPackages(tracking).then(value => {
                const id = value;
                this.paquetes.push(item);
                if (this.allowOpening === false) {
                  this.sinprealerta = this.sinprealerta + 1;
                } else {
                  this.openDialogNuevoPaquete(tracking, false, id, null, false, false);
                }

                resolve('ok');
              });
            }
          }

        },
          err => {
            //console.log('Error Find QR ', err);
            this.qrNoExiste = true;
          });

      }

      if (this.authService.activeRol !== 'ALMAC') {
        const guia = tracking;

        this.tracking = '';
        this.guia = '';

        this.paquetesService.findGuia(guia).subscribe((data: any) => {
          const filter = this.paquetes.filter(el => el.numero_de_guia === guia);

          if (data.length > 0 && filter.length === 0) {
            data[0].valid = true;
            this.paquetes.push(data[0]);
            this.playAudioCorrect();
          } else {
            if (data.length === 0) {
              this.openDialog('Paquete ' + guia + ' no se encuentra registrado');
              this.playAudioWrong();
            }
          }
          this.cambiarEstadoPaquete(data[0]);
          this.spinner.hide();
          resolve('ok');
        },
          err => {
            //console.log('Error Find QR ', err);
            this.qrNoExiste = true;
            this.playAudioWrong();
          });
      }
    }));

    return Promise.all(promises).then(results => {
      this.spinner.hide();
      return results;
    });
  }

  validarEstadoPaquetes() {
    return new Promise(resolve => {

      let valid = true;
      this.paquetes.forEach(paquete => {
        if (paquete.valid) {
          const invalido = this.estado_nuevo < paquete.estado_actual.id ? true : false;

          const item = this.selectionList.options.find(el => el.value === paquete.tracking);

          const i = this.paquetes.map(function (x) {
            return x.tracking;
          }).indexOf(paquete.tracking);

          if (invalido === true) {
            item._setSelected(false);
            this.paquetes[i].invalidcode = true;
            valid = false;
          } else {
            this.paquetes[i].invalidcode = false;
            item._setSelected(true);
          }
        }
      });

      resolve(valid);
    });
  }

  cambiarEstadoPaquete(paquete) {
    //console.log('Paquete', paquete);

    if (!paquete) {
      return;
    }
    const invalido = this.estado_nuevo < paquete.estado_actual.id ? true : false;

    const data = [];
    if (invalido === false) {
      const item = {
        id: paquete.id,
        observacion: '',
        logged_user: this.authService.getUser().id,
      };

      data.push(item);

      this.paquetesService.capturar(this.estado_nuevo, data).subscribe(data => {
        //console.log(data);

      });
    }

  }

  async cambiarEstadoPaquetes() {

    const data = [];
    if (this.paquetes.length > 0) {
      this.validarEstadoPaquetes().then((value: boolean) => {

        this.estado_invalido = value;
        this.paquetes.forEach(paquete => {

          if (paquete.valid) {
            const item = {
              id: paquete.id,
              observacion: this.descripcion,
              logged_user: this.authService.getUser().id,
            };

            data.push(item);
          }
        });

        this.paquetesService.capturar(this.estado_nuevo, data).subscribe(data => {
          this.spinner.hide();
          this.paquetes = [];
          //console.log(data);

          if (value === true) {
            this.openDialog('Paquetes procesados con éxito');
            this.prealertados = 0;
            this.sinprealerta = 0;
          } else if (value === false) {
            this.openDialog('Atencion: Algunos paquetes no fueron actualizados debido a estados inválidos. Revise la lista de paquetes para mas detalles.', 5000);
            this.prealertados = 0;
            this.sinprealerta = 0;
          }
          this.descripcion = '';
        });
      });

    }
  }

  showGuiasPendientes() {
    if (this.paquetePendientes.length > 0) {
      this.showGuias = true;
    }
  }

  openDialog(message, duration = 3000) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, duration);
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  openDialogNuevoPaquete(tracking, isNew, id, paquete, consolidar, addls) {
    //console.log('open new package', tracking, isNew, id);
    const dialogRef = this.dialog.open(NewPackageComponent, {
      width: '90%',
      panelClass: 'newpackage',
      data: { tracking, isNew, id, consolidar, addls, paquete, pendientes: this.paquetePendientes },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPaquetesPendientes();
      }

      this.tracking = '';
      //console.log(`Dialog result: ${result}`);
    });
  }

  printLabel() {
    const paquetes = [];

    this.paquetes.forEach(paquete => {

      if (paquete.valid) {
        paquetes.push(paquete);
      }
    });

    this.packageLabel.generatePackageLabel(paquetes);
  }

  newPackages(tracking) {
    return new Promise(async (resolve) => {

      const newPackage = {
        clase: 'C',
        estado_actual: 2,
        plan: 'A',
        tracking,
        tipo_de_paquete: '',
        descripcion: '-',
      };

      this.paquetesService.post(newPackage).subscribe((result: any) => {
        //console.log('Nuevo Paquete', result);
        resolve(result.id);

      }, err => {
        //console.log('Error', err);
        if (this.allowOpening) {
          this.openDialogNuevoPaquete(this.tracking, false, err.error.id, null, false, false);
        }
      });
    });
  }

  paginate(event: any) {
    console.log('event', event);
    this.loading = true;
    this.pageIndex = event;
    this.getPaquetesPendientes();
  }

  deletePackage(pkg) {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      data: { message: `¿Confirma que desea eliminar el paquete ${pkg.numero_de_guia}?` },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paquetesService.deletePaquete(pkg.id).subscribe(result => {
          this.openDialog('Paquete Eliminado');
          this.getPaquetesPendientes();
        },
          err => {
            console.log('HTTP Error', err);
            this.openDialog('No se puede eliminar este paquete');
          });
      }
    });
  }

  getClientType(pkg) {
    return pkg?.usuario?.tipo_cliente?.[0]?.nombre || ' ';
  }

}
