<div class="pt-3 pb-3" style="display: flex; align-items: center; justify-content: space-between;">
  <div style="width: 100%;">
    <button mat-button [routerLink]="['/admin/almacen/entregas']"
      style="padding: 0; padding-right: 10px; color: #0094a3; float: left;">
      <mat-icon>keyboard_arrow_left</mat-icon> ENTREGAS
    </button>
  </div>
</div>

<div class="content" fxLayout="column">
  <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones">
    <button class="botones-opciones-movil" mat-button>
      <mat-icon>more_vert</mat-icon>
    </button>
    <div class="botones-opciones">
      <!-- <button mat-button>REFINAR BUSQUEDA</button> -->
      <button mat-button routerLink="agregar">AGREGAR RUTA</button>
    </div>

    <div class="content-encontrados">
      <span class="texto-encontrados">{{length}} Rutas Encontrada(s)</span>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="content-table mr-4 ml-4 mb-4">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Nombre </th>
        <td mat-cell class="pr-pl-20" mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="available">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Disponible </th>
        <td mat-cell class="pr-pl-20 check-center" mat-cell *matCellDef="let element">
          <mat-slide-toggle (toggleChange)="this.changeRouteAvailability(element)" [checked]="element.available"></mat-slide-toggle>
        </td>
      </ng-container>
      <ng-container matColumnDef="opciones">
        <th mat-header-cell class="pr-pl-20 table-title" *matHeaderCellDef mat-sort-header [disabled]="true"> Opciones</th>
        <td mat-cell class="pr-pl-20 text-center" mat-cell *matCellDef="let element">
          <a mat-button [routerLink]="['editar']" [queryParams]="{id: element.id}">
           <mat-icon>edit</mat-icon>
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; "  [ngClass]="{'highlight': odd }"></tr>
    </table>
  </div>
</div>
