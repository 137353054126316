import { Component, HostListener, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { NavigationService } from './services/navigation.service';

export let browserRefresh = false;
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnDestroy {

  subscription: Subscription;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private navigationService: NavigationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
        //console.log('refresh', browserRefresh);
        if (browserRefresh) {
          navigationService.setHistory();
        }
      }
    });
    this.matIconRegistry.addSvgIcon(
      `en_flag`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/en.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `es_flag`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/es.svg')
    );
  }

  @HostListener('window:beforeunload', ['$event'])
  async ngOnDestroy() {
    //console.log('Cerrando');
  }

  ngOnInit() {
    /** spinner starts on init */
    // this.spinner.show();

    // setTimeout(() => {
    //   /** spinner ends after 5 seconds */
    //   this.spinner.hide();
    // }, 5000);

  }



}
