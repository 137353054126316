import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { environment } from 'src/environments/environment';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-contrato',
  templateUrl: './contrato.component.html',
  styleUrls: ['./contrato.component.scss']
})
export class ContratoComponent implements OnInit {

  fileLoad1: any;
  mediaUrl = environment.mediaUrl;

  url;
  urlSafe: SafeResourceUrl;

  contratoForm;

  constructor(
    private usuariosService: UsuariosService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer
  ) {
    this.usuariosService.getContrato().subscribe((value: any) => {
      this.url = this.mediaUrl + '/media/' + value[0].url;
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      this.contratoForm = this.fb.group({
        url: ['']
      });
    });
  }

  ngOnInit(): void { }

  processFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileLoad1 = file;
      this.contratoForm.controls.url.setValue(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        const size = file.size / 1024;
        if (size <= 20480) {
          if (!file.name.includes('.pdf')) {
            this.openDialog('Formato de archivo inválido');
          }
        } else {
          this.openDialog('Archivo supera los 20 megabytes');
          this.fileLoad1 = null;
        }

        this.usuariosService.putContrato(this.contratoForm.value).subscribe((value: any) => {
          //console.log(value);
          this.url = this.mediaUrl + value.url;
          this.openDialog('Contrato Modificado');
          window.location.reload();
        });
      };

    }
  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
