/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralDialogComponent } from '../../../../common/dialogs/general-dialog/general-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Genero, ModoDeEntrega } from 'src/app/models/Usuario';
import { ActivatedRoute } from '@angular/router';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { RutasService } from 'src/app/services/rutas.service';
import { TarifasService } from 'src/app/services/tarifas.service';
import { MustMatch } from 'src/app/shared/validators/must-match.validator';
import { AgeValidator } from 'src/app/shared/validators/age.validator';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  tipo_cuenta: string;
  anotacionChange = false;

  roles: object[] = [
    { name: 'Administrador', value: 'ADMIN' },
    { name: 'Cliente', value: 'CLIENT' },
    { name: 'Transportista', value: 'TRANSP' },
    { name: 'Almacen Miami', value: 'ALMAC' },
    { name: 'Servicio al Cliente', value: 'SERVCLI' },
    { name: 'Pedimentación', value: 'PEDIM' },
    { name: 'Operaciones Miami', value: 'OPMIA' },
    { name: 'Operaciones Costa Rica', value: 'OPCR' },
    { name: 'Vendedor', value: 'VEND' }
  ];

  genders: object[] = [
    { name: 'Masculino', value: Genero.masculino },
    { name: 'Femenino', value: Genero.femenino },
    { name: 'Otro', value: Genero.otro }
  ];


  deliveryModes: any[] = [
    { name: 'En domicilio', value: ModoDeEntrega.enDomicilio },
    { name: 'En sucursal', value: ModoDeEntrega.enSucursal, },
  ];

  estadosCliente: any[] = [
    { name: 'Activo', value: true },
    { name: 'Inactivo', value: false }
  ];

  existing_email: boolean;
  isClient: boolean;

  provincias;
  cantones;
  distritos;

  formFactura = this.fb.group({
    nombre: [''],
    primer_apellido: [''],
    segundo_apellido: [''],
    numero_identificacion: [''],
    razon_social: [''],
    tipo_factura: [''],
    direccion: [''],
    canton: [''],
    distrito: [''],
    provincia: [''],
    telefono: ['', Validators.pattern('^[0-9]*$')],
    correo: [''],
  });

  formCreate = this.fb.group({
    // Necesarios para el modelo
    nombre: ['', [Validators.maxLength(30)]],
    primer_apellido: ['', [Validators.maxLength(30)]],
    password: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(6)]],
    confirm_password: ['', [Validators.required]], //No se envia al modelo
    numero_de_cedula: ['', [Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ]+$')]],
    telefono_movil: ['', [Validators.required, Validators.maxLength(30)]],
    fecha_de_nacimiento: [''],

    //Opcionales para el Modelo
    segundo_apellido: [null, [Validators.maxLength(30)]],
    rol: ['', [Validators.required]], //Default Cliente
    genero: ['M', []], //Default 'M'
    email: ['', [Validators.required, Validators.maxLength(50)]],
    email_2: ['', [Validators.maxLength(50)]], // No se envia al modelo
    referido_id: [null],
    cuenta: [null],

    // Solo Aplican para Clientes
    direccion: ['', [Validators.maxLength(500)]],
    telefono_oficina: [null, [Validators.maxLength(30)]],
    telefono_casa: [null, [Validators.maxLength(30)]],
    metodo_de_captacion: ['No_aplica'], //Default "No_Aplica"

    modo_de_entrega: [ModoDeEntrega.enSucursal], //Default 'ES' (En sucursal)
    direccion_entrega: ['', [Validators.maxLength(500)]],
    direccion_entrega_apps: ['', [Validators.maxLength(500)]],
    detalles_adicionales: ['', [Validators.maxLength(500)]],

    sucursal: [],
    metodo_pago: ['', [Validators.required]],

    provincia: ['', [Validators.required,]],
    canton: ['', [Validators.required]],
    distrito: ['', [Validators.required]],
    ruta: ['', [Validators.required]],

    autoriza_entrega: [''],
    // Solo Aplican para Cuentas Juridicas
    razon_social: ['', []],
    cedula_juridica: ['', []],
    representante_legal: ['', []],


  });

  tarifasAereas;
  tarifasMaritimas;
  rutas;

  step = 0;

  metodosPagos;
  metodosPagoPreviaEntrega;
  metodosPagoContraEntrega;

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private usuariosService: UsuariosService,
    private rutasService: RutasService,
    private tarifasService: TarifasService,
  ) {
    this.route.paramMap.subscribe(queryParams => {
      // do something with the query params
      this.tipo_cuenta = this.route.snapshot.paramMap.get('tipo');
      this.ngOnInit();
    });
  }



  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  getTarifas() {
    this.tarifasService.getByCodigo('A').subscribe(data => {
      this.tarifasAereas = data;
    });

    this.tarifasService.getByCodigo('M').subscribe(data => {
      this.tarifasMaritimas = data;
    });
  }
  getProvincias() {
    this.usuariosService.getProvincias().subscribe(data => {
      this.provincias = data;
    });
  }

  getCantones(idProvincia) {
    this.usuariosService.getCantones(idProvincia).subscribe(data => {
      this.cantones = data;
    });
  }

  getDistritos(idProvincia, idCanton) {
    // eslint-disable-next-line max-len
    this.usuariosService.getDistritos(idProvincia, idCanton).subscribe(data => {
      this.distritos = data;
    });
  }
  getMetodosPago() {
    this.usuariosService.getMetodosPago().subscribe(data => {
      this.metodosPagos = data;

      this.metodosPagoPreviaEntrega = this.metodosPagos.filter(function(item) {
        return item.previaentrega === true;
      });

      this.metodosPagoContraEntrega = this.metodosPagos.filter(function(item) {
        return item.contraentrega === true;
      });
    });
  }

  ngOnInit() {
    this.getTarifas();
    this.getProvincias();
    this.getMetodosPago();

    this.formCreate.controls['nombre'].clearValidators();
    this.formCreate.controls['primer_apellido'].clearValidators();
    this.formCreate.controls['numero_de_cedula'].clearValidators();
    this.formCreate.controls['razon_social'].clearValidators();
    this.formCreate.controls['cedula_juridica'].clearValidators();
    this.formCreate.controls['representante_legal'].clearValidators();

    switch (this.tipo_cuenta) {
      case 'fisica':
        this.formCreate.controls['nombre'].setValidators([Validators.required, Validators.maxLength(250)]);
        this.formCreate.controls['primer_apellido'].setValidators([Validators.required, Validators.maxLength(250)]);
        this.formCreate.controls['numero_de_cedula'].setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
        this.formCreate.controls['rol'].setValue('CLIENT');
        this.isClient = true;
        this.formFactura.controls.tipo_factura.setValue('FIS');
        break;
      case 'juridica':
        this.formCreate.controls['razon_social'].setValidators([Validators.required, Validators.maxLength(250)]);
        this.formCreate.controls['cedula_juridica'].setValidators([Validators.required, Validators.maxLength(250)]);
        this.formCreate.controls['representante_legal'].setValidators([Validators.required]);
        this.formCreate.controls['rol'].setValue('CLIENT');
        this.formFactura.controls.tipo_factura.setValue('JUR');
        this.isClient = true;
        break;
      default:
        this.isClient = false;
        this.formCreate.controls['rol'].setValue('ADMIN');
        break;
    }

    this.formCreate.updateValueAndValidity();

    this.existing_email = false;

    this.formCreate.controls['modo_de_entrega'].valueChanges.subscribe(value => {
      //console.log(value);
      if (value === 'ED') {
        this.formCreate.controls['direccion_entrega'].setValidators([Validators.required, Validators.maxLength(250)]);
      } else {
        this.formCreate.controls['direccion_entrega'].clearValidators();
        this.formCreate.controls['direccion_entrega'].setValidators([Validators.maxLength(250)]);
      }
    });

    this.rutasService.get().subscribe(value => {
      this.rutas = value;
      //console.log(this.rutas);
    });

  }


  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  createUser() {
    // console.log(this.formCreate);
    // console.log(this.formFactura);

    if (this.tipo_cuenta == 'juridica') {
      if (this.formCreate.value.nombre === '') {
        this.formCreate.removeControl('nombre');
      }
      if (this.formCreate.value.primer_apellido === '') {
        this.formCreate.removeControl('primer_apellido');
      }
      if (this.formCreate.value.numero_de_cedula === '') {
        this.formCreate.removeControl('numero_de_cedula');
      }
    }

    if (this.formCreate.valid &&
      this.formCreate.controls['confirm_password'].value === this.formCreate.controls['password'].value &&
      this.existing_email === false) {

      //console.log('valid');

      this.spinnerService.show();

      if (this.formCreate.value.nombre === '') {
        this.formCreate.removeControl('nombre');
      }
      if (this.formCreate.value.primer_apellido === '') {
        this.formCreate.removeControl('primer_apellido');
      }
      if (this.formCreate.value.numero_de_cedula === '') {
        this.formCreate.removeControl('numero_de_cedula');
      }
      if (this.formCreate.value.fecha_de_nacimiento === '') {
        this.formCreate.removeControl('fecha_de_nacimiento');
      }
      if (this.formCreate.value.segundo_apellido === '') {
        this.formCreate.removeControl('segundo_apellido');
      }
      if (this.formCreate.value.razon_social === '') {
        this.formCreate.removeControl('razon_social');
      }
      if (this.formCreate.value.cedula_juridica === '') {
        this.formCreate.removeControl('cedula_juridica');
      }
      if (this.formCreate.value.representante_legal === '') {
        this.formCreate.removeControl('representante_legal');
      }
      if (this.formCreate.value.telefono_oficina === '') {
        this.formCreate.controls['telefono_oficina'].setValue(null);
      }
      if (this.formCreate.value.telefono_casa === '') {
        this.formCreate.controls['telefono_casa'].setValue(null);
      }
      if (this.formCreate.value.direccion_entrega === '') {
        this.formCreate.controls['direccion_entrega'].setValue(null);
      }
      if (this.formCreate.value.direccion_entrega_apps === '') {
        this.formCreate.controls['direccion_entrega_apps'].setValue(null);
      }
      if (this.formCreate.value.direccion === '') {
        this.formCreate.controls['direccion'].setValue(null);
      }
      if (this.formCreate.value.referido_id) {
        this.formCreate.controls['referido_id'].setValue(null);
      }

      if (this.isClient) {
        this.formCreate.controls['metodo_de_captacion'].setValue('Manual');
      } else {
        this.formCreate.value.modo_entrega = null;
      }
      //console.log('Form Data: ', this.formCreate.value);

      if (this.formCreate.value.fecha_de_nacimiento) {
        this.formCreate.value.fecha_de_nacimiento = this.formCreate.value.fecha_de_nacimiento.toISOString().split('T')[0];
      }

      this.authService.createUser(this.formCreate.value).subscribe((data) => {
        //console.log('Endpoint Response: ', data);

        if (!data.ok && data.error.email) {
          this.existing_email = true;
          this.openDialog(`Correo en uso`);
        } else if (!data.ok && data.error.numero_de_cedula) {
          this.openDialog('Número de Cédula ya registrada');
        } else if (data.ok) {
          this.existing_email = false;
          this.authService.updateDatosFactura(this.formFactura.value, data.id).subscribe(() => {
            this.openDialog(`Usuario creado con éxito`);
            this.formCreate.reset();
            this.formFactura.reset();
            this.setStep(0);
          });
        }
        this.spinnerService.hide();
      });

    } else {
      this.openDialog('Revise los datos y verifique que las contraseñas ingresadas coincidan');
    }
  }

  public rolChanged(control) {
    if (control.value === 'CLIENT') {
      this.isClient = true;
    } else {
      this.isClient = false;
    }
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }

  public getErrorMessage(control) {
    if (control.hasError('required')) {
      return 'Campo necesario';
    }
    if (control.hasError('minlength')) {
      return 'Demasiado Corto';
    }
    if (control.hasError('maxlength')) {
      return 'Demasiado Largo';
    }
    if (control.hasError('pattern')) {
      return 'Formato inválido';
    }
    if (control.hasError('min')) {
      return 'Límite mínimo';
    }
    if (control.hasError('exists')) {
      return 'Correo en uso';
    }
    if (control.hasError('equal')) {
      return 'Correos deben ser distintos';
    }

    return '';
  }

  checkEmail(email, field) {
    if (this.formCreate.controls[field].value) {

      if (this.formCreate.controls['email'].value === this.formCreate.controls['email_2'].value) {
        //console.log('error', field)

        this.formCreate.controls[field].setErrors({ equal: true });

      } else {
        this.usuariosService.checkUserEmail(email).subscribe((data) => {
          this.existing_email = data['exists'];

          if (this.existing_email) {
            this.formCreate.controls[field].setErrors({ exists: this.existing_email });

          } else {
            this.formCreate.controls[field].setErrors(null);

          }
        });
      }
    }
  }


  getDistrito(value) {
    if (typeof value === 'object') {
      var value_distrito = value.id;
    } else {
      var value_distrito = value;
    }

    try {
      const item = this.distritos.filter(function(item) {
        return item.id === value_distrito;
      });
      if (item[0] !== undefined && item[0] != null) {
        return item[0]['distrito'];
      }
      return '';
    } catch (error) {
      return '';
    }

  }

  getDistritoTipo(value) {
    try {
      const item = this.distritos.filter(function(e) {
        return e.id === value;
      });

      if (item[0] !== undefined && item[0] !== null) {
        return item[0]['tipo'];
      }
      return '';
    } catch (error) {
      return '';
    }

  }

  asignarSucursal(event) {
    //console.log(event);

    if (event.value === 'Guadalupe') {
      const latitude = 9.951436;
      const longitude = -84.055484;
      const address = '350 este de los Tribunales de Justicia, Goicochea, Barrio Santa Cecilia, San José, Guadalupe, Costa Rica';

      this.formCreate.patchValue({
        provincia: 1,
        canton: 8,
        distrito: 54,
        direccion: '350 este de los Tribunales de Justicia, Goicochea, Barrio Santa Cecilia, San José, Guadalupe, Costa Rica',
        direccion_entrega: '350 este de los Tribunales de Justicia, Goicochea, Barrio Santa Cecilia, San José, Guadalupe, Costa Rica',
        detalles_adicionales: '350 este de los Tribunales de Justicia, Goicochea, Barrio Santa Cecilia, San José, Guadalupe, Costa Rica',
        direccion_entrega_apps: JSON.stringify({ lat: latitude, lng: longitude, address }),
        sucursal: 'Guadalupe',
        ruta: 72
      });
    }

    if (event.value === 'Limón') {
      const latitude = 9.992673;
      const longitude = -83.026042;
      const address = 'XXVF+2HW, C. 1, Limón, Costa Rica';

      this.formCreate.patchValue({
        provincia: 7,
        canton: 76,
        distrito: 448,
        direccion: 'XXVF+2HW, C. 1, Limón, Costa Rica',
        direccion_entrega: 'XXVF+2HW, C. 1, Limón, Costa Rica',
        detalles_adicionales: 'Frente al Parque Vargas continuo a la entrada de Camiones Trali. Planta baja, Edificio Cobre y Acero.',
        direccion_entrega_apps: JSON.stringify({ lat: latitude, lng: longitude, address }),
        sucursal: 'Limón',
        ruta: 48
      });
    }
  }


  changeMode(mode) {

    if (mode === 'ES') {
      this.formCreate.controls['modo_de_entrega'].setValue('ES');
      this.formCreate.controls['sucursal'].setValidators([Validators.required]);
      this.formCreate.updateValueAndValidity();
    }

    if (mode === 'ED') {
      this.formCreate.controls['modo_de_entrega'].setValue('ED');
      this.formCreate.controls['direccion_entrega'].setValidators([Validators.required]);
      this.formCreate.controls['detalles_adicionales'].setValidators([Validators.required]);
      this.formCreate.controls['metodo_pago'].setValidators([Validators.required]);
      this.formCreate.controls['canton'].setValidators([Validators.required]);
      this.formCreate.controls['distrito'].setValidators([Validators.required]);
      this.formCreate.controls['provincia'].setValidators([Validators.required]);
      this.formCreate.controls['sucursal'].setValue('Domicilio');
      this.formCreate.updateValueAndValidity();
    }

    if (mode === 'AM') {
      this.formCreate.controls['modo_de_entrega'].setValue('AM');
      this.formCreate.controls['direccion_entrega'].setValidators([Validators.required]);
      this.formCreate.controls['detalles_adicionales'].setValidators([Validators.required]);
      this.formCreate.controls['encomienda'].setValidators([Validators.required]);
      this.formCreate.controls['metodo_pago'].setValidators([Validators.required]);
      this.formCreate.controls['canton'].setValidators([Validators.required]);
      this.formCreate.controls['distrito'].setValidators([Validators.required]);
      this.formCreate.controls['provincia'].setValidators([Validators.required]);
      this.formCreate.controls['sucursal'].setValue('Domicilio');
      this.formCreate.updateValueAndValidity();
    }
  }

}
