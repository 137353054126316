<div class="p-3 content" fxLayout="column">
  <div class="content-title">
    <p class="title">Emails</p>
  </div>

  <div class="content-table mt-5 pt-4">
    <table mat-table [dataSource]="dataSource" matSort *ngIf="dataSource.data.length > 0">
      <ng-container matColumnDef="email">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element">
          {{element.title}}<span class="mr-4"></span>
        </td>
      </ng-container>

      <ng-container matColumnDef="modulo">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Módulo </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.module}}
        </td>
      </ng-container>

      <ng-container matColumnDef="activo">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Activo </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
        <mat-checkbox [checked]="element.active" disabled></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="opciones">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Opciones </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <button mat-icon-button class="mr-2" [routerLink]="['/admin/administracion/email/new']" [queryParams]="{id: element.id, tipo: tipo}">
            <mat-icon>border_color</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
      <tr mat-row *matRowDef="let rows; columns: displayedColumns;"></tr>
    </table>

    <div class="p-4" *ngIf="dataSource.data.length === 0">
      <span>No hay correos</span>
    </div>

  </div>

  <div class="mt-2 footer-email-button">
    <button mat-flat-button color="primary" class="button-calcular" routerLink="new" [queryParams]="{tipo: tipo}">Nuevo</button>
  </div>
</div>
