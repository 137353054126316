<div class="content-general">
  <div class="content-1 pl-4 pr-4 pb-4 pt-5">
    <div class="content-title">
      <p class="title">Paquetes</p>
    </div>

    <div fxLayout="column" *ng="data.option != 'adicionar'">
      <form [formGroup]="formBuscar">
        <div fxLayout="row" fxLayoutGap="20px" class="pr-4 pl-4 pt-3 mt-2 inputs-form"
          style="display: flex; align-items: center;">
          <div>
            <label class="label">Tracking</label><br>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Tracking" formControlName="tracking" class="input-field">
            </mat-form-field>
          </div>
          <div>
            <label class="label">Guía</label><br>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Guía" formControlName="guia" class="input-field">
            </mat-form-field>
          </div>
          <div>
            <label class="label">Nombre de Usuario</label><br>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Nombre de Usuario" formControlName="nombre" class="input-field">
            </mat-form-field>
          </div>
          <div>
            <label class="label">Tipo</label><br>
            <mat-form-field appearance="outline">
              <mat-select matNativeControl formControlName="tipo_paquete" placeholder="Seleccione">
                <mat-option value="Caja" selected>Caja</mat-option>
                <mat-option value="Correspondencia">Correspondencia</mat-option>
                <mat-option value="SobreBolsa">Sobre/Bolsa</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="align-self-end pb-2">
            <button mat-button (click)="filterTable()">
              <img src="assets/iconos-dashboard/Lupa.png">Buscar
            </button>
            <button mat-button (click)="cleanFilters()">
              <img src="assets/iconos-dashboard/Facturas.png">Limpiar
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="content-table mt-4">

      <div class="text-center" *ngIf="dataSource.data.length == 0">
        <p>No hay paquetes disponibles para {{data.option}}</p>
      </div>
      <table mat-table [dataSource]="dataSource" matSort *ngIf="dataSource.data.length > 0" style="width: 100%;">

        <ng-container matColumnDef="seleccionar">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header [disabled]="true">
            <mat-checkbox class="d-flex" (click)="masterToggle()" aria-label="Seleccionar/Deseleccionar Todo"
              title="Seleccionar/Deseleccionar Todo"></mat-checkbox>
          </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let row">
            <mat-checkbox class="d-flex justify-content-center" (click)="$event.stopPropagation()"
              (change)="$event ? selectedPackages.toggle(row) : null" [checked]="selectedPackages.isSelected(row)"
              [aria-label]="checkboxLabel(row)"></mat-checkbox>
          </td>
        </ng-container>

        <!-- guia Column -->
        <ng-container matColumnDef="guia">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Guía </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
            <a [routerLink]="['/admin/paquetes']" [queryParams]="{pack: element.id}">
              {{element.guia? element.guia: '-'}}
            </a>
          </td>
        </ng-container>

        <!-- estado Column -->
        <ng-container matColumnDef="estado">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Estado </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.estado? element.estado: '-'}}
          </td>
        </ng-container>

        <!-- envio Column -->
        <ng-container matColumnDef="nombre">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Nombre de Usuario </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.nombre? element.nombre : '-'}}
          </td>
        </ng-container>

        <!-- cuenta Column -->
        <ng-container matColumnDef="cuenta">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cuenta </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">{{element.cuenta? element.cuenta : '-'}}
          </td>
        </ng-container>

        <!-- tracking Column -->
        <ng-container matColumnDef="tracking">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Tracking </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.tracking? element.tracking :
            '-'}} </td>
        </ng-container>

        <!-- descripcion Column -->
        <ng-container matColumnDef="descripcion">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
            {{element.descripcion? element.descripcion : '-'}}
          </td>
        </ng-container>

        <!-- status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.status? element.status : '-'}}
          </td>
        </ng-container>

        <!-- peso real Column -->
        <ng-container matColumnDef="peso_real">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Peso Real</th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.peso_total? element.peso_total
            : '-'}}
          </td>
        </ng-container>

        <!-- peso volumetrico Column -->
        <ng-container matColumnDef="peso_volumetrico">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Peso Volumen </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.peso_volumetrico?
            element.peso_volumetrico
            : '-'}}
          </td>
        </ng-container>

         <!-- ruta Column -->
         <ng-container matColumnDef="ruta">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Ruta </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.ruta ?
            element.ruta.nombre
            : '-'}}
          </td>
        </ng-container>

        <!-- envio Column -->
        <ng-container matColumnDef="envio">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Método de Envío </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.envio? element.envio : '-'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="creacion">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha de Creacion</th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
            {{element.creacion.toLocaleDateString('es-CR', { weekday: 'short', year: 'numeric', month: 'short', day:
            'numeric' })}}
          </td>
        </ng-container>

        <ng-container matColumnDef="modificado">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Última actualización</th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
            {{element.ultima_actualizacion.toLocaleDateString('es-CR', { weekday: 'short', year: 'numeric', month:
            'short', day: 'numeric' })}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

    <div class="content-table mx-4 mt-4">
      <div class="col-6 text-left" style="float: left;">
        <button mat-flat-button color="primary" class="button-calcular" (click)="addPackages()">
          Agregar paquete(s) a {{data.option}}
        </button>
      </div>

      <div class="text-right" style="float: right;">
        <p>Total paquetes seleccionados: {{ selectedPackages?.selected.length ?
          selectedPackages?.selected.length : 0 }}</p>
        <p>Total paquetes: {{ paquetes?.length ? paquetes?.length : 0 }}</p>
      </div>
    </div>

    <div class="col-12">
      <div class="col-lg-12 text-center">
        <div class="paginator">
          <button mat-button (click)="page.firstPage()" class="lados">
            << </button>
              <button mat-button (click)="page.previousPage()" class="lados">
                < </button>
                  <button mat-button *ngFor="let pag of pages" (click)="updateManualPage(pag)"
                    [ngClass]="{'actual': page.pageIndex === pag}">{{pag + 1}}</button>
                  <button mat-button (click)="page.nextPage()" class="lados">></button>
                  <button mat-button (click)="page.lastPage()" class="lados">>></button>


        </div>
      </div>
    </div>
  </div>
  <mat-paginator style="display: none;" [pageIndex]="pageIndex" #page [length]="length"
  [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="paginator = $event"
  showFirstLastButtons="true"></mat-paginator>
