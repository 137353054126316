<div *ngIf="isCameraExist; else noCameraExist">
  <div style="text-align:center">
      <div class="btn-group">
          <button class="button" (click)="takeSnapshot()">Tomar Foto</button>
          <button class="button" (click)="changeWebCame(true)">Cambiar Cámara</button>
      </div> <br />
      <webcam [height]="240" [width]="480" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
          *ngIf="showWebcam" [switchCamera]="nextWebcamObservable" [imageType]="'image/png'"></webcam>
  </div>

  <div *ngIf="errors.length > 0">
      <h4>Mensajes de error:</h4>
      <ul *ngFor="let error of errors">
          <li>{{ error | json }}</li>
      </ul>
  </div>
</div>

<ng-template #noCameraExist>
  Cámara no disponible
</ng-template>
