/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PaquetesService } from 'src/app/services/paquetes.service';

import { UsuariosService } from 'src/app/services/usuarios.service';

export interface Paquetes {
  id: number;
  guia: number;
  nombre: string;
  estado: string;
  cuenta: string;
  tracking: string;
  descripcion: string;
  peso_total: number;
  peso_volumetrico: number;
  envio: string;
  creacion: Date;
  ultima_actualizacion: Date;
  tipo_paquete: string;
  ruta: string;
  modo_entrega: string;
  sucursal: string;
  entrega_detalle: any;
  idestado: number;
  usuarioid: number;
  entrega_observacion: string;
}

export interface DialogData {
  option: string;
  plan: string;
  paquetes: Paquetes[];
  fecha_entrega: string;
  user: number;
  paqueteid: number;
}

@Component({
  selector: 'app-select-package',
  templateUrl: './select-packages.component.html',
  styleUrls: ['./select-packages.component.scss']
})
export class SelectPackagesComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  paquetes = [];
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pages: any[] = [];
  pageIndex = 0;

  // eslint-disable-next-line max-len
  displayedColumns: string[] = ['seleccionar', 'tracking', 'guia', 'descripcion', 'nombre', 'peso_real', 'peso_volumetrico', 'modificado'];
  dataSource = new MatTableDataSource<Paquetes>();
  selectedPackages = new SelectionModel<Paquetes>(true, []);

  formBuscar = this.fb.group({
    guia: [''],
    tracking: [''],
    cuenta: [''],
    ultima_actualizacion: [''],
    creacion: [''],
    nombre: [''],
    tipo_paquete: ['']
  });

  constructor(
    private fb: FormBuilder,
    public paquetesService: PaquetesService,
    private usuariosService: UsuariosService,
    public dialogRef: MatDialogRef<SelectPackagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {

    this.dataSource.paginator = this.paginator;

    if (this.data.option === 'embarque') {
      this.getPackagesEmbarque();
    }

    if (this.data.option === 'entrega') {
      // eslint-disable-next-line max-len
      this.displayedColumns = ['seleccionar', 'tracking', 'guia', 'descripcion', 'nombre', 'peso_real', 'peso_volumetrico', 'ruta', 'modificado'];
      this.getPackagesEntrega();

    }

    if (this.data.option === 'adicionar') {
      //console.log('bulto');
      this.displayedColumns = ['seleccionar', 'tracking', 'guia', 'descripcion', 'estado', 'peso_real', 'peso_volumetrico'];
      this.getPackagesAdicionales();
    }

  }

  public isAllSelected = (): boolean => {
    const numSelected = this.selectedPackages.selected.length;
    const numRows = this.paquetes.length;
    return numSelected === numRows;
  };

  public masterToggle = (): void => {
    this.isAllSelected() ?
      this.selectedPackages.clear() :
      this.paquetes.forEach(row => this.selectedPackages.select(row));
  };

  public checkboxLabel = (row?: Paquetes): string => {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectedPackages.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  };

  getPackagesEmbarque() {
    this.paquetes = [];
    this.pages = [];
    this.paquetesService.getForShip().subscribe(
      (res: any) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
        const paquetes_lista: Paquetes[] = [];
        res.forEach(element => {
          paquetes_lista.push({
            id: element.id,
            guia: element.numero_de_guia,
            nombre: this.usuariosService.get_fullname(element.usuario),
            estado: element.estado_actual.estado,
            cuenta: this.usuariosService.get_account_number(element.usuario),
            tracking: element.tracking,
            descripcion: element.descripcion,
            envio: element.plan,
            peso_total: element.peso_total,
            peso_volumetrico: element.peso_volumetrico,
            creacion: new Date(element.created_at),
            ultima_actualizacion: new Date(element.updated_at),
            tipo_paquete: element.tipo_de_paquete,
            ruta: element.usuario.ruta ? element.usuario.ruta.nombre : '',
            modo_entrega: element.modo_entrega,
            sucursal: element.sucursal,
            entrega_detalle: element.entrega_detalle,
            idestado: element.estado_actual.id,
            usuarioid: element.usuario.id,
            entrega_observacion: element.entrega_observacion
          });


        });

        this.paquetes = paquetes_lista;
        this.dataSource.data = this.paquetes;
        this.length = paquetes_lista.length;

        for (let i = 0; i < (this.length / this.pageSize); i++) {
          this.pages.push(i);
        }

      }
    );
  }

  getPackagesEntrega() {
    this.paquetes = [];
    this.pages = [];
    this.paquetesService.getPendingsDeliver().subscribe(
      (res: any) => {
        //console.log('res',res)
        // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
        const paquetes_lista: Paquetes[] = [];
        res.forEach(element => {
          paquetes_lista.push({
            id: element.id,
            guia: element.numero_de_guia,
            nombre: this.usuariosService.get_fullname(element.usuario),
            estado: element.estado_actual.estado,
            cuenta: this.usuariosService.get_account_number(element.usuario),
            tracking: element.tracking,
            descripcion: element.descripcion,
            envio: element.plan,
            peso_total: element.peso_total,
            peso_volumetrico: element.peso_volumetrico,
            creacion: new Date(element.created_at),
            ultima_actualizacion: new Date(element.updated_at),
            tipo_paquete: element.tipo_de_paquete,
            ruta: element.ruta,
            modo_entrega: element.modo_entrega,
            sucursal: element.sucursal,
            entrega_detalle: element.entrega_detalle,
            idestado: element.estado_actual.id,
            usuarioid: element.usuario.id,
            entrega_observacion: element.entrega_observacion
          });
        });


        this.paquetes = paquetes_lista.filter(function(el) {
          return (el.idestado === 12 && (el.modo_entrega == 'ED' || el.modo_entrega == 'AM') && el.entrega_detalle.length == 0);
        });

        this.dataSource.data = this.paquetes;
        this.length = paquetes_lista.length;

        for (let i = 0; i < (this.length / this.pageSize); i++) {
          this.pages.push(i);
        }

      }
    );
  }

  getPackagesAdicionales() {
    this.paquetes = [];
    this.pages = [];
    this.paquetesService.getAdicionales(this.data.user).subscribe(
      (res: any) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
        const paquetes_lista = [];
        res.forEach(element => {

          if (element.id !== this.data.paqueteid) {
            paquetes_lista.push({
              id: element.id,
              descripcion: element.descripcion,
              estado: element.estado_actual.estado,
              tracking: element.tracking,
              peso: element.peso,
              alto: element.alto,
              ancho: element.ancho,
              largo: element.largo,
              peso_total: element.peso_total,
              guia: element.numero_de_guia,
              peso_volumetrico: element.peso_volumetrico,
              modo_entrega: element.modo_entrega,
              sucursal: element.sucursal,
              entrega_detalle: element.entrega_detalle,
              idestado: element.estado_actual.id,
              usuarioid: element.usuario.id
            });
          }
        });

        this.paquetes = paquetes_lista;
        this.dataSource.data = this.paquetes;
        this.length = paquetes_lista.length;

        for (let i = 0; i < (this.length / this.pageSize); i++) {
          this.pages.push(i);
        }

      }
    );
  }

  addPackages() {
    this.data.paquetes = this.selectedPackages.selected;
    // this.selectedPackages.selected.forEach(paquete => this.data.paquetes.push(paquete));
    this.dialogRef.close(this.data);
  }

  public filterTable() {
    this.formBuscar.value.cuenta == null ? this.formBuscar.value.cuenta = '' : '';
    this.formBuscar.value.guia == null ? this.formBuscar.value.guia = '' : '';
    this.formBuscar.value.tracking == null ? this.formBuscar.value.tracking = '' : '';
    this.formBuscar.value.creacion == null ? this.formBuscar.value.creacion = '' : '';
    this.formBuscar.value.ultima_actualizacion == null ? this.formBuscar.value.ultima_actualizacion = '' : '';
    this.formBuscar.value.nombre == null ? this.formBuscar.value.nombre = '' : '';
    this.formBuscar.value.estado == null ? this.formBuscar.value.estado = '' : '';
    this.formBuscar.value.tipo_paquete == null ? this.formBuscar.value.tipo_paquete = '' : '';

    const day = 60 * 60 * 24 * 1000;
    let sameCreationDay = true;
    const sameUpdateDay = true;
    const filter: Paquetes[] = this.paquetes.filter(paquete => {
      if (this.formBuscar.value.creacion != '') {
        const creacion = paquete.creacion;
        const creacion_filter = new Date(Date.parse(this.formBuscar.value.creacion) + day);
        sameCreationDay = this.isSameDay(creacion, creacion_filter);
      }
      return (
        String(paquete.guia).trim().toLowerCase().includes(String(this.formBuscar.value.guia).trim().toLowerCase()) &&
        String(paquete.tracking).trim().toLowerCase().includes(String(this.formBuscar.value.tracking).trim().toLowerCase()) &&
        String(paquete.nombre).trim().toLowerCase().includes(String(this.formBuscar.value.nombre).trim().toLowerCase()) &&
        String(paquete.estado).trim().toLowerCase().includes(String(this.formBuscar.value.estado).trim().toLowerCase()) &&
        String(paquete.cuenta).trim().toLowerCase().includes(String(this.formBuscar.value.cuenta).trim().toLowerCase()) &&
        String(paquete.tipo_paquete).trim().toLowerCase().includes(String(this.formBuscar.value.tipo_paquete).trim().toLowerCase()) &&
        sameCreationDay /* &&
      sameUpdateDay  */
      );
    });

    this.dataSource.data = filter;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }

  public cleanFilters() {
    this.formBuscar.reset();
    this.dataSource.data = this.paquetes;
  }

  public updateManualPage(index: number): void {
    this.dataSource.paginator.pageIndex = index + 1;
    this.dataSource.paginator.previousPage();
  }

  private isSameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }


}
