/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { GeneralDialogComponent } from '../../../../../common/dialogs/general-dialog/general-dialog.component';
import { DescuentosService } from '../../../../../../services/descuentos.service';

@Component({
  selector: 'app-descuento',
  templateUrl: './descuento.component.html',
  styleUrls: ['./descuento.component.scss'],
})
export class DescuentoComponent implements OnInit {
  discountTypes = [
    { value: 'N', name: 'Normal' },
    { value: 'P', name: 'Porcentual' },
  ];

  validPlans = '';

  existing_promo_code: boolean;

  descuentoId = 0;

  form = this.fb.group({
    discount_type: ['N', [Validators.required]],
    value: ['', [Validators.required, Validators.min(0)]],
    started_at: ['', [Validators.required]],
    finishing_at: ['', [Validators.required]],
    promo_code: ['', [Validators.required]],
    limit: ['', [Validators.required]],
    aereo: [false],
    maritimo: [false],
    exonerar_flete: [false],
    exonerar_warehouse: [false],
    exonerar_manejos: [false],
    exonerar_seguro: [false],
    exonerar_combustible: [false],
  });

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    public descuentosService: DescuentosService,
    private activatedRoute: ActivatedRoute,
  ) { }

  keyPress(event) {

    /* if(event.keyCode == 101 || event.keyCode == 46){
       return false
     }
     return true*/
    const value = event.target.value;

    if (!event.key.match(/[a-zA-Z0-9]/)) {
      event.preventDefault();
    }
  }

  changeDateStart(event) {
    //console.log(event);
  }

  changeDateFinal(event) {

    const dateToday = new Date();

    const final = new Date(event).getTime();
    const start = new Date(this.form.get('started_at').value).getTime();

    //console.log(start, final);

    if (final <= start) {
      this.openDialog(`La fecha final no puede ser menor a la de inicio`);
      this.form.patchValue({ finishing_at: '' });
      return;
    }

    if (final < dateToday.getTime()) {
      this.openDialog(`La fecha final no puede ser menor a la actual`);
      this.form.patchValue({ finishing_at: '' });
    }

  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.id) {
        this.descuentoId = params.id;
        this.descuentosService.getByID(params.id).subscribe((data: any) => {

          this.form = this.fb.group({
            discount_type: [data.discount_type, [Validators.required]],
            value: [data.value, [Validators.required, Validators.min(0)]],
            started_at: [data.started_at, [Validators.required]],
            finishing_at: [data.finishing_at, [Validators.required]],
            promo_code: [data.promo_code, [Validators.required]],
            limit: [data.limit, [Validators.required]],
            aereo: [data.aereo],
            maritimo: [data.maritimo],
            exonerar_flete: [data.exonerar_flete],
            exonerar_warehouse: [data.exonerar_warehouse],
            exonerar_manejos: [data.exonerar_manejos],
            exonerar_seguro: [data.exonerar_seguro],
            exonerar_combustible: [data.exonerar_combustible],
          });
        });
      }
    });

  }

  public create() {
    if (this.form.valid) {
      this.spinnerService.show();
      if (this.form.value.warning == '') {
        this.form.controls.warning.setValue(null);
      }

      this.form.value.promo_code = this.form.value.promo_code.toUpperCase();
      this.descuentosService.post(this.form.value).subscribe(
        (data: any) => {
          this.router.navigate(['/admin/administracion/descuentos/']);
          this.openDialog('Descuento Creado Exitosamente');
          this.spinnerService.hide();
        },
        (err) => {
          if (err.error.promo_code) {
            this.existing_promo_code = true;
            this.openDialog(`El código promocional ya existe`);
          }
          this.spinnerService.hide();
          console.error(err);
        }
      );
    }
  }

  public update() {
    if (this.form.valid) {
      this.spinnerService.show();
      if (this.form.value.warning == '') {
        this.form.controls.warning.setValue(null);
      }
      this.form.value.promo_code = this.form.value.promo_code.toUpperCase();
      this.descuentosService.update(this.descuentoId, this.form.value).subscribe(
        (data: any) => {
          this.router.navigate(['/admin/administracion/descuentos/']);
          this.openDialog('Descuento Actualizado Exitosamente');
          this.spinnerService.hide();
        },
        (err) => {
          if (err.error.promo_code) {
            this.existing_promo_code = true;
            this.openDialog(`El código promocional ya existe`);
          }
          this.spinnerService.hide();
          console.error(err);
        }
      );
    }
  }

  public getErrorMessage(control) {
    if (control.hasError('required')) {
      return 'Campo necesario';
    }
    if (control.hasError('minlength')) {
      return 'Demasiado Corto';
    }
    if (control.hasError('maxlength')) {
      return 'Demasiado Largo';
    }
    if (control.hasError('pattern')) {
      return 'Formato ínvalido';
    }
    if (control.hasError('min')) {
      return 'Límite mínimo excedido';
    }
    if (control.hasError('max')) {
      return 'Límite máximo excedido';
    }
    return 'Error';
  }

  private openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe((_) => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });
  }
}
