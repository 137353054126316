/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */

import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from 'src/app/services/translate.service';

export interface DialogData {
  usuario: number;
}

@Component({
  selector: 'app-autorizacion',
  templateUrl: './autorizacion.component.html',
  styleUrls: ['./autorizacion.component.scss']
})
export class AutorizacionComponent implements OnInit {
  ;
  tiposDeAutorizacion = [{
    name: 'Completa',
    desp: this.translate.translate('Persona autorizada en retirar, cancelar, ver estados de cuenta y realizar consultas personales de la cuenta. Tiene el mismo poder que el titular de la cuenta')
  },
  {
    name: 'Pago y Retiro',
    desp: this.translate.translate('Persona autorizada en retirar y cancelar los paquetes de la cuenta únicamente')
  },
  {
    name: 'Retiro',
    desp: this.translate.translate('Persona autorizada en retirar los paquetes pagos de la cuenta únicamente')
  }];

  disabledF = true;
  authorizations = [];

  formAutorizaciones = this.fb.group({
    nombre: ['', [Validators.required, Validators.max(30)]],
    cedula: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
    telefono: ['', [Validators.required]],
    tipo_autorizacion: ['', [Validators.required, Validators.max(30),]],
    email: ['', [Validators.email]],
    activo: [false, [Validators.required]],
    enviar_correo: [false, [Validators.required]],
  });

  user;
  id: number;

  cards;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private authService: AuthService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AutorizacionComponent>
  ) { }

  ngOnInit() { }


  changeFormAutorizacion(form) {
    form.usuario = this.data.usuario;

    this.authService.createAuthorization(form).subscribe(data => {

      //console.log(data);
      if (data.ok) {
        this.dialogRef.close();
      }
    });
  }
}
