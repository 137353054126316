<div class="general">
  <h3 mat-dialog-title class="modal-title text-center">{{data.title}}</h3>

  <app-camera (getPicture)="handleImage($event)"></app-camera>

  <div class="webcam-image text-center" *ngIf="webcamImage">
    <img width="480" height="240" [src]="webcamImage.imageAsDataUrl" />
  </div>

  <div mat-dialog-actions style="display: flex; justify-content: space-between;">
	  <button mat-flat-button color="primary" class="button1" (click)="onNoClick()">Cancelar</button>
	  <button mat-flat-button color="primary" class="button1" (click)="onYesClick()">Aceptar</button>
	</div>

</div>
