<div style="display: block">
	<canvas baseChart width="100%" height="100%"
		[datasets]="barChartData"
		[labels]="barChartLabels"
		[options]="barChartOptions"
		[plugins]="barChartPlugins"
		[legend]="barChartLegend"
		[colors]="barChartColors"
		[chartType]="barChartType">
  </canvas>
</div>
