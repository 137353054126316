<div class="pt-3 pb-3" style="display: flex; align-items: center; justify-content: space-between;">
  <div style="width: 100%;">
    <button mat-button [routerLink]="['/admin/almacen/entregas']"
      style="padding: 0; padding-right: 10px; color: #0094a3; float: left;">
      <mat-icon>keyboard_arrow_left</mat-icon> ENTREGAS
    </button>

    <button mat-button style="float: right;" (click)="pagarPaquetes()">
      <mat-icon>save</mat-icon>Programar Paquetes
    </button>
  </div>
</div>

<div class="p-3 content" fxLayout="column">
  <div style="padding-top: 0;" class="content-title">
    <p class="title">Programar Paquetes</p>
  </div>

  <form [formGroup]="clienteForm">
    <div fxLayout="row" fxLayoutGap="20px" class="mt-5 p-4">
      <div class="container">
        <div>
          <label class="label label_form_field_required">Cuenta</label><br>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Cuenta" formControlName="usuario" [matAutocomplete]="auto" required #cuenta (keyup)="buscarListaClientes($event, cuenta.value)">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
              (optionSelected)="getPackagesEntrega()">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>

            <span style="cursor: pointer;" matSuffix mat-flat-button (click)="clienteForm.get('usuario').setValue('')">
              <mat-icon> close </mat-icon>
            </span>

          </mat-form-field>
          <mat-error *ngIf="clienteForm.controls['usuario'].errors && clienteForm.controls['usuario'].touched">
            {{getErrorMessage(clienteForm.controls['usuario'])}}
          </mat-error>
        </div>

        <!--
        <div>
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="picker" placeholder="Fecha" formControlName="fecha" (click)="picker.open()"
              style="text-align: center;">

            <mat-datepicker #picker></mat-datepicker>

            <button matPrefix mat-flat-button (click)="previousDate()"
              style="padding: 0; padding-right: 10px; color: #0094a3">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>

            <button matSuffix mat-flat-button (click)="nextDate()"
              style="padding: 0; padding-left: 10px; color: #0094a3;">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </mat-form-field>
          <mat-error *ngIf="clienteForm.controls['fecha'].errors">
            {{getErrorMessage(clienteForm.controls['fecha'])}}
          </mat-error>

        </div>
        -->

      </div>
    </div>

  </form>

  <div class="content-table mx-4 mb-4">
    <table mat-table [dataSource]="dataSource" matSort style="width: 100%;" (matSortChange)="sortData($event)">
      <!-- select Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header [disabled]="true">
          <mat-checkbox class="d-flex" (click)="masterToggle()" aria-label="Seleccionar/Deseleccionar Todo"
            title="Seleccionar/Deseleccionar Todo"></mat-checkbox>
        </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let row">
          <mat-checkbox class="d-flex justify-content-center" (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"></mat-checkbox>
        </td>
      </ng-container>

      <!-- cuenta Column -->
      <ng-container matColumnDef="cuenta">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Cuenta </th>

        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: element.usuario.id}"> {{
            element.cuenta }}</a>

        </td>
      </ng-container>

      <!-- guia Column -->
      <ng-container matColumnDef="guia">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Guía </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: element.id}">{{element.guia}}</a>
        </td>
      </ng-container>

      <!-- cliente Column -->
      <ng-container matColumnDef="cliente">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Cliente </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.cliente ? element.cliente
          : '-'}}
        </td>
      </ng-container>

      <!-- tipo Column -->
      <ng-container matColumnDef="tipo_paquete">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Tipo </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.tipo_paquete ?
          element.tipo_paquete
          : '-'}}
        </td>
      </ng-container>

      <!-- descripcion Column -->
      <ng-container matColumnDef="descripcion">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Descripción </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.descripcion ?
          element.descripcion
          : '-'}}
        </td>
      </ng-container>

      <!-- total Column -->
      <ng-container matColumnDef="total_dolares">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Total (Dolares) </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> $ {{element.total_factura | number : '1.2-2' }}
        </td>
      </ng-container>

      <!-- total Column -->
      <ng-container matColumnDef="total_colones">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Total (Colones) </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> ¢ {{element.total_colones | number : '1.2-2' }}
        </td>
      </ng-container>
      <!-- Usuario Column -->
      <ng-container matColumnDef="usuario">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Usuario</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.Usuario ?
          element.tipo_paquete
          : '-'}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }"></tr>
    </table>

    <div class="text-center" *ngIf="dataSource.data.length == 0 && clienteForm.value.usuario != '' ">
      No hay paquetes disponibles para programar
    </div>

    <div class="text-center" *ngIf="clienteForm.value.usuario == '' ">Seleccione una cuenta</div>

  </div>

  <div class="content-table m-4 text-right" style="float: right;" *ngIf="dataSource.data.length > 0 ">
    <p>Total Paquetes Seleccionados: {{ selection?.selected.length ? selection?.selected.length : 0 }}</p>
    <p>Total Facturas Dolares: $ {{ totalFactura | number : '1.2-2' }}</p>
    <p>Total Facturas Colones: ¢ {{ totalColones | number : '1.2-2' }}</p>
    <p>Total Paquetes: {{ paquetes?.length ? paquetes?.length : 0 }}</p>
  </div>

  <div class="px-4 pb-4 paginator text-center" *ngIf="dataSource.data.length > 0 ">
    <button mat-button (click)="page.firstPage()" class="lados">
      << </button>
        <button mat-button (click)="page.previousPage()" class="lados">
          < </button>
            <button mat-button *ngFor="let pag of pages" (click)="updateManualPage(pag)"
              [ngClass]="{'actual': page.pageIndex === pag}">{{pag + 1}}</button>
            <button mat-button (click)="page.nextPage()" class="lados">></button>
            <button mat-button (click)="page.lastPage()" class="lados">>></button>

            <mat-paginator style="display: none;" [pageIndex]="pageIndex" #page [length]="length" [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions" (page)="paginator = $event" showFirstLastButtons="true">
            </mat-paginator>
  </div>
</div>
