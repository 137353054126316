<div style="width: 100%; position: relative;">
	<mat-progress-bar mode="indeterminate" *ngIf="guardando"></mat-progress-bar>

  <button mat-icon-button class="guardar-template" (click)="guardar(template, false)" *ngIf="modal == false">
		<mat-icon style="margin: 0;">save</mat-icon>
	</button>

  <button mat-icon-button [ngClass]="{'guardar-template ': true, 'mx-5' : !modal }" (click)="guardar(template, true)" *ngIf="send == true">
		<mat-icon style="margin: 0;">send</mat-icon>
	</button>

  <ckeditor id="editor" [(ngModel)]="template" style="width: 100%;" [config]="editorConfig"></ckeditor>
</div>
