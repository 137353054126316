/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
import { Component} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { EmailsService } from 'src/app/services/emails.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-mass-emails',
  templateUrl: './mass-emails.component.html',
  styleUrls: ['./mass-emails.component.scss']
})

export class MassEmailsComponent {

  form: FormGroup;

  public subject = '';
  public templateName = '';
  public mailList = '';
  public mailgun_list = '';
  public mailgun_template: any[] = [];
  public receiver_list: any[] = [];


  constructor(
    private emailsService: EmailsService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private users: UsuariosService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      template: [''],
      subject: [''],
      mailList: ['']
    });
    this.emailsService.getMailgunTemplates().subscribe((data: any) => {
      this.mailgun_template = data;
      this.emailsService.getReceiverList().subscribe((data: any) => {
        this.receiver_list = data;
      });
    });
  }
  openDialog(message, detail: string = '') {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message, detail },
      panelClass: 'general'
    });

    dialogRef.afterClosed().subscribe(result => {

    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 4000);
    });

  }

  sendMassEmail(): void {
    const params = this.form.value;
        this.emailsService.postSendEmail(params).subscribe((data: any) => {
        this.openDialog('Correos enviados correctamente');
        this.form.reset();
    });

  }
}
