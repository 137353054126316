import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { FacturasService } from 'src/app/services/facturas.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { MatDialog } from '@angular/material/dialog';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';

@Component({
  selector: 'app-facturas',
  templateUrl: './facturas.component.html',
  styleUrls: ['./facturas.component.scss']
})
export class FacturasComponent implements OnInit {
  email = new FormControl('', [Validators.required, Validators.email]);
  displayedColumns: string[] = ['numero', 'cuenta', 'guia', 'servicio', 'transporte', 'impuestos', 'iva', 'total', 'estado'];
  dataSource = [];
  selection = new SelectionModel<any>(true, []);
  facturas;

  form = this.fb.group({
    id: [''],
    date_from: [''],
    date_until: ['']
  });

  length: number;
  pageSize = 10;
  pageIndex = 1;

  constructor(
    private facturasService: FacturasService,
    public usuariosService: UsuariosService,
    public paqueteService: PaquetesService,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getFacturas();
  }

  getFacturas() {

    this.paqueteService.getForBilling(this.pageIndex).subscribe((data: any) => {

      data.results.forEach(element => {
        if (element.factura === null) {
          element.factura = {
            cif: '-',
          };
        }
      });

      this.dataSource = data.results;
      this.facturas = data.results;

      this.length = data.count;

      //console.log(this.facturas);


    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  public applyFilters() {
    //console.log('Aplicar Filtro');
    this.form.value.id == null ? this.form.value.id = '' : '';
    this.form.value.date_from == null ? this.form.value.date_from = '' : '';
    this.form.value.date_until == null ? this.form.value.date_until = '' : '';

  }

  public cleanFilters() {
    this.form.reset();
  }

  paginate(event: any) {
    //console.log(event);
    this.pageIndex = event;
    this.getFacturas();
  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 5000);
    });


    dialogRef.afterClosed().subscribe(result => {

    });
  }
}


