<div class="pt-3 pb-3" style="display: flex; align-items: center; justify-content: space-between;">
  <div style="width: 100%;">
    <button mat-button (click)="back()" style="padding: 0; padding-right: 10px; color: #0094a3; float: left;">
      <mat-icon>keyboard_arrow_left</mat-icon> Integraciones
    </button>

    <button style="float: right;" mat-flat-button color="primary" (click)="clear()">
      <mat-icon style="color: white !important; font-size: 24px !important;">clear</mat-icon> Limpiar Logs
    </button>
  </div>
</div>

<tabset #staticTabs [justified]="true" customClass="paquetesTabs">
  <tab heading="DELMAN">
    <div style="width: 100%;">
      <span>Logs DELMAN</span>
      <textarea  style="width: 100%;" [(ngModel)]="logs.delman" rows="10" readonly></textarea>
    </div>

  </tab>

  <tab heading="GTI">

    <div style="width: 100%;">
      <span>Logs Consolidación GTI</span>
      <br>
      <span>Última Ejecución: {{gtiStatusTask?.started | date:'long'}} </span>
      <br>
      <span>Proxima Ejecución: {{gtiStatus?.next_run | date:'long'}} </span>
      <br>
      <span>Repetición: {{gtiStatus?.repeats * -1}}</span>
      <br>

      <textarea style="width: 100%;" [(ngModel)]="logs.gti" rows="10" readonly></textarea>
      <br>
      <span>Estado Descargar Facturas GTI</span>
      <br>
      <span>Última Ejecución: {{facturasStatusTask?.started | date:'long'}} </span>
      <br>
      <span>Proxima Ejecución: {{facturasStatus?.next_run | date:'long'}} </span>
      <br>
      <span>Repeticiones: {{facturasStatus?.repeats * -1}}</span>
      <br>

      <textarea style="width: 100%;" [(ngModel)]="logs.gticonsolidar" rows="10" readonly></textarea>
    </div>
  </tab>


  <tab heading="PAGOS">


    <div style="width: 100%;">
      <span>Logs Pagos</span>
      <textarea style="width: 100%;" [(ngModel)]="logs.pagos" rows="10" readonly></textarea>
      <br>
      <span>Logs Procesador</span>
      <br>
      <span>Última Ejecución Pagos Automáticos: {{pagosStatusTask?.started |date:'long'}}</span>
      <br>
      <span>Proxima Ejecución Pagos Automáticos: {{pagosStatus?.next_run | date:'long'}}</span>
      <br>
      <span>Repeticiones: {{pagosStatus?.repeats * -1}}</span>
      <textarea style="width: 100%;" [(ngModel)]="logs.pagosauto" rows="10" readonly></textarea>
    </div>
  </tab>

</tabset>
