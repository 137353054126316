<h2 class="h2-title p-4">Pendiente por Consolidar</h2>

<div class="container-example" fxLayout="column" fxLayoutGap="10px"
  style="margin-left: auto;margin-right: auto; width:100%; box-shadow: 0px 8px 25px rgba(0, 148, 163, 0.12); background-color: white;">
  <div class="container-example">

    <div class="spinner-container" *ngIf="loading">
      <mat-spinner diameter="50"></mat-spinner>
    </div>

    <div class="row">
      <div class="col-12 col-lg-4 m-2 p-0" *ngFor="let paquete of paquetes">
        <mat-card class="card p-0 m-0 pb-5">
          <mat-card-header>
            <mat-card-title class="pt-2">Cliente:
              {{usuariosService.get_account_number(paquete.usuario)}}-{{usuariosService.get_fullname(paquete.usuario)}}</mat-card-title>
            <mat-card-subtitle>
              <div style="display: flex; align-items: center;">
                <h4>Guía Consolidada: {{paquete.numero_de_guia}}</h4>
                <div
                  style="background-color: white; border-radius: 50%; width: 50px; height: 50px; display: flex; align-items: center; justify-content: center; margin-left: 20px;">
                  <h4 style="color: red;">{{ getClientType(paquete) }}</h4>
                </div>
              </div>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content class="p-4">
            <div style="cursor: pointer;" (click)="openModalConsolidation(item)"
              *ngFor="let item of paquete.paquete_contenedor">
              <p>
                {{item.numero_de_guia}} - {{item.tracking}} - {{item.descripcion}}
              </p>
            </div>
            <div style="display: flex; justify-content: flex-end;">
              <div class="mr-2" *ngIf="authService.isAdmin || authService.isMiami || authService.isOperacionesMiami">
                <button type="button" mat-flat-button style="background-color: red; color: white"
                  (click)="undoConsolidate(paquete)">Deshacer</button>
              </div>

              <div *ngIf="authService.isAdmin || authService.isMiami || authService.isOperacionesMiami">
                <button type="button" mat-flat-button color="primary"
                  (click)="openDialogNuevoPaquete(paquete.tracking, false, paquete.id, paquete, true, false)">
                  Consolidar
                </button>
              </div>

              <div *ngIf="paquetes?.length > 0 && !authService.isServicioCliente">
                <button mat-flat-button color="primary" class="button-calcular"
                  (click)="openDialogNuevoPaquete(paquete.tracking, false, paquete.id, paquete, false, true)">
                  Convertir en Bultos adicionales
                </button>
              </div>
            </div>

          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

</div>
