import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TarifasService {
  private url = `${environment.apiUrl}/tarifas/`;
  private urlVariablesGenerales = `${environment.apiUrl}/tarifas/variables`;

  constructor(
    private http: HttpClient
  ) { }

  getTarifaByPlan(id) {
    return this.http.get(this.url + id);
  }

  getTarifaFleteByPlan(id) {
    return this.http.get(this.url + 'flete/plan/' + id);
  }

  getTarifaManejoByPlan(id) {
    return this.http.get(this.url + 'manejo/plan/' + id);
  }

  getTarifaBodegajeByPlan(id) {
    return this.http.get(this.url + 'bodegaje/plan/' + id);
  }

  getTarifaEmbalajeByPlan(id) {
    return this.http.get(this.url + 'embalaje/plan/' + id);
  }

  getTarifaCorrespondenciaByPlan(id) {
    return this.http.get(this.url + 'correspondencia/plan/' + id);
  }

  getTarifaTransporteByPlan(id, empresa) {
    return this.http.get(this.url + 'transporte/plan/' + id + '/' + empresa);
  }

  getTarifasVariables(id) {
    return this.http.get(this.url + 'variables/plan/' + id);
  }

  postTarifa(data) {
    return this.http.post(this.url, data);
  }

  postTarifaFlete(data) {
    return this.http.post(this.url + 'flete/', data);
  }

  postTarifaManejo(data) {
    return this.http.post(this.url + 'manejo/', data);
  }

  postTarifaBodegaje(data) {
    return this.http.post(this.url + 'bodegaje/', data);
  }

  postTarifaEmbalaje(data) {
    return this.http.post(this.url + 'embalaje/', data);
  }

  postTarifaCorrespondencia(data) {
    return this.http.post(this.url + 'correspondencia/', data);
  }

  getTarifaTransporte(data) {
    return this.http.get(this.url + 'transporte/', {params: data});
  }

  postTarifaTransporte(data) {
    return this.http.post(this.url + 'transporte/', data);
  }

  postTarifaVariable(data) {
    return this.http.post(this.url + 'variables/', data);
  }

  updateTarifa(id, data) {
    return this.http.put(this.url + id + '/', data);
  }

  updateTarifaFlete(id, data) {
    return this.http.put(this.url + 'flete/' + id + '/', data);
  }

  updateTarifaManejo(id, data) {
    return this.http.put(this.url + 'manejo/' + id + '/', data);
  }

  updateTarifaBodegaje(id, data) {
    return this.http.put(this.url + 'bodegaje/' + id + '/', data);
  }

  updateTarifaEmbalaje(id, data) {
    return this.http.put(this.url + 'embalaje/' + id + '/', data);
  }

  updateTarifaCorrespondencia(id, data) {
    return this.http.put(this.url + 'correspondencia/' + id + '/', data);
  }

  updateTarifaTransporte(id, data) {
    return this.http.put(this.url + 'transporte/' + id + '/', data);
  }

  updateTarifaVariable(id, data) {
    return this.http.put(this.url + 'variables/' + id + '/', data);
  }

  deleteTarifa(id) {
    return this.http.delete(this.url + id + '/');
  }

  deleteTarifaFlete(id) {
    return this.http.delete(this.url + 'flete/' + id + '/');
  }

  deleteTarifaManejo(id) {
    return this.http.delete(this.url + 'manejo/' + id + '/');
  }

  deleteTarifaBodegaje(id) {
    return this.http.delete(this.url + 'bodegaje/' + id + '/');
  }

  deleteTarifaEmbalaje(id) {
    return this.http.delete(this.url + 'embalaje/' + id + '/');
  }

  deleteTarifaCorrespondencia(id) {
    return this.http.delete(this.url + 'correspondencia/' + id + '/');
  }

  deleteTarifaTransporte(id) {
    return this.http.delete(this.url + 'transporte/' + id + '/');
  }

  /* Tarifas */
  get() {
    return this.http.get(this.url);
  }

  getByCodigo(codigo) {
    return this.http.get(this.url + 'codigo/' + codigo);
  }

  getByID(id) {
    return this.http.get(this.url + id);
  }

  getEmpresas() {
    return this.http.get(this.url + 'empresas/');
  }



}
