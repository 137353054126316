<div class="container-example" fxLayout="column" fxLayoutGap="10px"
  style="margin-left: auto;margin-right: auto; width:100%; box-shadow: 0px 8px 25px rgba(0, 148, 163, 0.12); background-color: white;">
  <div class="container-example">

    <div class="content-table m-4">
      Cuentas ({{cuentas.length}})

      <mat-list-item *ngIf="cuentas.length == 0">
        <div mat-line> No hay cuentas correspondientes a su búsqueda </div>
      </mat-list-item>

      <table mat-table [dataSource]="cuentas" class="mat-elevation-z8" *ngIf="cuentas.length > 0">

        <ng-container matColumnDef="cuenta">
          <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Cuenta </th>
          <td class="pr-pl-20" mat-cell *matCellDef="let element"> <a [routerLink]="['/admin/perfil']"
              [queryParams]="{cuenta: element.id}" style="cursor: pointer;">
              <mat-icon mat-list-icon>person</mat-icon> {{usuariosService.get_account_number(element)}}
            </a> </td>
        </ng-container>

        <ng-container matColumnDef="nombre">
          <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Cliente </th>
          <td class="pr-pl-20" mat-cell *matCellDef="let element"> <a [routerLink]="['/admin/perfil']"
              [queryParams]="{cuenta: element.id}" style="cursor: pointer;">{{usuariosService.get_fullname(element)}}
            </a>
          </td>
        </ng-container>

        <!-- delete Name Column -->
        <ng-container matColumnDef="delete_by">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Eliminada por </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{ element.delete_by ?
            usuariosService.get_fullname(element.delete_by) : '-'}} </td>
        </ng-container>

        <!-- delete date Column -->
        <ng-container matColumnDef="delete_date">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Fecha de Eliminación </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element">
            <span *ngIf="element.delete">{{ element.delete_date | date:'short'}}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsClientes"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsClientes;"></tr>
      </table>
    </div>

    <mat-divider></mat-divider>

    <div class="content-table m-4">
      Paquetes ({{paquetes.length}})

      <mat-list-item *ngIf="paquetes.length == 0">
        <div mat-line> No hay paquetes correspondientes a su búsqueda </div>
      </mat-list-item>

      <table mat-table [dataSource]="paquetes" class="mat-elevation-z8" *ngIf="paquetes.length > 0">

        <ng-container matColumnDef="numero_de_guia">
          <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Guía </th>
          <td class="pr-pl-20" mat-cell *matCellDef="let element">

            <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: element.id}" style="cursor: pointer;"
              *ngIf="authService.isServicioCliente || authService.isAdmin">
              <mat-icon mat-list-icon>inventory_2</mat-icon> {{element.numero_de_guia}}
              <span
                *ngIf="element.paquete_principal.length > 0">({{element.paquete_principal[0].numero_de_guia}})</span>
            </a>

            <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: element.id}" style="cursor: pointer;"
              *ngIf="authService.isOperacionesMiami">
              <mat-icon mat-list-icon>inventory_2</mat-icon> {{element.numero_de_guia}}
              <span *ngIf="element.paquete_principal.length > 0">({{element.paquete_principal[0].numero_de_guia}})
              </span>
            </a>

            <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: element.id}" style="cursor: pointer;"
              *ngIf="authService.isOperacionesCostaRica || authService.isVendedor || authService.isMiami">
              <mat-icon mat-list-icon>inventory_2</mat-icon> {{element.numero_de_guia}}
              <span *ngIf="element.paquete_principal.length > 0">({{element.paquete_principal[0].numero_de_guia}})
              </span>
            </a>

            <a (click)="openDialogNuevoPaquete(element.tracking, false, element.id, element)" style="cursor: pointer;"
              *ngIf="!authService.isAdmin  && !authService.isOperacionesMiami && !authService.isOperacionesCostaRica && !authService.isVendedor && !authService.isMiami && !authService.isServicioCliente">
              <mat-icon mat-list-icon>inventory_2</mat-icon> {{element.numero_de_guia}}
            </a>

          </td>
        </ng-container>

        <ng-container matColumnDef="tracking">
          <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Tracking </th>
          <td class="pr-pl-20" mat-cell *matCellDef="let element"> {{element.tracking}}</td>
        </ng-container>

        <ng-container matColumnDef="descripcion">
          <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Descripción </th>
          <td class="pr-pl-20" mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
        </ng-container>

        <ng-container matColumnDef="zona">
          <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Zona </th>
          <td class="pr-pl-20 text-center" mat-cell *matCellDef="let element">
            {{element.entrega.zona_centro_distribucion}} </td>
        </ng-container>

        <ng-container matColumnDef="nombre">
          <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Cliente </th>
          <td class="pr-pl-20" mat-cell *matCellDef="let element">
            <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: element.usuario?.id}"
              style="cursor: pointer;">{{usuariosService.get_fullaccount(element.usuario)}} </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="ultima_actualizacion">
          <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Última Actualización </th>
          <td class="pr-pl-20" mat-cell *matCellDef="let element"> {{ element.estado_historial.estado_fecha |
            date:'dd-MM-yyyy'}} -
            {{element.estado_actual.estado}} </td>
        </ng-container>

        <ng-container matColumnDef="embarque">
          <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Embarque </th>
          <td class="pr-pl-20 text-center" mat-cell *matCellDef="let element"> {{element.embarque}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsPaquetes"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsPaquetes;"></tr>
      </table>
    </div>

    <mat-divider></mat-divider>

    <div class="content-table m-4">

      Autorizados ({{autorizados.length}})

      <mat-list-item *ngIf="autorizados.length == 0">
        <div mat-line> No hay Autorizados correspondientes a su búsqueda </div>
      </mat-list-item>

      <table mat-table [dataSource]="autorizados" class="mat-elevation-z8" *ngIf="autorizados.length > 0">

        <ng-container matColumnDef="nombre">
          <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Nombre </th>
          <td class="pr-pl-20" mat-cell *matCellDef="let element"> <a [routerLink]="['/admin/perfil']"
              [queryParams]="{cuenta: element.usuario?.id}" style="cursor: pointer;">
              <mat-icon mat-list-icon>person</mat-icon> {{element.nombre}}
            </a> </td>
        </ng-container>

        <ng-container matColumnDef="cuenta">
          <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Cuenta </th>
          <td class="pr-pl-20" mat-cell *matCellDef="let element"> <a [routerLink]="['/admin/perfil']"
              [queryParams]="{cuenta: element.usuario?.id}"
              style="cursor: pointer;">{{usuariosService.get_fullaccount(element.usuario)}} </a></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsAutorizados"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsAutorizados;"></tr>
      </table>
    </div>

  </div>
</div>