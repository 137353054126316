import { Component, OnInit } from '@angular/core';
import {FormControl , Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router} from '@angular/router';

import {RutasService} from '../../../../../services/rutas.service';

import { GeneralDialogComponent } from '../../../../common/dialogs/general-dialog/general-dialog.component';


export interface RouteElement {
  id: number;
  name: string;
  available: boolean;
}

@Component({
  selector: 'app-add-route',
  templateUrl: './add-route.component.html',
  styleUrls: ['./add-route.component.scss']
})
export class AddRouteComponent implements OnInit {
  name: string;
  nameCtrl: FormControl;

  constructor(
    private router: Router,
    private routes: RutasService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.name = '';

    // Setea Validaciones a los Controles
    this.nameCtrl = new FormControl('',[
      Validators.required,
      Validators.minLength(3)
    ]);

    //Si no existe la lista de Rutas la carga
    if (!this.routes.routesList){
      this.routes.get().subscribe(
        (res: any)=>{
          const routesList: RouteElement[] = [];
          res.forEach(element => {
            routesList.push({
              id: element.id,
              name: element.nombre,
              available: element.disponible
            });
          });
          this.routes.routesList = routesList;
        }
      );
    }
  }

  public saveRoute(){
    this.nameCtrl.setValue(this.name);
    this.nameCtrl.updateValueAndValidity();

    //Build Route Structure to Send Data
    if (this.Validate() ){
      const data={
        nombre:this.name
      };
      this.routes.post(data).subscribe(
        (res: any)=>{
          this.router.navigate(['/admin/otros/rutas']);
          this.openDialog('Ruta Creada Exitosamente');
      });
    }
  }

  private Validate(){
    if ( this.nameCtrl.valid ) {
return true;
}
    return false;
  }

  public getErrorMessage(control) {
    if (control.hasError('required')) {
      return 'Valor requerido';
    }
    if (control.hasError('minlength')) {
      return 'Demasiado Corto';
    }
    return 'Error';
  }

  private openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass:'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }

}
