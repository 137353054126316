<h2 class="h2-title p-4">Facturas por Generar</h2>

<div class="content" fxLayout="column">
  <!-- <div>
    <label class="label">Paquetes Creados</label><br>
    <mat-form-field appearance="outline" class="form-field" >
      <input matInput placeholder="Paquetes Creados" type="date" class="input-field">
    </mat-form-field>
  </div> -->

  <form [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutGap="20px" class="pr-4 pl-4 pt-2 pb-2 mt-2 inputs-form"
      style="display: flex; align-items: center;">


      <div>
        <label class="label">Embarque</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Embarque" formControlName="embarque" class="input-field">
        </mat-form-field>
      </div>

      <div>
        <label class="label">Desde e Incluido</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Hasta e Incluido" formControlName="date_from" type="date" class="input-field">
        </mat-form-field>
      </div>

      <div>
        <label class="label">Hasta e Incluido</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Hasta e Incluido" formControlName="date_until" type="date" class="input-field">
        </mat-form-field>
      </div>

      <div>
        <button mat-button (click)="getFacturas()">
          <img src="assets/iconos-dashboard/Lupa.png">Buscar
        </button>
      </div>

      <div>
        <button mat-button (click)="cleanFilters()">
          <img src="assets/iconos-dashboard/Facturas.png">Limpiar
        </button>
      </div>
    </div>
  </form>
  <!--
  <div>
    <label class="label">Anulada</label><br>
    <mat-form-field appearance="outline" class="form-field" >
      <input matInput placeholder="Anulada" class="input-field">
    </mat-form-field>
  </div> -->

  <mat-divider></mat-divider>

  <div class="content-table mt-0 mx-1 mb-0">
    <table mat-table
      [dataSource]="dataSource | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length}" matSort
      matSortDisabled>

      <ng-container matColumnDef="cuenta">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cuenta </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{usuariosService.get_fullaccount(element.usuario)}} </td>
      </ng-container>

      <ng-container matColumnDef="guia">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Guía </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: element.id, factura: true}">
            {{element.numero_de_guia ? element.numero_de_guia: '-'}}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="descripcion">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Descripción </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.descripcion}}
        </td>
      </ng-container>

      <ng-container matColumnDef="embarque">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Embarque </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.embarque}}
        </td>
      </ng-container>

      <ng-container matColumnDef="estado">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Estado </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          Pendiente
        </td>
      </ng-container>

      <ng-container matColumnDef="clase">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Clase </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.clase}}
        </td>
      </ng-container>

      <ng-container matColumnDef="fecha">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.updated_at | date: 'mediumDate'
          }} </td>
      </ng-container>

      <ng-container matColumnDef="opciones">
        <th mat-header-cell class="pr-pl-20 table-title" *matHeaderCellDef>Opciones</th>
        <td mat-cell class="pr-pl-20 text-center" mat-cell *matCellDef="let element">

          <button mat-flat-button color="primary" class="button-calcular d-inline" (click)="crearFactura(element.id)"
            style="width: min-content; min-width: 0 !important" matTooltip='Revisar Factura'>Revisar Factura</button>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }"></tr>
    </table>
  </div>

  <div class="pt-2 paginator text-center">
    <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
      (pageChange)="pageIndex = $event; paginate(pageIndex)"></pagination-controls>
  </div>

</div>