import { Injectable } from '@angular/core';
import { TRANSLATIONS } from '../shared/translations';

@Injectable({
  providedIn: 'root'
})

export class TranslateService {

  currentLanguage = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'es' ;

  constructor() { }

  translate(str) {
    /**Esta linea trae el nodo con la palabra  traducir en todas sus
     * variantes de idioma.
     */
    let count = 0;
    const translation = TRANSLATIONS.filter(entrada => entrada.es === str);
    if (translation.length > 0) {
      count = Object.keys(translation[0]).length;
    }

    /**retorno el mismo texto en el idioma actualmente seleccionado */
    let result;

    if (count > 2) {
      result = translation[0][this.currentLanguage];
      if (translation[0][this.currentLanguage] === '') {
        result = translation[0].en;
      }
    } else {
      /* result = translation[0].en;*/
      if (count === 2 && this.currentLanguage !== 'es'){
        result = translation[0].en;
      } else {
        result = str;
      }
    }

    return result;
  }

  changeLanguage(lang){
    this.currentLanguage = lang;
    localStorage.setItem('lang', lang);
    window.location.reload();
  }
}
