import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EmailsService {
  private url = `${environment.apiUrl}/emails/`;
  private url2 = `${environment.apiUrl}/emails-embarques/`;

  constructor(
    private http: HttpClient
  ) { }

  get() {
    return this.http.get(this.url);
  }

  getTemplates() {
    return this.http.get(this.url + 'templates');
  }

  getByID(id) {
    return this.http.get(this.url + id);
  }

  post(data) {
    return this.http.post(this.url, data);
  }

  put(id, data) {
    return this.http.put(this.url + id + '/', data);
  }

  postEmbarque(data) {
    return this.http.post(this.url2, data);
  }

  getMailgunTemplates() {
    return this.http.get(this.url + 'mailgun/templates');
  }

  getReceiverList() {
    return this.http.get(this.url + 'mailgun/lists');
  }
  postSendEmail(data) {
    return this.http.post(this.url + 'mailgun/send', data);
  }
}
