import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BaseService {

  baseUrl = environment.apiUrl;

  constructor(public http: HttpClient, @Inject('endpoint') endpoint: string) {
    this.baseUrl = this.joinUrl(endpoint);
  }

  joinUrl(...segments: (string | number)[]): string {
    return [this.baseUrl, ...segments].join('/');
  }

  get(url: string, params?: any) {
    return this.http.get(url, {...params });
  }

  post(url: string, body?: any) {
    return this.http.post(url, {... body });
  }

  put(url: string, body?: any) {
    return this.http.put(url, {... body });
  }

}
