<h2 class="h2-title p-4">Almacén</h2>

<!--<div style="display: flex; justify-content: flex-end;">
  <button style="min-width: inherit !important;" mat-flat-button color="primary" (click)="changeSize(fontSize + 1)"
    class="button-calcular">+</button>
  <button style="min-width: inherit !important;" mat-flat-button color="primary" (click)="changeSize(fontSize - 1)"
    class="button-calcular">-</button>
</div>-->

<div class="content" fxLayout="column">
  <div class="content-title">
    <p class="title" *ngIf="tipo == 1">Actualización Multiple</p>
    <p class="title" *ngIf="tipo == 2">Capturar Guía</p>
    <p class="title" *ngIf="tipo == 3">Capturar Paquetes</p>
  </div>

  <div class="pt-4 px-4 mb-2" *ngIf="tipo == 1 || tipo == 3">
    <label class="label">Seleccione una Acción</label>
    <ng-select class="input-field" [(ngModel)]="selectedAction" (ngModelChange)="estado_nuevo = selectedAction"
      bindLabel="estado" bindValue="id" name="action" [clearable]="false">
      <ng-option *ngFor="let act of estadosPaquetes" [value]="act.id">
        {{ act.estado }}
      </ng-option>
    </ng-select>
  </div>

  <div class="pl-4 pr-4" [ngClass]="{'pt-2 ': tipo==1, 'pt-4': tipo==2 }" *ngIf="tipo == 1 || tipo == 2 || tipo == 3">
    <label class="label" *ngIf="authService.activeRol == 'ALMAC' && tipo==1">Tracking</label>
    <label class="label" *ngIf="authService.activeRol == 'ALMAC' && tipo==2">Guía</label>
    <label class="label" *ngIf="authService.activeRol == 'ADMIN'">Guía</label>
    <label class="label" *ngIf="authService.activeRol != 'ADMIN' && authService.activeRol != 'ALMAC'">Guía</label>

    <mat-form-field appearance="outline" class="form-field">
      <input matInput #track [(ngModel)]="tracking" rows="2" (blur)="$event.preventDefault(); findByManualInput()"
        (keyup.enter)="$event.preventDefault(); findByManualInput();">
    </mat-form-field>
    <span *ngIf="noResults" class="errorLabel">Número de guía no existe</span>
    <span *ngIf="packageDelete" class="errorLabel">El paquete fue eliminado</span>
    <br />
    <span *ngIf="tipo == 2 && paquetePendientes?.length > 0">
      Existen <span class="label1" (click)="showGuiasPendientes()">{{paquetePendientes?.length}} </span> guía(s) por
      completar
    </span>
    <br>
    <br>

    <label class="label" *ngIf="showGuias">Guía Pendientes</label>
    <mat-nav-list *ngIf="showGuias && !loading">
      <table mat-table [dataSource]="paquetePendientes.data" matSort>

        <!-- Columnas -->
        <ng-container matColumnDef="guide_number">
          <th mat-header-cell *matHeaderCellDef>Número de Guía</th>
          <td mat-cell *matCellDef="let pendiente">{{ pendiente.numero_de_guia }}</td>
        </ng-container>

        <ng-container matColumnDef="tracking">
          <th mat-header-cell *matHeaderCellDef>Tracking</th>
          <td mat-cell *matCellDef="let pendiente">{{ pendiente.tracking }}</td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef>Fecha de Creación</th>
          <td mat-cell *matCellDef="let pendiente">{{ pendiente.created_at | dateFormat }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Acciones</th>
          <td mat-cell *matCellDef="let element">

            <button mat-flat-button style="background-color: green !important; color: white !important;"
              (click)="openDialogNuevoPaquete(element.tracking, false, element.id, element, false, false);">
              <mat-icon style="color: white !important;">edit</mat-icon>Editar
            </button>
            <button mat-flat-button style="background-color: red !important; color: white !important;"
              (click)="deletePackage(element)" *ngIf="authService.isAdmin">
              <mat-icon style="color: white !important;">delete</mat-icon>Eliminar
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['guide_number', 'tracking', 'created_at', 'actions']"></tr>

        <tr mat-row *matRowDef="let row; columns: ['guide_number', 'tracking', 'created_at', 'actions']"></tr>
      </table>
      <ng-container
        *ngFor="let pendiente of paquetePendientes.data  | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length}">
      </ng-container>
    </mat-nav-list>

    <div *ngIf="length > 0 && showGuias">
      <div style="display: flex; justify-content: center;" *ngIf="loading == true">
        <mat-spinner></mat-spinner>
      </div>
      <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
        (pageChange)="pageIndex = $event; paginate(pageIndex)"></pagination-controls>
    </div>

  </div>
  <br>

  <div class="pl-4 pr-4" *ngIf="paquetes.length > 0 && (tipo == 1 || tipo == 3)">
    <label class="label">Paquetes a Procesar:</label>

    <mat-selection-list #listapaquetes dense>
      <mat-list-option *ngFor="let paquete of paquetes" [value]="paquete.tracking"
        [ngStyle]="{'color': paquete.invalidcode ? 'red': 'black'}" [selected]="true" [disabled]="true">
        {{paquete.tracking}} ({{paquete.numero_de_guia == undefined ? 'Sin prealerta' : paquete.numero_de_guia}})
        {{ getClientType(paquete) }}
        {{paquete.invalidcode != undefined ? paquete.invalidcode ? '(Estado Actual: ' + paquete.estado_actual.estado +
        ')' : '' : '' }}
      </mat-list-option>
    </mat-selection-list>

    <mat-divider></mat-divider>

    <label class="label pr-2" *ngIf="authService.activeRol == 'ALMAC'">Prealertados: {{prealertados}} </label>
    <label class="label px-2" *ngIf="authService.activeRol == 'ALMAC'">Sin Prealerta: {{sinprealerta}} </label>
    <label class="label pl-2" *ngIf="authService.activeRol == 'ALMAC'">Total: {{paquetes.length}} </label>
  </div>

  <div class="pl-4 pt-4 pr-4" *ngIf="paquetes.length > 0 && (tipo == 1 || tipo == 3)">
    <label class="label">Agregue una Anotación</label>
    <textarea [(ngModel)]="descripcion" rows="2"></textarea>
  </div>

  <div class="pl-4 pt-2 pr-4 pb-5" *ngIf="paquetes.length > 0 && tipo == 3">
    <mat-checkbox style="font-size: 14px;" [(ngModel)]="notificar">Notificar Cliente</mat-checkbox>
  </div>

  <div style="display: flex; justify-content: flex-end;" class="pl-4 pr-4 pt-2 pb-5"
    *ngIf="(authService.isServicioCliente || authService.isAdmin) && paquetes.length > 0">
    <button mat-flat-button color="primary" (click)="cambiarEstadoPaquetes()" class="button-calcular">Procesar</button>
  </div>
</div>
