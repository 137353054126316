import { AuthService } from 'src/app/services/auth.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { GeneralDialogComponent } from '../../../../common/dialogs/general-dialog/general-dialog.component';
import { EmbarquesService } from 'src/app/services/embarques.service';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { UsuariosService } from 'src/app/services/usuarios.service';

@Component({
  selector: 'app-capturar-embarques',
  templateUrl: './capturar-embarques.component.html',
  styleUrls: ['./capturar-embarques.component.scss']
})
export class CapturarEmbarquesComponent implements OnInit {
  @ViewChild('listapaquetes') selectionList: MatSelectionList;

  selectedEmbarque;
  paquetes: any = [];

  tracking = '';
  descripcion = '';
  numero_de_guia = '';
  scannedGuide = '';

  qrNoExiste = false;
  alreadyExists = false;
  scanned = false;
  isPackageWithBultos = false;

  // eslint-disable-next-line max-len
  displayedColumns: string[] = ['guia', 'tracking', 'peso_real', 'tipo_paquete', 'peso_volumetrico'];
  dataSource = new MatTableDataSource<any>();

  fontSize = 16;
  selectedAction: any;
  embarques = [];
  scannedChildPackage = false;
  childPackage: any;

  constructor(
    public usuariosService: UsuariosService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private embarquesService: EmbarquesService,
    private datePipe: DatePipe,
    private elRef: ElementRef,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.getEmbarques();
    this.fontSize = this.authService.isMiami ? 20 : 16;
    this.elRef.nativeElement.style.setProperty('--font-size', this.fontSize + 'px');
  }

  changeSize(fontSize) {
    this.fontSize = fontSize;
    this.elRef.nativeElement.style.setProperty('--font-size', this.fontSize + 'px');
  }
  playAudioWrong() {
    const audio = new Audio();
    audio.src = '../../../assets/wrong.wav';
    audio.load();
    audio.play();
  }
  playAudioCorrect() {
    const audio = new Audio();
    audio.src = '../../../assets/correct.wav';
    audio.load();
    audio.play();
  }

  // Busqueda Manual
  findByManualInput(guia) {

    guia = String(guia).toLocaleLowerCase();
    this.scannedChildPackage = false;
    this.childPackage = null;
    this.numero_de_guia = guia;
    this.qrNoExiste = false;
    this.alreadyExists = false;
    this.isPackageWithBultos = false;
    this.scanned = false;

    const filter = this.paquetes.filter(function (el) {
      return el.numero_de_guia.toLocaleLowerCase().includes(guia) || (el.bultos && el.bultos.some(bulto => bulto.guia && bulto.guia.toLocaleLowerCase().includes(guia)));
    });
    const hasBultos = filter.some(el => el.bultos && el.bultos.length > 0 && !(el.bultos.some(bulto => bulto.guia && bulto.guia.toLocaleLowerCase() === guia)));
    if(hasBultos){
      this.playAudioWrong();
      this.isPackageWithBultos = true;
    }else{
      if (filter.length > 0) {
        const i = this.paquetes.map(function (x) {
          return x.id;
        }).indexOf(filter[0].id);
        if (this.paquetes[i].escaneado_embarque && this.paquetes[i].numero_de_guia.toLocaleLowerCase() === guia) {
          this.playAudioCorrect();
          this.alreadyExists = true;
        } else {

          let option = this.selectionList.options.find(function (el) {
            return el.value.numero_de_guia.toLocaleLowerCase().includes(guia);
          });
          if (option) {
            option._setSelected(true);
          }

          this.scanned = true;
          if (this.paquetes[i].numero_de_guia.toLocaleLowerCase() === guia) {
            this.scannedGuide = this.paquetes[i].numero_de_guia;
            this.paquetes[i].escaneado_embarque = true;
          } else {
            this.scannedGuide = this.paquetes[i].bultos.find(function (el) {
              return el.guia.toLocaleLowerCase().includes(guia);
            }).guia;

            this.paquetes[i].bultos.find(function (el) {
              return el.guia.toLocaleLowerCase().includes(guia);
            }).escaneado_embarque = true;

            this.scannedChildPackage = true;
            this.childPackage = this.paquetes[i].bultos.find(function (el) {
              return el.guia.toLocaleLowerCase().includes(guia);
            });
          }

          this.playAudioCorrect(); //TODO: Cambiar que el audio se reproduzca en la respuesta del servidor. Referencia: SB-223
          this.procesarPaquetes(false);


          // eslint-disable-next-line max-len
          this.paquetes.sort((a, b) => (a.escaneado_embarque > b.escaneado_embarque) ? 1 : ((b.escaneado_embarque > a.escaneado_embarque) ? -1 : 0));
        }

      } else {
        if (filter.length === 0) {
          this.qrNoExiste = true;
          this.playAudioWrong();
        }
      }
    }
    this.numero_de_guia = '';

    //console.log(this.selectionList.selectedOptions.selected);

    this.dataSource.data = this.paquetes;
  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  public getEmbarques() {
    this.spinner.show();
    this.embarquesService.getLast().subscribe((res: any) => {
      let status;

      //console.log(res);

      this.embarques = res;

      const e = this.embarques[0];
      this.selectedAction = e;

      const d = new Date(e.date);
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset());

      this.selectedEmbarque = {
        awb: e.awb_bl,
        charge_weight: e.charge_weight,
        refdate: this.datePipe.transform(d, 'dd-MM-yyyy'),
        file: e.file_a,
        vessel: e.travel_identifier,
        gross_weight: e.gross_weight,
        id: e.id,
        notes: e.notes,
        packages: e.total_packages,
        pieces: e.pieces,
        scanned: e.scanned_packages,
        services: e.travel_type,
        status,
        bultos: e.total_charge,
        total: e.total,
        creation: this.datePipe.transform(new Date(e.created_at), 'dd-MM-yyyy HH:mm'),
        full: e.paquetes.paquetes,
        scanned_packages: e.paquetes.escaneado_embarque_count
      };

      this.paquetes = this.selectedEmbarque.full;
      //console.log('paquetes', this.paquetes);

      // eslint-disable-next-line max-len
      this.paquetes.sort((a, b) => (a.escaneado_embarque > b.escaneado_embarque) ? 1 : ((b.escaneado_embarque > a.escaneado_embarque) ? -1 : 0));
      //modify string weight
      this.paquetes.forEach(element => {
        const peso_str = element.largo + '" x ' + element.ancho + '" x ' + element.alto + '"';
        element.dimensions = peso_str;
        const bultos_array = JSON.parse(element.bultos);
        if(bultos_array != null){
          element.bultos = bultos_array
        }
      });
      //
      this.dataSource.data = this.paquetes;

      setTimeout(() => {
        this.paquetes.forEach(element => {
          if (element.escaneado_embarque) {
            this.selectionList.options.find(function (el) {
              return el.value.tracking === element.tracking;
            })._setSelected(true);

          }
        });
        this.spinner.hide();

      }, 1000);
    });
  }

  changeEmbarque(embarque) {
    //console.log(embarque);

    const e = embarque.value;

    const d = new Date(e.date);
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset());

    this.selectedEmbarque = {
      awb: e.awb_bl,
      charge_weight: e.charge_weight,
      refdate: this.datePipe.transform(d, 'dd-MM-yyyy'),
      file: e.file_a,
      vessel: e.travel_identifier,
      gross_weight: e.gross_weight,
      id: e.id,
      notes: e.notes,
      packages: e.total_packages,
      pieces: e.pieces,
      scanned: e.scanned_packages,
      services: e.travel_type,
      status,
      bultos: e.total_charge,
      total: e.total,
      creation: this.datePipe.transform(new Date(e.created_at), 'dd-MM-yyyy HH:mm'),
      full: e.paquetes.paquetes,
      scanned_packages: e.paquetes.escaneado_embarque_count

    };

    this.selectedAction = this.selectedEmbarque;

    this.paquetes = embarque.value.paquetes.paquetes;

    this.dataSource.data = this.paquetes;



    setTimeout(() => {
      this.paquetes.forEach(element => {
        if (element.escaneado_embarque) {

          this.selectionList.options.find(function (el) {
            return el.value.tracking === element.tracking;
          })._setSelected(true);

        }
      });
      this.spinner.hide();

    }, 1000);
  }

  procesarPaquetes(showConfirm = true) {
    const items = [];

    this.selectionList.selectedOptions.selected.forEach(element => {
      ////console.log(element.value.id);
      items.push(element.value.id);
    });

    if (this.childPackage) {
      items.push(this.childPackage.guia);
    }

    //console.log(items);

    const embarque = {
      descripcion: this.datePipe.transform(new Date(), 'dd-MM-yyy') + ': ' + this.descripcion,
      paquetes: items,
      scanned_packages: this.selectedEmbarque.scanned_packages,
      total_packages: this.paquetes.length,
    };

    if (this.descripcion.length === 0) {
      delete embarque.descripcion;
    }

    this.embarquesService.capturarEmbarques(this.selectedEmbarque.id, embarque).subscribe((res: any) => {
      this.selectedEmbarque.scanned_packages = res.scanned_packages;
      if (res.status && showConfirm) {
        this.openDialog('Embarque Actualizado');
      }
    });

  }

  parseBultos(bultos: any): any {
  if (typeof bultos === 'string') {
    try {
      return JSON.parse(bultos);
    } catch (e) {
      console.error('Error parsing bultos:', e);
    }
  }
  return bultos;
}

getEmbarqueCount(element: any): string {
  element.bultos = this.parseBultos(element.bultos);
  if (element.bultos && element.bultos.length > 0) {
    let count = element.bultos.filter(item => item.escaneado_embarque === true).length;
    if(element.escaneado_embarque){
      count++;
    }
    return `(${count}/${element.bultos.length})`; 
  }
  return '';
}

isAllBultosEscaneadoEmbarque(row): boolean {
  row.bultos = this.parseBultos(row.bultos);
  return row.bultos.every(bulto => bulto.escaneado_embarque);
}

isSomeBultosEscaneadoEmbarque(row): boolean {
  row.bultos = this.parseBultos(row.bultos);
  if ((row.bultos !== null && (!this.isAllBultosEscaneadoEmbarque(row)  && row.bultos.some(bulto => bulto.escaneado_embarque)))) {
    return true;
  }
  return false;
}

}

