import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-radar',
  templateUrl: './radar.component.html',
  styleUrls: ['./radar.component.scss']
})
export class RadarComponent implements OnInit {

  public radarChartOptions: RadialChartOptions = {
    responsive: true,
  };
  public radarChartLabels: Label[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun'];

  public radarChartData: ChartDataSets[] = [
    { data: [65, 59, 90, 81, 56, 55, 40] },
    { data: [28, 48, 40, 19, 96, 27, 100] }
  ];
  public radarChartType: ChartType = 'radar';
  public radarChartLegend = false;

  constructor() { }

  ngOnInit() {

  }

}
