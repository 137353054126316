import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { EmailsService } from 'src/app/services/emails.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ['email', 'opciones'];
  dataSource = new MatTableDataSource<any>();

  tipo = '';

  constructor(
    private emailService: EmailsService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.tipo) {
        this.tipo = params.tipo;

        if (this.tipo == 'N') {
          this.emailService.get().subscribe((data: any) => {
            this.dataSource.data = data;
          });
        }

        if (this.tipo == 'T') {
          this.emailService.getTemplates().subscribe((data: any) => {
            this.dataSource.data = data;
          });
        }

        //console.log(this.tipo);
      } else {
        this.tipo = 'N';
        this.emailService.get().subscribe((data: any) => {
          this.dataSource.data = data;
        });
      }
    });


  }
}

