import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuariosService } from '../../../../../services/usuarios.service';
import { RutasService } from '../../../../../services/rutas.service';

import { GeneralDialogComponent } from '../../../../common/dialogs/general-dialog/general-dialog.component';

// export interface DriverElement {
//   id: number;
//   name: string;
// }
export interface RouteElement {
  id: number;
  name: string;
  available: boolean;
}

@Component({
  selector: 'app-edit-route',
  templateUrl: './edit-route.component.html',
  styleUrls: ['./edit-route.component.scss']
})
export class EditRouteComponent implements OnInit {
  route: RouteElement;
  name: string;
  // driver: DriverElement;
  nameCtrl: FormControl;
  driverCtrl: FormControl;
  // driverOptions:DriverElement[]

  // filteredOptions: Observable<DriverElement[]>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private users: UsuariosService,
    private routes: RutasService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.nameCtrl = new FormControl('', [
      Validators.required,
      Validators.minLength(3)
    ]);
    this.driverCtrl = new FormControl('', [
      Validators.required
    ]);

    //Obtiene la ruta a editar
    this.activatedRoute.queryParams.subscribe(params => {
      //Si no existe la lista de Rutas la carga
      if (!this.routes.routesList) {
        this.routes.get().subscribe(
          (res: any) => {
            const routesList: RouteElement[] = [];
            res.forEach(element => {
              routesList.push({
                id: element.id,
                name: element.nombre,
                available: element.disponible
              });
            });
            this.routes.routesList = routesList;
            this.findRoute(params);
            this.name = this.route.name;
            // this.driver = {id: this.route.driverID, name: this.route.driver}
          }
        );
      } else {
        this.findRoute(params);
        this.name = this.route.name;
        // this.driver = {id: this.route.driverID, name: this.route.driver}
      }


    });

    //Obtiene la lista de Conductores
    // this.users.get_driver_users().subscribe(
    //   (r:any)=>{
    //     let datos:DriverElement[] = []
    //     r.forEach(element => {
    //       datos.push({id : element.id , name : element.nombre + ' ' + element.primer_apellido})
    //     });
    //     this.driverOptions = datos;
    //     this.filteredOptions = this.driverCtrl.valueChanges.pipe(
    //       startWith(''),
    //       map(value => this._filter(value)),
    //     );
    //   }
    // )
  }

  // private _filter(value: string): DriverElement[] {
  //   if (!value) return this.driverOptions
  //   const filterValue = value.toLowerCase();
  //   return this.driverOptions.filter(driver => driver.name.toLowerCase().indexOf(filterValue) === 0);
  // }

  public updateRoute() {
    this.nameCtrl.setValue(this.name);
    this.nameCtrl.updateValueAndValidity();
    const id = this.route.id;

    //Build Route Structure to Send Data
    if (this.Validate()) {
      const data = {
        nombre: this.name,
      };
      this.routes.put(id, data).subscribe(
        (res) => {
          this.router.navigate(['/admin/otros/rutas']);
          this.openDialog('Ruta Editada Exitosamente');
        });
    }
    // if(!this.DriverNotExist()){
    //   this.openDialog('No existe el Conductor!')
    // }else if(!this.UniqueRouteNameDriver()) {
    //   this.openDialog('La combinación del nombre y el conductor no es única')
    // }

  }

  private Validate() {
    if (this.nameCtrl.valid) {
return true;
}
    return false;
  }

  // private DriverNotExist():boolean{
  //   if(this.driver){
  //    let val =  this.driverOptions.findIndex(e=> e.name == this.driver.name)
  //    if (val != -1)
  //      return true
  //   }
  //   return false;
  // }
  // private UniqueRouteNameDriver():boolean{
  //   if(this.driver && this.name){
  //     let val =  this.routes.routesList.findIndex(e=> e.name == this.name && e.driverID == this.driver.id && e.id != this.route.id)
  //     if (val == -1)
  //         return true
  //   }
  //   return false;
  // }

  private findRoute(params) {
    this.route = this.routes.routesList.find(o => o.id == params.id);
  }

  // public displayDriverName(value){
  //   if (!value) return '';
  //   return value.name
  // }
  // public filterList(event){
  //   this.driverCtrl.setValue(event.target.value)
  // }
  public getErrorMessage(control) {
    if (control.hasError('required')) {
      return 'Valor requerido';
    }
    if (control.hasError('minlength')) {
      return 'Demasiado Corto';
    }
    return 'Error';
  }

  private openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }
}
