<div class="container">

  <div class="content col-12 p-5">
    <div class="row">

      <div class="content-title col-12">
        <p class="title">Información de Cuenta</p>
      </div>

      <div class="col-12 pt-4">
        <mat-accordion class="headers-align">

          <mat-expansion-panel mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Cuenta
              </mat-panel-title>
              <mat-panel-description>
                Datos Básicos
                <mat-icon>account_circle</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="inputs-form col-12" [formGroup]="formCreate" class="container">
              <div class="row">
                <div class="col-12 col-md-6 mb-4" *ngIf="tipo_cuenta == 'fisica'">
                  <label class="label label_form_field_required w-100">Nombre</label><br>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Nombre" formControlName="nombre">
                    <mat-error *ngIf="formCreate.controls['nombre'].errors">
                      {{getErrorMessage(formCreate.controls['nombre'])}}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-12 col-md-6 mb-4" *ngIf="tipo_cuenta == 'fisica'">
                  <label class="label label_form_field_required w-100">Primer Apellido</label><br>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Primer Apellido" formControlName="primer_apellido">
                    <mat-error *ngIf="formCreate.controls['primer_apellido'].errors">
                      {{getErrorMessage(formCreate.controls['primer_apellido'])}}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-12 col-md-6 mb-4" *ngIf="tipo_cuenta == 'fisica'">
                  <label class="label w-100">Segundo Apellido</label><br>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Segundo Apellido" formControlName="segundo_apellido">
                    <mat-error *ngIf="formCreate.controls['segundo_apellido'].errors">
                      {{getErrorMessage(formCreate.controls['segundo_apellido'])}}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-12 col-md-6 mb-4" *ngIf="tipo_cuenta == 'fisica'">
                  <label class="label label_form_field_required w-100">Número de Cédula</label><br>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Número de Cédula" formControlName="numero_de_cedula">
                    <mat-error *ngIf="formCreate.controls['numero_de_cedula'].errors">
                      {{getErrorMessage(formCreate.controls['numero_de_cedula'])}}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-12 col-md-6 mb-4" *ngIf="tipo_cuenta == 'juridica'">
                  <label class="label label_form_field_required w-100">Razón Social</label><br>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Razón Social" formControlName="razon_social">
                    <mat-error *ngIf="formCreate.controls['razon_social'].errors">
                      {{getErrorMessage(formCreate.controls['razon_social'])}}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-12 col-md-6 mb-4" *ngIf="tipo_cuenta == 'juridica'">
                  <label class="label label_form_field_required w-100">Número de Cédula</label><br>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Número de Cédula" formControlName="cedula_juridica">
                    <mat-error *ngIf="formCreate.controls['cedula_juridica'].errors">
                      {{getErrorMessage(formCreate.controls['cedula_juridica'])}}
                    </mat-error>
                  </mat-form-field>
                </div>


                <div class="col-12 col-md-12 mb-4" *ngIf="tipo_cuenta == 'juridica'">
                  <label class="label label_form_field_required w-100">Nombre del Contacto</label><br>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Nombre del Contacto" formControlName="representante_legal">
                    <mat-error *ngIf="formCreate.controls['representante_legal'].errors">
                      {{getErrorMessage(formCreate.controls['representante_legal'])}}
                    </mat-error>
                  </mat-form-field>
                </div>


                <div class="col-12 col-md-6 mb-4">
                  <label class="label label_form_field_required w-100">Correo</label><br>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Correo" #email formControlName="email" required
                      (blur)="checkEmail(email.value,'email')">
                    <mat-error *ngIf="formCreate.controls['email'].errors">
                      {{getErrorMessage(formCreate.controls['email'])}}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-12 col-md-6 mb-4">
                  <label class="label w-100">Correo #2</label><br>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Correo #2" formControlName="email_2" #email_2>
                    <mat-error *ngIf="formCreate.controls['email_2'].errors">
                      {{getErrorMessage(formCreate.controls['email_2'])}}
                    </mat-error>

                  </mat-form-field>
                </div>

                <div class="col-12 col-md-6 mb-4">
                  <label class="label label_form_field_required w-100">Contraseña</label><br>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput type="password" placeholder="Contraseña" formControlName="password">
                    <mat-error *ngIf="formCreate.controls['password'].errors">
                      {{getErrorMessage(formCreate.controls['password'])}}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-12 col-md-6 mb-4">
                  <label class="label label_form_field_required w-100">Confirmar contraseña</label><br>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput type="password" placeholder="Confirmar contraseña"
                      formControlName="confirm_password">
                    <mat-error
                      *ngIf="formCreate.controls['password'].value !== formCreate.controls['confirm_password'].value || formCreate.controls['confirm_password'].hasError('mustMatch')">
                      Contraseña no coinciden
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-12 col-md-6 mb-4">
                  <label class="label label_form_field_required w-100">Teléfono celular</label><br>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Teléfono celular" formControlName="telefono_movil">
                    <mat-error *ngIf="formCreate.controls['telefono_movil'].errors">
                      {{getErrorMessage(formCreate.controls['telefono_movil'])}}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div *ngIf="isClient && tipo_cuenta == 'fisica'" class="col-12 col-md-6 mb-4">
                  <label class="label w-100">Teléfono casa</label><br>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Teléfono casa" formControlName="telefono_casa">
                    <mat-error *ngIf="formCreate.controls['telefono_casa'].errors">
                      {{getErrorMessage(formCreate.controls['telefono_casa'])}}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div *ngIf="isClient" class="col-12 col-md-6 mb-4">
                  <label class="label w-100">Teléfono oficina</label><br>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Teléfono oficina" formControlName="telefono_oficina">
                    <mat-error *ngIf="formCreate.controls['telefono_oficina'].errors">
                      {{getErrorMessage(formCreate.controls['telefono_oficina'])}}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-12 col-md-6 mb-4" *ngIf="tipo_cuenta == 'fisica'">
                  <label class="label w-100">Fecha de nacimiento</label><br>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput [matDatepicker]="picker" placeholder="Fecha de nacimiento"
                      formControlName="fecha_de_nacimiento">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="col-12 col-md-6 mb-4 pt-3" *ngIf="tipo_cuenta == 'fisica'">
                  <label class="label w-100">Género</label><br>
                  <mat-radio-group class="radio-group mt-1" formControlName="genero" name="gender">
                    <mat-radio-button *ngFor="let gender of genders" [value]="gender.value" class="radio-button mr-2">
                      {{ gender.name }}
                    </mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="formCreate.controls['genero'].errors">
                    {{getErrorMessage(formCreate.controls['genero'])}}
                  </mat-error>
                </div>


                <div class="col-12 col-md-6 mb-4 pt-3">
                  <label class="label w-100">Cuenta</label><br>
                  <mat-form-field appearance="outline">
                    <input matInput placeholder="{{'Cuenta' | translate}}" formControlName="cuenta">
                    <mat-error *ngIf="formCreate.controls['cuenta'].errors">
                      {{getErrorMessage(formCreate.controls['cuenta'])}}
                    </mat-error>
                  </mat-form-field>
                </div>

              </div>
            </div>

            <mat-action-row>
              <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
            </mat-action-row>
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Entrega
              </mat-panel-title>
              <mat-panel-description>
                Datos de Entrega
                <mat-icon>map</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="inputs-general inputs-form pl-2 pr-2" [formGroup]="formCreate" class="container">

              <div class="col-12 col-md-6">
                <label class="label label_form_field_required w-100">{{'Modo de Entrega' | translate}}</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-select matInput placeholder="{{'Modo de Entrega' | translate}}" formControlName="modo_de_entrega"
                    (selectionChange)="changeMode($event.value)">
                    <mat-option *ngFor="let deliveryMode of deliveryModes" [value]="deliveryMode.value">
                      {{deliveryMode.name}}
                    </mat-option>
                  </mat-select>

                  <mat-error *ngIf="formCreate.controls['modo_de_entrega'].errors">
                    {{getErrorMessage(formCreate.controls['modo_de_entrega'])}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12 mb-2"
                *ngIf="formCreate.controls['modo_de_entrega'].value == 'ED' || formCreate.controls['modo_de_entrega'].value == 'AM'">
                <label class="label label_form_field_required w-100">{{'Dirección de entrega' | translate}}</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <input style="margin-bottom: 0px;" matInput id="direccion_entrega" name="direccion_entrega"
                    #direccion_entrega placeholder="{{'Dirección de entrega' | translate}}"
                    formControlName="direccion_entrega">

                  <mat-error *ngIf="formCreate.controls['direccion_entrega'].errors">
                    {{getErrorMessage(formCreate.controls['direccion_entrega'])}}
                  </mat-error>
                </mat-form-field>

                <ng-container
                  *ngIf="formCreate.controls['modo_de_entrega'].value == 'ED' || formCreate.controls['modo_de_entrega'].value == 'AM'">
                  <label class="label w-100"
                    [ngClass]="{'label_form_field_required': formCreate.controls['modo_de_entrega'].value == 'ED'}">
                    {{'Detalles Adicionales' | translate}}
                  </label><br>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput
                      placeholder="Ejemplo 250 metros Sur del Hospital Santa María y 25 Este, casa de dos pisos, color beige con portón negro, A22"
                      formControlName="detalles_adicionales">
                    <mat-error *ngIf="formCreate.controls['detalles_adicionales'].errors">
                      {{getErrorMessage(formCreate.controls['detalles_adicionales'])}}
                    </mat-error>
                  </mat-form-field>
                </ng-container>

              </div>

              <div class="col-12 mb-2"
                *ngIf="((formCreate.controls['modo_de_entrega'].value == 'ED' || formCreate.controls['modo_de_entrega'].value == 'AM') && (authService.isAdmin || authService.isOperacionesCostaRica) )">
                <label class="label label_form_field_required w-100">{{'Ruta' | translate}}</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-select matInput placeholder="{{'Ruta de Entrega' | translate}}" required formControlName="ruta">
                    <mat-option *ngFor="let ruta of rutas" [value]="ruta.id">
                      {{ruta.nombre}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="formCreate.controls['ruta'].errors">
                    {{getErrorMessage(formCreate.controls['ruta'])}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12 mb-2" *ngIf="formCreate.controls['modo_de_entrega'].value == 'ES'">
                <label class="label w-100">{{'Sucursal' | translate}}</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-select matInput placeholder="{{'Sucursal' | translate}}" formControlName="sucursal"
                    (selectionChange)="asignarSucursal($event)">
                    <mat-option value="Limón">
                      Limón
                    </mat-option>
                    <mat-option value="Guadalupe">
                      Guadalupe
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="formCreate.controls['sucursal'].errors">
                    {{getErrorMessage(formCreate.controls['sucursal'])}}
                  </mat-error>
                </mat-form-field>

              </div>

              <div class="col-12 mb-2"
                *ngIf="formCreate.controls['modo_de_entrega'].value == 'ED' || formCreate.controls['modo_de_entrega'].value == 'AM'">
                <div>
                  <label class="label_form_field_required">{{'Provincia' | translate}}</label><br>
                  <mat-form-field appearance="outline">
                    <mat-select matInput placeholder="Seleccione Provincia" formControlName="provincia"
                      (valueChange)="getCantones($event);" #provinciaInput>
                      <mat-option *ngFor="let provincia of provincias" [value]="provincia.id">
                        {{provincia.provincia}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formCreate.controls['provincia'].errors">
                      {{getErrorMessage(formCreate.controls['provincia'])}}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div>
                  <label class="label_form_field_required">{{'Cantón' | translate}}</label><br>
                  <mat-form-field appearance="outline">
                    <mat-select matInput placeholder="Seleccione Cantón" formControlName="canton"
                      (valueChange)="getDistritos(provinciaInput.value,$event); ">
                      <mat-option *ngFor="let canton of cantones" [value]="canton.id">
                        {{canton.canton}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formCreate.controls['canton'].errors">
                      {{getErrorMessage(formCreate.controls['canton'])}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="col-12 mb-2"
                *ngIf="formCreate.controls['modo_de_entrega'].value == 'ED' || formCreate.controls['modo_de_entrega'].value == 'AM'">
                <div>
                  <label class="label_form_field_required">{{'Distrito' | translate}}</label><br>
                  <mat-form-field appearance="outline">
                    <mat-select matInput placeholder="Seleccione Distrito" formControlName="distrito">
                      <mat-option *ngFor="let distrito of distritos" [value]="distrito.id">
                        {{distrito.distrito}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formCreate.controls['distrito'].errors">
                      {{getErrorMessage(formCreate.controls['distrito'])}}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div>
                  <span *ngIf="getDistritoTipo(formCreate.controls['distrito']?.value) == 'v'" class="green">
                    {{'Entrega Gratuita Disponible' | translate}}
                  </span>
                  <span *ngIf="getDistritoTipo(formCreate.controls['distrito']?.value) == 'a'" class="yellow">
                    {{'Comuníquese con atención al cliente para entregas a esta zona' | translate}}
                  </span>
                  <span *ngIf="getDistritoTipo(formCreate.controls['distrito']?.value) == 'r'" class="red">{{'Entrega
                    con
                    Costo Adicional' | translate}}</span>
                </div>
              </div>

              <div class="col-12 mb-2"
                *ngIf="getDistritoTipo(formCreate.controls['distrito']?.value) == 'a' && (authService.isAdmin || authService.isOperacionesCostaRica)">
                <mat-checkbox formControlName="autoriza_entrega">Autorizar Entrega</mat-checkbox>
              </div>

              <div class="col-12 mb-2">
                <label class="label label_form_field_required w-100">{{'Método de Pago Predeterminado' |
                  translate}}</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-select matInput placeholder="Método de Pago Predeterminado" formControlName="metodo_pago">
                    <mat-option *ngFor="let metodo of metodosPagoPreviaEntrega" [value]="metodo.id">
                      {{metodo.nombre}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="formCreate.controls['metodo_pago'].errors">
                    {{getErrorMessage(formCreate.controls['metodo_pago'])}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <mat-action-row>
              <button mat-button color="warn" (click)="prevStep()">Anterior</button>
              <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
            </mat-action-row>
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Factura Electrónica
              </mat-panel-title>
              <mat-panel-description>
                Datos para Facturación Electrónica
                <mat-icon>text_snippet</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>


            <div class="inputs-general inputs-form pt-5 pl-5 pr-5" [formGroup]="formFactura" class="form">
              <div class="inputs-50">
                <label>Tipo de Persona</label><br>

                <mat-radio-group aria-label="Seleccione" formControlName="tipo_factura">
                  <mat-radio-button value="FIS">Física</mat-radio-button>
                  <mat-radio-button style="margin-left: 10px;" value="JUR">Jurídica</mat-radio-button>
                </mat-radio-group>

              </div>

              <div class="inputs-50" *ngIf="formFactura.controls.tipo_factura.value  == 'JUR'">
                <div>
                  <label>Razón Social</label><br>
                  <mat-form-field appearance="outline">
                    <input matInput placeholder="Razón Social" formControlName="razon_social">
                  </mat-form-field>
                </div>
              </div>

              <div class="inputs-50" *ngIf="formFactura.controls.tipo_factura.value == 'FIS'">
                <div>
                  <label>Nombre</label><br>
                  <mat-form-field appearance="outline">
                    <input matInput placeholder="Nombre" formControlName="nombre">
                  </mat-form-field>
                </div>

                <div>
                  <label>Apellido 1</label><br>
                  <mat-form-field appearance="outline">
                    <input matInput placeholder="Apellido 1" formControlName="primer_apellido">
                  </mat-form-field>
                </div>
              </div>

              <div class="inputs-50">
                <div *ngIf="formFactura.controls.tipo_factura.value == 'FIS'">
                  <label>Apellido 2</label><br>
                  <mat-form-field appearance="outline">
                    <input matInput placeholder="Apellido 2" formControlName="segundo_apellido">
                  </mat-form-field>
                </div>

                <div
                  *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'JUR'">
                  <label>Número de Cédula</label><br>
                  <mat-form-field appearance="outline">
                    <input matInput placeholder="Número de Cédula" formControlName="numero_identificacion" numeric
                      decimals="0">
                  </mat-form-field>
                </div>
              </div>

              <div class="inputs-50"
                *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'JUR'">
                <div>
                  <label>Teléfono</label><br>
                  <mat-form-field appearance="outline">
                    <input matInput placeholder="Teléfono" formControlName="telefono">
                  </mat-form-field>
                </div>

                <div>
                  <label>Correo Electrónico</label><br>
                  <mat-form-field appearance="outline">
                    <input matInput placeholder="Correo Electrónico" formControlName="correo">
                  </mat-form-field>
                </div>

              </div>

              <div class="inputs"
                *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'JUR'">
                <div>
                  <label>Dirección de facturación</label><br>
                  <mat-form-field appearance="outline">
                    <input matInput placeholder="Dirección" formControlName="direccion">
                  </mat-form-field>
                </div>
              </div>

              <div class="inputs-50"
                *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'JUR'">
                <div>
                  <label>Provincia</label><br>
                  <mat-form-field appearance="outline">
                    <mat-select matInput placeholder="Seleccione Provincia" required formControlName="provincia"
                      (valueChange)="getCantones($event)" #provinciaInput>
                      <mat-option *ngFor="let provincia of provincias" [value]="provincia.id">
                        {{provincia.provincia}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div>
                  <label>Cantón</label><br>
                  <mat-form-field appearance="outline">
                    <mat-select matInput placeholder="Seleccione Cantón" required formControlName="canton"
                      (valueChange)="getDistritos(provinciaInput.value,$event)">
                      <mat-option *ngFor="let canton of cantones" [value]="canton.id">{{canton.canton}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="inputs-50"
                *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'JUR'">
                <div>
                  <label>Distrito</label><br>
                  <mat-form-field appearance="outline">
                    <mat-select matInput placeholder="Seleccione Distrito" required formControlName="distrito">
                      <mat-option *ngFor="let distrito of distritos" [value]="distrito.id">{{distrito.distrito}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <mat-action-row>
              <button mat-button color="warn" (click)="prevStep()">Anterior</button>
              <button mat-button color="primary" class="button-calcular" (click)="createUser()">Guardar</button>
            </mat-action-row>

          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <div class="col-12 col-md-12" style="text-align: right;">

      </div>

    </div>
  </div>
</div>