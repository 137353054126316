<h2 class="h2-title p-4">Alianzas</h2>

<div class="content" fxLayout="column">
  <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones justify-content-center;">
    <div class="row" style="width: 100%;">
      <div class="col-sm botones-opciones">

      </div>
      <div class="col-sm text-right botones-opciones">
        <button (click)="crearAlianza()" mat-button>AGREGAR ALIANZA</button>
        <span class="texto-encontrados">{{length}} Alianzas encontradas</span>
      </div>

    </div>

  </div>

  <mat-divider></mat-divider>

  <div class="content-table mr-4 ml-4 mb-4">
    <table mat-table [dataSource]="dataSource.data" matSort matSortDisabled>
      <ng-container matColumnDef="id">
        <th mat-header-cell class="pr-pl-20 text-center" *matHeaderCellDef mat-sort-header> Alianza </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> <a
            [routerLink]="['/admin/administracion/alianzas/alianza']"
            [queryParams]="{alianza: element.id}">{{element.id}}</a></td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Nombre </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.nombre}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Descripcion / Notas </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.descripcion}} </td>
      </ng-container>


      <!--acciones-->
      <ng-container matColumnDef="acciones">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Acciones</th>
        <td mat-cell *matCellDef="let element" class="action-link" style="text-align: center;">
          <mat-icon style="cursor: pointer;" (click)="disableAlianza(element.id)">delete</mat-icon>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }"></tr>
    </table>
  </div>
</div>