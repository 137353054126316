<div class="p-3 content" fxLayout="column">
  <div style="padding-top: 0;" class="content-title">
    <p class="title">Tipo de Cambio - Insertando nuevos datos</p>
  </div>

</div>

<div class="mt-4 pt-4"
  style="width: 100%; text-align: center; font-family: Varela Round, sans-serif; font-size: 18px !important;">
  <button mat-button [routerLink]="['/admin/otros/']"
    style="padding: 0; padding-right: 10px; color: #0094a3; float: left;">
    <mat-icon>keyboard_arrow_left</mat-icon> Atras
  </button>

  <button mat-button submit style="float: right;" (click)="create()">
    <mat-icon>save</mat-icon>Guardar
  </button>
</div>

<div class="row mt-4">
  <form [formGroup]="form" fxLayout="row wrap" fxLayoutGap="20px" class="pt-3" style="display: flex; align-items: center;">
    <mat-form-field appearance="outline">
      <mat-label>Valor</mat-label>
      <input matInput placeholder="Valor" formControlName="value">
      <mat-error *ngIf="form.controls['value'].errors">
        {{getErrorMessage(form.controls['value'])}}
      </mat-error>
    </mat-form-field>
  </form>
</div>
