<div class="pt-3 pb-3" style="display: flex; align-items: center; justify-content: space-between;">
  <div style="width: 100%;">
    <button mat-button [routerLink]="['/admin/almacen/embarques']" style="padding: 0; padding-right: 10px; color: #0094a3; float: left;">
      <mat-icon>keyboard_arrow_left</mat-icon> EMBARQUES
    </button>
  </div>
</div>

<div class="content" fxLayout="column">

  <form [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutGap="20px" class="pr-4 pl-4 pt-3 mt-2 inputs-form"
      style="display: flex; align-items: center;">
      <div>
        <label class="label">ID</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="ID" class="input-field" formControlName="id" type="number">
        </mat-form-field>
      </div>

      <div>
        <label class="label">Fecha:</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput [matDatepicker]="picker" placeholder="Fecha" class="input-field" formControlName="creacion">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div>
        <label class="label">Tipo de Embarque</label><br>
        <mat-form-field appearance="outline" >
          <mat-select formControlName="travel_type" placeholder="Seleccione">
            <mat-option value="1">Aéreo</mat-option>
            <mat-option value="2">Marítimo</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="align-self-end pb-2">
        <button mat-button (click)="filterTable()">
          <img src="assets/iconos-dashboard/Lupa.png">Buscar
        </button>
        <button mat-button (click)="cleanFilters()">
          <img src="assets/iconos-dashboard/Facturas.png">Limpiar
        </button>
      </div>
    </div>
  </form>

  <div class="content-table m-4">
    <table mat-table [dataSource]="dataSource | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length}" matSort (matSortChange)="sortData($event)">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Seleccionar </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">{{row.id}}</mat-checkbox>
        </td>
      </ng-container>

      <!-- id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header>Embarque</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <a [routerLink]="['/admin/almacen/embarques/agregar']" [queryParams]="{pack: element.id}">
            {{element.id ? element.id : '-'}}
          </a>
        </td>
      </ng-container>

      <!-- awb Column -->
      <ng-container matColumnDef="awb">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> AWB/BL </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.awb ? element.awb : '-'}} </td>
      </ng-container>

      <!-- cweight Column -->
      <ng-container matColumnDef="cweight">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Peso Real</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{ element.charge_weight | number:'1.2-2' || '-' }} </td>
      </ng-container>

      <!-- refdate Column -->
      <ng-container matColumnDef="refdate">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.refdate | date:'dd-MM-yyyy'}} </td>
      </ng-container>

      <!-- file Column -->
      <ng-container matColumnDef="file">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Archivo </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.file ? element.file : '-'}} </td>
      </ng-container>

      <!-- vessel Column -->
      <ng-container matColumnDef="vessel">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Vuelo/Embarcación </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.vessel ? element.vessel : '-'}}
        </td>
      </ng-container>

      <!-- gross Column -->
      <ng-container matColumnDef="gross">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header>Peso Volumen</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.gross_weight | number:'1.2-2' || '-' }} </td>
      </ng-container>

      <!-- pieces Column -->
      <ng-container matColumnDef="pieces">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Piezas </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.pieces ? element.pieces : '-'}}
        </td>
      </ng-container>

      <!-- notes Column -->
      <ng-container matColumnDef="notes">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Notas </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.notes ? element.notes : '-'}}
        </td>
      </ng-container>

      <!-- packages Column -->
      <ng-container matColumnDef="packages">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Paquetes </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.packages ? element.scanned + '/' +element.packages :
          '-'}} </td>
      </ng-container>

      <!-- scanned Column -->
      <ng-container matColumnDef="scanned">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Paquetes Escaneados </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.scanned ? element.scanned : '-'}} / {{element.packages ? element.packages : '-'}}
        </td>
      </ng-container>

      <!-- services Column -->
      <ng-container matColumnDef="services">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Vía </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.services == 1 ? 'Aereo' :
          element.services == 2 ? 'Marítimo' : ''}} </td>
      </ng-container>

      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Estado </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.status ? element.status : '-'}}
        </td>
      </ng-container>

      <!-- bultos Column -->
      <ng-container matColumnDef="bultos">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Total bultos </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.bultos ? element.bultos : '-'}}
        </td>
      </ng-container>

      <!-- total Column -->
      <ng-container matColumnDef="total">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Total </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.total ? element.total : '-'}}
        </td>
      </ng-container>

      <!-- user Column -->
      <ng-container matColumnDef="user">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Usuario </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.user ? element.user : '-'}} </td>
      </ng-container>

      <!-- print Column -->
      <ng-container matColumnDef="print">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Imprimir </th>
        <td style="padding-top: 5px;" mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; "  [ngClass]="{'highlight': odd }"></tr>
    </table>
    <div class="text-center" *ngIf="dataSource.length == 0">
      <p>No hay embarques disponibles</p>
    </div>
  </div>

  <div class="paginator text-center">
    <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior"  nextLabel="Siguiente" (pageBoundsCorrection)="pageIndex = $event; paginate(pageIndex)" (pageChange)="pageIndex = $event; paginate(pageIndex)"></pagination-controls>
  </div>
</div>
