export enum ActionsConstants {
    DELETE_PREALERTS='DELETE_PREALERTS', 
    CHANGE_PACKAGE_ACCOUNT='CHANGE_PACKAGE_ACCOUNT',
    CHANGE_CLIENT_DIRECTION='CHANGE_CLIENT_DIRECTION', 
    ADD_DISCOUNT = 'ADD_DISCOUNT', 
    REMOVE_DISCOUNT= 'REMOVE_DISCOUNT', 
    EDIT_BILL = 'EDIT_BILL',
    EDIT_CREDIT_CARD = 'EDIT_CREDIT_CARD', 
    REMOVE_CREDIT_CARD = 'REMOVE_CREDIT_CARD', 
    MODIFY_CLIENT_AUTOMATIC_CHARGE = 'MODIFY_CLIENT_AUTOMATIC_CHARGE', 
    CONNECT_AS_CLIENT = 'CONNECT_AS_CLIENT', 
    EMIT_NC_ND = 'EMIT_NC_ND',
    EMIT_FE = 'EMIT_FE',
    DELETE_PACKAGE = 'DELETE_PACKAGE',
    DELETE_ACCOUNT = 'DELTE_ACCOUNT',
    MODIFY_INVOICE = 'MODIFY_INVOICE',
    EXONERATE_CHARGE = 'EXONERATE_CHARGE',
    ADD_PARCIAL_PAYMENT = 'ADD_PARCIAL_PAYMENT',
    CHARGE_DELMAN = 'CHARGE_DELMAN',
    CHECK_ACCOUNT_STATE = 'CHECK_ACCOUNT_STATE',
    ACCOUNT_OPTIONS = 'ACCOUNT_OPTIONS',
    VIEW_SHIPMENTS = 'VIEW_SHIPMENTS',
    CREATE_SHIPMENTS = 'CREATE_SHIPMENTS',
    ATTACH_PACKAGES = 'ATTACH_PACKAGES',
    EDIT_ITEMS = 'EDIT_ITEMS',
    SCHEDULE_PACKAGES = 'SCHEDULE_PACKAGES',
    PAYMENT_METHODS = 'PAYMENT_METHODS',
    SHOW_DELIVERY_OPTIONS = 'SHOW_DELIVERY_OPTIONS',
    ADD_RATE = 'ADD_FEE',
    EDIT_RATE = 'EDIT_FEE',
}