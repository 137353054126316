import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class FacturaElectronicaService extends BaseService {

  constructor(http: HttpClient) {
    super(http, 'factura-electronica');
   }

  getFacturas() {
    return this.http.get(this.joinUrl());
  }

  emitInvoice(id: number, type: number) {
    return this.post(this.joinUrl('emit-invoice', id), { type });
  }

  checkEmailState(id: number) {
    return this.get(this.joinUrl('check-email-state', id));
  }

  resendInvoice(id: number, email: string) {
    return this.post(this.joinUrl('resend-invoice', id), { email });
  }

  downloadInvoice(id: number) {
    return this.get(this.joinUrl('download-invoice', id), { responseType: 'blob' });
  }

  emitCreditNote(id: number, type: number) {
    const body = type ? { type } : {};
    return this.post(this.joinUrl('emit-credit-note', id), body);
  }

  emitDebitNote(id: number) {
    return this.post(this.joinUrl('emit-debit-note', id));
  }

}
