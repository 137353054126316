<div class="content-item">
  <div class="mt-5">
    <p class="text-right" *ngIf="tipoCambio > 0 "><strong>Tipo de Cambio: </strong>{{ tipoCambio |
      currency:'CRC':'symbol-narrow':'1.2-2' }}</p>

    <table *ngIf="!authService.isServicioCliente">
      <thead>
        <tr>
          <td>Cantidad</td>
          <td>Descripción</td>
          <td>Precio Unitario</td>
          <td>Partida</td>
          <td>Impuesto</td>
          <td>Porcentaje</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of paqueteItems; let i = index">
          <td>
            <mat-form-field class="px-1" appearance="outline" style="display: block !important;">
              <input class="text-center" matInput [(ngModel)]="paqueteItems[i].cantidad" numeric decimals="0">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="px-1" appearance="outline" style="display: block !important;">
              <textarea (ngModelChange)="getItemsSearch($event); selectedIndex = i" rows="3" style="width: 100%;"
                matInput [(ngModel)]="paqueteItems[i].descripcion"></textarea>

              <div class="suggestions" style="height: 100px; overflow: scroll" *ngIf="selectedIndex == i">
                <p style="cursor: pointer;" (click)="paqueteItems[i].descripcion = s.descripcion; options=[]"
                  *ngFor="let s of options">{{s.descripcion}}</p>
              </div>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="px-1" appearance="outline" style="display: block !important;">
              <input class="text-center" matInput [(ngModel)]="paqueteItems[i].precio" numeric decimals="2">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="px-1" appearance="outline" style="display: block !important;">
              <input class="text-center" matInput [(ngModel)]="paqueteItems[i].partida">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="px-1" appearance="outline" style="display: block !important;">
              <input class="text-center" matInput readonly [(ngModel)]="paqueteItems[i].impuesto" numeric decimals="2">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="px-1" appearance="outline" style="display: block !important;">
              <input class="text-center" matInput readonly [(ngModel)]="paqueteItems[i].porcentaje" numeric
                decimals="2">
            </mat-form-field>
          </td>

          <td *ngIf="showButtons">
            <button class="del-item" mat-button (click)="deleteItems(i)"
              *ngIf="authService.isAdmin || authService.isPedimentacion || authService.isOperacionesCostaRica || authService.isOperacionesMiami">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td></td>
          <td class="text-center"><b>Total: {{totalItems | number:'1.2-2' }}</b></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  </div>

  <mat-divider></mat-divider>

  <div [class.m-div]="!column" class="content-table mt-2 mb-2" *ngIf="showButtons">
    <div class="text-right" style="float: right;"
      *ngIf="authService.isAdmin || authService.isPedimentacion || authService.isOperacionesCostaRica || authService.isOperacionesMiami">
      <button mat-flat-button color="primary" class="mx-1 button-calcular" (click)="addItems()">
        Agregar item(s)
      </button>

      <button mat-flat-button color="primary" class="mx-1 button-calcular" (click)="saveItems()">
        Guardar item(s)
      </button>
    </div>

  </div>
</div>