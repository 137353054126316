<div class="container-example" fxLayout="column" fxLayoutGap="10px"
  style="margin-left: auto;margin-right: auto; width:100%; box-shadow: 0px 8px 25px rgba(0, 148, 163, 0.12); background-color: white;">

  <div class="content-title mt-4 mb-4">
    <p class="title">Buscar Tarjeta de Crédito</p>
  </div>

  <div class="container-example mt-4 pt-4">

    <div class="content-table m-4 pt-4">

      <mat-form-field appearance="outline">
        <mat-label>Buscar</mat-label>
        <input class="w-75 d-inline" matInput #search (keyup.enter)="buscarTarjeta(search.value)">

        <button  class="w-25 d-inline" mat-button (click)="buscarTarjeta(search.value)" style="padding: 0; margin-right: 0px; color: #0094a3;">
          BUSCAR <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>

      <table mat-table [dataSource]="tarjetas" class="mat-elevation-z8" *ngIf="tarjetas.length > 0">

        <ng-container matColumnDef="tarjeta">
          <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Tarjeta </th>
          <td class="pr-pl-20" mat-cell *matCellDef="let element"> {{element.PaddedCardNo}} </td>
        </ng-container>

        <ng-container matColumnDef="cuenta">
          <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Cuenta </th>
          <td class="pr-pl-20" mat-cell *matCellDef="let element"> <a [routerLink]="['/admin/perfil']"
              [queryParams]="{cuenta: element.user.id}" style="cursor: pointer;">
              <mat-icon mat-list-icon>person</mat-icon> {{usuariosService.get_account_number(element.user)}}
            </a> </td>
        </ng-container>

        <ng-container matColumnDef="nombre">
          <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Cliente </th>
          <td class="pr-pl-20" mat-cell *matCellDef="let element"> {{usuariosService.get_fullname(element.user)}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsClientes"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsClientes;"></tr>
      </table>
    </div>


  </div>
</div>


