<div class="p-3 content" style="min-width: 80vw !important" fxLayout="column">

  <div class="content-title">
    <p class="title">Notificaciones Push</p>
  </div>

  <div class="inputs-form mt-5 pt-4">
    <div>
      <label class="label">Titulo</label><br>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="title" placeholder="Título">
      </mat-form-field>
    </div>

    <div>
      <label class="label">Asunto</label><br>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="asunto" placeholder="Asunto">
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-12">
      <label class="label">Destinatarios</label><br>
      <mat-form-field appearance="outline">
        <mat-chip-list #chipList aria-label="Destinatarios">
          <mat-chip *ngFor="let destinatario of destinatarios" (removed)="remove(destinatario)">
            {{destinatario.name}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input placeholder="Seleccione" #destinatarioInput [formControl]="destinatarioCtrl" [matAutocomplete]="auto"
            [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" #cuenta (keyup)="buscarListaClientes($event, cuenta.value)">
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="displayFn">
          <mat-option *ngFor="let destinatario of filteredDestinatarios | async" [value]="destinatario">
            {{destinatario.name}}
          </mat-option>
        </mat-autocomplete>

      </mat-form-field>
    </div>
  </div>

  <div>
    <button  style="float: right;" mat-flat-button class="button-calcular" color="primary" (click)="send()">Enviar</button>
  </div>

</div>
