/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { ConsolidationModalComponent } from '../../../components/consolidation-modal/consolidation-modal.component';
import { NewPackageComponent } from '../../../components/newpackage/newpackage.component';
import { ConfirmarComponent } from 'src/app/modules/common/dialogs/confirmar/confirmar.component';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';

@Component({
  selector: 'app-consolidacion',
  templateUrl: './consolidacion.component.html',
  styleUrls: ['./consolidacion.component.scss']
})
export class ConsolidacionComponent implements OnInit {

  paquetes: any;
  loading: boolean;

  constructor(
    private paquetesService: PaquetesService,
    public usuariosService: UsuariosService,
    private dialog: MatDialog,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.loading = true;
    this.paquetesService.getPendingConsolidate().subscribe(data => {
      this.paquetes = data;
      this.loading = false;
      //console.log('consolidados',this.paquetes)
    });
  }
  openModalConsolidation(data) {
    const dialogRef = this.dialog.open(ConsolidationModalComponent, {
      data,
      width: '500',
      panelClass: 'consolidation'
    });

    dialogRef.afterClosed().subscribe(value => {

    });

  }

  openDialogNuevoPaquete(tracking, isNew, id, paquete, consolidar, addls) {
    //console.log('open new package', tracking, isNew, id);
    const dialogRef = this.dialog.open(NewPackageComponent, {
      width: '90%',
      panelClass: 'newpackage',
      data: { tracking, isNew, id, consolidar, addls, paquete }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paquetesService.getPendingConsolidate().subscribe(data => {
          this.paquetes = data;
        });
      }
      //console.log(`Dialog result: ${result}`);
    });
  }

  undoConsolidate(paquete) {
    //console.log(paquete);

    const dialogRef = this.dialog.open(ConfirmarComponent, {
      data: { message: '¿Confirma que desea deshacer este consolidado?.' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        paquete.paquete_contenedor.forEach((element, index, array) => {
          const data = {
            id: element.id,
            estado_actual: element.estado_historial[element.estado_historial.length - 2].estado.id,
            paquete_contenedor: 'False',
            consolidado: false,
            logged_user: this.authService.getUser().id,
            bulto: null
          };

          this.paquetesService.put(element.id, data).subscribe(() => {

            if (index == array.length - 1) {

              this.paquetesService.deletePaquete(paquete.id).subscribe(() => {
                this.openDialog('Consolidado Eliminado');
                this.ngOnInit();
              }, err => {
                this.openDialog('Consolidado Eliminado');
                this.ngOnInit();
              });

            }

          });
        });
      }
    });
  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterClosed().subscribe(result => {

    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }

  getClientType(pkg) {
    return pkg?.usuario?.tipo_cliente?.[0]?.nombre || ' ';
  }

}
