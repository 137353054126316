<div class="content-pa" fxLayout="column">
  <form [formGroup]="form">
    <div class="row ml-2 mt-4">
      <div class="col-md-2">
        <label class="label mb-2">Cuenta</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Cuenta" formControlName="cuenta" class="input-field">
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <label class="label mb-2">Guía</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Guía" formControlName="guia" class="input-field">
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <label class="label mb-2">Tipo de Paquete</label><br>
        <ng-select formControlName="tipo_paquete" placeholder="Seleccione" [selectOnTab]="true"
          (change)="filterTable()">
          <ng-option value="Caja">Caja</ng-option>
          <ng-option value="SobreBolsa">Bolsa</ng-option>
          <ng-option value="Correspondencia">Correspondencia</ng-option>
        </ng-select>
      </div>
      <div class="col-md-3">
        <label class="label mb-2">Estado</label><br>
        <ng-select class="input-field" formControlName="estado" name="action" placeholder="Seleccione"
          (change)="filterTable()" [selectOnTab]="true">
          <ng-option *ngFor="let act of estados_paquetes" [value]="act.id">
            {{ act.estado }}
          </ng-option>
        </ng-select>
      </div>
      <div class="col-md-3">
        <label class="label mb-2">Tipo de Paquete</label><br>
        <ng-select formControlName="clase" placeholder="Seleccione" [selectOnTab]="true" (change)="filterTable()">
          <ng-option value="A">A</ng-option>
          <ng-option value="B">B</ng-option>
          <ng-option value="C">C</ng-option>
          <ng-option value="D">D</ng-option>
        </ng-select>
      </div>
    </div>
    <div class="row ml-2 mt-1">
      <div class="col-md-3">
        <label class="label mb-2">Tipo de cliente</label><br>
        <ng-select class="input-field" formControlName="sin_identificar" name="action" placeholder="Seleccione"
          (change)="filterTable()" [selectOnTab]="true" bindValue="id" bindLabel="nombre" [items]="client_types">
        </ng-select>
      </div>
      <div class="col-md-2">
        <label class="label mb-2">Fecha de Inicio</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Fecha de Inicio" formControlName="creacion" type="date" class="input-field">
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <label class="label mb-2">Fecha de Finalización</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Fecha de Finalización" formControlName="creacion2" type="date"
            class="input-field">
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <label class="label mb-2">Facturados</label><br>
        <mat-checkbox [checked]="facturados" (change)="facturados = !facturados"></mat-checkbox>
      </div>

    </div>
  </form>
  <mat-divider></mat-divider>

  <div class="botones">
    <div class="px-4 text-right" style="float: right; ">
      <button mat-button (click)="filterTable()">
        <img src="assets/iconos-dashboard/Lupa.png">Buscar
      </button>
      <button mat-button (click)="cleanFilters()">
        <img src="assets/iconos-dashboard/Facturas.png">Limpiar
      </button>

      <button mat-button (click)="exportTable(exporter)">
        <mat-icon>print</mat-icon>Imprimir
      </button>
    </div>

  </div>

  <div class="content-table m-4">
    <div class="text-center" *ngIf="dataSource.data.length == 0">
      <p>No hay paquetes disponibles para mostrar</p>
    </div>

    <table mat-table matTableExporter #exporter="matTableExporter"
      [dataSource]="dataSource.data | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length}"
      matSort (matSortChange)="sortData($event)">

      <!-- guia Column -->
      <ng-container matColumnDef="guia">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Guía </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: element.id}">
            {{element.guia? element.guia: '-'}}
          </a>
        </td>
      </ng-container>
      <!-- tipo_cliente Column -->
      <ng-container matColumnDef="tipo_cliente">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Tipo de Cliente </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <span *ngFor="let tipo of element.tipo_cliente" class="m-2 p-2 d-block"
            style="border-radius: 15px; font-size:16px !important; color: white !important;"
            [style.background-color]="tipo.color">{{
            tipo.nombre | translate}}</span>
        </td>
      </ng-container>
      <!-- estado Column -->
      <ng-container matColumnDef="estado">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Estado</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.estado? element.estado: '-'}}
        </td>
      </ng-container>

      <!-- envio Column -->
      <ng-container matColumnDef="nombre">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Nombre de Usuario </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.nombre? element.nombre : '-'}}
        </td>
      </ng-container>

      <!-- envio Column -->
      <ng-container matColumnDef="correo">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Correo Electrónico </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.email ? element?.email : '-'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="tipo_paquete">
        <th mat-header-cell class="pr-pl-20 text-center" *matHeaderCellDef> {{'Tipo Paquete' | translate}} </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">

          <div *ngIf="print == false">

            <span *ngIf="element.tipo_paquete === 'Caja'"><fa-icon style="font-size:x-large; color: #119ea8"
                [icon]="faArchive"></fa-icon></span>
            <span *ngIf="element.tipo_paquete === 'SobreBolsa'"><fa-icon style="font-size:x-large; color: #119ea8"
                [icon]="faShoppingBag"></fa-icon></span>
            <span *ngIf="element.tipo_paquete === 'Correspondencia'"><fa-icon style="font-size:x-large; color: #119ea8"
                [icon]="faEnvelope"></fa-icon></span>

          </div>

          <div *ngIf="print == true">
            {{element.tipo_paquete}}
          </div>

        </td>
      </ng-container>

      <!-- cuenta Column -->
      <ng-container matColumnDef="cuenta">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cuenta </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: element.usuarioid}">{{element.cuenta?
            element.cuenta : '-'}}-{{element.nombre? element.nombre : '-'}}</a>
        </td>
      </ng-container>

      <!-- tracking Column -->
      <ng-container matColumnDef="tracking">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Tracking </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.tracking? element.tracking :
          '-'}} </td>
      </ng-container>

      <!-- descripcion Column -->
      <ng-container matColumnDef="descripcion">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Descripción </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.descripcion? element.descripcion : '-'}}
        </td>
      </ng-container>

      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.status? element.status : '-'}}
        </td>
      </ng-container>

      <!-- envio Column -->
      <ng-container matColumnDef="envio">
        <th class="pr-pl-20" mat-header-cell *matHeaderCellDef>{{'Método' | translate}}</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">

          <div *ngIf="print == false">
            <mat-icon style="color: #119ea8 ;" *ngIf="element.envio == 'A'">airplanemode_active</mat-icon>
            <mat-icon style="color: #119ea8 ;" *ngIf="element.envio == 'M'">directions_boat</mat-icon>
            <mat-icon style="color: #119ea8 ;" *ngIf="element.envio == 'C'">front_hand</mat-icon>
          </div>

          <div *ngIf="print == true">
            <span *ngIf="element.envio == 'A'">Super Rápido (Aéreo)</span>
            <span *ngIf="element.envio == 'M'">Super Económico (Marítimo)</span>
            <span *ngIf="element.envio == 'C'">Super Compacto (Consolidado)</span>
          </div>

        </td>
      </ng-container>

      <ng-container matColumnDef="creacion">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha de Creacion</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.creacion.toLocaleDateString('es-CR', { weekday: 'short', year: 'numeric', month: 'short', day:
          'numeric' })}}
        </td>
      </ng-container>

      <ng-container matColumnDef="modificado">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Última actualización</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.ultima_actualizacion.toLocaleDateString('es-CR', { weekday: 'short', year: 'numeric', month:
          'short', day: 'numeric' })}} - {{element.estado? element.estado: '-'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="usuario">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Usuario</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.ultimo_usuario ? element.ultimo_usuario : ''}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true" [ngClass]="{'highlight-top-header': true }">
      </tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }"></tr>
    </table>
  </div>

  <div class="paginator text-center">
    <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
      (pageBoundsCorrection)="pageIndex = $event; paginate(pageIndex)"
      (pageChange)="pageIndex = $event; paginate(pageIndex)"></pagination-controls>
  </div>

  <mat-divider></mat-divider>

  <div class="p-4 botones-content-footer">
    <!-- <button mat-flat-button color="primary" class="button-calcular">Imprimir Etiqueta</button>
    <button mat-flat-button color="primary" class="button-calcular">Imprimir/Ver Factura</button>
    <button class="botones-content-footer-600" mat-button [matMenuTriggerFor]="menu" aria-label="More options"
      style="display: none;">
      <mat-icon style="margin: 0 !important;">more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>Imprimir Etiqueta</button>
      <button mat-menu-item>Imprimir/Ver Factura</button>
    </mat-menu> -->
  </div>
</div>
