<div class="content" fxLayout="column">
  <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones">
    <div *ngIf="authService.validateRolePermission('SHOW_DELIVERY_OPTIONS')" class="botones-opciones">

      <button mat-button (click)="crearEntrega()">CREAR ENTREGAS</button>
      <button mat-button (click)="adjuntarPaquetes()">ADJUNTAR PAQUETES</button>

      <button mat-button [routerLink]="['list']">VER ENTREGAS</button>
      <button mat-button *ngIf="authService.validateRolePermission('SCHEDULE_PACKAGES')" [routerLink]="['programar']">PROGRAMAR PAQUETES</button>
      <button mat-button [routerLink]="['/admin/otros/rutas']">RUTAS</button>
      <button mat-button [routerLink]="['transportistas']">TRANSPORTISTAS</button>
      <button mat-button [routerLink]="['/admin/almacen/capturar-zona']">ASIGNAR ZONA</button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="col-12 p-4"
    style="margin-left: auto; margin-right: auto; width: auto;  box-shadow: 0px 8px 25px rgba(0, 148, 163, 0.12);">
    <h4>Entregas del día</h4>

    <div class="d-block">
      <ng-container *ngFor="let driver of drivers">
        <mat-card *ngIf="getSummaryEntregaID(driver.id) != 0" [queryParams]="{pack: getSummaryEntregaID(driver.id)}"
          [routerLink]="['/admin/almacen/entregas/agregar']" style="cursor: pointer;"
          class="mt-2 mx-2 d-block d-md-inline-block">
          <mat-card-header>
            <mat-icon mat-card-avatar style="color:purple">local_shipping</mat-icon>
            {{driver.nombre}} <br>
            Entregados/Total : {{getSummary(driver.id)}}
          </mat-card-header>
        </mat-card>

        <mat-card *ngIf="getSummaryEntregaID(driver.id) == 0" [queryParams]="{conductor: driver.id}"
          [routerLink]="['/admin/almacen/entregas/agregar']" style="cursor: pointer;"
          class="mt-2 mx-2 d-block d-md-inline-block">
          <mat-card-header>
            <mat-icon mat-card-avatar style="color:purple">local_shipping</mat-icon>
            {{driver.nombre}} <br>
            Entregados/Total : {{getSummary(driver.id)}}
          </mat-card-header>
        </mat-card>
      </ng-container>

    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="p-4">
    <div class="button-row text-center">
      <button [class.red]="status=='distribucion'" mat-flat-button color="primary"
        (click)="changeTable(distribucion,'distribucion')" *ngIf="!authService.isServicioCliente">
        EN CENTRO DE DISTRIBUCIÓN ({{distribucion?.length}}) </button>

      <button [class.yellow]="status=='confirmados'" mat-flat-button color="primary"
        (click)="changeTable(confirmados,'confirmados')">
        PAQUETES POR ALISTAR ({{confirmados?.length}}) </button>

      <button [class.green]="status=='transito'" mat-flat-button color="primary"
        (click)="changeTable(transito,'transito')" *ngIf="!authService.isServicioCliente">
        EN TRANSITO AL CLIENTE ({{transito?.length}})
      </button>

      <button [class.orange]="status=='guadalupe'" mat-flat-button color="primary"
        (click)="changeTable(sucursalGuadalupe,'guadalupe')" *ngIf="!authService.isServicioCliente">
        SUCURSAL GUADALUPE ({{sucursalGuadalupe?.length}})
      </button>

      <button [class.lemon]="status=='limon'" mat-flat-button color="primary"
        (click)="changeTable(sucursalLimon,'limon')" *ngIf="!authService.isServicioCliente">
        SUCURSAL LIMÓN ({{sucursalLimon?.length}})
      </button>

    </div>
  </div>

  <div class="content-table mx-4 mb-4">
    <table mat-table [dataSource]="dataSource.data | paginate: {itemsPerPage: pageSize, currentPage: pageIndex }"
      matSort (matSortChange)="sortData($event)">
      <ng-container matColumnDef="seleccionar">
        <th mat-header-cell class="pr-pl-20 text-center" *matHeaderCellDef mat-sort-header [disabled]="true">
          <mat-checkbox class="d-flex" (click)="masterToggle()" aria-label="Seleccionar/Deseleccionar Todo" [disabled] = "!authService.validateRolePermission('SHOW_DELIVERY_OPTIONS')"
            title="Seleccionar/Deseleccionar Todo"></mat-checkbox>
        </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let row">
          <mat-checkbox class="d-flex justify-content-center" (click)="$event.stopPropagation()"
            [disabled]="(row.entrega?.entrega != null ? true: false) || !authService.validateRolePermission('SHOW_DELIVERY_OPTIONS')"
            (change)="$event ? selectedPackages.toggle(row) : null" [checked]="selectedPackages.isSelected(row)"
            matTooltip="{{row.entrega?.entrega != null ? 'Paquete Asignado': ''}}"
            [aria-label]="checkboxLabel(row)"></mat-checkbox>
        </td>
      </ng-container>

      <!-- guia Column -->
      <ng-container matColumnDef="guia">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Guía</th>
        <td mat-cell class="text-center" *matCellDef="let element"> 
          <div style="display: flex; justify-content: flex-end; align-items: center;">
            <div style="margin-left: 10px;">
              <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: element.id}">
                {{element.guia? element.guia: '-'}} <span style="color: orange;">{{element.primer_paquete ?
                  '(Primer Paquete)' : ''}}</span>
              </a>
            </div>
            <button style="padding: 4px 8px;" *ngIf="element.paquete_contenedor.length > 0 " mat-button (click)="element.isExpanded = !element.isExpanded">
              <mat-icon *ngIf="element.isExpanded; else downIcon"
                [ngStyle]="{'color': 'black', 'font-size': '1.2em'}">arrow_drop_up</mat-icon>
              <ng-template #downIcon><mat-icon
                  [ngStyle]="{'color': 'black', 'font-size': '1.2em'}">arrow_drop_down</mat-icon></ng-template>
            </button>
          </div>
          <ng-container *ngIf="element.isExpanded && element.paquete_contenedor.length > 0">
            <ng-container *ngFor="let item of element.paquete_contenedor">
              <div style="font-size: 0.9em !important" >
                {{item}}
              </div>
            </ng-container>
         </ng-container>

        </td>
      </ng-container>

      <!-- estado Column -->
      <ng-container matColumnDef="estado">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Estado </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.estado? element.estado: '-'}}
        </td>
      </ng-container>

      <!-- envio Column -->
      <ng-container matColumnDef="usuario">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Usuario </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: element.usuarioid}">{{element.nombre?
            element.nombre : '-'}}</a>
        </td>
      </ng-container>

      <!-- cuenta Column -->
      <ng-container matColumnDef="cuenta">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cuenta </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <a style="display: block;" [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: element.usuarioid}">{{element.cuenta?
            element.cuenta : '-'}}</a>
          <label *ngIf="element.userLastRoute" style="display: block; margin-top: 10px;  color: red; ">* El cliente cambió su dirección *</label>
        </td>
      </ng-container>

      <!-- tracking Column -->
      <ng-container matColumnDef="tracking">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Tracking </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.tracking? element.tracking :
          '-'}} </td>
      </ng-container>

      <!-- descripcion Column -->
      <ng-container matColumnDef="descripcion">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Descripción </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.descripcion? element.descripcion : '-'}}
        </td>
      </ng-container>

      <!-- cantpaquetes Column -->
      <ng-container matColumnDef="cantpaquetes">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cantidad de Paquetes </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.cantpaquetes ? element.cantpaquetes : '-'}}
        </td>
      </ng-container>

      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Estado </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.estado? element.estado : '-'}}
        </td>
      </ng-container>

      <!-- peso real Column -->
      <ng-container matColumnDef="peso_real">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Peso Real</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.peso_total? element.peso_total
          : '-'}}
        </td>
      </ng-container>

      <!-- peso volumetrico Column -->
      <ng-container matColumnDef="peso_volumetrico">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Peso Volumen </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.peso_volumetrico?
          element.peso_volumetrico
          : '-'}}
        </td>
      </ng-container>

      <!-- ruta Column -->
      <ng-container matColumnDef="ruta">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Ruta </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.entrega?.ruta?
          element.ruta
          : '-'}}
        </td>
      </ng-container>

      <!-- envio Column -->
      <ng-container matColumnDef="envio">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Método de Envío </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.envio? element.envio : '-'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="metodo_pago">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Método de Pago </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.metodo_pago?
          element.metodo_pago.nombre : '-'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="fecha_entrega">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header="fecha_entrega"> Fecha Programada</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">{{element.entrega?.fecha_entrega | date:
          'EEEE d MMMM':null:'es-cr' || '-'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="driver">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Conductor </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.entrega?.entrega?.conductor ?
          element.entrega.entrega.conductor.nombre : '-'}}
        </td>
      </ng-container>
      <ng-container matColumnDef="creacion">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha de Creación</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.creacion.toLocaleDateString('es-CR', { weekday: 'short', year: 'numeric', month: 'short', day:
          'numeric' })}}
        </td>
      </ng-container>

      <ng-container matColumnDef="modificado">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Última actualización</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.ultima_actualizacion | date: 'fullDate' : '' : 'es-CR'}}&nbsp;-&nbsp;{{element.estado}}
        </td>
      </ng-container>

      <ng-container matColumnDef="zona">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Zona</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.entrega?.zona_centro_distribucion
          || '-'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="tipo_de_paquete">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Tipo de Paquete </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.tipo_paquete ?
          element.tipo_paquete : '-'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="zona_entrega">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Zona Entrega</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <span *ngIf="element.modo_entrega != 'ES'">{{element.entrega?.canton?.canton || '-'}}</span>
          <span *ngIf="element.modo_entrega == 'ES'">Sucursal {{element.entrega.sucursal || '-'}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="alistado">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Alistado </th>
        <td mat-cell class="pr-pl-20 check-center" mat-cell *matCellDef="let element">
          <mat-checkbox (change)="changeStatus($event,element)" 
            [checked]="element.entrega.alistado" [disabled]="!isNextDayOrMonday(element.entrega.fecha_entrega) || !authService.validateRolePermission('SHOW_DELIVERY_OPTIONS')">
          </mat-checkbox>
        </td>
      </ng-container>


      <ng-container matColumnDef="entrega">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Entrega </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <a [routerLink]="['/admin/almacen/entregas/agregar']" [queryParams]="{pack: element?.entrega?.entrega?.id}">
            {{ element?.entrega?.entrega?.id || '-'}}
          </a>
        </td>
      </ng-container>

      <!-- tipo_cliente Column -->
      <ng-container matColumnDef="tipo_cliente">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Tipo de Cliente </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <span *ngFor="let tipo of element.tipo_cliente" class="m-2 p-2 d-block"
            style="border-radius: 15px; font-size:16px !important; color: white !important;"
            [style.background-color]="tipo.color">{{
            tipo.nombre | translate}}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row [class.alistado]="row.alistado" *matRowDef="let row; columns: displayedColumns; let odd = odd; "
        [ngClass]="{'highlight': odd, 'alistado': row.entrega.alistado }"></tr>
    </table>
    <div class="text-center" *ngIf="dataSource.data.length == 0">
      <p>No hay paquetes</p>
    </div>
  </div>

  <div class="mx-4 text-right" style="float: right;" *ngIf="dataSource.data.length > 0">
    <p>Total Paquetes Seleccionados: {{ selectedPackages?.selected.length ?
      selectedPackages?.selected.length : 0 }}
      <br>
      Total Paquetes: {{ paquetes?.length ? paquetes?.length : 0 }}
    </p>
  </div>

  <div class="paginator text-center">
    <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
      (pageBoundsCorrection)="pageIndex = $event; paginate(pageIndex)"
      (pageChange)="pageIndex = $event; paginate(pageIndex)"></pagination-controls>
  </div>

</div>
