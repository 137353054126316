<div class="pt-3 pb-3" style="display: flex; align-items: center; justify-content: space-between;">
  <h2 class="h2-title p-4">Log</h2>
</div>
<div class="content" fxLayout="column">
  <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones">
    <div class="botones-opciones">
      <button mat-button (click)="pageIndex=1; selected=1; getHistory()">Historial de Operaciones</button>
      <button mat-button (click)="pageIndex=1; selected=2; getHistoryPackagesImages()">Historial de Paquetes (Imagenes)</button>
      <button mat-button (click)="pageIndex=1; selected=3; getHistoryPackagesEstado()">Historial de Paquetes (Movimiento de Estados)</button>
    </div>

    <div class="search-table">
      <mat-form-field appearance="outline" class="form-field">
        <input matInput placeholder="Buscar..." #input (keyup.enter)="applyFilter(input.value)">
        <mat-icon (click)="applyFilter(input.value)">search</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <div class="content-general">
    <div class="content">
      <div class="content-table m-4">
        <table mat-table [dataSource]="dataSource  | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length}">

          <ng-container matColumnDef="user">
            <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Usuario </th>
            <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{ usersService.get_fullname(element.user) }} </td>
          </ng-container>

          <ng-container matColumnDef="rol">
            <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Rol </th>
            <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element?.user?.rol}} </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Acción </th>
            <td mat-cell class="pr-pl-20" *matCellDef="let element">
              {{element.action}}
            </td>
          </ng-container>

          <ng-container matColumnDef="table_name">
            <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Ruta </th>
            <td mat-cell class="pr-pl-20" *matCellDef="let element">
              {{element.table_name}}
            </td>
          </ng-container>

          <ng-container matColumnDef="created_at">
            <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Fecha </th>
            <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.created_at}} </td>
          </ng-container>

          <ng-container matColumnDef="usuario">
            <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Usuario </th>
            <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.usuario}} </td>
          </ng-container>

          <ng-container matColumnDef="imagen">
            <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Imagen </th>
            <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.image}} </td>
          </ng-container>

          <ng-container matColumnDef="guia">
            <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Guia </th>
            <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.guia}} </td>
          </ng-container>

          <ng-container matColumnDef="fecha">
            <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Fecha </th>
            <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.fecha}} </td>
          </ng-container>

          <ng-container matColumnDef="estado">
            <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Estado </th>
            <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.estado}} </td>
          </ng-container>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No hay data con el filtro "{{input.value}}"</td>
          </tr>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div class="paginator text-center">
        <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="pageIndex = $event; paginate(pageIndex)"></pagination-controls>
      </div>
    </div>
  </div>
</div>
