import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class DelmanService extends BaseService {

  constructor(http: HttpClient) {
    super(http, 'delman');
   }

  getPackageTaxes(guideNo: string) {
    return this.get(this.joinUrl('taxes', guideNo));
  }

  putPackageTaxes(guideNo: string) {
    return this.put(this.joinUrl('taxes', guideNo));
  }

  putShipmentTaxes(shipmentId: number) {
    return this.put(this.joinUrl('shipment-taxes', shipmentId));
  }

}
