import { Component, OnInit } from '@angular/core';
import { IntegracionesService } from '../../../../../services/integraciones.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-integraciones',
  templateUrl: './integraciones.component.html',
  styleUrls: ['./integraciones.component.scss']
})
export class IntegracionesComponent implements OnInit {

  logs = { delman: '', gti: '', gticonsolidar: '', pagos: '', pagosauto: '' };
  status = { schedule: [], task: [] };

  gtiStatus;
  pagosStatus;
  facturasStatus;

  gtiStatusTask;
  pagosStatusTask;
  facturasStatusTask;

  constructor(private integracionesService: IntegracionesService, public navigation: NavigationService) { }

  ngOnInit(): void {
    this.integracionesService.get().subscribe((res: any) => {
      //console.log(res);

      this.logs.delman = res.delman;
      this.logs.gti = res.gti;
      this.logs.gticonsolidar = res.gticonsolidar;
      this.logs.pagos = res.pagos;
      this.logs.pagosauto = res.pagosauto;
    });

    this.integracionesService.status().subscribe((res: any) => {
      this.status.schedule = JSON.parse(res.schedule);
      this.status.task = JSON.parse(res.task);

      this.gtiStatus = this.status.schedule.filter(e => e.fields.func === 'apps.facturas.view_gti.ConsolidarFacturas')[0].fields;
      this.pagosStatus = this.status.schedule.filter(e => e.fields.func === 'apps.pagos.views.procesar_pago')[0].fields;
      this.facturasStatus = this.status.schedule.filter(e => e.fields.func === 'apps.facturas.view_gti.downloadFacturas')[0].fields;;

      this.gtiStatusTask = this.status.task.filter(e => e.fields.func === 'apps.facturas.view_gti.ConsolidarFacturas');
      this.pagosStatusTask = this.status.task.filter(e => e.fields.func === 'apps.pagos.views.procesar_pago');
      this.facturasStatusTask = this.status.task.filter(e => e.fields.func === 'apps.facturas.view_gti.downloadFacturas');

      this.gtiStatusTask = this.gtiStatusTask.reduce((mostRecent, item) =>
        item.fields.started > mostRecent.fields.started ? item : mostRecent
      ).fields;

      this.pagosStatusTask = this.pagosStatusTask.reduce((mostRecent, item) =>
        item.fields.started > mostRecent.fields.started ? item : mostRecent
      ).fields;

      this.facturasStatusTask = this.facturasStatusTask.reduce((mostRecent, item) =>
        item.fields.started > mostRecent.fields.started ? item : mostRecent
      ).fields;

      //console.log(this.status);

      //console.log(this.gtiStatus);
      //console.log(this.gtiStatusTask);

      //console.log(this.pagosStatus);
      //console.log(this.pagosStatusTask);

      //console.log(this.facturasStatus);
      //console.log(this.facturasStatusTask);
    });
  }

  clear() {
    this.integracionesService.clear().subscribe((res: any) => {
      this.ngOnInit();
    });
  }

  back() {
    this.navigation.back();
  }

}
