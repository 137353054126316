<h2 class="h2-title p-4">Tipos de Cambios</h2>

<div class="content" fxLayout="column">
  <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones">
    <button class="botones-opciones-movil" mat-button>
      <mat-icon>more_vert</mat-icon>
    </button>
    <div class="botones-opciones">
      <!-- <button mat-button>REFINAR BUSQUEDA</button> -->
      <button mat-button routerLink="agregar">AGREGAR TIPO DE CAMBIO</button>
    </div>

    <div class="content-encontrados">
      <span class="texto-encontrados">{{length}} Tipos de Cambios Encontrados</span>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="content-table mr-4 ml-4 mb-4">
    <table mat-table [dataSource]="dataSource" matSort matSortDisabled>
      <ng-container matColumnDef="id">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Id </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="fecha">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha de Inicio </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.fecha.toLocaleDateString('es-CR', { weekday:
          'short', year: 'numeric', month: 'short', day: 'numeric' })}} </td>
      </ng-container>

      <ng-container matColumnDef="valor">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Valor </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.valor.toFixed(2)}} </td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Responsable </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.user}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }"></tr>
    </table>
  </div>
</div>