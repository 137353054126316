import { NgModule } from '@angular/core';

import { GlobalModule } from '../../global.module';

import { AutorizacionComponent } from './dialogs/autorizacion/autorizacion.component';
import { EditAuthorizationsComponent } from './dialogs/edit-authorizations/edit-authorizations.component';
import { ConfirmarComponent } from './dialogs/confirmar/confirmar.component';
import { ProfilePictureComponent } from './dialogs/profile-picture/profile-picture.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { GeneralDialogComponent } from './dialogs/general-dialog/general-dialog.component';
import { CalculadoraComponent } from './pages/calculadora/calculadora.component';
import { MaterialModule } from '../../material.module';
import { DateFormatPipe } from 'src/app/shared/pipes/date.pipe';
import { FileFormatPipe } from 'src/app/shared/pipes/file.pipe';
import { CRCFormatPipe } from 'src/app/shared/pipes/number-format.pipe';
import { SelectPackagesComponent } from './dialogs/selectPackages/select-packages.component';
import { GeneralInputComponent } from './dialogs/general-input/general-input.component';
import { AdjuntarPagoComponent } from './dialogs/adjuntar-pago/adjuntar-pago.component';
import { ProgramaEntregaComponent } from './dialogs/programar-entrega/programar-entrega.component';
import { AgmCoreModule } from '@agm/core';
import { AddListDetailsComponent } from './dialogs/add-list-details/add-list-details.component';
import { SinpePaymentDialogComponent } from './dialogs/sinpe-payment-dialog/sinpe-payment-dialog.component';
import { PaymentPackagesListComponent } from './dialogs/payment-packages-list/payment-packages-list.component';
import { SelectPackagesConsolidadoComponent } from './dialogs/select-packages-consolidado/select-packages-consolidado.component';
import { ConsolidarPagosComponent } from './pages/consolidar-pagos/consolidar-pagos.component';

@NgModule({
  declarations: [
    AutorizacionComponent,
    EditAuthorizationsComponent,
    ConfirmarComponent,
    ProfilePictureComponent,
    PerfilComponent,
    GeneralDialogComponent,
    CalculadoraComponent,
    SelectPackagesComponent,
    GeneralInputComponent,
    AdjuntarPagoComponent,
    AddListDetailsComponent,
    SinpePaymentDialogComponent,
    ProgramaEntregaComponent,
    DateFormatPipe,
    FileFormatPipe,
    CRCFormatPipe,
    PaymentPackagesListComponent,
    SelectPackagesConsolidadoComponent,
    ConsolidarPagosComponent,
  ],
  imports: [
    GlobalModule,
    MaterialModule,
    AgmCoreModule,
  ],
  exports: [
    AutorizacionComponent,
    EditAuthorizationsComponent,
    ConfirmarComponent,
    ProfilePictureComponent,
    PerfilComponent,
    GeneralDialogComponent,
    CalculadoraComponent,
    SelectPackagesComponent,
    GeneralInputComponent,
    AdjuntarPagoComponent,
    AddListDetailsComponent,
    SinpePaymentDialogComponent,
    ProgramaEntregaComponent,
    DateFormatPipe,
    FileFormatPipe,
    CRCFormatPipe,
  ],
  providers: []
})
export class SharedComponentsModule { }
