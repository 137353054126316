<div class="pt-3 pb-3" style="display: flex; align-items: center; justify-content: space-between;">
  <div style="width: 100%;">
    <button mat-button [routerLink]="['/admin/almacen/entregas']"
      style="padding: 0; padding-right: 10px; color: #0094a3; float: left;">
      <mat-icon>keyboard_arrow_left</mat-icon> ENTREGAS
    </button>
  </div>
</div>


<div class="content" fxLayout="column">
  <div class="content-title">
    <p class="title">Capturar Zona</p>
  </div>

  <div class="pt-4 px-4">
    <label class="label">Seleccione una Zona</label>
    <mat-form-field appearance="outline" class="form-field">
      <mat-select class="input-field" [(value)]="selectedZona"
        (selectionChange)="zona_nueva =  zona.value; paquetes = []" #zona name="zona">
        <mat-option *ngFor="let zona of zonasPaquetes" [value]="zona.id">
          {{ zona.zona }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!--  <span class="unavailableEmail" *ngIf="estado_invalido">
      Elija una acción válida
    </span> -->
  </div>

  <div class="pl-4 pr-4">
    <label class="label">Guía</label>
    <mat-form-field appearance="outline" class="form-field">
      <input matInput #track [(ngModel)]="tracking" rows="2"
        (keyup.enter)="$event.preventDefault(); findByManualInput(track.value)">
    </mat-form-field>
  </div>

  <div class="pl-4 pr-4" *ngIf="paquetes.length > 0">
    <label class="label">Paquetes Asignados:</label>
    <mat-selection-list #listapaquetes dense>
      <mat-list-option *ngFor="let paquete of paquetes" [value]="paquete.tracking"
        [ngStyle]="{'color': paquete.invalidcode ? 'red': 'black'}">
        {{paquete.tracking}} ({{paquete.numero_de_guia}})
      </mat-list-option>
    </mat-selection-list>
    <mat-divider></mat-divider>
    <label class="label pl-2 pb-4">Total: {{paquetes.length}} </label>
  </div>
</div>