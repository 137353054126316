/* eslint-disable max-len */
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Validators, FormBuilder } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-edit-authorizations',
  templateUrl: './edit-authorizations.component.html',
  styleUrls: ['./edit-authorizations.component.scss']
})
export class EditAuthorizationsComponent implements OnInit {
  selectedChoice;
  tiposDeAutorizacion = [{
    name: 'Completa',
    desp: this.translate.translate('Persona autorizada en retirar, cancelar, ver estados de cuenta y realizar consultas personales de la cuenta. Tiene el mismo poder que el titular de la cuenta')
  },
  {
    name: 'Pago y Retiro',
    desp: this.translate.translate('Persona autorizada en retirar y cancelar los paquetes de la cuenta únicamente')
  },
  {
    name: 'Retiro',
    desp: this.translate.translate('Persona autorizada en retirar los paquetes pagos de la cuenta únicamente')
  }];

  formAutorizaciones = this.fb.group({
    nombre: ['', [Validators.required, Validators.max(30)]],
    cedula: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
    telefono: ['', [Validators.required]],
    tipo_autorizacion: ['', [Validators.required, Validators.max(30),]],
    email: ['', [Validators.email]],
    activo: [false, [Validators.required]],
    enviar_correo: [false, [Validators.required]],
  });

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditAuthorizationsComponent>,
    @Inject(MAT_DIALOG_DATA,
    ) public data: any) {


  }

  ngOnInit() {
    this.formAutorizaciones.controls.nombre.setValue(this.data.nombre);
    this.formAutorizaciones.controls.cedula.setValue(this.data.cedula);
    this.formAutorizaciones.controls.telefono.setValue(this.data.telefono);
    this.formAutorizaciones.controls.tipo_autorizacion.setValue(this.data.tipo_autorizacion);
    this.formAutorizaciones.controls.email.setValue(this.data.email);
    this.formAutorizaciones.controls.activo.setValue(this.data.activo);
    this.formAutorizaciones.controls.enviar_correo.setValue(this.data.enviar_correo);
    this.selectedChoice = this.data.tipo_autorizacion;
  }

  edit(form) {

    form.id = this.data.id;
    const user = JSON.parse(localStorage.getItem('user'));
    form.user_id = user.id;
    ////console.log('editar',form)
    this.authService.editAuthorization(form).subscribe(data => {
      ////console.log('data',data)
      if (data.ok == true) {
        this.openSnackBar('Autorización Actualizada.', 'Cerrar');
        this.dialogRef.close();
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
