/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import printJS from 'print-js';
import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';

import { UsuariosService } from '../../../../../../../services/usuarios.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmarComponent } from 'src/app/modules/common/dialogs/confirmar/confirmar.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

interface Cuentas {
  id: number;
  name: string;
  account: string;
  ci: string;
  email: string;
  phone: string;
  date: Date;
}
@Component({
  selector: 'app-transportistas',
  templateUrl: './transportistas.component.html',
  styleUrls: ['./transportistas.component.scss']
})
export class TransportistasComponent implements OnInit {
  length: number;
  pageSize = 10;
  pageIndex = 1;
  copy_data = [];
  email = new FormControl('', [Validators.required, Validators.email]);
  displayedColumns: string[] = ['name', 'email', 'date', 'eliminar'];
  dataSource = new MatTableDataSource<Cuentas>();
  selection = new SelectionModel<Cuentas>(true, []);

  constructor(
    private fb: FormBuilder,
    public usuariosService: UsuariosService,
    private spinnerService: NgxSpinnerService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.getUsers();
  }
  getUsers() {
    this.dataSource.data = [];
    this.usuariosService.get_driver_users().subscribe(
      (res: any) => {

        const data = [];
        res.forEach(element => {
          data.push({
            id: element.id,
            // eslint-disable-next-line max-len
            name: this.usuariosService.get_fullname(element).startsWith('null') ? element.razon_social : this.usuariosService.get_fullname(element),
            account: this.usuariosService.get_account_number(element),
            ci: element.numero_de_cedula,
            email: element.email,
            phone: element.telefono_movil,
            date: new Date(element.created_at),
            tipo_cliente: element.tipo_cliente
          });
        });
        this.dataSource.data = data;
        //console.log(data);
        this.copy_data = data;
        this.length = res.count;
      }
    );
  }

  getErrorMessage() {
    return this.email.hasError('required') ? 'Debes ingresar un correo' :
      this.email.hasError('email') ? 'No es un correo valido' :
        '';
  }

  public printUsersTable() {
    printJS({
      printable: 'users-table',
      type: 'html'
    });
  }

  paginate(event: any) {
    //console.log(event);
    this.pageIndex = event;
    this.getUsers();

  }

  deleteTransportista(id) {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      data: { message: '¿Desea eliminar este transportista?' },
      height: '200px',
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.usuariosService.delete_user_by_id(id).subscribe(data => {
          this.ngOnInit();
          this.openSnackBar('Se ha eliminado un transportista.', 'Cerrar');
        });
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
