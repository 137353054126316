<div *ngIf="!verTemplate; else showTemplate" class="p-3 content" style="min-width: 80vw !important" fxLayout="column">

  <div class="content-title">
    <button mat-button [routerLink]="['/admin/administracion/email']" [queryParams]="{tipo: 'T'}" class="pt-3"
      style="padding: 0; padding-right: 10px; color: #ffffff; float: left;">
      <mat-icon style="color: #ffffff !important;">keyboard_arrow_left</mat-icon>
    </button>
    <p class="title">Emails</p>
  </div>

  <div class="inputs-form mt-5 pt-4 mb-4 inputs-template">
    <div>
      <label class="label">Titulo</label><br>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="title" placeholder="Título">
      </mat-form-field>
    </div>

    <div>
      <label class="label">Asunto</label><br>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="asunto" placeholder="Asunto">
      </mat-form-field>
    </div>

    <!--
      <div>
      <label class="label">Módulo</label><br>
      <mat-form-field appearance="outline">
        <mat-select [(ngModel)]="modulo" placeholder="Seleccione">
          <mat-option [value]="'Usuario'">Usuario</mat-option>
          <mat-option [value]="'Embarques'">Embarques</mat-option>
          <mat-option [value]="'Entregas'">Entregas</mat-option>
        </mat-select>
      </mat-form-field>
      </div>
    -->

  </div>

  <div class="row">

    <div class="col-12 col-md-12" *ngIf="tipo == 'N'">
      <label class="label">Destinatarios</label><br>
      <mat-form-field appearance="outline">
        <mat-chip-list #chipList aria-label="Destinatarios">
          <mat-chip *ngFor="let destinatario of destinatarios" (removed)="remove(destinatario)">
            {{destinatario.name}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input placeholder="Seleccione" #destinatarioInput [formControl]="destinatarioCtrl" [matAutocomplete]="auto"
            [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" #cuenta (keyup)="buscarListaClientes($event, cuenta.value)">
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="displayFn">
          <mat-option *ngFor="let destinatario of filteredDestinatarios | async" [value]="destinatario">
            {{destinatario.name}}
          </mat-option>
        </mat-autocomplete>

      </mat-form-field>
    </div>

    <div class="col-12 col-md-12" *ngIf="tipo == 'N'">
      <button mat-flat-button color="primary" (click)="searchUsersEmbarque()">{{'Seleccionar Embarque' | translate}}</button>
      <button mat-flat-button color="primary" (click)="searchUsersStatus()">{{'Seleccionar Estatus' | translate}}</button>
    </div>

    <!--
    <div class="col-12 col-md-5" >
      <label class="label">CC</label><br>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="cc" [disabled]="modulo == 'Usuario'" placeholder="CC"
          matTooltip="Para múltiples direcciones introducir separadas por comas">
      </mat-form-field>
    </div>-->

    <!-- <div class="col-12 col-md-2 text-center">
      <label class="label">Activo</label><br>

      <mat-checkbox style="display: inline-flex !important;" [value]="activo" [checked]="activo"></mat-checkbox>

    </div> -->
  </div>

  <!--
    <div class="banner-template">
      <label for="1">Banner</label>
      <input type="file" id="1" (change)="onUpload($event)">
    </div>
    -->

  <app-ckeditor [template]="template" [send]="tipo == 'N' ? true : false" [modal]="false" (content)="recibirTemplate($event)" (sending)="checkSend($event)"></app-ckeditor>

  <!--   <div>
    <p class="title1">Campos de Formulario</p>
  </div>

  <mat-nav-list>
    <mat-list-item>

    </mat-list-item>
  </mat-nav-list> -->

</div>

<ng-template #showTemplate>
  <app-vista [title]="title" [asunto]="asunto" [banner]="banner" [template]="template"></app-vista>
</ng-template>
