/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UsuariosService } from 'src/app/services/usuarios.service';

@Injectable({
  providedIn: 'root',
})
export class FacturasService {
  urlFacturas = `${environment.apiUrl}/facturas/`;

  constructor(private http: HttpClient, private usuariosService: UsuariosService) { }

  getFacturas() {
    return this.http.get(this.urlFacturas);
  }

  getFacturasByUser(id) {
    return this.http.get(`${this.urlFacturas}usuario/${id}`);
  }

  getFacturasByUserPorPagar(id) {
    return this.http.get(`${this.urlFacturas}facturasporpagar/${id}`);
  }

  getFacturasByUserPagadas(id) {
    return this.http.get(`${this.urlFacturas}facturaspagadas/${id}`);
  }

  getFacturaByID(id) {
    return this.http.get(`${this.urlFacturas}${id}`);
  }

  postFacturas(data) {
    return this.http.post(this.urlFacturas, data);
  }

  putFacturas(id, data) {
    return this.http.put(`${this.urlFacturas}${id}`, data);
  }

  // consolidarFactura(id) {
  //   return this.http.get(`${this.urlFacturas}consolidar/${id}`);
  // }

  // consolidarTicket(id) {
  //   return this.http.get(`${this.urlFacturas}consolidar-ticket/${id}`);
  // }

  // consultarFactura(id, correo) {
  //   return this.http.get(`${this.urlFacturas}consultar/${id}/${correo}`);
  // }

  // downloadFactura(id) {
  //   return this.http.get(`${this.urlFacturas}download-gti/${id}`, { responseType: 'blob' });
  // }

  calculator(data) {
    let url = `${this.urlFacturas}calculadora?`;
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        url = url + key + '=' + data[key] + '&';
      }
    }

    return this.http.get(url);
  }

  crearFactura(data) {
    let url = `${this.urlFacturas}crear?`;
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        url = url + key + '=' + data[key] + '&';
      }
    }

    return this.http.get(url);
  }

  getOrCreateNumber(bill) {
    if (bill) {
      if (!bill.numero) {
        return ('00000000' + bill.id).slice(-8);
      } else {
        return bill.numero;
      }
    }
    return '-';
  }

  recalculate(data) {

    let descuento;
    const suma_iva =
      this.parseDoubleOrNull(data.cargo_seguro) +
      this.parseDoubleOrNull(data.cargo_manejo) +
      this.parseDoubleOrNull(data.cargo_exoneracion) +
      this.parseDoubleOrNull(data.cargo_permiso) +
      this.parseDoubleOrNull(data.cargo_bodegaje) +
      (this.parseDoubleOrNull(data.cargo_encomienda) / this.parseDoubleOrNull(data.tipo_de_cambio)) +
      this.parseDoubleOrNull(data.cargo_gestion_compra_corporativa);

    const suma_no_iva =
      this.parseDoubleOrNull(data.cargo_impuestos) +
      this.parseDoubleOrNull(data.cargo_transporte) +
      this.parseDoubleOrNull(data.cargo_fuel) +
      this.parseDoubleOrNull(data.cargo_confeccion_guia) +
      this.parseDoubleOrNull(data.cargo_confeccion_bl) +
      this.parseDoubleOrNull(data.cargo_consolidacion_paquetes) +
      this.parseDoubleOrNull(data.cargo_compra_corporativa);


    data.iva = suma_iva * 0.13;
    data.subtotal = suma_iva + suma_no_iva + data.iva;

    switch (data.tipo_descuento) {
      case 'Porcentaje':
        descuento = data.subtotal * (data.descuento / 100);
        break;
      case 'Recomendacion':
        descuento = data.descuento;
        break;
      case 'Feedback':
        descuento = data.cargo_transporte * (data.descuento / 100);
        break;
      default:
        descuento = data.descuento;
        break;
    }

    data.servicios_sb = suma_iva - ((this.parseDoubleOrNull(data.cargo_encomienda) / this.parseDoubleOrNull(data.tipo_de_cambio)));
    data.flete_internacional = this.parseDoubleOrNull(data.cargo_transporte) + this.parseDoubleOrNull(data.cargo_fuel) + this.parseDoubleOrNull(data.cargo_confeccion_guia) + this.parseDoubleOrNull(data.cargo_confeccion_bl) + this.parseDoubleOrNull(data.cargo_consolidacion_paquetes);

    data.total = suma_iva + suma_no_iva + data.iva - descuento - (data.nota_credito ? data.nota_credito.monto : 0);

    return data;
  }

  getNotasCredito(page, params) {
    return this.http.get(this.urlFacturas + 'nota-credito' + '?per_page=10&page_no=' + page + params);
  }

  getNotasCreditoUsuario(id, index = 1) {
    return this.http.get(this.urlFacturas + 'nota-credito/usuario/' + id + '?page=' + index);
  }

  postNotaCredito(data) {
    return this.http.post(this.urlFacturas + 'nota-credito', data);
  }

  putNotaCredito(id, data) {
    return this.http.put(`${this.urlFacturas}nota-credito/${id}`, data);
  }

  deleteNotaCredito(id) {
    return this.http.delete(`${this.urlFacturas}nota-credito/${id}`);
  }

  anularFactura(id, tipo, anular = 0) {
    return this.http.get(`${this.urlFacturas}anular/${id}/${tipo}/${anular}`);
  }

  // notaDebitoGTI(id, anular = 0) {
  //   return this.http.get(`${this.urlFacturas}nota-debito-gti/${id}/${anular}`);
  // }

  getNotasDebito(page, params) {
    return this.http.get(this.urlFacturas + 'nota-debito' + '?per_page=10&page_no=' + page + params);
  }

  getNotasDebitoUsuario(id, index = 1) {
    return this.http.get(this.urlFacturas + 'nota-debito/usuario/' + id + '?page=' + index);
  }

  postNotaDebito(data) {
    return this.http.post(this.urlFacturas + 'nota-debito', data);
  }

  putNotaDebito(id, data) {
    return this.http.put(`${this.urlFacturas}nota-debito/${id}`, data);
  }
  deleteNotaDebito(id) {
    return this.http.delete(`${this.urlFacturas}nota-debito/${id}`);
  }

  private parseDoubleOrNull(value) {
    if (value == null) {
      return 0;
    }
    return parseFloat(value);
  }

}
