
const regexGovId = /^\d{1}-\d{3}-\d{6}$/;
const regexPhysicalID = /^\d{2}-\d{4}-\d{4}$/;

const isValidPhysicalID = (input) => regexPhysicalID.test(input);

const isValidGovId = (input) => regexGovId.test(input);

export const formatGovId = (govId: string, govIdType: number): string => {

    if (govIdType === 1) {
        if (!isValidGovId(govId)) {
            return govId.replace(/(\d{1})(\d{3})(\d{6})/, '$1-$2-$3');
        }
    } else if (govIdType === 0) {
        if (!isValidPhysicalID(govId)) {
            govId = '0' + govId;
            return govId.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
        }
    }
    return govId;
  };
