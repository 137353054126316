<div class="pt-3 pb-3" style="display: flex; align-items: center; justify-content: space-between;">
  <div style="width: 100%;">
    <span class="ml-4" style="margin-top: 0;margin-bottom: 0;">Vendedores</span>

    <button mat-flat-button color="primary" class="button-calcular mr-4" (click)="back()" style="padding: 0; float: right;">Nuevo</button>
  </div>
</div>

<div class="content" fxLayout="column">

  <ng-container>
    <div class="content-table m-4" id="users-table">
      <table mat-table
        [dataSource]="dataSource.data  | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length}"
        matSort>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Vendedor </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> <a [routerLink]="['/admin/perfil']"
            [queryParams]="{cuenta: element.id}">{{element.name}}</a></td>
        </ng-container>

        <!-- Account Column -->
        <ng-container matColumnDef="account">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cuenta </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element">
            <a [routerLink]="['/admin/perfil']"
              [queryParams]="{cuenta: element.id}">{{element.account}}-{{element.name}} </a>
          </td>
        </ng-container>

        <!-- ci Column -->
        <ng-container matColumnDef="ci">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cédula </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.ci}} </td>
        </ng-container>

        <!-- email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Correo </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <!-- phone Column -->
        <ng-container matColumnDef="phone">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Teléfono </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.phone}} </td>
        </ng-container>

        <!-- date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha de Creación </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.date.toLocaleDateString('es-CR', { weekday:
            'short', year: 'numeric', month: 'short', day: 'numeric' })}} </td>
        </ng-container>

         <!-- date Column -->
        <ng-container matColumnDef="eliminar">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Opciones </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element">
            <button mat-icon-button aria-label="Eliminar" class="button-opcione-borrar"
            (click)="deleteVendedor(element.id)">
            <mat-icon style="color: red !important">delete</mat-icon>
          </button>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true"
          [ngClass]="{'highlight-top-header': true }"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }">
        </tr>
      </table>
    </div>

    <div class="paginator text-center">
      <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
        (pageBoundsCorrection)="pageIndex = $event; paginate(pageIndex)"
        (pageChange)="pageIndex = $event; paginate(pageIndex)"></pagination-controls>
    </div>
  </ng-container>
  <mat-divider></mat-divider>


</div>
