<h2 class="h2-title p-4">Reportes</h2>

<div class="content" fxLayout="column">
  <form [formGroup]="reportsForm">
    <div class="row pl-4 pr-4 pt-3 mt-2 inputs-form">

      <div class="col-2">
        <label class="label">Reporte</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <mat-select formControlName="report" name="report" placeholder="Seleccione" #report
            (selectionChange)="filterReport(report.value)">
            <mat-option *ngFor="let r of reports" [value]="r.value">
              {{r.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="selectedReport?.value != 'antiguedad-saldos'" class="col-2">
        <label class="label">Fecha de Creación</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Fecha de Creación" type="date" class="input-field" formControlName="start"
            (change)="saveFilters()">
        </mat-form-field>
      </div>

      <div class="col-2">
        <label class="label">Fecha de Finalización</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Fecha de Finalización" type="date" class="input-field" formControlName="end"
            (change)="saveFilters()">
        </mat-form-field>
      </div>

      <div *ngIf="selectedReport?.cuenta" class="col-2">
        <label class="label">Cuenta</label><br>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Cuenta" formControlName="user" [matAutocomplete]="auto" required #cuenta
            (keyup)="buscarListaClientes($event, cuenta.value)">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
            (selectionChange)="saveFilters()">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
          <button matSuffix mat-icon-button (click)="reportsForm.get('user').reset()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div *ngIf="selectedReport?.sucursal" class="col-2">
        <label class="label">Sucursal</label><br>
        <mat-form-field appearance="outline">
          <mat-select matInput placeholder="Sucursal" formControlName="sucursal" (selectionChange)="saveFilters()">
            <mat-option value="-1">Todas</mat-option>
            <mat-option value="Limón">
              Limón
            </mat-option>
            <mat-option value="Guadalupe">
              Guadalupe
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="selectedReport?.metodo" class="col-2">
        <label class="label">Métodos de pago</label><br>
        <mat-form-field appearance="outline">
          <mat-select matInput placeholder="Método de pago" formControlName="metodo" (selectionChange)="saveFilters()">
            <mat-option value="-1">Todos</mat-option>
            <mat-option *ngFor="let metodo of metodosPago" [value]="metodo.id">
              {{metodo.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="selectedReport?.nota" class="col-2">
        <label class="label">Tipo</label><br>
        <mat-form-field appearance="outline">
          <mat-select matInput placeholder="Seleccione Tipo" formControlName="nota" (selectionChange)="saveFilters()">
            <mat-option value="-1">Todos</mat-option>
            <mat-option value="credito">Nota de Crédito</mat-option>
            <mat-option value="debito">Nota de Débito</mat-option>
            <mat-option value="descuento">Descuentos</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="selectedReport?.generada" class="col-2">
        <label class="label">Estatus</label><br>
        <mat-form-field appearance="outline">
          <mat-select matInput placeholder="Estatus" formControlName="generada" (selectionChange)="saveFilters()">
            <mat-option value="-1">
              Todas
            </mat-option>
            <mat-option value="0">
              Pendientes
            </mat-option>
            <mat-option value="1">
              Generadas
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="selectedReport?.status" class="col-2">
        <label class="label">Estado</label><br>
        <mat-form-field appearance="outline">
          <mat-select matInput placeholder="Estado" formControlName="estado" (selectionChange)="saveFilters()">
            <mat-option value="-1">Todos</mat-option>
            <mat-option *ngFor="let act of estadosPaquetes" [value]="act.id">
              {{ act.estado }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="selectedReport?.alianza" class="col-2">
        <label class="label">Alianzas</label><br>
        <mat-form-field appearance="outline">
          <mat-select matInput placeholder="Alianza" formControlName="alianza" (selectionChange)="saveFilters()">
            <mat-option *ngFor="let alianza of alianzas" [value]="alianza.id">
              {{ alianza.nombre }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="selectedReport?.noPagosEntregados && user.rol == 'ADMIN'" class="col-2">
        <label class="label">Neteo</label><br>
        <button mat-flat-button color="primary" (click)="netPackages()">
          Netear Paquetes
        </button>
      </div>

      <div fxLayout="row wrap" class="col-4 px-4">
        <label class="label">&nbsp;</label><br>
        <button mat-button (click)="blank(); procesarReporte()">
          Buscar <mat-icon>search</mat-icon>
        </button>

        <button mat-button (click)="clear()">
          <mat-icon>clear</mat-icon> Borrar
        </button>

        <button mat-button [routerLink]="['/admin/vendedor']" *ngIf="selectedReport?.value == 'vendedores'">
          <mat-icon>add</mat-icon> Crear Cuenta Vendedor
        </button>

      </div>
    </div>

  </form>

  <div *ngIf="dataSource?.length">
    <button mat-button style="float: right" (click)="$event.preventDefault(); $event.stopPropagation(); export()">
      <mat-icon>insert_drive_file</mat-icon> Exportar a excel
    </button>

    <button mat-button style="float: right" (click)="$event.preventDefault(); $event.stopPropagation(); enviarCorreo()">
      <mat-icon>email</mat-icon> Enviar por Correo
    </button>
  </div>

  <div class="content-table mx-4">
    <p-table [columns]="displayedColumns"
      [value]="dataSource | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length}"
      dataKey="{{groupByColumns}}" autoLayout="true" styleClass="p-datatable-gridlines">

      <ng-template pTemplate="header" let-columns>

        <tr class="top-header">
          <th *ngIf="selectedReport?.noPagosEntregados">
            Seleccionar
          </th>
          <th *ngFor="let col of columns">
            {{col.name}}
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row let-columns="columns" let-rowIndex="rowIndex" let-expanded="expanded">
        <tr
          *ngIf="groupByColumns.length > 0 ? rowGroupMetadata[row[groupByColumns]].index === rowIndex ? true: false : false">
          <td *ngFor="let column of columns; let i=index">
            <div *ngIf="i == 0">
              <mat-button pButton pRipple [pRowToggler]="row"
                class="p-button-text p-button-rounded p-button-plain p-mr-2"><mat-icon>{{expanded ? 'expand_more' :
                  'chevron_right'}}</mat-icon></mat-button>

              <b *ngIf="groupByColumns == 'codigo_promocional'">{{ row[groupByColumns] | uppercase }} - Cuota de Uso: {{
                sumArray(rowGroupMetadata[row[groupByColumns]].data, 'cantidad_promo')}} /
                {{rowGroupMetadata[row[groupByColumns]].data[0].limit}} </b>

              <b *ngIf="groupByColumns == 'cuenta'">{{ row[groupByColumns] + " " +
                usuariosService.get_fullname(rowGroupMetadata[row[groupByColumns]].data[0].usuario) | uppercase }}</b>

              <b *ngIf="groupByColumns == 'vendedor'">{{
                usuariosService.get_fullname(rowGroupMetadata[row[groupByColumns]].data[0].vendedor) | uppercase }}</b>

              <b *ngIf="groupByColumns == 'numero_de_guia'"></b>

            </div>

            <div *ngIf="i > 0">
              <span *ngIf="column.total && column.currency == false && column.total_value != 'Total:'">{{
                sumArray(rowGroupMetadata[row[groupByColumns]].data , column.prop) }}</span>
              <span *ngIf="column.total && column.currency == true && column.total_value != 'Total:'">{{
                sumArrayCurrency(rowGroupMetadata[row[groupByColumns]].data , column.prop) | currency:
                'CRC':'symbol-narrow':'1.2-2' }}</span>
            </div>
          </td>
        </tr>

        <tr class="report-cells" *ngIf="groupByColumns.length == 0">
          <td *ngIf="selectedReport?.noPagosEntregados">
            <mat-checkbox [checked]="row.checked" (change)="checkPackage(row)"></mat-checkbox>
          </td>
          <td *ngFor="let column of columns">
            <ng-container *ngIf="column.prop == 'cuenta'; else ccuenta">
              <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.usuario.id}"> {{row.usuario.cuenta}}</a>
            </ng-container>

            <ng-template #ccuenta>
              <ng-container *ngIf="column.prop == 'ccuenta'; else xcuenta">
                <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.cuenta.id}"> {{row.cuenta.cuenta}}</a>
              </ng-container>
            </ng-template>

            <ng-template #xcuenta>
              <ng-container *ngIf="column.prop == 'xcuenta'; else cliente">
                <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.id}"> {{row.cuenta}}</a>
              </ng-container>
            </ng-template>

            <ng-template #cliente>
              <ng-container *ngIf="column.prop == 'cliente'; else fcliente">
                <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.usuario.id}"> {{
                  usuariosService.get_fullname(row.usuario) }}</a>
              </ng-container>
            </ng-template>

            <ng-template #fcliente>
              <ng-container *ngIf="column.prop == 'fcliente'; else ccliente">
                <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.user.id}"> {{
                  usuariosService.get_fullaccount(row.user) }}</a>
              </ng-container>
            </ng-template>

            <ng-template #ccliente>
              <ng-container *ngIf="column.prop == 'ccliente'; else xcliente">
                <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.cuenta.id}"> {{
                  usuariosService.get_fullaccount(row.cuenta) }}</a>
              </ng-container>
            </ng-template>

            <ng-template #xcliente>
              <ng-container *ngIf="column.prop == 'xcliente'; else xalianza">
                <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.id}"> {{
                  usuariosService.get_fullname(row)
                  }} </a>
              </ng-container>
            </ng-template>

            <ng-template #xalianza>
              <ng-container *ngIf="column.prop == 'xalianza'; else vendedor">
                {{ row.alianza.nombre}}
              </ng-container>
            </ng-template>

            <ng-template #vendedor>
              <ng-container *ngIf="column.prop == 'vendedor'; else total_factura">
                <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.vendedor.id}"> {{
                  usuariosService.get_fullname(row.vendedor)}} </a>
              </ng-container>
            </ng-template>

            <ng-template #percentage>
              <ng-container *ngIf="column.percentage == true; else total_factura">
                {{ row[column.prop] | number:'1.2-2' }}%
              </ng-container>
            </ng-template>

            <ng-template #total_factura>
              <ng-container *ngIf="column.prop == 'total_factura'; else pago_1">
                {{row.factura?.factura_crc.total | currency: 'CRC':'symbol-narrow':'1.2-2'}}
              </ng-container>
            </ng-template>

            <ng-template #pago_1>
              <ng-container *ngIf="column.prop == 'pago_1'; else pago_2">
                {{row.factura?.pago[0]?.referencia}}
              </ng-container>
            </ng-template>

            <ng-template #pago_2>
              <ng-container *ngIf="column.prop == 'pago_2'; else guias_status">
                {{row.factura?.pago[1]?.referencia}}
              </ng-container>
            </ng-template>

            <ng-template #guias_status>
              <ng-container *ngIf="column.prop == 'guias_status'; else guias_vendedor">
                <table>
                  <tr>
                    <th>Guia</th>
                    <th>Fecha</th>
                  </tr>
                  <tr *ngFor="let p of row.paquetes">
                    <td>
                      <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: p.id}">{{p.guia}}</a>
                    </td>
                    <td>
                      {{p.fecha | date: 'dd-MM-yyyy'}}
                    </td>
                  </tr>
                </table>

              </ng-container>
            </ng-template>

            <ng-template #guias_vendedor>
              <ng-container *ngIf="column.prop == 'guias_vendedor'; else guia_clased">
                <span *ngFor="let p of row.paquetes">
                  <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: p.id}">{{p.guia}}&nbsp; </a>
                </span>
              </ng-container>
            </ng-template>

            <ng-template #guia_clased>
              <ng-container *ngIf="column.prop == 'guia_clased'; else guia_link">
                <a [routerLink]="['/admin/paquetes/paquete']"
                  [queryParams]="{pack: row.id}">{{row.numero_de_guia}}&nbsp; </a>
              </ng-container>
            </ng-template>

            <ng-template #guia_link>
              <ng-container *ngIf="column.prop == 'guia_link'; else guia_compra">
                <a [routerLink]="['/admin/paquetes/paquete']"
                  [queryParams]="{pack: row.id}">{{row.numero_de_guia}}&nbsp; </a>
              </ng-container>
            </ng-template>

            <ng-template #guia_compra>
              <ng-container *ngIf="column.prop == 'guia_compra'; else guia_pago">
                <a [routerLink]="['/admin/paquetes/paquete']"
                  [queryParams]="{pack: row.paquete.id}">{{row.paquete.numero_de_guia}} </a>
              </ng-container>
            </ng-template>

            <ng-template #guia_pago>
              <ng-container *ngIf="column.prop == 'guia_pago'; else guia_nota">
                <!--              <span *ngIf="row.guias.length > 0">
                  <a [routerLink]="['/admin/paquetes/paquete']"
                    [queryParams]="{pack: row.guias[0].id}">{{row.guias[0].guia || '-'}} </a>
                </span>
                <span *ngIf="row.guias.length == 0">
                  -
                </span> -->
                {{row.guias}}

              </ng-container>
            </ng-template>

            <ng-template #guia_nota>
              <ng-container *ngIf="column.prop == 'guia_nota'; else id_compra">
                <span *ngIf="row.paquete[0]">
                  <a [routerLink]="['/admin/paquetes/paquete']"
                    [queryParams]="{pack: row.paquete[0].id}">{{row.paquete[0].numero_de_guia}} </a>
                </span>
                <span *ngIf="!row.paquete[0]">
                  -
                </span>
              </ng-container>
            </ng-template>

            <ng-template #id_compra>
              <ng-container *ngIf="column.prop == 'id_compra'; else fecha_nacimiento">
                <a [routerLink]="['/admin/compras-corporativas/agregar']" [queryParams]="{id: row.id}">{{row.id}}&nbsp;
                </a>
              </ng-container>
            </ng-template>

            <ng-template #fecha_nacimiento>
              <ng-container *ngIf="column.prop == 'fecha_nacimiento'; else fecha">
                {{ row[column.prop]}}
              </ng-container>
            </ng-template>

            <ng-template #fecha>
              <ng-container *ngIf="column.fecha == true; else fecha_entrega">
                {{ row[column.prop] | date:'dd-MM-yyyy'}}
              </ng-container>
            </ng-template>

            <ng-template #fecha_entrega>
              <ng-container *ngIf="column.prop == 'fecha_entrega'; else hora_entrega">
                {{row.entrega?.fecha_entrega | date:'dd-MM-yyyy'}}
              </ng-container>
            </ng-template>

            <ng-template #hora_entrega>
              <ng-container *ngIf="column.prop == 'hora_entrega'; else fecha_factura">
                {{(row.entrega.hora_entrega ? row.entrega.hora_entrega : getHoraEntrega(row.estado_historial)) |
                date:'dd-MM-yyyy'}}
              </ng-container>
            </ng-template>

            <ng-template #fecha_factura>
              <ng-container *ngIf="column.prop == 'fecha_factura'; else fecha_pago">
                {{row.fecha_factura | date:'dd-MM-yyyy'}}
              </ng-container>
            </ng-template>

            <ng-template #fecha_pago>
              <ng-container *ngIf="column.prop == 'fecha_pago'; else usuario_sb_entregado">
                {{ row.factura?.pago[0]?.updated_at | date:'dd-MM-yyyy' }}
              </ng-container>
            </ng-template>

            <ng-template #usuario_sb_entregado>
              <ng-container *ngIf="column.prop == 'usuario_sb_entregado'; else usuario_sb_pago">
                {{ row.estado_historial | filterUsuarioEntregado }}
              </ng-container>
            </ng-template>

            <ng-template #usuario_sb_pago>
              <ng-container *ngIf="column.prop == 'usuario_sb_pago'; else usuario_sb_compra">
                {{ usuariosService.get_fullname(row.factura?.pago[0]?.usuario) }}
              </ng-container>
            </ng-template>

            <ng-template #usuario_sb_compra>
              <ng-container *ngIf="column.prop == 'usuario_sb_compra'; else metodo_pago">
                {{ usuariosService.get_fullname(row.created_by) }}
              </ng-container>
            </ng-template>

            <ng-template #metodo_pago>
              <ng-container *ngIf="column.prop == 'metodo_pago'; else fecha_cancelado">
                {{ row.metodo_pago?.nombre }}
              </ng-container>
            </ng-template>

            <ng-template #fecha_cancelado>
              <ng-container *ngIf="column.prop == 'fecha_cancelado'; else referencia">
                {{ row.fecha_cancelado | date:'dd-MM-yyyy'}}
              </ng-container>
            </ng-template>

            <ng-template #referencia>
              <ng-container *ngIf="column.prop == 'referencia'; else responsable">
                {{ row.referencia }}
              </ng-container>
            </ng-template>

            <ng-template #responsable>
              <ng-container *ngIf="column.prop == 'responsable'; else respuesta">
                {{ usuariosService.get_fullname(row.responsable) }}
              </ng-container>
            </ng-template>

            <ng-template #respuesta>
              <ng-container *ngIf="column.prop == 'respuesta'; else monto_cancelado">
                {{ row.respuesta ? row.respuesta.ResponseMessage ? row.respuesta.ResponseMessage :
                row.respuesta.ResponseMessage : '-'}}
              </ng-container>
            </ng-template>

            <ng-template #monto_cancelado>
              <ng-container *ngIf="column.prop == 'monto_cancelado'; else metodo_pago_cliente">
                {{ row.monto_crc | currency: 'CRC':'symbol-narrow':'1.2-2' }}
              </ng-container>
            </ng-template>

            <ng-template #metodo_pago_cliente>
              <ng-container *ngIf="column.prop == 'metodo_pago_cliente'; else factura_numero">
                {{ row.metodo_pago }}
              </ng-container>
            </ng-template>

            <ng-template #factura_numero>
              <ng-container *ngIf="column.prop == 'factura_numero'; else factura_generada">
                {{ row.factura?.numero }}
              </ng-container>
            </ng-template>

            <ng-template #factura_generada>
              <ng-container *ngIf="column.prop == 'factura_generada'; else detalle_gti">
                {{row.factura?.generado_gti ? 'Generada' : 'Pendiente'}}
              </ng-container>
            </ng-template>

            <ng-template #detalle_gti>
              <ng-container *ngIf="column.prop == 'detalle_gti'; else check_factura">
                {{ row.factura?.detalle_gti }}
              </ng-container>
            </ng-template>


            <ng-template #check_factura>
              <ng-container *ngIf="column.prop == 'check_factura'; else check_pago">
                <mat-checkbox [disabled]="true" #estado_factura_check [checked]="row.estado_factura"></mat-checkbox>
              </ng-container>
            </ng-template>

            <ng-template #check_pago>
              <ng-container *ngIf="column.prop == 'check_pago'; else emitir">
                <mat-checkbox [disabled]="true" #estado_pago_check [checked]="row.estado_pago"></mat-checkbox>
              </ng-container>
            </ng-template>

            <ng-template #emitir>
              <ng-container *ngIf="column.prop == 'emitir'; else tipoCambio">
                <button mat-flat-button color="primary" class="button-calcular ml-0"
                  (click)="consolidarFactura(row.factura.id)">
                  Emitir Factura Electrónica
                </button>

                <button mat-flat-button color="primary" class="button-calcular ml-2"
                  (click)="consolidarTicket(row.factura.id)">
                  Emitir Ticket Electrónico
                </button>
              </ng-container>
            </ng-template>

            <ng-template #tipoCambio>
              <ng-container *ngIf="column.prop == 'tipo_cambio'; else currency">
                {{ row.tipo_cambio | currency: 'CRC':'symbol-narrow':'1.2-2' }}
              </ng-container>
            </ng-template>

            <ng-template #currency>
              <ng-container *ngIf="column.currency == true; else neted_by">
                {{ row[column.prop] | currency: 'CRC':'symbol-narrow':'1.2-2' }}
              </ng-container>
            </ng-template>

            <ng-template #neted_by>
              <ng-container *ngIf="column.prop == 'neted_by'; else neted_at">
                <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.usuario.id}"> {{
                  usuariosService.get_fullname(row.neted_by) }}</a>
              </ng-container>
            </ng-template>

            <ng-template #neted_at>
              <ng-container *ngIf="column.prop == 'neted_at'; else monto_total">
                {{ row[column.prop] | date:'dd-MM-yyyy'}}
              </ng-container>
            </ng-template>

            <ng-template #monto_total>
              <ng-container *ngIf="column.prop == 'monto_total'; else monto_pendiente">
                {{ row.factura ? (row.factura.total_crc | currency: 'CRC':'symbol-narrow':'1.2-2') : '-' }}
                {{ row.factura ? ('(' + (row.factura.total_usd | currency: 'USD':'symbol-narrow':'1.2-2') + ')') : '' }}
              </ng-container>
            </ng-template>

            <ng-template #monto_pendiente>
              <ng-container *ngIf="column.prop == 'monto_pendiente'; else ultima_actualizacion">
                {{ row.factura.monto_pendiente_crc | currency: 'CRC':'symbol-narrow':'1.2-2' }}
                ({{row.factura.monto_pendiente_usd | currency: 'USD':'symbol-narrow':'1.2-2'}})
              </ng-container>
            </ng-template>

            <ng-template #ultima_actualizacion>
              <ng-container *ngIf="column.prop == 'ultima_actualizacion'; else abandoned_by">
                {{ row.estado_historial | ultimaActualizacion }}
              </ng-container>
            </ng-template>

            <ng-template #abandoned_by>
              <ng-container *ngIf="column.prop == 'abandoned_by'; else abandoned_actions">
                <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.estado_abandono.usermodify.id}"> {{
                  row.estado_abandono ?
                  usuariosService.get_fullname(row.estado_abandono.usermodify) : '-' }}</a>
              </ng-container>
            </ng-template>

            <ng-template #abandoned_actions>
              <ng-container *ngIf="column.prop == 'abandoned_actions'; else default" style="width: auto; ">
                <button mat-flat-button
                  style="background-color: green !important; color: white !important; margin-right: 20px;"
                  (click)="emitCreditNote(row)">
                  <mat-icon style="color: white !important;">description</mat-icon>Emitir
                </button>
                <button mat-flat-button style="background-color: red !important; color: white !important;"
                  (click)="deleteNotaCredito()">
                  <mat-icon style="color: white !important;">delete</mat-icon>Eliminar
                </button>
              </ng-container>
            </ng-template>


            <ng-template #default>
              {{ row[column.prop] || '-' }}
            </ng-template>

          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="rowexpansion" let-row let-columns="columns">
        <tr class="report-cells">
          <td *ngFor="let column of columns">
            <ng-container *ngIf="column.prop == 'cuenta'; else ccuenta">
              <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.usuario.id}"> {{row.usuario.cuenta}}</a>
            </ng-container>

            <ng-template #ccuenta>
              <ng-container *ngIf="column.prop == 'ccuenta'; else xcuenta">
                <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.cuenta.id}"> {{row.cuenta.cuenta}}</a>
              </ng-container>
            </ng-template>

            <ng-template #xcuenta>
              <ng-container *ngIf="column.prop == 'xcuenta'; else cliente">
                <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.id}"> {{row.cuenta}}</a>
              </ng-container>
            </ng-template>

            <ng-template #cliente>
              <ng-container *ngIf="column.prop == 'cliente'; else ccliente">
                <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.usuario.id}"> {{
                  usuariosService.get_fullname(row.usuario) }}</a>
              </ng-container>
            </ng-template>

            <ng-template #ccliente>
              <ng-container *ngIf="column.prop == 'ccliente'; else xcliente">
                <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.cuenta.id}"> {{
                  usuariosService.get_fullname(row.cuenta) }}</a>
              </ng-container>
            </ng-template>

            <ng-template #xcliente>
              <ng-container *ngIf="column.prop == 'xcliente'; else vendedor">
                <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.id}"> {{
                  usuariosService.get_fullname(row)
                  }} </a>
              </ng-container>
            </ng-template>

            <ng-template #vendedor>
              <ng-container *ngIf="column.prop == 'vendedor'; else total_factura">
                <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.vendedor.id}"> {{
                  usuariosService.get_fullname(row.vendedor)}} </a>
              </ng-container>
            </ng-template>

            <ng-template #percentage>
              <ng-container *ngIf="column.percentage == true; else total_factura">
                {{ row[column.prop] | number:'1.2-2' }}%
              </ng-container>
            </ng-template>

            <ng-template #total_factura>
              <ng-container *ngIf="column.prop == 'total_factura'; else pago_1">
                {{row.factura?.factura_crc.total | currency: 'CRC':'symbol-narrow':'1.2-2'}}
              </ng-container>
            </ng-template>

            <ng-template #pago_1>
              <ng-container *ngIf="column.prop == 'pago_1'; else pago_2">
                {{row.factura?.pago[0]?.referencia}}
              </ng-container>
            </ng-template>

            <ng-template #pago_2>
              <ng-container *ngIf="column.prop == 'pago_2'; else guias_status">
                {{row.factura?.pago[1]?.referencia}}
              </ng-container>
            </ng-template>

            <ng-template #guias_status>
              <ng-container *ngIf="column.prop == 'guias_status'; else guias_vendedor">
                <span *ngFor="let p of row.paquetes">
                  <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: p.id}">{{p.guia}}&nbsp; {{p.fecha
                    | date: 'dd-MM-yyyy'}} &nbsp;</a>
                </span>
              </ng-container>
            </ng-template>

            <ng-template #guias_vendedor>
              <ng-container *ngIf="column.prop == 'guias_vendedor'; else guia_clased">
                <span *ngFor="let p of row.paquetes">
                  <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: p.id}">{{p.guia}}&nbsp; </a>
                </span>
              </ng-container>
            </ng-template>

            <ng-template #guia_clased>
              <ng-container *ngIf="column.prop == 'guia_clased'; else guia_compra">
                <a [routerLink]="['/admin/paquetes/paquete']"
                  [queryParams]="{pack: row.id}">{{row.numero_de_guia}}&nbsp; </a>
              </ng-container>
            </ng-template>

            <ng-template #guia_compra>
              <ng-container *ngIf="column.prop == 'guia_compra'; else guia_pago">
                <a [routerLink]="['/admin/paquetes/paquete']"
                  [queryParams]="{pack: row.paquete.id}">{{row.paquete.numero_de_guia}} </a>
              </ng-container>
            </ng-template>

            <ng-template #guia_pago>
              <ng-container *ngIf="column.prop == 'guia_pago'; else id_compra">
                <!-- <a [routerLink]="['/admin/paquetes/paquete']"
                  [queryParams]="{pack: row.guias[0].id}">{{row.guias[0].guia}} </a> -->
                {{row.guias}}
              </ng-container>
            </ng-template>

            <ng-template #id_compra>
              <ng-container *ngIf="column.prop == 'id_compra'; else fecha_nacimiento">
                <a [routerLink]="['/admin/compras-corporativas/agregar']" [queryParams]="{id: row.id}">{{row.id}}&nbsp;
                </a>
              </ng-container>
            </ng-template>

            <ng-template #fecha_nacimiento>
              <ng-container *ngIf="column.prop == 'fecha_nacimiento'; else fecha">
                {{ row[column.prop] | date:'dd-MM'}}
              </ng-container>
            </ng-template>

            <ng-template #fecha>
              <ng-container *ngIf="column.fecha == true; else fecha_entrega">
                {{ row[column.prop] | date:'dd-MM-yyyy'}}
              </ng-container>
            </ng-template>

            <ng-template #fecha_entrega>
              <ng-container *ngIf="column.prop == 'fecha_entrega'; else hora_entrega">
                {{row.fecha_entrega | date:'dd-MM-yyyy'}}
              </ng-container>
            </ng-template>

            <ng-template #hora_entrega>
              <ng-container *ngIf="column.prop == 'hora_entrega'; else fecha_pago">
                {{row.hora_entrega | date:'dd-MM-yyyy'}}
              </ng-container>
            </ng-template>

            <ng-template #fecha_pago>
              <ng-container *ngIf="column.prop == 'fecha_pago'; else usuario_sb_entregado">
                {{ row.factura?.pago[0]?.updated_at | date:'dd-MM-yyyy' }}
              </ng-container>
            </ng-template>

            <ng-template #usuario_sb_entregado>
              <ng-container *ngIf="column.prop == 'usuario_sb_entregado'; else ultima_actualizacion">
                {{ row.estado_historial | filterUsuarioEntregado }}
              </ng-container>
            </ng-template>

            <ng-template #ultima_actualizacion>
              <ng-container *ngIf="column.prop == 'ultima_actualizacion'; else usuario_sb_pago">
                {{ row.estado_historial | ultimaActualizacion }}
              </ng-container>
            </ng-template>

            <ng-template #usuario_sb_pago>
              <ng-container *ngIf="column.prop == 'usuario_sb_pago'; else usuario_sb_compra">
                {{ usuariosService.get_fullname(row.factura?.pago[0]?.usuario) }}
              </ng-container>
            </ng-template>

            <ng-template #usuario_sb_compra>
              <ng-container *ngIf="column.prop == 'usuario_sb_compra'; else metodo_pago">
                {{ usuariosService.get_fullname(row.created_by) }}
              </ng-container>
            </ng-template>

            <ng-template #metodo_pago>
              <ng-container *ngIf="column.prop == 'metodo_pago'; else fecha_cancelado">
                {{ row.factura?.pago[0]?.metodo_pago.nombre }}
              </ng-container>
            </ng-template>

            <ng-template #fecha_cancelado>
              <ng-container *ngIf="column.prop == 'fecha_cancelado'; else referencia">
                {{ row.factura?.pago[0]?.updated_at | date:'dd-MM-yyyy'}}
              </ng-container>
            </ng-template>

            <ng-template #referencia>
              <ng-container *ngIf="column.prop == 'referencia'; else responsable">
                {{ row.factura?.pago[0]?.referencia }}
              </ng-container>
            </ng-template>

            <ng-template #responsable>
              <ng-container *ngIf="column.prop == 'responsable'; else respuesta">
                {{ usuariosService.get_fullname(row.factura?.pago[0]?.comprobante?.responsable) }}
              </ng-container>
            </ng-template>

            <ng-template #respuesta>
              <ng-container *ngIf="column.prop == 'respuesta'; else monto_cancelado">
                {{ row.factura?.pago[0]?.respuesta ? row.factura?.pago[0]?.respuesta.ResponseMessage ?
                row.factura?.pago[0]?.respuesta.ResponseMessage :
                row.factura?.pago[0]?.respuesta.ResponseMessage: '-'}}
              </ng-container>
            </ng-template>

            <ng-template #monto_cancelado>
              <ng-container *ngIf="column.prop == 'monto_cancelado'; else metodo_pago_cliente">
                {{ row.factura?.pago[0]?.monto_crc | currency: 'CRC':'symbol-narrow':'1.2-2' }}
              </ng-container>
            </ng-template>

            <ng-template #metodo_pago_cliente>
              <ng-container *ngIf="column.prop == 'metodo_pago_cliente'; else factura_numero">
                {{ row.metodo_pago }}
              </ng-container>
            </ng-template>

            <ng-template #factura_numero>
              <ng-container *ngIf="column.prop == 'factura_numero'; else factura_generada">
                {{ row.factura?.numero }}
              </ng-container>
            </ng-template>

            <ng-template #factura_generada>
              <ng-container *ngIf="column.prop == 'factura_generada'; else detalle_gti">
                {{row.factura?.generado_gti ? 'Generada' : 'Pendiente'}}
              </ng-container>
            </ng-template>

            <ng-template #detalle_gti>
              <ng-container *ngIf="column.prop == 'detalle_gti'; else check_factura">
                {{ row.factura?.detalle_gti }}
              </ng-container>
            </ng-template>

            <ng-template #check_factura>
              <ng-container *ngIf="column.prop == 'check_factura'; else check_pago">
                <mat-checkbox [disabled]="true" #estado_factura_check [checked]="row.estado_factura"></mat-checkbox>
              </ng-container>
            </ng-template>

            <ng-template #check_pago>
              <ng-container *ngIf="column.prop == 'check_pago'; else currency">
                <mat-checkbox [disabled]="true" #estado_check [checked]="row.estado_pago"></mat-checkbox>
              </ng-container>
            </ng-template>

            <ng-template #currency>
              <ng-container *ngIf="column.currency == true; else neted_by">
                {{ row[column.prop] | currency: 'CRC':'symbol-narrow':'1.2-2' }}
              </ng-container>
            </ng-template>

            <ng-template #neted_by>
              <ng-container *ngIf="column.prop == 'neted_by'; else neted_at">
                <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: row.usuario.id}"> {{
                  usuariosService.get_fullname(row.neted_by) }}</a>
              </ng-container>
            </ng-template>

            <ng-template #neted_at>
              <ng-container *ngIf="column.prop == 'neted_at'; else default">
                {{ row[column.prop] | date:'dd-MM-yyyy'}}
              </ng-container>
            </ng-template>

            <ng-template #default>
              {{ row[column.prop] }}
            </ng-template>

          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer" let-columns>
        <tr *ngIf="dataSource?.length > 0">
          <th *ngFor="let col of columns" style="margin-left: 10px !important; margin-right: 10px !important;">
            <span *ngIf="col.total"> {{col.total_value }} {{ totals[col.prop] | currency:
              'CRC':'symbol-narrow':'1.2-2'}} </span>
          </th>
        </tr>
      </ng-template>
    </p-table>

  </div>

  <mat-divider></mat-divider>

  <div class="paginator text-center">
    <pagination-controls autoHide="true" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
      (pageBoundsCorrection)="pageIndex = $event; paginate(pageIndex)"
      (pageChange)="pageIndex = $event; paginate(pageIndex)"></pagination-controls>
  </div>

</div>
