/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-ckeditor',
  templateUrl: './ckeditor.component.html',
  styleUrls: ['./ckeditor.component.scss']
})
export class CkeditorComponent implements OnInit {
  @Input() template: any;
  @Input() send: any;
  @Input() modal: any;

  @Output() content = new EventEmitter<any>();
  @Output() sending = new EventEmitter<any>();

  public guardando = false;

  editorConfig: any = {
    fontFamily: {
      options: [
        'default',
        'Arial/Arial, Helvetica, sans-serif;',
        'Calibri/Calibri, Verdana, Geneva, sans-serif;',
        'Comic Sans MS/Comic Sans MS, cursive;',
        'Courier New/Courier New, Courier, monospace;',
        'Tahoma/Tahoma, Geneva, sans-serif;',
        'Times New Roman/Times New Roman, Times, serif;',
        'Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;',
      ]
    },
    extraPlugins: 'div'
  };

  constructor() { }

  ngOnInit() {
  }

  public guardar = (valor: any, sending: any): void => {
    this.guardando = true;
    this.sending.emit(sending);
    this.content.emit(valor);
    setTimeout(() => {
      this.guardando = false;
    }, 1000);
  };

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

}
