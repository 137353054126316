<div class="content p-1" fxLayout="column" style="width: 100% !important;">
  <form [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutGap="20px" class="pr-4 pl-4 pt-2 pb-2 mt-2 inputs-form"
      style="display: flex; align-items: center;">

      <div>
        <label class="label">Tipo</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <mat-select placeholder="Seleccione" formControlName="tipo">
            <mat-option *ngFor="let metodo of metodosPago" [value]="metodo.id">
              {{metodo.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <label class="label">Paquete</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Paquete" formControlName="paquete" class="input-field">
        </mat-form-field>
      </div>

      <div>
        <label class="label">Desde</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Hasta e Incluido" formControlName="date_from" type="date" class="input-field">
        </mat-form-field>
      </div>

      <div>
        <label class="label">Hasta</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Hasta e Incluido" formControlName="date_until" type="date" class="input-field">
        </mat-form-field>
      </div>

      <div>
        <button mat-button (click)="applyFilter()">
          <img src="assets/iconos-dashboard/Lupa.png">Buscar
        </button>
      </div>
      <div>
        <button mat-button (click)="cleanFilters()">
          <img src="assets/iconos-dashboard/Facturas.png">Limpiar
        </button>
      </div>
    </div>
  </form>
  <!--
  <div>
    <label class="label">Anulada</label><br>
    <mat-form-field appearance="outline" class="form-field" >
      <input matInput placeholder="Anulada" class="input-field">
    </mat-form-field>
  </div> -->

  <mat-divider></mat-divider>

  <!-- <div class="botones-header-title">
    <button mat-button class="mr-4">
      <mat-icon>fiber_manual_record</mat-icon>Imprimir Reporte
    </button>
    <button mat-button class="mr-4">
      <mat-icon>fiber_manual_record</mat-icon>Exportar Cargos
    </button>
    <button mat-button>
      <mat-icon>fiber_manual_record</mat-icon>Reporte Cobros Electronicos
    </button>
  </div> -->


  <div class="content">

    <div class="content-table m-4">
      <table mat-table
        [dataSource]="dataSource | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length}"
        matSort (matSortChange)="sortData($event)">

        <!-- usuario Column -->
        <ng-container matColumnDef="usuario">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Usuario </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.usuario.cuenta}} </td>
        </ng-container>

        <!-- factura Column -->
        <ng-container matColumnDef="factura">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Factura </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.factura}} </td>
        </ng-container>

        <!-- factura Column -->
        <ng-container matColumnDef="guias">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Guias </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element">
            <span *ngFor="let guia of element.guias">
              <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: guia.id, factura: true}">
                {{guia.guia || '-'}}
              </a><br>
            </span>

          </td>
        </ng-container>

        <!-- cuenta Column -->
        <ng-container matColumnDef="cuenta">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cuenta </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{usuariosService.get_fullaccount(element.usuario) }}
          </td>
        </ng-container>

        <!-- fecha Column -->
        <ng-container matColumnDef="fecha">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha y Hora </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.created_at | date: 'dd-MM-yyyy HH:mm'}}
          </td>
        </ng-container>

        <!-- respuesta Column -->
        <ng-container matColumnDef="respuesta">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Respuesta del Banco </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.respuesta ?
            element.respuesta.ResponseMessage ? element.respuesta.ResponseMessage : element.respuesta.ResponseMessage :
            '-'}} </td>
        </ng-container>

        <!-- pago Column -->
        <ng-container matColumnDef="pago">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Método de Pago </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.metodo_pago.nombre}} </td>
        </ng-container>

        <!-- monto Column -->
        <ng-container matColumnDef="monto_usd">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Monto USD </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.monto.toFixed(2) | currency:
            'USD':'symbol-narrow':'1.2-2'}} </td>
        </ng-container>

        <!-- monto Column -->
        <ng-container matColumnDef="monto_crc">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Monto CRC </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.monto_crc.toFixed(2) | currency:
            'CRC':'symbol-narrow':'1.2-2'}} </td>
        </ng-container>

        <!-- referencia Column -->
        <ng-container matColumnDef="referencia">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Referencia </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.referencia}} </td>
        </ng-container>

        <!-- creador Column -->
        <ng-container matColumnDef="creador">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Creador </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element">
            {{usuariosService.get_fullaccount(element.created_by)}} </td>
        </ng-container>

        <!-- opciones Column -->
        <ng-container matColumnDef="opciones">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Opciones </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element">
            <button mat-icon-button (click)="deletePago(element.id)" *ngIf="authService.isAdmin">
              <mat-icon style="color: red !important; font-size: 16px !important;">delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>

  <div class="paginator text-center">
    <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
      (pageChange)="pageIndex = $event; paginate(pageIndex)"></pagination-controls>
  </div>

</div>