import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compras-corporativas',
  templateUrl: './compras-corporativas.component.html',
  styleUrls: ['./compras-corporativas.component.scss']
})
export class ComprasCorporativasComponent implements OnInit {

  ngOnInit() {

  }
  constructor() { }
}
