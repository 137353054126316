import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ComprasCorporativasService } from 'src/app/services/compras-corporativas.service';
import { UsuariosService } from 'src/app/services/usuarios.service';

export interface Actions {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  length: number;
  pageSize = 10;
  pageIndex = 1;

  displayedColumns = ['id', 'fecha', 'cuenta', 'descripcion', 'remitente', 'tipo', 'pago1', 'pago2'];
  dataSource;

  form = this.fb.group({
    date_from: [''],
    date_until: ['']
  });


  tipoCompra: Actions[] = [
    { value: 1, viewValue: 'Compra Corporativa Cliente' },
    { value: 2, viewValue: 'Cumpleaños Empleado' },
    { value: 3, viewValue: 'Reposición' },
    { value: 4, viewValue: 'Compra de Gerencia' },
  ];

  constructor(
    private fb: FormBuilder,
    private comprasCorporativasService: ComprasCorporativasService,
    public usuariosService: UsuariosService,
  ) { }

  ngOnInit() {
    this.getCompras();
  }

  getCompras(params = '') {
    this.comprasCorporativasService.get(this.pageIndex, params).subscribe((data: any) => {
      this.dataSource = data.results;
      this.length = data.pagination.total_count;
    });
  }

  getTipoName(value) {
    const item = this.tipoCompra.filter(function(item) {
      return item.value == value;
    });
    if (item[0] != undefined && item[0] != null) {
      return item[0].viewValue;
    }
    return '';
  }

  public applyFilters() {
    this.form.value.date_from == null ? this.form.value.date_from = '' : '';
    this.form.value.date_until == null ? this.form.value.date_until = '' : '';

    let filter = '';

    Object.keys(this.form.value).forEach(async key => {
      if (this.form.value[key] !== '') {
        //console.log(key + ' - ' + this.form.value[key]); // key - value

        if (key === 'date_from') {
          filter = filter + '&fecha_compra__from=' + this.form.value[key];
        }

        if (key === 'date_until') {
          filter = filter + '&fecha_compra__to=' + this.form.value[key];
        }

      }
    });

    this.getCompras(filter);

  }
  public cleanFilters() {
    this.pageSize = 10;
    this.pageIndex = 1;
    this.getCompras();
    this.form.reset();
  }
  paginate(event: any) {
    //console.log(event);
    this.pageIndex = event;
    this.getCompras();
  }
}
