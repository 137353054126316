/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable space-before-function-paren */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-output-native */

import { Component, ViewChild, OnInit, ComponentFactoryResolver, ApplicationRef, Injector, OnDestroy, AfterViewInit, EventEmitter, Output, HostListener, Input } from '@angular/core';
import { CdkPortal, DomPortalHost } from '@angular/cdk/portal';

/**
 * This component template wrap the projected content
 * with a 'cdkPortal'.
 */

@Component({
  selector: 'window',
  template: `
    <ng-container *cdkPortal>
      <ng-content></ng-content>
    </ng-container>
  `
})
export class WindowComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() close = new EventEmitter<boolean>();
  @Input() print = false;

  // STEP 1: get a reference to the portal
  @ViewChild(CdkPortal) portal: CdkPortal;

  // STEP 2: save a reference to the window so we can close it
  private externalWindow = null;

  // STEP 3: Inject all the required dependencies for a PortalHost
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector) { }


  ngOnInit() {

  }

  ngAfterViewInit() {
    // STEP 4: create an external window
    const width = this.print ? '1024' : '700';
    const height = this.print ? '768' : '400';
    this.externalWindow = window.open('', '', 'width=' + width + ',height=' + height + ',left=200,top=200,toolbar=no,titlebar=no');

    // STEP 5: create a PortalHost with the body of the new window document
    const host = new DomPortalHost(
      this.externalWindow.document.body,
      this.componentFactoryResolver,
      this.applicationRef,
      this.injector
    );

    // STEP 5.1
    let sc = document.createElement('link');
    sc.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap@4.1.3/dist/css/bootstrap.min.css');
    sc.setAttribute('rel', 'stylesheet');
    sc.setAttribute('integrity', 'sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO');
    sc.setAttribute('crossorigin', 'anonymous');

    sc = document.createElement('link');
    sc.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap@4.1.3/dist/css/bootstrap.min.css');
    sc.setAttribute('rel', 'stylesheet');
    sc.setAttribute('integrity', 'sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO');
    sc.setAttribute('crossorigin', 'anonymous');

    this.externalWindow.document.head.appendChild(sc);

    // STEP 5.1: clone stylesheets and style tags to external window
    document.querySelectorAll('link, style').forEach(htmlElement => {
      this.externalWindow.document.head.appendChild(htmlElement.cloneNode(true));
    });

    //console.log(this.externalWindow);

    // STEP 6: Attach the portal
    host.attach(this.portal);

    const own = this;
    this.externalWindow.addEventListener('beforeunload', function (e) {
      e.preventDefault();
      own.externalWindow.close();
      own.close.emit(true);
      //console.log('Destroy window Portal');
    });

    if (this.print) {
      const printStyle = this.externalWindow.document.querySelectorAll('.pagebreak');

      printStyle.forEach(function (style) {
        style.style.cssText = 'clear: both; page-break-after: always; transform: scale(0.97)';
      });

      own.externalWindow.window.print();
    }
  }

  ngOnDestroy() {
    // STEP 7: close the window when this component destroyed
    this.externalWindow.close();
    this.close.emit(true);
    //console.log('Destroy window Portal');
  }

}


