import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DialogModalComponent } from 'src/app/modules/admin/components/dialog-modal/dialog-modal.component';
import { ConfirmarComponent } from 'src/app/modules/common/dialogs/confirmar/confirmar.component';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { GeneralInputComponent } from 'src/app/modules/common/dialogs/general-input/general-input.component';
import { AuthService } from 'src/app/services/auth.service';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { TarifasService } from 'src/app/services/tarifas.service';

@Component({
  selector: 'app-tarifas',
  templateUrl: './tarifas.component.html',
  styleUrls: ['./tarifas.component.scss']
})
export class TarifasComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = this.authService.isOperacionesCostaRica2 ?  ['id', 'name'] : ['id', 'name', 'acciones'];
  dataSource = new MatTableDataSource<any>();
  length = 0;

  constructor(
    public tarifasService: TarifasService,
    public authService: AuthService,
    private paquetesService: PaquetesService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getTarifas();
  }

  deleteTarifa(id) {

    //console.log(id);
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      data: { message: 'Esta operación es definitiva y no puede reversarse. ¿Confirma que desea eliminar esta tarifa?.' },
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.tarifasService.deleteTarifa(id).subscribe((res: any) => {
          this.openDialog('Tarifa eliminada');
          this.dataSource.data = this.dataSource.data.filter((value, key) => value.id !== id);
        });
      }
    });
  }

  openDialogModalCustom(action, obj) {

    const dialogRef = this.dialog.open(DialogModalComponent, {
      width: '350px',
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Delete') {
        this.deleteRowData(result.data);
      }
    });
  }

  deleteRowData(row_obj) {
    //console.log(row_obj.id)
    this.paquetesService.deleteTarifas(row_obj.id).subscribe((res: any) => {
      this.openDialog('Tarifa eliminada');

    });

  }


  public getTarifas() {

    this.paquetesService.getTarifas().subscribe((data: any) => {
      this.length = data.length;
      this.dataSource.data = data;
    });

  }

  crearTarifa() {

    const dialogRef = this.dialog.open(GeneralInputComponent, {
      data: { title: 'Seleccione tipo de tarifa', value: 'A', type: 'tarifa' },
      height: '250px',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      if (result != null) {
        let tarifa = 0;

        if (result.value === 'A') {
          tarifa = 1;
        }

        if (result.value === 'M') {
          tarifa = 2;
        }

        this.router.navigate(['administracion/tarifas/tarifa'], { queryParams: { pack: tarifa, new: true } });
      }
    });

  }

  private openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }

}
