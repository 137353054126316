/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Usuario } from '../models/Usuario';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { actionRoleMap } from '../core/data/roleMap';

import * as forge from 'node-forge';
import { Router } from '@angular/router';
forge.options.usePureJavaScript = true;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public activeRol: string;

  public isAdmin = false;
  public isMiami = false;
  public isEmbarques = false;
  public isServicioCliente = false;
  public isOperacionesMiami = false;
  public isOperacionesCostaRica = false;
  public isPedimentacion = false;
  public isVendedor = false;
  public isContabilidad = false;
  public isCliente = false;
  public isOperacionesCostaRica2 = false;

  private loginRoute = `${environment.apiUrl}/api/token/`;
  private userRout = `${environment.apiUrl}/usuarios/`;
  private historyRoute = `${environment.apiUrl}/historias`;

  private registerRoute = `${environment.apiUrl}/registro/`;
  private authorizationsRoute = `${environment.apiUrl}/authorizations/`;
  private recomiendaRoute = `${environment.apiUrl}/usuarios/recommend_mail`;

  private notificationsRoute = `${environment.apiUrl}/notificaciones/`;
  private emailRoute = `${environment.apiUrl}/emails/`;

  private publickKey;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {

    fetch('./assets/receiver.pem').then(response => response.text()).then(publicKey => {
      // Do something with your data
      this.publickKey = publicKey;
    });

  }

  hashPassword(password) {
    const key = forge.pki.publicKeyFromPem(this.publickKey);
    // console.log(password);
    try {
      const hash = key.encrypt(forge.util.encodeUtf8(password));
      return forge.util.encode64(hash);
    } catch {
      return false;
    }

  }
  //console.log('encrypted:', forge.util.encode64(hash));


  historial(page) {
    return this.httpClient.get(this.historyRoute + '?per_page=10&page_no=' + page).pipe(
      catchError(this.handleError<any>())
    );
  }

  login(credenciales: any): Observable<any> {
    // console.log('credenciales', credenciales);
    credenciales.password = this.hashPassword(credenciales.password);
    return this.httpClient.post(this.loginRoute, credenciales, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  logOut(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('authenticator');
  }

  registro(usuario: Usuario) {
    return this.httpClient.post(this.registerRoute, usuario, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  isLogin(): boolean {
    return localStorage.getItem('token') != null;
  }

  getToken(): any {
    return JSON.parse(localStorage.getItem('token'));
  }

  getUser(): Usuario {
    if (this.isLogin()) {
      const data = JSON.parse(localStorage.getItem('user'));
      return new Usuario(data);
    }
    return null;
  }

  getUserById(id) {
    return this.httpClient.get(this.userRout + id, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  getUsers() {
    return this.httpClient.get(this.userRout, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  createUser(user_data) {
    return this.httpClient.post(this.userRout, user_data, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  createDatosFactura(datos_factura) {
    return this.httpClient.post(this.userRout + 'datosfacturacion', datos_factura, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  updateUser(user, id) {
    return this.httpClient.put(this.userRout + id, user, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  updateToken(token, id) {
    const data = {
      platform: 'Web',
      push_token: token,
      user: id
    };

    return this.httpClient.put(this.userRout + 'push_token/' + id, data, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  generateSecretkey(id) {
    return this.httpClient.put(this.userRout + 'generate/' + id, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  updateDatosFactura(datosfactura, id) {
    return this.httpClient.put(this.userRout + 'datosfacturacion/' + id, datosfactura, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  verification(pin, id) {
    return this.httpClient.post(this.userRout + 'authentificator/' + id, { pin }, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  get_URI(id) {
    return this.httpClient.post(this.userRout + 'uri/' + id, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  createAuthorization(authorization) {
    return this.httpClient.post(this.authorizationsRoute, authorization, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  editAuthorization(authorization) {
    return this.httpClient.put(`${this.authorizationsRoute}${authorization.id}`, authorization, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  deleteAuthorization(authorization_id) {
    return this.httpClient.delete(`${this.authorizationsRoute}${authorization_id}`, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }


  getAuthorizations(user_id) {
    return this.httpClient.get(this.authorizationsRoute + user_id, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }


  recomienda(recomend) {
    return this.httpClient.post(this.recomiendaRoute, recomend, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  setProfilePicture(id, user_data) {
    return this.httpClient.post(this.userRout + 'imgprofile/' + id, user_data, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  getNotifications(user_id) {
    return this.httpClient.get(this.notificationsRoute + user_id, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  setNotifications(id, user_data) {
    return this.httpClient.put(this.notificationsRoute + id, user_data, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  createNotifications(user_data) {
    return this.httpClient.post(this.notificationsRoute, user_data, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  searchAuthorizations(value) {
    return this.httpClient.get(this.authorizationsRoute + 'buscar/?search=' + value, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  sendEmail(data) {
    return this.httpClient.post(this.emailRoute + 'test/', data);
  }
  sendEmailTest(id, data) {
    return this.httpClient.post(this.emailRoute + 'verificate/' + id, data);
  }

  save2fa(status, id, secret_key = null) {

    const data = {
      status
    };

    if (secret_key) {
      data['secret_key'] = secret_key;
    }

    return this.httpClient.post(this.userRout + 'save2fa/' + id, data, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  remove2fa(id) {
    return this.httpClient.get(this.userRout + 'remove2fa/' + id, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  recoverPassword(email) {
    return this.httpClient.get(this.userRout + 'recover_password/' + email, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  setPassword(id, data) {
    return this.httpClient.put(this.userRout + 'set_password/' + id, data, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  getUserTokens(email) {
    return this.httpClient.get(this.userRout + 'user_tokens/' + email, { observe: 'response' }).pipe(
      catchError(this.handleError<any>())
    );
  }

  isSBAdmin() {
    const cuenta = this.getUser().cuenta;
    const validAccounts = ['SBadmin24', 'SB000036', 'SB000037', 'SB008936'];
    return validAccounts.includes(cuenta);
  };

  validateRolePermission(action): boolean {
    const validRoles = actionRoleMap[action];
    return this.activeRol === 'ADMIN' || (validRoles && validRoles.includes(this.activeRol));
  }

  setRol() {

    this.isAdmin = false;
    this.isMiami = false;
    this.isServicioCliente = false;
    this.isServicioCliente = false;
    this.isPedimentacion = false;
    this.isOperacionesMiami = false;
    this.isContabilidad = false;
    this.isCliente = false;
    this.isOperacionesCostaRica2 = false;

    if (this.activeRol == 'ADMIN') {
      this.isAdmin = true;
    }

    if (this.activeRol == 'ALMAC') {
      this.isMiami = true;
    }

    if (this.activeRol == 'SERVCLI') {
      this.isServicioCliente = true;
    }

    if (this.activeRol == 'PEDIM') {
      this.isPedimentacion = true;
    }

    if (this.activeRol == 'OPMIA') {
      this.isOperacionesMiami = true;
    }

    if (this.activeRol == 'OPCR') {
      this.isOperacionesCostaRica = true;
    }

    if (this.activeRol == 'CONTAB') {
      this.isContabilidad = true;
    }

    if (this.activeRol == 'CLIENT') {
      this.isCliente = true;
    }

    if (this.activeRol == 'VEND') {
      this.isVendedor = true;
    }

    if (this.activeRol == 'OPCR2') {
      this.isOperacionesCostaRica2 = true;
    }
    //console.log('Rol Aplicado', this.activeRol);
  }

  goToPage() {
    if (this.activeRol == 'CLIENT') {
      //console.log('go', this.getUser());
      if (this.getUser().tempPass) {
        this.router.navigate(['/dashboard/perfil'], { queryParams: { pass: true } });
      } else {
        this.router.navigate(['/dashboard']);
      }

    } else {
      if (localStorage.getItem('currentUrl')) {
        try {
          this.router.navigateByUrl(localStorage.getItem('currentUrl'));
        } catch {
          this.router.navigate(['/admin']);
        }

        localStorage.removeItem('currentUrl');
      } else {
        this.router.navigate(['/admin']);
      }

    }


    this.setRol();
  }

  private handleError<T>() {
    return (error: any): Observable<T> => of(error as T);
  }

}
