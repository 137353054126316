import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { UsuariosService } from 'src/app/services/usuarios.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  length: number;
  pageSize = 10;
  pageIndex = 1;

  displayedColumns: string[] = ['user', 'rol', 'action', 'table_name', 'created_at'];
  dataSource: any;

  selected = 1;

  constructor(
    private authService: AuthService,
    private packagesService: PaquetesService,
    public usersService: UsuariosService,
  ) { }

  ngOnInit() {
    this.getHistory();
  }

  async getHistory() {
    this.displayedColumns = ['user', 'rol', 'action', 'table_name', 'created_at'];
    const aux = await this.authService.historial(this.pageIndex).toPromise();

    aux.results.forEach((element) => {

      if (element.action.includes('POST')) {
        element.action = 'Crear';
      } else if (element.action.includes('PUT')) {
        element.action = 'Editar';
      } else if (element.action.includes('DELETE')) {
        element.action = 'Borrar';
      }

      if (element.table_name.includes('usuario')) {
        element.table_name = 'Usuarios';
      } else if (element.table_name.includes('paquetes')) {
        element.table_name = 'Paquetes';
      } else if (element.table_name.includes('embarque')) {
        element.table_name = 'Embarques';
      } else if (element.table_name.includes('entrega')) {
        element.table_name = 'Entregas';
      } else {
        element.table_name = 'Undefined';
      }

    });

    this.dataSource = aux.results;
    this.length = aux.count;
  }

  async getHistoryPackagesImages() {

    this.displayedColumns = ['guia', 'imagen', 'fecha', 'usuario'];
    const aux = await this.packagesService.getHistorialImagenes(this.pageIndex).toPromise();
    const data = [];

    aux.results.forEach((element) => {
      const item = {
        image: element.img_cambiada,
        guia: element.guia[0].numero_de_guia,
        fecha: element.update_at,
        usuario: this.usersService.get_fullname(element.usermodify)

      };
      data.push(item);
    });

    this.dataSource = data;
    this.length = aux.count;
  }

  async getHistoryPackagesEstado() {
    this.displayedColumns = ['guia', 'estado', 'fecha', 'usuario'];
    const aux = await this.packagesService.getHistorialEstados(this.pageIndex).toPromise();
    const data = [];

    aux.results.forEach((element) => {
      const item = {
        estado: element.estado.estado,
        guia: element.guia,
        fecha: element.estado_fecha,
        usuario: this.usersService.get_fullname(element.usermodify)
      };
      data.push(item);
    });

    this.dataSource = data;
    this.length = aux.count;
  }

  paginate(event: any) {
    //console.log(event);
    this.pageIndex = event;

    switch (this.selected) {
      case 1:
        this.getHistory();
        break;
      case 2:
        this.getHistoryPackagesImages();
        break;
      case 3:
        this.getHistoryPackagesEstado();
        break;
      default:
        break;
    }

  }

  applyFilter(value) {

  }

}

