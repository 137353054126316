<div class="container-example" fxLayout="column" fxLayoutGap="10px" style="margin-left: auto;margin-right: auto; width:100%; box-shadow: 0px 8px 25px rgba(0, 148, 163, 0.12); background-color: white;"> 
  <div class="container-example">
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Estadísticas
        </mat-panel-title>
      </mat-expansion-panel-header>
      <h5>Estado actual de paquetes</h5>

      <form class="form-type">
        <mat-divider></mat-divider>
        <div class="form-group row">
          <label for="staticEmail" class="col-sm-10 col-form-label">Prealertas</label>
          <div class="col-sm-2">
            <input type="text" class="form-control-plaintext" id="staticEmail" value="284">
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="form-group row">
          <label for="staticEmail" class="col-sm-10 col-form-label">Bodega Miami</label>
          <div class="col-sm-2">
            <input type="text" class="form-control-plaintext" id="staticEmail" value="23">
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="form-group row">
          <label for="staticEmail" class="col-sm-10 col-form-label">Recibidos Miami</label>
          <div class="col-sm-2">
            <input type="text" class="form-control-plaintext" id="staticEmail" value="122">
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="form-group row">
          <label for="staticEmail" class="col-sm-10 col-form-label">En Tránsito a CR</label>
          <div class="col-sm-2">
            <input type="text" class="form-control-plaintext" id="staticEmail" value="283">
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="form-group row">
          <label for="staticEmail" class="col-sm-10 col-form-label">Almacén CR</label>
          <div class="col-sm-2">
            <input type="text" class="form-control-plaintext" id="staticEmail" value="140">
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="form-group row">
          <label for="staticEmail" class="col-sm-10 col-form-label">En Tránsito a Guadalupe</label>
          <div class="col-sm-2">
            <input type="text" class="form-control-plaintext" id="staticEmail" value="0">
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="form-group row">
          <label for="staticEmail" class="col-sm-10 col-form-label">Bodega Guadalupe</label>
          <div class="col-sm-2">
            <input type="text" class="form-control-plaintext" id="staticEmail" value="0">
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="form-group row">
          <label for="staticEmail" class="col-sm-10 col-form-label">Sucursal Guadalupe</label>
          <div class="col-sm-2">
            <input type="text" class="form-control-plaintext" id="staticEmail" value="39">
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="form-group row">
          <label for="staticEmail" class="col-sm-10 col-form-label">En Tránsito a Limón</label>
          <div class="col-sm-2">
            <input type="text" class="form-control-plaintext" id="staticEmail" value="0">
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="form-group row">
          <label for="staticEmail" class="col-sm-10 col-form-label">Sucursal Limón</label>
          <div class="col-sm-2">
            <input type="text" class="form-control-plaintext" id="staticEmail" value="0">
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="form-group row">
          <label for="staticEmail" class="col-sm-10 col-form-label">En Tránsito al cliente</label>
          <div class="col-sm-2">
            <input type="text" class="form-control-plaintext" id="staticEmail" value="0">
          </div>
        </div>
        <mat-divider></mat-divider>

      </form>
      <br><br>
      <h5>Indicadores Clave</h5>
      <form class="form-type">
          <mat-divider></mat-divider>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-10 col-form-label">Paquetes Facturados</label>
            <div class="col-sm-2">
              <input type="text" class="form-control-plaintext" id="staticEmail" value="0">
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-10 col-form-label">Nuevas Cuentas</label>
            <div class="col-sm-2">
              <input type="text" class="form-control-plaintext" id="staticEmail" value="4">
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-10 col-form-label">Recibidos Miami</label>
            <div class="col-sm-2">
              <input type="text" class="form-control-plaintext" id="staticEmail" value="34">
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-10 col-form-label">Entregados Hoy</label>
            <div class="col-sm-2">
              <input type="text" class="form-control-plaintext" id="staticEmail" value="34">
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-10 col-form-label">Transacciones Registradas</label>
            <div class="col-sm-2">
              <input type="text" class="form-control-plaintext" id="staticEmail" value="34">
            </div>
          </div>
          <mat-divider></mat-divider>

        </form>
    </mat-expansion-panel>
  </div>
</div>