<div style="display: block;">
	<canvas baseChart width="100%" height="100%" 
	        [datasets]="lineChartData"
	        [labels]="lineChartLabels"
	        [options]="lineChartOptions"
	        [colors]="lineChartColors"
	        [legend]="lineChartLegend"
	        [chartType]="lineChartType"
	        [plugins]="lineChartPlugins"></canvas>
	        <!-- (chartHover)="chartHovered($event)"
	        (chartClick)="chartClicked($event)" -->
</div>