import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { serialize } from 'object-to-formdata';

import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EmbarquesService {
  selectedPackages = [];
  embarqueData;

  private url = `${environment.apiUrl}/embarques/`;


  constructor(
    private http: HttpClient
  ) { }

  get(page) {
    return this.http.get(this.url + '?per_page=10&page_no=' + page);
  }

  getLast() {
    return this.http.get(this.url + 'miami');
  }

  getPendingEmbarques() {
    return this.http.get(this.url + 'embarque-pending');
  }

  getClose() {
    return this.http.get(this.url + 'close');
  }

  getOpen() {
    return this.http.get(this.url + 'open');
  }

  getByID(id) {
    return this.http.get(this.url + id);
  }

  getSimpleById(id) {
    return this.http.get(`${this.url}simple/${id}`);
  }

  post(data) {
    return this.http.post(this.url, data);
  }

  put(id, data) {
    return this.http.put(this.url + id, data);
  }

  capturarEmbarques(id, data) {
    return this.http.put(this.url + 'capturar/' + id, data);
  }

  capturarEmbarquesAdmin(id, data) {
    return this.http.put(this.url + 'capturar-distribucion/' + id, data);
  }

  capturarPaqueteEmbarque(id, data) {
    return this.http.put(this.url + 'capture-package/' + id, data);
  }

  update(id, data) {
    return this.http.put(this.url + id + '/', data);
  }

  updateawb(id, data) {
    return this.http.put(this.url + 'awb/' + id, data);
  }

  invertirManifiesto(id, tipo) {
    const data = {};
    return this.http.put(this.url + 'manifiesto/' + id + '/' + tipo, data);
  }

  otrosCargos(data) {
    return this.http.put(this.url + 'cargos', data);
  }

  getGuiaAerea(id) {
    return this.http.get(this.url + 'guia-aerea/' + id);
  }

  putGuiaAerea(id, data) {
    return this.http.put(this.url + 'guia-aerea/' + id, data);
  }

  private getFormData(object) {
    if (object.file_a == null) {
      delete object.file_a;
    }
    return serialize(object);
  }
}
