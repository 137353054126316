<div class="general">
  <h3 mat-dialog-title class="modal-title text-center">{{ data.title }}</h3>

  <div class="p-4" style="width: 100%">
    <mat-form-field class="px-1" appearance="outline" style="display: block !important">
      <mat-label>Descripción</mat-label>
      <textarea (ngModelChange)="getItemsSearch($event)" style="width: 100%" matInput [(ngModel)]="detail"></textarea>

      <div class="suggestions" style="height: 100px; overflow: scroll" *ngIf="options.length > 0">
        <p style="cursor: pointer" (click)="detail = s.descripcion; options = []" *ngFor="let s of options">
          {{ s.descripcion }}
        </p>
      </div>
    </mat-form-field>
    <mat-form-field style="display: block !important;" appearance="outline">
      <mat-label>Partida arancelaria</mat-label>
      <input matInput [(ngModel)]="partida">
    </mat-form-field>
  </div>


  <div mat-dialog-actions style="display: flex; justify-content: space-between; margin-bottom: 2px">
    <button mat-flat-button color="primary" class="button1" (click)="onCancelClick()">
      Cancelar
    </button>
    <button mat-flat-button color="primary" class="button1" (click)="onConfirmClick()">
      Aceptar
    </button>
  </div>
</div>