<div style="overflow:scroll;" class="content pt-5">
  <div class="content-title">
    <p class="title text-center">Factura</p>
  </div>

  <div id="pdfTable" #pdfTable>
    <div style="width: 100%;">

      <div style="width: 50%; display: inline-block;">
        <img [src]="getLogo()" alt="logo" class="logo" width="250">
      </div>

      <div style="width: 50%; display: inline-block;">
        <p style="text-align: right;">
          Dirección: De los Tribunales de Justicia en Goicoechea 350 este.
          Teléfono: +(506) 2253-5400
          Fax: +(506) 0
          Correo: hola@superboxcr.com
        </p>
      </div>
    </div>

    <p class="title-factura pb-2" style="text-align: center;">
      <strong>Resumen de Factura #{{factura?.numero}}</strong>
    </p>

    <p class="title-factura pb-2">
      Fecha: {{factura?.updated_at | date: 'dd/MM/yyyy'}} <br>
      <br>

      Cliente: {{ usuariosService.get_fullname(factura?.usuario)}} <br>
      Ident. Física: {{factura?.usuario?.numero_de_cedula}} <br>
      Correo: {{ factura?.usuario.email}} <br>
      Código Interno : {{ factura?.usuario.cuenta}} <br>
      <br>
      Factura de Referencia: {{ paquete.numero_de_guia}} <br>
      Dirección: {{ factura?.usuario?.direccion}} <br>
    </p>

    <br>

    <table style="width:100%;">
      <tr>
        <th>Rubro</th>
        <th>Monto (Colones)</th>
        <th>Monto (USD)</th>
      </tr>
      <tr *ngIf="factura?.flete_internacional > 0">
        <td>Flete</td>
        <td>₡{{factura?.factura_crc.flete_internacional | number : '1.2-2'}}</td>
        <th>${{factura?.factura_usd.flete_internacional | number : '1.2-2'}}</th>
      </tr>
      <tr *ngIf="factura?.servicios_sb > 0">
        <td>Servicio SuperBox</td>
        <td>₡{{factura?.factura_crc.servicios_sb | number : '1.2-2'}}</td>
        <th>${{factura?.factura_usd.servicios_sb | number : '1.2-2'}}</th>
      </tr>
      <tr *ngIf="factura?.cargo_impuestos">
        <td>Impuestos</td>
        <td>₡{{factura?.factura_crc.cargo_impuestos | number : '1.2-2'}}
        </td>
        <th>${{factura?.factura_usd.cargo_impuestos | number : '1.2-2'}}</th>
      </tr>
      <tr *ngIf="factura?.otros_cargos > 0">
        <td>Otros Cargos</td>
        <td>₡{{factura?.factura_crc.otros_cargos | number : '1.2-2'}}</td>
        <th>${{factura?.factura_usd.otros_cargos | number : '1.2-2'}}</th>
      </tr>
      <tr *ngIf="factura?.cargo_encomienda > 0">
        <td>Encomienda</td>
        <td>₡{{factura?.factura_crc.cargo_encomienda | number : '1.2-2'}}</td>
        <th>${{factura?.factura_usd.cargo_encomienda | number : '1.2-2'}}</th>
      </tr>
      <tr>
        <td>Iva</td>
        <td>₡{{factura?.factura_crc.iva | number : '1.2-2'}}</td>
        <th>${{factura?.factura_usd.iva | number : '1.2-2'}}</th>
      </tr>
      <tr *ngIf="factura?.descuento > 0">
        <td>Descuento</td>
        <td> - ₡{{factura?.factura_crc.descuento | number : '1.2-2'}}</td>
        <th> - ${{factura?.factura_usd.descuento | number : '1.2-2'}}</th>
      </tr>
      <tr *ngIf="factura?.descuento_promocional > 0">
        <td>Descuento Promocional {{factura?.codigo_promocional}}</td>
        <td> - ₡{{factura?.factura_crc.descuento_promocional | number : '1.2-2'}}</td>
        <th> - ${{factura?.factura_usd.descuento_promocional | number : '1.2-2'}}</th>
      </tr>
      <tr class="total">
        <td><strong>Total:</strong></td>
        <td><strong>₡{{factura?.factura_crc.total | number : '1.2-2'}}</strong></td>
        <th><strong>${{factura?.factura_usd.total | number : '1.2-2'}}</strong></th>
      </tr>
    </table>

    <div>
      <p *ngIf="data.comprobante">
        Este es un comprobante temporal. A manera de consulta, una vez se haya generado su factura electrónica podrá
        consultarla en la sección de Facturas.
      </p>
      <p *ngIf="!data.comprobante">

      </p>
    </div>
  </div>
</div>

<div class="text-right">
  <button mat-flat-button class="button1" (click)="downloadAsPDF()">
    <mat-icon>print</mat-icon> Imprimir
  </button>
</div>
