<div class="container-example" fxLayout="column" fxLayoutGap="10px" style=" width:100%;">
  <div style="background-color: #0094a3; border-radius: 0px 0px 0px 0px;">
    <p
      style="text-align: center;color:white;  font-size: 20px; font-family: 'Varela Round'; line-height: 22px; font-size: 18px; font-style: normal; height: 50px; padding-top: 15px;">
      {{'Editar Tarjeta' | translate}}</p>
  </div>

  <form [formGroup]="formTarjeta" class="form">
    <div class="row">

      <div class="col-lg-12" *ngIf="authService.isAdmin">
        <mat-checkbox formControlName="Active">{{'Activa' | translate}}</mat-checkbox>
      </div>

      <div class="col-lg-12">
        <mat-checkbox formControlName="Default">{{'Tarjeta para cargo automático' | translate}}</mat-checkbox>
      </div>

      <div class="col-lg-12">
        <div style="text-align: left; position: absolute; bottom: 0; right: 0;">
          <button mat-flat-button color="primary" class="button-calcular" (click)="edit()"
            style="background-color: #0094a3;" [disabled]="!formTarjeta.valid">{{'Guardar' | translate}}</button>
        </div>
      </div>
    </div>
  </form>
</div>