import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { serialize } from 'object-to-formdata';


@Injectable({
  providedIn: 'root'
})
export class ComprasCorporativasService {
  private url = `${environment.apiUrl}/compras-corporativas/`;

  constructor(
    private http: HttpClient
  ) { }

  get(page, params= '') {
    return this.http.get(this.url + '?per_page=10&page_no=' + page + params);
  }

  getHistorial(id) {
    return this.http.get(this.url + 'historial-single/' + id);
  }

  getByID(id) {
    return this.http.get(this.url + id);
  }

  post(data) {
    return this.http.post(this.url,  this.getFormData(data));
  }

  historial_corporativa(data) {
    return this.http.post(this.url + 'historial-corporativa/',  this.getFormData(data));
  }

  put(id, data) {
    return this.http.put(this.url + id + '/',  this.getFormData(data));
  }

  delete(id) {
    return this.http.delete(this.url + id );
  }

  private getFormData(object) {
    if (object.factura_1 == null) {
      delete object.factura_1;
    }
    if (object.factura_2 == null) {
      delete object.factura_2;
    }
    if (object.imagen == null) {
      delete object.imagen;
    }

    return serialize(object);
  }
}
