/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { serialize } from 'object-to-formdata';


@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  usuario = localStorage.user ? JSON.parse(localStorage.user) : null;

  public usuarios_clientes;
  private urlUsuarios = `${environment.apiUrl}/usuarios`;
  private urlCards = `${environment.apiUrl}/cards`;
  private urlRoutes = `${environment.apiUrl}/rutas`;

  constructor(
    private http: HttpClient
  ) { }

  get_account_number(user) {
    if (user) {
      return user.cuenta;
    }
    return '-';
  }

  get_consecutive_number(id) {
    if (id) {
      return 'SB' + ('000000' + id).slice(-6);
    }
    return '-';
  }

  get_fullname(user) {

    if (user) {
      const eliminada = user.delete ? ' (CUENTA ELIMINADA)' : '';
      const alianza = user.alianza != null ? user.alianza.nombre ? ' (' + user.alianza.nombre + ')' : '' : '';

      if (user.tipo_de_cedula === 'FIS' || user.tipo_de_cedula === 'ALI') {
        if (user.segundo_apellido) {
          return `${user.nombre} ${user.primer_apellido} ${user.segundo_apellido}${alianza}${eliminada}`;
        } else {
          if (user.primer_apellido) {
            return `${user.nombre} ${user.primer_apellido}${alianza}${eliminada}`;
          }
          return `${user.nombre}${alianza}${eliminada} `;
        }

      } else {
        if (!user.razon_social) {
          return '-';
        }
        return `${user.razon_social}${alianza}${eliminada} `;
      }

    }
    return '-';
  }

  getInfo(user) {
    return this.get_user(user);
  }

  get_user(user) {
    this.get_user_by_id(user).subscribe((res) =>
      //console.log('res user', res);
      res['nombre']
    );
  }

  get_fullnameFactura(user) {

    if (user) {
      if (user.tipo_factura === 'FIS') {
        if (user.segundo_apellido) {
          return `${user.nombre} ${user.primer_apellido} ${user.segundo_apellido}`;
        }
        return `${user.nombre} ${user.primer_apellido}`;
      } else {
        return `${user.razon_social}`;
      }

    }
    return '-';
  }

  get_fullaccount(user) {
    return this.get_account_number(user) + '-' + this.get_fullname(user);
  }

  get_users(page, filter = '') {
    return this.http.get(this.urlUsuarios + '?per_page=50&page_no=' + page + filter);
  }
  get_user_by_id(id) {
    return this.http.get(this.urlUsuarios + '/' + id);
  }
  get_type_client(id) {
    return this.http.get(this.urlUsuarios + '/tipo_cliente/' + id);
  }
  get_route(id) {
    return this.http.get(this.urlRoutes + '/' + id);
  }
  get_driver_users() {
    return this.http.get(this.urlUsuarios + '/conductores');
  }
  get_clients_users() {
    return this.http.get(this.urlUsuarios + '/clientes');
  }
  get_clients_embarque(embarque) {
    return this.http.get(this.urlUsuarios + '/clientes-embarque/' + embarque);
  }
  get_clients_status(status) {
    return this.http.get(this.urlUsuarios + '/clientes-status/' + status);
  }
  get_seller_users() {
    return this.http.get(this.urlUsuarios + '/vendedores');
  }
  getAfilliateCode(id) {
    return this.http.get(this.urlUsuarios + '/afiliado/' + id);
  }
  get_user_referrals(id) {
    return this.http.get(this.urlUsuarios + '/referidos/' + id);
  }
  checkUserEmail(email) {
    return this.http.get(this.urlUsuarios + '/checkemail/' + email);
  }
  checkUserCode(code) {
    return this.http.get(this.urlUsuarios + '/checkcode/' + code);
  }

  getProvincias() {
    return this.http.get(this.urlUsuarios + '/provincias');
  }

  getCantones(idProvincia) {
    return this.http.get(this.urlUsuarios + '/cantones/' + idProvincia);
  }
  getCantonesAll() {
    return this.http.get(this.urlUsuarios + '/cantones');
  }
  getDistritos(idProvincia, idCanton) {
    return this.http.get(this.urlUsuarios + '/distritos/' + idProvincia + '/' + idCanton);
  }

  getMetodosPago() {
    return this.http.get(this.urlUsuarios + '/metodos-pago');
  }

  getMetodosPagoContraEntrega() {
    return this.http.get(this.urlUsuarios + '/metodos-pago-contraentrega');
  }

  getMetodosPagoPreviaEntrega() {
    return this.http.get(this.urlUsuarios + '/metodos-pago-previaentrega');
  }

  search(value) {
    return this.http.get(this.urlUsuarios + '/buscar/?search=' + value);
  }

  searchCliente(value) {
    return this.http.get(this.urlUsuarios + '/buscar-cliente/?search=' + value);
  }

  getCards() {
    return this.http.get(this.urlCards);
  }

  postCards(data) {
    return this.http.post(this.urlCards, data);
  }

  putCards(id, data) {
    return this.http.put(this.urlCards + '/' + id, data);
  }

  deleteCards(id) {
    return this.http.delete(this.urlCards + '/' + id);
  }

  searchCards(value) {
    return this.http.get(this.urlCards + '/search/?search=' + value);
  }

  activarCuenta(email) {
    return this.http.get(this.urlUsuarios + '/verificar_email/' + email + '/');
  }

  delete_user_by_id(id) {
    return this.http.delete(this.urlUsuarios + '/' + id);
  }

  getNoRoutes(page = 1) {
    return this.http.get(this.urlUsuarios + '/no-routes?page=' + page);
  }

  sendPushNotificacions(data) {
    return this.http.post(this.urlUsuarios + '/push_notifications', data);
  }

  getAlianzasAll() {
    return this.http.get(this.urlUsuarios + '/alianzas');
  }

  getAlianzas() {
    return this.http.get(this.urlUsuarios + '/alianza');
  }

  getAlianza(id) {
    return this.http.get(this.urlUsuarios + '/alianza/' + id);
  }

  postAlianzas(data) {
    return this.http.post(this.urlUsuarios + '/alianza', data);
  }

  putAlianzas(id, data) {
    return this.http.put(this.urlUsuarios + '/alianza/' + id, data);
  }

  getTipoCliente() {
    return this.http.get(this.urlUsuarios + '/tipo_cliente');
  }

  postTipoCliente(data) {
    return this.http.post(this.urlUsuarios + '/tipo_cliente', data);
  }

  putTipoCliente(id, data) {
    return this.http.put(this.urlUsuarios + '/tipo_cliente/' + id, data);
  }

  getContrato() {
    return this.http.get(this.urlUsuarios + '/contrato');
  }

  postContrato(data) {
    return this.http.post(this.urlUsuarios + '/contract', data);
  }

  putContrato(data) {
    return this.http.put(this.urlUsuarios + '/contract', serialize(data));
  }
}
