/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralDialogComponent } from '../../../../common/dialogs/general-dialog/general-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { Genero } from 'src/app/models/Usuario';
import { NavigationService } from 'src/app/services/navigation.service';


@Component({
  selector: 'app-vendedor',
  templateUrl: './vendedor.component.html',
  styleUrls: ['./vendedor.component.scss']
})
export class VendedorComponent implements OnInit {

  formCreate;

  existing_email: boolean;

  genders: object[] = [
    { name: 'Masculino', value: Genero.masculino },
    { name: 'Femenino', value: Genero.femenino },
    { name: 'Otro', value: Genero.otro }
  ];

  rutas;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private dialog: MatDialog,
    private router: Router,
    private usuariosService: UsuariosService,
    private navigation: NavigationService,
  ) { }

  ngOnInit() {
    this.inicializarForm();
  }

  inicializarForm() {
    this.formCreate = this.fb.group({
      // Necesarios para el modelo
      nombre: ['', [Validators.required, Validators.maxLength(30)]],
      primer_apellido: ['', [Validators.required, Validators.maxLength(30)]],
      password: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(6)]],
      confirm_password: ['', [Validators.required]], //No se envia al modelo
      numero_de_cedula: ['', [Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ]+$')]],
      telefono_movil: ['', [Validators.required, Validators.maxLength(30)]],
      fecha_de_nacimiento: ['', []],

      //Opcionales para el Modelo
      segundo_apellido: [null, [Validators.maxLength(30)]],
      rol: ['VEND', [Validators.required]], //Default TRANSP
      genero: ['M', []], //Default 'M'
      email: ['', [Validators.required, Validators.maxLength(50)]],
      ruta: [1, [Validators.maxLength(250)]],
    });
  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  createUser() {
    //console.log(this.formCreate.value);
    if (this.formCreate.valid && this.formCreate.controls['confirm_password'].value == this.formCreate.controls['password'].value && this.existing_email == false) {
      //console.log('valid');
      this.spinnerService.show();
      this.formCreate.value.referido = null;
      this.authService.createUser(this.formCreate.value).subscribe((data) => {

        //console.log('Endpoint Response: ', data);
        if (!data.ok && data.error.email) {
          this.existing_email = true;
          this.openDialog(`Correo en uso`);
        } else if (data.ok) {
          this.existing_email = false;
          this.openDialog(`Vendedor creado con éxito`);
          this.router.navigateByUrl('/admin/vendedores');
        }
        this.spinnerService.hide();
      });

    } else {
      //console.log(this.formCreate);
    }
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }

  public getErrorMessage(control) {
    if (control.hasError('required')) {
      return 'Campo necesario';
    }
    if (control.hasError('minlength')) {
      return 'Demasiado Corto';
    }
    if (control.hasError('maxlength')) {
      return 'Demasiado Largo';
    }
    if (control.hasError('pattern')) {
      return 'Formato ínvalido';
    }
    if (control.hasError('min')) {
      return 'Límite mínimo';
    }
    if (control.hasError('exists')) {
      return 'Correo en uso';
    }
    if (control.hasError('equal')) {
      return 'Correos deben ser distintos';
    }

    return '';
  }

  checkEmail(email, field) {
    //console.log('check', email);
    if (this.formCreate.controls[field].value) {

      this.usuariosService.checkUserEmail(email).subscribe((data) => {
        this.existing_email = data['exists'];

        if (this.existing_email) {
          this.formCreate.controls[field].setErrors({ exists: this.existing_email });

        } else {
          this.formCreate.controls[field].setErrors(null);
        }
      });

    }
  }

  back() {
    this.navigation.back();
  }

}
