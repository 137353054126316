import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { GeneralDialogComponent } from '../../../../../common/dialogs/general-dialog/general-dialog.component';
import { TarifaDeProductoService } from '../../../../../../services/tarifa-de-producto.service';

@Component({
  selector: 'app-create',
  templateUrl: './tarifa-de-producto.component.html',
  styleUrls: ['./tarifa-de-producto.component.scss']
})
export class TarifaProductoComponent implements OnInit {
  tarifa: any;
  editMode: Boolean;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public tarifaDeProductoService: TarifaDeProductoService
  ) { }
  form = this.fb.group({
		// Necesarios para el modelo
    description : ['',[Validators.required]],
    value : ['',[Validators.required,Validators.min(0),Validators.max(100)]],
    //Opcionales
    warning : ['']
	});

  ngOnInit() {
    this.tarifa = {};
    this.activatedRoute.queryParams.subscribe(params => {
      this.tarifaDeProductoService.getByID(params.id).subscribe((res: any)=>{
        this.editMode = true;
        this.tarifa=res;
        this.form.controls.description.setValue(this.tarifa.description);
        this.form.controls.value.setValue(this.tarifa.value);
        this.form.controls.warning.setValue(this.tarifa.warning);
      });
    });
  }

  public save(){
    if (this.form.valid){
      this.spinnerService.show();
      if (this.form.value.warning == '') {
this.form.controls.warning.setValue(null);
}
      if (!this.editMode){
        this.tarifaDeProductoService.post(this.form.value).subscribe(
          (data: any)=>{
            this.spinnerService.hide();
            this.router.navigate(['/admin/administracion/tarifa-productos/']);
            this.openDialog('Tarifa Creada Exitosamente');
          }, err => {
            this.openDialog('Ocurrio un error');
            this.spinnerService.hide();
            console.error(err);
          }
        );
      }else{
        this.tarifaDeProductoService.update(this.tarifa.id, this.form.value).subscribe(
          (data: any)=>{
            this.spinnerService.hide();
            this.router.navigate(['/admin/administracion/tarifa-productos/']);
            this.openDialog('Tarifa Actualizada Exitosamente');
          }, err => {
            this.openDialog('Ocurrio un error');
            this.spinnerService.hide();
            console.error(err);
          }
        );
      }
    }
  }
  public getErrorMessage(control) {
		if (control.hasError('required')) {
			return 'Campo necesario';
		}
		if (control.hasError('minlength')) {
			return 'Demasiado Corto';
		}
		if (control.hasError('maxlength')) {
			return 'Demasiado Largo';
		}
		if (control.hasError('pattern')) {
			return 'Formato ínvalido';
		}
		if (control.hasError('min')) {
			return 'Límite mínimo excedido';
    }
    if (control.hasError('max')) {
			return 'Límite máximo excedido';
		}
		return 'Error';
  }
  private openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass:'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }
}
