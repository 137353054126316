<div style="overflow:scroll;" class="content pt-5">
  <div class="content-title">
    <p class="title">Factura</p>
  </div>
</div>

<form [formGroup]="form" (keydown.enter)="$event.preventDefault();">
  <div mat-dialog-content class="p-4">
    <div class="row px-4">

      <div class="col-12 col-md-3 col-lg-2">
        <label class="label col-12 p-0">Número de Factura</label>
        <mat-form-field appearance="outline" class="form-field readonly-input">
          <input matInput placeholder="Número" class="input-field" formControlName="numero" required [readonly]="true">
          <mat-error *ngIf="form.controls['numero'].errors">
            {{getErrorMessage(form.controls['numero'])}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 col-lg-4">
        <label class="label col-12 p-0">Cuenta</label>
        <mat-form-field appearance="outline" class="form-field readonly-input">
          <input matInput placeholder="Número" class="input-field"
            [value]="usuariosService.get_fullaccount(factura?.usuario)" [readonly]="true">

        </mat-form-field>
      </div>

      <div class="col-12 col-md-3 col-lg-2">
        <label class="label col-12 p-0">Guía</label>
        <mat-form-field appearance="outline" class="form-field readonly-input">
          <input matInput placeholder="Número" class="input-field" [value]="paquete?.numero_de_guia" [readonly]="true">
        </mat-form-field>
      </div>

      <div class="col-12 col-md-3 col-lg-2">
        <label class="label col-12 p-0">Valor CIF</label>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Monto" class="input-field" formControlName="cif" [readonly]="!this.editable">
          <mat-label class="prefix" matPrefix>USD $</mat-label>
          <mat-error *ngIf="form.controls['cif'].errors">
            {{getErrorMessage(form.controls['cif'])}}
          </mat-error>
        </mat-form-field>
      </div>

    </div>

    <div class="ml-2">
      <p class="title1">Rubros Fijos</p>
    </div>

    <div class="row px-4">
      <div class="col-12 col-md-3 col-lg-2">
        <label class="label col-12 p-0">Transporte Internacional</label>
        <mat-form-field appearance="outline" class="form-field readonly-input">
          <input matInput placeholder="Monto" class="input-field" formControlName="cargo_transporte"
            [readonly]="!this.editable" (change)="checkAsManual()">
          <mat-label class="prefix" matPrefix>USD $</mat-label>
          <mat-error *ngIf="form.controls['cargo_transporte'].errors">
            {{getErrorMessage(form.controls['cargo_transporte'])}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-3 col-lg-2">
        <label class="label col-12 p-0">Combustible</label>
        <mat-form-field appearance="outline" class="form-field readonly-input">
          <input matInput placeholder="Monto" class="input-field" formControlName="cargo_fuel"
            [readonly]="!this.editable" (change)="checkAsManual()">
          <mat-label class="prefix" matPrefix>USD $</mat-label>
          <mat-error *ngIf="form.controls['cargo_fuel'].errors">
            {{getErrorMessage(form.controls['cargo_fuel'])}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-3 col-lg-2">
        <label class="label col-12 p-0">Seguro</label>
        <mat-form-field appearance="outline" class="form-field readonly-input">
          <input matInput placeholder="Monto" class="input-field" formControlName="cargo_seguro"
            [readonly]="!this.editable" (change)="checkAsManual()">
          <mat-label class="prefix" matPrefix>USD $</mat-label>
          <mat-error *ngIf="form.controls['cargo_seguro'].errors">
            {{getErrorMessage(form.controls['cargo_seguro'])}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-3 col-lg-2">
        <label class="label col-12 p-0">Manejo</label>
        <mat-form-field appearance="outline" class="form-field readonly-input">
          <input matInput placeholder="Monto" class="input-field" formControlName="cargo_manejo"
            [readonly]="!this.editable" (change)="checkAsManual()">
          <mat-label class="prefix" matPrefix>USD $</mat-label>
          <mat-error *ngIf="form.controls['cargo_manejo'].errors">
            {{getErrorMessage(form.controls['cargo_manejo'])}}
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Solo Aereos -->
      <div class="col-12 col-md-3 col-lg-2" *ngIf="!planEconomico">
        <label class="label col-12 p-0">Guía</label>
        <mat-form-field appearance="outline" class="form-field readonly-input">
          <input matInput placeholder="Monto" class="input-field" formControlName="cargo_confeccion_guia"
            [readonly]="!this.editable" (change)="checkAsManual()">
          <mat-label class="prefix" matPrefix>USD $</mat-label>
          <mat-error *ngIf="form.controls['cargo_confeccion_guia'].errors">
            {{getErrorMessage(form.controls['cargo_confeccion_guia'])}}
          </mat-error>
        </mat-form-field>
      </div>
      <!-- Fin Solo Aereos -->

      <!-- Solo Maritimo -->
      <div class="col-12 col-md-3 col-lg-2" *ngIf="planEconomico">
        <label class="label col-12 p-0">Confección BL</label>
        <mat-form-field appearance="outline" class="form-field readonly-input">
          <input matInput placeholder="Monto" class="input-field" formControlName="cargo_confeccion_bl"
            [readonly]="!this.editable" (change)="checkAsManual()">
          <mat-label class="prefix" matPrefix>USD $</mat-label>
          <mat-error *ngIf="form.controls['cargo_confeccion_bl'].errors">
            {{getErrorMessage(form.controls['cargo_confeccion_bl'])}}
          </mat-error>
        </mat-form-field>
      </div>
      <!-- Fin Solo Maritimo -->
    </div>

    <div class="ml-2">
      <p class="title1">Impuestos</p>
    </div>

    <div class="row px-4">
      <div class="col-12 col-md-3 col-lg-2">
        <label class="label col-12 p-0">% de Hacienda</label>
        <mat-form-field appearance="outline" class="form-field readonly-input">
          <input matInput placeholder="Monto" class="input-field" formControlName="porcentaje_hacienda"
            [readonly]="true" max="100" min="0" step="0.5" (keyup)="getTaxes($event.target.value)"
            (change)="getTaxes($event.target.value)">
          <mat-error *ngIf="form.controls['porcentaje_hacienda'].errors">
            {{getErrorMessage(form.controls['porcentaje_hacienda'])}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-3 col-lg-2">
        <label class="label col-12 p-0">Impuestos</label>
        <mat-form-field appearance="outline" class="form-field readonly-input">
          <input matInput placeholder="Monto" class="input-field" formControlName="cargo_impuestos"
            [readonly]="!this.editable" (change)="checkAsManual()">
          <mat-label class="prefix" matPrefix>USD $</mat-label>
          <mat-error *ngIf="form.controls['cargo_impuestos'].errors">
            {{getErrorMessage(form.controls['cargo_impuestos'])}}
          </mat-error>
        </mat-form-field>
      </div>

      <!--  <div class="col-12 col-md-3 col-lg-2" *ngIf="isExonerado">
        <label class="label col-12 p-0">Por Exoneración</label>
        <mat-form-field appearance="outline" class="form-field col-12 readonly-input">
          <input matInput placeholder="Monto" class="input-field" formControlName="cargo_exoneracion" [readonly]="!this.editable"
           >
          <mat-label class="prefix" matPrefix>USD $</mat-label>
          <mat-error *ngIf="form.controls['cargo_exoneracion'].errors">
            {{getErrorMessage(form.controls['cargo_exoneracion'])}}
          </mat-error>
        </mat-form-field>
      </div> -->

    </div>

    <tabset>
      <tab heading="Otros Cargos">
        <div class="row">
          <div class="col" *ngIf="isConsolidado">
            <label class="label col-12 p-0">Consolidación</label>
            <mat-form-field appearance="outline" class="form-field readonly-input">
              <input matInput placeholder="Monto" class="input-field" formControlName="cargo_consolidacion_paquetes"
                [readonly]="!this.editable">
              <mat-label class="prefix" matPrefix>USD $</mat-label>
              <mat-error *ngIf="form.controls['cargo_consolidacion_paquetes'].errors">
                {{getErrorMessage(form.controls['cargo_consolidacion_paquetes'])}}
              </mat-error>
            </mat-form-field>
          </div>


          <div class="col" *ngIf="isClaseD">
            <label class="label col-12 p-0">Por Bodegaje</label>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Monto" class="input-field" formControlName="cargo_bodegaje"
                (change)="updateTotal()" (keyup)="updateTotal()" [readonly]="!this.editable">
              <mat-label class="prefix" matPrefix>USD $</mat-label>
              <mat-error *ngIf="form.controls['cargo_bodegaje'].errors">
                {{getErrorMessage(form.controls['cargo_bodegaje'])}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col">
            <label class="label col-12 p-0">Por Encomienda</label>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Monto" type="number" class="input-field" formControlName="cargo_encomienda"
                (change)="updateTotal()" (keyup)="updateTotal()" [readonly]="!this.editable">
              <mat-label class="prefix" matPrefix>CRC ₡</mat-label>
              <mat-error *ngIf="form.controls['cargo_encomienda'].errors">
                {{getErrorMessage(form.controls['cargo_encomienda'])}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col" *ngIf="paquete?.compra_corporativa">
            <label class="label col-12 p-0">Por Compra Corporativa</label>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Monto" class="input-field" formControlName="cargo_compra_corporativa"
                (change)="updateTotal()" (keyup)="updateTotal()" [readonly]="!this.editable">
              <mat-label class="prefix" matPrefix>USD $</mat-label>
              <mat-error *ngIf="form.controls['cargo_compra_corporativa'].errors">
                {{getErrorMessage(form.controls['cargo_compra_corporativa'])}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col" *ngIf="paquete?.compra_corporativa">
            <label class="label col-12 p-0">Cargo Gestión</label>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Monto" class="input-field" formControlName="cargo_gestion_compra_corporativa"
                (change)="updateTotal()" (keyup)="updateTotal()" [readonly]="!this.editable">
              <mat-label class="prefix" matPrefix>USD $</mat-label>
              <mat-error *ngIf="form.controls['cargo_gestion_compra_corporativa'].errors">
                {{getErrorMessage(form.controls['cargo_gestion_compra_corporativa'])}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col">
            <label class="label col-12 p-0">Permisos</label>
            <mat-form-field appearance="outline" class="form-field">
              <mat-select #permisos placeholder="Seleccione" (valueChange)="checkPermisos(permisos.value)"
                [disabled]="!this.editable" [(value)]="permisosSelect">
                <mat-option value="0">Ninguno</mat-option>
                <mat-option *ngFor="let variable of tarifasVariables" [value]="variable.value">
                  {{variable.description}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col" *ngIf="isClaseD || permisos.value > 0">
            <label class="label col-12 p-0">Por Permiso/Trámite</label>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Monto" class="input-field" formControlName="cargo_permiso"
                (change)="updateTotal()" (keyup)="updateTotal()" [readonly]="!this.editable">
              <mat-label class="prefix" matPrefix>USD $</mat-label>
              <mat-error *ngIf="form.controls['cargo_permiso'].errors">
                {{getErrorMessage(form.controls['cargo_permiso'])}}
              </mat-error>
            </mat-form-field>
          </div>

        </div>

        <div class="row">
          <!-- Otros Cargos 1 -->
          <div class="col">
            <label class="label col-12 p-0">Otros Cargos 1</label>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Monto" class="input-field" type="number" formControlName="otros_cargos_1"
                (blur)="updateTotal()" [readonly]="!this.editable">
              <mat-label class="prefix" matPrefix>USD $</mat-label>
              <mat-error *ngIf="form.controls['otros_cargos_1'].errors">
                {{getErrorMessage(form.controls['otros_cargos_1'])}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col" *ngIf="form.controls.otros_cargos_1.value > 0">
            <label class="label col-12 p-0">Descripción</label>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Descripción" class="input-field" formControlName="otros_cargos_1_descripcion"
                [readonly]="!this.editable">
              <mat-error *ngIf="form.controls['otros_cargos_1_descripcion'].errors">
                {{getErrorMessage(form.controls['otros_cargos_1_descripcion'])}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <!-- Otros Cargos 2 -->
          <div class="col" *ngIf="form.controls.otros_cargos_1.value != 0">
            <label class="label col-12 p-0">Otros Cargos 2</label>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Monto" class="input-field" type="number" formControlName="otros_cargos_2"
                (change)="updateTotal()" (keyup)="updateTotal()" [readonly]="!this.editable">
              <mat-label class="prefix" matPrefix>USD $</mat-label>
              <mat-error *ngIf="form.controls['otros_cargos_2'].errors">
                {{getErrorMessage(form.controls['otros_cargos_2'])}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col" *ngIf="form.controls.otros_cargos_2.value > 0">
            <label class="label col-12 p-0">Descripción</label>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Descripción" class="input-field" formControlName="otros_cargos_2_descripcion"
                [readonly]="!this.editable">
              <mat-error *ngIf="form.controls['otros_cargos_2_descripcion'].errors">
                {{getErrorMessage(form.controls['otros_cargos_2_descripcion'])}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <!-- Otros Cargos 3 -->
          <div class="col" *ngIf="form.controls.otros_cargos_2.value != 0">
            <label class="label col-12 p-0">Otros Cargos 3</label>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Monto" class="input-field" type="number" formControlName="otros_cargos_3"
                (change)="updateTotal()" (keyup)="updateTotal()" [readonly]="!this.editable">
              <mat-label class="prefix" matPrefix>USD $</mat-label>
              <mat-error *ngIf="form.controls['otros_cargos_3'].errors">
                {{getErrorMessage(form.controls['otros_cargos_3'])}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col" *ngIf="form.controls.otros_cargos_3.value > 0">
            <label class="label col-12 p-0">Descripción</label>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Descripción" class="input-field" formControlName="otros_cargos_3_descripcion"
                [readonly]="!this.editable">
              <mat-error *ngIf="form.controls['otros_cargos_3_descripcion'].errors">
                {{getErrorMessage(form.controls['otros_cargos_3_descripcion'])}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

      </tab>

      <tab heading="Descuentos" *ngIf="authService.validateRolePermission('ADD_DISCOUNT')">
        <div class="row">
          <div class="col-3">
            <label class="label col-12 p-0">Tipo de Descuento</label>
            <mat-form-field appearance="outline" class="form-field">
              <mat-select matNativeControl formControlName="tipo_descuento" required
                (selectionChange)="setDefaultDiscount($event.value)" [disabled]="!this.editable">
                <mat-option value="Normal">Normal</mat-option>
                <mat-option value="Porcentaje">Normal %</mat-option>
                <mat-option value="Recomendacion">Por Recomendación</mat-option>
                <mat-option value="Feedback">Por Feedback</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-3">
            <label class="label col-12 p-0">Descripción del Descuento</label>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Descripción" class="input-field" formControlName="descuento_descripcion"
                [readonly]="!this.editable">
            </mat-form-field>
          </div>

          <div class="col-3"
            *ngIf="form.controls['tipo_descuento'].value == 'Normal' || form.controls['tipo_descuento'].value == 'Recomendacion'">
            <label class="label col-12 p-0">Descuento CRC</label>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Monto" class="input-field" [(ngModel)]="descuentoCRC"
                [ngModelOptions]="{standalone: true}" [readonly]="!this.editable"
                (change)="convertCurrency('CRC', 'Descuento')" (keyup)="convertCurrency('CRC', 'Descuento')">
              <mat-label class="prefix" matPrefix>CRC ¢
              </mat-label>
              <mat-error *ngIf="form.controls['descuento'].errors">
                {{getErrorMessage(form.controls['descuento'])}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-3">
            <label class="label col-12 p-0">Descuento USD</label>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Monto" class="input-field" formControlName="descuento"
                (change)="updateTotal(); convertCurrency('USD', 'Descuento')"
                (keyup)="updateTotal(); convertCurrency('USD', 'Descuento')" [readonly]="!this.editable">
              <mat-label class="prefix" matPrefix *ngIf="form.controls['tipo_descuento'].value == 'Normal'">USD $
              </mat-label>
              <mat-label class="prefix" matPrefix *ngIf="form.controls['tipo_descuento'].value == 'Porcentaje'">%
              </mat-label>
              <mat-label class="prefix" matPrefix *ngIf="form.controls['tipo_descuento'].value == 'Recomendacion'">USD $
              </mat-label>
              <mat-label class="prefix" matPrefix *ngIf="form.controls['tipo_descuento'].value == 'Feedback'">%
              </mat-label>
              <mat-error *ngIf="form.controls['descuento'].errors">
                {{getErrorMessage(form.controls['descuento'])}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-4" *ngIf="form.get('descuento_promocional').value > 0">
            <label class="label col-12 p-0">Código Promocional</label>
            <mat-form-field appearance="outline" class="form-field readonly-input">
              <input matInput placeholder="Monto" class="input-field"
                [matTooltip]="'Cupon: '+ factura.codigo_promocional + ' Aplicado'"
                formControlName="descuento_promocional" [readonly]="!this.editable" (change)="updateTotal()"
                (keyup)="updateTotal()">
              <mat-label class="prefix" matPrefix>USD $</mat-label>
              <mat-error *ngIf="form.controls['descuento_promocional'].errors">
                {{getErrorMessage(form.controls['descuento_promocional'])}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

      </tab>

      <tab heading="Notas de Crédito">
        <div class="row px-4">
          <div class="col-12 my-4" *ngIf="authService.isAdmin">
            <button mat-button style="padding: 0; margin-right: 0px; color: #0094a3;" (click)="showNotaCredito = true;"
              *ngIf="factura?.generado_gti && showNotaCredito == false">
              Crear Nota de Crédito <mat-icon style="font-size: 18px;">create</mat-icon>
            </button>

            <span *ngIf="!factura?.generado_gti">Factura Electronica No Generada</span>

          </div>

          <form [formGroup]="formNotaCredito" class="col-12"
            *ngIf="showNotaCredito == true && editNotaCredito == false">
            <div class="row">
              <div class="col-6">
                <label class="label">Tipo</label>
                <div appearance="outline" class="form-field">
                  <mat-radio-group aria-label="Seleccione" (change)="checkTipoNota($event)">
                    <mat-radio-button class="mx-4" value="1">Total</mat-radio-button>
                    <mat-radio-button class="mx-4" value="2">Parcial</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>

              <div class="col-6" *ngIf="tipoNotaCredito == '2'">
                <label class="label">Concepto</label>
                <div appearance="outline" class="form-field">
                  <mat-radio-group aria-label="Seleccione" (change)="checkNotaParcial($event)">
                    <mat-radio-button class="mx-4" value="1">Flete Internacional</mat-radio-button>
                    <mat-radio-button class="mx-4" value="2">Servicio SuperBox</mat-radio-button>
                    <mat-radio-button class="mx-4" value="3">Impuestos</mat-radio-button>
                    <mat-radio-button class="mx-4" value="4">Encomienda</mat-radio-button>
                    <mat-radio-button class="mx-4" value="5">Otros Exento</mat-radio-button>
                    <mat-radio-button class="mx-4" value="6">Otros IVA</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>

            <div class="row mt-4" *ngIf="(tipoNotaCredito == '1') || (tipoNotaCredito == '2' && notaParcial != '0')">

              <div class="col-6">
                <label class="label">Descripción</label>
                <mat-form-field appearance="outline" class="form-field">
                  <input matInput placeholder="Descripción" class="input-field" formControlName="descripcion"
                    [disabled]="!editNotaCredito">
                </mat-form-field>
              </div>

              <div class="col-6">
                <label class="label">Tipo de Nota</label>
                <mat-form-field appearance="outline" class="form-field">
                  <mat-select placeholder="Seleccione" formControlName="tipo">
                    <mat-option *ngFor="let tipo of opcionesNotasCredito" [value]="tipo.value">
                      {{tipo.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-4">
                <label class="label col-12 p-0">Monto CRC</label>
                <mat-form-field appearance="outline" class="form-field">
                  <input matInput placeholder="Monto" class="input-field" formControlName="monto_crc"
                    (change)="convertCurrency('CRC', 'Nota')" (keyup)="convertCurrency('CRC', 'Nota')"
                    [disabled]="tipoNotaCredito == '1'" [readonly]="tipoNotaCredito == '1'">
                  <mat-label class="prefix" matPrefix>CRC ¢</mat-label>

                </mat-form-field>
              </div>

              <div class="col-4">
                <label class="label col-12 p-0">IVA</label>
                <mat-form-field appearance="outline" class="form-field">
                  <input matInput placeholder="IVA" class="input-field" formControlName="iva_crc" disabled readonly>
                  <mat-label class="prefix" matPrefix>CRC ¢</mat-label>
                </mat-form-field>
              </div>

              <div class="col-4">
                <label class="label">Monto USD</label>
                <mat-form-field appearance="outline" class="form-field">
                  <input matInput placeholder="Monto" class="input-field" formControlName="monto"
                    (change)="convertCurrency('USD', 'Nota')" (keyup)="convertCurrency('USD', 'Nota')"
                    [disabled]="tipoNotaCredito == '1'" [readonly]="tipoNotaCredito == '1'">
                  <mat-label class="prefix" matPrefix>USD $</mat-label>
                </mat-form-field>
              </div>
            </div>

            <div class="col-12 mb-2" *ngIf="tipoNotaCredito == '1' || (tipoNotaCredito == '2' && notaParcial != '0')">
              <button style="float: right;" mat-flat-button class="button-calcular" (click)="createNotaCredito()"
                [disabled]="!formNotaCredito.valid">
                <mat-icon>save</mat-icon>Guardar
              </button>
            </div>

          </form>

          <div class="col-12 mb-4" *ngIf="factura?.nota_credito">
            <label class="label">Nota de Crédito</label>
            <table class="factura" style="width:100%;">
              <tr>
                <th>Descripción</th>
                <th>Tipo</th>
                <th>Monto (Colones)</th>
                <th>IVA</th>
                <th>Monto (USD)</th>
                <th>Consecutivo</th>
                <th>Opciones</th>
              </tr>
              <tr *ngFor="let notaCredito of factura.nota_credito">
                <td>{{ notaCredito?.descripcion }}</td>
                <td *ngIf="notaCredito?.tipo == '1'">Transferencia</td>
                <td *ngIf="notaCredito?.tipo == '2'">Reembolso a Tarjeta</td>
                <td>₡{{ notaCredito?.monto_crc | number : '1.2-2'}}</td>
                <td>₡{{ notaCredito?.iva | number : '1.2-2'}}</td>
                <th>${{ notaCredito?.monto | number : '1.2-2'}}</th>
                <td>{{notaCredito?.consecutivo_gti}}</td>
                <th *ngIf="authService.validateRolePermission('EMIT_NC_ND')">
                  <button mat-flat-button style="background-color: green !important; color: white !important;"
                    (click)="emitCreditNote(notaCredito)" *ngIf="authService.isAdmin && !notaCredito.consecutivo_gti">
                    <mat-icon style="color: white !important;">description</mat-icon>Emitir
                  </button>
                  <button mat-flat-button style="background-color: red !important; color: white !important;"
                    (click)="deleteNotaCredito()" *ngIf="authService.isAdmin">
                    <mat-icon style="color: white !important;">delete</mat-icon>Eliminar
                  </button>
                </th>
              </tr>
            </table>
          </div>
        </div>
      </tab>
      <tab heading="Notas de Débito">
        <div class="row px-4">
          <div class="col-12 my-4" *ngIf="authService.isAdmin">
            <button mat-button style="padding: 0; margin-right: 0px; color: #0094a3;" (click)="showNotaDebito = true;"
              *ngIf="factura?.generado_gti && showNotaDebito == false">
              Crear Nota de Débito <mat-icon style="font-size: 18px;">create</mat-icon>
            </button>

            <span *ngIf="!factura?.generado_gti">Factura Electronica No Generada</span>
          </div>

          <form [formGroup]="formNotaDebito" class="col-12" *ngIf="showNotaDebito == true && editNotaDebito == false">

            <div class="row mt-4">

              <div class="col-3">
                <label class="label">Descripción</label>
                <mat-form-field appearance="outline" class="form-field">
                  <input matInput placeholder="Descripción" class="input-field" formControlName="descripcion"
                    [disabled]="!editNotaCredito">
                </mat-form-field>
              </div>

              <div class="col-3">
                <label class="label col-12 p-0">Monto CRC</label>
                <mat-form-field appearance="outline" class="form-field">
                  <input matInput placeholder="Monto" class="input-field" formControlName="monto_crc"
                    (change)="convertCurrency('CRC', 'NotaDebito')" (keyup)="convertCurrency('CRC', 'NotaDebito')">
                  <mat-label class="prefix" matPrefix>CRC ¢</mat-label>

                </mat-form-field>
              </div>

              <div class="col-3">
                <label class="label">Monto USD</label>
                <mat-form-field appearance="outline" class="form-field">
                  <input matInput placeholder="Monto" class="input-field" formControlName="monto"
                    (change)="convertCurrency('USD', 'NotaDebito')" (keyup)="convertCurrency('USD', 'NotaDebito')">
                  <mat-label class="prefix" matPrefix>USD $</mat-label>
                </mat-form-field>
              </div>

              <div class="col-3">
                <label class="label"></label>
                <div class="form-field">
                  <button style="float: right;" mat-flat-button class="button-calcular" (click)="createNotaDebito()"
                    [disabled]="!formNotaDebito.valid">
                    <mat-icon>save</mat-icon>Guardar
                  </button>
                </div>
              </div>
            </div>

          </form>

          <div class="col-12 mb-4" *ngIf="showNotaDebito == true && editNotaDebito == true">
            <label class="label">Nota de Débito</label>
            <table class="factura" style="width:100%;">
              <tr>
                <th>Descripción</th>
                <th>Monto (Colones)</th>
                <th>Monto (USD)</th>
                <th>Opciones</th>
              </tr>
              <tr *ngFor="let notaDebito of factura.nota_debito">
                <td>{{ notaDebito?.descripcion }}</td>
                <td>₡{{ notaDebito?.monto_crc + notaDebito?.iva_crc | number : '1.2-2'}}</td>
                <th>${{ notaDebito?.monto + notaDebito?.iva_usd | number : '1.2-2'}}</th>
                <th *ngIf="authService.validateRolePermission('EMIT_NC_ND')">
                  <button mat-flat-button style="background-color: green !important; color: white !important;"
                    (click)="emitDebitNote(notaDebito)"
                    *ngIf="authService.isAdmin && !notaDebito.consecutivo_gti && !notaDebito.anulada">
                    <mat-icon style="color: white !important;">description</mat-icon>Emitir
                  </button>
                  <button mat-flat-button style="background-color: red !important; color: white !important;"
                    (click)="deleteNotaDebito(notaDebito)"
                    *ngIf="authService.isAdmin && !notaDebito.consecutivo_gti && !notaDebito.anulada">
                    <mat-icon style="color: white !important;">delete</mat-icon>Eliminar
                  </button>
                </th>
              </tr>
            </table>
          </div>

        </div>
      </tab>
    </tabset>

  </div>

  <hr class="m-0">

  <div mat-dialog-actions style="display: block;">
    <div class="row ml-2">
      <p class="title1">Totales</p>
    </div>

    <div class="row px-4">

      <div class="col-12 col-md-4">
        <label class="label col-12 p-0">IVA</label>
        <mat-form-field appearance="outline" class="form-field readonly-input">
          <input matInput placeholder="Monto" class="input-field" formControlName="iva" [readonly]="!this.editable">
          <mat-label class="prefix" matPrefix>USD $</mat-label>
          <mat-error *ngIf="form.controls['iva'].errors">
            {{getErrorMessage(form.controls['iva'])}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4">
        <label class="label col-12 p-0">SubTotal</label>
        <mat-form-field appearance="outline" class="form-field readonly-input">
          <input matInput placeholder="Monto" class="input-field" formControlName="subtotal"
            [readonly]="!this.editable">
          <mat-label class="prefix" matPrefix>USD $</mat-label>
          <mat-error *ngIf="form.controls['subtotal'].errors">
            {{getErrorMessage(form.controls['subtotal'])}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4">
        <label class="label col-12 p-0">Total</label>
        <mat-form-field appearance="outline" class="form-field readonly-input">
          <input matInput placeholder="Monto" class="input-field" formControlName="total" [readonly]="!this.editable">
          <mat-label class="prefix" matPrefix>USD $</mat-label>
          <mat-error *ngIf="form.controls['total'].errors">
            {{getErrorMessage(form.controls['total'])}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row p-4">
      <div class="col">
        <button mat-flat-button color="primary" class="button1" (click)="onNoClick()"
          style="float: left;">Cancelar</button>
      </div>
      <div class="col">
        <button mat-flat-button color="primary" class="button1" (click)="updateFactura()"
          style="float: right;">Guardar</button>
      </div>
    </div>


  </div>
</form>
