<h2 class="h2-title p-4">Almacenamiento</h2>

<!--<div style="display: flex; justify-content: flex-end;">
  <button style="min-width: inherit !important;" mat-flat-button color="primary" (click)="changeSize(fontSize + 1)"
    class="button-calcular">+</button>
  <button style="min-width: inherit !important;" mat-flat-button color="primary" (click)="changeSize(fontSize - 1)"
    class="button-calcular">-</button>
</div>-->

<div class="content" style="float: left; width: 30%" fxLayout="column">
  <div class="content-title">
    <p class="title">Embarques</p>
  </div>
  <!--select-->
  <div style="padding: 30px 20px 0;">

    <mat-form-field appearance="outline" style="padding-top: 5px">
      <mat-label>Seleccione Embarque</mat-label>
      <mat-select [(value)]="selectedAction" (selectionChange)="changeSelect($event)">
        <mat-option *ngFor="let act of embarques" [value]="act.id">
          Nro: {{ act.id }}&nbsp;AWB/BL: {{ act.awb_bl }}&nbsp; Fecha: {{act.date}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!---->
  <div class="pl-4  mt-4 pt-4 pr-4">
    <label class="label">Embarque</label>
    <br>
    Nro: {{selectedEmbarque?.id}}
    <br>
    Fecha: {{selectedEmbarque?.refdate}}
    <br>
    AWB/BL: {{selectedEmbarque?.awb ? selectedEmbarque?.awb : '-' }}
  </div>
  <br>
</div>

<div class="content inputs-form" style="float: right; width: 69%;" fxLayout="column">
  <div class="content-title">
    <p class="title">Paquetes</p>
  </div>

  <div class="pl-4 mt-4 pt-4 pr-4">
    <label class="label">Guia</label>
    <mat-form-field appearance="outline" class="form-field">
      <input matInput #track [(ngModel)]="numero_de_guia" rows="2" placeholder="Escanear Guía"
        (keyup.enter)="$event.preventDefault(); findByManualInput(track.value)">
    </mat-form-field>
    <span *ngIf="qrNoExiste" class="unavailableEmail">Paquete no pertenece a este embarque</span>
    <button mat-flat-button color="primary" (click)="findByManualInput(track.value)">Escanear</button>
  </div>
  <div class="pl-4 pr-4 text-left mt-2" style="min-height: max-content;background: cornflowerblue;;">
    <label class="label pt-2 pb-2" style="font-size: 30px !important;color: white;">
      {{selectionList?.selectedOptions.selected.length}} / {{paquetes.length}}</label>
  </div>
  <mat-tab-group>

    <mat-tab label="Detallado">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort *ngIf="dataSource.data.length > 0"
        style="width: 100%;">

        <!-- guia Column -->
        <ng-container matColumnDef="guia">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Guía </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
            {{element.numero_de_guia? element.numero_de_guia: '-'}}
          </td>
        </ng-container>

        <!-- estado Column -->
        <ng-container matColumnDef="estado">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Estado </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.estado? element.estado: '-'}}
          </td>
        </ng-container>

        <!-- envio Column -->
        <ng-container matColumnDef="nombre">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Nombre </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.usuario ?
            usuariosService.get_account_number(element.usuario) + '-' + usuariosService.get_fullname(element.usuario) :
            '-'}}
          </td>
        </ng-container>

        <!-- cuenta Column -->
        <ng-container matColumnDef="cuenta">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cuenta </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">{{element.usuario ?
            usuariosService.get_account_number(element.usuario) : '-'}}
          </td>
        </ng-container>

        <!-- tracking Column -->
        <ng-container matColumnDef="tracking">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Tracking </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.tracking? element.tracking :
            '-'}} </td>
        </ng-container>

        <!-- descripcion Column -->
        <ng-container matColumnDef="descripcion">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
            {{element.descripcion? element.descripcion : '-'}}
          </td>
        </ng-container>

        <!-- status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.status? element.status : '-'}}
          </td>
        </ng-container>

        <!-- peso real Column -->
        <ng-container matColumnDef="peso_real">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header>Dimensiones</th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element?.dimensions}}
          </td>
        </ng-container>

        <!-- peso volumetrico Column -->
        <ng-container matColumnDef="peso_volumetrico">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Peso </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.peso_total?
            element.peso_total + ' lbs'
            : '-'}}
          </td>
        </ng-container>

        <!-- escaneado Column -->
        <ng-container matColumnDef="escaneado">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Escaneado </th>
          <td mat-cell class="pr-pl-20 check-center" mat-cell *matCellDef="let element">
            <mat-checkbox [checked]="element.entrega.escaneado_distribucion"
              (click)="checkedEmbarque($event,element)"></mat-checkbox>
          </td>
        </ng-container>

        <!-- remitente Column -->
        <ng-container matColumnDef="remitente">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Remitente </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.remitente? element.remitente :
            '-'}}
          </td>
        </ng-container>

        <!-- remitente Column -->
        <ng-container matColumnDef="tipo">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Tipo </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.tipo_de_paquete?
            element.tipo_de_paquete : '-'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="tipo_paquete">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Tipo de paquete </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.tipo_de_paquete
            ? element.tipo_de_paquete
            : '-'}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row [ngClass]="{'selectedItem': row.entrega.escaneado_distribucion,  'itemClaseD': row.clase == 'D'}"
          *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </mat-tab>
    <mat-tab label="Lista de Tracking" [ngStyle]="{'font-size': fontSize}">
      <div class="p-4" *ngIf="paquetes.length > 0" style="max-height: 300px; overflow-y: auto;">
        <label class="label">Paquetes a Procesar:</label>
        <mat-selection-list #listapaquetes dense>
          <mat-list-option *ngFor="let paquete of paquetes" [value]="paquete" #option
            [ngClass]="{'selectedItem': false, 'readonly-block': true}">
            {{paquete.tracking}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div style="display: flex; justify-content: flex-end;" class="pl-4 pr-4 pt-5 pb-5"
    *ngIf="selectedEmbarque?.status_open">
    <button mat-flat-button color="primary" (click)="procesarPaquetes()" class="button-calcular">Procesar</button>
  </div>

</div>
