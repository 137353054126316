<!-- <h2 class="h2-title">Insertar Nuevos Datos</h2> -->


<div class="content pt-5">
  <div class="content-title">
    <p class="title">Ruta</p>
  </div>

  <div class="pl-4 pr-4 pt-4 d-flex justify-content-between">
    <button mat-button [routerLink]="['/admin/otros/rutas']" style="padding: 0; padding-right: 10px; color: #0094a3;">
      <mat-icon>keyboard_arrow_left</mat-icon> RUTAS
    </button>
    <button mat-button (click)="this.saveRoute()" style="padding: 0; margin-right: 0px; color: #0094a3;">
      GUARDAR <mat-icon>save</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <form fxLayout="row wrap" fxLayoutGap="20px" class="p-4 inputs-form d-flex align-items-center justify-content-center">
    <div class="container">
      <label class="label col-12">Nombre</label><br>
      <mat-form-field appearance="outline" class="form-field col-12">
        <input matInput placeholder="Nombre" class="input-field" [(ngModel)]="name" #nameCtrl="ngModel" name="name" required minlength="3">
        <mat-error *ngIf="nameCtrl.invalid && (nameCtrl.dirty || nameCtrl.touched)">{{getErrorMessage(nameCtrl)}}</mat-error>
      </mat-form-field>
    </div>
    <!-- <div>
      <label class="label">Chofer</label><br>
      <mat-form-field appearance="outline" class="form-field" >
        <input matInput placeholder="Chofer" aria-label="Chofer" [(ngModel)]="driver" #driverCtrl="ngModel" name="driver" [matAutocomplete]="auto" (keyup)="filterList($event)" required>
        <mat-error *ngIf="driverCtrl.invalid && (nameCtrl.dirty || nameCtrl.touched)">{{getErrorMessage(driverCtrl)}}</mat-error>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayDriverName">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div> -->
  </form>
</div>

