<div class="p-1 pt-3 content" fxLayout="column">
  <div class="content-title">
    <p *ngIf="editMode" class="title"> {{ conductor?.fecha | date: 'dd-MM-yy'}}&nbsp;{{ conductor?.nombre}}</p>
    <p style="margin-top:0px" *ngIf="!editMode" class="title"> Insertando nuevos datos</p>
  </div>

  <form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
    <div class="pt-3" style="margin-top: 35px; width: 100%;">
      <button mat-button (click)="back()" style="padding: 0; padding-right: 10px; color: #0094a3; float: left;">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>

      <button mat-button submit (click)="submitData()" style="float: right;">
        <mat-icon>save</mat-icon>Guardar
      </button>

      <button mat-button (click)="generatePDFEntregas()" matTooltip='Manifiesto Ruta' style="float: right;">
        <mat-icon>print</mat-icon>
      </button>

      <button mat-button (click)="generatePDFEntregasList()" matTooltip='Manifiesto Sucursal' style="float: right;">
        <mat-icon>print</mat-icon>
      </button>
    </div>

    <div fxLayout="row wrap" fxLayoutGap="20px" class="pt-3">
      <div class="container">
        <div class="twofield">
          <!-- Conductor -->
          <mat-form-field appearance="outline" class="col-12 col-md-4">
            <mat-label>Conductor</mat-label>
            <input matInput placeholder="Conductor" aria-label="Conductor" formControlName="conductor"
              [matAutocomplete]="auto2" required>
            <mat-error *ngIf="form.controls['conductor'].errors">
              {{getErrorMessage(form.controls['conductor'])}}
            </mat-error>
            <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" [displayWith]="displayUserName"
              (optionSelected)="adjuntarPaquetes($event)">
              <mat-option *ngFor="let option of filteredDriverOptions | async" [value]="option">
                {{option.nombre}}
              </mat-option>
            </mat-autocomplete>

            <button matSuffix mat-flat-button (click)="form.get('conductor').setValue('')">
              <mat-icon> close </mat-icon>
            </button>
          </mat-form-field>

          <!-- Fecha -->
          <mat-form-field appearance="outline" class="col-12 col-md-4">
            <mat-label>Fecha</mat-label>
            <input matInput formControlName="fecha" [matDatepickerFilter]="disabledDates" [min]="minDate"
              [matDatepicker]="picker" placeholder="Fecha" required>
            <mat-error *ngIf="form.controls['fecha'].errors">
              {{getErrorMessage(form.controls['fecha'])}}
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="mx-3">
          <mat-form-field appearance="outline" floatLabel="always" class="col-12 col-md-4">
            <mat-label id="escanear-label">Escanear Paquetes</mat-label>
            <input id="guia" #track matInput placeholder="Número de Guía"
              (keyup.enter)="findByManualInput(track.value); track.value = ''">
            <hr>
            <button mat-flat-button color="primary"
              (click)="findByManualInput(track.value); track.value = ''">Escanear</button>
          </mat-form-field>
        </div>

        <!-- Paquetes -->
        <input matInput formControlName="paquetes" hidden>

      </div>

    </div>

    <mat-tab-group>
      <mat-tab label="Paquetes ({{packageTotal}})" isActive="true">
        <div fxLayout="column" class="pt-3">

          <div class="content-table">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
              <!-- select Column -->
              <ng-container matColumnDef="select">
                <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header [disabled]="true">
                  <mat-checkbox class="d-flex" (click)="masterToggle()" aria-label="Seleccionar/Deseleccionar Todo"
                    title="Seleccionar/Deseleccionar Todo"></mat-checkbox>
                </th>

                <td mat-cell class="pr-pl-20" *matCellDef="let row">
                  <mat-checkbox class="d-flex justify-content-center" (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                    [aria-label]="checkboxLabel(row)"></mat-checkbox>
                </td>
              </ng-container>

              <!-- cuenta Column -->
              <ng-container matColumnDef="cuenta">
                <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cuenta </th>
                <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                  <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: element.usuarioid}">{{element.cuenta ?
                    element.cuenta :
                    '-'}}</a>
                </td>
              </ng-container>

              <!-- guia Column -->
              <ng-container matColumnDef="guia">
                <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Guía </th>
                <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                  <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: element.id}">
                    {{element.guia? element.guia : '-'}}
                  </a>
                </td>
              </ng-container>

              <!-- tracking Column -->
              <ng-container matColumnDef="tracking">
                <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Tracking </th>
                <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.tracking?
                  element.tracking :
                  '-'}}
                </td>
              </ng-container>

              <!-- cliente Column -->
              <ng-container matColumnDef="cliente">
                <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cliente </th>
                <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.nombre ? element.nombre
                  : '-'}}
                </td>
              </ng-container>

              <!-- Entregado Column -->
              <ng-container matColumnDef="entregado">
                <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Entregado </th>
                <td mat-cell class="pr-pl-20 check-center" mat-cell *matCellDef="let element">
                  <mat-slide-toggle #entre (toggleChange)="changeEntregado(element.tracking, !entre.checked)"
                    [disabled]="element.entregado" [checked]="element.entregado">
                  </mat-slide-toggle>
                </td>
              </ng-container>

              <!-- escanedo Column -->
              <ng-container matColumnDef="escaneado">
                <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Escaneado </th>
                <td mat-cell class="pr-pl-20 check-center" mat-cell *matCellDef="let element">
                  <mat-checkbox [checked]="element.escaneado_entrega" (click)="$event.preventDefault()"></mat-checkbox>
                </td>
              </ng-container>

              <!-- Observaciones Column -->
              <ng-container matColumnDef="observaciones">
                <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Observaciones </th>
                <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                  {{element.observacion}}
                  <button mat-button *ngIf="!element.entrega_observacion"
                    (click)="addObservation(element.entregaid, '')">
                    <mat-icon>add</mat-icon> Agregar
                  </button>
                  <button mat-button *ngIf="element.entrega_observacion"
                    (click)="addObservation(element.entregaid, element.entrega_observacion)">
                    {{element.entrega_observacion}}
                  </button>
                  <p *ngIf="element.entrega_observacion_conductor">
                    Observaciones Conductor: {{element.entrega_observacion_conductor}}
                  </p>

                </td>

              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row [class.no_entregado]="!row.entregado" [class.entregado]="row.entregado"
                *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
          <div class="text-center" *ngIf="packageTotal==0">No hay paquetes por entregar en la ruta</div>

          <div class="content-table mx-4 mt-4">
            <div class="col-12 text-left" style="float: left;">
              <button mat-flat-button color="primary" class="button-calcular" (click)="addPackages()">
                Agregar Paquete(s)
              </button>

              <button mat-flat-button color="primary" class="button-calcular" (click)="removePackages()">
                Eliminar Paquete(s)
              </button>

              <button mat-flat-button color="primary" class="button-calcular" (click)="transitShipment()">
                Poner en Tránsito hacia el cliente
              </button>

              <button mat-flat-button color="primary" class="button-calcular" (click)="transitLimon()">
                Poner en Tránsito a Limón
              </button>

            </div>

            <div class="text-right" style="float: right;">
              <p>Total Paquetes Seleccionados: {{ selection?.selected.length ?
                selection?.selected.length : 0 }}</p>
              <p>Total Paquetes: {{ packageTotal }}</p>
            </div>
          </div>

        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>
