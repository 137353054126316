import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import { DescuentosService } from '../../../../../../services/descuentos.service';

export interface Descuentos {
  id: number;
  tipo: string;
  valor: number;
  codigo: string;
  inicio: Date;
  fin: Date;
  usados: number;
  limite: number;

  exonerar_flete: boolean;
  exonerar_warehouse: boolean;
  exonerar_manejos: boolean;
  exonerar_seguro: boolean;
  exonerar_combustible: boolean;

  creado: Date;
}

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  length: number;
  pageSize = 10;
  pageIndex = 1;

  // eslint-disable-next-line max-len
  displayedColumns: string[] = ['codigo', 'tipo', 'porcentaje', 'usados', 'inicio', 'fin', 'creado'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<Descuentos>(true, []);


  constructor(
    public descuentosService: DescuentosService
  ) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getDescuentos();
  }

  getDescuentos() {
    this.descuentosService.get(this.pageIndex).subscribe(
      (res: any) => {

        const descuento: Descuentos[] = [];

        res.results.forEach(element => {
          descuento.push(
            {
              id: element.id,
              tipo: element.discount_type,
              valor: element.value,
              codigo: element.promo_code,
              inicio: element.started_at,
              fin: element.finishing_at,
              usados: element.used,
              limite: element.limit,
              exonerar_flete: element.exonerar_flete,
              exonerar_warehouse: element.exonerar_warehouse,
              exonerar_manejos: element.exonerar_manejos,
              exonerar_seguro: element.exonerar_seguro,
              exonerar_combustible: element.exonerar_combustible,
              creado: element.created_at
            }
          );
        });
        this.length = res.count;
        this.dataSource.data = descuento;
      }
    );
  }

  paginate(event: any) {
    //console.log(event);
    this.pageIndex = event;
    this.getDescuentos();
  }

}
