import { Component, OnInit } from '@angular/core';
import { UsuariosService } from '../../../../../services/usuarios.service';

@Component({
  selector: 'app-tarjetas',
  templateUrl: './tarjetas.component.html',
  styleUrls: ['./tarjetas.component.scss']
})
export class TarjetasComponent implements OnInit {

  tarjetas = [];
  displayedColumnsClientes = ['tarjeta', 'cuenta', 'nombre'];

  constructor(
    public usuariosService: UsuariosService
  ) { }

  ngOnInit(): void {

  }

  buscarTarjeta(value) {
    this.usuariosService.searchCards(value).subscribe((data: any) => {
      this.tarjetas = data;

    });
  }
}
