<div style="padding: 24px;overflow: auto;">

  <div class="row" mat-dialog-title>

    <div class="col-12" style="display: flex; align-items: center; justify-content: space-between;">
      <div style="text-align: left;">
        <span *ngIf="!paquete.usuario?.name" style="color:orange; font-size:24px !important">Cuenta:
          {{usuariosService.get_fullaccount(paquete.usuario)}}
        </span>
        <span *ngIf="paquete.usuario?.name" style="color:orange; font-size:24px !important">Cuenta: {{selectedAccount}}
        </span> <br>
        <span style="color:orange; font-size:24px !important">Guía: {{paquete.numero_de_guia}} </span>
        <span *ngIf="paquetes?.length > 0" style="color:#0094a3; font-size:24px !important">Consolidados:</span>
        <span (click)="openModalConsolidation(item)" *ngFor="let item of paquetes"
          style="color:#0094a3;cursor: pointer; font-size:24px !important"> {{item.numero_de_guia}}
        </span>
      </div>

      <h6 class="top-title" *ngIf="deletePackage" style="background-color:red; color:white; padding: 3px;">
        PAQUETE {{paquete.numero_de_guia}} ELIMINADO
      </h6>
      <div>

        <button mat-flat-button color="primary" class="button-calcular" [disabled]="printButtonDisabled()"
          (click)="printLabel()" *ngIf="editMode && !deletePackage">
          <mat-icon style="color: white !important; font-size: 16px !important;">print</mat-icon>Imprimir Etiqueta
        </button>

        <button mat-flat-button color="primary" class="button-calcular" [disabled]="!paquetesForm?.valid"
          (click)="crearPaquete()" *ngIf="!editMode && !deletePackage">
          <mat-icon style="color: white !important; font-size: 16px !important;">save</mat-icon> Guardar
        </button>
        <button mat-flat-button color="primary" class="button-calcular" (click)="actualizarPaquete()"
          *ngIf="editMode && !deletePackage" [disabled]="!paquetesForm?.valid">
          <mat-icon style="color: white !important; font-size: 16px !important;">save</mat-icon> Guardar
        </button>
      </div>
    </div>

  </div>

  <mat-dialog-content>

    <form *ngIf="paquetesForm" [formGroup]="paquetesForm" (keyup.enter)="$event.preventDefault()">
      <div class="row mb-2">
        <div class="col-12 col-md-6 ml-6" *ngIf="addls">
          <p style="color: red" class="pl-6">* Convirtiendo paquete en bultos adicionales</p>
        </div>
        <div class="col-12 col-md-6 ml-6" *ngIf="consolidar">
          <p style="color: red" class="pl-6">* Consolidando paquetes </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6" [ngClass]="addls ? 'col-md-12': 'col-md-6'">
          <!-- Inner content goes here -->
          <div class="col-12 px-0 content-title">
            <p class="title" style="line-height: 10px;margin-top: 0 !important; padding-top: 0 !important">
              Información de Ingreso</p>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <label class="label_form_field_required">Tracking</label><br>
                <mat-form-field appearance="outline">
                  <input #tracking matInput placeholder="Tracking" formControlName="tracking" required onReturn
                    (keyup.enter)="tipo.focus()" [readonly]="editMode && !authService.isMiami">
                </mat-form-field>
                <mat-error
                  *ngIf="paquetesForm?.controls['tracking'].errors && paquetesForm?.controls['tracking'].touched">
                  {{getErrorMessage(paquetesForm?.controls['tracking'])}}
                </mat-error>
              </div>

              <div class="col-12 col-md-6 col-lg-6">
                <label class="label label_form_field_required">Tipo de paquete</label><br>
                <mat-form-field appearance="outline">
                  <mat-select (ngModelChange)="changeSelect($event)" #tipo matNativeControl
                    formControlName="tipo_de_paquete" required (keyup.enter)="tipo.close(); remitente.focus()">
                    <mat-option value="Caja" selected>Caja</mat-option>
                    <mat-option value="Correspondencia">Correspondencia</mat-option>
                    <mat-option value="SobreBolsa">Sobre/Bolsa</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 col-lg-6">
                <label class="label label_form_field_required">Remitente</label><br>
                <mat-form-field appearance="outline">
                  <input #remitente matInput placeholder="Remitente" formControlName="remitente"
                    (keyup.enter)="cuenta.focus()" [matAutocomplete]="auto">
                  <!--auto complete-->
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayStores">
                    <mat-option *ngFor="let productType of filteredOptionsStores | async" [value]="productType">
                      <span> {{ productType }}</span>
                    </mat-option>
                  </mat-autocomplete>
                  <!---->
                </mat-form-field>
                <mat-error
                  *ngIf="paquetesForm?.controls['remitente'].errors && paquetesForm?.controls['remitente'].touched">
                  {{getErrorMessage(paquetesForm?.controls['remitente'])}}
                </mat-error>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="label label_form_field_required">Cuenta</label><br>
                <mat-form-field appearance="outline">
                  <input #cuenta matInput placeholder="Cuenta" formControlName="usuario" [matAutocomplete]="autocuenta"
                    required (keyup.enter)="descripcion.focus()" #cuenta
                    (keyup)="buscarListaClientes($event, cuenta.value)" (change)="handleClientChange()">
                  <mat-autocomplete autoActiveFirstOption #autocuenta="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      {{option.name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-error
                  *ngIf="paquetesForm?.controls['usuario'].errors && paquetesForm?.controls['usuario'].touched">
                  {{getErrorMessage(paquetesForm?.controls['usuario'])}}
                </mat-error>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12">
                <label class="label">Descripción</label><br>
                <textarea #descripcion matInput placeholder="Descripción" formControlName="descripcion" rows="2"
                  (keyup.enter)="descripcion.value.length > 0 ? precio.focus() : destinatario.focus()"
                  style="width: 100%;"></textarea>
                <mat-error
                  *ngIf="paquetesForm?.controls['descripcion'].errors && paquetesForm?.controls['descripcion'].touched">
                  {{getErrorMessage(paquetesForm?.controls['descripcion'])}}
                </mat-error>
              </div>

            </div>

            <div class="row">
              <div class="col-10">
                <label class="label">Destinatario</label><br>
                <mat-form-field appearance="outline">
                  <input #destinatario matInput placeholder="Destinatario" formControlName="destinatario"
                    (keyup.enter)="precio.focus()">
                </mat-form-field>
                <mat-error
                  *ngIf="paquetesForm?.controls['destinatario'].errors && paquetesForm?.controls['destinatario'].touched">
                  {{getErrorMessage(paquetesForm?.controls['destinatario'])}}
                </mat-error>
              </div>
              <div class="col-2">
                <label class="label">Factura</label><br>
                <mat-checkbox formControlName="factura_miami" appearance="outline" style="width: 250px;"></mat-checkbox>
              </div>
            </div>

            <div class="row" *ngIf="changePlanActive">
              <div class="col-12">
                <label class="label label_form_field_required">Método de envío</label><br>
                <mat-form-field appearance="outline">
                  <mat-select required formControlName="plan" #plan (valueChange)="changePlan(plan.value)">
                    <mat-option value="A" selected>Super Rápido (Aéreo)</mat-option>
                    <mat-option value="M" selected>Super Económico (Marítimo)</mat-option>
                    <mat-option value="C" selected>Super Compacto (Consolidado)</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <label class="label">Cantidad de paquetes</label><br>
                <mat-form-field appearance="outline">
                  <input matInput type="number"
                    [readonly]="authService.activeRol == 'ALMAC' && !deletePackage ? false: true"
                    placeholder="Cantidad de paquetes" [value]="cantPaquetes">
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6">
                <label class="label">Precio (declarado en USD $)</label><br>
                <mat-form-field appearance="outline">
                  <input #precio matInput placeholder="Precio (declarado)" formControlName="precio" numeric decimals="2"
                    (keyup.enter)="precio.value.length > 0 ? (largo ? largo.focus(): null ): precio.focus()">
                  <mat-label class="prefix" matPrefix>USD $</mat-label>
                </mat-form-field>
                <mat-error *ngIf="paquetesForm?.controls['precio'].errors && paquetesForm?.controls['precio'].touched">
                  {{getErrorMessage(paquetesForm?.controls['precio'])}}
                </mat-error>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="label">Compra Corporativa</label><br>
                <mat-form-field appearance="outline">
                  <input #compra matInput placeholder="Compra Corporativa" (keyup.enter)="loadGuide(compra.value)"
                    (change)="loadGuide(compra.value)" [readonly]="!deletePackage ? false: true">
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="label">Observaciones</label><br>
                <textarea #observaciones rows="5" placeholder="Observaciones" formControlName="observacion"
                  style="width: 100%;"></textarea>
                <mat-error
                  *ngIf="paquetesForm?.controls['observacion'].errors && paquetesForm?.controls['observacion'].touched">
                  {{getErrorMessage(paquetesForm?.controls['observacion'])}}
                </mat-error>
              </div>
            </div>
          </div>

        </div>

        <div class="col-12 col-md-6" *ngIf="!addls">
          <div class="col-12 px-0 content-title">
            <p class="title" style="line-height: 10px;margin-top: 0 !important; padding-top: 0 !important">Peso y
              Medidas</p>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-12 col-md-4 col-lg-4">
                <label class="label">Largo</label><br>
                <mat-form-field appearance="outline">
                  <input #largo numeric matInput formControlName="largo" (keyup)="calcularMedidas()" autocomplete="off"
                    list="autocompleteOff" aria-autocomplete="none"
                    (keyup.enter)="largo.value.length > 0 ? alto.focus() : largo.focus()">
                </mat-form-field>
              </div>

              <div class="col-12 col-md-4 col-lg-4">
                <label class="label">Alto</label><br>
                <mat-form-field appearance="outline">
                  <input #alto numeric matInput formControlName="alto" (keyup)="calcularMedidas()" autocomplete="off"
                    list="autocompleteOff" aria-autocomplete="none"
                    (keyup.enter)="alto.value.length > 0 ? ancho.focus() : alto.focus()">
                </mat-form-field>
              </div>

              <div class="col-12 col-md-4 col-lg-4">
                <label class="label">Ancho</label><br>
                <mat-form-field appearance="outline">
                  <input #ancho numeric matInput formControlName="ancho" autocomplete="off" list="autocompleteOff"
                    aria-autocomplete="none" (keyup)="calcularMedidas(); calcularPesoCobrable()"
                    (keyup.enter)="preal.focus()" (keyup.tab)="ancho.value.length > 0 ? preal.focus() : ancho.focus()">
                </mat-form-field>
              </div>

            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <label class="label label_form_field_required">Peso Real Lbs.</label><br>
                <mat-form-field appearance="outline">
                  <input #preal type="number" matInput placeholder="Peso Real" formControlName="peso" autocomplete="off"
                    list="autocompleteOff" aria-autocomplete="none"
                    (change)="calcularPesoCobrable(); UpdateKg(preal.value)"
                    (keyup.enter)="preal.value.length > 0 ? ptotal.focus() : precio.focus()">
                </mat-form-field>
                <mat-error *ngIf="paquetesForm?.controls['peso'].errors && paquetesForm?.controls['peso'].touched">
                  {{getErrorMessage(paquetesForm?.controls['peso'])}}
                </mat-error>
              </div>

              <div class="col-12 col-md-6">
                <label class="label label_form_field_required">Peso Real Kg.</label><br>
                <mat-form-field appearance="outline">
                  <input #prealkg type="number" [(ngModel)]="pesoRealKg" [ngModelOptions]="{standalone: true}" matInput
                    placeholder="Peso Real Kg." readonly>
                </mat-form-field>
              </div>

            </div>

            <div class="row">

              <div class="col-12 col-md-6">
                <label class="label label_form_field_required">Peso Total Lbs.</label><br>
                <mat-form-field appearance="outline">
                  <input #ptotal matInput placeholder="Peso Total" formControlName="peso_total" readonly>
                </mat-form-field>
                <mat-error
                  *ngIf="paquetesForm?.controls['peso_total'].errors && paquetesForm?.controls['peso_total'].touched">
                  {{getErrorMessage(paquetesForm?.controls['peso_total'])}}
                </mat-error>
              </div>

              <div class="col-12 col-md-6">
                <label class="label label_form_field_required">Peso Total Kg.</label><br>
                <mat-form-field appearance="outline">
                  <input #ptotalkg type="number" [(ngModel)]="pesoTotalKg" [ngModelOptions]="{standalone: true}"
                    matInput placeholder="Peso Total Kg." readonly>
                </mat-form-field>
              </div>

            </div>

            <div class="row">

              <div class="col-12 col-md-6">
                <label class="label ">Peso Volumen</label><br>
                <mat-form-field appearance="outline">
                  <input matInput placeholder="Peso Volumen" formControlName="peso_volumetrico" readonly>
                </mat-form-field>
              </div>

            </div>
          </div>

          <div class="col-12" *ngIf="!deletePackage">
            <div class="col-12 px-0 content-title">
              <p class="title" style="line-height: 10px;margin-top: 0 !important; padding-top: 0 !important">Imágenes
              </p>
            </div>

            <div class="inputs-form mt-5" *ngIf="paquete?.paquete_contenedor?.length == 0">
              <div *ngIf="paquete.factura_1 == null; else imagen_factura_1">
                <div class="d-inline-block w-50" fileDragDrop (filesChangeEmiter)="processFile($event, '1')">
                  <button> <label for="1">Imagen de Factura 1</label></button>

                  <input type="file" id="1" formcontrolname="factura_1" class="d-none"
                    accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .doc, .docx, .pdf"
                    (change)="processFile($event.target.files, $event.target.id)">

                  <span class="d-inline-block" style="margin-left: 50%;"
                    *ngIf="this.fileLoad1">{{fileLoad1.name}}</span>

                </div>

                <div class="d-inline-block">
                  <button type="button" mat-flat-button (click)="openCamera($event, 'factura_1')">
                    <mat-icon>camera</mat-icon>
                    Tomar Foto
                  </button>
                </div>
              </div>

              <ng-template #imagen_factura_1>
                <div class="justify-content-center  text-center">
                  <span class="span-label">Imagen de Factura 1</span>

                  <button mat-flat-button color="primary" style="width: auto;"
                    *ngIf="paquete.factura_1.includes('.doc') || paquete.factura_1.includes('.docx') || paquete.factura_1.includes('.pdf')"
                    class="button-archivo mt-2 d-flex mx-auto" (click)="gotoFile(paquete.factura_1)" mat-flat-button
                    color="primary">
                    <mat-icon>download</mat-icon>{{paquete.factura_1.split('/')[3]}}
                  </button>

                  <img class="d-flex mx-auto" [src]="mediaUrl+paquete.factura_1" alt="Imagen de Factura 1" width="150px"
                    *ngIf="!paquete.factura_1.includes('.doc') && !paquete.factura_1.includes('.docx') && !paquete.factura_1.includes('.pdf')">
                </div>
              </ng-template>

              <div *ngIf="paquete.factura_2 == null; else imagen_factura_2">
                <div class="d-inline-block w-50" fileDragDrop (filesChangeEmiter)="processFile($event, '2')">
                  <button> <label for="2">Imagen de Factura 2</label></button>
                  <input type="file" id="2" formcontrolname="factura_2" class="d-none"
                    accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .doc, .docx, .pdf"
                    (change)="processFile($event.target.files, $event.target.id)">

                  <span class="d-inline-block" style="margin-left: 50%;"
                    *ngIf="this.fileLoad2">{{fileLoad2.name}}</span>
                </div>
                <div class="d-inline-block">
                  <button type="button" mat-flat-button (click)="openCamera($event, 'factura_2')">
                    <mat-icon>camera</mat-icon>
                    Tomar Foto
                  </button>
                </div>
              </div>
              <ng-template #imagen_factura_2>
                <div class="justify-content-center  text-center">
                  <span class="span-label">Imagen de Factura 2</span>

                  <button mat-flat-button color="primary" style="width: auto;"
                    *ngIf="paquete.factura_2.includes('.doc') || paquete.factura_2.includes('.docx') || paquete.factura_2.includes('.pdf')"
                    class="button-archivo mt-2 d-flex mx-auto" (click)="gotoFile(paquete.factura_2)" mat-flat-button
                    color="primary">
                    <mat-icon>download</mat-icon>{{paquete.factura_2.split('/')[3]}}
                  </button>

                  <img class="d-flex mx-auto" [src]="mediaUrl+paquete.factura_2" alt="Imagen de Factura 2" width="150px"
                    *ngIf="!paquete.factura_2.includes('.doc') && !paquete.factura_2.includes('.docx') && !paquete.factura_2.includes('.pdf')">
                </div>
              </ng-template>

              <div *ngIf="paquete.factura_3 == null; else imagen_factura_3">
                <div class="d-inline-block w-50" fileDragDrop (filesChangeEmiter)="processFile($event, '3')">
                  <button><label for="3">Imagen de Factura 3</label></button>
                  <input type="file" id="3" formcontrolname="factura_3" class="d-none"
                    accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .doc, .docx, .pdf"
                    (change)="processFile($event.target.files, $event.target.id)">

                  <span class="d-inline-block" style="margin-left: 50%;"
                    *ngIf="this.fileLoad3">{{fileLoad3.name}}</span>
                </div>
                <div class="d-inline-block">
                  <button type="button" mat-flat-button (click)="openCamera($event, 'factura_3')">
                    <mat-icon>camera</mat-icon>
                    Tomar Foto
                  </button>
                </div>
              </div>

              <ng-template #imagen_factura_3>
                <div class="justify-content-center  text-center">
                  <span class="span-label">Imagen de Factura 3</span>

                  <button mat-flat-button color="primary" style="width: auto;"
                    *ngIf="paquete.factura_3.includes('.doc') || paquete.factura_3.includes('.docx') || paquete.factura_3.includes('.pdf')"
                    class="button-archivo mt-2 d-flex mx-auto" (click)="gotoFile(paquete.factura_3)" mat-flat-button
                    color="primary">
                    <mat-icon>download</mat-icon>{{paquete.factura_3.split('/')[3]}}
                  </button>

                  <img class="d-flex mx-auto" [src]="mediaUrl+paquete.factura_3" alt="Imagen de Factura 3" width="150px"
                    *ngIf="!paquete.factura_3.includes('.doc') && !paquete.factura_3.includes('.docx') && !paquete.factura_3.includes('.pdf')">
                </div>
              </ng-template>

              <div *ngIf="paquete.imagen == null; else imagen_paquete">
                <div class="d-inline-block w-50" fileDragDrop (filesChangeEmiter)="processFile($event, '4')">
                  <button><label for="4">Imagen de Paquete</label></button>
                  <input type="file" id="4" formcontrolname="imagen" class="d-none" accept="image/*"
                    (change)="processFile($event.target.files, $event.target.id)">
                  <span class="d-inline-block" style="margin-left: 50%;"
                    *ngIf="this.fileLoad4">{{fileLoad4.name}}</span>
                </div>
                <div class="d-inline-block">
                  <button type="button" mat-flat-button (click)="openCamera($event, 'imagen')">
                    <mat-icon>camera</mat-icon>
                    Tomar Foto
                  </button>
                </div>
              </div>
              <ng-template #imagen_paquete>
                <div class="justify-content-center  text-center">
                  <span class="span-label">Imagen de Paquete</span>
                  <img class="d-flex mx-auto" [src]="mediaUrl+paquete.imagen" alt="Imagen de Paquete" width="150px">
                </div>
              </ng-template>

              <div>
                <div class="span-label">
                  Para adjuntar su documento puede usar los siguiente tipos de archivos PNG, JPG, WORD, PDF y el archivo
                  debe ser menor a 20 MB.
                </div>
              </div>
            </div>

            <div class="inputs-form mt-2" style="width: 600px;" *ngIf="paquete.paquete_contenedor?.length > 0">
              <span class="span-label">Facturas adjuntas a Guías Consolidadas</span>
              <div class="justify-content-center text-center" *ngIf="facturasConsolidadas.length == 0">
                <span class="span-label">No hay imágenes de facturas en las guías consolidadas. Solicitar al cliente
                  adjunte las facturas</span>
                <br>

                <!-- <button mat-flat-button color="primary" (click)="goToConsolidates()">  <mat-icon style="color: white !important; font-size: 16px !important;">remove_red_eye</mat-icon> Ver guias</button> -->
              </div>

              <div class="justify-content-center text-center" *ngFor="let f of facturasConsolidadas">

                <img *ngIf="!f.viewFile" class="d-flex mx-auto" [src]="mediaUrl+ f.factura" alt="Imagen de Factura"
                  width="150px">

                <button style="width: auto;margin-left: 15px;" *ngIf="f.viewFile && f.factura"
                  class="button-archivo mt-2" (click)="gotoFileF(f.factura)" mat-flat-button color="primary">
                  <mat-icon style="color: white !important; font-size: 16px !important;">remove_red_eye</mat-icon>
                  {{f.factura.split('/')[3]}}
                </button>
              </div>


            </div>
          </div>

        </div>

      </div>

      <div>
        <div style="overflow: scroll !important;">
          <label class="label">Bultos adicionales ({{dataSourceAdicionales.length}}) </label>
          <button style="float: right;" type="button" mat-flat-button color="primary" class="button-consultar" [disabled]="printButtonDisabled()"
            (click)="addPackagesAdditional()" *ngIf="(addls && ! deletePackage) || isSICSAAccount">Añadir
            Paquete</button>

          <button style="float: right;" type="button" mat-flat-button color="primary" class="button-consultar mr-2 mb-4"
            (click)="insertPackagesAdditional()" *ngIf="(addls && !deletePackage) || isSICSAAccount">Ingresar
            Paquete</button>

          <table class="pt-1" mat-table [dataSource]="dataSourceAdicionales" matSort style="width: 100%;"
            *ngIf="dataSourceAdicionales.length > 0">

            <!-- tracking Column -->
            <ng-container matColumnDef="tracking">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Tracking </th>
              <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.tracking?
                element.tracking :
                '-'}} </td>
            </ng-container>

            <!-- peso Column -->
            <ng-container matColumnDef="peso">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Peso </th>
              <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                {{element.peso ? element.peso : '-'}}
              </td>
            </ng-container>

            <!-- alto Column -->
            <ng-container matColumnDef="alto">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Altura </th>
              <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                {{element.alto ? element.alto : '-'}}
              </td>
            </ng-container>

            <!-- ancho Column -->
            <ng-container matColumnDef="ancho">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Ancho </th>
              <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                {{element.ancho ? element.ancho : '-'}}
              </td>
            </ng-container>

            <!-- largo Column -->
            <ng-container matColumnDef="largo">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Largo </th>
              <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                {{element.largo ? element.largo : '-'}}
              </td>
            </ng-container>

            <!-- peso_total Column -->
            <ng-container matColumnDef="peso_total">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Peso Cobrado </th>
              <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                {{element.peso_total ? element.peso_total : '-'}}
              </td>
            </ng-container>

            <!-- guia Column -->
            <ng-container matColumnDef="guia">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Guía </th>
              <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: element.id}">
                  {{element.numero_de_guia? paquete.numero_de_guia + '-' + element.bulto : '-'}}
                </a>
              </td>
            </ng-container>

            <!-- peso_volumetrico Column -->
            <ng-container matColumnDef="peso_volumetrico">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Peso Volúmetrico</th>
              <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                {{element.peso_volumetrico ? element.peso_volumetrico : '-'}}
              </td>
            </ng-container>

            <!-- Bulto Column -->
            <ng-container matColumnDef="numero_bulto">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Número de Bulto </th>
              <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                {{element.bulto ? element.bulto : '-'}}
              </td>
            </ng-container>

            <!-- eliminar Column -->
            <ng-container matColumnDef="eliminar">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Opciones</th>
              <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                <button class="del-item" mat-button (click)="updatePackagesAdditional(element)"
                  *ngIf="bultos.length > 0">
                  <mat-icon>edit</mat-icon>
                </button>
                <button class="del-item" mat-button (click)="removePackageAdditional(element.id)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsAdicionales; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsAdicionales;"></tr>
          </table>

        </div>
      </div>

    </form>

  </mat-dialog-content>

  <div style="margin: auto; width: fit-content;" *ngIf="data.pendientes">

    <button mat-flat-button color="primary" class="button-calcular" (click)="previousPackages()">
      <mat-icon style="color: white !important; font-size: 16px !important;">skip_previous</mat-icon>
    </button>

    <button mat-flat-button color="primary" class="button-calcular" (click)="nextPackages()">
      <mat-icon style="color: white !important; font-size: 16px !important;">skip_next</mat-icon>
    </button>

  </div>

</div>
