/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/naming-convention */
import printJS from 'print-js';
import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';

import { UsuariosService } from '../../../../../services/usuarios.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../../../../../services/auth.service';
import { Sort } from '@angular/material/sort';

interface Cuentas {
  id: number;
  name: string;
  account: string;
  ci: string;
  email: string;
  phone: string;
  date: any;
  delete_date: any;
  delete_by: any;
}

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {
  length: number;
  pageSize = 50;
  pageIndex = 1;
  copy_data = [];
  email = new FormControl('', [Validators.required, Validators.email]);
  displayedColumns: string[] = ['account', 'tipo_cliente', 'email', 'date', 'delete_date', 'delete_by'];
  dataSource = new MatTableDataSource<Cuentas>();
  selection = new SelectionModel<Cuentas>(true, []);

  form = this.fb.group({
    name: [''],
    ci: [''],
    email: [''],
    account: [''],
    accountType: [''],
    clientType: [''],
    date_from: [''],
    date_until: [''],
    phone: ['']
  });

  get fromDate() {
    return this.form.get('date_from').value;
  }

  get toDate() {
    return this.form.get('date_until').value;
  }

  advanced = false;
  tipoClientes: any[] = [];

  tipoCuentas: any[] = [
    { name: 'Administrador', value: 'ADMIN' },
    { name: 'Tracking', value: 'TRACK' },
    { name: 'Transportista', value: 'TRANSP' },
    { name: 'Cliente', value: 'CLIENT' },
    { name: 'Almacen', value: 'ALMAC' },
    { name: 'Vendedor', value: 'VEND' },
    { name: 'Servicio al Cliente', value: 'SERVCLI' },
    { name: 'Pedimentación', value: 'PEDIM' },
    { name: 'Operaciones Miami', value: 'OPMIA' },
    { name: 'Operaciones Costa Rica', value: 'OPCR' },
    { name: 'Contabilidad', value: 'CONTAB' },
  ];

  selectedFilter = '';

  timeout: any = null;

  accountType = '';
  clientType = '';

  constructor(
    private fb: FormBuilder,
    public usuariosService: UsuariosService,
    private spinnerService: NgxSpinnerService,
    public authService: AuthService
  ) { }

  ngOnInit() {


    this.usuariosService.getTipoCliente().subscribe((value: any) => {
      this.tipoClientes = value;
    });

    if (localStorage.getItem('accountType')) {
      this.form.get('accountType').setValue(localStorage.getItem('accountType'));
      this.selectedFilter = this.selectedFilter + '&rol=' + localStorage.getItem('accountType');
      this.accountType = localStorage.getItem('accountType');
    }

    if (localStorage.getItem('clientType')) {
      this.form.get('clientType').setValue(localStorage.getItem('clientType'));
      this.selectedFilter = this.selectedFilter + '&rol=CLIENT&tipo_cliente__id__exact=' + localStorage.getItem('clientType');
      this.clientType = localStorage.getItem('clientType');
    }

    if (localStorage.getItem('date_from_cuenta')) {
      this.form.get('date_from').setValue(localStorage.getItem('date_from_cuenta'));
      this.selectedFilter = this.selectedFilter + '&created_at__from=' + localStorage.getItem('date_from_cuenta');
    }

    if (localStorage.getItem('date_until_cuenta')) {
      this.form.get('date_until').setValue(localStorage.getItem('date_until_cuenta'));
      this.selectedFilter = this.selectedFilter + '&created_at__to=' + localStorage.getItem('date_until_cuenta');
    }

    this.getUsers(this.selectedFilter);
  }

  filtersAdvanced() {
    this.advanced = !this.advanced;
  }

  select(event) {
    this.getUsers(this.selectedFilter + '&rol=CLIENT&tipo_cliente__id__exact=' + event.value);
    this.selectedFilter = this.selectedFilter + '&rol=CLIENT&tipo_cliente__id__exact=' + event.value;
    this.clientType = event.value;
    localStorage.setItem('clientType', this.clientType);
  }

  selectTipoCuenta(event) {
    this.getUsers(this.selectedFilter + '&rol=' + event.value);
    this.selectedFilter = this.selectedFilter + '&rol=' + event.value;
    this.accountType = event.value;
    localStorage.setItem('accountType', this.accountType);
  }

  doFilter = (event = { keyCode: 13 }, value: string, field) => {

    clearTimeout(this.timeout);
    const $this = this;
    this.timeout = setTimeout(async function () {
      if (event.keyCode !== 13) {
        await $this.getUsers($this.selectedFilter + '&' + field + '=' + value);
        $this.selectedFilter = $this.selectedFilter + '&' + field + '=' + value;
      }
    }, 1000);

  };

  public async filterTable() {
    this.spinnerService.show();

    let filter = '';

    Object.keys(this.form.value).forEach(async (key, index, array) => {
      console.log(key);

      console.log(this.form);

      if (this.form.value[key] !== '') {

        if (key === 'name') {
          filter = filter + '&nombre=' + this.form.value[key];
        }

        if (key === 'ci') {
          filter = filter + '&numero_de_cedula=' + this.form.value[key];
        }

        if (key === 'email') {
          filter = filter + '&email=' + this.form.value[key];
        }

        if (key === 'account') {
          filter = filter + '&cuenta=' + this.form.value[key];
        }

        if (key === 'date_from') {
          if (this.form.value[key] != null) {
            filter = filter + '&created_at__from=' + this.form.value[key];
            localStorage.setItem('date_from_cuenta', this.form.value[key]);
          }

        }

        if (key === 'date_until') {
          if (this.form.value[key] != null) {
            filter = filter + '&created_at__to=' + this.form.value[key];
            localStorage.setItem('date_until_cuenta', this.form.value[key]);
          }
        }

        if (key === 'phone') {
          filter = filter + '&telefono_movil=' + this.form.value[key];
          // filter = filter + '&telefono_oficina=' + this.form.value[key];
          // filter = filter + '&telefono_casa=' + this.form.value[key];
        }
      }

      if (this.accountType !== '') {
        filter = filter + '&rol=' + this.accountType;
      }

      if (this.clientType !== '') {
        filter = filter + '&rol=CLIENT&tipo_cliente__id__exact=' + this.clientType;
      }

      if (index == array.length - 1) {
        this.selectedFilter = filter;
        this.getUsers(filter);
        this.spinnerService.hide();
      }
    });

  }

  searchUser(value): Promise<string> {
    return new Promise((resolve) => {
      let filter = '';
      this.usuariosService.search(value).subscribe((res: any) => {
        res.forEach(element => {
          filter = filter + '&usuario=' + element.id;
        });
        resolve(filter);
      });
    });
  }

  getUsers(filter = '') {
    return new Promise<void>((resolve, reject) => {
      this.dataSource.data = [];
      this.usuariosService.get_users(this.pageIndex, filter).subscribe(
        (res: any) => {

          const data = [];
          res.results.forEach((element, index, array) => {
            data.push({
              id: element.id,
              // eslint-disable-next-line max-len
              name: this.usuariosService.get_fullname(element).startsWith('null') ? element.razon_social : this.usuariosService.get_fullname(element),
              account: this.usuariosService.get_account_number(element),
              ci: element.numero_de_cedula,
              email: element.email,
              phone: element.telefono_movil,
              date: new Date(element.created_at),
              tipo_cliente: element.tipo_cliente,
              tipo_cuenta: element.rol,
              delete_date: element.delete_date ? new Date(element.delete_date) : '-',
              delete_by: element.delete_by
            });
            if (index == array.length - 1) {
              this.dataSource.data = data;
              //console.log(data);
              this.copy_data = data;
              this.length = res.count;
              resolve();
            }
          });
        }
      );
    });

  }

  getErrorMessage() {
    return this.email.hasError('required') ? 'Debes ingresar un correo' :
      this.email.hasError('email') ? 'No es un correo valido' :
        '';
  }

  public cleanFilters() {
    this.pageSize = 50;
    this.pageIndex = 1;
    this.selectedFilter = '';
    this.accountType = '';
    this.clientType = '';
    localStorage.removeItem('date_from_cuenta');
    localStorage.removeItem('date_until_cuenta');
    localStorage.removeItem('clientType');
    localStorage.removeItem('accountType');
    this.getUsers();
    this.form.reset();
  }

  public printUsersTable() {
    printJS({
      printable: 'users-table',
      type: 'html'
    });
  }

  paginate(event: any) {
    this.pageIndex = event;
    this.form.reset();
    this.getUsers(this.selectedFilter);

  }

  sortData(sort: Sort) {
    console.log(sort);
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {

        case 'account':
          return compare(a.account, b.account, isAsc);
        case 'email':
          return compare(a.email, b.email, isAsc);
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'delete_date':
          return compare(a.delete_date, b.delete_date, isAsc);
        case 'delete_by':
          return compare(a.delete_by, b.delete_by, isAsc);

        default:
          return 0;
      }
    });
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  //console.log(a,b);
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
