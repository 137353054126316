/* eslint-disable max-len */
export const TRANSLATIONS = [
  {
    es: 'Iniciar Sesión',
    en: 'Log In'
  },
  {
    es: 'Correo Electrónico, Cuenta, Cédula',
    en: 'Email, Account, ID'
  },
  {
    es: 'Contraseña',
    en: 'Password'
  },
  {
    es: 'Confirmar contraseña',
    en: 'Confirm password'
  },
  {
    es: '¿Olvidó su contraseña?',
    en: 'Forgot your Password?'
  },
  {
    es: 'Por favor ingresa un correo válido',
    en: 'Please input a valid email'
  },
  {
    es: 'Requerida',
    en: 'Required'
  },
  {
    es: 'Requerido',
    en: 'Required'
  },
  {
    es: 'requerida',
    en: 'required'
  },
  {
    es: 'requerido',
    en: 'required'
  },
  {
    es: 'invalido',
    en: 'invalid'
  },
  {
    es: 'La contraseña es',
    en: 'Password'
  },
  {
    es: 'Registro',
    en: 'Sign Up'
  },
  {
    es: 'Regístrate en SuperBox',
    en: 'Sign up at SuperBox'
  },
  {
    es: 'Tipo de cédula',
    en: 'Type of ID'
  },
  {
    es: 'Física',
    en: 'Individual'
  },
  {
    es: 'Jurídica',
    en: 'Legal'
  },
  {
    es: 'Alianza',
    en: 'Alliance'
  },
  {
    es: 'Seleccione Alianza',
    en: 'Select alliance'
  },
  {
    es: 'Nombre',
    en: 'Name'
  },
  {
    es: 'El nombre es',
    en: 'The name is'
  },
  {
    es: 'Primer Apellido',
    en: 'Surname'
  },
  {
    es: 'El primer apellido es',
    en: 'Surname is'
  },
  {
    es: 'Segundo Apellido',
    en: 'Maternal surname'
  },
  {
    es: 'El segundo apellido es',
    en: 'Maternal surname is'
  },
  {
    es: 'Razón Social',
    en: 'Registered name'
  },
  {
    es: 'La razón social es',
    en: ''
  },
  {
    es: 'La razón social es',
    en: 'The registered name is'
  },
  {
    es: 'Cédula Jurídica',
    en: 'Legal ID Number'
  },
  {
    es: 'La cédula jurídica es',
    en: 'The legal ID number is'
  },
  {
    es: 'Representante Legal',
    en: 'Legal Representative'
  },
  {
    es: 'Número de Cédula',
    en: 'ID Number'
  },
  {
    es: 'El Número de Cédula debe ser mínimo 9 dígitos sin espacios o guiones.',
    en: 'The ID Number must be at least 9 characters without spaces or dashes'
  },
  {
    es: 'El Número de Cédula debe ser máximo 12 dígitos sin espacios o guiones.',
    en: 'The ID Number must be maximum 12 characters without spaces or dashes'
  },
  {
    es: 'Fecha de nacimiento',
    en: 'Birth date'
  },
  {
    es: 'No se puede afiliar un menor de edad. Comuniquese con Servicio al Cliente al (506) 2253-5400 o por WhatsApp (506) 6037-6729 para aperturar su cuenta.',
    en: 'An underaged person cannot be an affiliate. Please contact Customer Service at (506) 2253-5400 o through WhatsApp a (506) 6037-6729 to open an account.'
  },
  {
    es: 'Celular',
    en: 'Cellphone number'
  },
  {
    es: 'Puede crear su número de cuenta personalizado para ello puede utilizar desde 4 hasta 8 caracteres Alfanuméricos. Símbolos o caracteres especiales no están disponibles.',
    en: 'You can create a custom account number. For this, you can use from 4 to 8 alphanumeric characters. Special characters and symbols not available.'
  },
  {
    es: 'Formato de cuenta Inválida (4-12 caracteres permitidos)',
    en: 'Invalid account format (4-12 characters permitted)'
  },
  {
    es: 'Correo',
    en: 'Email'
  },
  {
    es: 'Para validar su cuenta es necesario que use la dirección de correo proporcionado por su empresa.',
    en: 'It\'s necessary that you use your corporate email address in order to validate your account'
  },
  {
    es: 'Ingrese su correo electrónico',
    en: 'Type in your email address'
  },
  {
    es: 'Cree su número de cuenta',
    en: 'Create your account number'
  },
  {
    es: 'Por favor ingrese un correo valido',
    en: 'Please use a valid email address'
  },
  {
    es: 'Modo de Entrega',
    en: 'Form of Delivery'
  },
  {
    es: 'Sucursal',
    en: 'Location'
  },
  {
    es: 'Dirección de entrega (Waze o Google Maps)',
    en: 'Delivery address (Waze or Google Maps)'
  },
  {
    es: 'Dirección de entrega',
    en: 'Delivery address'
  },
  {
    es: 'Detalles Adicionales',
    en: 'Additional Details'
  },
  {
    es: 'Provincia',
    en: 'Province'
  },
  {
    es: 'Cantón',
    en: 'Canton'
  },
  {
    es: 'Distrito',
    en: 'District'
  },
  {
    es: 'Entrega a Domicilio GRATIS',
    en: 'FREE Shipping'
  },
  {
    es: 'Contacte a Servicio al Cliente para verificar la zona y costo de entrega (Se le aplica tarifa de encomienda)',
    en: 'Contact Customer Service to define the area and cost of shipping (Delivery fees will be applied)'
  },
  {
    es: 'Entrega a domicilio con costo adicional',
    en: 'Shipping with additional costs'
  },
  {
    es: '¿Has comprado antes por internet?',
    en: 'Have you made online purchases before?'
  },
  {
    es: '¿Cómo se enteró de nosotros?',
    en: 'How did you find out about us?'
  },
  {
    es: 'Si la respuesta es otros, favor especificar:',
    en: 'If the response was "Other", please specify:'
  },
  {
    es: 'Método de Pago Predeterminado',
    en: 'Default Payment Method'
  },
  {
    es: 'Método de Pago es',
    en: 'Payment Method is'
  },
  {
    es: 'Esta opción nos permite mejorar la experiencia a la hora de coordinar sus paquetes. Puede modificar este ajuste desde su perfil de usuario.',
    en: 'This option lets us improve the delivery of your packages. You can modify this setting from your user profile.'
  },
  {
    es: 'Introduzca, si posee, el código de quien lo refirió a nuestros servicios',
    en: 'If you have one, type in the referral code given to you by whoever introduced you to our services'
  },
  {
    es: 'Código de Referido',
    en: 'Referral Code'
  },
  {
    es: 'Redes Sociales',
    en: 'Social Media'
  },
  {
    es: 'Recomendación',
    en: 'Recommendation'
  },
  {
    es: 'Alianza o Convenio',
    en: 'Alliance or Agreement'
  },
  {
    es: 'Evento',
    en: 'Event'
  },
  {
    es: 'Publicidad exterior (Rótulos, banners, etc.)',
    en: 'Exterior advertising (Signs, banners, etc.)'
  },
  {
    es: 'Publicidad impresa',
    en: 'Printed advertising'
  },
  {
    es: 'Otros',
    en: 'Other'
  },
  {
    es: 'No, es mi primera vez.',
    en: 'No, it is my first time.'
  },
  {
    es: 'Si, he comprado antes por internet.',
    en: 'Yes, I have made online purchases before.'
  },
  {
    es: 'Género',
    en: 'Gender'
  },
  {
    es: 'Masculino',
    en: 'Male'
  },
  {
    es: 'Femenino',
    en: 'Female'
  },
  {
    es: 'Otro',
    en: 'Other'
  },
  {
    es: 'Código existe',
    en: 'Code already exists.'
  },
  {
    es: 'Las contraseñas no coinciden.',
    en: 'Passwords don\'t match.'
  },
  {
    es: 'Las contraseñas no',
    en: 'Passwords don\'t'
  },
  {
    es: 'coinciden.',
    en: 'match.'
  },
  {
    es: 'Este email ya existe.',
    en: 'Email already exists.'
  },
  {
    es: 'Esta cuenta ya existe.',
    en: 'Account already exists.'
  },
  {
    es: 'Por favor ingresa un correo válido',
    en: 'Enter the email address associated to your account'
  },
  {
    es: 'En sucursal',
    en: 'On location'
  },
  {
    es: 'En domicilio',
    en: 'At home'
  },
  {
    es: 'Sucursal es',
    en: 'Location is'
  },
  {
    es: 'CREAR',
    en: 'CREATE'
  },
  {
    es: 'El Correo es',
    en: 'Email is'
  },
  {
    es: 'El Número de Cédula es',
    en: 'ID Number'
  },
  {
    es: 'El Celular es',
    en: 'Cellphone number is'
  },
  {
    es: 'La Cuenta es',
    en: 'Account Number is'
  },
  {
    es: '¿Cómo se enteró de nosotros? es',
    en: 'How did you find out about us? is'
  },
  {
    es: 'Cerrar',
    en: 'Close'
  },
  {
    es: 'Cerrar Sesión',
    en: 'Logout'
  },
  {
    es: 'Código existe',
    en: 'Code already exists.'
  },
  {
    es: 'Las contraseñas no coinciden.',
    en: 'Passwords don\'t match.'
  },
  {
    es: 'Este email ya existe.',
    en: 'Email already exists.'
  },
  {
    es: 'Esta cuenta ya existe.',
    en: 'Account already exists.'
  },
  {
    es: 'Por favor ingresa un correo válido',
    en: 'Please enter a valid email address'
  },
  {
    es: 'Introduzca el correo asociado a su cuenta',
    en: 'Enter the email address associated to your account'
  },
  {
    es: 'Solicitud de contraseña realizada',
    en: 'Password request complete'
  },
  {
    es: 'Realice la verificación de su email',
    en: 'Verify your email'
  },
  {
    es: 'Las credenciales son inválidas',
    en: 'Invalid credentials'
  },
  {
    es: 'Revise su correo electrónico para activar su casillero. Una vez su cuenta activada podrá comprar con SuperBox desde Estados Unidos, Europa y Asia.',
    en: 'Check your email inbox to activate your locker. Once active, you will be able to place orders with SuperBox from the USA, Europe and Asia.'
  },
  {
    es: 'Esta cuenta ya ha sido activada',
    en: 'This account has already been activated.'
  },
  {
    es: 'Bienvenid@ a la experiencia de comprar por internet, SuperBox',
    en: 'Welcome to SuperBox, the online buying experience'
  },
  {
    es: 'Su cuenta ha sido activada!',
    en: 'Your account has been activated!'
  },
  {
    es: 'Acceder a su Cuenta',
    en: 'Log into your account'
  },
  {
    es: 'Ingrese el código de 6 dígitos generado en su aplicación de autentificación 2FA (Ej. Google Authenticator)',
    en: 'Enter the 6-digit code generated by your 2FA app (e.g. Google Authenticator)'
  },
  {
    es: 'Error: Código Inválido',
    en: 'Error: code invalid'
  },
  {
    es: 'Calculadora',
    en: 'Calculator'
  },
  {
    es: 'Mis paquetes',
    en: 'My packages'
  },
  {
    es: 'Mis Facturas',
    en: 'My Receipts'
  },
  {
    es: 'Mi Perfil',
    en: 'My Profile'
  },
  {
    es: 'Recomienda a un amigo',
    en: 'Recommend a friend'
  },
  {
    es: 'Prealertar',
    en: 'Prealert'
  },
  {
    es: 'Tutoriales',
    en: 'Tutorials'
  },
  {
    es: 'Bienvenido a SuperBox',
    en: 'Welcome to SuperBox'
  },
  {
    es: 'Copyright 2023 @ SuperBox. Todos los derechos reservados.',
    en: 'Copyright 2023 @ Superbox. All rights reserved.'
  },
  {
    es: 'Consolidar paquetes',
    en: 'Consolidate packages.'
  },
  {
    es: 'No hay paquetes correspondientes a su búsqueda',
    en: 'No packages correspond to your entered search terms.'
  },
  {
    es: 'Búsqueda',
    en: 'Search'
  },
  {
    es: 'Información General',
    en: 'General informaiton'
  },
  {
    es: 'Puedes consolidar un máximo de 10 paquetes',
    en: 'You can consolidate a maximum of 10 packages'
  },
  {
    es: 'siempre y cuando se encuentren recibidos en Miami y tengan factura en el sistema.',
    en: 'so long as they show as Received in Miami and have a receipt in the system.'
  },
  {
    es: 'Recuerda que el tiempo para consolidar',
    en: 'Remember that the time frame to consolidate'
  },
  {
    es: 'es de 15 días después de la llegada del paquete a Miami.',
    en: 'is of 15 days after the arrival of your package to Miami'
  },
  {
    es: 'El servicio se implementó debido',
    en: 'The service was implemented because'
  },
  {
    es: 'a que a veces compramos más de un artículo al mismo tiempo y queremos que todo nos llegue junto.',
    en: 'sometimes we buy more than one article and would like them to get to us at the same time.'
  },
  {
    es: 'Consolidado Aéreo:',
    en: 'Air Consolidation:'
  },
  {
    es: 'Consolidado Marítimo:',
    en: 'Sea Consolidation:'
  },
  {
    es: 'No requiere mínimo de peso en los paquetes a consolidar.',
    en: 'No weight limit for consolidation.'
  },
  {
    es: 'Requiere un mínimo de 5 kilos, entre todos los paquetes a consolidar.',
    en: 'A minimum of 5kg among all the packages to consolidate.'
  },
  {
    es: 'Reglas de un Consolidado',
    en: 'Consolidation Rules'
  },
  {
    es: 'Tenés derecho a un máximo de 10 paquetes en un periodo de 15 días.',
    en: 'You are allowed a maximum of 10 packages in a period of 15 days.'
  },
  {
    es: 'Este tiempo inicia a partir del ingreso del primer paquete a bodega Miami.',
    en: 'The time starts running when the first package reaches the Miami fulfillment center.'
  },
  {
    es: 'No se puede consolidar un solo paquete.',
    en: 'A single package cannot be consolidated.'
  },
  {
    es: 'El mínimo son 2 paquetes.',
    en: 'Minimum 2 packages.'
  },
  {
    es: 'La acción se debe realizar UNA SOLA vez',
    en: 'The action should be taken ONE SINGLE time'
  },
  {
    es: 'cuando todos los paquetes que estaba esperando hayan llegado a Miami. Si le hace falta uno o más paquetes y crea un consolidado, el consolidado se hará con los paquetes instruidos. Si esto le sucede, contactarnos inmediatamente al 2253-5400 para revertir la instrucción.',
    en: 'when all expected packages have arrived in Miami. If you\'re missing one or more packages and create a consolidation request, the consolidation will be executed with the indicated packages. If this happens, immediately contact us at 2253-5400 to revert the request.'
  },
  {
    es: 'Cada artículo dentro del consolidado debe tener factura.',
    en: 'Every consolidated article should have a receipt. '
  },
  {
    es: 'La factura válida debe tener: descripción del artículo, monto total desglosado y datos personales como el número de cuenta, su nombre y dirección de Miami.',
    en: 'The valid receipt should include: article description, total description of costs and personal data such as the account number, your name and Miami address.'
  },
  {
    es: 'NO consolidar artículos que requieran permiso debido a que estos productos tardan más de lo normal por el trámite ante del Ministerio y puede que sea rechazado el mismo.',
    en: 'Do NOT consolidate articles that require special permissions since these will take longer due to administrative procedures and the shipment can be rejected.'
  },
  {
    es: 'Hacer un consolidado de 3 paquetes o más para notar el ahorro o diferencia al envío individual.',
    en: 'Consolidate 3 or more packages to have noticeable savings when compared to individual shipments.'
  },
  {
    es: 'Seleccione el Plan deseado para los siguientes paquetes:',
    en: 'Select the desired Plan for the following packages:'
  },
  {
    es: 'Atrás',
    en: 'Back'
  },
  {
    es: 'No. Guía',
    en: 'Guide No.'
  },
  {
    es: 'No. Tracking',
    en: 'Tracking No.'
  },
  {
    es: 'Descripción',
    en: 'Description'
  },
  {
    es: 'Remitente',
    en: 'Sender'
  },
  {
    es: 'Consolidar',
    en: 'Consolidate'
  },
  {
    es: 'Seleccione el método de envío:',
    en: 'Select shipping method:'
  },
  {
    es: 'Vía Marítima',
    en: 'Sea'
  },
  {
    es: 'Vía aérea',
    en: 'Air'
  },
  {
    es: 'Consolidar',
    en: 'Consolidate'
  },
  {
    es: 'Solo se puede consolidar un mínimo de 2 paquetes hasta un máximo de 10. Por favor verifique su selección',
    en: 'You can only consolidate from 2 up to 10 packages. Please verify your selection.'
  },
  {
    es: 'Operación exitosa. Paquetes Consolidados. Número de guía: ',
    en: 'Succesful operation. Packages Consolidated. Guide No.: '
  },
  {
    es: 'Prealerta',
    en: 'Prealert'
  },
  {
    es: 'Prealertar',
    en: 'Prealert'
  },
  {
    es: 'En Miami',
    en: 'In Miami'
  },
  {
    es: 'En tránsito hacia Costa Rica',
    en: 'On its way to Costa Rica'
  },
  {
    es: 'En Centro de Distribución',
    en: 'In Distribution Center'
  },
  {
    es: 'En Sucursal',
    en: 'On Location'
  },
  {
    es: 'En transito',
    en: 'In transit'
  },
  {
    es: 'Detalles de Paquetes',
    en: 'Package details'
  },
  {
    es: 'Devuelto RMA',
    en: 'RMA Returned'
  },
  {
    es: 'Pagar Paquetes',
    en: 'Pay for Packages'
  },
  {
    es: 'Consolidar Paquetes',
    en: 'Consolidate packages'
  },
  {
    es: 'No hay paquetes disponibles',
    en: 'No packages available'
  },
  {
    es: 'Seleccionar',
    en: 'Select'
  },
  {
    es: 'Tipo Paquete',
    en: 'Package Type'
  },
  {
    es: 'Peso (lbs)',
    en: 'Weight (lbs)'
  },
  {
    es: 'Método',
    en: 'Method'
  },
  {
    es: 'Estado Actual',
    en: 'Current Status'
  },
  {
    es: 'Última Actualización',
    en: 'Last Update'
  },
  {
    es: 'No tiene paquetes con facturas pendientes.',
    en: 'You have no packages with pending bills.'
  },
  {
    es: 'Estado',
    en: 'Status'
  },
  {
    es: 'Dólares',
    en: 'Dollars'
  },
  {
    es: 'Colones',
    en: 'Colones'
  },
  {
    es: 'Pendiente',
    en: 'Pending'
  },
  {
    es: 'Resumen',
    en: 'Summary'
  },
  {
    es: 'Electronica',
    en: 'Electronics'
  },
  {
    es: 'Podrás cancelar el paquete cuando haya pasado Aduanas en Costa Rica',
    en: 'You can pay for the package once it has gone through Costa Rican customs'
  },
  {
    es: 'El paquete solo se puede consolidar',
    en: 'Package can only be consolidated'
  },
  {
    es: 'El paquete no tiene factura',
    en: 'Package has no receipt'
  },
  {
    es: 'La factura ya está pagada',
    en: 'Bill has been paid'
  },
  {
    es: 'Datos de la Tarjeta',
    en: 'Card details'
  },
  {
    es: 'Seleccione una tarjeta',
    en: 'Select a card'
  },
  {
    es: 'No. de tarjeta',
    en: 'Card number'
  },
  {
    es: 'Ninguna',
    en: 'None'
  },
  {
    es: 'Cargo Encomienda',
    en: 'Parcel'
  },
  {
    es: 'Pagar',
    en: 'Pay'
  },
  {
    es: 'Pago Tarjeta de crédito',
    en: 'Credit Card Payment'
  },
  {
    es: 'Aceptar',
    en: 'Accept'
  },
  {
    es: 'Resultado de la Transacción',
    en: 'Transaction results'
  },
  {
    es: 'Transacción Exitosa',
    en: 'Transaction succesful'
  },
  {
    es: 'Hubo un error al procesar su pago',
    en: 'Error when processing payment'
  },
  {
    es: 'Prealertado',
    en: 'Prealerted'
  },
  {
    es: 'Recibido en Miami',
    en: 'Received in Miami'
  },
  {
    es: 'Viajando a Costa Rica',
    en: 'On its way to Costa Rica'
  },
  {
    es: 'Aduana',
    en: 'Customs'
  },
  {
    es: 'Recibido en Bodega CR',
    en: 'Received in CR Warehouse'
  },
  {
    es: 'Entregado',
    en: 'Delivered'
  },
  {
    es: 'Información del Paquete',
    en: 'Package info'
  },
  {
    es: 'Destinatario',
    en: 'Recipient'
  },
  {
    es: 'Remitente',
    en: 'Sender'
  },
  {
    es: 'Precio',
    en: 'Price'
  },
  {
    es: 'Peso Cobrable',
    en: 'Billable weight'
  },
  {
    es: 'Método de Envío',
    en: 'Shipment Method'
  },
  {
    es: 'Facturas',
    en: 'Receipts'
  },
  {
    es: 'Guía Consolidada',
    en: 'Consolidated Guide'
  },
  {
    es: 'Detalles',
    en: 'Details'
  },
  {
    es: 'Información de Facturación',
    en: 'Billing info'
  },
  {
    es: 'Este Paquete aún no posee factura.',
    en: 'This package does not yet have a receipt'
  },
  {
    es: 'Rubro',
    en: 'Category'
  },
  {
    es: 'Monto (Colones)',
    en: 'Amount (Colones)'
  },
  {
    es: 'Monto (USD)',
    en: 'Amount (USD)'
  },
  {
    es: 'Flete',
    en: 'Shipment cost'
  },
  {
    es: 'Servicio',
    en: 'Service'
  },
  {
    es: 'Encomienda',
    en: 'Parcel'
  },
  {
    es: 'Impuestos',
    en: 'Taxes'
  },
  {
    es: 'Iva',
    en: 'VAT'
  },
  {
    es: 'Descuento',
    en: 'Discount'
  },
  {
    es: 'Descuento Promocional',
    en: 'Promo Discount'
  },
  {
    es: 'Pendiente',
    en: 'Pending'
  },
  {
    es: 'Nota de Débito',
    en: 'Debit note'
  },
  {
    es: 'Nota de Crédito',
    en: 'Credit note'
  },
  {
    es: 'Pagos Realizados',
    en: 'Payments made'
  },
  {
    es: 'Este paquete no tiene pagos realizados.',
    en: 'There are no payments made for this package.'
  },
  {
    es: 'Recordá enviar el comprobante de pago por WhatsApp +(506) 6037-6729 para completar la coordinación. Si ya adjunto su comprobante el mismo se vera reflejado luego de validar su pago.',
    en: 'Remember to send proof of payment through WhatsApp at +(506) 6037-6729 in order to complete the transaction. If you have already attached proof, this will show after you\'ve validated your payment.'
  },
  {
    es: 'Aplicado por',
    en: 'Applied by'
  },
  {
    es: 'Pagar Nota de Débito',
    en: 'Pay Debit Note'
  },
  {
    es: 'Datos de Entrega',
    en: 'Delivery Details'
  },
  {
    es: 'Ha programado su entrega para el día',
    en: 'You have scheduled delivery for:'
  },
  {
    es: 'Dirección de Entrega:',
    en: 'Delivery address:'
  },
  {
    es: 'Este paquete aún no se ha asignado a un conductor',
    en: 'This package hasn\'t had a driver assigned to it yet.'
  },
  {
    es: 'Si desea cambiar los datos de entrega de este paquete favor comuniquese con Servicio al cliente mediante WhatsApp +(506) 6037-6729.',
    en: 'If you wish to change your delivery details, please communicate with Customer Service through WhatsApp at +(506) 6037-6729'
  },
  {
    es: 'Entrega Asignada:',
    en: 'Assigned Delivery:'
  },
  {
    es: 'Paquete en tránsito',
    en: 'Package in transit'
  },
  {
    es: 'Dirección de Entrega:',
    en: 'Delivery Address:'
  },
  {
    es: 'Entrega Programada',
    en: 'Delivery scheduled.'
  },
  {
    es: 'Error al programar entrega',
    en: 'Error when scheduling delivery'
  },
  {
    es: 'Paquetes que ingresan en bolsa/sobre cuentan con tarifa Premium de ¢5.000 a ¢25.000 colones según su peso.',
    en: 'Packages coming in a bag or a slip have a Premium fee of ¢5.000 to ¢25.000 according to their weight.'
  },
  {
    es: 'Tipo de Producto',
    en: 'Product Type'
  },
  {
    es: 'Precio del producto',
    en: 'Product Price'
  },
  {
    es: 'Su resultado',
    en: 'Your result'
  },
  {
    es: 'El peso mínimo para envíos vía marítima es de 25 kilos o 55 libras.',
    en: 'The minimum weight for deliveries by sea is 25kg (55lbs)'
  },
  {
    es: 'Este cálculo está basado en un valor de $',
    en: 'This calculation is based on an article with a value of $'
  },
  {
    es: ', un peso de ',
    en: ', a weight of '
  },
  {
    es: ' y un arancel de ',
    en: ' and a Tax of '
  },
  {
    es: ', dimensiones de ',
    en: ', dimensions of '
  },
  {
    es: 'pulg. de alto,',
    en: 'in. High,'
  },
  {
    es: 'pulg. de ancho',
    en: 'in. Wide'
  },
  {
    es: 'pulg. de largo',
    en: 'in. long'
  },
  {
    es: 'Peso en Lbs',
    en: 'Weight in lbs'
  },
  {
    es: 'Peso en Kgs',
    en: 'Weight in kg'
  },
  {
    es: 'Calcular',
    en: 'Calculate'
  },
  {
    es: 'Alto',
    en: 'High'
  },
  {
    es: 'Ancho',
    en: 'Wide '
  },
  {
    es: 'Largo',
    en: 'Long'
  },
  {
    es: 'Seleccionar Producto',
    en: 'Select Product'
  },
  {
    es: 'Pulgadas',
    en: 'Inches'
  },
  {
    es: 'Exportar Cálculo',
    en: 'Export Results'
  },
  {
    es: 'Nuevo Cálculo',
    en: 'New Calculation'
  },
  {
    es: 'Artículos como comida y bebidas, maquillaje, medicamentos, perfumes, cremas, cuchillos, armas, entre otros requieren de un proceso adicional. Consulte antes de importar.',
    en: 'Items such as food and drinks, make-up, medicine, perfmuse, creams, knives, weapons and others will require additional procedures. Please consult before importing.'
  },
  {
    es: 'Obtenga tarifa premium si su paquete ingresa a bodega Miami en embalaje bolsa/sobre',
    en: 'Get a premium fee if your package enters the Miami warehouse in a bag/slip.'
  },
  {
    es: 'Nuevo Calculo',
    en: 'New Result'
  },
  {
    es: 'Exportar Calculo',
    en: 'Export Result'
  },
  {
    es: 'Formulario de Prealerta',
    en: 'Prealert Form'
  },
  {
    es: 'El formulario de pre alerta sirve para incluir tu paquete apenas llegue a Miami ya que tenemos toda la información necesaria',
    en: 'The Prealert Form works to include your package as soon as it arrives in Miami, since it includes all the necessary information.'
  },
  {
    es: 'Descripción del Producto',
    en: 'Product Description'
  },
  {
    es: '1ra. Imagen de Factura',
    en: '1st Receipt Picture'
  },
  {
    es: '2da. Imagen de Factura',
    en: '2nd Receipt Picture'
  },
  {
    es: '3ra. Imagen de Factura',
    en: '3rd Receipt Picture'
  },
  {
    es: 'Para adjuntar su factura puede usar los siguiente tipos de archivos PNG, JPG, WORD, PDF y el archivo debe ser menor a 20MB',
    en: 'You must attach your file using either .PNG, .JPG, .DOCX, or .PDF, and it cannot exceed 20MB in size.'
  },
  {
    es: 'Una factura válida debe contener descripción del articulo y su monto. Si la tienda le despachó el pedido en varias ordenes/paquetes, coloque unicamente la porción correspondiente ya que de lo contrario cancelará doble impuesto.',
    en: 'A valid receipt must contain an item description and its cost. If the store shipped your item in multiple orders/packages, show only the corresponding portion to avoid being double-taxed on your product.'
  },
  {
    es: 'Ver Productos con Permiso',
    en: 'See Permitted Products.'
  },
  {
    es: 'Todavía no ha recomendado a ningún amigo. Comience enviando su código a un amigo.',
    en: 'You have not recommended us to any of your friends yet. Start by sending your friend a code.'
  },
  {
    es: '¡Recomienda A Un Amigo y Gana!',
    en: 'Recommend to a friend and win!'
  },
  {
    es: 'Por cada amigo o familiar que recomiendes, te regalamos $10 en tu siguiente paquete.',
    en: 'For each friend or relative you recommend, we will give you $10 on your next package.'
  },
  {
    es: 'La regalía se hará efectiva cuando tu amigo o familiar se haya registrado con tu código y cancelado exitosamente su primer paquete. Una vez que esto suceda, los $10 se te aplicarán automáticamente en el próximo paquete sobre el total de la factura.',
    en: 'The giveaway will take place when your friends or relatives have signed up with the code and have succesfully paid for their first package. Once this happens, the $10 bonus will be automatically applied to the total receipt on your next order.'
  },
  {
    es: 'Activas',
    en: 'Active'
  },
  {
    es: 'Enviadas',
    en: 'Sent'
  },
  {
    es: 'Enviadas: Tu amigo o familiar se registró exitosamente con tu código',
    en: 'Sent: Your friend or relative has succesfully signed up with your code.'
  },
  {
    es: 'Activas: ¡Estarás recibiendo $10 en tu próximo paquete!.',
    en: 'Active: You will receive a $10 bonus on your next package!'
  },
  {
    es: 'Tu amigo o familiar canceló su primer paquete exitosamente.',
    en: 'Your friend or relative has succesfully paid for their first package.'
  },
  {
    es: 'Tu código:',
    en: 'Your code:'
  },
  {
    es: 'Copiar Código',
    en: 'Copy Code'
  },
  {
    es: 'Código copiado',
    en: 'Copied'
  },
  {
    es: 'Solo se puede consolidar un mínimo de 2 paquetes hasta un máximo de 10. Por favor verifique su selección',
    en: 'You can only consolidate a minimum of 2 packages up to a maximum of 10. Please check your selection'
  },
  {
    es: 'Solo se puede seleccionar paquete en Miami con Status de Guia Completada',
    en: 'You can only select a package in Miami with Completed Guide Status'
  },
  {
    es: 'Debés adjuntar la factura para poder consolidar este paquete. Guia:',
    en: 'You must attach the invoice in order to consolidate this package. Guide:'
  },
  {
    es: 'El paquete ',
    en: 'Package '
  },
  {
    es: ' se encuentra incluido en un embarque. Contacte a Servicio al Cliente para más información.',
    en: ' is included in a shipment. Contact Customer Service for more information.'
  },
  {
    es: 'No ha seleccionado paquetes para pagar',
    en: 'You have not selected packages to pay'
  },
  {
    es: 'Ha seleccionado paquetes que solo se pueden consolidar',
    en: 'You have selected packages that can only be consolidated'
  },
  {
    es: 'Ha seleccionado paquetes sin factura',
    en: 'You have selected packages without invoice'
  },
  {
    es: 'La factura ya está pagada',
    en: 'The bill is already paid'
  },
  {
    es: 'Esta operación es definitiva y no puede reversarse. ¿Confirma que desea eliminar este paquete?.',
    en: 'This operation is final and cannot be reversed. Are you sure you want to remove this package?'
  },
  {
    es: 'Anterior',
    en: 'Previous'
  },
  {
    es: 'Siguiente',
    en: 'Next'
  },
  {
    es: 'Flete Internacional',
    en: 'International freight'
  },
  {
    es: 'Servicio SuperBox',
    en: 'Servicio SuperBox'
  },
  {
    es: 'Factura Pagada',
    en: 'Invoice paid'
  },
  {
    es: 'Factura Pendiente',
    en: 'Pending Invoice'
  },
  {
    es: 'Pagada',
    en: 'Paid'
  },
  {
    es: 'Código Promocional',
    en: 'Promotional code'
  },
  {
    es: 'No. de Tracking',
    en: 'Tracking Num.'
  },
  {
    es: 'Campo Requerido',
    en: 'Required Field'
  },
  {
    es: 'Enviar',
    en: 'Send'
  },
  {
    es: 'Buscar',
    en: 'Search'
  },
  {
    es: 'Paquete Actualizado. Número de guía: ',
    en: 'Package Updated . Guide number:'
  },
  {
    es: 'Prealerta realizada exitosamente. Número de guía: ',
    en: 'Pre-alert successful. Guide number:'
  },
  {
    es: 'Ha ocurrido un error, por favor intente nuevamente',
    en: 'An error has occurred, please try again'
  },
  {
    es: 'Formato de archivo inválido',
    en: 'Invalid file format'
  },
  {
    es: 'Archivo supera los 20 megabytes',
    en: 'File exceeds 20 megabytes'
  },
  {
    es: '¿Confirma que desea eliminar esta factura?',
    en: ''
  },
  {
    es: '¿Confirma que desea eliminar esta factura?',
    en: 'Are you sure you want to delete this invoice?'
  },
  {
    es: 'Factura Eliminada',
    en: 'Invoice Deleted'
  },
  {
    es: 'Paquete se encuentra en transito. Para cambios en la información debe comunicarse con Servicio al Cliente',
    en: 'Package is in transit. For changes in the information you must contact Customer Service'
  },
  {
    es: 'Paquete asignado a un embarque. Para cambios en el método de envío debe comunicarse con Servicio al Cliente',
    en: 'Package assigned to a shipment. For changes in the shipping method you must contact Customer Service'
  },
  {
    es: 'Estadísticas de Recomendaciones',
    en: 'Recommendation Statistics'
  },
  {
    es: 'Recomendar',
    en: 'Recommend'
  },
  {
    es: 'Paquetes',
    en: 'Packages'
  },
  {
    es: 'Sin Prealerta',
    en: 'No Prealert'
  },
  {
    es: 'No. de Guía',
    en: 'Guide Num.'
  },
  {
    es: 'Método de pago',
    en: 'Payment Method'
  },
  {
    es: 'Fecha',
    en: 'Date'
  },
  {
    es: 'Monto',
    en: 'Amount'
  },
  {
    es: 'Referencia',
    en: 'Reference'
  },
  {
    es: 'Adjuntar Comprobante de Pago',
    en: 'Attach Proof of Payment'
  },
  {
    es: 'Número de Comprobante',
    en: 'Voucher number'
  },
  {
    es: 'Para adjuntar su comprobante puede usar los siguiente tipos de archivos PNG, JPG, WORD, PDF y el archivo debe ser menor a 20MB.',
    en: 'To attach your receipt you can use the following types of files PNG, JPG, WORD, PDF and the file must be less than 20MB.'
  },
  {
    es: 'Entrega Gratuita Disponible',
    en: 'Free Delivery Available'
  },
  {
    es: 'Comuníquese con atención al cliente para entregas a esta zona',
    en: 'Contact customer service for deliveries to this area'
  },
  {
    es: 'Se aplicará una tarifa adicional por encomienda de',
    en: 'An additional fee will apply for shipping of'
  },
  {
    es: 'Una tarifa adicional por encomienda aplica para este envío.',
    en: 'An additional fee applies for this shipment.'
  },
  {
    es: 'Comuníquese con Atención al cliente para cotizar su envío.',
    en: 'Please contact Customer Service for a shipping quote.'
  },
  {
    es: 'Instrucciones de entrega',
    en: 'Delivery instructions'
  },
  {
    es: 'Por favor enviar su comprobante de pago al WhatsApp +506 6037-6729',
    en: 'Please send your proof of payment to WhatsApp +506 6037-6729'
  },
  {
    es: 'Pagos Realizados',
    en: 'Payments made'
  },
  {
    es: 'Pagar Paquete',
    en: 'Pay Package'
  },
  {
    es: 'Total a Pagar',
    en: 'Total to pay'
  },
  {
    es: 'Cancelar',
    en: 'Cancel'
  },
  {
    es: 'Fecha de Entrega',
    en: 'Delivery Date'
  },
  {
    es: 'Debe ingresar todos los datos de la dirección',
    en: 'You must enter all the address data'
  },
  {
    es: '¡Estamos casi listos! Su comprobante de pago sera revisado para completar la coordinación.',
    en: 'We are almost ready! Your proof of payment will be reviewed to complete the coordination'
  },
  {
    es: 'Error al programar entreg',
    en: 'Error scheduling delivery'
  },
  {
    es: 'Paquete Programado',
    en: 'Scheduled Package'
  },
  {
    es: 'Gracias, su paquete fue coordinado para ser cancelado con tarjeta de crédito/débito',
    en: 'Thank you, your package was coordinated to be paid with a credit/debit card'
  },
  {
    es: 'No hay items seleccionados',
    en: 'No items selected'
  },
  {
    es: 'Comprobante de Pago Requerido. Adjunte su comprobante e intente nuevamente.',
    en: 'Proof of Payment Required. Please attach your receipt and try again.'
  },
  {
    es: 'ALERTA',
    en: 'ALERT'
  },
  {
    es: 'Confirmar',
    en: 'Confirm'
  },
  {
    es: 'Seleccione una imagen',
    en: 'Select a picture'
  },
  {
    es: 'Cuenta',
    en: 'Account'
  },
  {
    es: 'Perfil',
    en: 'Profile'
  },
  {
    es: 'Guardar Cambios',
    en: 'Save Changes'
  },
  {
    es: 'Editar',
    en: 'Edit'
  },
  {
    es: 'Nombre Completo',
    en: 'Full Name'
  },
  {
    es: 'Apellido 1',
    en: 'Last Name'
  },
  {
    es: 'Apellido 2',
    en: 'Maternal Surname'
  },
  {
    es: 'Correo Electrónico',
    en: 'Email'
  },
  {
    es: 'Teléfono Casa',
    en: 'Home Number'
  },
  {
    es: 'Teléfono Oficina',
    en: 'Office Number'
  },
  {
    es: 'Seguridad',
    en: 'Security'
  },
  {
    es: 'Autenticación de 2 factores',
    en: '2 factor authentication'
  },
  {
    es: 'Refuerce la seguridad de su cuenta con los siguientes pasos.',
    en: 'Strengthen the security of your account with the following steps.'
  },
  {
    es: 'Su cuenta tiene la primera capa de seguridad por medio de su usuario y contraseña. Este es un segundo paso, opcional, para una doble protección cada vez que desee acceder a su perfil.',
    en: 'Your account has the first layer of security through your username and password. This is a second, optional step for double protection every time you want to access your profile.'
  },
  {
    es: 'Habilitar Autenticación de dos factores',
    en: 'Enable Two Factor Authentication'
  },
  {
    es: 'Activo',
    en: 'Enable'
  },
  {
    es: 'Inactivo',
    en: 'Disable'
  },
  {
    es: 'Eliminar Autenticación de dos factores',
    en: 'Remove Two Factor Authentication'
  },
  {
    es: 'Active la autentificación',
    en: 'Activate authentication'
  },
  {
    es: 'Descargue el App',
    en: 'Download the App'
  },
  {
    es: 'Escanee el código QR desde el App',
    en: 'Scan the QR code from the App'
  },
  {
    es: 'Configurada',
    en: 'Configured'
  },
  {
    es: 'No configurada',
    en: 'Not configured'
  },
  {
    es: 'Si necesita ayuda comuníquese con Servicio al Cliente: hola@superboxcr.com, Tel. 2253-5400, WhatsApp 6037-6729',
    en: 'If you need help, contact Customer Service: hola@superboxcr.com, Tel. 2253-5400, WhatsApp 6037-6729'
  },
  {
    es: 'Entrega',
    en: 'Shipping'
  },
  {
    es: 'Ruta de Entrega',
    en: 'Delivery Route'
  },
  {
    es: 'Entrega con Costo Adicional',
    en: 'Delivery with additional cost'
  },
  {
    es: 'Autorizaciones',
    en: 'Authorizations'
  },
  {
    es: 'Cédula Identidad',
    en: 'ID'
  },
  {
    es: 'Teléfono',
    en: 'Phone Number'
  },
  {
    es: 'Tipo de Autorización',
    en: 'Authorization Type'
  },
  {
    es: 'Opciones',
    en: 'Options'
  },
  {
    es: 'Aún no hay autorizaciones creadas.',
    en: 'There are no authorizations created yet.'
  },
  {
    es: 'Agregar',
    en: 'Add'
  },
  {
    es: 'Agregar Autorización',
    en: 'Add Authorization'
  },
  {
    es: 'Cédula de Identidad',
    en: 'Id Number'
  },
  {
    es: 'Guardar',
    en: 'Save'
  },
  {
    es: 'El campo es',
    en: 'The field is'
  },
  {
    es: 'Editar Autorización',
    en: 'Edit Authorization'
  },
  {
    es: 'Persona autorizada en retirar, cancelar, ver estados de cuenta y realizar consultas personales de la cuenta. Tiene el mismo poder que el titular de la cuenta',
    en: 'Person authorized to withdraw, cancel, view account statements and make personal inquiries about the account. Has the same power as the account holder'
  },
  {
    es: 'Persona autorizada en retirar y cancelar los paquetes de la cuenta únicamente',
    en: 'Person authorized to withdraw and cancel the packages of the account only'
  },
  {
    es: 'Persona autorizada en retirar los paquetes pagos de la cuenta únicamente',
    en: 'Person authorized to withdraw paid packages from the account only'
  },
  {
    es: 'Factura Electrónica',
    en: 'Electronic bill'
  },
  {
    es: 'Tipo de Persona',
    en: 'Type of Person'
  },
  {
    es: 'Cédula',
    en: 'ID'
  },

  {
    es: 'Dirección de Facturación',
    en: 'Billing Address'
  },

  {
    es: 'Nombre de la Tarjeta',
    en: 'Card Name'
  },

  {
    es: 'Tipo de Tarjeta',
    en: 'Card Type'
  },

  {
    es: 'Número de la Tarjeta',
    en: 'Card Number'
  },

  {
    es: 'Tarjetas',
    en: 'Credit Cards'
  },

  {
    es: 'Aún no hay tarjetas Guardadas.',
    en: 'There are no saved cards yet.'
  },


  {
    es: 'Podrá administrar sus tarjetas luego de realizar su primer pago con las mismas.',
    en: 'You will be able to manage your cards after making your first payment with them.'
  },

  {
    es: 'Activa',
    en: 'Enabled'
  },

  {
    es: 'Inactiva',
    en: 'Disabled'
  },
  {
    es: 'Tarjeta para cargo automático',
    en: 'Card for automatic charge'
  },
  {
    es: 'Editar Tarjeta',
    en: 'Edit Card'
  },
  {
    es: 'Estatus de Notificación',
    en: 'Notification Status'
  },
  {
    es: 'Medios de notificación',
    en: 'Notification Channel'
  },
  {
    es: 'Notificar Recibido en Miami',
    en: 'Notify Received in Miami'
  },

  {
    es: 'Notificar En Tránsito Hacia Costa Rica',
    en: 'Notify In Transit To Costa Rica'
  },
  {
    es: 'Notificar Recibido en Centro de Distribución',
    en: 'Notify Received in Fullfiment Center'
  },
  {
    es: 'Notificar Entregado al Cliente',
    en: 'Notify Delivered to Customer'
  },
  {
    es: 'Notificación por Email',
    en: 'Email'
  },
  {
    es: 'Notificación por SMS',
    en: 'SMS'
  },
  {
    es: 'Notificaciones por Sistema',
    en: 'System'
  },
  {
    es: 'Notificaciones',
    en: 'Notifications'
  },
  {
    es: 'Usuario Actualizado',
    en: 'User Updated'
  },

  {
    es: 'Error al actualizar usuario',
    en: 'Failed to update user'
  },
  {
    es: 'Datos de entrega Actualizados',
    en: 'Delivery details Updated'
  },
  {
    es: 'Error al actualizar los datos de entrega',
    en: 'Error updating delivery details'
  },
  {
    es: '¿Desea eliminar este elemento?',
    en: 'Do you want to delete this item?'
  },
  {
    es: 'Se ha eliminado un elemento.',
    en: 'An item has been removed.'
  },
  {
    es: 'Configuración guardada',
    en: 'Configuration Saved'
  },

  {
    es: 'Debe activar Autenticación de 2 factores para guardar',
    en: 'You must activate 2-factor authentication to save'
  },
  {
    es: 'Autenticación de dos factores Configurada',
    en: 'Two Factor Authentication Configured'
  },
  {
    es: 'Autenticación de dos factores Eliminada para esta cuenta',
    en: 'Two-Factor Authentication Removed for this account'
  },
  {
    es: 'Contraseña enviada al correo electronico asociado a la cuenta.',
    en: 'Password sent to the email associated with the account.'
  },
  {
    es: 'Longitud de contraseña invalida. Debe ser igual o superior a 6 caracteres.',
    en: 'Invalid password length. It must be equal to or greater than 6 characters.'
  },
  {
    es: 'Debe introducirse una cuenta de correo previamente creada o asignada por su empresa. Mediante este correo validamos la alianza.',
    en: 'An email account previously created or assigned by your company must be entered. Through this email we validate the alliance.'
  },
  {
    es: 'Observaciones Entrega',
    en: 'Delivery Notes'
  },
  {
    es: 'Observaciones',
    en: 'Notes'
  },
  {
    es: 'Debe aceptar los términos y condiciones antes de continuar',
    en: 'You must accept the terms and conditions before continuing'
  },
  {
    es: 'Términos de Servicio',
    en: 'Terms of Service'
  },
  {
    es: 'Dentro de Consolidado',
    en: 'In Package Consolidation'
  },
  {
    es: 'En Bodega Miami - Guía por Completar',
    en: 'In Miami\'s Warehouse - Package to be Completed'
  },

  {
    es: 'En Revisión Aduanas',
    en: 'Under Customs Review'
  },
  {
    es: 'En Tránsito a Centro de Distribución',
    en: 'In Transit to Distribution Center'
  },
  {
    es: 'En tránsito a Limón',
    en: 'In transit to Limón'
  },
  {
    es: 'En Tránsito al Cliente',
    en: 'In Transit to Customer'
  },
  {
    es: 'En Tránsito Hacia Costa Rica',
    en: 'In Transit To Costa Rica'
  },

  {
    es: 'Entregado',
    en: 'Delivered'
  },
  {
    es: 'Guía en Tramite Individual/Exoneración',
    en: 'Receipt in Process'
  },
  {
    es: 'Paquete Consolidado',
    en: 'Package Consolidation'
  },
  {
    es: 'Paquete Devuelto al Centro de Distribución',
    en: 'Package Returned to the Distribution Center'
  },
  {
    es: 'Paquete en Abandono',
    en: 'Abandoned Package'
  },
  {
    es: 'Paquete en Abandono Miami',
    en: 'Abandonment Package in Miami'
  },
  {
    es: 'Paquete Perdido',
    en: 'Lost Package'
  },
  {
    es: 'Pasado a Cuenta Carga',
    en: 'Package transfer to SICSA\'s account'
  },

  {
    es: 'Perdido En Miami',
    en: 'Lost Package in Miami'
  },
  {
    es: 'Prealerta',
    en: 'Pre alert'
  },
  {
    es: 'Proceso de Aduanas',
    en: 'In Customs'
  },
  {
    es: 'Recibido en Centro de Distribución',
    en: 'In Distribution Center'
  },
  {
    es: 'Recibido en Miami - Guía Completada',
    en: 'In Miami\'s Warehouse - Package Completed'
  },
  {
    es: 'Recibido en Sucursal Guadalupe',
    en: 'Received at Guadalupe\'s Branch'
  },
  {
    es: 'Recibido en Sucursal Limón',
    en: 'Received at Limón\'s Branch'
  },

  {
    es: 'Retirado por Cliente en Miami',
    en: 'Pick up by client in Miami'
  },
  {
    es: 'Vuelo Atrasado',
    en: 'Delayed flight'
  },
  {
    es: 'Todos',
    en: 'All'
  },
  {
    es: 'A/C todo sistema separados-split',
    en: 'A/C (split system)',
    value: '29,95 '
  },
  {
    es: 'A/C un cuerpo pared o ventana',
    en: 'A/C Unit (Wall or Window)',
    value: '29,95 '

  },
  {
    es: 'Abanico para computadora',
    en: 'Computer fan',
    value: '24,30 '

  },
  {
    es: 'Adaptador de corriente',
    en: 'Power adapter',
    value: '14,13 '

  },
  {
    es: 'Adaptador de corriente electrica CACD',
    en: 'CACD power adapter',
    value: '14,13 '

  },
  {
    es: 'Adorno de uso doméstico',
    en: 'Home use ornament',
    value: '29,95 '

  },
  {
    es: 'Adorno plástico, madera, hierro u otro material',
    en: 'Ornament plastic, wood, iron or other material',
    value: '29,95 '

  },
  {
    es: 'Afeitadora eléctrica',
    en: 'Electric razor',
    value: '42,78 '

  },
  {
    es: 'Afilador de cuchillos eléctrico',
    en: 'Electric knife sharpener',
    value: '49,27 '

  },
  {
    es: 'Agenda',
    en: 'Diary',
    value: '29,95 '

  },
  {
    es: 'Agenda electrónica',
    en: 'Electronic diary',
    value: '14,13 '

  },
  {
    es: 'Aguja para coser y tejer',
    en: 'Needle for sewing and knitting',
    value: '14,13 '

  },
  {
    es: 'Airbag',
    en: 'Airbag',
    value: '1,00 '
  },
  {
    es: 'Alarma contra robo o incendio',
    en: 'Burglar or fire alarm',
    value: '14,13 '

  },
  {
    es: 'Álbum fotográfico',
    en: 'Photo album',
    value: '19,78 '

  },
  {
    es: 'Alfombra',
    en: 'Rug',
    value: '29,95 '

  },
  {
    es: 'Alicate',
    en: 'Pliers',
    value: '14,13 '

  },
  {
    es: 'Almohada',
    en: 'Pillow',
    value: '29,95 '

  },
  {
    es: 'Almohada Yoga',
    en: 'Yoga Pad',
    value: '24,30 '

  },
  {
    es: 'Alternador - Repuesto para vehiculo',
    en: 'Alternator - Vehicle Spare',
    value: '42,38 '

  },
  {
    es: 'Amortiguador, compensador y sus partes',
    en: 'Shock absorber, compensator and its parts',
    value: '42,78 '

  },
  {
    es: 'Amperímetro',
    en: 'Ammeter',
    value: '14,13 '

  },
  {
    es: 'Amplificador (parlante sin reproductor)',
    en: 'Amplifier (speaker without player)',
    value: '14,13 '

  },
  {
    es: 'Andadera',
    en: 'Walker',
    value: '1,00 '

  },
  {
    es: 'Antena',
    en: 'Antenna',
    value: '14,13 '

  },
  {
    es: 'Anteojos para sol',
    en: 'Sunglasses',
    value: '29,95 '

  },
  {
    es: 'Anzuelo',
    en: 'Fish hook',
    value: '14,13 '

  },
  {
    es: 'Apple Watch (reloj inteligente)',
    en: 'Apple watch (smart watch)',
    value: '13,00 '

  },
  {
    es: 'AppleTV, Google Chromecast',
    en: 'Apple TV, Google Chromecast',
    value: '49,27 '

  },
  {
    es: 'Arandela de hierro',
    en: 'Iron washer',
    value: '14,13 '

  },
  {
    es: 'Aros para lente',
    en: 'Lens rings',
    value: '14,13 '

  },
  {
    es: 'Artículo deportivo',
    en: 'Sporting goods',
    value: '24,30 '

  },
  {
    es: 'Artículos para aire libre',
    en: 'Outdoor items',
    value: '24,30 '

  },
  {
    es: 'Artículos para fiesta',
    en: 'Party supplies',
    value: '29,95 '

  },
  {
    es: 'Asiento para bebé automóvil',
    en: 'Baby car seat',
    value: '29,95 '

  },
  {
    es: 'Aspersor de agua para jardín',
    en: 'Garden water sprinkler',
    value: '14,13 '

  },
  {
    es: 'Aspiradora doméstica',
    en: 'Vacuum cleaner (house use only)',
    value: '49,27 '

  },
  {
    es: 'Audífonos',
    en: 'Earphones',
    value: '14,13 '

  },
  {
    es: 'Balanza doméstica',
    en: 'Domestic scale',
    value: '19,78 '

  },
  {
    es: 'Báscula',
    en: 'Weighing machine',
    value: '19,78 '

  },
  {
    es: 'Bastón',
    en: 'Walking stick',
    value: '29,95 '

  },
  {
    es: 'Batería (instrumento musical)',
    en: 'Drums (musical instrument)',
    value: '24,30 '

  },
  {
    es: 'Batería externa para celular, laptop o tableta',
    en: 'External battery for cell phone, laptop or tablet',
    value: '42,38 '

  },
  {
    es: 'Batería interna para computadora',
    en: 'Internal battery for computer',
    value: '42,38 '

  },
  {
    es: 'Batería para carro (NUEVA)',
    en: 'Car battery (NEW)',
    value: '62,16 '

  },
  {
    es: 'Batería recargable',
    en: 'Rechargeable battery',
    value: '42,38 '

  },
  {
    es: 'Bateria recargable para herramientas',
    en: 'Rechargeable battery for tools',
    value: '42,38 '

  },
  {
    es: 'Baterías y pilas tipo pastilla',
    en: 'Batteries and tablet cells',
    value: '14,13 '

  },
  {
    es: 'Batidora y licuadora doméstica',
    en: 'Household mixer and blender',
    value: '49,27 '

  },
  {
    es: 'Biberón o chupón para bebé',
    en: 'Baby bottle or pacifier',
    value: '29,95 '

  },
  {
    es: 'Bicicleta (valor mayor $1.000)',
    en: 'Bicycle (value greater than $1,000)',
    value: '29,95 '
  },
  {
    es: 'Bicicleta (valor menor $1.000)',
    en: 'Bicycle (less than $1,000 value)',
    value: '13,00 '

  },
  {
    es: 'Billetera',
    en: 'Wallet',
    value: '29,95 '

  },
  {
    es: 'Binoculares',
    en: 'Binoculars',
    value: '29,95 '

  },
  {
    es: 'Bisutería',
    en: 'Jewelry',
    value: '29,95 '

  },
  {
    es: 'Blusa',
    en: 'Blouse or Shirt',
    value: '29,95 '

  },
  {
    es: 'Bola (deporte)',
    en: 'ball (any sport)',
    value: '24,30 '

  },
  {
    es: 'Bolígrafo',
    en: 'Pen',
    value: '29,95 '

  },
  {
    es: 'Bolso (cualquier material o uso)',
    en: 'Bag (any material or use)',
    value: '29,95 '

  },
  {
    es: 'Bomba gasolina, aceite o refrig',
    en: 'Fuel, oil or refrigerant pump',
    value: '14,13 '

  },
  {
    es: 'Bombillas led',
    en: 'Led bulbs',
    value: '14,13 '

  },
  {
    es: 'Bombillo 15w o más',
    en: 'Light bulb 15w or more',
    value: '19,88 '

  },
  {
    es: 'Booster de seguridad',
    en: 'Security booster',
    value: '13,00 '

  },
  {
    es: 'Botella',
    en: 'Bottle',
    value: '29,95 '

  },
  {
    es: 'Botella con atomizador',
    en: 'Spray bottle',
    value: '14,13 '

  },
  {
    es: 'Boxer',
    en: 'Boxer',
    value: '29,95 '

  },
  {
    es: 'Brocha para maquillaje',
    en: 'Makeup brush',
    value: '19,78 '

  },
  {
    es: 'Bronceador',
    en: 'Bronzer',
    value: '29,95 '

  },
  {
    es: 'Brújula',
    en: 'Compass',
    value: '1,00 '

  },
  {
    es: 'Bufanda',
    en: 'Scarf',
    value: '29,95 '

  },
  {
    es: 'Bujía o candela',
    en: 'Spark plug or candle',
    value: '42,38 '

  },
  {
    es: 'Bulto',
    en: 'Bulk',
    value: '29,95 '

  },
  {
    es: 'Cable coaxial',
    en: 'Coaxial cable',
    value: '19,78 '

  },
  {
    es: 'Cable de encendido, lagartos',
    en: 'Ignition cable, lizards',
    value: '42,38 '

  },
  {
    es: 'Cable eléctrico',
    en: 'Electric cable',
    value: '29,95 '

  },
  {
    es: 'Cable fibra óptica',
    en: 'Fiber optic cable',
    value: '13,00 '

  },
  {
    es: 'Cable USB, (voltaje menor o igual a 80V con piezas de conexión)',
    en: 'USB cable, (voltage less than or equal to 80V with connecting parts)',
    value: '14,13 '

  },
  {
    es: 'Cables para bujía de encendido',
    en: 'Spark plug wires',
    value: '42,38 '

  },
  {
    es: 'Cables para celular',
    en: 'Cell phone cables',
    value: '14,13 '

  },
  {
    es: 'Cafetera eléctrica',
    en: 'Electric coffe maker',
    value: '49,27 '

  },
  {
    es: 'Caja Registradora',
    en: 'Cash register',
    value: '13,00 '

  },
  {
    es: 'Cajita de madera de adorno',
    en: 'Ornamental wooden box',
    value: '29,95 '

  },
  {
    es: 'Calculadora (NUEVA)',
    en: 'Calculator (NEW)',
    value: '13,00 '

  },
  {
    es: 'Calendario',
    en: 'Calendar',
    value: '29,95 '

  },
  {
    es: 'Calentador de toallas',
    en: 'Towel warmer',
    value: '49,27 '

  },
  {
    es: 'Calentador o Calefactor',
    en: 'Heater',
    value: '29,95 '

  },
  {
    es: 'Calzones para mujer',
    en: 'Breeches or Panties',
    value: '29,95 '

  },
  {
    es: 'Cámara digital de video',
    en: 'Digital video camera',
    value: '13,00 '

  },
  {
    es: 'Cámara fotográfica digital',
    en: 'Digital camera',
    value: '14,13 '

  },
  {
    es: 'Cámara para vehículo',
    en: 'Vehicle camera',
    value: '13,00 '

  },
  {
    es: 'Caminadora',
    en: 'treadmill',
    value: '24,30 '

  },
  {
    es: 'Camisa',
    en: 'Shirt',
    value: '29,95 '

  },
  {
    es: 'Caña para pescar',
    en: 'Fishing rod',
    value: '24,30 '

  },
  {
    es: 'Candado',
    en: 'Padlock',
    value: '19,78 '

  },
  {
    es: 'Candelas decorativas',
    en: 'Decorative candles',
    value: '29,95 '

  },
  {
    es: 'Capa contra lluvia',
    en: 'Rain cape',
    value: '29,95 '

  },
  {
    es: 'Capota para carro',
    en: 'Car hood',
    value: '42,78 '

  },
  {
    es: 'Cargador de batería para celular: cable con adaptador para pared',
    en: 'Cell phone battery charger: cable with wall adapter',
    value: '14,13 '

  },
  {
    es: 'Cargador de batería para computadora/laptop',
    en: 'Computer/Laptop Battery Charger',
    value: '14,13 '

  },
  {
    es: 'Cargador de baterías para computadora',
    en: 'Computer battery charger',
    value: '14,13 '

  },
  {
    es: 'Cargador para vehículo eléctrico',
    en: 'Electric vehicle charger',
    value: '14,13 '

  },
  {
    es: 'Carrito plástico para enrollar manguera',
    en: 'Plastic hose reel cart',
    value: '29,95 '

  },
  {
    es: 'Cartera',
    en: 'Briefcase, Purse, Bag',
    value: '29,95 '

  },
  {
    es: 'Cartucho para impresora',
    en: 'Cartridge printer',
    value: '13,00 '

  },
  {
    es: 'Casco para deporte',
    en: 'Helmet (sport)',
    value: '29,95 '

  },
  {
    es: 'Casco seguridad y moto otro material',
    en: 'Safety helmet and motorcycle other material',
    value: '29,95 '

  },
  {
    es: 'Catálogo promocional afiche y fotografía',
    en: 'Promotional catalog poster and photography',
    value: '29,95 '

  },
  {
    es: 'Catálogo técnico',
    en: 'Technical catalog, Blue Prints',
    value: '14,13 '

  },
  {
    es: 'CD música',
    en: 'CD music',
    value: '24,30 '

  },
  {
    es: 'CD película',
    en: 'CD movie',
    value: '14,13 '

  },
  {
    es: 'Celular',
    en: 'Cell phone',
    value: '13,00 '

  },
  {
    es: 'Celular USADO',
    en: 'USED cell phone',
    value: '13,00 '

  },
  {
    es: 'Cepillo dientes eléctrico',
    en: 'Electric toothbrush',
    value: '49,27 '

  },
  {
    es: 'Cepillo eléctrico para piso',
    en: 'Electric floor brush',
    value: '49,27 '

  },
  {
    es: 'Cera para carros',
    en: 'Car wax',
    value: '29,95 '

  },
  {
    es: 'Cera para tabla surf',
    en: 'Surfboard wax',
    value: '14,13 '

  },
  {
    es: 'Champú',
    en: 'Shampoo',
    value: '49,27 '

  },
  {
    es: 'Chocolates, confites, gomitas',
    en: 'Chocolates, candies, gummies',
    value: '29,95 '
  },
  {
    es: 'Cigarro de nicotina',
    en: 'Nicotine cigarette',
    value: '44,52 '

  },
  {
    es: 'Cigarro electrónico',
    en: 'Electronic cigarette',
    value: '14,13 '

  },
  {
    es: 'Cinta adhesiva',
    en: 'Scotch tape',
    value: '24,30 '

  },
  {
    es: 'Cinturón de seguridad',
    en: 'Seat belt',
    value: '42,78 '

  },
  {
    es: 'Cobija',
    en: 'Blanket',
    value: '29,95 '

  },
  {
    es: 'Coche para bebé',
    en: 'Baby car',
    value: '29,95 '

  },
  {
    es: 'Cocina de gas con cilindro',
    en: 'Gas cooker with cylinder',
    value: '29,95 '

  },
  {
    es: 'Cocina de gas sin cilindro',
    en: 'Gas cooker without cylinder',
    value: '29,95 '

  },
  {
    es: 'Cocina eléctrica',
    en: 'Electric kitchen',
    value: '42,83 '

  },
  {
    es: 'Coffee maker',
    en: 'Coffee maker',
    value: '49,27 '

  },
  {
    es: 'Colchón',
    en: 'Mattress',
    value: '29,95 '

  },
  {
    es: 'Colgador, percha y similar',
    en: 'Hanger, hanger and the like',
    value: '24,30 '

  },
  {
    es: 'Colonia o Perfume',
    en: 'Cologne or Perfume',
    value: '29,95 '

  },
  {
    es: 'Compresor',
    en: 'Compressor',
    value: '14,13 '

  },
  {
    es: 'Computadora desktop y portátil (NUEVA)',
    en: 'Desktop and laptop computer (NEW)',
    value: '13,00 '

  },
  {
    es: 'Computadora desktop y portátil (USADA)',
    en: 'Desktop and laptop computer (USED)',
    value: '13,00 '

  },
  {
    es: 'Computadora para vehículo',
    en: 'vehicle computer',
    value: '14,13 '

  },
  {
    es: 'Condón',
    en: 'Condom',
    value: '1,00 '

  },
  {
    es: 'Congelador',
    en: 'Freezer',
    value: '24,30 '

  },
  {
    es: 'Consolas para videojuegos ((Play Station, Xbox, Game Cube, Nintendo Switch,etc)',
    en: 'Video game consoles (Play Station, Xbox, Game Cube, Nintendo Switch,etc)',
    value: '55,71 '

  },
  {
    es: 'Control para videojuegos',
    en: 'Controller for video games',
    value: '55,71 '

  },
  {
    es: 'Control remoto TV',
    en: 'Remote control TV',
    value: '14,13 '

  },
  {
    es: 'Copas Menstruales',
    en: 'Menstrual cups',
    value: '14,13 '

  },
  {
    es: 'Corbata',
    en: 'Tie',
    value: '29,95 '

  },
  {
    es: 'Cortadora de césped',
    en: 'Lawn mower',
    value: '14,13 '

  },
  {
    es: 'Cortina',
    en: 'Curtain',
    value: '29,95 '

  },
  {
    es: 'CPU disco duro',
    en: 'CPU hard drive',
    value: '13,00 '

  },
  {
    es: 'Crayola',
    en: 'Crayon',
    value: '10,00 '

  },
  {
    es: 'Crema depiladora',
    en: 'Hair remover cream',
    value: '68,60 '

  },
  {
    es: 'Crema facial/corporal',
    en: 'Face/body cream',
    value: '29,95 '

  },
  {
    es: 'Cuaderno',
    en: 'Notebook',
    value: '15,00 '

  },
  {
    es: 'Cubiertos',
    en: 'Knife, Fork, Spoons',
    value: '24,30 '

  },
  {
    es: 'Cubreasientos para automóvil sintéticos',
    en: 'Synthetic car seat covers',
    value: '29,95 '

  },
  {
    es: 'Cuchilla o navaja',
    en: 'Blade or razor',
    value: '24,30 '

  },
  {
    es: 'Cuchillas para licuadora',
    en: 'Blender blades',
    value: '31,08 '

  },
  {
    es: 'Cuchillo',
    en: 'Knife',
    value: '24,30 '

  },
  {
    es: 'Cuchillo hoja fija',
    en: 'Fixed blade knife',
    value: '24,30 '

  },
  {
    es: 'Delantal, material artificial',
    en: 'Apron, artificial material',
    value: '29,95 '

  },
  {
    es: 'Desatornillador eléctrico y manual',
    en: 'Electric and manual screwdriver',
    value: '14,13 '

  },
  {
    es: 'Deshidratador de alimentos',
    en: 'Food dehydrator',
    value: '49,27 '

  },
  {
    es: 'Desodorante ambiental',
    en: 'Environmental deodorant',
    value: '68,60 '

  },
  {
    es: 'Desodorante corporal',
    en: 'Body deodorant',
    value: '49,27 '

  },
  {
    es: 'Despertador',
    en: 'Alarm clock',
    value: '29,95 '

  },
  {
    es: 'Discos de acetato',
    en: 'Vinyl records',
    value: '29,95 '

  },
  {
    es: 'Dispensador de jabón líquido',
    en: 'Liquid soap dispenser',
    value: '14,13 '

  },
  {
    es: 'Dispositivo Alexa con video llamada',
    en: 'Alexa device with video call',
    value: '49,27 '

  },
  {
    es: 'Drone',
    en: 'Drone',
    value: '29,95 '

  },
  {
    es: 'Ducha',
    en: 'Shower',
    value: '29,95 '

  },
  {
    es: 'DVD',
    en: 'DVD',
    value: '14,13 '

  },
  {
    es: 'DVD o Bluray (dispositivo)',
    en: 'DVD or Blu-ray (console)',
    value: '49,27 '

  },
  {
    es: 'Edredón',
    en: 'Quilt',
    value: '29,95 '

  },
  {
    es: 'Enchufe inteligente',
    en: 'smart plug',
    value: '13,00 '

  },
  {
    es: 'Envase',
    en: 'Container',
    value: '24,30 '

  },
  {
    es: 'Equipo de sonido',
    en: 'Stereo',
    value: '49,27 '

  },
  {
    es: 'Equipo médico',
    en: 'Medical equipment',
    value: '14,13 '

  },
  {
    es: 'Escalera de aluminio',
    en: 'Aluminium ladder',
    value: '24,30 '

  },
  {
    es: 'Escritorio',
    en: 'Desk',
    value: '29,95 '

  },
  {
    es: 'Esmalte para uñas',
    en: 'Nail Polish',
    value: '29,95 '

  },
  {
    es: 'Espejo',
    en: 'Mirror',
    value: '29,95 '

  },
  {
    es: 'Espejo reflectante',
    en: 'Reflective mirror',
    value: '29,95 '

  },
  {
    es: 'Espejo retrovisor',
    en: 'Rear-view mirror',
    value: '14,13 '

  },
  {
    es: 'Estetoscopio',
    en: 'Stethoscope',
    value: '14,13 '

  },
  {
    es: 'Estuche para celular, cuero, plástico, caucho, etc',
    en: 'Cell phone case, leather, plastic, rubber, etc.',
    value: '29,95 '

  },
  {
    es: 'Estuche para coche bebé',
    en: 'Baby car case',
    value: '29,95 '

  },
  {
    es: 'Estuche para laptop',
    en: 'Laptop case',
    value: '29,95 '

  },
  {
    es: 'Estuche para trípode',
    en: 'Tripod case',
    value: '29,95 '

  },
  {
    es: 'Etiqueta Adhesiva',
    en: 'Sticky label',
    value: '14,13 '

  },
  {
    es: 'Faja',
    en: 'Wad of bills',
    value: '29,95 '

  },
  {
    es: 'Faja de caucho dentada',
    en: 'Jagged rubber girdle',
    value: '14,13 '

  },
  {
    es: 'Filtro de aire para automóvil',
    en: 'Car air filter',
    value: '24,30 '

  },
  {
    es: 'Filtro para agua',
    en: 'Water filter',
    value: '14,13 '

  },
  {
    es: 'Filtro para aire y líquido en motor',
    en: 'Engine air and fluid filter',
    value: '24,30 '

  },
  {
    es: 'Filtro para cámara fotográfica',
    en: 'Camera filter',
    value: '14,13 '

  },
  {
    es: 'Filtro para peceras',
    en: 'Filter for fish tanks',
    value: '14,13 '

  },
  {
    es: 'Flash electrónico',
    en: 'Electronic flash',
    value: '24,30 '

  },
  {
    es: 'Florero',
    en: 'Vase',
    value: '29,95 '

  },
  {
    es: 'Foco de mano portátil',
    en: 'Portable handheld spotlight',
    value: '19,78 '

  },
  {
    es: 'Focos o luces para vehículo',
    en: 'Spotlights or vehicle lights',
    value: '14,13 '

  },
  {
    es: 'Fondue',
    en: 'Fondue',
    value: '49,27 '

  },
  {
    es: 'Fotocopiadora',
    en: 'Photocopier',
    value: '13,00 '

  },
  {
    es: 'Freidora de aire o aceite',
    en: 'Air or oil fryer',
    value: '49,27 '

  },
  {
    es: 'Frenos para automovil y sus partes',
    en: 'Brakes for automobile and its parts',
    value: '42,78 '

  },
  {
    es: 'Funda',
    en: 'Sheath',
    value: '29,95 '

  },
  {
    es: 'Gasificador de bebidas',
    en: 'Beverage carbonator',
    value: '14,13 '

  },
  {
    es: 'Gata hidráulico',
    en: 'Hydraulic jack',
    value: '14,13 '

  },
  {
    es: 'Glucómetro, medidor de azúcar en la sangre - glicemia',
    en: 'Glucometer, blood sugar meter - glycemia',
    value: '14,13 '

  },
  {
    es: 'Gorra',
    en: 'Cap',
    value: '29,95 '

  },
  {
    es: 'GPS localizador satelital',
    en: 'GPS satellite locator',
    value: '14,13 '

  },
  {
    es: 'Grifería de metal común',
    en: 'Base metal faucets',
    value: '14,13 '

  },
  {
    es: 'Guantes',
    en: 'Gloves',
    value: '29,95 '

  },
  {
    es: 'Guardabarro para bicicleta',
    en: 'Bike mudguard',
    value: '24,30 '

  },
  {
    es: 'Guitarra acústica',
    en: 'Acoustic guitar',
    value: '29,95 '

  },
  {
    es: 'Guitarra eléctrica',
    en: 'Electric guitar',
    value: '24,30 '

  },
  {
    es: 'Halógenos para vehículo',
    en: 'Halogen for vehicle',
    value: '14,13 '

  },
  {
    es: 'Herramienta cortadora multiuso',
    en: 'Multipurpose cutter tool',
    value: '14,13 '

  },
  {
    es: 'Hielera',
    en: 'Cooler',
    value: '29,95 '

  },
  {
    es: 'Home theater',
    en: 'Home theater',
    value: '49,27 '

  },
  {
    es: 'Impresora',
    en: 'Printer',
    value: '13,00 '

  },
  {
    es: 'Impresora 3D',
    en: '3D printer',
    value: '14,13 '

  },
  {
    es: 'Incubadora avicultura',
    en: 'Poultry incubator',
    value: '1,00 '

  },
  {
    es: 'Inodoro',
    en: 'Lavatory',
    value: '29,95 '

  },
  {
    es: 'Instrumentos musicales (partes y accesorios)',
    en: 'Musical instruments (parts and accessories)',
    value: '19,78 '

  },
  {
    es: 'Jacket/Sweater (cualquier material)',
    en: 'Jacket/Sweater (any material)',
    value: '29,95 '

  },
  {
    es: 'Jarra',
    en: 'Mug',
    value: '29,95 '

  },
  {
    es: 'Joyería',
    en: 'Jeweler\'s',
    value: '29,95 '

  },
  {
    es: 'Juego de decantador de Licor',
    en: 'Liquor decanter set',
    value: '29,95 '

  },
  {
    es: 'Juego de mesa',
    en: 'Board game',
    value: '29,95 '

  },
  {
    es: 'Juegos en cartucho para videojuegos',
    en: 'Games on cartridge for video games',
    value: '55,00 '

  },
  {
    es: 'Juegos en CD para videojuegos',
    en: 'CD games for video games',
    value: '14,13 '

  },
  {
    es: 'Juguete',
    en: 'Toy',
    value: '29,95 '

  },
  {
    es: 'Juguete de peluche',
    en: 'Stuffed toy',
    value: '29,95 '

  },
  {
    es: 'Juguete sexual (de baterías)',
    en: 'Sex toy (battery operated)',
    value: '29,95 '

  },
  {
    es: 'Juguete sexual (sin baterías)',
    en: 'Sex toy (without batteries)',
    value: '29,95 '

  },
  {
    es: 'Kindle Fire',
    en: 'Kindle Fire',
    value: '13,00 '

  },
  {
    es: 'Kindle Reader (lector de libros digital)',
    en: 'Kindle Reader (digital book reader)',
    value: '13,00 '

  },
  {
    es: 'Kit overhaul para motor',
    en: 'Engine overhaul kit',
    value: '14,13 '

  },
  {
    es: 'Lámpara',
    en: 'Lamp',
    value: '29,95 '

  },
  {
    es: 'Lámpara portátil de mano, foco o linterna',
    en: 'Portable hand lamp, spotlight or flashlight',
    value: '19,78 '

  },
  {
    es: 'Lámpara solar',
    en: 'Solar lamp',
    value: '29,95 '

  },
  {
    es: 'Lapicero desechable',
    en: 'Disposable pen',
    value: '15,00 '

  },
  {
    es: 'Lapicero NO desechable',
    en: 'Non-disposable pen',
    value: '29,95 '

  },
  {
    es: 'Lápices con cuaderno',
    en: 'Pencils with notebook',
    value: '24,30 '

  },
  {
    es: 'Laptop - Netbook (nueva)',
    en: 'Laptop - Netbook (nueva)',
    value: '13,00 '

  },
  {
    es: 'Laptop - Netbook (usada)',
    en: 'Laptop - Netbook (used)',
    value: '13,00 '

  },
  {
    es: 'Lavadora (ropa)',
    en: 'Washing machine (clothes)',
    value: '42,83 '

  },
  {
    es: 'Lavamanos',
    en: 'Washbasin',
    value: '29,95 '

  },
  {
    es: 'Lente para cámara',
    en: 'Camera lens',
    value: '14,13 '

  },
  {
    es: 'Lente para enfoque',
    en: 'Focus lens',
    value: '14,13 '

  },
  {
    es: 'Libreta',
    en: 'Notebook',
    value: '29,95 '

  },
  {
    es: 'Libro',
    en: 'Book',
    value: '2,13 '

  },
  {
    es: 'Libro de estampas',
    en: 'Book of prints',
    value: '19,78 '

  },
  {
    es: 'Licor (cualquier tipo)',
    en: 'Liquor (any kind)',
    value: '57,12 '

  },
  {
    es: 'Llavero',
    en: 'Key ring',
    value: '14,13 '

  },
  {
    es: 'Lonchera',
    en: 'Lunch box',
    value: '29,95 '

  },
  {
    es: 'Maleta',
    en: 'Suitcase',
    value: '29,95 '

  },
  {
    es: 'Maletín',
    en: 'Briefcase',
    value: '29,95 '

  },
  {
    es: 'Manga plástica para decorar queques',
    en: 'Plastic sleeve to decorate cakes',
    value: '29,95 '

  },
  {
    es: 'Manguera de tela',
    en: 'Fabric hose',
    value: '14,13 '

  },
  {
    es: 'Manguera flexible de PVC',
    en: 'PVC flexible hose',
    value: '19,78 '

  },
  {
    es: 'Manguera para jardín',
    en: 'Garden hose',
    value: '19,78 '

  },
  {
    es: 'Manivela para bicicleta',
    en: 'Bicycle crank',
    value: '24,30 '

  },
  {
    es: 'Mantel',
    en: 'Tablecloth',
    value: '29,95 '

  },
  {
    es: 'Manual técnico',
    en: 'Technical manual',
    value: '14,13 '

  },
  {
    es: 'Maquillaje',
    en: 'Make-up',
    value: '29,95 '

  },
  {
    es: 'Máquina de ejercicio',
    en: 'Exercise machine',
    value: '24,30 '

  },
  {
    es: 'Máquina para capuccino o espresso',
    en: 'Cappuccino or espresso machine',
    value: '49,27 '

  },
  {
    es: 'Máquina para cortar cabello',
    en: 'Haircut machine',
    value: '42,78 '

  },
  {
    es: 'Máquina para coser',
    en: 'Sewing machine',
    value: '14,13 '

  },
  {
    es: 'Máquina para ejercicio',
    en: 'Exercise machine',
    value: '24,30 '

  },
  {
    es: 'Máquina para manualidades',
    en: 'Craft machine',
    value: '68,60 '

  },
  {
    es: 'Máquina para scrapbook',
    en: 'Scrapbook machine',
    value: '14,13 '

  },
  {
    es: 'Máquina para soldar',
    en: 'Welding machine',
    value: '14,13 '

  },
  {
    es: 'Máquina para sublimación',
    en: 'Machine for sublimation',
    value: '14,13 '

  },
  {
    es: 'Máquina para tatuar',
    en: 'Tattoo machine',
    value: '14,13 '

  },
  {
    es: 'Marco bicicleta',
    en: 'Bicycle frame',
    value: '24,30 '

  },
  {
    es: 'Martillo',
    en: 'Hammer',
    value: '14,13 '

  },
  {
    es: 'Masajeador facial o corporal',
    en: 'Facial or body massager',
    value: '14,13 '

  },
  {
    es: 'Máscara para soldar',
    en: 'Welding mask',
    value: '14,13 '

  },
  {
    es: 'mascara respiradora reutilizable',
    en: 'Reusable respirator mask',
    value: '14,13 '

  },
  {
    es: 'Máscara Snorkeling',
    en: 'Snorkeling Mask',
    value: '24,30 '

  },
  {
    es: 'Maseta plástica',
    en: 'Plastic pot',
    value: '29,95 '

  },
  {
    es: 'Mat para yoga',
    en: 'Yoga mat',
    value: '29,95 '

  },
  {
    es: 'Medicamento',
    en: 'Medicine',
    value: '14,13 '

  },
  {
    es: 'Medicamento natural',
    en: 'Natural medicine',
    value: '14,13 '

  },
  {
    es: 'Medidor de electricidad',
    en: 'Electricity meter',
    value: '14,13 '

  },
  {
    es: 'Medidor de estatura',
    en: 'Height meter',
    value: '14,13 '

  },
  {
    es: 'Medidor de presión líquidos/gases',
    en: 'Liquid/gas pressure gauge',
    value: '13,00 '

  },
  {
    es: 'Medidores de humedad',
    en: 'Moisture meters',
    value: '1,00 '

  },
  {
    es: 'Mesa de billar',
    en: 'Pool table',
    value: '29,95 '

  },
  {
    es: 'Micrófono',
    en: 'Microphone',
    value: '14,13 '

  },
  {
    es: 'Microondas',
    en: 'Microwave',
    value: '37,58 '

  },
  {
    es: 'Microscopio',
    en: 'Microscope',
    value: '14,13 '

  },
  {
    es: 'Mixer',
    en: 'Mixer',
    value: '19,78 '

  },
  {
    es: 'Modulador de voz',
    en: 'Voice modulator',
    value: '14,13 '

  },
  {
    es: 'Molde para cocinar',
    en: 'Cooking mold',
    value: '29,95 '

  },
  {
    es: 'Monitor (sin sintonizador de TV)',
    en: 'Monitor (no TV tuner)',
    value: '13,00 '

  },
  {
    es: 'Monitor (con sintonizador de TV)',
    en: 'Monitor (with TV tuner)',
    value: '49,27 '

  },
  {
    es: 'Motor eléctrico',
    en: 'Electric motor',
    value: '14,13 '

  },
  {
    es: 'Motor (nuevo)',
    en: 'Engine(new)',
    value: '14,13 '

  },
  {
    es: 'Motor para portón eléctrico',
    en: 'Electric gate motor',
    value: '14,13 '

  },
  {
    es: 'Mouse',
    en: 'Mouse',
    value: '13,00 '

  },
  {
    es: 'Mousepad',
    en: 'Mouse pad',
    value: '19,78 '

  },
  {
    es: 'Mueble',
    en: 'Furniture',
    value: '29,95 '

  },
  {
    es: 'Mueble para máquina de coser',
    en: 'Sewing machine',
    value: '29,95 '

  },
  {
    es: 'Mufla y silenciador',
    en: 'Muffle and silencer',
    value: '42,78 '

  },
  {
    es: 'Multimetro',
    en: 'Multimeter',
    value: '14,13 '

  },
  {
    es: 'Muñeca',
    en: 'Doll',
    value: '29,95 '

  },
  {
    es: 'Olla arrocera 3 en1',
    en: '3 in 1 rice cooker',
    value: '49,27 '

  },
  {
    es: 'Olla para cocinar',
    en: 'Cooking pot',
    value: '29,95 '

  },
  {
    es: 'Organeta',
    en: 'Organeta',
    value: '24,30 '

  },
  {
    es: 'Pajilla',
    en: 'Straw',
    value: '29,95 '

  },
  {
    es: 'Palo de golf',
    en: 'Golf club',
    value: '24,30 '

  },
  {
    es: 'Pañal',
    en: 'Honeycomb',
    value: '15,00 '

  },
  {
    es: 'Paño',
    en: 'Cloth',
    value: '29,95 '

  },
  {
    es: 'Pañoleta',
    en: 'Kerchief',
    value: '29,95 '

  },
  {
    es: 'Pantalla para celular',
    en: 'Mobile screen',
    value: '13,00 '

  },
  {
    es: 'Pantaloneta',
    en: 'Shorts',
    value: '29,95 '

  },
  {
    es: 'Papel para decorar pared',
    en: 'Paper to decorate wall',
    value: '24,30 '

  },
  {
    es: 'Parabrisas',
    en: 'Windshield',
    value: '19,78 '

  },
  {
    es: 'Paraguas',
    en: 'Umbrella',
    value: '29,95 '

  },
  {
    es: 'Parilla de carbon',
    en: 'Charcoal grill',
    value: '24,30 '

  },
  {
    es: 'Parlante - Speaker',
    en: 'Speaker - Speaker',
    value: '14,13 '

  },
  {
    es: 'Parrilla a gas portátil',
    en: 'Portable gas grill',
    value: '24,30 '

  },
  {
    es: 'Parrilla de gas SIN recipiente',
    en: 'Gas grill WITHOUT container',
    value: '24,30 '

  },
  {
    es: 'Parte para embrague',
    en: 'Part for clutch',
    value: '42,78 '

  },
  {
    es: 'Partes para cajas registradoras',
    en: 'Parts for cash registers',
    value: '13,00 '

  },
  {
    es: 'Patines',
    en: 'Roller skates',
    value: '24,30 '

  },
  {
    es: 'Peluca',
    en: 'Wig',
    value: '29,95 '

  },
  {
    es: 'Peluche',
    en: 'Teddy',
    value: '29,95 '

  },
  {
    es: 'Perfume',
    en: 'Fragrance',
    value: '29,95 '

  },
  {
    es: 'Perla fina natural',
    en: 'Natural fine pearl',
    value: '24,30 '

  },
  {
    es: 'Piano de cola',
    en: 'Grand piano',
    value: '24,30 '

  },
  {
    es: 'Piano eléctrico',
    en: 'Electric piano',
    value: '24,30 '

  },
  {
    es: 'Pieza de mano odontología',
    en: 'Dentistry handpiece',
    value: '14,13 '

  },
  {
    es: 'Pieza instrumento musical',
    en: 'Musical instrument piece',
    value: '19,78 '

  },
  {
    es: 'Pieza para bicicleta',
    en: 'Bike part',
    value: '14,13 '

  },
  {
    es: 'Pintura hogar - edificio',
    en: 'Home painting - building',
    value: '36,39 '

  },
  {
    es: 'Pintura para entretenimiento',
    en: 'Paint for entertainment',
    value: '19,78 '

  },
  {
    es: 'Pistola paintball',
    en: 'Paintball gun',
    value: '29,95 '

  },
  {
    es: 'Plancha para cabello normal o vapor',
    en: 'Straightener for normal hair or steam',
    value: '49,27 '

  },
  {
    es: 'Planchas eléctricas para ropa de uso doméstico',
    en: 'Electric irons for household clothes',
    value: '24,30 '

  },
  {
    es: 'Plasticina',
    en: 'Plasticine',
    value: '6,00 '

  },
  {
    es: 'PlayStation',
    en: 'PlayStation',
    value: '55,71 '

  },
  {
    es: 'Portaretrato digital',
    en: 'Digital picture frame',
    value: '42,97 '

  },
  {
    es: 'Poster',
    en: 'Poster',
    value: '29,95 '

  },
  {
    es: 'Preamplificador de micrófono',
    en: 'Microphone preamplifier',
    value: '14,13 '

  },
  {
    es: 'Procesadores de computo',
    en: 'Computer processors',
    value: '13,00 '

  },
  {
    es: 'Protector de pantalla para celular',
    en: 'Cell phone screen protector',
    value: '24,30 '

  },
  {
    es: 'Protector para CPU',
    en: 'CPU Shield',
    value: '13,00 '

  },
  {
    es: 'Proteína o bebida en polvo',
    en: 'Protein or drink powder',
    value: '29,95 '

  },
  {
    es: 'Prótesis (varia según descripción)',
    en: 'Prosthesis (varies according to description)',
    value: '14,13 '

  },
  {
    es: 'Proyector',
    en: 'Projector',
    value: '31,08 '

  },
  {
    es: 'Puntero láser',
    en: 'Laser pointer',
    value: '14,13 '

  },
  {
    es: 'Radio',
    en: 'Radio',
    value: '49,27 '

  },
  {
    es: 'Radiograbadora',
    en: 'Radio recorder',
    value: '49,27 '

  },
  {
    es: 'Radioreloj',
    en: 'Radio clock',
    value: '49,27 '

  },
  {
    es: 'Raqueta de tenis',
    en: 'Tennis racket',
    value: '24,30 '

  },
  {
    es: 'Refrigeradora',
    en: 'Refrigerator',
    value: '42,83 '

  },
  {
    es: 'Reloj de pared',
    en: 'Wall Clock',
    value: '29,95 '

  },
  {
    es: 'Reloj de pulso',
    en: 'Wristwatch',
    value: '29,95 '

  },
  {
    es: 'Reloj deportivo inteligente',
    en: 'Smart sport watch',
    value: '14,13 '

  },
  {
    es: 'Reloj marcador para oficina',
    en: 'Marker clock for office',
    value: '14,13 '

  },
  {
    es: 'Reproductor de audio y video',
    en: 'Audio and video player',
    value: '49,27 '

  },
  {
    es: 'Reproductor MP3',
    en: 'Mp3 player',
    value: '49,27 '

  },
  {
    es: 'Resina UV Líquida',
    en: 'Liquid UV Resin',
    value: '14,13 '

  },
  {
    es: 'Revelador Fotográfico',
    en: 'Photographic Developer',
    value: '14,13 '

  },
  {
    es: 'Rodillo y brocha para pintar',
    en: 'Roller and paint brush',
    value: '29,95 '

  },
  {
    es: 'Ropa',
    en: 'Clothes',
    value: '29,95 '

  },
  {
    es: 'Ropa deportiva',
    en: 'Sportswear',
    value: '29,95 '

  },
  {
    es: 'Ropa interior',
    en: 'Underwear',
    value: '29,95 '

  },
  {
    es: 'Ropa (nueva)',
    en: 'New clothes',
    value: '29,95 '

  },
  {
    es: 'Ropa (usada)',
    en: 'Used clothes',
    value: '29,95 '

  },
  {
    es: 'Router',
    en: 'router',
    value: '13,00 '

  },
  {
    es: 'Router / Herramienta manual electrica',
    en: 'Router / Electric hand tool',
    value: '14,13 '

  },
  {
    es: 'Sábana',
    en: 'Sheet',
    value: '29,95 '

  },
  {
    es: 'Saborizantes/Nicotina para cigarrillos electrónicos',
    en: 'Flavorings/Nicotine for electronic cigarettes',
    value: '24,30 '

  },
  {
    es: 'Sartén',
    en: 'Frying pan',
    value: '29,95 '

  },
  {
    es: 'Scanner',
    en: 'Scanner',
    value: '13,00 '

  },
  {
    es: 'Secadora para cabello',
    en: 'Hair dryer',
    value: '49,27 '

  },
  {
    es: 'Selladora de bolsas',
    en: 'Sealing bags',
    value: '24,30 '

  },
  {
    es: 'Semillas (comestible)',
    en: 'Seeds (edible)',
    value: '29,95 '

  },
  {
    es: 'Shampoo / líquido de vidrios / liquido para llantas',
    en: 'Shampoo / glass liquid / liquid for tires',
    value: '14,13 '

  },
  {
    es: 'Short',
    en: 'Shorts',
    value: '29,95 '

  },
  {
    es: 'Silla de ruedas con motor',
    en: 'Motorized wheelchair',
    value: '14,13 '

  },
  {
    es: 'Silla, banca, mecedora, asiento para casa/negocio',
    en: 'Chair, bench, rocking chair, seat for home/business',
    value: '29,95 '

  },
  {
    es: 'Software',
    en: 'Software',
    value: '13,00 '

  },
  {
    es: 'Sombrilla',
    en: 'Parasol',
    value: '29,95 '

  },
  {
    es: 'Soundbar',
    en: 'sound bar',
    value: '14,13 '

  },
  {
    es: 'Spray para cabello',
    en: 'Hairspray',
    value: '68,60 '

  },
  {
    es: 'Suéter',
    en: 'Sweater',
    value: '29,95 '

  },
  {
    es: 'Suplemento alimenticio',
    en: 'Nutritional supplement',
    value: '29,95 '

  },
  {
    es: 'Suplemento dietetico',
    en: 'Dietary supplement',
    value: '24,30 '

  },
  {
    es: 'Tabla de Surf',
    en: 'Surfboard',
    value: '24,30 '

  },
  {
    es: 'Tableta, iPad y similar',
    en: 'Tablet, iPad and similar',
    value: '13,00 '

  },
  {
    es: 'Tacómetro (velocímetro)',
    en: 'Tachometer (speedometer)',
    value: '14,13 '

  },
  {
    es: 'Tarjeta de memoria',
    en: 'Memory card',
    value: '13,00 '

  },
  {
    es: 'Tarjeta de video',
    en: 'Graphics card',
    value: '13,00 '

  },
  {
    es: 'Tarjeta electrónica',
    en: 'Electronic card',
    value: '13,00 '

  },
  {
    es: 'Tarjeta madre/red',
    en: 'Motherboard/network',
    value: '13,00 '

  },
  {
    es: 'Teclado (computadora)',
    en: 'Keyboard (computer)',
    value: '13,00 '

  },
  {
    es: 'Teléfono innalámbrico',
    en: 'Cordless phone',
    value: '13,00 '

  },
  {
    es: 'Telescopio',
    en: 'Telescope',
    value: '14,13 '

  },
  {
    es: 'Televisor LED, LCD, Plasma, 3D',
    en: 'LED, LCD, Plasma, 3D TV',
    value: '49,27 '

  },
  {
    es: 'Termómetro hogar/hospital',
    en: 'Home/hospital thermometer',
    value: '14,13 '

  },
  {
    es: 'Termómetro para BBQ',
    en: 'BBQ Thermometer',
    value: '14,13 '

  },
  {
    es: 'Termómetro para cocina',
    en: 'Kitchen thermometer',
    value: '31,08 '

  },
  {
    es: 'Termometro USO AGROPECUARIO',
    en: 'Agricultural use thermometer',
    value: '1,00 '

  },
  {
    es: 'Termostato',
    en: 'Thermostat',
    value: '14,13 '

  },
  {
    es: 'Tienda para acampar',
    en: 'Camping store',
    value: '29,95 '

  },
  {
    es: 'Tocadiscos',
    en: 'Turntable',
    value: '49,27 '

  },
  {
    es: 'Traje de neopreno para Surf',
    en: 'Surf wetsuit',
    value: '29,95 '

  },
  {
    es: 'Tratamiento para Cabello',
    en: 'Treatment for hair',
    value: '68,60 '

  },
  {
    es: 'Tratamiento para los hongos en las uñas',
    en: 'Treatment for nail fungus',
    value: '3,00 '

  },
  {
    es: 'Trípode',
    en: 'Tripod',
    value: '14,13 '

  },
  {
    es: 'Tripode descansa sobre el suelo Mueble o basamento',
    en: 'Tripod rests on the ground Furniture or plinth',
    value: '29,95 '

  },
  {
    es: 'Triturador de latas',
    en: 'Can crusher',
    value: '14,13 '

  },
  {
    es: 'UPS',
    en: 'UPS',
    value: '13,00 '

  },
  {
    es: 'USB',
    en: 'USB',
    value: '13,00 '

  },
  {
    es: 'Utensilios para BBQ',
    en: 'BBQ utensils',
    value: '24,30 '

  },
  {
    es: 'Vajilla',
    en: 'Crockery',
    value: '29,95 '

  },
  {
    es: 'Vaporizador',
    en: 'Vaporizer',
    value: '14,13 '

  },
  {
    es: 'Vaporizador Facial',
    en: 'Facial vaporizer',
    value: '49,27 '

  },
  {
    es: 'Vaso',
    en: 'Glass',
    value: '29,95 '

  },
  {
    es: 'Vaso térmico',
    en: 'Thermos',
    value: '24,30 '

  },
  {
    es: 'Ventilador',
    en: 'Fan',
    value: '29,95 '

  },
  {
    es: 'Vestido de baño',
    en: 'Swimsuit',
    value: '29,95 '

  },
  {
    es: 'Vestido de poliester para mujer',
    en: 'Women\'s polyester dress',
    value: '29,95 '

  },
  {
    es: 'Video casette grabado audio y video',
    en: 'Video cassette recorded audio and video',
    value: '29,95 '

  },
  {
    es: 'Videojuego portátil',
    en: 'Portable video game',
    value: '55,71 '

  },
  {
    es: 'Violin, Viola, Violonchelo, Contrabajo',
    en: 'Violin, Viola, Cello, Double Bass',
    value: '24,30 '

  },
  {
    es: 'Vitaminas',
    en: 'Vitamins',
    value: '24,30 '

  },
  {
    es: 'Voltímetro',
    en: 'Voltmeter',
    value: '14,13 '

  },
  {
    es: 'Waflera',
    en: 'Waffle maker',
    value: '49,27 '

  },
  {
    es: 'Walkie Talkie',
    en: 'Walkie talkies',
    value: '13,00 '

  },
  {
    es: 'YETI',
    en: 'YETI',
    value: '29,95 '

  },
  {
    es: 'Zapato (nuevo)',
    en: 'Shoe (new)',
    value: '29,95 '

  },
  {
    es: 'Zapato (usado)',
    en: 'Shoe (used)',
    value: '29,95 '
  },
  {
    es: 'Requiere Permiso - consulte',
    en: 'Permit Required'
  },
  {
    es: 'Consultar el tipo',
    en: 'Consult Type'
  },
  {
    es: 'Requiere Permiso consulte',
    en: 'Permit Required'
  },
  {
    es: 'Tramite Especial - consulte',
    en: 'Special Permit'
  },
  {
    es: 'Mercancía peligrosa - consulte',
    en: 'Dangerous Goods'
  },
  {
    es: 'Buen Producto Para Exonerar :)',
    en: 'Good Product To Exonerate :)'
  },
  {
    es: 'Pagar Compra Corporativa',
    en: 'Pay Corporate Purchase'
  },
  {
    es: 'Compra Corporativa',
    en: 'Corporate Purchase'
  },
  {
    es: 'Este paquete no puede ser programado contraentrega',
    en: 'This package cannot be scheduled cash on delivery'
  },
  {
    es: 'Consulte a Servicio al cliente para factura y pago de este paquete.',
    en: 'Consult Customer Service for invoice and payment of this package.'
  },
  {
    es: 'SuperRápido (Aéreo)',
    en: 'Air Freight'
  },
  {
    es: 'SuperEconómico (Marítimo)',
    en: 'Sea Freight'
  },
  {
    es: 'Resultados',
    en: 'Results'
  },
  {
    es: 'Hora de Entrega',
    en: 'Delivery Time'
  },
  {
    es: 'Su pago esta siendo validado',
    en: 'Your payment is being validated'
  },
  {
    es: 'Recordá enviar el comprobante de pago por WhatsApp +(506) 6037-6729 para validar su pago. Si ya adjunto su comprobante el mismo se vera reflejado luego de validar su pago.',
    en: 'Remember to send the proof of payment by WhatsApp +(506) 6037-6729 to validate your payment. If I have already attached your receipt, it will be reflected after validating your payment.'
  },
  {
    es: 'Tiene paquetes antiguos por cancelar. Debe cancelar los más antiguos antes de cancelar los mas nuevos.',
    en: 'You have old packages to cancel. You must cancel the oldest ones before canceling the newest ones.'
  },
  {
    es: 'Verifique los datos de su Perfil y de entrega. Debe completar su perfil para poder usar el sitio.',
    en: 'Verify your Profile and delivery data. You must complete your profile in order to use the site.'
  },
  {
    es: 'Para usar el sitio, actualice sus datos personales y la dirección de entrega.',
    en: 'To use the site, update your personal information and delivery address.'
  },
  {
    es: 'Revise su correo electrónico para restablecer la contraseña.',
    en: 'Check your email to reset your password.'
  },
  {
    es: 'Acepto y estoy de acuerdo con el cumplimiento de los términos y condiciones estipuladas dentro del ',
    en: 'I accept and agree to comply with the terms and conditions stipulated within the '
  },
  {
    es: 'Acuerdo de Servicio.',
    en: 'Service Agreement.'
  },
  {
    es: 'No se puede mostrar el archivo PDF.',
    en: 'Unable to display PDF file.'
  },
  {
    es: 'Descargar',
    en: 'Download'
  },
  {
    es: 'Cambiar Contraseña',
    en: 'Change Password'
  },
  {
    es: 'Recuerde contactar a Servicio al Cliente para cualquier gestión relacionada con paquetes de la antigua plataforma.',
    en: 'Remember to contact Customer Service for any management related to packages from the old platform.'
  },
  {
    es: 'Arrastre o seleccione su archivo aquí',
    en: 'Drag or select your files here'
  },
  {
    es: 'Pago Electrónico Deshabilitado',
    en: 'Electronic Payment Disabled'
  },
  {
    es: 'Nombre del Contacto',
    en: 'Contact name'
  },
  {
    es: 'El Nombre del Contacto es',
    en: 'The contact name is'
  },
  {
    es: 'No se puede consolidar estos paquetes. Comuníquese al WhatsApp 6037-6729 o por correo hola@superboxcr.com para mas información.',
    en: 'These packages cannot be consolidated. Contact WhatsApp 6037-6729 or by email hola@superboxcr.com for more information.'
  },
  {
    es: 'El banco está presentando inconvenientes con el procesamiento de pagos en linea. Por el momento favor seleccionar el método de pago entre SINPE/transferencia o contra entrega con tarjeta.',
    en: 'The bank is having issues with online payment processing. For the moment please select the payment method between SINPE/transfer or cash on delivery by card.'
  },
  {
    es: 'Esta cédula ya existe.',
    en: 'This identification already exists'
  },
  {
    es: 'Verifique o modifique su dirección de entrega.',
    en: 'Check or change your delivery address.'
  },
  {
    es: 'Pago Electrónico',
    en: 'Online Payment'
  },
  {
    es: 'Transferencia/SINPE',
    en: 'Bank Transfer/SINPE'
  },
  {
    es: 'Contra Entrega',
    en: 'Upon Delivery'
  },
  {
    es: 'Pagar y Coordinar Paquete',
    en: 'Pay and Scheduled'
  },
  {
    es: 'Pagar y Coordinar Paquetes',
    en: 'Pay and Scheduled'
  },
  {
    es: 'Confirma que desea coordinar esta entrega para el día ',
    en: 'Confirm that you want to schedule this delivery for the day '
  },
  {
    es: ' esta guia se encuentra en embarque.',
    en: ' this guide are in a shipment.'
  },
  {
    es: '',
    en: ''
  },















];
