import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const user = this.authService.getUser();

    if (user != null) {

      const roles = route.data.roles;
      //console.log('Roles', user.rol);

      this.authService.activeRol = String(user.rol);
      this.authService.setRol();

      if (typeof roles !== 'undefined') {
        if (user.rol.includes('ADMIN')) {
          this.authService.activeRol = 'ADMIN';
          this.authService.setRol();
          return true;

        } else if (user.rol.includes('ALMAC')) {
          this.authService.activeRol = 'ALMAC';
          this.authService.setRol();
          return true;

        } else if (user.rol.includes('TRANSP')) {
          this.authService.activeRol = 'TRANSP';
          this.authService.setRol();
          return true;

        } else if (user.rol.includes('VEND')) {
          this.authService.activeRol = 'VEND';
          this.authService.setRol();
          return true;

        } else if (user.rol.includes('SERVCLI')) {
          this.authService.activeRol = 'SERVCLI';
          this.authService.setRol();
          return true;

        } else if (user.rol.includes('OPCR')) {
          this.authService.activeRol = user.rol.includes('OPCR2') ? 'OPCR2' : 'OPCR';
          this.authService.setRol();
          return true;

        } else if (user.rol.includes('PEDIM')) {
          this.authService.activeRol = 'PEDIM';
          this.authService.setRol();
          return true;

        } else if (user.rol.includes('OPMIA')) {
          this.authService.activeRol = 'OPMIA';
          this.authService.setRol();
          return true;

        } else if (user.rol.includes('CONTAB')) {
          this.authService.activeRol = 'CONTAB';
          this.authService.setRol();
          return true;

        } else {
          this.authService.activeRol = 'CLIENT';
          this.authService.setRol();
          this.router.navigate(['/dashboard']);
          return false;

        }
      }

      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }// end canActivate
}// end AuthGuard class
