import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsuariosService } from '../../../../services/usuarios.service';

@Component({
  selector: 'app-guias',
  templateUrl: './guias.component.html',
  styleUrls: ['./guias.component.scss']
})
export class GuiasComponent implements OnInit {
  guias;

  displayedColumns = ['seleccionar', 'cuenta', 'guia', 'tracking', 'descripcion', 'modificado'];

  constructor(
    public dialogRef: MatDialogRef<GuiasComponent>,
    public usuariosService: UsuariosService,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) { }

  ngOnInit(): void {
    this.guias = this.data.guias;
    console.log(this.guias);
  }

  close(index){
    this.dialogRef.close(index);
  }

}
