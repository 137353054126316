import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-acciones',
  templateUrl: './acciones.component.html',
  styleUrls: ['./acciones.component.scss']
})
export class AccionesComponent implements OnInit {

  ngOnInit() {
  }

  constructor() { }
}

