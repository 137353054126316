import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxPaginationModule } from 'ngx-pagination';
import { MaterialModule } from './material.module';
import { NumericDirective } from './shared/pipes/numeric.pipe';
import { PostAuthComponent } from './components/postauth/postauth.component';
import { QRCodeModule } from 'angular2-qrcode';
import { AgmCoreModule } from '@agm/core';
import { TranslatePipe } from './shared/pipes/translate.pipe';
import { MainPackageGuidePipe, MainPackageIdPipe } from './shared/pipes/paquete.pipe';
import { FileDragNDropDirective } from './shared/dragdrop.directive';
import { ResultComponent } from './components/result/result.component';
import { TableModule } from 'primeng/table';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    NumericDirective,
    PostAuthComponent,
    TranslatePipe,
    MainPackageIdPipe,
    MainPackageGuidePipe,
    FileDragNDropDirective,
    ResultComponent
  ],
  imports: [
    QRCodeModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    RouterModule,
    CarouselModule,
    TabsModule,
    NgxPaginationModule,
    AgmCoreModule,
    TableModule,
  ],
  exports: [
    QRCodeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    RouterModule,
    CarouselModule,
    TabsModule,
    NgxPaginationModule,
    LoginComponent,
    RegisterComponent,
    ResultComponent,
    NumericDirective,
    PostAuthComponent,
    TableModule,
    TranslatePipe,
    MainPackageGuidePipe,
    MainPackageIdPipe,
    FileDragNDropDirective,
    NgSelectModule,
  ]
})
export class GlobalModule { }
