<div class="pt-3 pb-3" style="display: flex; align-items: center; justify-content: space-between;">
  <div style="width: 100%;">
    <button mat-button [routerLink]="['/admin/almacen/entregas']"
      style="padding: 0; padding-right: 10px; color: #0094a3; float: left;">
      <mat-icon>keyboard_arrow_left</mat-icon> ENTREGAS
    </button>
  </div>
</div>

<div class="content" fxLayout="column">

  <div class="col-12 p-4"
    style="margin-left: auto; margin-right: auto; width: auto;  box-shadow: 0px 8px 25px rgba(0, 148, 163, 0.12);">
    <h4>Entregas del día</h4>

    <div class="d-block">
      <ng-container *ngFor="let driver of drivers">
        <mat-card style="cursor: pointer;" [routerLink]="['/admin/almacen/entregas/agregar']" [queryParams]="{pack: getSummaryEntregaID(driver.id)}" *ngIf="getSummaryEntregaID(driver.id) != 0" class="mt-2 mx-2 d-block d-md-inline-block">
          <mat-card-header>
            <mat-icon mat-card-avatar style="color:purple">local_shipping</mat-icon>
            {{driver.nombre}} <br>
            Entregados/Total : {{getSummary(driver.id)}}
          </mat-card-header>
        </mat-card>

        <mat-card style="cursor: pointer;" [routerLink]="['/admin/almacen/entregas/agregar']" [queryParams]="{conductor: driver.id}" *ngIf="getSummaryEntregaID(driver.id) == 0" class="mt-2 mx-2 d-block d-md-inline-block">
          <mat-card-header>
            <mat-icon mat-card-avatar style="color:purple">local_shipping</mat-icon>
            {{driver.nombre}} <br>
            Entregados/Total : {{getSummary(driver.id)}}
          </mat-card-header>

        </mat-card>
      </ng-container>

    </div>

  </div>

  <div class="content-table m-4">
    <table mat-table [dataSource]="dataSource | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems: length }" matSort (matSortChange)="sortData($event)">

      <ng-container matColumnDef="id">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Entrega Nro. </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <a [routerLink]="['/admin/almacen/entregas/agregar']" [queryParams]="{pack: element.id}">
            {{element.id ? element.id : '-'}}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.date | date:'dd-MM-yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="conductor">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Conductor </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.conductor}} </td>
      </ng-container>

      <ng-container matColumnDef="paquetes">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Paquetes </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.paquetes}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }"></tr>
    </table>

    <div class="text-center pt-2" *ngIf="dataSource.length == 0"> No hay entregas </div>
  </div>

  <mat-divider></mat-divider>

  <div class="paginator text-center">
    <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior" nextLabel="Siguiente" (pageBoundsCorrection)="pageIndex = $event; paginate(pageIndex)" (pageChange)="pageIndex = $event; paginate(pageIndex)"></pagination-controls>
  </div>
</div>

<table style="display: none;" mat-table [dataSource]="dataSourceEntregas" id="entregas">

  <ng-container matColumnDef="guia">
    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Guia</th>
    <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.numero_de_guia}} </td>
  </ng-container>

  <ng-container matColumnDef="cliente">
    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Cliente </th>
    <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
      {{usersService.get_fullname(element.usuario)}} </td>
  </ng-container>


  <ng-container matColumnDef="direccion">
    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Dirección </th>
    <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.direccion_entrega}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumnsEntregas; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsEntregas;"></tr>

</table>
