/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-arrow/prefer-arrow-functions */

import { AuthService } from 'src/app/services/auth.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { GeneralDialogComponent } from '../../../../common/dialogs/general-dialog/general-dialog.component';
import { EmbarquesService } from 'src/app/services/embarques.service';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { UsuariosService } from 'src/app/services/usuarios.service';


@Component({
  selector: 'app-capturar-embarques-admin',
  templateUrl: './capturar-embarques-admin.component.html',
  styleUrls: ['./capturar-embarques-admin.component.scss']
})
export class CapturarEmbarquesAdminComponent implements OnInit {
  @ViewChild('listapaquetes') selectionList: MatSelectionList;

  embarques: any = [];
  selectedEmbarque;
  paquetes: any = [];
  selectedAction: any;
  tracking = '';

  numero_de_guia = '';
  descripcion = '';

  qrNoExiste = false;

  // eslint-disable-next-line max-len
  displayedColumns: string[] = ['escaneado', 'tracking', 'guia', 'tipo_paquete','peso_real', 'peso_volumetrico'];
  dataSource = new MatTableDataSource<any>();

  fontSize = 16;

  constructor(
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private embarquesService: EmbarquesService,
    private datePipe: DatePipe,
    public usuariosService: UsuariosService,
    private elRef: ElementRef,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.getEmbarques();


    this.fontSize = this.authService.isMiami ? 20 : 16;
    this.elRef.nativeElement.style.setProperty('--font-size', this.fontSize + 'px');
  }

  checkedEmbarque(event, element) {
    if (element.entrega.escaneado_distribucion) {
      event.preventDefault();
    } else {
      this.numero_de_guia = element.numero_de_guia;
    }

  }

  changeSize(fontSize) {
    this.fontSize = fontSize;
    this.elRef.nativeElement.style.setProperty('--font-size', this.fontSize + 'px');
  }

  playAudioWrong() {
    const audio = new Audio();
    audio.src = '../../../assets/wrong.wav';
    audio.load();
    audio.play();
  }

  playAudioCorrect() {
    const audio = new Audio();
    audio.src = '../../../assets/correct.wav';
    audio.load();
    audio.play();
  }

  // Busqueda Manual
  findByManualInput(guia) {
    guia = String(guia).toLocaleLowerCase();
    this.numero_de_guia = guia;

    const filter = this.paquetes.filter(function (el) {
      return (el.numero_de_guia.toLocaleLowerCase().includes(guia.toLocaleLowerCase()));
    });

    //console.log(guia, filter);

    if (filter.length > 0) {
      if (filter[0].clase != 'D') {
        const i = this.paquetes.map(function (x) {
          return x.id;
        }).indexOf(filter[0].id);

        this.selectionList.options.find(function (el) {
          return (el.value.numero_de_guia.toLocaleLowerCase().includes(guia));
        })._setSelected(true);


        this.paquetes[i].entrega.escaneado_distribucion = true;
        //this.procesarPaquetes(false);
        this.embarquesService.capturarPaqueteEmbarque(this.selectedEmbarque.id, { numero_de_guia: filter[0].numero_de_guia }).subscribe((res: any) => {
          console.log(res);
          this.playAudioCorrect();
        });

        this.paquetes.sort((a, b) => (a.entrega.escaneado_distribucion > b.entrega.escaneado_distribucion) ? 1 : ((b.entrega.escaneado_distribucion > a.entrega.escaneado_distribucion) ? -1 : 0));
      }

    } else {
      if (filter.length === 0) {
        this.qrNoExiste = true;
        this.playAudioWrong();
        setTimeout(() => {
          this.qrNoExiste = false;
        }, 5000);
      }
    }
    this.numero_de_guia = '';

  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  changeSelect(event) {
    console.log(event);

    this.spinner.show();

    this.embarquesService.getSimpleById(event.value).subscribe((embarque: any) => {
      this.selectedEmbarque = {
        awb: embarque.awb_bl,
        charge_weight: embarque.charge_weight,
        refdate: this.datePipe.transform(new Date(embarque.date), 'dd-MM-yyyy'),
        file: embarque.file_a,
        vessel: embarque.travel_identifier,
        gross_weight: embarque.gross_weight,
        id: embarque.id,
        notes: embarque.notes,
        packages: embarque.total_packages,
        pieces: embarque.pieces,
        scanned: embarque.scanned_packages,
        services: embarque.travel_type,
        status: embarque.status,
        bultos: embarque.total_charge,
        total: embarque.total,
        creation: this.datePipe.transform(new Date(embarque.created_at), 'dd-MM-yyyy HH:mm'),
        full: embarque.paquetes
      };

      this.paquetes = this.selectedEmbarque.full.paquetes;

      // eslint-disable-next-line max-len
      this.paquetes.sort((a, b) => (a.escaneado_distribucion > b.escaneado_distribucion) ? 1 : ((b.escaneado_distribucion > a.escaneado_distribucion) ? -1 : 0));

      this.paquetes.forEach(element => {
        const peso_str = element.largo + '" x ' + element.ancho + '" x ' + element.alto + '"';
        element.dimensions = peso_str;
      });

      this.dataSource.data = this.paquetes;

      setTimeout(() => {
        this.paquetes.forEach(element => {
          if (element.entrega.escaneado_distribucion) {
            this.selectionList.options.find(function (el) {
              return el.value.numero_de_guia === element.numero_de_guia;
            })._setSelected(true);

          }
        });
        this.spinner.hide();

      }, 1000);
    });


  }

  public getEmbarques() {
    this.spinner.show();
    this.embarquesService.getClose().subscribe((res: any) => {
      this.embarques = res;

      if (res.length > 0) {

        //console.log(this.embarques);

        this.selectedAction = this.embarques[0].id;
        this.spinner.show();
        this.embarquesService.getSimpleById(this.selectedAction).subscribe((embarque: any) => {
          this.selectedEmbarque = {
            awb: embarque.awb_bl,
            charge_weight: embarque.charge_weight,
            refdate: this.datePipe.transform(new Date(embarque.date), 'dd-MM-yyyy'),
            file: embarque.file_a,
            vessel: embarque.travel_identifier,
            gross_weight: embarque.gross_weight,
            id: embarque.id,
            notes: embarque.notes,
            packages: embarque.total_packages,
            pieces: embarque.pieces,
            scanned: embarque.scanned_packages,
            services: embarque.travel_type,
            status: embarque.status,
            bultos: embarque.total_charge,
            total: embarque.total,
            creation: this.datePipe.transform(new Date(embarque.created_at), 'dd-MM-yyyy HH:mm'),
            full: embarque.paquetes
          };

          this.paquetes = this.selectedEmbarque.full.paquetes;

          // eslint-disable-next-line max-len
          this.paquetes.sort((a, b) => (a.escaneado_distribucion > b.escaneado_distribucion) ? 1 : ((b.escaneado_distribucion > a.escaneado_distribucion) ? -1 : 0));

          this.paquetes.forEach(element => {
            const peso_str = element.largo + '" x ' + element.ancho + '" x ' + element.alto + '"';
            element.dimensions = peso_str;
          });

          this.dataSource.data = this.paquetes;

          setTimeout(() => {
            this.paquetes.forEach(element => {
              if (element.entrega.escaneado_distribucion) {
                this.selectionList.options.find(function (el) {
                  return el.value.numero_de_guia === element.numero_de_guia;
                })._setSelected(true);

              }
            });
            this.spinner.hide();

          }, 1000);
        });
      }
    });
  }

  changeEmbarque(embarque) {
    //console.log(embarque);
    this.selectedEmbarque = embarque;
    this.paquetes = embarque.full;

    this.dataSource.data = this.paquetes;

    setTimeout(() => {
      this.paquetes.forEach(element => {
        if (element.entrega.escaneado_distribucion) {

          this.selectionList.options.find(function (el) {
            return el.value.numero_de_guia === element.numero_de_guia;
          })._setSelected(true);

        }
      });
      //this.spinner.hide();

    }, 1000);
  }

  procesarPaquetes(showConfirm = true) {
    //console.log('Procesar paquetes');
    const items = [];

    this.selectionList.selectedOptions.selected.forEach((element: any) => {
      if (element.clase != 'D') {
        //console.log(element.value.id);
        items.push(element.value.id);
      }
    });

    const embarque = {
      descripcion: this.datePipe.transform(new Date(), 'dd-MM-yyy') + ': ' + this.descripcion,
      paquetes: items,
      scanned_packages: this.selectionList.selectedOptions.selected.length,
      total_packages: this.paquetes.length,
    };

    if (this.descripcion.length === 0) {
      delete embarque.descripcion;
    }

    this.embarquesService.capturarEmbarquesAdmin(this.selectedEmbarque.id, embarque).subscribe((res: any) => {
      //console.log(res);
      if (res.status && showConfirm) {
        this.openDialog('Embarque Actualizado');
      }
    });

  }
}
