/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { GeneralDialogComponent } from '../../../../common/dialogs/general-dialog/general-dialog.component';
import { TiposDeCambioService } from '../../../../../services/tipos-de-cambio.service';
import { UsuariosService } from '../../../../../services/usuarios.service';

@Component({
  selector: 'app-add-currency-rate',
  templateUrl: './add-currency-rate.component.html',
  styleUrls: ['./add-currency-rate.component.scss']
})
export class AddCurrencyRateComponent implements OnInit {

  form = this.fb.group({
    // Necesarios para el modelo
    value: ['', [Validators.required, Validators.min(0)]],
    created_by: [this.usuariosService.usuario.id]
  });

  constructor(
    private fb: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    public tiposDeCambioService: TiposDeCambioService,
    public usuariosService: UsuariosService,
    private dialog: MatDialog
  ) { }


  ngOnInit() {
    this.tiposDeCambioService.getRate().subscribe((data: any) => {
      //console.log(data);
      this.form.controls.value.setValue(data.rates.CRC.toFixed(2));
    });
  }

  public create() {
    if (this.form.valid) {
      this.spinnerService.show();
      this.tiposDeCambioService.post(this.form.value).subscribe(
        (data: any) => {
          this.spinnerService.hide();
          this.router.navigate(['/admin/otros/']);
          this.openDialog('Tipo de cambio Creado Exitosamente');
        }, err => {
          this.openDialog('Ocurrio un error');
          this.spinnerService.hide();
          console.error(err);
        }
      );
    }
  }

  public getErrorMessage(control) {
    if (control.hasError('required')) {
      return 'Campo necesario';
    }
    if (control.hasError('minlength')) {
      return 'Demasiado Corto';
    }
    if (control.hasError('maxlength')) {
      return 'Demasiado Largo';
    }
    if (control.hasError('pattern')) {
      return 'Formato ínvalido';
    }
    if (control.hasError('min')) {
      return 'Límite mínimo';
    }
    return 'Error';
  }
  private openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }

}
