<div class="container-example" fxLayout="column">
  <h2>Productos Calculadora</h2>
  <div fxLayout="row wrap">
    <button mat-button>
      <mat-icon>add</mat-icon>Agregar tarifa de producto
    </button>
    <button mat-button>
      <mat-icon>filter_list</mat-icon>Refinar búsqueda
    </button>
  </div>
  <mat-divider></mat-divider>
  <div fxLayoutAlign="end">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

      <!-- descripcion Column -->
      <ng-container matColumnDef="descripcion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
        <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
      </ng-container>

      <!-- id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Identificador </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <!-- porcentaje Column -->
      <ng-container matColumnDef="porcentaje">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Porcentaje </th>
        <td mat-cell *matCellDef="let element"> {{element.porcentaje}} </td>
      </ng-container>

      <!-- advertencia Column -->
      <ng-container matColumnDef="advertencia">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Advertencia </th>
        <td mat-cell *matCellDef="let element"> {{element.advertencia}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>
</div>