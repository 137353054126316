import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { NewPackageComponent } from '../../components/newpackage/newpackage.component';

@Component({
  selector: 'app-buscar',
  templateUrl: './buscar.component.html',
  styleUrls: ['./buscar.component.scss']
})
export class BuscarComponent implements OnInit {

  cuentas = [];
  paquetes = [];
  autorizados = [];

  displayedColumnsClientes: string[] = ['cuenta', 'nombre', 'delete_date', 'delete_by'];
  displayedColumnsPaquetes: string[] = ['numero_de_guia', 'tracking', 'descripcion', 'nombre', 'zona', 'embarque', 'ultima_actualizacion'];
  displayedColumnsAutorizados: string[] = ['nombre', 'cuenta'];

  constructor(
    public authService: AuthService,
    public usuariosService: UsuariosService,
    private activatedRoute: ActivatedRoute,
    private paquetesService: PaquetesService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      //console.log('params buscar',params);
      if (params.search) {


        this.usuariosService.search(params.search).subscribe((res: any) => {
          this.cuentas = res;
        });

        this.paquetesService.search(params.search).subscribe((res: any) => {

          const values = [];

          res.forEach(element => {
            //console.log(element.paquete_principal.length)
            if (element.paquete_principal.length > 0) {
              values.push(element);
            } else {
              values.push(element);
            }
          });
          this.paquetes = values;
        });

        this.authService.searchAuthorizations(params.search).subscribe((res: any) => {

          this.autorizados = res.body;
        });

      }
    });
  }

  openDialogNuevoPaquete(tracking, isNew, id, paquete?) {
    //console.log('open new package', tracking, isNew, id);
    const dialogRef = this.dialog.open(NewPackageComponent, {
      width: '90%',
      panelClass: 'newpackage',
      data: { tracking, isNew, id, consolidar: false, paquete },
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

}
