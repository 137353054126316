import { UsuariosService } from 'src/app/services/usuarios.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';


@Component({
  selector: 'app-index-alianza',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexAlianzaComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ['id', 'name'];
  dataSource = new MatTableDataSource<any>();
  length = 0;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private usuariosService: UsuariosService
  ) { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getAlianzas();
  }

  public getAlianzas() {

    this.usuariosService.getAlianzas().subscribe((data: any) => {
      this.length = data.count;
      this.dataSource.data = data.results;
    });
  }

  crearAlianza() {
    this.router.navigate(['administracion/alianzas/alianza']);
  }

  disableAlianza(id){

  }
}
