import { FacturasService } from './../../../../../services/facturas.service';
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { startWith } from 'rxjs/internal/operators/startWith';
import { ReportService } from '../../../../../services/reports.service';
import { UsuariosService } from '../../../../../services/usuarios.service';
import { GeneralDialogComponent } from '../../../../common/dialogs/general-dialog/general-dialog.component';
import { GeneralInputComponent } from '../../../../common/dialogs/general-input/general-input.component';
import { NavigationService } from 'src/app/services/navigation.service';
import { PaquetesService } from '../../../../../services/paquetes.service';
import { CurrencyPipe } from '@angular/common';
import { AuthService } from '../../../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FacturaElectronicaService } from '../../../../../services/factura_electronica.service';

export interface ClienteElement {
  id: number;
  name: string;
}

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss']
})

export class ReportViewerComponent implements OnInit {
  @ViewChild('myTable') table: any;

  length = 0;
  pageSize = 50;
  pageIndex = 1;

  reportsForm: any;

  displayedColumns;
  dataSource;
  totals;

  groupByColumns = '';

  reports;

  selectedReport;

  filteredOptions: Observable<ClienteElement[]>;

  metodosPago;

  provincias;
  cantones;

  estadosPaquetes;

  enableSummary = true;
  summaryPosition = 'bottom';

  rowGroupMetadata: any;

  alianzas;

  timeout: any = null;

  user = JSON.parse(localStorage.getItem('user'));

  constructor(
    public usuariosService: UsuariosService,
    private reportsService: ReportService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private navigation: NavigationService,
    private paquetesService: PaquetesService,
    private currencyPipe: CurrencyPipe,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private facturasService: FacturasService,
    private facturaElectronicaService: FacturaElectronicaService,

  ) { }

  ngOnInit(): void {
    this.reportsForm = this.fb.group({
      report: [''],
      start: [new Date().toISOString().split('T')[0]],
      end: [new Date().toISOString().split('T')[0]],
      user: [''],
      page: [1],
      sucursal: ['-1'],
      metodo: ['-1'],
      generada: ['0'],
      estado: ['-1'],
      alianza: [''],
      nota: ['-1'],
      filterBy: ['1']
    });

    switch (this.authService.activeRol) {
      case 'ADMIN':
        this.reports = [
          { value: 'mensual', viewValue: 'Mensual', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'estadistica', viewValue: 'Estadística', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: true, alianza: false, nota: false },
          { value: 'descuentos', viewValue: 'Descuentos', cuenta: false, total: true, sucursal: false, metodo: false, generada: false, status: false, custom: true, alianza: false, nota: false },
          { value: 'sucursal', viewValue: 'Sucursal', cuenta: false, total: true, sucursal: true, metodo: false, generada: false, status: false, custom: true, alianza: false, nota: false },
          { value: 'estatus', viewValue: 'Estatus', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: true, custom: true, alianza: false, nota: false },
          { value: 'whatsapp', viewValue: 'Contactos WhatsApp', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'no-pagos-entregados', viewValue: 'Paquetes no pagos y entregados', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false, noPagosEntregados: true },
          { value: 'no-entregados-pagos', viewValue: 'Paquetes no entregados y pagos', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'compras-corporativas', viewValue: 'Compras Corporativas', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'antiguedad-saldos', viewValue: 'Antigüedad de Saldos', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: true, alianza: false, nota: false },
          { value: 'comparativo-fegti', viewValue: 'Comparativo FE / FE GTI', cuenta: false, total: false, sucursal: false, metodo: false, generada: true, status: false, custom: false, alianza: false, nota: false },
          { value: 'clased', viewValue: 'Clase D', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'vendedores', viewValue: 'Vendedores', cuenta: true, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'metodos-pagos', viewValue: 'Métodos de pago', cuenta: false, total: false, sucursal: false, metodo: true, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'notas', viewValue: 'Notas de Crédito/Debito', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: true },
          { value: 'alianzas', viewValue: 'Alianzas', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: true, nota: false },
          { value: 'pendientes', viewValue: 'Contratos por Aceptar', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'frecuencia-compra', viewValue: 'Frecuencia de Compra', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'neted', viewValue: 'Paquetes neteados' },
          { value: 'abandoned', viewValue: 'Paquetes en abandono' }

          // { value: 'recomendados', viewValue: 'Recomendados', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false  , nota:false},
          // { value: 'embarque', viewValue: 'Rentabilidad Embarque', cuenta: false, sucursal: false, metodo: false, generada: false, status: false, custom: false , nota:false },
          //
        ];
        break;
      case 'ALMAC':
        this.reports = [
          { value: 'estadistica', viewValue: 'Estadística', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: true, alianza: false, nota: false },
          { value: 'descuentos', viewValue: 'Descuentos', cuenta: false, total: true, sucursal: false, metodo: false, generada: false, status: false, custom: true, alianza: false, nota: false },
          { value: 'sucursal', viewValue: 'Sucursal', cuenta: false, total: true, sucursal: true, metodo: false, generada: false, status: false, custom: true, alianza: false, nota: false },
          { value: 'estatus', viewValue: 'Estatus', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: true, custom: true, alianza: false, nota: false },
          { value: 'whatsapp', viewValue: 'Contactos WhatsApp', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'no-pagos-entregados', viewValue: 'Paquetes no pagos y entregados', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'no-entregados-pagos', viewValue: 'Paquetes no entregados y pagos', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'compras-corporativas', viewValue: 'Compras Corporativas', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'antiguedad-saldos', viewValue: 'Antigüedad de Saldos', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: true, alianza: false, nota: false },
          // { value: 'comparativo-fegti', viewValue: 'Comparativo FE / FE GTI', cuenta: false, total: false, sucursal: false, metodo: false, generada: true, status: false, custom: false, alianza: false, nota:false },
          { value: 'clased', viewValue: 'Clase D', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'vendedores', viewValue: 'Vendedores', cuenta: true, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'metodos-pagos', viewValue: 'Métodos de pago', cuenta: false, total: false, sucursal: false, metodo: true, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'notas', viewValue: 'Notas de Crédito/Debito', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: true },
          { value: 'alianzas', viewValue: 'Alianzas', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: true, nota: false },

          // { value: 'recomendados', viewValue: 'Recomendados', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false  , nota:false},
          // { value: 'embarque', viewValue: 'Rentabilidad Embarque', cuenta: false, sucursal: false, metodo: false, generada: false, status: false, custom: false  , nota:false},
          //
        ];

        break;
      case 'SERVCLI':
        this.reports = [
          { value: 'antiguedad-saldos', viewValue: 'Antigüedad de Saldos', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: true, alianza: false, nota: false },
          { value: 'compras-corporativas', viewValue: 'Compras Corporativas', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'estatus', viewValue: 'Estatus', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: true, custom: true, alianza: false, nota: false },
          { value: 'no-entregados-pagos', viewValue: 'Paquetes no entregados y pagos', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'no-pagos-entregados', viewValue: 'Paquetes no pagos y entregados', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
        ];
        break;

      case 'PEDIM':
        this.reports = [];
        break;

      case 'OPMIA':
        this.reports = [
          { value: 'compras-corporativas', viewValue: 'Compras Corporativas', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'clased', viewValue: 'Clase D', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'estatus', viewValue: 'Estatus', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: true, custom: true, alianza: false, nota: false },
        ];
        break;

      case 'OPCR':
        this.reports = [
          { value: 'estatus', viewValue: 'Estatus', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: true, custom: true, alianza: false, nota: false },
          { value: 'no-pagos-entregados', viewValue: 'Paquetes no pagos y entregados', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'no-entregados-pagos', viewValue: 'Paquetes no entregados y pagos', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'clased', viewValue: 'Clase D', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'metodos-pagos', viewValue: 'Métodos de pago', cuenta: false, total: false, sucursal: false, metodo: true, generada: false, status: false, custom: false, alianza: false, nota: false },
        ];
        break;
      case 'CONTAB':
        let contaAccount = this.authService.getUser().cuenta;
        this.reports = [
          { value: 'descuentos', viewValue: 'Descuentos', cuenta: false, total: true, sucursal: false, metodo: false, generada: false, status: false, custom: true, alianza: false, nota: false },
          { value: 'compras-corporativas', viewValue: 'Compras Corporativas', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'antiguedad-saldos', viewValue: 'Antigüedad de Saldos', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: true, alianza: false, nota: false },
          { value: 'comparativo-fegti', viewValue: 'Comparativo FE / FE GTI', cuenta: false, total: false, sucursal: false, metodo: false, generada: true, status: false, custom: false, alianza: false, nota: false },
          { value: 'clased', viewValue: 'Clase D', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'metodos-pagos', viewValue: 'Métodos de pago', cuenta: false, total: false, sucursal: false, metodo: true, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'notas', viewValue: 'Notas de Crédito/Debito', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: true },
          // { value: 'recomendados', viewValue: 'Recomendados', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false  , nota:false},
          // { value: 'embarque', viewValue: 'Rentabilidad Embarque', cuenta: false, sucursal: false, metodo: false, generada: false, status: false, custom: false , nota:false },
        ];

        if (contaAccount === 'SB006198') { //SB-134: Only for this account
          this.reports.unshift({ value: 'mensual', viewValue: 'Mensual', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false });
        }
        break;
      case 'OPCR2':
        this.reports = [
          { value: 'no-pagos-entregados', viewValue: 'Paquetes no pagos y entregados', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false, noPagosEntregados: true },
          { value: 'no-entregados-pagos', viewValue: 'Paquetes no entregados y pagos', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'comparativo-fegti', viewValue: 'Comparativo FE / FE GTI', cuenta: false, total: false, sucursal: false, metodo: false, generada: true, status: false, custom: false, alianza: false, nota: false },
          { value: 'clased', viewValue: 'Clase D', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'metodos-pagos', viewValue: 'Métodos de pago', cuenta: false, total: false, sucursal: false, metodo: true, generada: false, status: false, custom: false, alianza: false, nota: false },
          { value: 'notas', viewValue: 'Notas de Crédito/Debito', cuenta: false, total: false, sucursal: false, metodo: false, generada: false, status: false, custom: false, alianza: false, nota: true },
        ];
        break;
      default:
        break;
    }

    this.getMetodosPago().then(() => {
      this.getAlianzas().then(() => {
        this.setFilters();
      });

    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.selectedReport) {
        this.selectedReport = this.reports.filter(e => e.value === params.selectedReport)[0];
        //console.log(this.selectedReport);
        this.reportsForm.get('report').setValue(params.selectedReport);
        this.procesarReporte();
      }
    });
  }


  buscarListaClientes(event = { keyCode: 13 }, cuenta = null) {

    clearTimeout(this.timeout);
    const $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.usuariosService.search(cuenta).subscribe((res: any) => {
          const datos: ClienteElement[] = [];
          res.forEach(element => {
            // eslint-disable-next-line max-len
            datos.push({ id: element.id, name: $this.usuariosService.get_account_number(element) + '-' + $this.usuariosService.get_fullname(element) });
          });

          $this.filteredOptions = $this.reportsForm.controls.user.valueChanges.pipe(
            startWith(''),
            // eslint-disable-next-line no-underscore-dangle
            map(user => this._filter(user, datos)),
          );
        }
        );
      }
    }, 1000);
  }

  buscarListaVendedores() {
    this.usuariosService.get_seller_users().subscribe(
      (r: any) => {
        const datos: ClienteElement[] = [];
        r.forEach(element => {
          // eslint-disable-next-line max-len
          datos.push({ id: element.id, name: this.usuariosService.get_account_number(element) + '-' + this.usuariosService.get_fullname(element) });
        });

        this.filteredOptions = this.reportsForm.controls.user.valueChanges.pipe(
          startWith(''),
          // eslint-disable-next-line no-underscore-dangle
          map(user => this._filter(user, datos)),
        );
      }
    );
  }

  getMetodosPago() {
    return new Promise<void>((resolve, reject) => {
      this.usuariosService.getMetodosPago().subscribe(data => {
        this.metodosPago = data;
        resolve();
      });
    });

  }

  getEstadosPaquetes() {
    this.paquetesService.getEstadosPaquetes().subscribe((data: any) => {
      this.estadosPaquetes = data;
    });
  }

  getAlianzas() {
    return new Promise<void>((resolve, reject) => {
      this.usuariosService.getAlianzasAll().subscribe((res: any) => {
        this.alianzas = res;
        resolve();
      });
    });

  }

  procesarReporte(pageIndex = 1, all = false) {
    return new Promise((resolve, reject) => {
      this.spinner.show();

      if (this.reportsForm.controls.value !== '') {
        this.pageIndex = pageIndex;
        this.reportsForm.controls.page.setValue(this.pageIndex);
        this.groupByColumns = '';

        this.reportsService.getReports(this.reportsForm.value).subscribe((data: any) => {
          this.displayedColumns = data.columns;

          data.columns.forEach(column => {
            column.summaryFunc = cells => this.summaryRows(cells, column.total, column.total_value, column.currency);
            column.minWidth = 200;
          });

          if (this.selectedReport.cuenta || this.selectedReport.custom) {
            data.results.forEach(element => {
              element.usuario = JSON.parse(element.usuario);
            });
          }

          if (this.selectedReport.value === 'vendedores') {
            data.results.forEach(element => {
              element.vendedor = JSON.parse(element.vendedor);
            });
          }

          if (this.selectedReport.value === 'mensual') {
            if (all == true) {
              data.results.forEach(element => {
                element.cuenta = JSON.parse(element.cuenta);
              });
            }
          }

          if (this.selectedReport.value === 'frecuencia-compra') {
            data.results.forEach(element => {
              element.usuario = JSON.parse(element.usuario);
            });
          }

          if (this.selectedReport.value === 'descuentos') {
            // this.groupByColumns = 'codigo_promocional';
          }

          if (this.selectedReport.value === 'sucursal') {
            // this.groupByColumns = 'cuenta';
          }

          if (this.selectedReport.value === 'estatus') {
            // this.groupByColumns = 'cuenta';
          }

          if (this.selectedReport.value === 'vendedores') {
            // this.groupByColumns = 'vendedor';
          }

          if (this.selectedReport.value === 'no-pagos-entregados') {
            data.results.forEach(element => {
              element.checked = false;
            });
          }

          this.dataSource = data.results;
          this.totals = data.totals;

          // this.updateRowGroupMetaData();

          if (this.selectedReport.value === 'compras-corporativas') {
            this.dataSource.forEach(compra => {
              let totalPagado = 0;
              compra.factura.pago.forEach(pago => {
                totalPagado = totalPagado + parseFloat(pago.monto);
              });

              compra.totalPagado = totalPagado;
              compra.saldoPendiente = parseFloat(compra.total) - parseFloat(compra.totalPagado);
            });
          }

          if (this.selectedReport.value === 'estatus') {
            this.dataSource.forEach(element => {
              const guias = element.guias.split(',');
              const paquetes = element.paquetes_id.split(',');
              const fechas = element.estado_fecha.split(',');

              const paquete = [];
              for (let i = 0; i < guias.length; i++) {
                paquete.push({ id: paquetes[i], guia: guias[i], fecha: fechas[i] });
              }

              element.paquetes = paquete;
            });
          }

          if (this.selectedReport.value === 'descuentos') {
            this.dataSource.forEach(element => {
              const guias = element.guias.split(',');
              const paquetes = element.paquetes_id.split(',');
              const paquete = [];
              for (let i = 0; i < guias.length; i++) {
                paquete.push({ id: paquetes[i], guia: guias[i] });
              }

              element.paquetes = paquete;
            });
          }

          if (this.selectedReport.value === 'vendedores') {
            this.dataSource.forEach(element => {
              const guias = element.guias.split(',');
              const paquetes = element.paquetes_id.split(',');

              const paquete = [];
              for (let i = 0; i < guias.length; i++) {
                paquete.push({ id: paquetes[i], guia: guias[i] });
              }

              element.paquetes = paquete;
            });
          }

          this.length = data.count;
          this.pageSize = 50;

          if (this.length === 0) {
            this.openDialog('No se han encontrado datos');
          }

          resolve('ok');
          this.spinner.hide();

        }, (err) => {
          this.spinner.hide();
          this.openDialog('Ha ocurrido un error al obtener este reporte');
        });
      } else {
        this.openDialog('Debe seleccionar un reporte');
        resolve('ok');
        this.spinner.hide();
      }
    });
  }

  enviarCorreo() {


    const dialogRef = this.dialog.open(GeneralInputComponent, {
      data: { title: 'Correo', value: '', type: 'general' },
      height: '250px',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        const correo = result.value;
        this.spinner.show();
        this.reportsForm.controls.page.setValue(-1);
        this.reportsForm.value.correo = correo;

        this.reportsService.getReports(this.reportsForm.value).subscribe((data: any) => {
          this.reportsForm.controls.page.setValue(this.pageIndex);

          this.spinner.hide();
        }, err => {
          console.log(err);
          this.spinner.hide();
        });

      }
    });

  }

  export() {
    this.spinner.show();
    this.reportsForm.controls.page.setValue(-1);
    this.reportsService.getReportsBlob(this.reportsForm.value).subscribe((data: any) => {
      this.reportsForm.controls.page.setValue(this.pageIndex);

      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const url = window.URL.createObjectURL(blob);
      const pwa = window.open(url);
      if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert('Please disable your Pop-up blocker and try again.');
      }

      this.spinner.hide();
    }, err => {
      console.log(err);
      this.spinner.hide();
    });
  }

  clear() {
    this.reportsForm.reset();
    this.length = 0;
    this.dataSource = [];
    this.displayedColumns = [];
    this.clearFilters();
  }

  blank() {
    this.length = 0;
    this.dataSource = [];
    this.displayedColumns = [];
  }

  displayFn(value) {
    if (!value) {
      return '';
    }
    return value.name;
  }

  filterReport(value) {
    this.selectedReport = this.reports.filter(e => e.value === value)[0];

    if (this.selectedReport.cuenta) {
      if (value === 'vendedores') {
        this.buscarListaVendedores();
      }
    }

    if (value === 'metodos-pagos') {
      this.getMetodosPago();
    }

    if (value === 'estatus') {
      this.getEstadosPaquetes();
    }

    if (value === 'alianzas') {
      this.getAlianzas();
    }

    localStorage.setItem('filterReport', JSON.stringify(this.selectedReport));

  }

  saveFilters() {
    localStorage.setItem('reportBegin', this.reportsForm.value.start);
    localStorage.setItem('reportEnd', this.reportsForm.value.end);
    localStorage.setItem('reportUser', this.reportsForm.value.user);
    localStorage.setItem('reportPage', this.reportsForm.value.page);
    localStorage.setItem('reportSucursal', this.reportsForm.value.sucursal);
    localStorage.setItem('reportMetodo', this.reportsForm.value.metodo);
    localStorage.setItem('reportGenerada', this.reportsForm.value.generada);
    localStorage.setItem('reportEstado', this.reportsForm.value.estado);
    localStorage.setItem('reportAlianza', this.reportsForm.value.alianza);
    localStorage.setItem('reportNota', this.reportsForm.value.nota);

  }

  setFilters() {
    this.selectedReport = JSON.parse(localStorage.getItem('filterReport'));

    if (this.selectedReport) {
      this.reportsForm = this.fb.group({
        report: [this.selectedReport.value],
        start: [localStorage.getItem('reportBegin') ? localStorage.getItem('reportBegin') : new Date().toISOString().split('T')[0]],
        end: [localStorage.getItem('reportEnd') ? localStorage.getItem('reportEnd') : new Date().toISOString().split('T')[0]],
        user: [localStorage.getItem('reportUser') ? localStorage.getItem('reportUser') : ''],
        page: [localStorage.getItem('reportPage') ? Number(localStorage.getItem('reportPage')) : 1],
        sucursal: [localStorage.getItem('reportSucursal') ? localStorage.getItem('reportSucursal') : '-1'],
        metodo: [localStorage.getItem('reportMetodo') ? Number(localStorage.getItem('reportMetodo')) : '-1'],
        generada: [localStorage.getItem('reportGenerada') ? localStorage.getItem('reportGenerada') : '0'],
        estado: [localStorage.getItem('reportEstado') ? localStorage.getItem('reportEstado') : '-1'],
        alianza: [localStorage.getItem('reportAlianza') ? localStorage.getItem('reportAlianza') : ''],
        nota: [localStorage.getItem('reportNota') ? localStorage.getItem('reportNota') : '-1'],
        filterBy: ['1'],
      });
      this.filterReport(this.selectedReport.value);
    }
  }

  clearFilters() {
    localStorage.removeItem('filterReport');
    localStorage.removeItem('reportBegin');
    localStorage.removeItem('reportEnd');
    localStorage.removeItem('reportUser');
    localStorage.removeItem('reportPage');
    localStorage.removeItem('reportSucursal');
    localStorage.removeItem('reportMetodo');
    localStorage.removeItem('reportGenerada');
    localStorage.removeItem('reportEstado');
    localStorage.removeItem('reportAlianza');
    localStorage.removeItem('reportNota');
  }

  paginate(event: any) {
    if (this.selectedReport.value != 'mensual') {
      this.procesarReporte(this.pageIndex);
    }
  }


  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  back() {
    this.navigation.back();
  }

  public summaryRows(value, total, totalValue, currency) {

    if (total === true) {
      if (typeof totalValue === 'string' || totalValue instanceof String) {
        return totalValue;
      }

      const result = totalValue !== 0 ? totalValue : value.reduce((sum, a) => sum + a, 0);

      if (currency === true) {
        return this.currencyPipe.transform(result, 'CRC', 'symbol-narrow', '1.2-2');
      } else {
        return result;
      }

    } else {
      return '';
    }

  }

  public sumArray(data, key) {
    //console.log(data);
    return data.reduce((partialSum, a) => partialSum + a[key], 0);
  }

  public sumArrayCurrency(data, key) {
    //console.log(data);
    return data.reduce((partialSum, a) => partialSum + a[key] * (a.tipo_cambio ? a.tipo_cambio : 1), 0);
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};

    if (this.dataSource && this.groupByColumns.length > 0) {
      for (let i = 0; i < this.dataSource.length; i++) {
        const rowData = this.dataSource[i];
        const group = rowData[this.groupByColumns];

        //console.log(rowData);
        //console.log(group);

        if (i == 0) {
          this.rowGroupMetadata[group] = { index: 0, size: 1, data: [rowData] };
        } else {
          const previousRowData = this.dataSource[i - 1];
          const previousRowGroup = previousRowData[this.groupByColumns];

          if (group === previousRowGroup) {
            this.rowGroupMetadata[group].size++;
            this.rowGroupMetadata[group].data.push(rowData);
          } else {
            this.rowGroupMetadata[group] = { index: i, size: 1, data: [rowData] };
          }
        }
      }

      //console.log(this.rowGroupMetadata);
    }
  }

  consolidarFactura(id) {
    this.facturaElectronicaService.emitInvoice(id, 1).subscribe((data: any) => {
      //console.log(data);

      if (data.message) {
        this.openDialog(data.message);
        return;
      }

      if (data.Respuestas[0].Error == 'Exitoso') {
        this.ngOnInit();
        this.openDialog('Factura Emitida');
      } else {
        this.openDialog(data.Respuestas[0].Error) + ' ' + data.Respuestas[0].Detalle;
      }
    });
  }

  consolidarTicket(id) {
    this.facturaElectronicaService.emitInvoice(id, 2).subscribe((data: any) => {
      //console.log(data);

      if (data.message) {
        this.openDialog(data.message);
        return;
      }

      if (data.Respuestas[0].Error == 'Exitoso') {
        this.ngOnInit();
        this.openDialog('Factura Emitida');
      } else {
        this.openDialog(data.Respuestas[0].Error) + ' ' + data.Respuestas[0].Detalle;
      }
    });
  }

  getHoraEntrega(historial) {
    return historial.filter(e => e.estado.id === 18)[0].estado_fecha;
  }

  private _filter(value: any, options: any): ClienteElement[] {
    if (!value) {
      return options;
    }

    let filterValue: string;
    value.name ? filterValue = value.name.toLowerCase() : filterValue = value;
    return options.filter(user => user.name.toLowerCase().includes(filterValue));
  }

  checkPackage(row) {
    row.checked = !row.checked;
  }

  netPackages() {

    const packages = this.dataSource
      .filter(e => e.checked === true)
      .map(e => ({ paquete: e.id, factura: e.factura.id }));

    if (packages.length === 0) {
      this.openDialog('Debe seleccionar al menos un paquete para realizar la funcionalidad del "neteo"');
      return;
    }
    this.paquetesService.netPackages(packages).subscribe(
      (data: any) => {
        let result = "";
        if (data.success && data.success.length > 0) {
          result += "Paquetes neteados correctamente:\n";
          data.success.forEach(paquete => {
            result += `✅ ${paquete}\n`;
          });
        }

        if (data.failed && data.failed.length > 0) {
          result += "Paquetes con error al netear:\n";
          data.failed.forEach(paquete => {
            result += `❌ ${paquete}\n`;
          });
        }
        const dialogRef = this.dialog.open(GeneralDialogComponent, {
          data: { detail: result },
          panelClass: 'general'
        });
        dialogRef.afterClosed().subscribe(result => {
          this.procesarReporte();
        });
      },
      (error: any) => {
        this.openDialog('Ha ocurrido un error al netear los paquetes');
      }
    );
  }

  emitCreditNote(nc) {
  }

  deleteNotaCredito() {

  }

  filterPackages(event: any) {
    this.reportsForm.get('filterBy').setValue(event.value);
  }

}
