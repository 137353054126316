/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { EmbarquesService } from 'src/app/services/embarques.service';
import { EntregasService } from 'src/app/services/entregas.service';
import { PaquetesService } from 'src/app/services/paquetes.service';


export interface DialogData {
  title: string;
  value: string;
  type: string;
  full: boolean;
}

@Component({
  selector: 'app-general-input',
  templateUrl: './general-input.component.html',
  styleUrls: ['./general-input.component.scss']
})
export class GeneralInputComponent implements OnInit {

  value: string;

  embarques: any;
  entregas: any;
  estadosPaquetes: any;

  full: boolean;

  constructor(
    public dialogRef: MatDialogRef<GeneralInputComponent>,
    private embarquesService: EmbarquesService,
    private entregasService: EntregasService,
    private paquetesService: PaquetesService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {

    if (this.data.type === 'general') {
      this.value = this.data.value;
    }

    if (this.data.type === 'general-long') {
      this.value = this.data.value;
    }

    if (this.data.type === 'embarques') {
      this.full = this.data.full;
      this.getEmbarques();
    }

    if (this.data.type === 'entregas') {
      this.getEntregas();
    }

    if (this.data.type === 'tarifa') {
      this.value = this.data.value;
    }

    if (this.data.type === 'estado') {
      this.getEstadosPaquetes();
    }
  }

  public onYesClick = (): void => {
    this.data.value = this.value;
    this.dialogRef.close(this.data);
  };

  public onNoClick = (): void => {
    this.dialogRef.close();
  };

  public getEstadosPaquetes() {
    this.paquetesService.getEstadosPaquetesClientes().subscribe((data: any) => {
      this.estadosPaquetes = data;
      this.value = '';
    });
  }

  public getEmbarques() {
    this.embarquesService.getOpen().subscribe((res: any) => {
      let status;
      const ships = [];

      res.forEach(element => {
        ships.push({
          awb: element.awb_bl,
          charge_weight: element.charge_weight,
          refdate: this.datePipe.transform(new Date(element.date), 'dd/MM/yy'),
          file: element.file_a,
          vessel: element.travel_identifier,
          gross_weight: element.gross_weight,
          id: element.id,
          notes: element.notes,
          packages: element.total_packages,
          pieces: element.pieces,
          scanned: element.scanned_packages,
          services: element.travel_type,
          status,
          bultos: element.total_charge,
          total: element.total,
          creation: this.datePipe.transform(new Date(element.created_at), 'dd/MM/yy HH:mm'),
        });
      });

      this.embarques = ships;
      this.value = this.embarques[0].id;
    });
  }

  getEntregas() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    this.entregasService.get(0).subscribe(
      (res: any) => {
        //console.log('Entregas', res);
        const deliverlist = [];

        res.forEach((element, index) => {
          ////console.log(element.fecha)

          const d2 = new Date(element.fecha);

          d2.setDate(d2.getDate() + 1);
          d2.setHours(0, 0, 0, 0);

          if (today.getTime() <= d2.getTime()) {
            const transformDate = new Date(element.fecha);
            transformDate.setDate(transformDate.getDate() + 1);

            deliverlist.push({
              id: element.id,
              date: this.datePipe.transform(transformDate, 'dd/MM/yy'),
              conductor: element.conductor.nombre,
            });
          }

        });

        deliverlist.sort(function(a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.fecha).getTime() - new Date(a.fecha).getTime();
        });

        this.entregas = deliverlist;

        if (this.entregas.length > 0) {
          this.value = this.entregas[0].id;
        }
      }

    );
  }

}
