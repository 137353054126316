<div *ngIf="!verTemplate; else showTemplate" class="p-3 content" fxLayout="column">
  <div class="content-title">
    <p class="title">Enviar Email</p>
  </div>

  <!--
  <div class="inputs-form mt-5 pt-4 mb-4 inputs-template">

    <div>
      <label class="label">Titulo</label><br>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="title" placeholder="Título">
      </mat-form-field>
    </div>
  </div>
  -->

  <div class="inputs-form mt-5 pt-4 mb-4">
    <div  class="col-12">
      <label class="label">Asunto</label><br>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="asunto" placeholder="Asunto">
      </mat-form-field>
    </div>

  </div>

  <!--
  <div class="row">

    <div class="col-12 col-md-5">
      <label class="label">Destinatario</label><br>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="destinatario" [disabled]="modulo == 'Usuario'" placeholder="Destinatario"
          matTooltip="Para múltiples direcciones introducir separadas por comas">
      </mat-form-field>
    </div>

    <div class="col-12 col-md-5">
      <label class="label">CC</label><br>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="cc" [disabled]="modulo == 'Usuario'" placeholder="CC"
          matTooltip="Para múltiples direcciones introducir separadas por comas">
      </mat-form-field>
    </div>

    <div class="col-12 col-md-2 text-center">
      <label class="label">Activo</label><br>

      <mat-checkbox style="display: inline-flex !important;" [value]="activo" [checked]="activo"></mat-checkbox>

    </div>
  </div>
  -->

  <app-ckeditor [template]="template" (content)="recibirTemplate($event)" [modal]="true" [send]="true"></app-ckeditor>

</div>

<ng-template #showTemplate>
  <app-vista [title]="title" [asunto]="asunto" [banner]="banner" [template]="template"></app-vista>
</ng-template>
