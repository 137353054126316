import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  message: string;
  emailcheckbox: boolean;
}

@Component({
  selector: 'app-confirmar',
  templateUrl: './confirmar.component.html',
  styleUrls: ['./confirmar.component.scss']
})
export class ConfirmarComponent implements OnInit {

  sendEmail = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
  }

  public onNoClick = (): void => {
    this.dialogRef.close();
  };

  public onYesClick = (): void => {
    //console.log(this.sendEmail);
    if (this.data.emailcheckbox) {
      this.dialogRef.close(this.sendEmail);
    } else {
      this.dialogRef.close(true);
    }

  };

}
