import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IntegracionesService {
  urlIntegraciones = `${environment.apiUrl}/integraciones/`;

  constructor(private http: HttpClient) { }

  get() {
    return this.http.get(this.urlIntegraciones);
  }

  clear() {
    return this.http.get(this.urlIntegraciones + 'clear');
  }

  status(){
    return this.http.get(this.urlIntegraciones + 'status');
  }

}
