/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsuariosService } from '../../../../services/usuarios.service';
import { AuthService } from '../../../../services/auth.service';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  tiposTarjetas = ['Visa', 'Mastercard'];

  formTarjeta = this.fb.group({
    CustomerReference: [''],
    CardType: [''],
    CardToken: [''],
    PaddedCardNo: [''],
    Active: [false],
    Default: [false],
    usuario: [],
  });

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private router: Router,
    private usuariosService: UsuariosService,
    public authService: AuthService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.formTarjeta.controls.CustomerReference.setValue(this.data.CustomerReference);
    this.formTarjeta.controls.CardType.setValue(this.data.CardType);
    this.formTarjeta.controls.CardToken.setValue(this.data.CardToken);
    this.formTarjeta.controls.PaddedCardNo.setValue(this.data.PaddedCardNo);
    this.formTarjeta.controls.Active.setValue(this.data.Active);
    this.formTarjeta.controls.Default.setValue(this.data.Default);
    this.formTarjeta.controls.usuario.setValue(this.data.usuario);
  }

  edit() {
    this.usuariosService.putCards(this.data.id, this.formTarjeta.value).subscribe((data: any) => {
      this.openSnackBar(this.translate.translate('Tarjeta Actualizada.'), this.translate.translate('Cerrar'));
      this.dialogRef.close();
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
