/* eslint-disable no-underscore-dangle */
enum TipoDeCedula {
  'juridica' = 'JUR',
  'fisica' = 'FIS',
  'alianza' = 'ALI'
}

enum Genero {
  'masculino' = 'M',
  'femenino' = 'F',
  'otro' = 'O'
}

enum ModoDeEntrega {
  'enSucursal' = 'ES',
  'enDomicilio' = 'ED',
  'areaMetropolitana' = 'AM',
  'encomienda' = 'EN'
}

class Usuario {
  groupMember: any[];

  protected fillable: string[] = [
    'email', 'password', 'tipo_de_cedula', 'razon_social',
    'cedula_juridica', 'representante_legal',
    'nombre', 'primer_apellido', 'segundo_apellido',
    'numero_de_cedula', 'fecha_de_nacimiento',
    'telefono_movil', 'genero', 'modo_de_entrega',
    'direccion', 'compro_en_internet', 'metodo_de_captacion', 'group_member', 'rol',
    'id', 'tarifaA', 'tarifaM', 'cuenta', 'codigo_referido', 'ruta', 'sucursal', 'direccion_entrega_apps',
    'provincia', 'distrito', 'canton', 'direccion_entrega', 'metodo_pago', 'alianza', 'consent', 'temp_pass', 'detalles_adicionales'
  ];

  // eslint-disable-next-line @typescript-eslint/semi
  private _id: string
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _email: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _password: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _tipoDeCedula: TipoDeCedula;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _razonSocial: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _cuenta: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _cedulaJuridica: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _representanteLegal: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _nombre: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _primerApellido: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _segundoApellido: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _numeroDeCedula: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _fechaDeNacimiento: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _telefonoMovil: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _genero: Genero;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _modoDeEntrega: ModoDeEntrega;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _direccion: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _comproEnInternet: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _metodoDeCaptacion: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _tarifaA: number;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _tarifaM: number;

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _rol: any[];

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _ruta: number;
  private _sucursal: number;
  private _provincia: number;
  private _distrito: number;
  private _canton: number;
  private _direccionEntregaApps: string;
  private _direccionEntrega: string;
  private _codigoReferido: string;
  private _metodoPago: number;
  private _alianza: number;
  private _consent: boolean;
  private _tempPass: boolean;
  private _detallesAdicionales: string;

  constructor(
    object: any
  ) {

    this.fill(object);
  }

  set id(id: string) {

    this._id = id;
  }

  get id(): string {

    return this._id;
  }

  set email(email: string) {

    this._email = email;
  }

  get email(): string {

    return this._email;
  }

  set password(password: string) {

    this._password = password;
  }

  get password(): string {

    return this._password;
  }

  set tipoDeCedula(tipoDeCedula: TipoDeCedula) {

    this._tipoDeCedula = tipoDeCedula;
  }

  get tipoDeCedula(): TipoDeCedula {

    return this._tipoDeCedula;
  }

  set cuenta(cuenta: string) {

    this._cuenta = cuenta;
  }

  get cuenta(): string {

    return this._cuenta;
  }

  set razonSocial(razonSocial: string) {

    this._razonSocial = razonSocial;
  }

  get razonSocial(): string {

    return this._razonSocial;
  }

  set cedulaJuridica(cedulaJuridica: string) {

    this._cedulaJuridica = cedulaJuridica;
  }

  get cedulaJuridica(): string {

    return this._cedulaJuridica;
  }

  set representanteLegal(representanteLegal: string) {

    this._representanteLegal = representanteLegal;
  }

  get representanteLegal(): string {

    return this._representanteLegal;
  }

  set nombre(nombre: string) {

    this._nombre = nombre;
  }

  get nombre(): string {

    return this._nombre;
  }

  set primerApellido(primerApellido: string) {

    this._primerApellido = primerApellido;
  }

  get primerApellido(): string {

    return this._primerApellido;
  }

  set segundoApellido(segundoApellido: string) {

    this._segundoApellido = segundoApellido;
  }

  get segundoApellido(): string {

    return this._segundoApellido;
  }

  get rol(): any[] {

    return this._rol;
  }

  set rol(rol: any[]) {

    this._rol = rol;
  }

  set numeroDeCedula(numeroDeCedula: string) {

    this._numeroDeCedula = numeroDeCedula;
  }

  get numeroDeCedula(): string {

    return this._numeroDeCedula;
  }

  set fechaDeNacimiento(fechaDeNacimiento: string) {

    this._fechaDeNacimiento = fechaDeNacimiento;
  }

  get fechaDeNacimiento(): string {

    return this._fechaDeNacimiento;
  }

  set telefonoMovil(telefonoMovil: string) {

    this._telefonoMovil = telefonoMovil;
  }

  get telefonoMovil(): string {

    return this._telefonoMovil;
  }

  set genero(genero: Genero) {

    this._genero = genero;
  }

  get genero(): Genero {

    return this._genero;
  }

  set modoDeEntrega(modoDeEntrega: ModoDeEntrega) {

    this._modoDeEntrega = modoDeEntrega;
  }

  get modoDeEntrega(): ModoDeEntrega {

    return this._modoDeEntrega;
  }

  set direccion(direccion: string) {

    this._direccion = direccion;
  }

  get direccion(): string {

    return this._direccion;
  }

  set comproEnInternet(comproEnInternet: boolean) {

    this._comproEnInternet = comproEnInternet;
  }

  get comproEnInternet(): boolean {

    return this._comproEnInternet;
  }

  set metodoDeCaptacion(metodoDeCaptacion: string) {

    this._metodoDeCaptacion = metodoDeCaptacion;
  }

  get metodoDeCaptacion(): string {

    return this._metodoDeCaptacion;
  }

  get tarifaA(): number {

    return this._tarifaA;
  }

  set tarifaA(tarifaA: number) {

    this._tarifaA = tarifaA;
  }

  get tarifaM(): number {

    return this._tarifaM;
  }

  set tarifaM(tarifaM: number) {

    this._tarifaM = tarifaM;
  }


  get ruta(): number {

    return this._ruta;
  }

  set ruta(ruta: number) {

    this._ruta = ruta;
  }

  get sucursal(): number {

    return this._sucursal;
  }

  set sucursal(sucursal: number) {

    this._sucursal = sucursal;
  }

  get provincia(): number {

    return this._provincia;
  }

  set provincia(provincia: number) {

    this._provincia = provincia;
  }

  get distrito(): number {

    return this._distrito;
  }

  set distrito(distrito: number) {

    this._distrito = distrito;
  }

  get canton(): number {

    return this._canton;
  }

  set canton(canton: number) {

    this._canton = canton;
  }

  get direccionEntregaApps(): string {

    return this._direccionEntregaApps;
  }

  set direccionEntregaApps(direccionEntregaApps: string) {

    this._direccionEntregaApps = direccionEntregaApps;
  }

  get direccionEntrega(): string {

    return this._direccionEntrega;
  }

  set direccionEntrega(direccionEntrega: string) {

    this._direccionEntrega = direccionEntrega;
  }

  get codigoReferido(): string {

    return this._codigoReferido;
  }

  set codigoReferido(codigoReferido: string) {

    this._codigoReferido = codigoReferido;
  }

  get metodoPago(): number {

    return this._metodoPago;
  }

  set metodoPago(metodoPago: number) {

    this._metodoPago = metodoPago;
  }

  set alianza(alianza: number) {

    this._alianza = alianza;
  }

  get alianza(): number {

    return this._alianza;
  }

  set consent(consent: boolean) {

    this._consent = consent;
  }

  get consent(): boolean {

    return this._consent;
  }

  set tempPass(tempPass: boolean) {

    this._tempPass = tempPass;
  }

  get tempPass(): boolean {

    return this._tempPass;
  }

  get detallesAdicionales(): string {

    return this._detallesAdicionales;
  }

  set detallesAdicionales(detallesAdicionales: string) {

    this._detallesAdicionales = detallesAdicionales;
  }

  toJSON(): any {

    return {
      email: this.email,
      password: this.password,
      tipo_de_cedula: this.tipoDeCedula,
      razon_social: this.razonSocial,
      cedula_juridica: this.cedulaJuridica,
      representante_legal: this.representanteLegal,
      nombre: this.nombre,
      primer_apellido: this.primerApellido,
      segundo_apellido: this.segundoApellido,
      numero_de_cedula: this.numeroDeCedula,
      fecha_de_nacimiento: this.fechaDeNacimiento,
      telefono_movil: this.telefonoMovil,
      genero: this.genero,
      modo_de_entrega: this.modoDeEntrega,
      compro_en_internet: this.comproEnInternet,
      direccion: this.direccion,
      metodo_de_captacion: this.metodoDeCaptacion,
      tarifaA: this.tarifaA,
      tarifaM: this.tarifaM,
      cuenta: this.cuenta,
      ruta: this.ruta,
      sucursal: this.sucursal,
      provincia: this.provincia,
      distrito: this.distrito,
      canton: this.canton,
      direccion_entrega_apps: this.direccionEntregaApps,
      direccion_entrega: this.direccionEntrega,
      codigo_referido: this.codigoReferido,
      metodo_pago: this.metodoPago,
      alianza: this.alianza,
      consent: this.consent,
      temp_pass: this.tempPass,
      detalles_adicionales: this.detallesAdicionales
    };
  }

  fill(object: any): void {

    const props = Object.getOwnPropertyNames(object);
    props.forEach(prop => {

      if (this.fillable.indexOf(prop) > -1) {

        this[this.snakeToCamel(prop)] = object[prop];

      } else if (this.fillable.indexOf(this.camelToSnake(prop)) > -1) {

        this[prop] = object[prop];
      }
    });
  }

  snakeToCamel(word: string): string {
    return word.replace(/(_\w)/g, (m) => m[1].toUpperCase());
  }

  camelToSnake(word: string): string {
    return word.replace(/[\w]([A-Z])/g, (m) => m[0] + '_' + m[1]).toLowerCase();
  }
}

export { TipoDeCedula, Genero, ModoDeEntrega, Usuario };
