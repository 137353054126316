<div class="p-3 content" fxLayout="column">

  <form [formGroup]="compraForm" (keydown.enter)="$event.preventDefault()">

    <div fxLayout="row wrap" fxLayoutGap="20px" style="display: flex; align-items: center;">

      <div class="pt-2"
        style="width: 100%; text-align: center; font-family: Varela Round, sans-serif; font-size: 18px !important;">
        <button mat-button (click)="back()" style="padding: 0; padding-right: 10px; color: #0094a3; float: left;">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>

        Compra Corporativa

        <button mat-button style="float: right;" (click)="save()">
          <mat-icon>save</mat-icon>Guardar
        </button>

        <button *ngIf="editMode" mat-flat-button
          style="float: right; background-color: red !important; color: white !important;" (click)="delete()">
          <mat-icon style="color: white !important;">delete</mat-icon>Eliminar
        </button>

      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="content p-3 mb-3">
      <div class="content-title">
        <p class="title">{{'Información de Compra' | uppercase}}</p>
      </div>

      <div class="container-fluid pt-4 mt-5">
        <div class="row">
          <div class="col-12 col-md-6">


            <div class="col-12" *ngIf="editMode">
              <label style="cursor: pointer;" class="label" [routerLink]="['/admin/paquetes/paquete']"
                [queryParams]="{pack: paquete?.id}">Guía: {{ paquete?.numero_de_guia }} </label>
            </div>

            <div class="col-12" *ngIf="editMode">
              <label class="label">Creada por: {{ usuariosService.get_fullname(compra.created_by) }} </label>
            </div>

            <div class="col-12">
              <label class="label">Cuenta</label>
              <mat-form-field appearance="outline">
                <input matInput placeholder="Cuenta" formControlName="cuenta" [matAutocomplete]="auto" required #cuenta
                  (keyup)="buscarListaClientes($event, cuenta.value)">
                <mat-autocomplete (optionSelected)='selected($event.option.value)' autoActiveFirstOption
                  #auto="matAutocomplete" [displayWith]="displayFn">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option.name}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="compraForm.get('cuenta').hasError('required')">
                  Campo <strong>requerido</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12">
              <label class="label">Tipo de Compra</label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="tipo_compra" placeholder="Seleccione">
                  <mat-option *ngFor="let tipo of tipoCompra" [value]="tipo.value">
                    {{tipo.viewValue}}
                  </mat-option>
                </mat-select>

                <mat-error *ngIf="compraForm.get('tipo_compra').hasError('required')">
                  Campo <strong>requerido</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12">
              <label class="label">Fecha de Compra</label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="fecha_compra" type="date" placeholder="Fecha de Compra">
                <mat-error *ngIf="compraForm.get('tipo_compra').hasError('required')">
                  Campo <strong>requerido</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12">
              <label class="label">Descripción</label>
              <mat-form-field appearance="outline">
                <textarea matInput formControlName="descripcion" rows="4" placeholder="Descripción"
                  style="width: 100% !important;"></textarea>
                <mat-error *ngIf="compraForm.get('descripcion').hasError('required')">
                  Campo <strong>requerido</strong>
                </mat-error>
              </mat-form-field>

            </div>

            <div class="col-12">
              <label class="label">Remitente</label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="remitente" placeholder="Remitente">
                <mat-error *ngIf="compraForm.get('remitente').hasError('required')">
                  Campo <strong>requerido</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12">
              <label class="label">Número de Orden (Amazon, Shein, eBay)</label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="numero_orden" placeholder="Número de Orden">
                <mat-error *ngIf="compraForm.get('numero_orden').hasError('required')">
                  Campo <strong>requerido</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12" *ngIf="editMode">
              <label class="label">Tracking</label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="tracking" placeholder="Tracking">
              </mat-form-field>
            </div>

            <div class="row col-12 mt-4" *ngIf="!editMode">
              <div class="col-12 pt-2 pb-2">
                <label class="label">Datos de Pago</label>
              </div>

              <div class="col-12">
                Debe crear la compra antes de adjuntar los datos de pago
              </div>
            </div>

            <div class="row col-12 mt-4" *ngIf="editMode">
              <div class="col-12 pt-2 pb-2">
                <label class="label">Datos de Pago</label>
              </div>

              <div class="col-6">
                <label class="label">Cuota 1</label>
                <mat-form-field appearance="outline" class="form-field">
                  <input matInput formControlName="cuota_1" class="input-field" numeric decimals="2" #cuota1
                    [readonly]="params ? true:false">
                  <mat-label class="prefix" matPrefix>USD $</mat-label>
                </mat-form-field>
              </div>

              <div class="col-6">
                <label class="label">Cuota 2</label>
                <mat-form-field appearance="outline" class="form-field">
                  <input matInput formControlName="cuota_2" class="input-field" numeric decimals="2" #cuota2
                    [readonly]="params ? true:false">
                  <mat-label class="prefix" matPrefix>USD $</mat-label>
                </mat-form-field>
              </div>

              <div class="col-6" *ngIf="totalPagado < cuota1.value">
                <button id="adjuntar-pago-1" mat-flat-button color="primary" class="button-calcular mx-auto"
                  (click)="adjuntarPago(cuota1.value)">
                  Adjuntar Pago
                </button>
              </div>

              <div class="col-6" *ngIf="paquete">
                <button id="adjuntar-pago-2" mat-flat-button color="primary" class="button-calcular mx-auto"
                  (click)="adjuntarPago(cuota2.value)" *ngIf="paquete?.estado_actual == 12">
                  Adjuntar Pago
                </button>
              </div>
            </div>

            <div class="col-12 mt-2" *ngIf="paquete">
              <label class="label">Estado Actual </label> <br>
              <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: paquete.id}">
                {{paquete.estado_actual?.estado}}
              </a>
            </div>

            <div class="content-3 col-12 mt-4 pt-1 pb-4">
              <div class="inputs-form mt-5">
                <div *ngIf="compra.factura_1 == null; else imagen_factura_1">

                  <div class="d-inline-block w-50" fileDragDrop (filesChangeEmiter)="processFile($event, '1')">

                    <button> <label for="1">Imagen de Factura 1</label></button>
                    <input type="file" id="1" formcontrolname="factura_1" class="d-none"
                      accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .doc, .docx, .pdf"
                      (change)="processFile($event.target.files, $event.target.id)">

                      <span class="d-inline-block" style="margin-left: 50%;" *ngIf="this.fileLoad1">{{fileLoad1.name}}</span>
                  </div>

                  <div class="d-inline-block">
                    <button type="button" mat-flat-button (click)="openCamera($event, 'factura_1')">
                      <mat-icon>camera</mat-icon>
                      Tomar Foto
                    </button>
                  </div>
                </div>

                <ng-template #imagen_factura_1>
                  <div class="justify-content-center  text-center">
                    <span class="span-label">Imagen de Factura 1</span>
                    <img *ngIf="!loadOne" class="d-flex mx-auto" [src]="mediaUrl+compra.factura_1"
                      alt="Imagen de Factura 1" width="150px" (click)="gotoFile1()">
                    <button style="width: auto;margin-left: 15px;" *ngIf="loadOne" class="button-archivo mt-2"
                      (click)="gotoFile1()" mat-flat-button color="primary">
                      <mat-icon style="color: white !important; font-size: 16px !important;">remove_red_eye</mat-icon>
                      I{{compra.factura_1.split('/')[3]}}
                    </button>
                    <!-- <label style="background: none; border: 1px solid;" *ngIf="(compra?.factura_1?.includes('.pdf') || compra?.factura_1?.includes('.word')) && loadOne"
                      class="button-archivo mt-2">
                      {{fileLoad1.name}}</label>-->
                  </div>

                </ng-template>

                <div *ngIf="compra.factura_2 == null; else imagen_factura_2">
                  <div class="d-inline-block w-50" fileDragDrop (filesChangeEmiter)="processFile($event, '2')">
                    <button> <label for="2">Imagen de Factura 2</label></button>
                    <input type="file" id="2" formcontrolname="factura_2" class="d-none"
                      accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .doc, .docx, .pdf"
                      (change)="processFile($event.target.files, $event.target.id)">

                      <span class="d-inline-block" style="margin-left: 50%;" *ngIf="this.fileLoad2">{{fileLoad2.name}}</span>
                  </div>

                  <div class="d-inline-block">
                    <button type="button" mat-flat-button (click)="openCamera($event, 'factura_2')">
                      <mat-icon>camera</mat-icon>
                      Tomar Foto
                    </button>
                  </div>

                </div>
                <ng-template #imagen_factura_2>
                  <div class="justify-content-center  text-center">
                    <span class="span-label">Imagen de Factura 2</span>
                    <img *ngIf="!loadTwo" class="d-flex mx-auto" [src]="mediaUrl+compra.factura_2" (click)="gotoFile2()"
                      alt="Imagen de Factura 2" width="150px">
                    <button style="width: auto;margin-left: 15px;" *ngIf="loadTwo" class="button-archivo mt-2"
                      (click)="gotoFile2()" mat-flat-button color="primary">
                      <mat-icon style="color: white !important; font-size: 16px !important;">remove_red_eye</mat-icon>
                      I{{compra.factura_2.split('/')[3]}}
                    </button>
                  </div>
                </ng-template>

                <div *ngIf="compra.factura_3 == null; else imagen_factura_3">
                  <div class="d-inline-block w-50" fileDragDrop (filesChangeEmiter)="processFile($event, '3')">
                    <button> <label for="3">Imagen de Factura 3</label></button>
                    <input type="file" id="3" formcontrolname="factura_3" class="d-none"
                      accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .doc, .docx, .pdf"
                      (change)="processFile($event.target.files, $event.target.id)">
                      <span class="d-inline-block" style="margin-left: 50%;" *ngIf="this.fileLoad3">{{fileLoad3.name}}</span>
                  </div>
                  <div class="d-inline-block">
                    <button type="button" mat-flat-button (click)="openCamera($event, 'factura_3')">
                      <mat-icon>camera</mat-icon>
                      Tomar Foto
                    </button>
                  </div>
                </div>
                <ng-template #imagen_factura_3>
                  <div class="justify-content-center  text-center">
                    <span class="span-label">Imagen de Factura 3 </span>
                    <img *ngIf="!loadThree" class="d-flex mx-auto" [src]="mediaUrl+compra.factura_3"
                      (click)="gotoFile3()" alt="Imagen de Factura 3" width="150px">
                    <button style="width: auto;margin-left: 15px;" *ngIf="loadThree" class="button-archivo mt-2"
                      (click)="gotoFile3()" mat-flat-button color="primary">
                      <mat-icon style="color: white !important; font-size: 16px !important;">remove_red_eye</mat-icon>
                      I{{compra.factura_3.split('/')[3]}}
                    </button>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="col-12">
              <label class="label">Tipo de cambio</label>
              <mat-form-field appearance="outline">

                <input [readonly]="params ? true:false" matInput formControlName="tipo_de_cambio"
                  placeholder="Tipo de cambio">
                <mat-label class="prefix" matPrefix>CRC ¢</mat-label>
              </mat-form-field>
            </div>

            <div style="background-color: #0094a3; border-radius: 5px 5px 0px 0px;" *ngIf="editMode == false">
              <p
                style="text-align: center;color:white;  font-size: 20px; font-family: 'Varela Round'; line-height: 22px; font-size: 18px; font-style: normal;padding-top: 13px; padding-bottom: 13px; ">
                Calculadora
              </p>
            </div>

            <form class="row mt-2" [formGroup]="control" (keydown.enter)="$event.preventDefault()"
              *ngIf="editMode == false">
              <div class="container" fxLayout="column" fxLayoutGap="5px">
                <label class="label">Tipo de Producto</label>

                <mat-form-field appearance="outline" class="form-field">
                  <input name="product-type" class="input-field" placeholder="Seleccionar Producto" matInput
                    formControlName="producto" [matAutocomplete]="auto1">
                  <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFnProducts">
                    <mat-option *ngFor="let productType of filteredOptionsProducts | async " [value]="productType">
                      {{ productType.viewValue }} ({{productType.value}}%)
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>

                <label class="label">Precio del producto (incluir impuestos y envío) </label>
                <mat-form-field appearance="outline" class="form-field">
                  <input matInput formControlName="precio" class="input-field" numeric decimals="2">
                  <mat-label class="prefix" matPrefix>USD $</mat-label>
                </mat-form-field>

                <div class="container">
                  <div class="row">

                    <div class="col-6 pl-0">
                      <label class="label">Peso en Lbs</label>
                      <mat-form-field style="width: 100%" appearance="outline" class="form-field">
                        <input matInput placeholder="" type="number" formControlName="libra" numeric decimals="0"
                          (keyup)="UpdateKg($event.target.value)" (change)="UpdateKg($event.target.value)"
                          class="input-field">
                      </mat-form-field>
                    </div>

                    <div class="col-6 pr-0">
                      <label class="label">Peso en Kgs.</label>
                      <mat-form-field style="width: 100%" appearance="outline" class="form-field">
                        <input matInput placeholder="" type="number" formControlName="kilo" numeric decimals="0"
                          (keyup)="UpdateLb($event.target.value)" (change)="UpdateLb($event.target.value)"
                          class="input-field">
                      </mat-form-field>
                    </div>

                  </div>
                </div>

                <div *ngIf="tamanoOn">
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <label class="label mb-3">Alto</label>

                      <mat-form-field style="width: 120px" appearance="outline">
                        <mat-label position="floating">Pulgadas</mat-label>
                        <input matInput placeholder="" type="number" formControlName="alto" class="input-field" numeric
                          decimals="0">
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-md-4">
                      <label class="label mb-3">Ancho</label>
                      <mat-form-field style="width: 120px" appearance="outline">
                        <mat-label position="floating">Pulgadas</mat-label>
                        <input matInput placeholder="" type="number" formControlName="ancho" class="input-field" numeric
                          decimals="0">
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-md-4">
                      <label class="label mb-3">Largo</label>
                      <mat-form-field style="width: 120px;" appearance="outline">
                        <mat-label position="floating">Pulgadas</mat-label>
                        <input matInput placeholder="" type="number" formControlName="largo" class="input-field" numeric
                          decimals="0">
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div>

                  <div>
                    <label class="label col-12 p-0">Otros Cargos</label>
                    <mat-form-field appearance="outline">
                      <mat-select #otrosCargos placeholder="Seleccione"
                        (valueChange)="checkOtrosCargos(otrosCargos.value)">
                        <mat-option value="0">Ninguno</mat-option>
                        <mat-option *ngFor="let variable of tarifasVariablesPermisos" [value]="variable">
                          {{variable.description}} / ${{variable.value}}
                        </mat-option>
                        <mat-option value="-1">Personalizado</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <div class="col-12" *ngIf="visibleOtrosCargos2" [formGroup]="compraForm">
                      <label class="label">Descripción: </label>
                      <mat-form-field appearance="outline">
                        <input matInput formControlName="otros_cargos_descripcion" placeholder="Descripción">
                      </mat-form-field>
                    </div>

                    <div class="col-12" *ngIf="visibleOtrosCargos2" [formGroup]="compraForm">
                      <label class="label">Monto: </label>
                      <mat-form-field appearance="outline">
                        <input matInput formControlName="otros_cargos" placeholder="Monto">
                        <mat-label class="prefix" matPrefix>USD $</mat-label>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="text-center">
                  <button class="mx-4" style="width: 50%; margin-left: 0;" mat-flat-button color="primary"
                    [disabled]="!control.valid" (click)="Calcular()" class="button-calcular">Calcular</button>
                </div>
              </div>
            </form>
            <!--container resultados-->
            <div style="background-color: #0094a3; border-radius: 5px 5px 0px 0px;margin-top: 20px;">
              <p style="text-align: center;color:white;  font-size: 20px; font-family: 'Varela Round'; line-height: 22px; font-size: 18px; font-style: normal;padding-top: 13px; padding-bottom: 13px; "
                *ngIf="editMode == false">
                Resultados de Calculadora
              </p>

              <p style="text-align: center;color:white;  font-size: 20px; font-family: 'Varela Round'; line-height: 22px; font-size: 18px; font-style: normal;padding-top: 13px; padding-bottom: 13px; "
                *ngIf="editMode">
                Detalles de Facturación
              </p>
            </div>

            <div class="container" fxLayout="column" fxLayoutGap="5px">
              <div class="row">
                <div class="col-12" id="calculo_estimado" *ngIf="editMode">
                  <div style="width: 50%; display: inline-block;">
                    <img [src]="getLogo()" alt="logo" class="logo" width="250">
                  </div>

                  <div style="text-align:center; padding: 1em; color: #004481;">
                    <h3> {{'Compra Corporativa #' | translate}} {{compra.id | translate}} </h3>
                  </div>

                  <table class="compra-calc" style="width: 100%; border: 1px solid #E9E7E7;">
                    <tr>
                      <th
                        style="background-color: #0094a3; line-height: 24px; color: #004481; border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{'Rubro' | translate}}</th>
                      <th
                        style="background-color: #0094a3; line-height: 24px; color: #004481; border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        SuperRápido (Aéreo)</th>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{'Precio' | translate}}</td>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{facturaUSD?.cargo_compra_corporativa | currency: 'USD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{'Gestión' | translate}} </td>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{facturaUSD?.cargo_gestion_compra_corporativa | currency: 'USD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{'Flete Internacional' | translate}}</td>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{facturaUSD?.flete_internacional | currency: 'USD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{'Servicio SuperBox' | translate}} </td>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{facturaUSD?.servicios_sb | currency: 'USD':'symbol-narrow':'1.2-2' }}</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{'Impuestos' | translate}}</td>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{facturaUSD?.cargo_impuestos | currency: 'USD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                    <tr *ngIf="facturaUSD?.cargo_encomienda > 0">
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{'Encomienda' | translate }}</td>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{facturaUSD?.cargo_encomienda | currency: 'USD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                    <tr *ngIf="facturaUSD?.otros_cargos> 0">
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{this.compra?.factura?.otros_cargos_1_descripcion | translate }}</td>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{facturaUSD?.otros_cargos_1 | currency: 'USD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{'IVA' | translate | uppercase}}: </td>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{facturaUSD?.iva | currency: 'USD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                    <tr>
                      <td
                        style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px; font-weight: bold">
                        {{'TOTAL' | translate | uppercase}}:</td>
                      <td
                        style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px; font-weight: bold">
                        {{facturaUSD?.total | currency: 'USD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                  </table>

                  <p
                    style="font-family: 'Roboto'; font-style: normal; font-weight: normal; font-size: 16px; line-height: 22px; letter-spacing: -0.5px; color: #A2A2A2; padding-top: 20px;">
                    {{'Este cálculo está basado en un valor de $' | translate}}{{this.compra?.precio}}{{', un peso de '
                    | translate}}
                    {{ this.compra?.paquete?.peso_total || 0 }}lbs. {{' y un arancel de ' | translate}} {{
                    compraForm.value.tipo_producto?.value || 0}}%.
                    <br>
                    {{'Artículos como comida y bebidas, maquillaje, medicamentos, perfumes, cremas,
                    cuchillos, armas, entre otros requieren de un proceso adicional. Consulte antes de importar.' |
                    translate}}
                  </p>

                </div>

                <div class="col-12" id="calculo_estimado" *ngIf="!editMode">
                  <div style="width: 50%; display: inline-block;">
                    <img [src]="getLogo()" alt="logo" class="logo" width="250">
                  </div>

                  <div style="text-align:center; padding: 1em; color: #004481;">
                    <h3> {{'Compra Corporativa' | translate}}</h3>
                  </div>

                  <table class="compra-calc" style="width: 100%; border: 1px solid #E9E7E7;">
                    <tr>
                      <th
                        style="background-color: #0094a3; line-height: 24px; color: #004481; border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{'Rubro' | translate}}</th>
                      <th
                        style="background-color: #0094a3; line-height: 24px; color: #004481; border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        SuperRápido (Aéreo)</th>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{'Precio' | translate}}</td>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{this.compra.precio | currency: 'USD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{'Gestión' | translate}} </td>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{this.compra.gestion | currency: 'USD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{'Flete Internacional' | translate}}</td>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{this.compra.flete || 0 | currency: 'USD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{'Servicio SuperBox' | translate}} </td>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{this.compra.servicio || 0 | currency: 'USD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{'Impuestos' | translate}}</td>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{this.compra.impuesto || 0 | currency: 'USD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                    <tr *ngIf="compra.encomienda > 0">
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{'Encomienda' | translate }}</td>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{compra.encomienda | currency: 'USD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                    <tr *ngIf="compra.otros_cargos > 0">
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{compraForm.value.otros_cargos_descripcion | translate }}</td>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{compra.otros_cargos | currency: 'USD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{'IVA' | translate | uppercase}}</td>
                      <td style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px;">
                        {{this.compra.iva | currency: 'USD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                    <tr>
                      <td
                        style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px; font-weight: bold">
                        {{'TOTAL' | translate | uppercase}}:</td>
                      <td
                        style="border: 1px solid #E9E7E7; line-height: 14px; letter-spacing: -0.5px; padding: 10px; font-weight: bold">
                        {{totalCompra | currency: 'USD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                  </table>

                  <p
                    style="font-family: 'Roboto'; font-style: normal; font-weight: normal; font-size: 16px; line-height: 22px; letter-spacing: -0.5px; color: #A2A2A2; padding-top: 20px;">
                    {{'Este cálculo está basado en un valor de $' | translate}} {{compra.precio || 0}} {{', un peso de '
                    | translate}}
                    {{ control?.value?.libra || 0 }} lbs. {{' y un arancel de ' | translate}} {{
                    control.value.producto?.value || 0}}%.
                    <br>
                    {{'Artículos como comida y bebidas, maquillaje, medicamentos, perfumes, cremas,
                    cuchillos, armas, entre otros requieren de un proceso adicional. Consulte antes de importar.' |
                    translate}}
                  </p>

                </div>

              </div>

              <div class="col-12" *ngIf="totalCompra > 0">
                <button mat-flat-button style="float: right;" (click)="$event.preventDefault(); sendCalculo()">
                  <mat-icon>send</mat-icon> Enviar por Correo Electrónico
                </button>
              </div>

            </div>

            <div style="background-color: #0094a3; border-radius: 5px 5px 0px 0px;margin-top: 20px;">
              <p
                style="text-align: center;color:white;  font-size: 20px; font-family: 'Varela Round'; line-height: 22px; font-size: 18px; font-style: normal;padding-top: 13px; padding-bottom: 13px; ">
                Totales
              </p>
            </div>
            <div class="col-12">
              <div fxLayout="row wrap" fxLayoutGap="20px" class="mt-4">
                <!-- <span class="w-200"><b>Total en Colones</b></span>
                <span class="user w-80 text-end">{{totalCompra * tipoCambio?.value | currency:'CRC':'symbol-narrow':'1.2-2'}}</span>-->

                <span class="w-200"><b>Total</b></span>
                <span class="user w-80 text-end">{{totalCompra | currency: 'USD':'symbol-narrow':'1.2-2'}}</span>
              </div>

              <div fxLayout="row wrap" fxLayoutGap="20px" class="mt-4">
                <!--  <span class="w-200"><b>Saldo Pendiente en Colones</b></span>
                <span class="user w-80 text-end">{{saldoPendiente * tipoCambio?.value | currency:'CRC':'symbol-narrow':'6.2-2'}}</span>-->

                <span class="w-200"><b>Saldo Pendiente</b></span>
                <span class="user w-80 text-end">{{saldoPendiente | currency: 'USD':'symbol-narrow':'1.2-2'}}</span>
              </div>

              <div fxLayout="row wrap" fxLayoutGap="20px" class="mt-4">
                <!-- <span class="w-200"><b>Total Pagado en Colones</b></span>
                <span class="user w-80 text-end">{{totalPagado * tipoCambio?.value | currency:'CRC':'symbol-narrow':'1.2-2'}}</span>-->

                <span class="w-200"><b>Monto Cancelado</b></span>
                <span class="user w-80 text-end">{{totalPagado | currency: 'USD':'symbol-narrow':'1.2-2'}}</span>
              </div>

            </div>

          </div>
        </div>

      </div>
    </div>
  </form>
</div>

<!--
<ng-container *ngIf="editMode">
  <div style="position: relative;" class="content-title">
    <p style="margin-top: 0.8rem;" class="title">Historial de Compra</p>
  </div>

  <div class="content-table m-4">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Nro. </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          {{element.id}}
        </td>
      </ng-container>

      <ng-container matColumnDef="fecha">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Fecha de compra </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element"> {{element.fecha_compra | date:
          'dd-MM-yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="tipo">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Tipo de compra </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element"> {{element.tipo_compra ?
          getTipoName(element.tipo_compra) : ''}} </td>
      </ng-container>

      <ng-container matColumnDef="descripcion">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Descripción </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          {{element.descripcion}}
        </td>
      </ng-container>

      <ng-container matColumnDef="remitente">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Remitente </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          {{element.remitente}}
        </td>
      </ng-container>

      <ng-container matColumnDef="colones">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Total en colones </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.colones}} </td>
      </ng-container>

      <ng-container matColumnDef="dolares">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Total en dólares </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.dolares}} </td>
      </ng-container>

      <ng-container matColumnDef="metodopago">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Método de pago </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.metodopago}} </td>
      </ng-container>

      <ng-container matColumnDef="created_by">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Modificado por </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{usuariosService.get_fullname(element.created_by)}} </td>
      </ng-container>
      <ng-container matColumnDef="update_at">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Fecha de actualización </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.updated_at | date: 'dd-MM-yyyy'}} </td>
      </ng-container>
      <ng-container matColumnDef="pago1">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Pago 1</th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          <mat-checkbox [checked]="element.saldoPendiente <= element.cuota_1" disabled></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="pago2">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Pago 2 </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          <mat-checkbox [checked]="element.saldoPendiente == 0" disabled></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="cuenta">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Cuenta </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.cuenta}}
        </td>
      </ng-container>

      <ng-container matColumnDef="observacion">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Observación </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element"> Compra Modificada</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsHistorial" [ngClass]="{'highlight-top-header': true }">
      </tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsHistorial; let odd = odd; "
        [ngClass]="{'highlight': odd }"></tr>
    </table>
  </div>
</ng-container>
 -->
