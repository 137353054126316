import { AuthService } from 'src/app/services/auth.service';
/* eslint-disable no-underscore-dangle */
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';

export interface PaqueteItems {
  paquete: number;
  anio: number;
  linea: number;
  cantidad: number;
  descripcion: string;
  precio: number;
  fob: number;
  partida: string;
  impuesto: number;
  porcentaje: number;
  procesado: boolean;
  tipo_cambio: number;
}

@Component({
  selector: 'app-paquete-items',
  templateUrl: './paquete-items.component.html',
  styleUrls: ['./paquete-items.component.scss']
})
export class PaqueteItemsComponent implements OnInit, AfterViewInit {
  @ViewChild('auto') auto: MatAutocomplete;

  @Input() id: any;
  @Input() column: any;
  @Input() paqueteItems: PaqueteItems[] = [];
  @Output() save = new EventEmitter<boolean>();
  @Input() showButtons: any;
  @Output() fob = new EventEmitter<number>();

  options: any = [];
  editMode = false;
  filteredOptions: Observable<[]>;
  productCrtl: any;

  form = this.fb.group({
    cantidad: [''],
    descripcion: [''],
    precio: [''],
    impuesto: [''],
    porcentaje: [''],
    partida: ['']
  });

  tipoCambio = 0;

  selectedIndex = 0;

  totalItems = 0;

  constructor(
    public authService: AuthService,
    private paquetesService: PaquetesService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private fb: FormBuilder,

  ) { }

  ngOnInit(): void {

    this.productCrtl = this.form.get('descripcion');
    this.filteredOptions = this.productCrtl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }

  ngAfterViewInit() {
    this.getItems();
  }

  getItems() {
    this.paqueteItems = [];
    this.paquetesService.getItems(this.id).subscribe((data: any) => {
      this.paqueteItems.push(...data);

      if (this.paqueteItems.length > 0) {
        this.tipoCambio = this.paqueteItems[0].tipo_cambio;
      }

      let fob = 0;
      this.paqueteItems.forEach((res) => {
        res.descripcion = res.descripcion;
        fob = fob + res.precio;
        this.fob.emit(fob);
      });

      if (this.paqueteItems.length > 0) {
        this.editMode = true;
      }
      this.calculateTotal();
    });
  }

  getItemsSearch(value) {
    if (value.length > 3) {
      this.paquetesService.getItemsSearch(value).subscribe(data => {
        this.options = data;
      });
    }
  }

  displayFn(product: any): string {
    return product && product.descripcion ? product.descripcion : '';
  }

  addItems() {
    const currentLine = this.paqueteItems.length + 1;

    this.paqueteItems.push({
      paquete: this.id,
      anio: Number(this.datePipe.transform(new Date(), 'yyyy')),
      linea: currentLine,
      cantidad: this.paqueteItems.length > 0 ? 0 : 1,
      descripcion: '',
      precio: null,
      fob: 0,
      partida: null,
      impuesto: 0,
      porcentaje: 0,
      procesado: false,
      tipo_cambio: 0
    });

    this.calculateTotal();
  }

  deleteItems(i) {
    //console.log(i);
    this.paqueteItems.splice(i, 1);
    this.calculateTotal();
  }

  saveItems() {
    //console.log(this.paqueteItems)
    this.paquetesService.putItems(this.paqueteItems, this.id).subscribe(data => {
      //console.log(data);
      this.openDialog('Items Actualizados');
      this.save.emit(true);
    });

  }

  public calculateTotal() {
    this.totalItems = this.paqueteItems.reduce((accum, curr) => accum + curr.precio, 0);
  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  private _filter(value: any) {
    //console.log('filter', value,);
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.descripcion.toLowerCase().includes(filterValue));
  }

}
