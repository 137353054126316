import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import { TarifaDeProductoService } from '../../../../../../services/tarifa-de-producto.service';
import { MatDialog } from '@angular/material/dialog';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { ConfirmarComponent } from 'src/app/modules/common/dialogs/confirmar/confirmar.component';

export interface PeriodicElement {
  descripcion: string;
  id: number;
  porcentaje: number;
  advertencia: string;
}

@Component({
  selector: 'app-index-tarifas-productos',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  length: number;

  displayedColumns: string[] = ['descripcion', 'porcentaje', 'advertencia', 'opciones'];
  dataSource = new MatTableDataSource<PeriodicElement>();
  selection = new SelectionModel<PeriodicElement>(true, []);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  deleteAction(id) {

    //console.log(id);
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      data: { message: 'Esta operación es definitiva y no puede reversarse. ¿Confirma que desea eliminar este producto calculadora?.' },
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
          this.tarifaDeProductoService.delete(id).subscribe((res: any) => {
            this.openDialog('Producto Calculadora eliminada');
            this.dataSource.data = this.dataSource.data.filter((value,key)=>value.id != id);
          });
      }
    });
  }
  private openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass:'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }
  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.tarifaDeProductoService.get().subscribe(
      (res: any)=>{
        const tarifaDeManejo: PeriodicElement[] = [];
        res.forEach(element => {
          tarifaDeManejo.push(
            { id: element.id,
              descripcion : element.description,
              porcentaje: element.value,
              advertencia: element.warning
            }
          );
        });
        this.length = tarifaDeManejo.length;
        //sort

      const s = tarifaDeManejo.sort((x,y)=>x.descripcion.localeCompare(y.descripcion));

        this.dataSource.data = s;
      }
    );
  }

  constructor(
    public tarifaDeProductoService: TarifaDeProductoService,
    private dialog: MatDialog,
  ) { }

}
