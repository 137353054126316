import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReportService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit {

  @Input() chart: any;
  @Input() i: number;

  @Output() addClose = new EventEmitter<boolean>();
  @Output() index = new EventEmitter<number>();

  @Input() dashboard: any;

  constructor() { }

  ngOnInit() { }

  public addChart = () => {
    this.index.emit(this.i);
    this.addClose.emit(true);
  };

  public closeChart = () => {
    this.index.emit(this.i);
    this.addClose.emit(false);
  };

}
