/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { NgxSpinnerService } from 'ngx-spinner';

import { EntregasService } from '../../../../../../services/entregas.service';
import { UsuariosService } from 'src/app/services/usuarios.service';


import { DatePipe } from '@angular/common';
import { NavigationService } from 'src/app/services/navigation.service';
import { Sort } from '@angular/material/sort';

export interface DeliverElement {
  id: number;
  date: string;
  conductor: string;
  paquetes: number;
  full: any;
}

export interface Conductores {
  id: number;
  nombre: string;
}

@Component({
  selector: 'app-entregas',
  templateUrl: './entregas.component.html',
  styleUrls: ['./entregas.component.scss']
})
export class EntregasComponent implements OnInit {
  search: string;

  length = 0;
  pageSize = 10;
  pageIndex = 1;

  displayedColumns: string[] = ['id', 'date', 'conductor', 'paquetes'];

  dataSource = [];
  selection = new SelectionModel<DeliverElement>(true, []);

  drivers: Conductores[];

  dataSourceEntregas = new MatTableDataSource<any>();
  displayedColumnsEntregas = ['guia', 'cliente', 'direccion'];

  currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  resumen;

  constructor(
    public deliverService: EntregasService,
    private spinnerService: NgxSpinnerService,
    public usersService: UsuariosService,
    private datePipe: DatePipe,
    public navigation: NavigationService,
  ) { }

  ngOnInit() {
    this.spinnerService.show();


    // Solicita lista de usuarios conductores
    this.usersService.get_driver_users().subscribe(
      (r: any) => {
        const data: Conductores[] = [];

        r.forEach(element => {
          data.push({ id: element.id, nombre: element.nombre });
        });

        this.drivers = data;

      }
    );

    this.getEntregas();
    this.deliverService.getSummary(this.currentDate).subscribe(
      (res: any) => {
        this.resumen = res;
        //console.log('resumen', this.resumen);
      });

  }

  getEntregas() {
    // Lista de Entregas
    this.deliverService.getSimple(this.pageIndex).subscribe(
      (res: any) => {
        const deliverList: DeliverElement[] = [];

        res.results.forEach(element => {
          deliverList.push({
            id: element.id,
            date: element.fecha,
            conductor: element.conductor_detalle.nombre,
            paquetes: element.package_count,
            full: element
          });
        });

        this.dataSource = deliverList;
        this.deliverService.deliverList = deliverList;
        this.length = res.count;

        //console.log(this.length);
        this.spinnerService.hide();
      }
    );

  }

  public isAllSelected = (): boolean => {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  };

  public masterToggle = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.forEach(row => this.selection.select(row));
  };

  public checkboxLabel = (row?: DeliverElement): string => {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  };


  getSummary(conductor) {
    let value = '';
    if (this.resumen !== undefined) {
      if (this.resumen.length > 0) {
        const result = this.resumen.find(t => t.conductor_id === conductor);

        if (result !== undefined) {
          value = result.entregados + ' / ' + result.total;
        } else {
          value = '0 / 0';
        }

        return (value);

      } else {
        value = '0 / 0';
        return (value);

      }
    } else {
      value = '0 / 0';
      return (value);

    }

  }

  getSummaryEntregaID(conductor) {
    let value = 0;
    if (this.resumen !== undefined) {
      if (this.resumen.length > 0) {
        const result = this.resumen.find(t => t.conductor_id === conductor);

        if (result !== undefined) {
          value = result.id;
        } else {
          value = 0;
        }

        return (value);

      } else {
        value = 0;
        return (value);

      }
    } else {
      value = 0;
      return (value);

    }

  }

  back() {
    this.navigation.back();
  }

  paginate(event: any) {
    //console.log('paginate', event);
    this.pageIndex = event;
    this.getEntregas();
  }

  sortData(sort: Sort) {
    console.log(sort);
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'nombre':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'cuenta':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'guia':
          return compare(a.guia, b.guia, isAsc);
        case 'peso_real':
          return compare(a.peso_total, b.peso_total, isAsc);
        case 'zona':
          return compare(a.entrega.zona_centro_distribucion, b.entrega.zona_centro_distribucion, isAsc);
        case 'tipo_paquete':
          return compare(a.tipo_paquete, b.tipo_paquete, isAsc);
        case 'modificado':
          return compare(a.ultima_actualizacion, b.ultima_actualizacion, isAsc);
        case 'factura':
          return compare(a.entrega.fecha_entrega, b.entrega.fecha_entrega, isAsc);
        case 'escaneado':
          return compare(a.escaneado_embarque, b.escaneado_embarque, isAsc);
        case 'usuario':
          return compare(a.nombre, b.nombre, isAsc);
        case 'factura':
          return compare(a.factura, b.factura, isAsc);
        case 'medidas':
          return compare(a.ancho, b.ancho, isAsc);
        case 'peso_volumetrico':
          return compare(a.peso_volumetrico, b.peso_volumetrico, isAsc);
        case 'paquetes':
          return compare(a.paquetes, b.paquetes, isAsc);
        case 'conductor':
          return compare(a.conductor, b.conductor, isAsc);
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'id':
          return compare(a.id, b.id, isAsc);

        default:
          return 0;
      }
    });
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  //console.log(a,b);
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
