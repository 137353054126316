<h2 class="h2-title p-4">Tipos de Cliente</h2>

<div class="content" fxLayout="column">
  <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones justify-content-center;">
    <div class="row" style="width: 100%;">
      <div class="col-sm botones-opciones">
      </div>
      <div class="col-sm text-right botones-opciones">
        <button (click)="crearTipo()" mat-button>AGREGAR TIPO</button>
        <span class="texto-encontrados">{{length}} tipos encontrados</span>
      </div>

    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="content-table mr-4 ml-4 mb-4">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell class="pr-pl-20 text-center" *matHeaderCellDef mat-sort-header> Tipo </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <span class="link"
            (click)="editarTipo(element.id, element.nombre, element.color, false)">{{element.id}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="nombre">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Nombre </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.nombre}} </td>
      </ng-container>

      <ng-container matColumnDef="color">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Color </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element">
          <input [(colorPicker)]="element.color" [style.background]="element.color"
            (colorPickerSelect)="editarTipo(element.id, element.nombre, element.color, true)" />
        </td>
      </ng-container>

      <!--acciones-->
      <ng-container matColumnDef="acciones">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Acciones</th>
        <td mat-cell *matCellDef="let element" class="action-link" style="text-align: center;">
          <mat-icon style="cursor: pointer;" (click)="deleteTipo(element.id)">delete</mat-icon>

        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }"></tr>
    </table>
  </div>
</div>