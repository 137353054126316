import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TiposDeCambioService {
  private url = `${environment.apiUrl}/tipos-de-cambio/`;

  constructor(
    private http: HttpClient
  ) { }

  get() {
    return this.http.get(this.url);
  }

  post(data) {
    return this.http.post(this.url, data);
  }

  ultimo_cambio() {
    return this.http.get(this.url + 'last/');
  }

  getRate(){
    return this.http.get('https://openexchangerates.org/api/latest.json?app_id=cacbbe1a2d1643af8fb34685df56be1f');
  }
}
