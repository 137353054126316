/* eslint-disable object-shorthand */
/* eslint-disable no-underscore-dangle */
/* eslint-disable eqeqeq */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable brace-style */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable max-len */
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { EntregasService } from '../../../../../../services/entregas.service';
import { PaquetesService } from '../../../../../../services/paquetes.service';
import { UsuariosService } from '../../../../../../services/usuarios.service';

import { DatePipe } from '@angular/common';
import { AuthService } from '../../../../../../services/auth.service';
import { Observable } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { GeneralDialogComponent } from '../../../../../../modules/common/dialogs/general-dialog/general-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProgramaEntregaComponent } from '../../../../../../modules/common/dialogs/programar-entrega/programar-entrega.component';
import { FacturasService } from 'src/app/services/facturas.service';

export interface PackageElement {
  id: number;
  cuenta: string;
  guia: string;
  cliente: string;
  tipo_paquete: string;
  metodo_pago: any;
  usuario: any;
  tracking: string;
  descripcion: string;
  total_factura: number;
  total_colones: number;
  plan: any;
  factura: any;
  clase: string;
  entrega: number;
}

export interface ClienteElement {
  id: number;
  name: string;
}

@Component({
  selector: 'app-programar-entregas',
  templateUrl: './programar-entregas.component.html',
  styleUrls: ['./programar-entregas.component.scss']
})
export class ProgramarEntregasComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selectedDate = new Date();

  displayedColumns: string[] = ['select', 'cuenta', 'descripcion', 'guia', 'cliente', 'tipo_paquete', 'total_dolares', 'total_colones'];
  dataSource = new MatTableDataSource<PackageElement>();
  selection = new SelectionModel<PackageElement>(true, []);

  paquetes = [];
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pages: any[] = [];
  pageIndex = 0;


  clienteForm: any;
  cliente: ClienteElement;
  clienteOptions: ClienteElement[];
  filteredOptions: Observable<ClienteElement[]>;

  totalFactura = 0;
  totalColones = 0;

  user: any;
  metodosPago;

  timeout: any = null;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private packagesService: PaquetesService,
    private usersService: UsuariosService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private billsServices: FacturasService
  ) {
  }

  ngOnInit(): void {
    this.clienteForm = this.fb.group({
      usuario: ['', [Validators.required]],
      fecha: [this.selectedDate, [Validators.required]],
    });
    this.getMetodosPago();

  }

  getPackagesEntrega() {
    this.spinnerService.show();
    this.paquetes = [];
    this.pages = [];
    this.selection.clear();
    const iduser = this.clienteForm.value.usuario.id;

    this.packagesService.getPendingsByUser(iduser).subscribe(
      (res: any) => {

        const paquetes_lista: PackageElement[] = [];
        //console.log('res', res);
        res.forEach(element => {
          const row = {
            id: element.id,
            guia: element.numero_de_guia,
            cliente: this.usersService.get_fullname(element.usuario),
            cuenta: this.usersService.get_account_number(element.usuario),
            tipo_paquete: element.tipo_de_paquete,
            metodo_pago: element.usuario.metodo_pago ? element.usuario.metodo_pago : '-',
            usuario: element.usuario,
            tracking: element.tracking,
            descripcion: element.descripcion,
            total_factura: element.factura != null ? element.factura.factura_usd.total : 0,
            total_colones: element.factura != null ? element.factura.factura_crc.total : 0,
            plan: element.plan,
            tarifa: element.tarifa,
            factura: element.factura,
            clase: element.clase,
            entrega: element.entrega.id
          };

          paquetes_lista.push(row);

        });

        this.paquetes = paquetes_lista;
        this.dataSource.data = this.paquetes;
        this.length = this.dataSource.data.length;

        for (let i = 0; i < (this.length / this.pageSize); i++) {
          this.pages.push(i);
        }

        this.spinnerService.hide();
      }
    );
  }

  setPackagesEntrega() {
    this.spinnerService.show();

    this.paquetes.forEach(element => {

      let fecha_entrega = null;
      if (this.selection.selected.filter(paquete => paquete.id === element.id).length > 0) {
        fecha_entrega = this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd');
      }

      const data = {
        id: element.id,
        fecha_entrega: fecha_entrega,
        logged_user: this.authService.getUser().id,
      };

      this.packagesService.put(element.id, data).subscribe((data: any) => { });

    });

    this.spinnerService.hide();
  }

  nextDate() {
    const nDate = this.selectedDate.setDate(this.selectedDate.getDate() + 1);
    this.selectedDate = new Date(nDate);
    //console.log(this.selectedDate);
    this.clienteForm.get('fecha').setValue(this.selectedDate);
  }

  previousDate() {
    const pDate = this.selectedDate.setDate(this.selectedDate.getDate() - 1);
    this.selectedDate = new Date(pDate);
    //console.log(this.selectedDate);
    this.clienteForm.get('fecha').setValue(this.selectedDate);
  }

  public isAllSelected = (): boolean => {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  };

  public masterToggle = (): void => {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    this.calcFactura();
  };

  public checkboxLabel = (row?: PackageElement): string => {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    this.calcFactura();
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  };

  public updateManualPage(index: number): void {
    this.dataSource.paginator.pageIndex = index + 1;
    this.dataSource.paginator.previousPage();
  }

  displayFn(value) {
    if (!value) { return ''; }
    return value.name;
  }

  buscarListaClientes(event = { keyCode: 13 }, cuenta = null) {
    clearTimeout(this.timeout);
    let $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.usersService.search(cuenta).subscribe((res: any) => {
          const datos: ClienteElement[] = [];
          res.forEach(element => {
            // eslint-disable-next-line max-len
            datos.push({ id: element.id, name: $this.usersService.get_account_number(element) + '-' + $this.usersService.get_fullname(element) });
          });
          $this.clienteOptions = datos;

          $this.filteredOptions = $this.clienteForm.controls.usuario.valueChanges.pipe(
            startWith(''),
            map(user => $this._filter(user, $this.clienteOptions)),
          );

          //console.log($this.clienteForm.value.usuario);
        }
        );
      }
    }, 1000);
  }

  public getErrorMessage(control) {

    if (control.hasError('required')) {
      return 'Campo necesario';
    }
    if (control.hasError('minlength')) {
      return 'Demasiado Corto';
    }
    if (control.hasError('maxlength')) {
      return 'Demasiado Largo';
    }
    if (control.hasError('min')) {
      return 'Límite mínimo';
    }
    if (control.hasError('email')) {
      return 'No es un correo valido';
    }
    if (control.hasError('invalidCode')) {
      return 'Código Invalido';
    }
    return 'Error';
  }


  public calcFactura() {
    this.totalFactura = 0;
    this.totalColones = 0;
    this.selection.selected.forEach(element => {
      if (element.clase != 'D') {
        this.totalFactura = this.totalFactura + element.total_factura;
        this.totalColones = this.totalColones + element.total_colones;
      }
    });
  }

  getMetodosPago() {
    this.usersService.getMetodosPagoPreviaEntrega().subscribe(data => {
      this.metodosPago = data;
    });
  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }

  pagarPaquetes() {
    if (this.selection.selected.length === 0) {
      this.openDialog('No ha seleccionado paquetes para pagar');
      return;
    }

    let result = true;
    for (const element of this.selection.selected) {

      if (element.plan === 'C') {
        this.openDialog('Ha seleccionado paquetes que solo se pueden consolidar');
        result = false;
        break;
      }

      if (element.factura === null) {
        this.openDialog('Ha seleccionado paquetes sin factura');
        result = false;
        break;
      }
    }

    if (result) {
      const dialogRef = this.dialog.open(ProgramaEntregaComponent, {
        data: { title: 'Programar Paquetes', paquetes: this.selection.selected, monto_usd: this.totalFactura, monto_crc: this.totalColones, tipo_cambio: this.selection.selected[0].factura.tipo_de_cambio.value, aprobado: true },
        height: '750px',
        width: '750px',
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        //console.log(result);

        if (result.status == true) {
          this.getPackagesEntrega();
        }

        if ((result.status == false && result.change == true)) {
          this.authService.getUserById(this.selection.selected[0].usuario.id).subscribe(data => {
            const metodoEntrega = data.body.modo_de_entrega;
            const direccionEntrega = data.body.direccion_entrega;
            const detallesAdicionales = data.body.detalles_adicionales;

            const provincia = data.body.provincia.id;
            const canton = data.body.canton.id;
            const distrito = data.body.distrito.id;

            const sucursal = data.body.sucursal;
            const ruta = data.body.ruta ? data.body.ruta.id : null;

            const direccionEntregaApps = data.body.direccion_entrega_apps;

            const tipoZonaEntrega = data.body.tipo_zona_entrega;

            const dataEntrega = {
              fecha_entrega: null,
              direccion_entrega: direccionEntrega,
              detalles_adicionales: detallesAdicionales,
              direccion_entrega_apps: direccionEntregaApps,
              tipo_zona_entrega: tipoZonaEntrega,
              logged_user: this.authService.getUser().id,
              modo_entrega: metodoEntrega,
              sucursal: sucursal,
              ruta: ruta,
              provincia: provincia,
              canton: canton,
              distrito: distrito,
              entrega_observacion: ''
            };

            this.selection.selected.forEach((paquete, index, array) => {
              this.packagesService.putEntrega(paquete.entrega, dataEntrega).subscribe(() => {
                this.crearFactura(paquete).then((res: any) => {

                  if (index == array.length - 1) {
                    this.getPackagesEntrega();
                  }

                });
              });
            });
          });
        }
      });
    }
  }

  crearFactura(paquete) {
    return new Promise((resolve, reject) => {

      const data = {
        completada: true,
        logged_user: this.authService.getUser().id,
      };

      this.billsServices.putFacturas(paquete.factura.id, data).subscribe((res: any) => {

        const dataFactura = {
          paquete: paquete.id,
          logged_user: this.authService.getUser().id,
        };

        this.billsServices.crearFactura(dataFactura).subscribe((data: any) => { resolve(data); }, (error: any) => {
          //console.log(error)
        });

      });
    });
  }

  sortData(sort: Sort) {
    // console.log(sort);
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'nombre':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'cuenta':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'guia':
          return compare(a.guia, b.guia, isAsc);
        case 'tipo_paquete':
          return compare(a.tipo_paquete, b.tipo_paquete, isAsc);
        case 'descripcion':
          return compare(a.descripcion, b.descripcion, isAsc);
        case 'tracking':
          return compare(a.tracking, b.tracking, isAsc);
        case 'total_dolares':
          return compare(a.total_factura, b.total_factura, isAsc);
        case 'total_colones':
          return compare(a.total_colones, b.total_colones, isAsc);

        default:
          return 0;
      }
    });
  }

  private _filter(value: any, options: any): ClienteElement[] {
    if (!value) { return options; }
    let filterValue: string;
    value.name ?
      filterValue = value.name.toLowerCase() :
      filterValue = value.toLowerCase();
    return options.filter(user => user.name.toLowerCase().includes(filterValue));
  }

}




function compare(a: number | string, b: number | string, isAsc: boolean) {
  //console.log(a,b);
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
