/* eslint-disable no-underscore-dangle */
import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UsuariosService } from '../../../services/usuarios.service';

import { PaquetesService } from 'src/app/services/paquetes.service';
import { EmbarquesService } from 'src/app/services/embarques.service';
import { Usuario } from 'src/app/models/Usuario';
import { PagosService } from 'src/app/services/pagos.service';
import { MatDialog } from '@angular/material/dialog';

import $ from 'jquery';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { GeneralDialogComponent } from '../../common/dialogs/general-dialog/general-dialog.component';
import { ColorService } from 'src/app/services/color.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy, DoCheck {
  mobileQuery: MediaQueryList;

  opened = false;

  user;
  title;
  chart;

  userRol;

  usuario: Usuario = null;
  pending = 0;
  pendingEmbarque = 0;
  pendingPagos = 0;
  pendingRoutes = 0;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  currentDate = new Date();
  version = '1.4.0';

  private _mobileQueryListener: () => void;

  constructor(
    public authService: AuthService,
    public usuariosService: UsuariosService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private paquetesService: PaquetesService,
    private embarquesService: EmbarquesService,
    private pagosService: PagosService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private colorService: ColorService,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  get bgColor(): string {
    return this.colorService.bgColor;
  }

  get envName(): string {
    return environment.type ?? 'develop';
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngDoCheck() {
    this.usuario = JSON.parse(localStorage.getItem('user'));
  }

  ngOnInit() {
    if (localStorage.getItem('adminTokens') != null) {
      const snackBar = this.snackBar.open('Conectado como Usuario', 'Cerrar', {
        duration: 600000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });

      snackBar.onAction().subscribe((_) => {
        this.closeConnectAsClient();
      });
    }

    $(() => {
      $('.user-dashboard-menu ul').addClass('hide-submenu');

      $('.user-dashboard-menu li a').on('click', (e: any) => {
        const a = $(e.currentTarget);
        const desplegado = $('.desplegado');
        const ulActual = $('.show-submenu');
        const ulEvent = a.siblings('ul');

        if (ulActual.length > 0 && ulEvent.length > 0) {
          if (ulEvent[0].className === 'show-submenu') {
            ulActual.addClass('hide-submenu');
            ulActual.removeClass('show-submenu');
            a.removeClass('desplegado');
          } else {
            ulActual.addClass('hide-submenu');
            ulActual.removeClass('show-submenu');
            desplegado.removeClass('desplegado');

            ulEvent.removeClass('hide-submenu');
            ulEvent.addClass('show-submenu');
            a.addClass('desplegado');
          }
        } else {
          ulEvent.toggleClass('hide-submenu');
          ulEvent.toggleClass('show-submenu');
          a.toggleClass('desplegado');
        }
      });

      $('.user-dashboard-menu-hiden ul').toggleClass('hide-submenu');

      $('.user-dashboard-menu-hiden li a').on('click', (e: any) => {
        const a = $(e.currentTarget);
        const desplegado = $('.desplegado');
        const ulActual = $('.show-submenu');
        const ulEvent = a.siblings('ul');

        if (ulActual.length > 0 && ulEvent.length > 0) {
          if (ulEvent[0].className === 'show-submenu') {
            ulActual.addClass('hide-submenu');
            ulActual.removeClass('show-submenu');
            a.removeClass('desplegado');
          } else {
            ulActual.addClass('hide-submenu');
            ulActual.removeClass('show-submenu');
            desplegado.removeClass('desplegado');

            ulEvent.removeClass('hide-submenu');
            ulEvent.addClass('show-submenu');
            a.addClass('desplegado');
          }
        } else {
          ulEvent.toggleClass('hide-submenu');
          ulEvent.toggleClass('show-submenu');
          a.toggleClass('desplegado');
        }
      });
    });

    this.user = this.authService.getUser();

    if (this.user.rol === 'ADMIN') {
      this.userRol = 'Usuario Administrador';
    }

    if (this.user.rol === 'ALMAC') {
      this.userRol = 'Usuario Almacen Miami';
    }

    if (this.user.rol === 'SERVCLI') {
      this.userRol = 'Usuario Servicio al cliente';
    }

    if (this.user.rol === 'PEDIM') {
      this.userRol = 'Usuario Pedimentación';
    }

    if (this.user.rol === 'OPMIA') {
      this.userRol = 'Usuario Operaciones Miami';
    }

    if (this.user.rol === 'OPCR') {
      this.userRol = 'Usuario Operaciones Costa Rica';
    }

    if (this.user.rol === 'OPCR2') {
      this.userRol = 'Usuario Operaciones Costa Rica 2';
    }

    this.paquetesService.getPendingConsolidate().subscribe((data: any) => {
      const res = data;
      this.pending = res.length;
    });

    this.getPendingEmbarques();

    this.pagosService.getComprobantePendientes().subscribe((data) => {
      let res: any;
      res = data;
      this.pendingPagos = res.length;
    });

    this.usuariosService.getNoRoutes().subscribe((data: any) => {
      let res: any;
      res = data;
      this.pendingRoutes = res.count;
    });
  }

  closeConnectAsClient() {
    const adminTokens = JSON.parse(localStorage.getItem('adminTokens'));
    const token = JSON.parse(adminTokens.token);
    const user = JSON.parse(adminTokens.user);

    this.setTokens(token, user);
  }

  logOut() {
    this.router.navigate(['/login']);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('authenticator');
    window.location.reload();
  }

  buscar(value) {
    const search = value.value.trim().toLowerCase();
    this.router.navigate(['admin/buscar'], { queryParams: { search } });
    value.value = '';
  }

  public getPendingEmbarques() {
    this.embarquesService.getPendingEmbarques().subscribe((res: any) => {
      this.pendingEmbarque = res;
    });
  }

  setTokens(token, user) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.removeItem('adminTokens');

    this.authService.activeRol = 'ADMIN';
    this.authService.setRol();
    this.authService.goToPage();
  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general',
    });

    dialogRef.afterOpened().subscribe((_) => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log(`Dialog result: ${result}`);
    });
  }
}
