/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/naming-convention */
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { GeneralInputComponent } from '../../../../../common/dialogs/general-input/general-input.component';
import { EntregasService } from 'src/app/services/entregas.service';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { ModoDeEntrega } from 'src/app/models/Usuario';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';

export interface Paquetes {
  id: number;
  guia: number;
  usuarioid: number;
  nombre: string;
  idestado: number;
  estado: string;
  cuenta: string;
  tracking: string;
  descripcion: string;
  peso_total: number;
  peso_volumetrico: number;
  codenvio: string;
  envio: string;
  creacion: Date;
  ultima_actualizacion: any;
  tipo_paquete: string;
  factura: string;
  cantpaquetes: number;
  estado_pago: number;
  metodo_pago: any;
  added?: any;
  entrega: any;
  primer_paquete: boolean;
  tipo_cliente: any;
  userLastRoute: number;
  paquete_contenedor: any;
  isExpanded: boolean;
}

export interface Conductores {
  id: number;
  nombre: string;
}

@Component({
  selector: 'app-index-entregas',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

export class IndexEntregasComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  status = 'distribucion';

  length = 0;
  pageSize = 10;
  pageIndex = 1;

  // eslint-disable-next-line max-len
  displayedColumns: string[] = ['seleccionar', 'cuenta', 'guia', 'tipo_cliente', 'peso_real', 'modificado'];
  paquetes: Paquetes[];
  filter = [];
  dataSource = new MatTableDataSource<Paquetes>();
  selectedPackages = new SelectionModel<Paquetes>(true, []);

  distribucion: Paquetes[];
  confirmados: Paquetes[];
  transito: Paquetes[];

  sucursalGuadalupe: Paquetes[];
  sucursalLimon: Paquetes[];

  drivers: Conductores[];

  currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  resumen;

  deliveryModes: any[] = [
    { name: 'Domicilio', value: ModoDeEntrega.enDomicilio },
    { name: 'Sucursal', value: ModoDeEntrega.enSucursal, },
    { name: 'Fuera del Área Metropolitana', value: ModoDeEntrega.areaMetropolitana, },
  ];

  constructor(
    private paquetesService: PaquetesService,
    public usuariosService: UsuariosService,
    public entregasService: EntregasService,
    public router: Router,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // Solicita lista de usuarios conductores
    this.usuariosService.get_driver_users().subscribe(
      (r: any) => {
        const data: Conductores[] = [];

        r.forEach(element => {
          data.push({ id: element.id, nombre: element.nombre });
        });

        this.drivers = data;

      }
    );

    this.getPackagesEntregas();

    this.entregasService.getSummary(this.currentDate).subscribe(
      (res: any) => {
        this.resumen = res;
        //console.log('resumen', this.resumen);
      });
  }

  getPackagesEntregas(all = false) {
    this.paquetes = [];
    this.spinner.show();
    this.paquetesService.getDistributionCenterSimple().subscribe(
      (res: any) => {
        // console.log('res', res);
        const paquetes_lista: Paquetes[] = [];
        res.forEach((element, index, array) => {
          if (element.entrega != null) {
            paquetes_lista.push({
              id: element.id,
              guia: element.numero_de_guia,
              nombre: this.usuariosService.get_fullname(element.usuario),
              idestado: element.estado_actual.id,
              estado: element.estado_actual.estado,
              usuarioid: element.usuario.id,
              cuenta: this.usuariosService.get_fullaccount(element.usuario),
              tracking: element.tracking,
              descripcion: element.descripcion,
              codenvio: element.plan,
              envio: element.plan.nombre,
              peso_total: element.peso_total,
              peso_volumetrico: element.peso_volumetrico,
              creacion: new Date(element.created_at),
              ultima_actualizacion: new Date(element.last_modif),
              tipo_paquete: element.tipo_de_paquete,
              factura: element.factura_1,
              cantpaquetes: element.paquete_contenedor.length === 0 ? 1 : element.paquete_contenedor.length,
              estado_pago: element.estado_pago,
              metodo_pago: element.metodo_pago,
              entrega: element.entrega,
              primer_paquete: element.primer_paquete,
              tipo_cliente: element.usuario?.tipo_cliente?.length > 0 ? element.usuario.tipo_cliente : [],
              userLastRoute: element.usuario.last_route,
              paquete_contenedor: element.paquete_contenedor,
              isExpanded: true,
            });
          }

          if (index == array.length - 1) {
            this.spinner.hide();

            //console.log(paquetes_lista);

            this.distribucion = paquetes_lista.filter(function (el) {
              return (el.idestado === 12 && (el.entrega.modo_entrega === 'ED' || el.entrega.modo_entrega === 'AM') && el.entrega.fecha_entrega == null && el.estado_pago === 0);
            });

            this.confirmados = paquetes_lista.filter(function (el) {
              return el.idestado === 12 && (el.entrega.modo_entrega === 'ED' || el.entrega.modo_entrega === 'AM') && (el.entrega.fecha_entrega != null || el.entrega.entrega != null);
            });
            this.setAlistadoStatus();
            this.transito = paquetes_lista.filter(function (el) {
              return el.idestado === 17 || (el.idestado === 12 && (el.entrega.modo_entrega === 'ED' || el.entrega.modo_entrega === 'AM') && el.entrega.entrega != null);
            });

            this.sucursalGuadalupe = paquetes_lista.filter(function (el) {
              return (el.idestado === 12 && el.entrega.modo_entrega === 'ES' && el.entrega.sucursal === 'Guadalupe');
            });

            this.sucursalLimon = paquetes_lista.filter(function (el) {
              return (el.idestado === 12 && el.entrega.modo_entrega === 'ES' && el.entrega.sucursal === 'Limón');
            });

            this.paquetes = paquetes_lista;
            this.dataSource.data = this.paquetes;

            this.length = paquetes_lista.length;

            if (this.authService.isServicioCliente) {
              this.changeTable(this.confirmados, 'confirmados');
            } else {
              this.changeTable(this.distribucion, 'distribucion');
            }


            if (this.length == 0) {
              this.spinner.hide();
            }
          }
        });

        if (res.length == 0) {
          this.spinner.hide();
        }

      }
    );
  }

  setAlistadoStatus() {
    const grouped = this.confirmados.reduce((acc, cur) => {
      (acc[cur.cuenta] = acc[cur.cuenta] || []).push(cur);
      return acc;
    }, {});

    Object.values(grouped).forEach((group: any[]) => {
      if (group.some(pkg => pkg.entrega.alistado === false)) {
        group.forEach(pkg => pkg.entrega.alistado = false);
      }
    });
  }

  public changeTable(filter, label) {
    this.status = label;

    this.filter = filter;
    this.dataSource.data = this.filter;
    this.length = this.dataSource.data.length;

    if (label === 'distribucion') {
      this.displayedColumns = ['seleccionar', 'cuenta', 'tipo_cliente', 'guia', 'peso_real', 'zona', 'tipo_de_paquete', 'modificado'];
    }

    if (label === 'confirmados') {
      this.displayedColumns = ['seleccionar', 'cuenta', 'guia', 'tipo_cliente', 'peso_real', 'fecha_entrega', 'zona_entrega', 'zona', 'tipo_de_paquete', 'modificado', 'metodo_pago', 'alistado'];

      this.dataSource.data.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      // this.dataSource.data.sort((a, b) => {
      //   const dateA = new Date(a.entrega.fecha_entrega).getTime();
      //   const dateB = new Date(b.entrega.fecha_entrega).getTime();
      //   const updateA = new Date(a.ultima_actualizacion).getTime();
      //   const updateB = new Date(b.ultima_actualizacion).getTime();

      //   return dateA - dateB || updateA - updateB;
      // });

      this.pageSize = this.length;
    }

    if (label === 'transito') {
      this.displayedColumns = ['cuenta', 'guia', 'tipo_cliente', 'peso_real', 'fecha_entrega', 'usuario', 'metodo_pago', 'entrega', 'driver', 'alistado'];
      this.pageSize = this.length;
    }

    if (label === 'guadalupe') {
      this.displayedColumns = ['seleccionar', 'cuenta', 'guia', 'tipo_cliente', 'peso_real', 'zona', 'tipo_de_paquete', 'modificado'];
    }

    if (label === 'limon') {
      this.displayedColumns = ['seleccionar', 'cuenta', 'guia', 'tipo_cliente', 'peso_real', 'zona', 'tipo_de_paquete', 'modificado'];
    }

  }

  public crearEntrega() {
    this.entregasService.selectedPackages = this.selectedPackages.selected;
    this.router.navigateByUrl('/almacen/entregas/agregar');
  }

  adjuntarPaquetes() {

    if (this.selectedPackages.selected.length > 0) {
      // Verification

      const dialogRef = this.dialog.open(GeneralInputComponent, {
        data: { title: 'Seleccione Entrega', value: 0, type: 'entregas' },
        height: '250px',
        width: '500px',
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != null) {
          //verification
          this.entregasService.selectedPackages = this.selectedPackages.selected;
          this.router.navigate(['/almacen/entregas/agregar'], { queryParams: { pack: result.value } });
        }

      });

    } else {
      this.openDialog('No hay paquetes seleccionados');
    }

  }

  public isAllSelected = (): boolean => {
    const numSelected = this.selectedPackages.selected.length;
    const numRows = this.filter.length;
    return numSelected === numRows;
  };

  public masterToggle = (): void => {
    if(this.authService.validateRolePermission('SHOW_DELIVERY_OPTIONS')){
    this.isAllSelected() ?
      this.selectedPackages.clear() :
      this.filter.forEach(row => this.selectedPackages.select(row));
    }
  };

  public checkboxLabel = (row?: Paquetes): string => {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectedPackages.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  };

  getSummary(conductor) {
    let value = '';
    if (this.resumen !== undefined) {
      if (this.resumen.length > 0) {
        const result = this.resumen.find(t => t.conductor_id === conductor);

        if (result !== undefined) {
          value = result.entregados + ' / ' + result.total;
        } else {
          value = '0 / 0';
        }

        return (value);

      } else {
        value = '0 / 0';
        return (value);

      }
    } else {
      value = '0 / 0';
      return (value);

    }

  }

  getSummaryEntregaID(conductor) {
    let value = 0;
    if (this.resumen !== undefined) {
      if (this.resumen.length > 0) {
        const result = this.resumen.find(t => t.conductor_id === conductor);

        if (result !== undefined) {
          value = result.id;
        } else {
          value = 0;
        }

        return (value);

      } else {
        value = 0;
        return (value);

      }
    } else {
      value = 0;
      return (value);

    }
  }

  getModoEntrega(value) {
    const item = this.deliveryModes.filter(function (e) {
      return e.value === value;
    });
    if (item[0] !== undefined && item[0] != null) {
      return item[0].name;
    }
    return '';
  }

  paginate(event: any) {
    //console.log('paginate', event);
    this.pageIndex = event;
  }

  changeStatus(event, element) {
    //console.log('changeStatus', event, element);
    element.alistado = event.checked;

    const data = {
      alistado: event.checked,
    };

    this.paquetesService.putEntrega(element.entrega.id, data).subscribe((res: any) => {
      //console.log(res);

    }, err => {
      console.error(err);
    });
  }

  sortData(sort: Sort) {
    //console.log(sort);
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'cuenta':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'guia':
          return compare(a.guia, b.guia, isAsc);
        case 'peso_real':
          return compare(a.peso_total, b.peso_total, isAsc);
        case 'zona':
          return compare(a.entrega.zona_centro_distribucion, b.entrega.zona_centro_distribucion, isAsc);
        case 'tipo_de_paquete':
          return compare(a.tipo_paquete, b.tipo_paquete, isAsc);
        case 'modificado':
          return compare(a.ultima_actualizacion, b.ultima_actualizacion, isAsc);
        case 'fecha_entrega':
          return compare(a.entrega.fecha_entrega, b.entrega.fecha_entrega, isAsc);
        case 'zona_entrega':
          return compare(a.entrega.canton.canton, b.entrega.canton.canton, isAsc);
        case 'usuario':
          return compare(a.nombre, b.nombre, isAsc);
        case 'metodo_pago':
          return compare(a.metodo_pago.nombre, b.metodo_pago.nombre, isAsc);
        case 'alistado':
          return compare(a.entrega.alistado, b.entrega.alistado, isAsc);
        case 'entrega':
          return compare(a.entrega.entrega.id, b.entrega.entrega.id, isAsc);
        case 'driver':
          return compare(a.entrega.entrega.conductor.nombre, b.entrega.entrega.conductor.nombre, isAsc);
        default:
          return 0;
      }
    });


  }

  private openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }

  isNextDayOrMonday(date: string): boolean {
    const today = moment();
    const tomorrow = moment().add(1, 'days');
    const nextMonday = moment().add(1, 'weeks').startOf('week').add(1, 'days');
    const deliveryDate = moment(date, 'YYYY-MM-DD');

    return deliveryDate.isSameOrBefore(tomorrow) || 
      (today.day() === 5 && deliveryDate.isSame(nextMonday));
  }
  
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  //console.log(a,b);
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
