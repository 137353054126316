/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import { PagosService } from 'src/app/services/pagos.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { ConfirmarComponent } from 'src/app/modules/common/dialogs/confirmar/confirmar.component';

export interface Actions {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.scss']
})
export class PagosComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  length: number;
  pageSize = 10;
  pageIndex = 1;

  displayedColumns: string[] = ['cuenta', 'guias', 'fecha', 'referencia', 'pago', 'monto_usd', 'monto_crc', 'respuesta', 'creador', 'opciones'];
  dataSource = [];
  selection = new SelectionModel<any>(true, []);
  selectedAction = '1';

  form = this.fb.group({
    paquete: [''],
    tipo: [''],
    date_from: [''],
    date_until: ['']
  });

  metodosPago;
  filter = '';

  constructor(
    private pagosService: PagosService,
    public usuariosService: UsuariosService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.getPagos();
    this.getMetodosPago();
  }

  getPagos() {
    this.pagosService.getPagos(this.pageIndex, this.filter).subscribe((data: any) => {
      this.dataSource = data.results;
      this.length = data.count;

    });
  }

  getMetodosPago() {
    this.usuariosService.getMetodosPago().subscribe(data => {
      this.metodosPago = data;

      this.metodosPago = this.metodosPago.filter(item => item.id != 4);

      this.metodosPago.sort();
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  applyFilter() {
    this.form.value.tipo == null ? this.form.value.tipo = '' : '';
    this.form.value.date_from == null ? this.form.value.date_from = '' : '';
    this.form.value.date_until == null ? this.form.value.date_until = '' : '';
    this.form.value.paquete == null ? this.form.value.paquete = '' : '';
    //console.log(this.form.value);

    let filter = '';

    Object.keys(this.form.value).forEach(async key => {
      if (this.form.value[key] !== '') {
        if (key === 'tipo') {
          filter = filter + '&metodo_pago=' + this.form.value[key];
        }

        if (key === 'paquete') {
          filter = filter + '&paquete=' + this.form.value[key];
        }

        if (key === 'date_from') {
          filter = filter + '&created_at__from=' + this.form.value[key];
        }

        if (key === 'date_until') {
          filter = filter + '&created_at__to=' + this.form.value[key];
        }
      }
    });

    //console.log(filter);
    this.filter = filter;
    this.getPagos();
  }

  deletePago(id) {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      // eslint-disable-next-line max-len
      data: { message: 'Esta operación es definitiva y no puede reversarse. ¿Confirma que desea eliminar este pago?.' },
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.pagosService.deletePago(id).subscribe(() => {
          this.openDialog('Pago Eliminado');
          this.ngOnInit();
        });
      }
    });
  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterClosed().subscribe(result => {

    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }

  public cleanFilters() {
    this.pageSize = 10;
    this.pageIndex = 1;
    this.filter = '';
    this.getPagos();
    this.form.reset();
  }

  paginate(event: any) {
    //console.log(event);
    this.pageIndex = event;
    this.getPagos();
  }

  sortData(sort: Sort) {
    //console.log(sort);
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'nombre':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'cuenta':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'guias':
          return compare(a.guias[0].guia, b.guias[0].guia, isAsc);
        case 'fecha':
          return compare(a.created_at, b.created_at, isAsc);
        case 'referencia':
          return compare(a.referencia, b.referencia, isAsc);
        case 'pago':
          return compare(a.metodo_pago.nombre, b.metodo_pago.nombre, isAsc);
        case 'monto_usd':
          return compare(a.monto, b.monto, isAsc);
        case 'monto_crc':
          return compare(a.monto_crc, b.monto_crc, isAsc);
        case 'creador':
          return compare(a.created_by, b.created_by, isAsc);
        default:
          return 0;
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
