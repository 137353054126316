import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { RutasService } from 'src/app/services/rutas.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-clientes-ruta',
  templateUrl: './clientes-ruta.component.html',
  styleUrls: ['./clientes-ruta.component.scss']
})
export class ClientesRutaComponent implements OnInit {

  usuarios;
  rutas;
  selectedRoute;

  rutaControl;

  ruta = [];

  filteredOptions: Observable<any[]>;

  displayedColumns: string[] = ['account', 'direccion', 'provincia', 'canton', 'distrito', 'ruta', 'date'];

  length = 0;
  pageSize = 50;
  pageIndex = 1;

  constructor(
    public usuariosService: UsuariosService,
    private rutasService: RutasService,
    private navigation: NavigationService,
    private authService: AuthService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.usuarios = [];
    this.rutas = [];

    this.usuariosService.getNoRoutes(this.pageIndex).subscribe((res: any) => {
      this.length = res.count;
      this.usuarios = res.results;
    });

    this.rutasService.get().subscribe((res) => {
      this.rutas = res;
    });
  }

  save() {
    const bar = new Promise<void>((resolve, reject) => {
      const promises = this.usuarios.map((usuario) => {
        const data = {
          ruta: usuario.ruta
        };
        return this.authService.updateUser(data, usuario.id).toPromise();
      });

      Promise.all(promises)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });

    bar
      .then(() => {
        this.openDialog('Rutas asignadas');
        this.ngOnInit();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });


    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  back() {
    this.navigation.back();
  }

  paginate(event: any) {
    //console.log('paginate',event);
    this.pageIndex = event;

    this.usuariosService.getNoRoutes(this.pageIndex).subscribe((res: any) => {
      this.length = res.count;
      this.usuarios = res.results;
    });
  }

  sortData(sort: Sort) {
    console.log(sort);
    const data = this.usuarios.slice();
    if (!sort.active || sort.direction === '') {
      this.usuarios = data;
      return;
    }

    this.usuarios = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'nombre':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'account':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'direccion':
          return compare(a.direccion, b.direccion, isAsc);
        case 'provincia':
          return compare(a.provincia?.provincia, b.provincia?.provincia, isAsc);
        case 'canton':
          return compare(a.canton?.canton, b.canton?.canton, isAsc);
        case 'distrito':
          return compare(a.distrito?.distrito, b.distrito?.distrito, isAsc);
        case 'date':
          return compare(a.created_at, b.created_at, isAsc);

        default:
          return 0;
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  //console.log(a,b);
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
