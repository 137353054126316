import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { TarifasService } from 'src/app/services/tarifas.service';

export interface Tarifa {
  id: number;
  type: string;
  min_amount: number;
  max_amount: number;
  amount: number;
  is_amount_fixed: boolean;
  tarifa: number;
  values: any;
  edit: boolean;
}

@Component({
  selector: 'app-tarifa-modal',
  templateUrl: './tarifa-modal.component.html',
  styleUrls: ['./tarifa-modal.component.scss']
})
export class TarifaModalComponent implements OnInit {
  tarifa: any;
  editMode = false;

  form = this.fb.group({
    // Necesarios para el modelo
    min_amount: ['', [Validators.required, Validators.min(0)]],
    max_amount: ['', [Validators.required]],
    amount: ['', [Validators.required]],
    is_amount_fixed: [false],
    tarifa: ['', [Validators.required]],
  });

  constructor(
    public dialogRef: MatDialogRef<TarifaModalComponent>,
    private spinnerService: NgxSpinnerService,
    private fb: FormBuilder,
    public tarifasService: TarifasService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Tarifa
  ) { }

  ngOnInit(): void {
    this.tarifa = this.data;

    //console.log(this.tarifa);

    this.form.controls.min_amount.setValue(this.tarifa.min_amount);
    this.form.controls.max_amount.setValue(this.tarifa.max_amount);
    this.form.controls.amount.setValue(this.tarifa.amount);
    this.form.controls.is_amount_fixed.setValue(this.tarifa.is_amount_fixed);
    this.form.controls.tarifa.setValue(this.tarifa.tarifa);

    this.editMode = this.tarifa.edit;
  }

  public getErrorMessage(control) {
    if (control.hasError('required')) {
      return 'Campo necesario';
    }
    if (control.hasError('minlength')) {
      return 'Demasiado Corto';
    }
    if (control.hasError('maxlength')) {
      return 'Demasiado Largo';
    }
    if (control.hasError('pattern')) {
      return 'Formato ínvalido';
    }
    if (control.hasError('min')) {
      return 'Límite mínimo';
    }
    if (control.hasError('range')) {
      return 'Rango Inválido';
    }
    return 'Error';
  }

  save() {
    //console.log(this.tarifa.tipo);
    //console.log(this.editMode);

    if (this.tarifa.tipo == 'flete') {
      this.saveFlete();
    }

    if (this.tarifa.tipo == 'manejo') {
      this.saveManejo();
    }

    if (this.tarifa.tipo == 'bodegaje') {
      this.saveBodegaje();
    }

    if (this.tarifa.tipo == 'embalaje') {
      this.saveEmbalaje();
    }

    if (this.tarifa.tipo == 'correspondencia') {
      this.saveCorrespondencia();
    }

    if (this.tarifa.tipo == 'transporte') {
      this.saveTransporte();
    }
  }

  public saveFlete() {
    //console.log(this.form);
    if (this.form.valid) {
      this.spinnerService.show();
      //console.log(this.form.value);
      if (!this.editMode) {
        this.tarifasService.postTarifaFlete(this.form.value).subscribe(
          (data: any) => {
            this.spinnerService.hide();
            this.openDialog('Tarifa Creada Exitosamente');
            this.dialogRef.close();
          }, err => {
            this.openDialog('Ocurrio un error');
            this.spinnerService.hide();
            console.error(err);
          }
        );
      } else {
        this.tarifasService.updateTarifaFlete(this.tarifa.id, this.form.value).subscribe(
          (data: any) => {
            this.spinnerService.hide();
            this.openDialog('Tarifa Actualizada Exitosamente');
            this.dialogRef.close();
          }, err => {
            this.openDialog('Ocurrio un error');
            this.spinnerService.hide();
            console.error(err);
          }
        );
      }
    }
  }

  public saveManejo() {
    if (this.form.valid) {
      this.spinnerService.show();
      //console.log(this.form.value);
      if (!this.editMode) {
        this.tarifasService.postTarifaManejo(this.form.value).subscribe(
          (data: any) => {
            this.spinnerService.hide();
            this.openDialog('Tarifa Creada Exitosamente');
            this.dialogRef.close();
          }, err => {
            this.openDialog('Ocurrio un error');
            this.spinnerService.hide();
            console.error(err);
          }
        );
      } else {
        this.tarifasService.updateTarifaManejo(this.tarifa.id, this.form.value).subscribe(
          (data: any) => {
            this.spinnerService.hide();
            this.openDialog('Tarifa Actualizada Exitosamente');
            this.dialogRef.close();
          }, err => {
            this.openDialog('Ocurrio un error');
            this.spinnerService.hide();
            console.error(err);
          }
        );
      }
    }
  }

  public saveBodegaje() {
    if (this.form.valid) {
      this.spinnerService.show();
      //console.log(this.form.value);
      if (!this.editMode) {
        this.tarifasService.postTarifaBodegaje(this.form.value).subscribe(
          (data: any) => {
            this.spinnerService.hide();
            this.openDialog('Tarifa Creada Exitosamente');
            this.dialogRef.close();
          }, err => {
            this.openDialog('Ocurrio un error');
            this.spinnerService.hide();
            console.error(err);
          }
        );
      } else {
        this.tarifasService.updateTarifaBodegaje(this.tarifa.id, this.form.value).subscribe(
          (data: any) => {
            this.spinnerService.hide();
            this.openDialog('Tarifa Actualizada Exitosamente');
            this.dialogRef.close();
          }, err => {
            this.openDialog('Ocurrio un error');
            this.spinnerService.hide();
            console.error(err);
          }
        );
      }
    }
  }

  public saveEmbalaje() {
    if (this.form.valid) {
      this.spinnerService.show();
      //console.log(this.form.value);
      if (!this.editMode) {
        this.tarifasService.postTarifaEmbalaje(this.form.value).subscribe(
          (data: any) => {
            this.spinnerService.hide();
            this.openDialog('Tarifa Creada Exitosamente');
            this.dialogRef.close();
          }, err => {
            this.openDialog('Ocurrio un error');
            this.spinnerService.hide();
            console.error(err);
          }
        );
      } else {
        this.tarifasService.updateTarifaEmbalaje(this.tarifa.id, this.form.value).subscribe(
          (data: any) => {
            this.spinnerService.hide();
            this.openDialog('Tarifa Actualizada Exitosamente');
            this.dialogRef.close();
          }, err => {
            this.openDialog('Ocurrio un error');
            this.spinnerService.hide();
            console.error(err);
          }
        );
      }
    }
  }

  public saveCorrespondencia() {
    if (this.form.valid) {
      this.spinnerService.show();
      //console.log(this.form.value);
      if (!this.editMode) {
        this.tarifasService.postTarifaCorrespondencia(this.form.value).subscribe(
          (data: any) => {
            this.spinnerService.hide();
            this.openDialog('Tarifa Creada Exitosamente');
            this.dialogRef.close();
          }, err => {
            this.openDialog('Ocurrio un error');
            this.spinnerService.hide();
            console.error(err);
          }
        );
      } else {
        this.tarifasService.updateTarifaCorrespondencia(this.tarifa.id, this.form.value).subscribe(
          (data: any) => {
            this.spinnerService.hide();
            this.openDialog('Tarifa Actualizada Exitosamente');
            this.dialogRef.close();
          }, err => {
            this.openDialog('Ocurrio un error');
            this.spinnerService.hide();
            console.error(err);
          }
        );
      }
    }
  }

  public saveTransporte() {
    if (this.form.valid) {
      this.spinnerService.show();

      this.form.value.encomienda = this.tarifa.empresa;

      //console.log(this.form.value);

      if (!this.editMode) {
        this.tarifasService.postTarifaTransporte(this.form.value).subscribe(
          (data: any) => {
            this.spinnerService.hide();
            this.openDialog('Tarifa Creada Exitosamente');
            this.dialogRef.close();
          }, err => {
            this.openDialog('Ocurrio un error');
            this.spinnerService.hide();
            console.error(err);
          }
        );
      } else {

        this.tarifasService.updateTarifaTransporte(this.tarifa.id, this.form.value).subscribe(
          (data: any) => {
            this.spinnerService.hide();
            this.openDialog('Tarifa Actualizada Exitosamente');
            this.dialogRef.close();
          }, err => {
            this.openDialog('Ocurrio un error');
            this.spinnerService.hide();
            console.error(err);
          }
        );
      }
    }
  }


  public checkRange(control) {

    const n = this.form.controls[control].value;
    //console.log(n);

    //console.log(this.data.edit);

    this.data.values.forEach(limit => {
      if (this.data.edit === true) {
        if (limit.id !== this.data.id) {
          if (Number(n) >= Number(limit.min_amount) && Number(n) <= Number(limit.max_amount)) {
            //console.log('Invalid');
            this.form.controls[control].setValue('');
            this.form.controls[control].setErrors({ range: true });
            return false;
          }
        }
      } else {
        if (Number(n) >= Number(limit.min_amount) && Number(n) <= Number(limit.max_amount)) {
          //console.log('Invalid');
          this.form.controls[control].setValue('');
          this.form.controls[control].setErrors({ range: true });
          return false;
        }
      }
    });
  }


  public onNoClick = (): void => {
    this.dialogRef.close();
  };

  private openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }
}
