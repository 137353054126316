<div class="p-3 content" fxLayout="column">
  <div class="content-title">
    <p class="title">Descuento - Insertando nuevos datos</p>
  </div>
</div>

<div class="pt-4 mt-3" style="display: flex; align-items: center; justify-content: space-between;">
  <button mat-button [routerLink]="['/admin/administracion/descuentos']"
    style="padding: 0; padding-right: 10px; color: #0094a3; float:left">
    <mat-icon>keyboard_arrow_left</mat-icon> DESCUENTOS
  </button>

  <!--  <button mat-button>Ver Informe de Promoción</button> -->

  <button *ngIf="descuentoId == 0" mat-button (click)="create()" style="padding: 0; margin-right: 0px; color: #0094a3">
    GUARDAR <mat-icon>save</mat-icon>
  </button>

  <button *ngIf="descuentoId > 0" mat-button (click)="update()" style="padding: 0; margin-right: 0px; color: #0094a3">
    GUARDAR <mat-icon>save</mat-icon>
  </button>
</div>


<div class="content">
  <form [formGroup]="form">

    <div fxLayout="row wrap" fxLayoutGap="20px" class="p-3 mb-3" style="display: flex; align-items: center;">

      <mat-form-field appearance="outline">
        <mat-label>Por Valor $ o Porcentual</mat-label>
        <mat-select required formControlName="discount_type">
          <mat-option *ngFor="let discount of discountTypes " [value]="discount.value">{{discount.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls['discount_type'].errors">
          {{getErrorMessage(form.controls['discount_type'])}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Valor o Porcentaje</mat-label>
        <input matInput placeholder="Valor o Porcentaje" formControlName="value">
        <mat-error *ngIf="form.controls['value'].errors">
          {{getErrorMessage(form.controls['value'])}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Código Promocional</mat-label>
        <input (keypress)="keyPress($event)" style="text-transform: uppercase;" matInput placeholder="Código Promocional" formControlName="promo_code">
        <mat-error *ngIf="form.controls['promo_code'].errors">
          {{getErrorMessage(form.controls['promo_code'])}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Límite de uso</mat-label>
        <input matInput placeholder="Límite de uso" formControlName="limit">
        <mat-error *ngIf="form.controls['limit'].errors">
          {{getErrorMessage(form.controls['limit'])}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Fecha de Inicio</mat-label>
        <input type="date" matInput (ngModelChange)="changeDateStart($event)" placeholder="Fecha de Inicio" formControlName="started_at">
        <mat-error *ngIf="form.controls['started_at'].errors">
          {{getErrorMessage(form.controls['started_at'])}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Fecha de Finalización</mat-label>
        <input type="date" matInput (ngModelChange)="changeDateFinal($event)" placeholder="Fecha de Finalización" formControlName="finishing_at">
        <mat-error *ngIf="form.controls['finishing_at'].errors">
          {{getErrorMessage(form.controls['finishing_at'])}}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="content p-3">
      <div class="content-title pt-0">
        <p class="title">{{'Descuento Disponible Para:' | uppercase}}</p>
      </div>
      <div class="mt-4 pt-5 row">
        <mat-checkbox class="col-12 col-md-4" formControlName="aereo">
          Súper Rápido (Aéreo)
        </mat-checkbox>
        <mat-checkbox class="col-12 col-md-4" formControlName="maritimo">
          Súper Económico (Maritímo)
        </mat-checkbox>
      </div>
    </div>

   <!-- <div class="content p-3">
      <div class="content-title">
        <p class="title">{{'Exonerar:' | uppercase}}</p>
      </div>
      <div class="mt-4 pt-5 row">
        <mat-checkbox class="col-12 col-md-4" formControlName="exonerar_flete">
          Exonerar Flete
        </mat-checkbox>
        <mat-checkbox class="col-12 col-md-4" formControlName="exonerar_warehouse">
          Exonerar Warehouse
        </mat-checkbox>
        <mat-checkbox class="col-12 col-md-4" formControlName="exonerar_manejos">
          Exonerar Manejos
        </mat-checkbox>
        <mat-checkbox class="col-12 col-md-4" formControlName="exonerar_seguro">
          Exonerar Seguro
        </mat-checkbox>
        <mat-checkbox class="col-12 col-md-4" formControlName="exonerar_combustible">
          Exonerar Combustible
        </mat-checkbox>
      </div>
    </div>-->
  </form>
</div>
