import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RutasService {
  public routesList;
  private urlRoutes = `${environment.apiUrl}/rutas/`;

  constructor(
    private http: HttpClient
  ) { }

  get() {
    return this.http.get(this.urlRoutes);
  }

  getMetro() {
    return this.http.get(this.urlRoutes + 'metro');
  }

  getEncomienda() {
    return this.http.get(this.urlRoutes + 'encomienda');
  }

  post(data){
    return this.http.post(this.urlRoutes, data);
  }

  put(id,data){
    return this.http.put( this.urlRoutes + id, data);
  }
}
