import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';

import { GeneralDialogComponent } from '../../../../../common/dialogs/general-dialog/general-dialog.component';

import { PaquetesService } from '../../../../../../services/paquetes.service';
import { UsuariosService } from '../../../../../../services/usuarios.service';

@Component({
  selector: 'app-accion',
  templateUrl: './accion.component.html',
  styleUrls: ['./accion.component.scss']
})
export class AccionComponent implements OnInit {
  editionMode: boolean;
  estadoPaquete: any;
  form = this.fb.group({
    estado: [null, [Validators.required, Validators.maxLength(255)]],
    progreso: [null, [Validators.required, Validators.min(0), Validators.max(100)]],
    check_transportista: [false],
    visualizacion: [false]
  });
  constructor(
    public usuariosService: UsuariosService,
    public paquetesService: PaquetesService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.editionMode = false;
    //Obtiene la ruta a editar
    this.activatedRoute.queryParams.subscribe(params => {
      //Si existe ID entra en modo edición
      if (params.id) {
        this.editionMode = true;
        this.paquetesService.getEstadoPaquete(params.id).subscribe(
          (res) => {
            this.estadoPaquete = res;
            this.form.controls.estado.setValue(this.estadoPaquete.estado);
            this.form.controls.progreso.setValue(this.estadoPaquete.progreso);
            this.form.controls.check_transportista.setValue(this.estadoPaquete.check_transportista);
            this.form.controls.visualizacion.setValue(this.estadoPaquete.visualizacion);
          }
        );
      }
    });
  }

  public addAction() {
    if (this.form.valid) {
      this.spinnerService.show();
      this.paquetesService.postEstadoPaquete(this.buildData()).subscribe(
        (res) => {
          this.form.reset();
          this.spinnerService.hide();
          this.openDialog('Acción creada Exitosamente');
        }
      );
    }
  }

  public updateAction() {
    if (this.form.valid) {
      this.spinnerService.show();
      this.paquetesService.putEstadoPaquete(this.estadoPaquete.id, this.buildData()).subscribe(
        (res) => {
          this.spinnerService.hide();
          this.openDialog('Acción actualizada Exitosamente');
        }
      );
    }
  }
  public buildData() {
    if (this.editionMode){
      return {
        estado: this.form.value.estado,
        progreso: this.form.value.progreso,
        visualizacion: this.form.value.visualizacion,
        check_transportista: this.form.value.check_transportista,
        updated_by: this.usuariosService.usuario.id
      };
    }
  }

  public getErrorMessage(control) {
    if (control.hasError('required')) {
      return 'Campo necesario';
    }
    if (control.hasError('min')) {
      return 'Límite mínimo';
    }
    if (control.hasError('max') || control.hasError('maxlength')) {
      return 'Límite maximo';
    }
    return 'Error';
  }
  private openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass:'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }
}
