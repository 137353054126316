<div class="dashboard-container" [class.is-mobile]="mobileQuery.matches" [style.background-color]="bgColor">
  <mat-toolbar [style.background-color]="bgColor">
    <button class="d-block d-lg-none" mat-icon-button (click)="snav.toggle()">
      <mat-icon style="color: #ffffff;">menu</mat-icon>
    </button>

    <div>
      <a [routerLink]="['/admin']" (click)="title = ''" class="logo-header">
        <img src="./assets/logo-superbox2.png" />
      </a>
    </div>

    <p class="title-header" *ngIf="!mobileQuery.matches">
      {{ userRol }}
      <span *ngIf="envName !== 'production'">
        Ambiente: [{{ envName}}]
      </span>
    </p>

    <span class="flexExpand"></span>

    <input type="text" placeholder="Buscar..." class="barra-de-busqueda" #search (keyup.enter)="buscar(search)"
      *ngIf="!mobileQuery.matches" />

    <p class="texto" *ngIf="!mobileQuery.matches">
      {{ usuariosService.get_account_number(usuariosService.usuario) }} (P.O.
      Box)
    </p>

    <p class="texto" *ngIf="!mobileQuery.matches && usuario">
      {{ usuario.email }}
    </p>

    <button mat-button [matMenuTriggerFor]="menu">
      <img class="img-perfil" src="../../../../../assets/iconos-dashboard/Avatar.png" style="width: 32px" alt />
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="title = 'Perfil'" [routerLink]="['/admin/perfil']">
        Perfil
      </button>
      <button mat-menu-item (click)="logOut()">Cerrar Sesión</button>
    </mat-menu>

    <a class="logout" title="Cerrar Sesión" (click)="logOut()" *ngIf="!mobileQuery.matches">
      <img src="../../../../../assets/iconos-dashboard/cerrarsession.png" style="width: 30px" alt />
    </a>
  </mat-toolbar>

  <!--menu horizontal-->
  <nav [style.background-color]="bgColor" style="align-self: center;" class="navbar navbar-expand-lg navbar-light">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ng-container *ngTemplateOutlet="mainMenu"></ng-container>
    </div>
  </nav>
  <!--end menu horizontal--->

  <mat-sidenav-container class="sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <!--menu mobile-->
    <mat-sidenav class="d-block d-lg-none" #snav [(opened)]="opened" [mode]="mobileQuery.matches ? 'over' : 'over'"
      [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <nav style="align-self: center; background-color: #0094a3 !important"
        class="navbar navbar-expand-lg navbar-light bg-light">
        <ng-container *ngTemplateOutlet="mainMenu"></ng-container>
      </nav>
    </mat-sidenav>
    <!--end mobile-->

    <!--main view-->
    <mat-sidenav-content fxLayout="column">
      <div style="height: 100vh" fxLayout="column">
        <div class="pl-2 pr-1 pt-1 pb-2" fxFlex>
          <router-outlet></router-outlet>
        </div>

        <div fxFlexOffset="auto" fxFlex="50px" fxLayoutAlign="center center" class="footer">
          Copyright {{ currentDate.getFullYear() }} @ SuperBox. Todos los derechos reservados. V-{{ version }}
        </div>
      </div>
    </mat-sidenav-content>
    <!--end main view-->
  </mat-sidenav-container>

</div>

<ng-template #mainMenu>
  <ul class="navbar-nav mr-auto">
    <!-- Menu Paquetes -->
    <li *ngIf="
              authService.isAdmin ||
              authService.isServicioCliente ||
              authService.isPedimentacion ||
              authService.isOperacionesMiami ||
              authService.isOperacionesCostaRica ||
              authService.isOperacionesCostaRica2 ||
              authService.isContabilidad
            " class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <i class="fa fa-cubes" style="margin-right: 10px"></i>Paquetes
      </a>

      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <a class="dropdown-item" title="Buscar Paquete" [routerLink]="['/admin/paquetes/buscar']">
          <i style="margin-right: 10px" class="fa fa-cubes"></i>Buscar</a>

        <a *ngIf="
                  authService.isAdmin ||
                  authService.isOperacionesCostaRica ||
                  authService.isOperacionesCostaRica2
                " class="dropdown-item" title="Nuevo" [routerLink]="['/admin/paquetes/paquete']">
          <i style="margin-right: 10px" class="fa fa-plus"></i>Nuevo</a>
      </div>
    </li>

    <!-- Menu Cuentas -->
    <li *ngIf="
              authService.isAdmin ||
              authService.isServicioCliente ||
              authService.isOperacionesCostaRica ||
              authService.isContabilidad
            " class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <i style="margin-right: 10px" class="fa fa-id-card"></i>Cuentas
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <a class="dropdown-item" title="Buscar Cuenta" [routerLink]="['/admin/cuentas/']"><span><i
              style="margin-right: 10px" class="fa fa-id-card"></i>Buscar</span></a>

        <a *ngIf="authService.isAdmin || authService.isServicioCliente" class="dropdown-item" title="Crear Cuenta"
          [routerLink]="['/admin/cuentas/crear/fisica']"><span><i style="margin-right: 10px"
              class="fa fa-users"></i>Crear
            Cuenta Física</span></a>

        <a *ngIf="authService.isAdmin || authService.isServicioCliente" class="dropdown-item" title="Crear Cuenta"
          [routerLink]="['/admin/cuentas/crear/juridica']"><span><i style="margin-right: 10px"
              class="fa fa-building"></i>Crear
            Cuenta Jurídica</span></a>

        <a *ngIf="authService.isAdmin" class="dropdown-item" title="Crear Cuenta"
          [routerLink]="['/admin/cuentas/superbox']"><span><i style="margin-right: 10px"
              class="fa fa-building"></i>Crear
            Cuenta SuperBox</span></a>
      </div>
    </li>

    <!-- Menu Miami -->
    <li routerLinkActive="ruta-activa" class="nav-item dropdown" *ngIf="
        authService.isMiami ||
        authService.isOperacionesMiami ||
        authService.isOperacionesCostaRica ||
        authService.isOperacionesCostaRica2">

      <a class="nav-link dropdown-toggle" title="Actualización Multiple"
        [routerLink]="['/admin/almacen/capturarPaquetes']" [queryParams]="{ tipo: 1 }"><span><i
            style="margin-right: 10px" class="fa fa-th"></i>Actualización
          Multiple</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isMiami" class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" title="Capturar Guía" [routerLink]="['/admin/almacen/capturarPaquetes']"
        [queryParams]="{ tipo: 2 }" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false"><span><i style="margin-right: 10px" class="fa fa-th-large"></i>Capturar
          Guía</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isMiami" class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" title="Capturar embarques" [routerLink]="['/admin/almacen/capturarEmbarques']"
        id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span><i
            style="margin-right: 10px" class="fa fa-th-large"></i>Revisión
          de Embarque</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isMiami" class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" title="Consolidados" [routerLink]="['/admin/almacen/consolidados']"
        id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span><i
            style="margin-right: 10px" class="fa fa-archive"></i>Consolidados
          ({{ pending }})</span></a>
    </li>

    <!-- Menu Almacen -->

    <li *ngIf="
              authService.isAdmin ||
              authService.isEmbarques ||
              authService.isOperacionesMiami ||
              authService.isOperacionesCostaRica ||
              authService.isOperacionesCostaRica2 ||
              authService.isServicioCliente
            " class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <i style="margin-right: 10px" class="fa fa-building"></i>Almacen ({{
        pending
        }})
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
    <li routerLinkActive="ruta-activa" *ngIf="
                  authService.isAdmin ||
                  authService.isEmbarques">
      <a class="dropdown-item" title="Actualización Multiple" [routerLink]="['/admin/almacen/capturarPaquetes']"
        [queryParams]="{ tipo: 1 }"><span><i style="margin-right: 10px" class="fa fa-th"></i>Actualización
          Multiple</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isAdmin">
      <a class="dropdown-item" title="Capturar Guía" [routerLink]="['/admin/almacen/capturarPaquetes']"
        [queryParams]="{ tipo: 2 }">
        <span><i style="margin-right: 10px" class="fa fa-th-large"></i>Capturar Guía</span>
      </a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isMiami">
      <a class="dropdown-item" title="Capturar Guía" [routerLink]="['/admin/almacen/capturarPaquetes']"
        [queryParams]="{ tipo: 2 }"><span><i style="margin-right: 10px" class="fa fa-th-large"></i>Capturar
          Guía</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isMiami || authService.isEmbarques">
      <a class="dropdown-item" title="Capturar embarques" [routerLink]="['/admin/almacen/capturarEmbarques']"><span><i
            style="margin-right: 10px" class="fa fa-th-large"></i>Revisión de Embarque</span></a>
    </li>
    <li routerLinkActive="ruta-activa"
      *ngIf="authService.isAdmin || authService.isOperacionesCostaRica || authService.isOperacionesCostaRica2">
      <a class="dropdown-item" title="Capturar embarques"
        [routerLink]="['/admin/almacen/capturarEmbarquesAdmin']"><span><i style="margin-right: 10px"
            class="fa fa-th-large"></i>Revisión de Embarque (Dist.)</span></a>
    </li>
    <li routerLinkActive="ruta-activa" *ngIf="
                  authService.isAdmin ||
                  authService.isOperacionesMiami ||
                  authService.isServicioCliente
                ">
      <a class="dropdown-item" title="Consolidados" [routerLink]="['/admin/almacen/consolidados']"><span><i
            style="margin-right: 10px" class="fa fa-archive"></i>Consolidados
          ({{ pending }})</span></a>
    </li>

    <li routerLinkActive="ruta-activa" [routerLinkActiveOptions]="{ exact: true }" *ngIf="
                  authService.isAdmin ||
                  authService.isOperacionesCostaRica2 ||
                  authService.isOperacionesMiami
                ">
      <a class="dropdown-item" title="Embarques" [routerLink]="['/admin/almacen/embarques']"><span><i
            style="margin-right: 10px" class="fa fa-ship"></i>Embarques</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="
                  authService.isAdmin ||
                  authService.isServicioCliente ||
                  authService.isOperacionesCostaRica ||
                  authService.isOperacionesCostaRica2
                ">
      <a class="dropdown-item" title="Entregas" [routerLink]="['/admin/almacen/entregas']"><span><i
            style="margin-right: 10px" class="fa fa-truck"></i>Entregas</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="
                  authService.isAdmin">
      <a class="dropdown-item" title="Entregas" [routerLink]="['/admin/almacen/sucursales']"><span><i
            style="margin-right: 10px" class="fa fa-building"></i>Sucursales</span></a>
    </li>
    </div>
    </li>

    <!-- Menu Administración -->
    <li *ngIf="
              authService.isOperacionesCostaRica ||
              authService.isServicioCliente ||
              authService.isVendedor
            ">
      <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false" title="Calculadora" [routerLink]="['/admin/calculadora']"><span><i
            style="margin-right: 10px" class="fa fa-area-chart"></i>Calculadora</span></a>
    </li>

    <li *ngIf="
              authService.isAdmin || authService.isOperacionesCostaRica2
            " class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <i style="margin-right: 10px" class="fa fa-users"></i>Administración
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
    <li routerLinkActive="ruta-activa" *ngIf="authService.isAdmin">
      <a class="dropdown-item" title="Tarifas" [routerLink]="['/admin/administracion/alianzas']"><span><i
            style="margin-right: 10px" class="fa fa-list-alt"></i>Alianzas</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isAdmin || authService.isOperacionesCostaRica2">
      <a class="dropdown-item" title="Tarifas" [routerLink]="['/admin/administracion/tarifas']"><span><i
            style="margin-right: 10px" class="fa fa-list" aria-hidden="true"></i>Tarifas</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isAdmin || authService.isOperacionesCostaRica">
      <a class="dropdown-item" title="Acciones" [routerLink]="['/admin/administracion/acciones']"><span><i
            style="margin-right: 10px" class="fa fa-bar-chart"></i>Acciones</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isAdmin">
      <a class="dropdown-item" title="Descuentos" [routerLink]="['/admin/administracion/descuentos']"><span><i
            style="margin-right: 10px" class="fa fa-tags"></i>Descuentos</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isAdmin">
      <a class="dropdown-item" title="Productos Calculadora"
        [routerLink]="['/admin/administracion/tarifa-productos']"><span><i style="margin-right: 10px"
            class="fa fa-area-chart"></i>Productos Calculadora</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="
                  authService.isAdmin ||
                  authService.isServicioCliente ||
                  authService.isOperacionesMiami
                ">
      <a class="dropdown-item" title="Calculadora" [routerLink]="['/admin/calculadora']"><span><i
            style="margin-right: 10px" class="fa fa-area-chart"></i>Calculadora</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isAdmin || authService.isOperacionesMiami">
      <a class="dropdown-item" title="Email" [routerLink]="['/admin/administracion/email']"
        [queryParams]="{ tipo: 'N' }"><span><i style="margin-right: 10px" class="fa fa-envelope-o"></i>Email</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isAdmin">
      <a class="dropdown-item" title="Email Templates" [routerLink]="['/admin/administracion/email']"
        [queryParams]="{ tipo: 'T' }"><span><i style="margin-right: 10px" class="fa fa-envelope-o"></i>Email
          Auto</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isAdmin">
      <a class="dropdown-item" title="Email Masivos" [routerLink]="['/admin/administracion/email/mass']"><span><i
            style="margin-right: 10px" class="fa fa-envelope-o"></i>Email Masivos
        </span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isAdmin || authService.isOperacionesMiami">
      <a class="dropdown-item" title="Notificaciones Push" [routerLink]="['/admin/administracion/push']"><span><i
            style="margin-right: 10px" class="fa fa-mobile"></i>Notificaciones
          Push</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isAdmin">
      <a class="dropdown-item" title="Integraciones" [routerLink]="['/admin/administracion/integraciones']"><span><i
            style="margin-right: 10px" class="fa fa-gear"></i>Integraciones</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isAdmin">
      <a class="dropdown-item" title="Descuentos" [routerLink]="['/admin/administracion/tipo_cliente']"><span><i
            style="margin-right: 10px" class="fa fa-users"></i>Tipos
          de Cliente</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isAdmin">
      <a class="dropdown-item" title="Descuentos" [routerLink]="['/admin/administracion/contrato']"><span><i
            style="margin-right: 10px" class="fa fa-file-pdf-o"></i>Contrato</span></a>
    </li>
    </div>
    </li>

    <!-- Menu Contabilidad -->
    <li routerLinkActive="ruta-activa"
      *ngIf="authService.isServicioCliente || authService.isAdmin || authService.isOperacionesCostaRica || authService.isOperacionesCostaRica2">
      <a class="nav-link dropdown-toggle" title="Pagos" [routerLink]="['/admin/contabilidad/aprobar-pagos']"><span><i
            style="margin-right: 10px" class="fa fa-exchange"></i>Pagos
          por aprobar ({{ pendingPagos }})</span></a>
    </li>

    <li *ngIf="authService.isAdmin" class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <i style="margin-right: 10px" class="fa fa-book"></i>Contabilidad
        ({{ pendingPagos }})
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <!--           <li routerLinkActive="ruta-activa" *ngIf="authService.isAdmin || authService.isOperacionesCostaRica">
                <a class="dropdown-item" title="Facturas" [routerLink]="['/admin/contabilidad/facturas']"><span><i
                  style="margin-right: 10px;"  class="fa fa-file-o"></i>Facturas</span></a>
              </li> -->

    <li routerLinkActive="ruta-activa" *ngIf="authService.isAdmin || authService.isOperacionesCostaRica">
      <a class="dropdown-item" title="Pagos" [routerLink]="['/admin/contabilidad/pagos']"><span><i
            style="margin-right: 10px" class="fa fa-exchange"></i>Pagos</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="
                  authService.isAdmin ||
                  authService.isServicioCliente ||
                  authService.isOperacionesCostaRica
                ">
      <a class="dropdown-item" title="Pagos" [routerLink]="['/admin/contabilidad/aprobar-pagos']"><span><i
            style="margin-right: 10px" class="fa fa-exchange"></i>Pagos
          por aprobar ({{ pendingPagos }})</span></a>
    </li>

    <li routerLinkActive="ruta-activa" *ngIf="authService.isAdmin || authService.isOperacionesCostaRica">
      <a class="dropdown-item" title="Facturas" [routerLink]="['/admin/contabilidad/facturas-singenerar']"><span><i
            style="margin-right: 10px" class="fa fa-file-o"></i>Paquetes
          sin Factura</span></a>
    </li>
    </div>
    </li>

    <!-- Menu Compras Corporativas -->
    <li *ngIf="
              authService.isAdmin ||
              authService.isServicioCliente ||
              authService.isVendedor
            " class="nav-item">
      <a style="color: white !important" class="nav-link" title="Compras corporativas"
        [routerLink]="'compras-corporativas'"><span><i style="margin-right: 10px" class="fa fa-university"></i>Compras
          corporativas
        </span></a>
    </li>

    <!-- Menu Reportes -->
    <li *ngIf="
              authService.isAdmin ||
              authService.isContabilidad ||
              authService.isOperacionesCostaRica2
            " class="nav-item dropdown">
      <a class="nav-link" style="color: white" title="Reportes" [routerLink]="['/admin/reportes/']" id="navbarDropdown"
        role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i style="margin-right: 10px" class="fa fa-book"></i>Reportes
      </a>
    </li>

    <!-- Menu Seguridad-->
    <li *ngIf="
              authService.isAdmin ||
              authService.isServicioCliente ||
              authService.isOperacionesCostaRica2
            " class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <i style="margin-right: 10px" class="fa fa-key"></i>Seguridad
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
    <li routerLinkActive="ruta-activa" [routerLinkActiveOptions]="{ exact: true }">
      <a class="dropdown-item" title="Tarjetas" [routerLink]="['/admin/tarjetas']"><span><i style="margin-right: 10px"
            class="fa fa-id-card"></i>Tarjetas</span></a>
    </li>
    </div>
    </li>

    <!-- Menu Tipo de Cambio-->
    <li *ngIf="authService.isAdmin || authService.isOperacionesCostaRica || authService.isOperacionesCostaRica2 "
      class="nav-item dropdown">
      <a class="nav-link" style="color: white" [routerLink]="['/admin/otros/']" id="navbarDropdown" role="button"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i style="margin-right: 10px" class="fa fa-book"></i>Tipo de cambio
      </a>
    </li>

    <!-- Menu Rutas por Asignar -->
    <li *ngIf="authService.isAdmin || authService.isOperacionesCostaRica || authService.isOperacionesCostaRica2"
      class="nav-item dropdown">
      <a class="nav-link" style="color: white" [routerLink]="['/admin/cuentas/rutas']" id="navbarDropdown" role="button"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i style="margin-right: 10px" class="fa fa-truck"></i>Rutas por
        Asignar ({{ pendingRoutes }})
      </a>
    </li>
  </ul>
</ng-template>
