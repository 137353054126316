import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform {

  transform(obj: any): string {
    const date = new Date(obj);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear();
    // Pad day and month with leading zeros if necessary
    const dayStr = day < 10 ? '0' + day : '' + day;
    const monthStr = month < 10 ? '0' + month : '' + month;
    return `${dayStr}/${monthStr}/${year}`;
  }
}
