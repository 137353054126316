import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { NgxSpinnerService } from 'ngx-spinner';

import { RutasService } from '../../../../../services/rutas.service';
export interface RouteElement {
  id: number;
  name: string;
  available: boolean;
}

@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrls: ['./route.component.scss']
})
export class RouteComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public length: number;

  displayedColumns: string[] = ['name', 'available', 'opciones'];
  dataSource = new MatTableDataSource<RouteElement>();
  selection = new SelectionModel<RouteElement>(true, []);

  constructor(
    public routes: RutasService,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinnerService.show();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    //Fill Route List
    this.routes.get().subscribe(
      (res: any) => {
        const routes_list: RouteElement[] = [];
        this.length = res.length;
        res.forEach(element => {
          routes_list.push({
            id: element.id,
            name: element.nombre,
            available: element.disponible
          });
        });
        this.dataSource.data = routes_list;
        this.routes.routesList = routes_list;
        this.spinnerService.hide();
      }
    );
  }



  public changeRouteAvailability(element) {
    const data: any = { disponible: !element.available };
    this.routes.put(element.id, data).subscribe(
      (res: any) => {
        element.available = data.disponible;
      },
      error => {
        //Pendiente Cambiar al valor anterior el toggle
        //console.log(error);
      }
    );
  }
}

