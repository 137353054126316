
<div style="overflow:scroll;" class="content pt-2">
  <div class="content-title" style="position: relative;">
    <p style="margin-top: 0 !important;line-height: 60px !important" class="title">Información Paquete</p>
  </div>

 <!--info-->
 <div class="container table-personal pt-5 pl-5 pr-5">
  <table style="width:100%">
    <div class="row">
      <div class="col">
        <tr>
          <td class="p-td cl">Tracking:  </td>
          <td class="p-td">{{params?.tracking}}</td>
        </tr>
      </div>
      <div class="col">
        <tr>
          <td class="p-td cl">Tipo de Paquete: </td>
          <td class="p-td">{{params?.tipo_de_paquete}}</td>
        </tr>
      </div>
      <div class="col">
        <tr>
          <td class="p-td cl">Remitente: </td>
          <td class="p-td">{{params?.remitente}}</td>
        </tr>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <tr>
          <td class="p-td cl">Destinatario: </td>
          <td class="p-td">{{params?.destinatario}}</td>
        </tr>
      </div>
      <div class="col">
        <tr>
          <td class="p-td cl">Descripción: </td>
          <td class="p-td">{{params?.descripcion}}</td>
        </tr>
      </div>
      <div class="col">
        <tr>
          <td class="p-td cl">Clase: </td>
          <td class="p-td">{{params?.clase}}</td>
        </tr>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col">
        <tr>
          <td class="p-td cl">Precio: </td>
          <td class="p-td">{{params?.precio}}</td>
        </tr>
      </div>
      <div class="col">

      </div>
      <div class="col">

      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <tr>
          <td class="p-td cl">Largo: </td>
          <td class="p-td">{{params?.largo}} "</td>
        </tr>
      </div>
      <div class="col">
        <tr>
        <td class="p-td cl">Alto: </td>
        <td class="p-td">{{params?.alto}} "</td>
      </tr>
      </div>
      <div class="col">
        <tr>
          <td class="p-td cl">Ancho: </td>
          <td class="p-td">{{params?.ancho}} "</td>
        </tr>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <tr>
          <td class="p-td cl">Peso Volumen: </td>
          <td class="p-td">{{params?.peso_volumetrico}} lbs.</td>
        </tr>
      </div>
      <div class="col">
        <tr>
        <td class="p-td cl">Peso Real: </td>
        <td class="p-td">{{params?.peso}} lbs.</td>
      </tr>
      </div>
      <div class="col">
        <tr>
          <td class="p-td cl">Peso Total: </td>
          <td class="p-td">{{params?.peso_total}} lbs.</td>
        </tr>
      </div>
    </div>
  </table>
</div>
 <!---->
</div>
<!--<div mat-dialog-actions style="display: flex; justify-content: space-between;">
  <button mat-flat-button color="primary" class="button1" (click)="onNoClick()">Cerrar</button>

</div>-->
