import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ultimaActualizacion'
})
export class UltimaActualizacionPipe implements PipeTransform {

  transform(value: any[]): any {
    if (!Array.isArray(value) || value.length === 0) {
      return null;
    }

    const lastElement = value.slice(-1)[0];
    const status = lastElement.estado.estado;
    const updatedAt = new Date(lastElement.update_at);
    const formattedDate = `${updatedAt.getDate()}/${updatedAt.getMonth() + 1}/${updatedAt.getFullYear()} ${updatedAt.getHours()}:${updatedAt.getMinutes()}:${updatedAt.getSeconds()}`;
    return `${status} (${formattedDate})`;
  }

}
