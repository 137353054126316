import { Component, OnInit } from '@angular/core';
import { SingleDataSet, Label } from 'ng2-charts';
import { ChartType } from 'chart.js';

@Component({
  selector: 'app-polar-area',
  templateUrl: './polar-area.component.html',
  styleUrls: ['./polar-area.component.scss']
})
export class PolarAreaComponent implements OnInit {

  public polarAreaChartLabels: Label[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun'];
  public polarAreaChartData: SingleDataSet = [300, 500, 100, 10, 40, 200];
  public polarAreaLegend = false;

  public polarAreaChartType: ChartType = 'polarArea';

  constructor() { }

  ngOnInit() {
  }

}
