<div class="chartBx">
  <!-- <mat-icon class="add" *ngIf="chart.type === 0" (click)="addChart()">control_point</mat-icon>
  <mat-icon class="add close" *ngIf="chart.type !== 0" (click)="closeChart()">control_point</mat-icon> -->

  <p class="title-chart" *ngIf="chart.type !== 0">{{chart.metrica}}</p>

  <img *ngIf="chart.type === 0" class="img-chart-none" src="../../../../../assets/logo-superbox2.png">

  <div class="content-chart">
    <app-bar *ngIf="chart.type === '1'"></app-bar>
    <app-bubble *ngIf="chart.type === '2'"></app-bubble>
    <app-doughnut-pie *ngIf="chart.type === '3'"></app-doughnut-pie>
    <app-line *ngIf="chart.type === '4'"></app-line>
    <app-polar-area *ngIf="chart.type === '5'"></app-polar-area>
    <app-radar *ngIf="chart.type === '6'"></app-radar>
    <app-scatter *ngIf="chart.type === '7'"></app-scatter>

    <div class="dashboard" *ngIf="chart.type === '8'" [routerLink]="['/admin/almacen/embarques']">
      {{dashboard?.paquetesPorCompletar}}
    </div>

    <div class="dashboard" *ngIf="chart.type === '9'">
      {{dashboard?.paquetesCompletados}}
    </div>

    <div class="dashboard" *ngIf="chart.type === '9-OM'">
      {{dashboard?.paquetesCompletados}}
      <span class="peso">{{dashboard?.paqueteCompletadosPeso.peso__sum | number:'1.2-2' }} Kg.</span>
    </div>

    <div class="dashboard" *ngIf="chart.type === '10'" [routerLink]="['/admin/almacen/consolidados']">
      {{dashboard?.porConsolidar}}
    </div>

    <div class="dashboard" *ngIf="chart.type === '11'">
      <span class="guide">#{{dashboard?.ultimoEmbarque.id}}</span>

      {{dashboard?.ultimoEmbarque.value}}
    </div>

    <div class="dashboard" style="font-size: 48px !important" *ngIf="chart.type === '12'">
      <span class="guide">#{{dashboard?.ultimoEmbarque.id}}</span>

      {{dashboard?.ultimoEmbarque.peso_real | number:'1.2-2' }} Kg. R
      <br>
      {{dashboard?.ultimoEmbarque.peso_volumen | number:'1.2-2' }} Kg. V
    </div>

    <div class="dashboard" *ngIf="chart.type === '13'" [routerLink]="['/admin/almacen/embarques']">
      {{dashboard?.paquetesMiamiSinFactura}}
    </div>

    <div class="dashboard" *ngIf="chart.type === '14'" [routerLink]="['/admin/almacen/entregas']">
      {{dashboard?.paquetesCentroDistribucion}}
    </div>

    <div class="dashboard" *ngIf="chart.type === '15'" [routerLink]="['/admin/almacen/entregas']">
      {{dashboard?.paquetesTransitoEntregados.entregados}}/{{dashboard?.paquetesTransitoEntregados.total}}
    </div>

    <div class="dashboard" *ngIf="chart.type === '16'" [routerLink]="['/admin/reportes']"
      [queryParams]="{selectedReport: 'estatus'}">
      {{dashboard?.paquetesCentroDistibucionSinFacturarse}}
    </div>

    <div class="dashboard" *ngIf="chart.type === '17'" [routerLink]="['/admin/reportes']"
      [queryParams]="{selectedReport: 'no-pagos-entregados'}">
      {{dashboard?.paquetesEntregadosNoPagos.cantidad}}
    </div>

    <div class="dashboard" *ngIf="chart.type === '17-A'" [routerLink]="['/admin/reportes']"
      [queryParams]="{selectedReport: 'no-pagos-entregados'}">
      {{dashboard?.paquetesEntregadosNoPagos.cantidad}}
      <br>
      <span class="peso">-{{dashboard?.paquetesEntregadosNoPagos.total | currency}}</span>
    </div>

    <div class="dashboard" *ngIf="chart.type === '18'" [routerLink]="['/admin/reportes']"
      [queryParams]="{selectedReport: 'no-entregados-pagos'}">
      {{dashboard?.paquetesPagosNoEntregados}}
    </div>

    <div class="dashboard" *ngIf="chart.type === '19'">
      <span class="guide_1">Centro de Distribución Loading Guide
        #{{dashboard?.ultimoEmbarqueCentroDistribucion.embarque_id}}</span>
      {{dashboard?.ultimoEmbarqueCentroDistribucion.escaneados}}/{{dashboard?.ultimoEmbarqueCentroDistribucion.total}}
    </div>

    <div class="dashboard" *ngIf="chart.type === '20'">
      <span class="guide_1">Centro Distribución/Sucursal</span>
      {{dashboard?.paquetesCentroDistribucionSucursal30.cantidad}}
    </div>

    <div class="dashboard" *ngIf="chart.type === '20-A'" [routerLink]="['/admin/reportes']"
      [queryParams]="{selectedReport: 'antiguedad-saldos'}">
      <span class="guide_1">Centro Distribución/Sucursal</span>
      {{dashboard?.paquetesCentroDistribucionSucursal30.cantidad}}
      <br>
      <span class="peso">{{dashboard?.paquetesCentroDistribucionSucursal30.total | currency}}</span>
    </div>

    <div class="dashboard" *ngIf="chart.type === '21'">
      {{dashboard?.loadingGuideMiami[0]?.cantidad || '-'}}
    </div>

    <div class="dashboard" *ngIf="chart.type === '22'">
      {{dashboard?.loadingGuideTransito[0]?.cantidad || '-'}}
    </div>

    <div class="dashboard" *ngIf="chart.type === '23'">
      {{dashboard?.loadingGuideAduanas[0]?.cantidad || '-'}}
    </div>

    <div class="dashboard" *ngIf="chart.type === '24'" [routerLink]="['/admin/reportes']"
      [queryParams]="{selectedReport: 'estadistica'}">
      {{dashboard?.clientes.nuevos}}
      <br>
      <span class="abajo_p">{{dashboard?.clientes.pasados}} mes pasado</span>
    </div>

    <div class="dashboard" *ngIf="chart.type === '25'" [routerLink]="['/admin/cuentas']">
      <span class="guide">Sin Movimiento</span>

      {{dashboard?.sinMovimiento.cantidad}}
    </div>

    <div class="dashboard" style="font-size: 36px !important;" *ngIf="chart.type === '26'">
      {{dashboard?.facturadoMensual.cantidad}}
      <br>
      {{dashboard?.facturadoMensual.dolares | currency}}
      <br>
      {{dashboard?.facturadoMensual.colones | currency}}
    </div>

    <div class="dashboard" style="font-size: 36px !important;" *ngIf="chart.type === '27'">
      <span class="guide" style="font-size: 14px !important; font-weight: bold">Crédito/Débito/Descuentos Mensual</span>

      {{ (dashboard?.facturadoMensual.descuentos_colones + (dashboard?.notasCredito?.colones ?
      dashboard?.notasCredito?.colones : 0) - (dashboard?.notasDebito?.colones ? dashboard?.notasDebito?.colones : 0)) >
      0 ? dashboard?.facturadoMensual.cantidad : 0}}
      <br>
      {{dashboard?.facturadoMensual.descuentos_dolares + (dashboard?.notasCredito?.dolares ?
      dashboard?.notasCredito?.dolares : 0) - (dashboard?.notasDebito?.dolares ? dashboard?.notasDebito?.dolares : 0) |
      currency}}
      <br>
      {{dashboard?.facturadoMensual.descuentos_colones + (dashboard?.notasCredito?.colones ?
      dashboard?.notasCredito?.colones : 0) - (dashboard?.notasDebito?.colones ? dashboard?.notasDebito?.colones : 0) |
      currency}}
    </div>

    <div class="dashboard" *ngIf="chart.type === '28'">
      {{dashboard?.vendedores.cantidad}}
    </div>

  </div>
</div>
