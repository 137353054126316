import { ReportService } from 'src/app/services/reports.service';
import { Component, OnInit, ViewRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  public cantCharts: any = [
    { type: 0, metrica: '' },
    { type: 0, metrica: '' },
    { type: 0, metrica: '' },
    { type: 0, metrica: '' },
    { type: 0, metrica: '' },
    { type: 0, metrica: '' },
  ];

  public mostrar = false;
  public index: number;
  public categoria: string = '';
  public metrica = '';
  public charts: string = '0';
  chart;
  public metricas: any[] = [];
  public dashboard;

  private metricasSatus: any[] = [
    'Bodega Miami',
    'Recibidos Miami',
    'Impuestos',
    'En Tránsito a CR',
    'Prealertas'
  ];

  constructor(
    private authService: AuthService,
    private ref: ChangeDetectorRef,
    private reportService: ReportService
  ) { }

  ngOnInit() {
    //console.log(this.authService.activeRol);

    const data = {};
    this.reportService.getReportsDashboards(data).subscribe(value => {
      this.dashboard = value;
    });

    if (this.authService.activeRol === 'ADMIN') {
      this.cantCharts = [
        { type: '8', index: 0, metrica: 'Paquetes en Guía por Completar' },
        { type: '9-OM', index: 1, metrica: 'Paquetes en Guía Completada' },
        { type: '13', index: 2, metrica: 'Paquetes sin Factura Miami' },
        { type: '16', index: 3, metrica: 'Paquetes sin Facturarse Costa Rica' },
        { type: '17-A', index: 4, metrica: 'Paquetes Entregados/No Pagos' },
        { type: '18', index: 5, metrica: 'Paquetes Pagos/No Entregados' },
        { type: '20-A', index: 6, metrica: 'Paquetes +30 Dias' },
        { type: '24', index: 7, metrica: 'Clientes Nuevos Mensual' },
        { type: '25', index: 8, metrica: 'Clientes AA-A-B +60 días' },
        { type: '26', index: 9, metrica: 'Total Facturado Mensual' },
        { type: '27', index: 10, metrica: 'Total Notas' },
        { type: '28', index: 11, metrica: 'Cuentas Nuevas Vendedores' },
      ];

      this.cantCharts.forEach(element => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.cantCharts[element.index] = { type: element.type, metrica: element.metrica };
          this.ref.detectChanges();
        }
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.authService.activeRol === 'ALMAC') {
      this.charts = '8';
      this.metrica = 'Paquetes en Guía por Completar';
      this.index = 0;
      this.generarGrafica();

      this.charts = '9';
      this.metrica = 'Paquetes en Guía Completada';
      this.index = 1;
      this.generarGrafica();

      this.charts = '10';
      this.metrica = 'Consolidados Por Realizar';
      this.index = 2;
      this.generarGrafica();


      this.charts = '11';
      this.metrica = 'Loading Guide';
      this.index = 4;
      this.generarGrafica();
    }

    if (this.authService.activeRol === 'OPMIA') {
      this.charts = '8';
      this.metrica = 'Paquetes en Guía por Completar';
      this.index = 0;
      this.generarGrafica();

      this.charts = '9-OM';
      this.metrica = 'Paquetes en Guía Completada';
      this.index = 1;
      this.generarGrafica();

      this.charts = '13';
      this.metrica = 'Paquetes sin Factura Miami';
      this.index = 2;
      this.generarGrafica();

      this.charts = '10';
      this.metrica = 'Consolidados Por Realizar';
      this.index = 3;
      this.generarGrafica();

      this.charts = '11';
      this.metrica = 'Loading Guide';
      this.index = 4;
      this.generarGrafica();

      this.charts = '12';
      this.metrica = 'Loading Guide';
      this.index = 5;
      this.generarGrafica();
    }

    if (this.authService.activeRol === 'OPCR') {
      this.charts = '14';
      this.metrica = 'Paquetes en Centro de Distribución';
      this.index = 0;
      this.generarGrafica();

      this.charts = '15';
      this.metrica = 'Paquetes En Transito/Entregados';
      this.index = 1;
      this.generarGrafica();

      this.charts = '16';
      this.metrica = 'Paquetes sin Facturarse';
      this.index = 2;
      this.generarGrafica();

      this.charts = '17';
      this.metrica = 'Paquetes Entregados/No Pagos';
      this.index = 3;
      this.generarGrafica();

      this.charts = '18';
      this.metrica = 'Paquetes Pagos/No Entregados';
      this.index = 4;
      this.generarGrafica();

      this.charts = '19';
      this.metrica = 'Revision de Embarque';
      this.index = 5;
      this.generarGrafica();
    }

    if (this.authService.activeRol === 'SERVCLI') {
      this.charts = '13';
      this.metrica = 'Paquetes sin Factura Miami';
      this.index = 0;
      this.generarGrafica();

      this.charts = '17';
      this.metrica = 'Paquetes Entregados/No Pagos';
      this.index = 1;
      this.generarGrafica();

      this.charts = '20';
      this.metrica = 'Paquetes +30 Dias';
      this.index = 2;
      this.generarGrafica();

      this.charts = '21';
      this.metrica = 'Loading Guide Miami';
      this.index = 3;
      this.generarGrafica();

      this.charts = '22';
      this.metrica = 'Loading Guide Costa Rica';
      this.index = 4;
      this.generarGrafica();

      this.charts = '23';
      this.metrica = 'Loading Guide Proceso Aduanas';
      this.index = 5;
      this.generarGrafica();

    }

    if (this.authService.activeRol === 'ADMIN') {
      this.generarGrafica();
    }

  }

  public addClose = (valor) => {
    if (valor) {
      this.mostrar = true;
    } else {
      if (this.ref && !(this.ref as ViewRef).destroyed) {
        this.cantCharts[this.index] = { type: 0, metrica: '' };

        this.ref.detectChanges();
      }
    }
  };

  public indexChart = (i) => {
    this.index = i;
  };

  public valorSelection = (valor) => {
    if (valor === 'status') {
      this.metricas = this.metricasSatus;
    } else {
      this.metricas = [];
    }
  };

  public generarGrafica = () => {
    if (this.ref && !(this.ref as ViewRef).destroyed) {
      this.cantCharts[this.index] = { type: this.charts, metrica: this.metrica };
      this.ref.detectChanges();
    }
    this.mostrar = false;
    this.categoria = '';
    this.metrica = '';
    this.charts = '0';
  };
}
