import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-resumen-paquetes',
  templateUrl: './resumen-paquetes.component.html',
  styleUrls: ['./resumen-paquetes.component.scss']
})
export class ResumenPaquetesComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
