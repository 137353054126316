<h2 class="h2-title p-4">Contrato</h2>

<div class="content" fxLayout="column">
  <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones justify-content-center;">
    <div class="row" style="width: 100%;">
      <div class="col-sm botones-opciones">
      </div>

    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="content-table mr-4 ml-4 mb-4">
    <div class="col-12" *ngIf="url">

      <label class="label">{{'Contrato' | translate}}</label><br>

      <div style="height: 75vh;">
        <iframe [src]="urlSafe" style="width: 100%; height: 100%"></iframe>
      </div>
      <button class="button-archivo mt-2" (click)="imageInput1.click(); $event.preventDefault()" mat-flat-button
        color="primary"><i class="fa fa-file-pdf-o" aria-hidden="true"></i>{{'Seleccionar' | translate}}</button>

      <form [formGroup]="contratoForm">
        <input style="width: 100%; display: none;" type="file" class="form-control-file" accept="application/pdf, .pdf"
          #imageInput1 (change)="processFile($event)">
      </form>
    </div>

  </div>
</div>
