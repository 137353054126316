import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { FacturasService } from 'src/app/services/facturas.service';
import { UsuariosService } from 'src/app/services/usuarios.service';


@Component({
  selector: 'app-notas-de-credito',
  templateUrl: './notas-de-credito.component.html',
  styleUrls: ['./notas-de-credito.component.scss']
})
export class NotasDeCreditoComponent implements OnInit {

  displayedColumns: string[] = ['id', 'fecha', 'factura', 'cuenta', 'descripcion', 'tipo', 'monto', 'creador'];
  dataSource = [];

  length: number;
  pageSize = 10;
  pageIndex = 1;

  notas;

  form = this.fb.group({
    date_from: [''],
    date_until: ['']
  });

  constructor(
    private facturasServices: FacturasService,
    private fb: FormBuilder,
    public usuariosService: UsuariosService,

  ) { }

  ngOnInit() {
    this.getNotas();
  }

  getNotas(params = '') {
    this.facturasServices.getNotasCredito(this.pageIndex, params).subscribe((res: any) => {
      //console.log(res);

      this.notas = res;
      this.dataSource = res.results;
      this.length = res.count;

    });
  }

  public applyFilters() {
    this.form.value.date_from == null ? this.form.value.date_from = '' : '';
    this.form.value.date_until == null ? this.form.value.date_until = '' : '';

    let filter = '';

    Object.keys(this.form.value).forEach(async key => {
      if (this.form.value[key] !== '') {
        //console.log(key + ' - ' + this.form.value[key]); // key - value

        if (key === 'date_from') {
          filter = filter + '&created_at__from=' + this.form.value[key];
        }

        if (key === 'date_until') {
          filter = filter + '&created_at__to=' + this.form.value[key];
        }

      }
    });

    this.getNotas(filter);

  }

  public cleanFilters() {
    this.pageSize = 10;
    this.pageIndex = 1;
    this.getNotas();
    this.form.reset();
  }

  paginate(event: any) {
    //console.log(event);
    this.pageIndex = event;
    this.getNotas();
  }


}

