<div class="content-general" [ngClass]="{'mostrar': mostrar}">
  <div class="content-1">
    <div class="content-title">
      <p class="title">Estadisticas</p>
    </div>

    <div class="cuadros-estadisticas mt-5 pt-5 pr-4 pl-4 pb-4">
      <app-charts *ngFor="let chart of cantCharts; let i = index;" [chart]="chart" [i]="i" [dashboard]="dashboard"
        (addClose)="addClose($event)" (index)="indexChart($event)"></app-charts>
    </div>
  </div>

  <div class="content-2 " [ngClass]="{'mostrar': mostrar}">
    <div class="content-title">
      <p class="title">Mostrar</p>
    </div>

    <div class="inputs-form pt-5 mt-5">
      <div class="pl-4 pr-4">
        <label class="label">Fecha Desde</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="dd/mm/aaaa" type="date" class="input-field">
        </mat-form-field>
      </div>

      <div class="pl-4 pt-4 pr-4">
        <label class="label">Fecha Hasta</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="dd/mm/aaaa" type="date" class="input-field">
        </mat-form-field>
      </div>

      <div class="pl-4 pt-4 pr-4" *ngIf="categoria">
        <label class="label">Categoria</label><br>
        <mat-form-field appearance="outline">
          <mat-select matNativeControl required [(ngModel)]="categoria" placeholder="Categoria"
            (selectionChange)="valorSelection(categoria)">
            <mat-option value="status">Status</mat-option>
            <mat-option value="indicadores">Indicadores</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="pl-4 pt-4 pr-4">
        <label class="label">Métrica</label><br>
        <mat-form-field appearance="outline">
          <mat-select matNativeControl required [(ngModel)]="metrica" placeholder="Métrica">
            <mat-option *ngFor="let metrica of metricas" [value]="metrica">{{metrica}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="p-4">
        <label class="label">Tipó de Gráfico</label><br>
        <mat-form-field appearance="outline">
          <mat-select matNativeControl required [(ngModel)]="charts" placeholder="Tipó de Gráfico">
            <mat-option value="1">Bar</mat-option>
            <mat-option value="2">Bubble</mat-option>
            <mat-option value="3">Pie</mat-option>
            <mat-option value="4">Line</mat-option>
            <mat-option value="5">Polar Area</mat-option>
            <mat-option value="6">Radar</mat-option>
            <mat-option value="7">Scatter</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div style="display: flex; justify-content: center;" class="pl-4 pr-4 pb-4">
      <button mat-flat-button color="primary" class="button-calcular" (click)="generarGrafica()">Guardar</button>
    </div>
  </div>
</div>
