import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private url = `${environment.apiUrl}/reports/`;

  constructor(
    private http: HttpClient
  ) { }

  getReports(data) {
    return this.http.post(this.url, data);
  }

  getReportsBlob(data) {
    return this.http.post(this.url, data, { responseType: 'blob' });
  }

  getReportsDashboards(data) {
    return this.http.post(this.url + 'dashboards', data);
  }

  updateReport() {
    return this.http.post(this.url + 'update', null);
  }

}
