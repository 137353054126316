/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import { EmbarquesService } from '../../../../../../services/embarques.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { FormBuilder } from '@angular/forms';

import { DatePipe } from '@angular/common';

import autoTable from 'jspdf-autotable';
import { jsPDF } from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { AuthService } from 'src/app/services/auth.service';
import { PackageLabelComponent } from 'src/app/modules/admin/components/package-label/package-label.component';
import { NgxSpinnerService } from 'ngx-spinner';


export interface Embarque {
  awb: number;
  cweight: string;
  refdate: Date;
  file: string;
  vessel: string;
  gross: string;
  id: string;
  notes: string;
  packages: string;
  pieces: string;
  scanned: string;
  services: string;
  status: string;
  bultos: string;
  total: string;
  user: string;
  creation: Date;
  full: any;
}

@Component({
  selector: 'app-embarques',
  templateUrl: './embarques.component.html',
  styleUrls: ['./embarques.component.scss']
})
export class EmbarquesComponent implements OnInit {
  ships: Embarque[];

  length = 0;
  pageSize = 10;
  pageIndex = 1;

  form = this.fb.group({
    id: [''],
    creacion: [''],
    travel_type: ['']
  });

  displayedColumns: string[] = [
    'id', 'refdate', 'awb', 'cweight', 'gross', 'packages', 'services', 'status', 'user'
  ];

  dataSource = [];
  selection = new SelectionModel<Embarque>(true, []);



  constructor(
    private fb: FormBuilder,
    public embarquesService: EmbarquesService,
    public usuariosService: UsuariosService,
    public usersService: UsuariosService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private packageLabel: PackageLabelComponent,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.getEmbarques();
  }

  getEmbarques() {
    this.spinner.show();
    this.embarquesService.get(this.pageIndex).subscribe((res: any) => {
      let status;
      const ships = [];

      res.results.forEach((element, index, array) => {
        if (element.status_open) {
          status = 'Abierto';
        } else {
          status = 'Cerrado';
        }

        const rol = this.authService.getUser().rol;

        if (String(rol) === 'ADMIN' || (element.visible)) {
          ships.push({
            awb: element.awb_bl,
            charge_weight: element.charge_weight,
            refdate: element.date,
            file: element.file_a,
            vessel: element.travel_identifier,
            gross_weight: element.gross_weight,
            id: element.id,
            notes: element.notes,
            packages: element.total_packages,
            pieces: element.pieces,
            scanned: element.scanned_packages,
            services: element.travel_type,
            status,
            bultos: element.total_charge,
            total: element.total,
            total_weight: element.total_weight,
            user: this.usuariosService.get_fullname(element.user),
            creation: this.datePipe.transform(new Date(element.created_at), 'dd-MM-yyyy HH:mm'),
            full: element
          });
        }

        this.ships = ships;
        this.dataSource = this.ships;
        this.length = res.count;

        if (index == array.length - 1) {
          this.spinner.hide();
        }
      });


    }, (error) => {
      this.spinner.hide();
    });
  }

  public filterTable() {
    this.form.value.id == null ? this.form.value.id = '' : '';
    this.form.value.travel_type == null ? this.form.value.travel_type = '' : '';

    let validId = true;
    const day = 60 * 60 * 24 * 1000;
    let sameCreationDay = true;
    const sameUpdateDay = true;
    const filter: Embarque[] = this.ships.filter(ship => {
      if (this.form.value.creacion !== '') {
        const creacion = ship.creation;
        const creacion_filter = new Date(Date.parse(this.form.value.creation) + day);
        sameCreationDay = this.isSameDay(creacion, creacion_filter);
      }
      if ((this.form.value.id !== '') && (ship.id !== this.form.value.id)) {
        validId = false;
      }
      return (
        validId &&
        String(ship.services).trim().toLowerCase().includes(String(this.form.value.travel_type).trim().toLowerCase()) &&
        sameCreationDay);
    });

    this.dataSource = filter;
  }

  public cleanFilters() {
    this.form.reset();
    this.dataSource = this.ships;
  }

  public isAllSelected = (): boolean => {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  };

  public masterToggle = (): void => {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.forEach(row => this.selection.select(row));
  };

  public checkboxLabel = (row?: Embarque): string => {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  };

  paginate(event: any) {
    //console.log('paginate',event);
    this.pageIndex = event;
    this.getEmbarques();
  }

  sortData(sort: Sort) {
    console.log(sort);
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'nombre':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'cuenta':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'guia':
          return compare(a.guia, b.guia, isAsc);
        case 'peso_real':
          return compare(a.peso_total, b.peso_total, isAsc);
        case 'zona':
          return compare(a.entrega.zona_centro_distribucion, b.entrega.zona_centro_distribucion, isAsc);
        case 'tipo_paquete':
          return compare(a.tipo_paquete, b.tipo_paquete, isAsc);
        case 'modificado':
          return compare(a.ultima_actualizacion, b.ultima_actualizacion, isAsc);
        case 'cweight':
          return compare(a.charge_weight, b.charge_weight, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'user':
          return compare(a.user, b.user, isAsc);
        case 'services':
          return compare(a.services, b.services, isAsc);
        case 'packages':
          return compare(a.packages, b.packages, isAsc);
        case 'gross':
          return compare(a.gross_weight, b.gross_weight, isAsc);
        case 'awb':
          return compare(a.awb, b.awb, isAsc);
        case 'tracking':
          return compare(a.tracking, b.tracking, isAsc);
        case 'refdate':
          return compare(a.refdate, b.refdate, isAsc);
        case 'id':
          return compare(a.id, b.id, isAsc);

        default:
          return 0;
      }
    });
  }

  private isSameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  //console.log(a,b);
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
