<div style="padding: 24px;overflow: auto;">

  <div class="row" mat-dialog-title>

    <div class="col-12" style="display: flex; align-items: center; justify-content: space-between;">
      <div style="text-align: left;">
        <span style="color:orange; font-size:24px !important">Guía: {{data.guia}} </span>
      </div>

      <div>
        <button mat-flat-button color="primary" class="button-calcular" (click)="actualizarPaquete()">
          <mat-icon style="color: white !important; font-size: 16px !important;">save</mat-icon> Guardar
        </button>
      </div>

    </div>

  </div>

</div>

<mat-dialog-content>

  <form *ngIf="paquetesForm" [formGroup]="paquetesForm" (keyup.enter)="$event.preventDefault()">
    <div class="row">
      <div class="col-12 col-md-12">
        <div class="col-12 px-0 content-title">
          <p class="title" style="line-height: 10px;margin-top: 0 !important; padding-top: 0 !important">
            Información de Ingreso
          </p>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <label class="label_form_field_required">Tracking</label><br>
              <mat-form-field appearance="outline">
                <input #tracking matInput placeholder="Tracking" formControlName="tracking" required onReturn
                  (keyup.enter)="largo.focus()" [readonly]="editMode && !authService.isMiami">
              </mat-form-field>
              <mat-error
                *ngIf="paquetesForm?.controls['tracking'].errors && paquetesForm?.controls['tracking'].touched">
                {{getErrorMessage(paquetesForm?.controls['tracking'])}}
              </mat-error>
            </div>

          </div>

        </div>


        <div class="col-12 col-md-12">
          <div class="col-12 px-0 content-title">
            <p class="title" style="line-height: 10px;margin-top: 0 !important; padding-top: 0 !important">Peso y
              Medidas</p>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-12 col-md-4 col-lg-4">
                <label class="label label_form_field_required">Largo</label><br>
                <mat-form-field appearance="outline">
                  <input #largo numeric matInput formControlName="largo" (keyup)="calcularMedidas()"
                    (keyup.enter)="largo.value.length > 0 ? alto.focus() : largo.focus()">
                </mat-form-field>
                <mat-error *ngIf="paquetesForm?.controls['largo'].errors && paquetesForm?.controls['largo'].touched">
                  {{getErrorMessage(paquetesForm?.controls['largo'])}}
                </mat-error>
              </div>

              <div class="col-12 col-md-4 col-lg-4">
                <label class="label label_form_field_required">Alto</label><br>
                <mat-form-field appearance="outline">
                  <input #alto numeric matInput formControlName="alto" (keyup)="calcularMedidas()"
                    (keyup.enter)="alto.value.length > 0 ? ancho.focus() : alto.focus()">
                </mat-form-field>
                <mat-error *ngIf="paquetesForm?.controls['alto'].errors && paquetesForm?.controls['alto'].touched">
                  {{getErrorMessage(paquetesForm?.controls['alto'])}}
                </mat-error>
              </div>

              <div class="col-12 col-md-4 col-lg-4">
                <label class="label label_form_field_required">Ancho</label><br>
                <mat-form-field appearance="outline">
                  <input #ancho numeric matInput formControlName="ancho"
                    (keyup)="calcularMedidas(); calcularPesoCobrable()" (keyup.enter)="preal.focus()"
                    (keyup.tab)="ancho.value.length > 0 ? preal.focus() : ancho.focus()">
                </mat-form-field>
                <mat-error *ngIf="paquetesForm?.controls['ancho'].errors && paquetesForm?.controls['ancho'].touched">
                  {{getErrorMessage(paquetesForm?.controls['ancho'])}}
                </mat-error>
              </div>

            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <label class="label label_form_field_required">Peso Real Lbs.</label><br>
                <mat-form-field appearance="outline">
                  <input #preal type="number" matInput placeholder="Peso Real" formControlName="peso"
                    (change)="calcularPesoCobrable(); UpdateKg(preal.value)">
                </mat-form-field>
                <mat-error *ngIf="paquetesForm?.controls['peso'].errors && paquetesForm?.controls['peso'].touched">
                  {{getErrorMessage(paquetesForm?.controls['peso'])}}
                </mat-error>
              </div>

              <div class="col-12 col-md-6">
                <label class="label label_form_field_required">Peso Real Kg.</label><br>
                <mat-form-field appearance="outline">
                  <input #prealkg type="number" [(ngModel)]="pesoRealKg" [ngModelOptions]="{standalone: true}" matInput
                    placeholder="Peso Real Kg." readonly>
                </mat-form-field>
              </div>

            </div>

            <div class="row">

              <div class="col-12 col-md-6">
                <label class="label label_form_field_required">Peso Total Lbs.</label><br>
                <mat-form-field appearance="outline">
                  <input #ptotal matInput placeholder="Peso Total" formControlName="peso_total" readonly>
                </mat-form-field>
                <mat-error
                  *ngIf="paquetesForm?.controls['peso_total'].errors && paquetesForm?.controls['peso_total'].touched">
                  {{getErrorMessage(paquetesForm?.controls['peso_total'])}}
                </mat-error>
              </div>

              <div class="col-12 col-md-6">
                <label class="label label_form_field_required">Peso Total Kg.</label><br>
                <mat-form-field appearance="outline">
                  <input #ptotalkg type="number" [(ngModel)]="pesoTotalKg" [ngModelOptions]="{standalone: true}"
                    matInput placeholder="Peso Total Kg." readonly>
                </mat-form-field>
              </div>

            </div>

            <div class="row">

              <div class="col-12 col-md-6">
                <label class="label label_form_field_required">Peso Volumen</label><br>
                <mat-form-field appearance="outline">
                  <input matInput placeholder="Peso Volumen" formControlName="peso_volumetrico" readonly>
                </mat-form-field>

              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </form>

</mat-dialog-content>
