import { Pipe, PipeTransform } from '@angular/core';
import { UsuariosService } from 'src/app/services/usuarios.service';

@Pipe({
  name: 'filterUsuarioEntregado'
})
export class FilterUsuarioEntregadoPipe implements PipeTransform {

  constructor(private usuariosService: UsuariosService) { }

  transform(value): any {
    //console.log(value);

    let usuario = value.filter(e => e.estado.id === 18)[0];
    if (usuario) {
      usuario = this.usuariosService.get_fullname(usuario.usermodify);
    } else {
      usuario = '-';
    }
    return usuario;
  }

}
