<div class="pt-2 pb-1" style="width: 100%;">
  <h2 class="h2-title p-4 d-inline-block m-auto" style="margin-top: 0;margin-bottom: 0;">Cuentas con Rutas por Asignar
  </h2>

  <button mat-flat-button color="primary" class="d-inline-block button-calcular mr-5" style="float: right;"
    (click)="save()">Guardar</button>

</div>

<div class="content" fxLayout="column">

  <div class="content-table m-4 text-center" id="users-table">

    <table mat-table
      [dataSource]="usuarios | paginate: { itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length }" matSort
      (matSortChange)="sortData($event)">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Nombre </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Account Column -->
      <ng-container matColumnDef="account">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cuenta </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element">
          <a [routerLink]="['/admin/perfil']"
            [queryParams]="{cuenta: element.id}">{{usuariosService.get_fullaccount(element)}}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="direccion">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Dirección de Entrega </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.direccion ? element.direccion :
          element.direccion_entrega }} </td>
      </ng-container>

      <ng-container matColumnDef="provincia">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Provincia </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.provincia?.provincia}} </td>
      </ng-container>

      <ng-container matColumnDef="canton">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Canton </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.canton?.canton}} </td>
      </ng-container>

      <ng-container matColumnDef="distrito">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Distrito </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.distrito?.distrito}} </td>
      </ng-container>

      <ng-container matColumnDef="ruta">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Ruta </th>
        <td mat-cell *matCellDef="let element; let i = index;" class="pt-4">

          <mat-form-field appearance="outline" style="width: 250px">
            <mat-select [(ngModel)]="usuarios[i].ruta" placeholder="Ruta">
              <mat-option *ngFor="let ruta of rutas" [value]="ruta.id">
                {{ruta.nombre}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <label *ngIf="element.last_route" style="color: red;">* El cliente cambió su dirección *</label>
          
        </td>
      </ng-container>

      <!-- date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha de Registro </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{ element.created_at | date:'dd-MM-yyyy'
          }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true" [ngClass]="{'highlight-top-header': true }">
      </tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; let i = index"
        [ngClass]="{'highlight': odd }"></tr>
    </table>

    <div class="paginator text-center">
      <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
        (pageBoundsCorrection)="pageIndex = $event; paginate(pageIndex)"
        (pageChange)="pageIndex = $event; paginate(pageIndex)"></pagination-controls>
    </div>

    <span *ngIf="usuarios.length == 0">No hay cuentas pendientes por asignar ruta</span>
  </div>
</div>