/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { MatInput } from '@angular/material/input';

export interface DialogData {
  paquete: number;
  guia: string;
  tracking: string;
  medida: string;
  largo: number;
  alto: number;
  ancho: number;
  peso: number;
  peso_volumetrico: number;
  peso_total: number;
  bulto: number;
  tarifa: number;
}

@Component({
  selector: 'app-app-package',
  templateUrl: './app-package.component.html',
  styleUrls: ['./app-package.component.scss']
})
export class AppPackageComponent implements OnInit {

  @ViewChild('largo', { read: MatInput, static: true }) largo: MatInput;

  paquetesForm: any;
  paquete: any;
  dialogModal;

  tarifas: any;
  pesoRealKg = '';
  pesoTotalKg = '';
  editMode = false;

  constructor(

    public usuariosService: UsuariosService,
    public authService: AuthService,

    private fb: FormBuilder,
    private paquetesService: PaquetesService,
    private spinnerService: NgxSpinnerService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AppPackageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {

    this.paquetesForm = this.fb.group({
      paquete: [this.data.paquete, [Validators.required]],
      guia: [this.data.guia, [Validators.required]],
      tracking: [this.data.tracking, [Validators.required, Validators.maxLength(250)]],
      medida: [this.data.medida, [Validators.required, Validators.maxLength(250)]],
      largo: [this.data.largo, [Validators.required]],
      alto: [this.data.alto, [Validators.required]],
      ancho: [this.data.ancho, [Validators.required]],
      peso: [this.data.peso, [Validators.required, Validators.maxLength(250)]],
      peso_volumetrico: [this.data.peso_volumetrico, [Validators.required, Validators.maxLength(250)]],
      peso_total: [this.data.peso_total, [Validators.required, Validators.maxLength(250)]],
      bulto: [this.data.bulto, [Validators.required, Validators.maxLength(250)]],
    });

    this.getTarifas().then(() => {
      this.calcularMedidas();
      this.calcularPesoCobrable();
      this.UpdateKg(this.data.peso);
    });
  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.dialogModal === true) {
        //console.log(`Dialog result: ${result}`);
      }

    });
  }

  actualizarPaquete() {
    this.dialogRef.close(this.paquetesForm.value);
  }

  calcularMedidas() {
    console.log('here')

    if (this.paquetesForm.controls.largo.value && this.paquetesForm.controls.alto.value && this.paquetesForm.controls.ancho.value) {
      // eslint-disable-next-line max-len
      this.paquetesForm.controls.medida.setValue(`${this.paquetesForm.controls.largo.value} - ${this.paquetesForm.controls.alto.value} - ${this.paquetesForm.controls.ancho.value}`);
      // eslint-disable-next-line max-len
      const peso_volumetrico = (this.paquetesForm.controls.largo.value * this.paquetesForm.controls.alto.value * this.paquetesForm.controls.ancho.value) / 166;
      //console.log(peso_volumetrico);
      this.paquetesForm.controls.peso_volumetrico.setValue(Math.ceil(peso_volumetrico));
    }
  }

  calcularPesoCobrable() {
    const peso = parseFloat(this.paquetesForm.controls.peso.value);
    const peso_volumen = parseFloat(this.paquetesForm.controls.peso_volumetrico.value);

    const own = this;

    const tarifa = this.tarifas.filter(p => p.id === own.data.tarifa)[0].codigo;

    if (tarifa === 'A') {
      this.paquetesForm.controls.peso_total.setValue(peso);

    } else {
      this.paquetesForm.controls.peso_total.setValue(peso > peso_volumen ? peso : peso_volumen);
    }

    this.UpdateKgTotal(this.paquetesForm.value.peso_total);
  }

  public getTarifas() {
    return new Promise(async (resolve) => {
      this.paquetesService.getTarifas().subscribe((data: any) => {
        this.tarifas = data;
        resolve(true);
      });
    });
  }

  UpdateKg(value: number) {
    const kg = (value * 0.453592).toFixed(2);
    this.pesoRealKg = kg;

  }

  UpdateKgTotal(value: number) {
    const kg = (value * 0.453592).toFixed(2);
    this.pesoTotalKg = kg;
  }

  public getErrorMessage(control) {

    if (control.hasError('required')) {
      return 'Campo necesario';
    }
    if (control.hasError('minlength')) {
      return 'Demasiado Corto';
    }
    if (control.hasError('maxlength')) {
      return 'Demasiado Largo';
    }
    if (control.hasError('min')) {
      return 'Límite mínimo';
    }
    if (control.hasError('email')) {
      return 'No es un correo valido';
    }
    if (control.hasError('invalidCode')) {
      return 'Código Invalido';
    }
    return 'Error';
  }

}
