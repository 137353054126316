<div class="container">

  <div class="pt-3 pb-3" style="display: flex; align-items: center; justify-content: space-between;">
    <div style="width: 100%;">
      <button mat-button (click)="back()"
        style="padding: 0; padding-right: 10px; color: #0094a3; float: left;">
        <mat-icon>keyboard_arrow_left</mat-icon> Vendedores
      </button>

      <button mat-flat-button color="primary" class="button-calcular mr-4" style="padding: 0; float: right;"
        (click)="createUser()">Guardar</button>

    </div>
  </div>

  <form [formGroup]="formCreate" class="container">

    <div class="tab row">
      <div class="content col-12 p-5">
        <div class="row">
          <div class="content-title col-12">
            <p class="title">Información General</p>
          </div>

          <div class="inputs-form col-12">
            <div class="row">

              <div class="col-12 col-md-6 mb-4">
                <label class="label label_form_field_required w-100">Nombre</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput placeholder="Nombre" formControlName="nombre">
                  <mat-error *ngIf="formCreate.controls['nombre'].errors">
                    {{getErrorMessage(formCreate.controls['nombre'])}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 mb-4">
                <label class="label label_form_field_required w-100">Primer Apellido</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput placeholder="Primer Apellido" formControlName="primer_apellido">
                  <mat-error *ngIf="formCreate.controls['primer_apellido'].errors">
                    {{getErrorMessage(formCreate.controls['primer_apellido'])}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 mb-4">
                <label class="label w-100">Segundo Apellido</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput placeholder="Segundo Apellido" formControlName="segundo_apellido">
                  <mat-error *ngIf="formCreate.controls['segundo_apellido'].errors">
                    {{getErrorMessage(formCreate.controls['segundo_apellido'])}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 mb-4">
                <label class="label label_form_field_required w-100">Número de Cédula</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput placeholder="Número de Cédula" formControlName="numero_de_cedula">
                  <mat-error *ngIf="formCreate.controls['numero_de_cedula'].errors">
                    {{getErrorMessage(formCreate.controls['numero_de_cedula'])}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 mb-4">
                <label class="label label_form_field_required w-100">Correo</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput placeholder="Correo" #email formControlName="email" required
                    (blur)="checkEmail(email.value,'email')">
                  <mat-error *ngIf="formCreate.controls['email'].errors">
                    {{getErrorMessage(formCreate.controls['email'])}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 mb-4">
                <label class="label label_form_field_required w-100">Teléfono celular</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput placeholder="Teléfono celular" formControlName="telefono_movil">
                  <mat-error *ngIf="formCreate.controls['telefono_movil'].errors">
                    {{getErrorMessage(formCreate.controls['telefono_movil'])}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 mb-4">
                <label class="label label_form_field_required w-100">Contraseña</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput type="password" placeholder="Contraseña" formControlName="password">
                  <mat-error *ngIf="formCreate.controls['password'].errors">
                    {{getErrorMessage(formCreate.controls['password'])}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 mb-4">
                <label class="label label_form_field_required w-100">Confirmar contraseña</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput type="password" placeholder="Confirmar contraseña" formControlName="confirm_password">
                  <mat-error *ngIf="formCreate.controls['confirm_password'].errors">
                    {{getErrorMessage(formCreate.controls['confirm_password'])}}
                  </mat-error>
                  <mat-error
                    *ngIf="formCreate.controls['password'].value !== formCreate.controls['confirm_password'].value">
                    Contraseña no coinciden
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 mb-4">
                <label class="label w-100">Fecha de nacimiento</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput type="date" placeholder="Fecha de nacimiento" formControlName="fecha_de_nacimiento">

                  <mat-error *ngIf="formCreate.controls['fecha_de_nacimiento'].errors">
                    {{getErrorMessage(formCreate.controls['fecha_de_nacimiento'])}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 mb-4 pt-3">
                <label class="label w-100">Género</label><br>
                <mat-radio-group class="radio-group mt-1" formControlName="genero" name="gender">
                  <mat-radio-button *ngFor="let gender of genders" [value]="gender.value" class="radio-button mr-2">
                    {{ gender.name }}
                  </mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="formCreate.controls['genero'].errors">
                  {{getErrorMessage(formCreate.controls['genero'])}}
                </mat-error>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </form>
</div>
