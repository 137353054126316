import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { MatDialog } from '@angular/material/dialog';
import { GeneralInputComponent } from 'src/app/modules/common/dialogs/general-input/general-input.component';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-tipo-cliente',
  templateUrl: './tipo-cliente.component.html',
  styleUrls: ['./tipo-cliente.component.scss']
})
export class TipoClienteComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['id', 'nombre', 'color'];

  dataSource = new MatTableDataSource<any>();
  length = 0;

  constructor(
    private usuariosService: UsuariosService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;

    this.usuariosService.getTipoCliente().subscribe((value: any) => {
      this.dataSource.data = value;
      this.length = this.dataSource.data.length;
    });
  }

  crearTipo() {
    const dialogRef = this.dialog.open(GeneralInputComponent, {
      data: { title: 'Tipo de Usuario', value: '', type: 'general' },
      height: '250px',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        //console.log(result);

        const data = {
          nombre: result.value
        };

        this.usuariosService.postTipoCliente(data).subscribe(() => {
          this.openDialog('Tipo Creado');
          this.ngOnInit();
        });

      }
    });

  }

  editarTipo(id, nombre, color, check = false) {
    if (check === false) {
      const dialogRef = this.dialog.open(GeneralInputComponent, {
        data: { title: 'Tipo de Usuario', value: nombre, type: 'general' },
        height: '250px',
        width: '500px',
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != null) {
          //console.log(result);

          const data = {
            nombre: result.value,
            color
          };

          this.usuariosService.putTipoCliente(id, data).subscribe(() => {
            this.openDialog('Tipo Actualizado');
            this.ngOnInit();
          });

        }
      });
    } else {
      const data = {
        nombre,
        color
      };

      this.usuariosService.putTipoCliente(id, data).subscribe(() => {
        this.openDialog('Color Actualizado');
        this.ngOnInit();
      });
    }

  }

  deleteTipo(id) { }

  private openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });
  }


}
