<div class="confirmar">
  <h3 mat-dialog-title class="modal-title text-center">{{'Confirmar' | translate}}</h3>

  <p style="text-align: justify;white-space: pre-wrap">{{data.message}}</p>

  <p>
    <mat-checkbox *ngIf="data.emailcheckbox" [value]="sendEmail" [checked]="sendEmail" #send
      (change)="sendEmail = send.checked">
      Modificar Correo a Clientes
    </mat-checkbox>
  </p>

  <div mat-dialog-actions style="display: flex; justify-content: space-between;">
    <button mat-flat-button color="primary" class="button1" (click)="onNoClick()">{{'Cancelar' | translate}}</button>
    <button mat-flat-button color="primary" class="button1" (click)="onYesClick()">{{'Aceptar' | translate}}</button>
  </div>
</div>