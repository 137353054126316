<div class="content-general">
  <div>
    <div class="pt-2"
      style="width: 100%; text-align: center; font-family: Varela Round, sans-serif; font-size: 18px !important;">
      <button mat-button [routerLink]="['/admin/administracion/alianzas']"
        style="padding: 0; padding-right: 10px; color: #0094a3; float: left;">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>

      Editar Alianza

      <button mat-flat-button color="primary" class="button-calcular" style="float: right;" (click)="saveAlianza()">
        <mat-icon color="light" style="font-size: 1.5em !important; color: white !important">save</mat-icon> Guardar
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>


  <div class="row" [formGroup]="formAlianza">
    <div class="col-12 col-md-6">
      <div class="content-general">
        <div class="content" style="padding: 10px">

          <div class="content-title">
            <p class="title">Descripción de la Alianza</p>
          </div>

          <div class="mt-5 pt-4">
            <div class="row">
              <div class="col-12">
                <label class="label">Nombre</label><br>
                <mat-form-field appearance="outline" class="form-field">
                  <input #nombre (blur)="checkField('nombre')" matInput formControlName="nombre"
                    class="input-field">
                  <mat-error *ngIf="formAlianza.controls['nombre'].errors">
                    {{getErrorMessage(formAlianza.controls['nombre'])}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12">
                <label class="label">Descripción</label><br>
                <mat-form-field appearance="outline" class="form-field">
                  <input #nombre matInput formControlName="descripcion"
                    class="input-field">
                  <mat-error *ngIf="formAlianza.controls['descripcion'].errors">
                    {{getErrorMessage(formAlianza.controls['descripcion'])}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12">
                <label class="label">Dominio</label><br>
                <mat-form-field appearance="outline" class="form-field">
                  <input #domain (blur)="checkField('domain')" matInput formControlName="domain"
                    class="input-field">
                  <mat-error *ngIf="formAlianza.controls['domain'].errors">
                    {{getErrorMessage(formAlianza.controls['domain'])}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12">
                <label class="label">Prefijo</label><br>
                <mat-form-field appearance="outline" class="form-field">
                  <input #prefijo (blur)="checkField('prefijo')" matInput formControlName="prefijo"
                    class="input-field">
                  <mat-error *ngIf="formAlianza.controls['prefijo'].errors">
                    {{getErrorMessage(formAlianza.controls['prefijo'])}}
                  </mat-error>
                </mat-form-field>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <div class="content-general">
        <div class="content" style="padding: 10px">

          <div class="content-title">
            <p class="title">Tarifas Asociada</p>
          </div>

          <div class="mt-5 pt-4">
            <div class="row">


              <div class="col-12">
                <label class="label">Tarifa Aérea</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-select matInput placeholder="Tarifa Aérea" formControlName="tarifaA">
                    <mat-option *ngFor="let tarifa of tarifasAereas" [value]="tarifa.id">{{tarifa.nombre}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-12">
                <label class="label">Tarifa Marítima</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-select matInput placeholder="Tarifa Marítima" formControlName="tarifaM">
                    <mat-option *ngFor="let tarifa of tarifasMaritimas" [value]="tarifa.id">{{tarifa.nombre}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-12">
                <label class="label">Vendedor</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-select matInput placeholder="Vendedor" formControlName="vendedor">
                    <mat-option value="">Ninguno</mat-option>
                    <mat-option *ngFor="let v of vendedores" [value]="v.id">{{usuariosService.get_fullname(v)}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-12">
                <label class="label">Estado</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-select matInput placeholder="Seleccione" formControlName="status">
                    <mat-option value="1">Activo</mat-option>
                    <mat-option value="0">Inactivo</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
