<div class="content p-1" fxLayout="column" style="width: 100% !important;">
  <div class="content">

    <div class="content-table m-4">
      <table mat-table [dataSource]="pendientes" matSort (matSortChange)="sortData($event)">

        <!-- cuenta Column -->
        <ng-container matColumnDef="cuenta">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cuenta </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element">
            <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: element.cuenta.id}">
              {{usuariosService.get_fullaccount(element.cuenta) }}
            </a>
          </td>
        </ng-container>

        <!-- factura Column -->
        <ng-container matColumnDef="guias">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Guías </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element">
            <a *ngFor="let item of element.paquete; let i = index" [routerLink]="['/admin/paquetes/paquete']"
              [queryParams]="{pack: item}">{{element.guias[i]}}
            </a>
          </td>
        </ng-container>

        <!-- fecha Column -->
        <ng-container matColumnDef="fecha">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha y Hora </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.created_at | date: 'dd-MM-yyyy HH:mm'}}
          </td>
        </ng-container>

        <!-- referencia Column -->
        <ng-container matColumnDef="referencia">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Referencia </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.referencia}} <mat-icon
              (click)="editReferencia(element)" style="color: #004481; font-size: 16px; cursor: pointer">edit</mat-icon>
          </td>
        </ng-container>

        <!-- monto Column -->
        <ng-container matColumnDef="monto_usd">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Monto Dólares</th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.monto | currency:
            'USD':'symbol-narrow':'1.2-2' }} </td>
        </ng-container>

        <!-- monto Column -->
        <ng-container matColumnDef="monto_crc">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Monto Colones </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{ element.monto_crc | currency:
            'CRC':'symbol-narrow':'1.2-2'}} </td>
        </ng-container>

        <ng-container matColumnDef="comprobante">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Comprobante </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element">
            <button style="width: auto;margin-left: 15px;" class="button-archivo mt-2"
              (click)="gotoFile(element.comprobante)" mat-flat-button color="primary">
              <mat-icon style="color: white !important; font-size: 16px !important;">download</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- creador Column -->
        <ng-container matColumnDef="creador">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Creador </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{usuariosService.get_fullaccount(element.creador)}}
          </td>
        </ng-container>

        <!-- opciones Column -->
        <ng-container matColumnDef="opciones">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Opciones </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element">
            <button mat-flat-button color="primary" class="button-calcular" (click)="aprobarPago(element)">
              Aprobar Pago
            </button>

            <button mat-flat-button color="primary" class="button-calcular" (click)="rechazarPago(element)">
              Rechazar Pago
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div class="m-4" style="text-align: center;" *ngIf="pendientes?.length == 0">
        No hay pagos pendientes por aprobar
      </div>
    </div>
  </div>

</div>