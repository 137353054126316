import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { TarifaDeProducto } from '../models/tarifa-de-producto';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TarifaDeProductoService {
  private url = `${environment.apiUrl}/tarifas-de-productos/`;

  constructor(
    private http: HttpClient
  ) { }

  get() {
    return this.http.get(this.url);
  }
  getByID(id) {
    return this.http.get(this.url+id);
  }
  post(data){
    return this.http.post(this.url, data);
  }
  update(id,data){
    return this.http.put(this.url+id+'/', data);
  }

  // all(): Observable<TarifaDeProducto[]> {

  //   return this.httpClient.get<TarifaDeProducto[]>(this.url)
  //     .pipe(
  //       map((data: any[]) =>
  //         data.map(
  //           (item: any) =>
  //             new TarifaDeProducto(item.id, item.descripcion, item.porcentaje, item.advertencia)
  //         )
  //       )
  //     )
  //     .pipe(catchError(this.handleError<any>()));
  // }

  // store(tarifaData: any): Observable<TarifaDeProducto> {

  //   return this.httpClient.post<TarifaDeProducto>(this.url, tarifaData)
  //     .pipe(
  //       map((data: any) =>
  //         new TarifaDeProducto(data.id, data.descripcion, data.porcentaje, data.advertencia)
  //       )
  //     )
  //     .pipe(catchError(this.handleError<any>()));
  // }

  // update(tarifaDeProducto: TarifaDeProducto): Observable<TarifaDeProducto> {

  //   //console.log(tarifaDeProducto);
  //   return this.httpClient.put<TarifaDeProducto>(this.url + tarifaDeProducto.id + '/', tarifaDeProducto)
  //     .pipe(
  //       map((data: any) =>
  //         new TarifaDeProducto(data.id, data.descripcion, data.porcentaje, data.advertencia)
  //       )
  //     )
  //     .pipe(catchError(this.handleError<any>()));
  // }

 delete(id: number) {

    return this.http.delete(this.url + id + '/');
   }

  // private handleError<T>() {
  //   return (error: any): Observable<T> => {

  //     // Let the app keep running by returning an empty result.
  //     return of(error as T);
  //   };
  // }
}
