import { NgxSpinnerService } from 'ngx-spinner';
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/naming-convention */
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { GeneralInputComponent } from 'src/app/modules/common/dialogs/general-input/general-input.component';
import { EmbarquesService } from 'src/app/services/embarques.service';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { AuthService } from 'src/app/services/auth.service';
import { isNumber } from 'util';
import { ChangeDetectorRef } from '@angular/core';
import { faArchive } from '@fortawesome/free-solid-svg-icons';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';

export interface Paquetes {
  id: number;
  guia: number;
  nombre: string;
  idestado: number;
  estado: string;
  cuenta: string;
  tracking: string;
  descripcion: string;
  peso_total: number;
  peso_volumetrico: number;
  codenvio: string;
  envio: string;
  creacion: Date;
  ultima_actualizacion: Date;
  tipo_paquete: string;
  ruta: string;
  factura: string;
  factura2: string;
  factura3: string;
  cantpaquetes: number;
  escaneado_embarque: boolean;
  usuarioid: number;
  tipo_cliente: any;
  factura_miami: boolean;
}

@Component({
  selector: 'app-index-embarques',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexEmbarquesComponent implements OnInit {
  faArchive = faArchive;
  faShoppingBag = faShoppingBag;
  faEnvelope = faEnvelope;

  mediaUrl = environment.mediaUrl;

  length = 0;
  pageSize = 50;
  pageIndex = 1;

  // eslint-disable-next-line max-len
  displayedColumns: string[] = ['seleccionar', 'nombre', 'tipo_cliente', 'tracking', 'guia', 'descripcion', 'tipo_paquete', 'peso_real', 'peso_volumetrico', 'factura', 'ver', 'modificado'];

  paquetes = [];
  filter = [];
  dataSource = [];
  selectedPackages = new SelectionModel<Paquetes>(true, []);

  sobres = [];
  sinfactura = [];
  confactura = [];
  maritimo = [];
  retenidos = [];
  completados = [];
  porcompletar = [];

  totalWeigth;
  totalWeigthVolumen;

  status = 'paquetes';

  showPortal = false;
  facturas = [];

  constructor(
    private paquetesService: PaquetesService,
    public usuariosService: UsuariosService,
    public embarqueService: EmbarquesService,
    public router: Router,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    public authService: AuthService) { }

  ngOnInit(): void {
    this.getPackagesEmbarque();
    this.totalWeigth = 0;
    this.totalWeigthVolumen = 0;
  }

  getTipoCliente(usuario) {
    return usuario.map(a => a.nombre);
  }

  getPackagesEmbarque() {
    this.spinner.show();
    this.paquetes = [];
    this.paquetesService.getForShip().subscribe(
      (res: any) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
        const paquetes_lista: Paquetes[] = [];
        res.forEach((element, index, array) => {
          paquetes_lista.push({
            id: element.id,
            guia: element.numero_de_guia,
            nombre: this.usuariosService.get_fullname(element.usuario),
            idestado: element.estado_actual.id,
            estado: element.estado_actual.estado,
            cuenta: this.usuariosService.get_account_number(element.usuario),
            tracking: element.tracking,
            descripcion: element.descripcion,
            codenvio: element.plan,
            envio: element.plan.nombre,
            peso_total: element.peso,
            peso_volumetrico: element.peso_volumetrico,
            creacion: new Date(element.created_at),
            ultima_actualizacion: new Date(element.updated_at),
            tipo_paquete: element.tipo_de_paquete,
            ruta: element.usuario ? element.usuario.ruta ? element.usuario.ruta.nombre : '' : '',
            factura: element.factura_1,
            factura2: element.factura_2,
            factura3: element.factura_3,
            cantpaquetes: element.paquete_contenedor.length === 0 ? 1 : element.paquete_contenedor.length,
            escaneado_embarque: element.escaneado_embarque,
            usuarioid: element.usuario?.id,
            tipo_cliente: element.usuario?.tipo_cliente?.length > 0 ? element.usuario.tipo_cliente : [],
            factura_miami: element.factura_miami
          });

          if (index == array.length - 1) {
            this.spinner.hide();

            this.completados = paquetes_lista.filter(function (el) {
              return el.idestado === 4;
            });

            this.sobres = paquetes_lista.filter(function (el) {
              return el.idestado === 4 && el.codenvio !== 'C' && (el.tipo_paquete === 'Correspondencia' || el.tipo_paquete === 'SobreBolsa');
            });

            this.confactura = paquetes_lista.filter(function (el) {
              return el.idestado === 4 && el.codenvio !== 'C' && el.factura != null;
            });

            this.sinfactura = paquetes_lista.filter(function (el) {
              return el.idestado === 4 && el.codenvio !== 'C' && el.factura == null;
            });

            this.maritimo = paquetes_lista.filter(function (el) {
              return el.codenvio === 'M';
            });

            this.retenidos = paquetes_lista.filter(function (el) {
              return el.codenvio === 'C';
            });

            this.porcompletar = paquetes_lista.filter(function (el) {
              return el.idestado === 2;
            });

            this.paquetes = paquetes_lista;
            this.dataSource = this.completados;
            this.length = paquetes_lista.length;
            this.pageSize = this.length;
          }

        });

      }
    );
  }

  public changeTable(filter, status) {
    this.status = status;
    this.filter = filter;
    this.dataSource = this.filter;
    this.length = this.dataSource.length;
    this.pageSize = this.length;
  }

  public crearEmbarque() {
    const own = this;
    const sinCompletar = this.selectedPackages.selected.filter(function (el) {
      return el.idestado === 2;
    });

    if (sinCompletar.length === 0) {

      if (this.selectedPackages.selected.length > 0) {
        this.embarqueService.selectedPackages = this.selectedPackages.selected;
        this.router.navigateByUrl('/almacen/embarques/agregar');
      } else {
        this.openDialog('No hay paquetes seleccionados');
      }

    } else {
      this.openDialog('Ha seleccionado paquetes sin completar. Revise su selección e intente nuevamente');
    }
  }

  public adjuntarPaquetes() {
    if (this.selectedPackages.selected.length > 0) {
      const dialogRef = this.dialog.open(GeneralInputComponent, {
        data: { title: 'Seleccione Embarque', value: 0, type: 'embarques' },
        height: '250px',
        width: '500px',
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != null) {
          this.embarqueService.selectedPackages = this.selectedPackages.selected;
          this.router.navigate(['/almacen/embarques/agregar'], { queryParams: { pack: result.value } });
        }
      });

    } else {
      this.openDialog('No hay paquetes seleccionados');
    }
  }

  public isAllSelected = (): boolean => {
    this.filter = this.dataSource;
    const numSelected = this.selectedPackages.selected.length;
    const numRows = this.filter.length;

    if (numSelected !== 0 && numRows !== 0) {
      return numSelected === numRows;
    } else {
      return false;
    }
  };

  public masterToggle = (): void => {

    this.isAllSelected() ?
      this.selectedPackages.clear() :
      this.filter.forEach(row => this.selectedPackages.select(row));
    this.calculateWeigth();
  };

  public checkboxLabel = (row?: Paquetes): string => {
    this.calculateWeigth();
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectedPackages.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  };

  paginate(event: any) {
    //console.log('paginate', event);
    this.pageIndex = event;
  }

  calculateWeigth() {
    this.totalWeigth = 0;
    this.totalWeigthVolumen = 0;

    this.selectedPackages.selected.forEach(element => {
      this.cdRef.markForCheck();
      this.totalWeigth = isNumber(element.peso_total) ? this.totalWeigth + element.peso_total : this.totalWeigth;
      this.totalWeigthVolumen = isNumber(element.peso_volumetrico) ? this.totalWeigthVolumen + element.peso_volumetrico : this.totalWeigthVolumen;
    });
  }

  openViewer(paquetes) {
    //console.log(paquetes);
    this.showPortal = false;

    this.facturas = [];

    const paquete = {
      guia: paquetes.guia,
      factura: paquetes.factura,
      factura2: paquetes.factura2,
      factura3: paquetes.factura3,
      consolidado: paquetes.consolidado ? true : paquetes.adicional ? true : false,
    };

    this.facturas.push(paquete);

    //console.log(this.facturas);

    if (paquetes.paquete_contenedor) {
      if (paquetes.paquete_contenedor.length > 0) {

        paquetes.paquete_contenedor.forEach((element, index, array) => {
          const paquete = {
            guia: element.numero_de_guia,
            factura: element.factura_1,
            factura2: element.factura_2,
            factura3: element.factura_3,
            consolidado: false,
          };
          this.facturas.push(paquete);

          //console.log(index, array.length);
          if (index == array.length - 1) {
            //console.log(this.facturas);
            this.showPortal = true;
          }
        });

      } else {
        this.showPortal = true;
      }
    } else {
      this.showPortal = true;
    }
  }

  closeViewer(event) {
    //console.log('viewer close', event);
    this.showPortal = false;
  }

  gotoFile(file) {
    window.open(file, '', 'width=600,height=400,left=200,top=200,toolbar=no,titlebar=no');
    event.preventDefault();
  }

  export() {
    this.spinner.show();

    this.pageIndex = 1;

    const selectedPackages = this.selectedPackages.selected;
    const allPackages = this.dataSource;

    this.dataSource = selectedPackages;

    setTimeout(() => {
      const table = document.getElementById('paquetesTable');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, this.status + '.xlsx');

      this.spinner.hide();
      this.dataSource = allPackages;

    }, 2000);
  }

  sortData(sort: Sort) {
    console.log(sort);
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'nombre':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'cuenta':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'guia':
          return compare(a.guia, b.guia, isAsc);
        case 'peso_real':
          return compare(a.peso_total, b.peso_total, isAsc);
        case 'zona':
          return compare(a.entrega.zona_centro_distribucion, b.entrega.zona_centro_distribucion, isAsc);
        case 'tipo_paquete':
          return compare(a.tipo_paquete, b.tipo_paquete, isAsc);
        case 'modificado':
          return compare(a.ultima_actualizacion, b.ultima_actualizacion, isAsc);
        case 'fecha_entrega':
          return compare(a.entrega.fecha_entrega, b.entrega.fecha_entrega, isAsc);
        case 'zona_entrega':
          return compare(a.entrega.canton.canton, b.entrega.canton.canton, isAsc);
        case 'usuario':
          return compare(a.nombre, b.nombre, isAsc);
        case 'factura':
          return compare(a.factura, b.factura, isAsc);
        case 'alistado':
          return compare(a.entrega.alistado, b.entrega.alistado, isAsc);
        case 'peso_volumetrico':
          return compare(a.peso_volumetrico, b.peso_volumetrico, isAsc);
        case 'descripcion':
          return compare(a.descripcion, b.descripcion, isAsc);
        case 'tracking':
          return compare(a.tracking, b.tracking, isAsc);
        default:
          return 0;
      }
    });
  }

  private openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  //console.log(a,b);
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
