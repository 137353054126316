<div class="container-example" fxLayout="column" fxLayoutGap="10px" style=" width:100%;">
  <div style="background-color: #0094a3; border-radius: 0px 0px 0px 0px;">
    <p
      style="text-align: center;color:white;  font-size: 20px; font-family: 'Varela Round'; line-height: 22px; font-size: 18px; font-style: normal;padding-top: 13px; height: 50px; padding-top: 15px;">
      {{'Editar Autorización' | translate}}</p>
  </div>
  <form (ngSubmit)="edit(formAutorizaciones.value)" [formGroup]="formAutorizaciones" class="form">
    <div class="row">
      <div class="col-lg-12">
        <label class="label">{{'Nombre Completo' | translate}}</label><br>
        <mat-form-field appearance="outline">
          <input matInput formControlName="nombre">
          <mat-error *ngIf="formAutorizaciones.get('nombre').hasError('pattern')">
            {{'Por favor ingresa un nombre válido' | translate}}
          </mat-error>
          <mat-error *ngIf="formAutorizaciones.get('nombre').hasError('required')">
            {{'El campo es' | translate}} <strong>{{'requerido'| translate}}</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-lg-6">
        <label class="label">{{'Cédula de Identidad' | translate}}</label><br>
        <mat-form-field appearance="outline">
          <input matInput formControlName="cedula">
          <mat-error *ngIf="formAutorizaciones.get('cedula').hasError('pattern')">
            {{'Por favor ingresa una cédula valida' | translate}}
          </mat-error>
          <mat-error *ngIf="formAutorizaciones.get('cedula').hasError('required')">
            {{'El campo es' | translate}} <strong>{{'requerido' | translate}}</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-lg-6">
        <label class="label">{{'Email' | translate}}</label><br>
        <mat-form-field appearance="outline">
          <input matInput placeholder="" formControlName="email">
          <mat-error *ngIf="formAutorizaciones.get('email').hasError('email')">
            {{'Por favor ingresa un correo valida' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-lg-6">
        <label class="label">{{'Tipo de Autorización' | translate}}</label><br>
        <mat-form-field appearance="outline">
          <mat-select formControlName="tipo_autorizacion">
            <mat-option style="font-size: 13px;line-height: 2em;padding: 20px;height: auto;"
              *ngFor="let tipo of tiposDeAutorizacion" [value]="tipo.name">
              <span>{{tipo.name}}</span><br>
              <span style="white-space: break-spaces;"> {{tipo.desp}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-lg-6">
        <label class="label">{{'Celular' | translate}}</label><br>
        <mat-form-field appearance="outline">
          <input matInput placeholder="" formControlName="telefono">
          <mat-error *ngIf="formAutorizaciones.get('telefono').hasError('pattern')">
            {{'Por favor ingresa un telefono valido' | translate}}
          </mat-error>
          <mat-error *ngIf="formAutorizaciones.get('telefono').hasError('required')">
            {{'El campo es' | translate}} <strong>{{'requerido' | translate}}</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-lg-12">
        <div style="text-align: left; float: right;">
          <button mat-flat-button color="primary" class="button-calcular" type="submit"
            style="background-color: #0094a3;" [disabled]="!formAutorizaciones.valid">{{'Guardar' | translate}}</button>
          <div style="height: 30px;"></div>
        </div>
      </div>
    </div>
  </form>
</div>
