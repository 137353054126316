import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { AuthService } from 'src/app/services/auth.service';

import { FacturasService } from 'src/app/services/facturas.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PagosService } from 'src/app/services/pagos.service';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { TarifasService } from 'src/app/services/tarifas.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-consolidation-modal',
  templateUrl: './consolidation-modal.component.html',
  styleUrls: ['./consolidation-modal.component.scss']
})
export class ConsolidationModalComponent implements OnInit {

  params: any;

  constructor(
    public dialogRef: MatDialogRef<ConsolidationModalComponent>,
    private dialog: MatDialog,
    public facturasServices: FacturasService,
    public tarifasServices: TarifasService,
    public paquetesService: PaquetesService,
    public usuariosService: UsuariosService,
    private navigation: NavigationService,
    private authService: AuthService,

    @Inject(MAT_DIALOG_DATA) public data: any

  ) { }

  ngOnInit() {
    this.params = this.data;
    //console.log('params',this.params);

  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });


    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  back() {
    this.navigation.back();
  }

  public onNoClick = (): void => {
    this.dialogRef.close();
  };

}
