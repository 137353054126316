/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
import { Validators } from '@angular/forms';
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { AuthService } from 'src/app/services/auth.service';

import { FacturasService } from 'src/app/services/facturas.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PagosService } from 'src/app/services/pagos.service';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { TarifasService } from 'src/app/services/tarifas.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FacturaElectronicaService } from '../../../../services/factura_electronica.service';

@Component({
  selector: 'app-factura-modal',
  templateUrl: './factura-modal.component.html',
  styleUrls: ['./factura-modal.component.scss']
})
export class FacturaModalComponent implements OnInit {
  form: any;
  formNotaCredito: any;
  formNotaDebito: any;
  params: any;
  tarifasVariables;
  tarifasManejo;

  factura: any;
  paquete: any;

  isConsolidado = false;
  isExonerado = false;
  isClaseD = false;
  planEconomico = false;
  editable = true;

  origin = 'facturas';
  paqueteId = 0;

  notaCredito;
  notaDebito;

  editNotaCredito = false;
  showNotaCredito = false;

  editNotaDebito = false;
  showNotaDebito = false;

  opcionesNotasCredito = [
    { value: 1, viewValue: 'Transferencia' },
    { value: 2, viewValue: 'Reembolso a tarjeta' },
  ];

  permisosSelect = '0';
  otrosCargos1Select = '0';
  otrosCargos2Select = '0';
  otrosCargos3Select = '0';

  descuentoCRC = 0;

  notaCreditoCRC = 0;
  notaDebitoCRC = 0;

  tipoNotaCredito;
  notaParcial = '0';
  cerrarModal = true;

  transporteInternacional = 0;
  servicioSuperbox = 0;
  cargoImpuestos = 0;
  cargoEncomiendas = 0;
  no_modificable_keys = [
        'subtotal',
        'total',
        'porcentaje_hacienda',
        'cargo_impuestos',
        'cargo_confeccion_bl',
        'cargo_consolidacion',
        'cargo_exoneracion',
        'cargo_bodegaje',
  ]

  constructor(
    public dialogRef: MatDialogRef<FacturaModalComponent>,
    private fb: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public facturasServices: FacturasService,
    public tarifasServices: TarifasService,
    public paquetesService: PaquetesService,
    public usuariosService: UsuariosService,
    public authService: AuthService,
    private navigation: NavigationService,
    private pagosService: PagosService,
    private facturaElectronicaService: FacturaElectronicaService,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) { }

  ngOnInit() {
    this.params = this.data;
    //console.log('params', this.params);
    this.form = this.fb.group({
      numero: [''],
      cif: [''],
      cargo_transporte: [''],
      cargo_fuel: [''],
      cargo_seguro: [''],
      cargo_manejo: [''],
      cargo_impuestos: [''],
      cargo_confeccion_guia: [''],
      cargo_confeccion_bl: [''],
      cargo_consolidacion_paquetes: [''],
      cargo_exoneracion: [''],
      cargo_permiso: [''],
      cargo_bodegaje: [''],
      cargo_encomienda: [''],
      cargo_compra_corporativa: [''],
      cargo_gestion_compra_corporativa: [''],
      otros_cargos_1: [''],
      otros_cargos_1_descripcion: [''],
      otros_cargos_2: [''],
      otros_cargos_2_descripcion: [''],
      otros_cargos_3: [''],
      otros_cargos_3_descripcion: [''],
      tipo_descuento: ['Normal'],
      descuento: [''],
      descuento_descripcion: [''],
      codigo_promocional: [''],
      descuento_promocional: [''],
      flete_internacional: [''],
      servicios_sb: [''],
      iva: [''],
      subtotal: [''],
      total: [''],
      completada: [false],
      // No Aplica para el modelo
      porcentaje_hacienda: [''],
      nota_credito: [null],
      nota_debito: [null],
      manual: ['']
    });

    this.formNotaCredito = this.fb.group({
      factura: [''],
      descripcion: ['', [Validators.required]],
      monto: ['', [Validators.required]],
      monto_crc: ['', [Validators.required]],
      iva_crc: [0, [Validators.required]],
      tipo: ['', [Validators.required]],
      aplicada: [''],
      created_by: [''],
      parcial: [''],
    });

    this.formNotaDebito = this.fb.group({
      factura: [''],
      descripcion: ['', [Validators.required]],
      monto: ['', [Validators.required]],
      monto_crc: ['', [Validators.required]],
      created_by: [''],
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.origin) {
        this.origin = params.origin;
      }

      this.facturasServices.getFacturaByID(this.params).subscribe((res: any) => {
        //console.log('Get Factura', res);
        this.factura = res;
        this.editable = !this.factura.generado_gti && this.authService.validateRolePermission('EDIT_BILL');
        this.form.controls['numero'].setValue(this.facturasServices.getOrCreateNumber(this.factura));
        this.form.controls['manual'].setValue(this.factura.manual);
        if (this.factura.nota_credito) {
          this.factura.nota_credito = this.factura.nota_credito.filter(function (el) {
            return (el.anulada === false);
          });
        }
        if (this.factura.cargo_permiso != '' && this.factura.cargo_permiso != null) {
          this.otrosCargos1Select = this.factura.cargo_permiso;
        }

        if (this.factura.otros_cargos_1 > 0) {
          this.otrosCargos1Select = '-1';
          this.form.controls['otros_cargos_1_descripcion'].setValue(this.factura.otros_cargos_1_descripcion);
        }

        if (this.factura.otros_cargos_2 > 0) {
          this.otrosCargos2Select = '-1';
          this.form.controls['otros_cargos_2_descripcion'].setValue(this.factura.otros_cargos_2_descripcion);
        }

        if (this.factura.otros_cargos_3 > 0) {
          this.otrosCargos3Select = '-1';
          this.form.controls['otros_cargos_3_descripcion'].setValue(this.factura.otros_cargos_3_descripcion);
        }

        if (this.factura.descuento > 0) {
          this.form.controls['descuento_descripcion'].setValue(this.factura.descuento_descripcion);
        }

        this.servicioSuperbox = this.factura.servicios_sb;
        this.transporteInternacional = this.factura.flete_internacional;
        this.cargoImpuestos = this.factura.cargo_impuestos;
        this.cargoEncomiendas = this.factura.cargo_encomienda;

        this.paquetesService.getByFactura(this.factura.id).subscribe((res1: any) => {
          //console.log('Get Paquete', res1);
          this.paquete = res1[0];

          if (this.paquete.paquete_contenedor.length > 0) {
            if (this.paquete.paquete_contenedor[0].consolidado) {
              this.isConsolidado = true;
            }
            if (this.paquete.paquete_contenedor[0].adicional) {
              this.isConsolidado = true;
            }
          }

          if (this.paquete.codigo_promocional) {
            this.factura.codigo_promocional = this.paquete.codigo_promocional;
          }

          const ncs = this.factura.nota_credito ?? [];
          if (ncs.length > 0) {
            this.notaCredito = this.factura.nota_credito[0];
            this.showNotaCredito = true;
            this.editNotaCredito = true;

            this.formNotaCredito = this.fb.group({
              factura: [this.notaCredito.factura],
              descripcion: [this.notaCredito.descripcion],
              monto: [this.notaCredito.monto],
              monto_crc: [this.notaCredito.monto_crc],
              iva_crc: [this.notaCredito.iva_crc],
              tipo: [this.notaCredito.tipo],
              aplicada: [this.notaCredito.aplicada],
              created_by: [this.notaCredito.created_by],
              parcial: [this.notaCredito.parcial],
            });

            //console.log('Nota', this.notaCredito);
            // this.factura.nota_credito = this.factura.nota_credito.id;
          }

          if (this.factura.nota_debito != null) {
            this.factura.nota_debito = this.factura.nota_debito.filter(function (el) {
              return (el.anulada === false);
            });

            const nds = this.factura.nota_debito ?? [];
            if (nds.length > 0) {
              this.notaDebito = this.factura.nota_debito[0];
              this.showNotaDebito = true;
              this.editNotaDebito = true;

              this.formNotaDebito = this.fb.group({
                factura: [this.notaDebito.factura],
                descripcion: [this.notaDebito.descripcion],
                monto: [this.notaDebito.monto],
                monto_crc: [this.notaDebito.monto_crc],
                created_by: [this.notaDebito.created_by],
              });
            } else {
              this.factura.nota_debito = null;
            }

            //console.log('Nota Credito', this.notaCredito);
            //console.log('Nota Debito', this.notaDebito);

            //this.factura.nota_debito = this.factura.nota_debito.id;
          }

          this.setValuesToForm(this.factura);

          this.tarifasServices.getTarifasVariables(this.paquete.tarifa.id).subscribe(tarifasVariables => {
            this.tarifasVariables = tarifasVariables;
            this.tarifasVariables = this.tarifasVariables.filter(function (el) {
              return (el.group === 1);
            });
          });
          /*
            if (this.editable) {
              this.Calculate();
            }
          */
        });

      });
    });
  }

  public Calculate() {

    const data: any = {
      weight: this.paquete.peso_total,
      price: this.paquete.precio,
      taxes: this.factura.cargo_impuestos,
      tax: this.factura.porcentaje_hacienda
    };

    if (this.paquete.paquete_contenedor.length > 0) {
      if (this.paquete.paquete_contenedor[0].consolidado) {
        this.isConsolidado = true;
        data.consolidate = this.isConsolidado;
      }
      if (this.paquete.paquete_contenedor[0].adicional) {
        this.isConsolidado = true;
        data.consolidate = this.isConsolidado;
      }
    }

    if (this.paquete.exonerado === true) {
      this.isExonerado = true;
    }

    if (this.paquete.exonerado === true) {
      data.exonerate = true;
    }

    if (this.paquete.clase === 'D') {
      data.d_class = true;
      this.isClaseD = true;
    }

    if (this.factura.descuento) {
      data.discount = this.factura.descuento;
    }

    if (this.factura.descuento_promocional) {
      data.promotional_discount = this.factura.descuento_promocional;
    }

    if (this.factura.cargo_permiso) {
      data.authorization_charge = this.factura.cargo_permiso;
    }

    if (this.factura.cargo_compra_corporativa) {
      data.compra_corporativa = this.factura.cargo_compra_corporativa;
    }

    if (this.factura.cargo_gestion_compra_corporativa) {
      data.gestion_compra_corporativa = this.factura.cargo_gestion_compra_corporativa;
    }

    if (this.factura.otros_cargos_1) {
      data.other_charges = data.other_charges + this.factura.otros_cargos_1;
    }

    if (this.factura.otros_cargos_2) {
      data.other_charges = data.other_charges + this.factura.otros_cargos_2;
    }

    if (this.factura.otros_cargos_3) {
      data.other_charges = data.other_charges + this.factura.otros_cargos_3;
    }

    if (this.paquete.tarifa.codigo === 'A') {
      data['tarifa_a'] = this.paquete.tarifa.id;
    } else {
      data['tarifa_a'] = this.paquete.usuario.tarifaA;
    }

    if (this.paquete.tarifa.codigo === 'M') {
      data['tarifa_m'] = this.paquete.tarifa.id;
    } else {
      data['tarifa_m'] = this.paquete.usuario.tarifaM;
    }

    if (this.factura.codigo_promocional) {
      data.promotional_code = this.factura.codigo_promocional;
    }

    data['type'] = 1;

    this.facturasServices.calculator(data).subscribe((res: any) => {
      let presupuesto: any = {};
      if (this.paquete.plan === 'A') {
        presupuesto = res.A;
      } else {
        presupuesto = res.M;
        this.planEconomico = true;
      }

      this.assignValidNumberValue(presupuesto.cif, 'cif');
      this.assignValidNumberValue(presupuesto.cargo_charge, 'cargo_transporte');
      this.assignValidNumberValue(presupuesto.fuel_charge, 'cargo_fuel');
      this.assignValidNumberValue(presupuesto.insurance_charge, 'cargo_seguro');
      this.assignValidNumberValue(presupuesto.handling_charge, 'cargo_manejo');
      this.assignValidNumberValue(presupuesto.tax_rate, 'porcentaje_hacienda');
      this.assignValidNumberValue(presupuesto.taxes, 'cargo_impuestos');
      this.assignValidNumberValue(presupuesto.guide_charge, 'cargo_confeccion_guia');
      this.assignValidNumberValue(presupuesto.bl_charge, 'cargo_confeccion_bl');
      this.assignValidNumberValue(presupuesto.consolidation_charge, 'cargo_consolidacion_paquetes');
      this.assignValidNumberValue(presupuesto.exoneration_charge, 'cargo_exoneracion');
      this.assignValidNumberValue(presupuesto.authorization_charge, 'cargo_permiso');
      this.assignValidNumberValue(presupuesto.storage_charge, 'cargo_bodegaje');
      this.assignValidNumberValue(presupuesto.delivery_charge, 'cargo_encomienda');
      this.assignValidNumberValue(presupuesto.compra_corporativa, 'cargo_compra_corporativa');
      this.assignValidNumberValue(presupuesto.gestion_compra_corporativa, 'cargo_gestion_compra_corporativa');

      this.assignValidNumberValue(this.factura.otros_cargos_1, 'otros_cargos_1');
      this.assignValidNumberValue(this.factura.otros_cargos_2, 'otros_cargos_2');
      this.assignValidNumberValue(this.factura.otros_cargos_3, 'otros_cargos_3');

      this.assignValidNumberValue(presupuesto.services_total, 'servicios_sb');
      this.assignValidNumberValue(presupuesto.iva, 'iva');
      this.assignValidNumberValue(presupuesto.subtotal, 'subtotal');
      this.assignValidNumberValue(presupuesto.total, 'total');
      this.assignValidNumberValue(presupuesto.discount, 'descuento');
      this.assignValidNumberValue(presupuesto.promotional_discount, 'descuento_promocional');
      this.updateTotal();
    });

  }

  public updateTotal() {
    if (this.editable) {
      //console.log('UpdateTotal Values', this.form.value);

      this.form.value.tipo_de_cambio = this.factura.tipo_de_cambio.value;
      const values = this.facturasServices.recalculate(this.form.value);
      //console.log('After Recalculate Data', values);

      this.setValuesToForm(values);

      this.assignValidNumberValue(values.iva, 'iva');
      this.assignValidNumberValue(values.subtotal, 'subtotal');
      this.assignValidNumberValue(values.total, 'total');
      this.assignValidNumberValue(values.flete_internacional, 'flete_internacional');
      this.assignValidNumberValue(values.servicios_sb + values.otros_cargos_1 + values.otros_cargos_2 + values.otros_cargos_3, 'servicios_sb');

    }
  }

  public setValuesToForm(data) {
    //console.log('Set To Form', data);

    this.form.controls['cif'].setValue(data.cif);
    this.form.controls['cargo_transporte'].setValue(data.cargo_transporte);
    this.form.controls['cargo_fuel'].setValue(data.cargo_fuel);
    this.form.controls['cargo_seguro'].setValue(data.cargo_seguro);
    this.form.controls['cargo_manejo'].setValue(data.cargo_manejo);
    this.form.controls['porcentaje_hacienda'].setValue(data.porcentaje_hacienda);
    this.form.controls['cargo_impuestos'].setValue(data.cargo_impuestos);
    this.form.controls['cargo_confeccion_guia'].setValue(data.cargo_confeccion_guia);
    this.form.controls['cargo_confeccion_bl'].setValue(data.cargo_confeccion_bl);
    this.form.controls['cargo_consolidacion_paquetes'].setValue(data.cargo_consolidacion_paquetes);
    this.form.controls['cargo_exoneracion'].setValue(data.cargo_exoneracion);
    this.form.controls['cargo_permiso'].setValue(data.cargo_permiso);
    this.form.controls['cargo_bodegaje'].setValue(data.cargo_bodegaje);
    this.form.controls['cargo_encomienda'].setValue(data.cargo_encomienda || 0);
    this.form.controls['cargo_compra_corporativa'].setValue(data.cargo_compra_corporativa);
    this.form.controls['cargo_gestion_compra_corporativa'].setValue(data.cargo_gestion_compra_corporativa);

    this.form.controls['otros_cargos_1'].setValue(data.otros_cargos_1);
    this.form.controls['otros_cargos_2'].setValue(data.otros_cargos_2);
    this.form.controls['otros_cargos_3'].setValue(data.otros_cargos_3);

    this.form.controls['flete_internacional'].setValue(data.flete_internacional);
    this.form.controls['servicios_sb'].setValue(data.servicios_sb);
    this.form.controls['iva'].setValue(data.iva);
    this.form.controls['subtotal'].setValue(data.subtotal);
    this.form.controls['total'].setValue(data.total);
    this.form.controls['descuento'].setValue(data.descuento);
    this.form.controls['codigo_promocional'].setValue(data.codigo_promocional);
    this.form.controls['descuento_promocional'].setValue(data.descuento_promocional);
    this.form.controls['tipo_descuento'].setValue(data.tipo_descuento);
    this.form.controls['nota_credito'].setValue(data.nota_credito);
    this.form.controls['nota_debito'].setValue(data.nota_debito);
    this.form.controls['completada'].setValue(data.completada);
  }

  public getTaxes(value) {
    value < 0 ? value = 0 : '';
    value > 100 ? value = 100 : '';
    this.form.controls['porcentaje_hacienda'].setValue(value);
    this.form.controls['cargo_impuestos'].setValue(((value / 100) * this.form.value.cif).toFixed(2));
    this.updateTotal();
  }

  public setDefaultDiscount(value) {
    switch (value) {
      case 'Recomendacion':
        this.form.controls['descuento'].setValue(10);
        break;
      case 'Feedback':
        this.form.controls['descuento'].setValue(this.form.value.cargo_transporte * 0.3);
        break;
      default:
        this.form.controls['descuento'].setValue(0);
        break;
    }
    this.updateTotal();
  }

  public getErrorMessage(control) {
    if (control.hasError('required')) {
      return 'Campo necesario';
    }
    if (control.hasError('minlength')) {
      return 'Demasiado Corto';
    }
    if (control.hasError('min')) {
      return 'Límite mínimo';
    }
    return 'Error';
  }

  crearFactura(paqueteId) {
    return new Promise<void>((resolve, reject) => {
      const data = {
        paquete: paqueteId,
        completada: true,
        logged_user: this.authService.getUser().id,
      };

      this.facturasServices.crearFactura(data).subscribe((data: any) => {
        resolve();
      }, (error: any) => {
        //console.log(error);
        this.openDialog('Error al Generar Factura: ' + error.error);
        resolve();
      });
    });
  }

  public updateFactura() {
    return new Promise<void>((resolve, reject) => {

      if (this.form.valid) {
        this.spinnerService.show();
        this.form.value.usuario = this.factura.usuario.id;

        if (this.form.get('otros_cargos_1_descripcion').value == '') {
          this.form.get('otros_cargos_1_descripcion').setValue(null);
        }

        if (this.form.get('otros_cargos_2_descripcion').value == '') {
          this.form.get('otros_cargos_2_descripcion').setValue(null);
        }

        if (this.form.get('otros_cargos_3_descripcion').value == '') {
          this.form.get('otros_cargos_3_descripcion').setValue(null);
        }

        if (this.form.get('descuento_descripcion').value == '') {
          this.form.get('descuento_descripcion').setValue(null);
        } 

        this.updateTotal();

        const vals = this.form.value;
        if (vals.nota_debito) {
          vals['nota_debito'] = vals.nota_debito[0].id
        }
        if (vals.nota_credito) {
          vals['nota_credito'] = vals.nota_credito[0].id
        }
        for (const key of this.no_modificable_keys) {
          delete vals[`${key}`]
        }

        this.facturasServices.putFacturas(this.factura.id, vals).subscribe(res => {
          this.spinnerService.hide();
          if (this.origin === 'crear') {
            this.router.navigate(['/admin/paquetes/paquete'], { queryParams: { pack: this.paqueteId } });
          } else {
            if (this.cerrarModal) {
              this.dialogRef.close();
              this.openDialog('Factura Actualizada');
             
              if (this.form.value.manual == false) {
                 this.crearFactura(this.paquete.id).then(() => { });
              }
            } else {
              this.ngOnInit();
            }

            resolve();
          }

        }, err => {
          this.openDialog(err.error);
          this.spinnerService.hide();
          console.error(err);
          reject();
        });
      }
    });

  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });


    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  back() {
    this.navigation.back();
  }

  createNotaCredito() {

    this.cerrarModal = false;

    this.formNotaCredito.value.user = this.factura.usuario.id;
    this.formNotaCredito.value.factura = this.factura.id;
    this.formNotaCredito.value.created_by = this.authService.getUser().id;
    this.formNotaCredito.value.tipo = Number(this.tipoNotaCredito);
    this.formNotaCredito.value.aplicada = false;

    switch (Number(this.notaParcial)) {
      case 1:
        if (this.formNotaCredito.value.monto > this.transporteInternacional) {
          this.openDialog('Monto Excedido');
          return;
        }
        break;
      case 2:
        if (this.formNotaCredito.value.monto > this.servicioSuperbox) {
          this.openDialog('Monto Excedido');
          return;
        }
        break;
      case 3:
        if (this.formNotaCredito.value.monto > this.cargoImpuestos) {
          this.openDialog('Monto Excedido');
          return;
        }
        break;
      case 4:
        if (this.formNotaCredito.value.monto_crc > this.cargoEncomiendas) {
          this.openDialog('Monto Excedido');
          return;
        }
        break;
      case 4:
        if (this.formNotaCredito.value.monto > this.factura.total) {
          this.openDialog('Monto Excedido');
          return;
        }
        break;
      default:
        break;
    }

    if (this.formNotaCredito.value.tipo === 2) {

      if (this.factura.generado_gti) {
        // if (this.factura.pago.length > 0) {
        //   if (this.paquete.metodo_pago.id === 1) {
        this.facturasServices.postNotaCredito(this.formNotaCredito.value).subscribe((res: any) => {
          //console.log(res);
          this.spinnerService.hide();
          this.openDialog('Nota de credito Creada');
          this.notaCredito = res;
          this.form.get('nota_credito').setValue(res.id);
          this.showNotaCredito = true;
          this.editNotaCredito = true;

          // this.facturaElectronicaService.emitCreditNote(res.id, res.parcial).subscribe((data1: any) => {
          //   this.crearFactura(this.paquete.id).then(() => {
          //     //console.log('Factura Modificada ', data1);
          //     this.showNotaCredito = true;
          //     this.editNotaCredito = true;
          //   });
          // }, (error) => {
          //   //console.log(error);
          //   this.openDialog(error.error.Respuestas[0].Detalle);
          // });
          this.dialogRef.close();

        });

        const monto = parseFloat(this.formNotaCredito.value.monto) * this.factura.tipo_de_cambio.value;
        this.spinnerService.show();
        const payments = this.factura.pago;
        if (payments.length > 0) {
          const orderID = payments[0].referencia;
          this.pagosService.refundPago(orderID, monto.toFixed(2)).subscribe((data: any) => {
            data = JSON.parse(data);
            //console.log(data);
          });
        }


        //   } else {
        //     this.openDialog('Esta factura no fue pagada mediante pago en linea');
        //   }
        // } else {
        //   this.openDialog('Esta factura no fue pagada mediante pago en linea');
        // }

      } else {
        this.spinnerService.hide();
        this.openDialog('Factura no generada en GTI');
      }

    } else {
      if (this.factura.generado_gti) {
        this.facturasServices.postNotaCredito(this.formNotaCredito.value).subscribe((res: any) => {
          this.notaCredito = res;
          this.form.get('nota_credito').setValue(res.id);
          this.showNotaCredito = true;
          this.editNotaCredito = true;

          // this.facturaElectronicaService.emitCreditNote(res.id, res.parcial).subscribe((data1: any) => {
          //   this.crearFactura(this.paquete.id).then(() => {
          //     //console.log('Factura Modificada ', data1);
          //     if (this.formNotaCredito.value.tipo === 1) {
          //       this.openDialog('Nota Creada. Por favor proceda a realizar la transferencia por el monto de la nota de credito');
          //       this.showNotaCredito = true;
          //       this.editNotaCredito = true;
          //     }
          //   });

          this.dialogRef.close();

          // }, (error) => {
          //   //console.log(error);
          //   this.openDialog(error.error.Respuestas[0].Detalle);
          // });
        });

      } else {
        this.openDialog('Factura no generada en GTI');
      }
    }
  }

  emitCreditNote(notaCredito: any) {
    this.facturaElectronicaService.emitCreditNote(notaCredito.id, notaCredito.parcial).subscribe((data1: any) => {
      this.crearFactura(this.paquete.id).then(() => {
        //console.log('Factura Modificada ', data1);
        this.openDialog('Nota de crédito emitida. Por favor proceda a realizar la transferencia por el monto de la nota de credito');
        this.showNotaCredito = true;
        this.editNotaCredito = true;
        this.dialogRef.close();
        // this.cerrarModal = true;
      });

      this.updateTotal();

    }, (error) => {
      //console.log(error);
      this.openDialog(error.error.Respuestas[0].Detalle);
    });
  }

  emitDebitNote(notaDebito: any) {
    this.facturaElectronicaService.emitDebitNote(notaDebito.id).subscribe((data1: any) => {
      this.crearFactura(this.paquete.id).then(() => {
        //console.log('Factura Modificada Nota de Debito', data1);
        this.openDialog('Nota de Débito Emitida');
        this.showNotaDebito = true;
        this.editNotaDebito = true;
        this.dialogRef.close();
        // this.cerrarModal = true;
      });

      this.updateTotal();
    }, (error) => {
      //console.log(error);
      this.openDialog(error.error.Respuestas[0].Detalle);
    });

  }

  createNotaDebito() {
    this.cerrarModal = false;

    //console.log('Form Debito', this.formNotaDebito.value);

    this.formNotaDebito.value.user = this.factura.usuario.id;
    this.formNotaDebito.value.factura = this.factura.id;
    this.formNotaDebito.value.created_by = this.authService.getUser().id;

    //console.log('Form Debito', this.formNotaDebito.value);

    this.facturasServices.postNotaDebito(this.formNotaDebito.value).subscribe((res: any) => {
      //console.log(res);
      this.spinnerService.hide();
      this.showNotaDebito = true;
      this.editNotaDebito = true;
      this.openDialog('Nota de Débito Creada');
      this.notaDebito = res;
      this.form.get('nota_debito').setValue(res.id);

      this.dialogRef.close();

    });

  }

  public onNoClick = (): void => {
    this.dialogRef.close();
  };

  checkPermisos(value, descripcion = '') {
    if (value) {
      this.form.get('cargo_permiso').setValue(value);
      this.updateTotal();
    } else {
      this.form.get('cargo_permiso').setValue(null);
    }
  }

  checkOtrosCargos(value, control, descripcion = '') {
    //console.log('check', value, control, descripcion);
    if (value) {
      this.form.get(control).setValue(value);
      this.form.get(control + '_descripcion').setValue(descripcion);
      this.updateTotal();
    } else {
      this.form.get(control).setValue(null);
      this.form.get(control + '_descripcion').setValue(null);
      this.updateTotal();
    }
  }

  setIvaAmount() {
    const partial = this.formNotaCredito.controls.parcial.value || 0;
    if (+partial !== 1 && +partial !== 5 && +partial !== 3) {
      const montoCrcValue = Number(this.formNotaCredito.controls.monto_crc.value);
      const ivaCrcValue = Number((montoCrcValue * 0.13).toFixed(2));
      console.log('ivaCrcValue', ivaCrcValue);
      this.formNotaCredito.controls.iva_crc.setValue(ivaCrcValue);
    } else {
      this.formNotaCredito.controls.iva_crc.setValue(0);
    }
  }

  convertCurrency(from, type) {
    //console.log(this.form.value.tipo_descuento, this.form.controls.descuento.value);

    if (type === 'Descuento') {
      if (from === 'USD') {
        if (this.form.value.tipo_descuento == 'Normal' || this.form.value.tipo_descuento == 'Feedback') {
          this.descuentoCRC = Number((this.form.controls.descuento.value * this.factura.tipo_de_cambio.value).toFixed(2));
        } else {
          this.descuentoCRC = Number((this.form.controls.descuento.value));
        }
      }

      if (from === 'CRC') {
        this.form.controls.descuento.setValue(Number(this.descuentoCRC / this.factura.tipo_de_cambio.value).toFixed(2));
        this.updateTotal();
      }
    }

    if (type === 'Nota') {
      if (from === 'USD') {
        this.formNotaCredito.controls.monto_crc.setValue(Number(this.formNotaCredito.controls.monto.value * this.factura.tipo_de_cambio.value).toFixed(2));
      }

      if (from === 'CRC') {
        this.formNotaCredito.controls.monto.setValue(Number(this.formNotaCredito.controls.monto_crc.value / this.factura.tipo_de_cambio.value).toFixed(2));
      }
      this.setIvaAmount();
    }

    if (type === 'NotaDebito') {
      if (from === 'USD') {
        this.formNotaDebito.controls.monto_crc.setValue(Number(this.formNotaDebito.controls.monto.value * this.factura.tipo_de_cambio.value).toFixed(2));
      }

      if (from === 'CRC') {
        this.formNotaDebito.controls.monto.setValue(Number(this.formNotaDebito.controls.monto_crc.value / this.factura.tipo_de_cambio.value).toFixed(2));
      }
    }

    /*  if (type === 'Credito') {
       if (from === 'USD') {
         this.notaCreditoCRC = Number((this.form.controls.nota_credito_monto.value * this.factura.tipo_de_cambio.value).toFixed(2));
       }

       if (from === 'CRC') {
         this.form.controls.nota_credito_monto.setValue(Number(this.notaCreditoCRC / this.factura.tipo_de_cambio.value).toFixed(2));
       }
     } */

  }

  public checkTipoNota(event) {
    this.tipoNotaCredito = event.value;

    if (this.tipoNotaCredito == '1') {
      this.formNotaCredito.patchValue({
        monto_crc: this.factura.factura_crc.subtotal,
        monto: this.factura.total,
        parcial: 0
      });
      this.notaParcial = '0';
      if (this.factura.factura_crc.iva > 0) {
        this.formNotaCredito.patchValue({
          iva_crc: this.factura.factura_crc.iva
        });
      }
    }

    if (this.tipoNotaCredito == '2') {
      this.formNotaCredito.patchValue({
        monto: null
      });
      this.convertCurrency('USD', 'Nota');
      this.notaParcial = '0';
    }
  }

  public checkNotaParcial(event) {
    console.log('Nota Parcial', event.value);
    this.notaParcial = event.value;

    if (this.notaParcial == '1') {
      const flete = this.factura.flete_internacional;
      this.formNotaCredito.patchValue({
        monto: flete,
        parcial: 1,
      });
      this.convertCurrency('USD', 'Nota');
    }

    if (this.notaParcial == '2') {
      this.formNotaCredito.patchValue({
        monto: this.factura.servicios_sb,
        parcial: 2
      });
      this.convertCurrency('USD', 'Nota');
      this.setIvaAmount();
    }

    if (this.notaParcial == '3') {
      this.formNotaCredito.patchValue({
        monto: this.factura.cargo_impuestos,
        parcial: 3
      });
      this.convertCurrency('USD', 'Nota');
    }

    if (this.notaParcial == '4') {
      this.formNotaCredito.patchValue({
        monto_crc: this.factura.cargo_encomienda,
        monto: (this.factura.cargo_encomienda / this.factura.tipo_de_cambio.value).toFixed(2),
        parcial: 4
      });
      this.setIvaAmount();
    }

    if (this.notaParcial == '5') {
      this.formNotaCredito.patchValue({
        monto_crc: 0,
        monto: 0,
        parcial: 5
      });
      this.setIvaAmount();
    }

    if (this.notaParcial == '6') {
      this.formNotaCredito.patchValue({
        monto_crc: 0,
        monto: 0,
        parcial: 6
      });
      this.setIvaAmount();
    }

  }

  public deleteNotaCredito() {
    this.spinnerService.show();
    this.facturasServices.deleteNotaCredito(this.notaCredito.id).subscribe((res: any) => {
      this.spinnerService.hide();
      this.openDialog('Nota de Crédito eliminada');
      this.dialogRef.close();
    });
  }

  public deleteNotaDebito(notaDebito: any) {
    this.spinnerService.show();
    if (!notaDebito.generado_gti) {
      this.facturasServices.deleteNotaDebito(notaDebito.id).subscribe((res: any) => {
        this.spinnerService.hide();
        this.openDialog('Nota de Débito eliminada');
        this.dialogRef.close();
      });
    }
  }

  public checkAsManual() {
    this.form.controls.manual.setValue(true);
    // console.log(this.form.value);
    this.updateTotal();
  }

  private assignValidNumberValue(value, control) {
    //console.log(value, control);
    if ((value != null) && !(typeof value === 'string' || value instanceof String)) {
      this.form.controls[control].setValue(value.toFixed(2));
    } else {
      this.form.controls[control].setValue(0);
    }
  }


}
