<h2 style="margin-top: 0;margin-bottom: 0;" class="h2-title p-4">Cuentas</h2>

<div class="content" fxLayout="column">
  <form [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutGap="20px" class="pr-4 pl-4 pt-3 mt-2 inputs-form"
      style="display: flex; align-items: center;">
      <div>
        <label class="label">Nombre Completo</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput (keyup)="doFilter($event, $event.target.value,'nombre')" placeholder="Nombre Completo"
            formControlName="name" class="input-field">
        </mat-form-field>
      </div>

      <div>
        <label class="label">Número de Cédula</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Número de Cédula"
            (keyup)="doFilter($event, $event.target.value,'numero_de_cedula')" formControlName="ci" class="input-field">
        </mat-form-field>
      </div>

      <div>
        <label class="label">Correo</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Correo" (keyup)="doFilter($event, $event.target.value,'email')"
            formControlName="email" class="input-field">
        </mat-form-field>
      </div>

      <div>
        <label class="label">Número de Cuenta</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Cuenta" (keyup)="doFilter($event, $event.target.value,'cuenta')"
            formControlName="account" class="input-field">
          <span matPrefix class="span-cuenta-prefix">SB&nbsp;</span>
        </mat-form-field>
      </div>

      <!--tipo de cuenta-->
      <div>
        <label class="label">Tipo de Cuenta</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <mat-select (selectionChange)="selectTipoCuenta($event)" matInput placeholder="Tipo de Cuenta"
            formControName="accountType">
            <mat-option *ngFor="let tipo of tipoCuentas" value="{{tipo.value}}">{{tipo.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!---->

      <!--Número de Teléfono-->
      <div *ngIf="authService.isServicioCliente">
        <label class="label">Número de Teléfono</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Número de Teléfono" formControlName="phone" class="input-field">
        </mat-form-field>
      </div>
      <!---->

      <!--tipo de cliente-->
      <div *ngIf="!authService.isServicioCliente">
        <label class="label">Tipo de Cliente</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <mat-select (selectionChange)="select($event)" matInput placeholder="Tipo de Cliente"
            formControlName="clientType">
            <mat-option *ngFor="let tipo of tipoClientes" value="{{tipo.id}}">{{tipo.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!---->

      <div>
        <label class="label">Desde</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Desde e Incluido" formControlName="date_from" type="date" class="input-field">
        </mat-form-field>
      </div>
      <div>
        <label class="label">Hasta</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Hasta e Incluido" formControlName="date_until" type="date" class="input-field">
        </mat-form-field>
      </div>
    </div>

  </form>
  <mat-divider></mat-divider>

  <div class="botones">
    <div class="px-4 text-right" style="float: right; ">
      <button mat-button (click)="filterTable()">
        <img src="assets/iconos-dashboard/Lupa.png">Buscar
      </button>

      <button mat-button (click)="cleanFilters()">
        <img src="assets/iconos-dashboard/Facturas.png">Limpiar
      </button>
    </div>

  </div>

  <mat-divider></mat-divider>
  <ng-container>
    <div class="content-table m-4" id="users-table">
      <table mat-table
        [dataSource]="dataSource.data  | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length}"
        matSort (matSortChange)="sortData($event)">

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Nombre </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.name}} </td>
        </ng-container>


        <!-- tipo_cliente Column -->
        <ng-container matColumnDef="tipo_cliente">
          <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Tipo de Cliente </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
            <span *ngFor="let tipo of element.tipo_cliente" class="m-2 p-2 d-block"
              style="border-radius: 15px; font-size:16px !important; color: white !important;"
              [style.background-color]="tipo.color">{{
              tipo.nombre | translate}}</span>
          </td>
        </ng-container>

        <!-- Account Column -->
        <ng-container matColumnDef="account">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cuenta </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element">
            <a [routerLink]="['/admin/perfil']"
              [queryParams]="{cuenta: element.id}">{{element.account}}-{{element.name}} </a>
          </td>
        </ng-container>

        <!-- ci Column -->
        <ng-container matColumnDef="ci">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cédula </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.ci}} </td>
        </ng-container>

        <!-- email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Correo </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <!-- phone Column -->
        <ng-container matColumnDef="phone">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Teléfono </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.phone}} </td>
        </ng-container>

        <!-- date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha de Creación </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.date.toLocaleDateString('es-CR', { weekday:
            'short', year: 'numeric', month: 'short', day: 'numeric' })}} </td>
        </ng-container>

        <!-- delete Name Column -->
        <ng-container matColumnDef="delete_by">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Eliminada por </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{ element.delete_by ?
            usuariosService.get_fullname(element.delete_by) : '-'}} </td>
        </ng-container>

        <!-- delete date Column -->
        <ng-container matColumnDef="delete_date">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha de Eliminación </th>
          <td mat-cell class="pr-pl-20" *matCellDef="let element">
            <span *ngIf="element.delete">{{ element.delete_date | date:'short'}}</span>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true"
          [ngClass]="{'highlight-top-header': true }"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }">
        </tr>
      </table>
    </div>

    <div class="paginator text-center">
      <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
        (pageBoundsCorrection)="pageIndex = $event; paginate(pageIndex)"
        (pageChange)="pageIndex = $event; paginate(pageIndex)"></pagination-controls>
    </div>
  </ng-container>
  <mat-divider></mat-divider>

  <ng-container *ngIf="advanced"></ng-container>


</div>
