<h2 class="h2-title p-4">Notas de Crédito</h2>

<div class="content" fxLayout="column">
  <div fxLayout="row wrap" fxLayoutGap="20px" class="pl-4 pr-4 pt-3 pb-2 mt-2 inputs-form"
    style="display: flex; align-items: center;">
    <form [formGroup]="form" style="width: 100%;">
      <div class="row">

        <div class="col">
          <label class="label">Fecha de Creación</label><br>
          <mat-form-field appearance="outline" class="form-field">
            <input formControlName="date_from" matInput placeholder="Fecha de Creación" type="date" class="input-field">
          </mat-form-field>
        </div>

        <div class="col">
          <label class="label">Hasta e Incluido</label><br>
          <mat-form-field appearance="outline" class="form-field">
            <input formControlName="date_until" matInput placeholder="Hasta e Incluido" form type="date"
              class="input-field">
          </mat-form-field>
        </div>

        <div class="col">
          <button class="pt-4" mat-button (click)="applyFilters()">
            <img src="assets/iconos-dashboard/Lupa.png">Buscar
          </button>

          <button class="pt-4" mat-button (click)="cleanFilters()">
            <img src="assets/iconos-dashboard/Facturas.png">Limpiar
          </button>
        </div>


      </div>

    </form>
  </div>

  <mat-divider></mat-divider>

  <div class="content-table m-4">
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="id">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Nro. </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="factura">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Factura </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element">
          <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: element.paquete[0].id, factura: true}">
            {{element.factura.numero}}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="fecha">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Fecha </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.created_at | date: 'dd-MM-yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="cuenta">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Cuenta </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{usuariosService.get_fullaccount(element.user)}} </td>
      </ng-container>

      <ng-container matColumnDef="descripcion">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Descripción </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.descripcion}} </td>
      </ng-container>

      <ng-container matColumnDef="tipo">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Tipo </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.tipo}} </td>
      </ng-container>

      <ng-container matColumnDef="monto">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Monto (TOTAL) </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.monto | currency}} </td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Cuenta </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.user}} </td>
      </ng-container>

      <ng-container matColumnDef="creador">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Creador </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{usuariosService.get_fullaccount(element.created_by)}}
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }"></tr>
    </table>
  </div>

  <div class="paginator text-center">
    <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
      (pageChange)="pageIndex = $event; paginate(pageIndex)"></pagination-controls>
  </div>



</div>
