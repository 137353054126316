/* eslint-disable object-shorthand */
/* eslint-disable no-underscore-dangle */
/* eslint-disable eqeqeq */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable brace-style */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable max-len */
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { EntregasService } from '../../../../../../services/entregas.service';
import { PaquetesService } from '../../../../../../services/paquetes.service';
import { UsuariosService } from '../../../../../../services/usuarios.service';

import { DatePipe } from '@angular/common';
import { AuthService } from '../../../../../../services/auth.service';
import { Observable } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { elementAt, map, startWith } from 'rxjs/operators';
import { GeneralDialogComponent } from '../../../../../../modules/common/dialogs/general-dialog/general-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdjuntarPagoComponent } from '../../../../../common/dialogs/adjuntar-pago/adjuntar-pago.component';
import { PagosService } from '../../../../../../services/pagos.service';
import { FacturasService } from '../../../../../../services/facturas.service';
import { GeneralInputComponent } from 'src/app/modules/common/dialogs/general-input/general-input.component';

export interface PackageElement {
  id: number;
  cuenta: string;
  guia: string;
  cliente: string;
  tipo_paquete: string;
  metodo_pago: any;
  usuario: string;
  tracking: string;
  descripcion: string;
  total_factura: number;
  total_colones: number;
  plan: any;
  factura: any;
}

export interface ClienteElement {
  id: number;
  name: string;
}

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexSucursalesComponent implements OnInit {

  selectedDate = new Date();

  displayedColumns: string[] = ['select', 'cuenta', 'guia', 'cliente', 'tipo_paquete', 'total_dolares', 'total_colones'];
  dataSource = new MatTableDataSource<PackageElement>();
  selection = new SelectionModel<PackageElement>(true, []);

  paquetes = [];

  length: number;
  pageSize = 20;
  pageIndex = 1;

  clienteForm: any;
  cliente: ClienteElement;
  clienteOptions: ClienteElement[];
  filteredOptions: Observable<ClienteElement[]>;

  totalFactura = 0;
  totalColones = 0;
  user: any;

  timeout: any = null;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private packagesService: PaquetesService,
    private usersService: UsuariosService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private entregasService: EntregasService,
    private facturasService: FacturasService,
    private pagosService: PagosService,
  ) {
  }

  ngOnInit(): void {
    this.clienteForm = this.fb.group({
      usuario: ['', [Validators.required]],
      fecha: [this.selectedDate, [Validators.required]],
      sucursal: [''],
    });
  }

  getPackagesSucursal() {
    this.spinnerService.show();
    this.paquetes = [];
    this.selection.clear();

    const iduser = this.clienteForm.value.usuario.id;
    const idSucursal = this.clienteForm.value.sucursal === 'Guadalupe' ? 15 : this.clienteForm.value.sucursal === 'Limón' ? 14 : 0;

    this.packagesService.getByStatusUsuario(iduser, idSucursal, this.pageIndex).subscribe((res: any) => {

      const paquetes_lista: PackageElement[] = [];
      //console.log('Response', res);

      res.results.forEach(element => {
        const row = {
          id: element.id,
          guia: element.numero_de_guia,
          cliente: this.usersService.get_fullname(element.usuario),
          cuenta: this.usersService.get_account_number(element.usuario),
          tipo_paquete: element.tipo_de_paquete,
          metodo_pago: element.usuario.metodo_pago ? element.usuario.metodo_pago : '-',
          usuario: element.usuario,
          tracking: element.tracking,
          descripcion: element.descripcion,
          total_factura: element.factura != null ? element.factura.total : 0,
          total_colones: element.factura != null ? element.factura.total * element.factura.tipo_de_cambio.value : 0,
          plan: element.plan,
          factura: element.factura
        };

        paquetes_lista.push(row);

      });

      this.paquetes = paquetes_lista;
      this.dataSource.data = this.paquetes;
      this.length = this.dataSource.data.length;

      this.spinnerService.hide();
    }
    );
  }

  public isAllSelected = (): boolean => {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  };

  public masterToggle = (): void => {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    this.calcFactura();
  };

  public checkboxLabel = (row?: PackageElement): string => {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    this.calcFactura();
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  };

  displayFn(value) {
    if (!value) { return ''; }
    return value.name;
  }

  buscarListaClientes(event = { keyCode: 13 }, cuenta = null) {

    clearTimeout(this.timeout);
    let $this = this;
    this.timeout = setTimeout(function() {
      if (event.keyCode != 13) {
        $this.usersService.search(cuenta).subscribe((res: any) => {
          //  //console.log('r',r)
          const datos: ClienteElement[] = [];
          res.forEach(element => {
            // eslint-disable-next-line max-len
            datos.push({ id: element.id, name: $this.usersService.get_account_number(element) + '-' + $this.usersService.get_fullname(element) });
          });
          $this.clienteOptions = datos;

          $this.filteredOptions = $this.clienteForm.controls.usuario.valueChanges.pipe(
            startWith(''),
            map(user => $this._filter(user, $this.clienteOptions)),
          );

          //console.log($this.clienteForm.value.usuario);
        }
        );
      }
    }, 1000);
  }

  public getErrorMessage(control) {

    if (control.hasError('required')) {
      return 'Campo necesario';
    }
    if (control.hasError('minlength')) {
      return 'Demasiado Corto';
    }
    if (control.hasError('maxlength')) {
      return 'Demasiado Largo';
    }
    if (control.hasError('min')) {
      return 'Límite mínimo';
    }
    if (control.hasError('email')) {
      return 'No es un correo valido';
    }
    if (control.hasError('invalidCode')) {
      return 'Código Invalido';
    }
    return 'Error';
  }

  public calcFactura() {
    this.totalFactura = 0;
    this.totalColones = 0;
    this.selection.selected.forEach(element => {
      this.totalFactura = this.totalFactura + element.total_factura;
      this.totalColones = this.totalColones + element.total_colones;
    });
  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }

  pagarPaquetes() {
    if (this.selection.selected.length === 0) {
      this.openDialog('No ha seleccionado paquetes para entregar');
      return;
    }

    const dialogRef = this.dialog.open(AdjuntarPagoComponent, {
      data: { title: 'Adjuntar Pago', origin: 'admin', monto_usd: this.totalFactura, monto_crc: this.totalColones, tipo_cambio: 0 },
      height: '350px',
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      //console.log(data);
      if (data != null) {
        this.procesarPago(data.metodo_pago, data.referencia, this.totalFactura, this.totalColones, this.clienteForm.value.usuario.id, this.authService.getUser().id);
      }
    });

  }

  procesarPago(metodo_pago, referencia, monto, monto_crc, usuario, created_by) {
    this.spinnerService.show();

    const d1 = {
      metodo_pago,
      monto,
      monto_crc,
      referencia,
      usuario,
      created_by
    };

    //console.log('data before add1', d1);

    this.pagosService.procesarPago(d1).subscribe((pago: any) => {
      if (this.selection.selected.length > 0) {
        this.selection.selected.forEach(element => {
          const idPaquete = element.id;
          const idFactura = element.factura.id;

          if (pago) {

            const d2 = {
              fecha_entrega: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
              hora_entrega: this.datePipe.transform(new Date(), 'yyyy-MM-dd h:mm:ss'),
              metodo_pago,
              metodo_pago2: metodo_pago,
              estado_pago: 1,
              logged_user: this.authService.getUser().id,
              entregado: 1,
              estado_actual: 18
            };
            //console.log('data before add2', d2);
            const completada = true;
            const bloqueada = true;

            // eslint-disable-next-line max-len
            this.facturasService.putFacturas(idFactura, { pago: pago.id, usuario, completada, bloqueada }).subscribe((data: any) => {
              //console.log('Pago asignado a factura', data);

              this.packagesService.put(idPaquete, d2).subscribe((res: any) => {
                //console.log('Pago Realizado', res);
                this.openDialog('Pago Aplicado');
                this.spinnerService.hide();
                this.getPackagesSucursal();
              }, err => {

                console.error('err', err);
                this.openDialog(err.error);
              });
            }, err => {
              console.error('err', err);
              this.openDialog(err.error);
              this.spinnerService.hide();
              console.error(err);
            });
          }
        });
      }
    });
  }

  paginate(event: any) {
    //console.log(event);
    this.pageIndex = event;
    this.getPackagesSucursal();
  }

  private _filter(value: any, options: any): ClienteElement[] {
    if (!value) { return options; }
    let filterValue: string;
    value.name ?
      filterValue = value.name.toLowerCase() :
      filterValue = value.toLowerCase();
    return options.filter(user => user.name.toLowerCase().includes(filterValue));
  }

}
