<h2 class="h2-title p-4">Compras Corporativas</h2>

<div class="content" fxLayout="column">
  <div fxLayout="row wrap" fxLayoutGap="20px" class="pl-4 pr-4 pt-3 pb-2 mt-2 inputs-form"
    style="display: flex; align-items: center;">
    <form [formGroup]="form" style="width: 100%;">
      <div class="row">
        <div class="col">
          <label class="label">Fecha de Creación (De)</label><br>
          <mat-form-field appearance="outline" class="form-field">
            <input  formControlName="date_from" matInput type="date" class="input-field">
          </mat-form-field>
        </div>
        <div class="col">
          <label class="label">Fecha de Creación (Hasta)</label><br>
          <mat-form-field appearance="outline" class="form-field">
            <input formControlName="date_until" matInput type="date" class="input-field">
          </mat-form-field>
        </div>

        <div class="col">
          <button class="pt-4" mat-button (click)="applyFilters()">
            <img src="assets/iconos-dashboard/Lupa.png">Buscar
          </button>

          <button class="pt-4" mat-button (click)="cleanFilters()">
            <img src="assets/iconos-dashboard/Facturas.png">Limpiar
          </button>
        </div>

        <div class="col">

        </div>

        <div class="col">
          <button class="pt-4" mat-button [routerLink]="['agregar']">
            <img src="assets/iconos-dashboard/Facturas.png">Agregar Compra Corporativa
          </button>
        </div>
      </div>

    </form>

  </div>

  <mat-divider></mat-divider>

  <div class="content-table m-4">
    <table mat-table  [dataSource]="dataSource | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length}" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Nro. </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          <a [routerLink]="['agregar']" [queryParams]="{id: element.id}">{{element.id}} </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="fecha">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Fecha de compra </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element"> {{element.fecha_compra | date:
          'dd-MM-yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="tipo">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Tipo de compra </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element"> {{element.tipo_compra ?
          getTipoName(element.tipo_compra) : ''}} </td>
      </ng-container>

      <ng-container matColumnDef="descripcion">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Descripción </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          {{element.descripcion}}
        </td>
      </ng-container>

      <ng-container matColumnDef="remitente">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Remitente </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          {{element.remitente}}
        </td>
      </ng-container>

      <ng-container matColumnDef="colones">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Total en colones </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.colones}} </td>
      </ng-container>

      <ng-container matColumnDef="dolares">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Total en dólares </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.dolares}} </td>
      </ng-container>

      <ng-container matColumnDef="metodopago">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Método de pago </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.metodopago}} </td>
      </ng-container>

      <ng-container matColumnDef="pago1">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Pago 1</th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          <mat-checkbox [checked]="element.totalPagado >= element.cuota_1" disabled></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="pago2">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Pago 2 </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          <mat-checkbox [checked]="element.totalPagado >= element.totalFactura" disabled></mat-checkbox>
        </td>
      </ng-container>

      <!--  <ng-container matColumnDef="adjunto">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Adjunto </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          <span *ngIf="element.adjunto !== ''" class="content-ext" matTooltip="ver {{element.adjunto | slice:0:25}}"
            matTooltipPosition="before">
            <p class="ext">{{typeArchivo(element.adjunto)}}</p>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="adjunto2">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Adjunto 2 </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          <span *ngIf="element.adjunto2 !== ''" class="content-ext" matTooltip="ver {{element.adjunto2 | slice:0:25}}"
            matTooltipPosition="after">
            <p class="ext">{{typeArchivo(element.adjunto2)}}</p>
          </span>
        </td>
      </ng-container> -->

      <ng-container matColumnDef="cuenta">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Cuenta </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{usuariosService.get_fullaccount(element.cuenta)}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }"></tr>
    </table>
  </div>
  <div class="paginator text-center">
    <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
      (pageChange)="pageIndex = $event; paginate(pageIndex)"></pagination-controls>
  </div>
</div>
