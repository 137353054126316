<div class="p-3 content-ac" fxLayout="column">
  <div style="padding-top: 0 ;"class="content-title">
    <p class="title">Acción</p>
  </div>
  <form [formGroup]="form">
    <div fxLayout="row wrap" class="pt-3" style="margin-top: 35px;">
    
      <!--add-->
      <div class="row" style="width: 100%;" *ngIf="!editionMode">
        <div class="col-sm botones-opciones">
          <button mat-button [routerLink]="['/admin/administracion/acciones']"
          style="padding: 0; padding-right: 10px; color: #0094a3; float: left;">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        </div>
        <div class="col-sm text-right botones-opciones">
          <button mat-button submit (click)="addAction()">
            <mat-icon>add_circle</mat-icon>Agregar
          </button>
        </div>
      
      </div>
      <!--edit mode-->
      <div class="row" style="width: 100%;" *ngIf="editionMode">
        <div class="col-sm botones-opciones">
          <button mat-button [routerLink]="['/admin/administracion/acciones']"
          style="padding: 0; padding-right: 10px; color: #0094a3; float: left;">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        </div>
        <div class="col-sm text-right botones-opciones">
          <button mat-button submit (click)="updateAction()">
            <mat-icon>save</mat-icon>Guardar
          </button>
        </div>
      
      </div>
      <!---->
     
    </div>
    <mat-divider></mat-divider>
      <div fxLayout="column" class="pt-3" style="padding-left: 15%; padding-right: 15%;">
        <mat-form-field appearance="outline">
          <mat-label>Acción</mat-label>
          <input matInput placeholder="Acción" aria-label="Acción" formControlName="estado" >
          <mat-error *ngIf="form.controls['estado']">
            {{getErrorMessage(form.controls['estado'])}}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Secuencia</mat-label>
          <input matInput placeholder="Secuencia" aria-label="Secuencia" formControlName="progreso" type="number" min="0" max="100">
          <mat-error *ngIf="form.controls['progreso'].errors">
            {{getErrorMessage(form.controls['progreso'])}}
          </mat-error>
        </mat-form-field>
        <div fxLayout="row wrap" fxLayoutGap="10px" class="mb-3" style="outline: none;">
          <mat-checkbox [disabled]="true" [checked]="true" appearance="outline" style="width: 250px;">Administrador</mat-checkbox>
          <mat-checkbox formControlName="check_transportista" appearance="outline" style="width: 250px;">Transportista</mat-checkbox>
          <mat-checkbox [disabled]="true" appearance="outline" style="width: 250px;">Sucursal</mat-checkbox>
          <mat-checkbox [disabled]="true" appearance="outline" style="width: 250px;">Contabilidad</mat-checkbox>
          <mat-checkbox [disabled]="true" appearance="outline" style="width: 250px;">Miami</mat-checkbox>
          <mat-checkbox [disabled]="true" appearance="outline" style="width: 250px;">Facturación</mat-checkbox>
          <mat-checkbox [disabled]="true" appearance="outline" style="width: 250px;">Almacén</mat-checkbox>
          <mat-checkbox [disabled]="true" appearance="outline" style="width: 250px;">Operaciones</mat-checkbox>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="10px" class="mb-3" style="outline: none;">
          <mat-checkbox formControlName="visualizacion" appearance="outline" style="width: 250px;">Visible para cliente</mat-checkbox>
        </div>
      </div>
      
      <!--   <mat-tab-group class="mt-3">
    <mat-tab label="Paquetes Con Este Estado (0)">
      <div fxLayout="row wrap" class="pt-3">
			  <button mat-button>Añadir Paquete</button>
		  </div>
    </mat-tab> 
  </mat-tab-group> -->
</form>
</div>

