/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../../../environments/environment';
import { PagosService } from '../../../../../services/pagos.service';
import { UsuariosService } from '../../../../../services/usuarios.service';
import { AuthService } from '../../../../../services/auth.service';
import { PaquetesService } from '../../../../../services/paquetes.service';
import { FacturasService } from '../../../../../services/facturas.service';
import { ConfirmarComponent } from '../../../../common/dialogs/confirmar/confirmar.component';
import { GeneralDialogComponent } from '../../../../common/dialogs/general-dialog/general-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { GeneralInputComponent } from 'src/app/modules/common/dialogs/general-input/general-input.component';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-aprobar-pagos',
  templateUrl: './aprobar-pagos.component.html',
  styleUrls: ['./aprobar-pagos.component.scss']
})
export class AprobarPagosComponent implements OnInit {
  mediaUrl = environment.mediaUrl;
  pendientes;
  displayedColumns: string[] = ['cuenta', 'guias', 'fecha', 'referencia', 'monto_usd', 'monto_crc', 'comprobante', 'creador', 'opciones'];

  constructor(
    private pagosService: PagosService,
    public usuariosService: UsuariosService,
    private dialog: MatDialog,
    private authService: AuthService,
    private paquetesService: PaquetesService,
    private spinnerService: NgxSpinnerService,
    private facturasService: FacturasService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.getPagosPendientes();
  }

  getPagosPendientes() {
    this.pendientes = [];
    this.pagosService.getComprobantePendientes().subscribe((data: any) => {
      const comprobantes: any[] = [];



      data.forEach(element => {
        const cuenta = element.cuenta.split('}')[0] + '}';
        const d = new Date(element.created_at.split(',')[0]);
        d.setMinutes(d.getMinutes() - d.getTimezoneOffset());

        comprobantes.push(
          {
            cuenta: JSON.parse(cuenta),
            id: element.comprobante_id.split(','),
            guias: element.guias.split(','),
            paquete: element.paquete.split(','),
            factura: element.factura.split(','),
            comprobante: element.comprobante.split(',')[0],
            referencia: element.referencia,
            monto: element.monto,
            monto_crc: element.monto_crc,
            monto_detalles: element.monto_detalles.split(','),
            monto_crc_detalles: element.monto_crc_detalles.split(','),
            creador: JSON.parse(element.creador),
            created_at: d.toString(),
            nota_debito: Boolean(Number(element.nota_debito.split(',')[0]))
          }
        );
      });
      this.pendientes = comprobantes;
    });
  }

  gotoFile(url) {
    window.open(this.mediaUrl + url, '_blank');
    event.preventDefault();
  }

  aprobarPago(comprobante) {
    //console.log(comprobante);

    if (comprobante.nota_debito === false) {
      const dialogRef = this.dialog.open(ConfirmarComponent, {
        // eslint-disable-next-line max-len
        data: { message: '¿Confirma que desea aprobar este pago?' },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const bar = new Promise((resolve, reject) => {
            comprobante.id.forEach((id, index) => {
              const value = {
                aprobado: true,
                aprobado_by: this.authService.getUser().id,
                usuario: comprobante.cuenta.id,
                referencia: comprobante.referencia
              };

              this.paquetesService.getById(comprobante.paquete[index]).subscribe((res: any) => {

                this.pagosService.putComprobante(value, Number(id)).subscribe(async (data: any) => {
                  if (res.clase != 'D') {
                    await this.procesarPago(8, comprobante.referencia, Number(comprobante.monto_detalles[index]), Number(comprobante.monto_crc_detalles[index]), comprobante.cuenta.id, this.authService.getUser().id, comprobante.paquete[index], comprobante.factura[index]);
                    if (res.orden === false) {
                      resolve('');
                    } else {
                      this.pagosService.transferPayment({guide: res.numero_de_guia}).subscribe(() => {
                        resolve('');
                      });
                    }
                  } else {
                    const data = {
                      estado_pago: 1,
                      logged_user: this.authService.getUser().id,
                    };

                    this.paquetesService.put(res.id, data).subscribe((res: any) => {
                      resolve('');
                    });
                  }
                }, (error) => {
                  this.openDialog(error.error);
                });

              });
            });
          });

          bar.then(() => {
            //console.log('resolve')
            //console.log('Pago Aprobado', comprobante);
            this.openDialog('Pago Aprobado');
            this.getPagosPendientes();
          });
        }
      });
    }

    if (comprobante.nota_debito === true) {
      const dialogRef = this.dialog.open(ConfirmarComponent, {
        data: { message: '¿Confirma que desea aprobar este pago?' },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const bar = new Promise((resolve, reject) => {
            comprobante.id.forEach((id, index) => {

              const value = {
                aprobado: true,
                aprobado_by: this.authService.getUser().id,
                usuario: comprobante.cuenta.id,
                referencia: comprobante.referencia
              };

              this.pagosService.putComprobante(value, Number(id)).subscribe((res: any) => {
                //console.log('Pago Aprobado', comprobante);

                const data = {
                  metodo_pago: 2,
                  monto: comprobante.monto_detalles[index],
                  monto_crc: comprobante.monto_crc_detalles[index],
                  usuario: comprobante.cuenta.id,
                  created_by: this.authService.getUser().id,
                  referencia: comprobante.referencia
                };

                this.pagosService.procesarPago(data).subscribe((pago: any) => {
                  //console.log('Response pagos', pago);

                  this.facturasService.getFacturaByID(Number(comprobante.factura[index])).subscribe((factura: any) => {
                    const notaDebito = factura.nota_debito;

                    if (pago) {
                      const data1 = {
                        factura: Number(comprobante.factura[index]),
                        created_by: this.authService.getUser().id,
                        pago: pago.id,
                        user: comprobante.cuenta.id,
                      };

                      this.facturasService.putNotaDebito(notaDebito.id, data1).subscribe((res1: any) => {
                        this.facturasService.putFacturas(Number(comprobante.factura[index]), { pago: pago.id, usuario: comprobante.cuenta.id, completada: true, bloqueada: true, nota: true }).subscribe((res: any) => {
                          //console.log(res1);
                          const data = {
                            estado_pago: 1,
                            logged_user: this.authService.getUser().id,
                          };
                          this.paquetesService.put(res.paquete, data).subscribe((res: any) => {
                            this.openDialog('Pago Aprobado');
                            resolve('');
                          });
                        });
                      });
                    }
                  });
                }, (error) => {
                  this.openDialog(error.error);
                });
              });
            });
          });

          bar.then(() => {
            this.getPagosPendientes();
          });
        }
      });
    }
  }

  rechazarPago(comprobante) {
    //console.log(comprobante);

    if (comprobante.nota_debito === false) {

      const dialogRef = this.dialog.open(ConfirmarComponent, {
        // eslint-disable-next-line max-len
        data: { message: '¿Confirma que desea rechazar este pago?' },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          comprobante.id.forEach((id, index) => {

            const data = {
              estado_pago: 0,
              fecha_entrega: null,
              metodo_pago: null,
              logged_user: this.authService.getUser().id,
            };

            this.pagosService.deleteComprobante(Number(id)).subscribe(() => {
              //console.log('Pago Rechazado', comprobante);
              this.paquetesService.put(Number(comprobante.paquete[index]), data).subscribe(() => { });
              this.openDialog('Pago Rechazado');
              this.getPagosPendientes();
            });
          });
        }
      });
    }

    if (comprobante.nota_debito === true) {
      comprobante.id.forEach((id, index) => {
        this.pagosService.deleteComprobante(Number(id)).subscribe(() => {
          //console.log('Pago Rechazado', comprobante);
          this.openDialog('Pago Rechazado');
          this.getPagosPendientes();
        });
      });
    }

  }

  editReferencia(comprobante) {
    const dialogInputRef = this.dialog.open(GeneralInputComponent, {
      data: { title: 'Editar Referencia', value: comprobante.referencia, type: 'general' },
      height: '250px',
      width: '500px',
    });

    dialogInputRef.afterClosed().subscribe(result => {
      if (result) {
        comprobante.referencia = result.value;
      }
    });
  }

  procesarPago(metodo_pago, referencia, monto, monto_crc, usuario, created_by, paquete, factura) {
    return new Promise<void>((resolve, reject) => {
      this.spinnerService.show();

      const data = {
        metodo_pago,
        monto,
        monto_crc,
        referencia,
        usuario,
        created_by
      };

      this.pagosService.procesarPago(data).subscribe((pago: any) => {

        if (pago) {
          const fechaProgramada = new Date();
          fechaProgramada.setDate(fechaProgramada.getDate() + 1);

          const paqueteData = {
            fecha_entrega: this.datePipe.transform(fechaProgramada, 'yyyy-MM-dd'),
            metodo_pago,
            metodo_pago2: metodo_pago,
            estado_pago: 1,
            logged_user: this.authService.getUser().id
          };

          // eslint-disable-next-line max-len
          this.facturasService.putFacturas(factura, { pago: pago.id, usuario, completada: true, bloqueada: true }).subscribe((res: any) => {
            this.paquetesService.put(paquete, paqueteData).subscribe(() => {
              this.spinnerService.hide();
              resolve();
            }, err => {
              console.error(err);
            });

          });
        }
      });
    });
  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }

  sortData(sort: Sort) {
    console.log(sort);
    const data = this.pendientes.slice();
    if (!sort.active || sort.direction === '') {
      this.pendientes = data;
      return;
    }

    this.pendientes = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'nombre':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'cuenta':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'guias':
          return compare(a.guias[0], b.guias[0], isAsc);
        case 'fecha':
          return compare(a.created_at, b.created_at, isAsc);
        case 'referencia':
          return compare(a.referencia, b.referencia, isAsc);
        case 'pago':
          return compare(a.metodo_pago.nombre, b.metodo_pago.nombre, isAsc);
        case 'monto_usd':
          return compare(a.monto, b.monto, isAsc);
        case 'monto_crc':
          return compare(a.monto_crc, b.monto_crc, isAsc);
        case 'creador':
          return compare(a.creador, b.creador, isAsc);

        default:
          return 0;
      }
    });
  }
}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
