<div class="pt-3 pb-3" style="display: flex; align-items: center; justify-content: space-between;">
  <h2 class="h2-title p-4">Embarques</h2>
</div>

<div class="content" fxLayout="column">
  <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones">
    <div class="botones-opciones">

      <button *ngIf="authService.validateRolePermission('CREATE_SHIPMENTS')" mat-button (click)="crearEmbarque()">CREAR EMBARQUE</button>
      <button *ngIf="authService.validateRolePermission('ATTACH_PACKAGES')" mat-button (click)="adjuntarPaquetes()">ADJUNTAR PAQUETES</button>
      <button *ngIf="authService.validateRolePermission('VIEW_SHIPMENTS')" mat-button routerLink="list">VER EMBARQUES</button>
    </div>

    <div *ngIf="authService.isAdmin || authService.isOperacionesMiami">
      <button mat-button
        (click)="$event.preventDefault(); $event.stopPropagation(); export()"><mat-icon>print</mat-icon>IMPRIMIR</button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="m-4">
    <div class="button-row text-center">
      <button mat-flat-button [class.red]="status=='paquetes'" color="primary"
        (click)="changeTable(completados, 'paquetes')"> TODOS ({{completados.length}}) </button>
      <button mat-flat-button [class.yellow]="status=='sobres'" color="primary" (click)="changeTable(sobres, 'sobres')">
        SOBRES ({{sobres.length}}) </button>
      <button mat-flat-button [class.green]="status=='confactura'" color="primary"
        (click)="changeTable(confactura, 'confactura')"> CON FACTURA ({{confactura.length}})</button>
      <button mat-flat-button [class.orange]="status=='sinfactura'" color="primary"
        (click)="changeTable(sinfactura, 'sinfactura')"> SIN FACTURA ({{sinfactura.length}})</button>
      <button mat-flat-button [class.blue]="status=='maritimo'" color="primary"
        (click)="changeTable(maritimo, 'maritimo')"> MARITIMO ({{maritimo.length}}) </button>
      <button mat-flat-button [class.purple]="status=='retenidos'" color="primary"
        (click)="changeTable(retenidos, 'retenidos')"> RETENIDOS
        ({{retenidos.length}})
      </button>
      <button mat-flat-button [class.purple]="status=='porcompletar'" color="primary"
        (click)="changeTable(porcompletar, 'porcompletar')"> GUIA POR COMPLETAR
        ({{porcompletar.length}})
      </button>
    </div>
  </div>

  <div class="content-table mx-4 mb-4">
    <table mat-table matSort [dataSource]="dataSource | paginate: { itemsPerPage: pageSize, currentPage: pageIndex }"
      id="paquetesTable" (matSortChange)="sortData($event)">
      <ng-container matColumnDef="seleccionar">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef>
          <mat-checkbox class="d-flex" (click)="masterToggle()" aria-label="Seleccionar/Deseleccionar Todo"
            title="Seleccionar/Deseleccionar Todo"></mat-checkbox>
        </th>

        <td mat-cell class="pr-pl-20" *matCellDef="let row">
          <mat-checkbox class="d-flex justify-content-center" (click)="$event.stopPropagation()"
            (change)="$event ? selectedPackages.toggle(row) : null" [checked]="selectedPackages.isSelected(row)"
            [aria-label]="checkboxLabel(row)"></mat-checkbox>
        </td>
      </ng-container>

      <!-- guia Column -->
      <ng-container matColumnDef="guia">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Guía </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: element.id}">
            {{element.guia? element.guia: '-'}}
          </a>

          <span *ngIf="element.codenvio == 'C'" style="color:red">
            RETENIDO
          </span>
        </td>
      </ng-container>

      <!-- estado Column -->
      <ng-container matColumnDef="estado">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Estado </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.estado? element.estado: '-'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="tipo_paquete">
        <th mat-header-cell mat-sort-header class="pr-pl-20 text-center" *matHeaderCellDef> {{'Tipo Paquete' |
          translate}} </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">

          <span *ngIf="element.tipo_paquete === 'Caja'"><fa-icon style="font-size:x-large; color: #119ea8"
              [icon]="faArchive"></fa-icon></span>
          <span *ngIf="element.tipo_paquete === 'SobreBolsa'"><fa-icon style="font-size:x-large; color: #119ea8"
              [icon]="faShoppingBag"></fa-icon></span>
          <span *ngIf="element.tipo_paquete === 'Correspondencia'"><fa-icon style="font-size:x-large; color: #119ea8"
              [icon]="faEnvelope"></fa-icon></span>

        </td>
      </ng-container>

      <!-- envio Column -->
      <ng-container matColumnDef="nombre">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Cuenta </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: element.usuarioid}">{{element.cuenta?
            element.cuenta :
            '-'}}-{{element.nombre? element.nombre : '-'}}</a>
        </td>
      </ng-container>

      <!-- cuenta Column -->
      <ng-container matColumnDef="cuenta">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Cuenta </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">{{element.cuenta? element.cuenta : '-'}}
        </td>
      </ng-container>

      <!-- tracking Column -->
      <ng-container matColumnDef="tracking">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Tracking </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.tracking? element.tracking :
          '-'}} </td>
      </ng-container>

      <!-- descripcion Column -->
      <ng-container matColumnDef="descripcion">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Descripción </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.descripcion? element.descripcion : '-'}}
        </td>
      </ng-container>

      <!-- cantpaquetes Column -->
      <ng-container matColumnDef="cantpaquetes">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Cantidad de Paquetes </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.cantpaquetes ? element.cantpaquetes : '-'}}
        </td>
      </ng-container>

      <!-- cantpaquetes Column -->
      <ng-container matColumnDef="factura">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Factura </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <mat-checkbox disabled [checked]="element.factura != null || element.factura2 != null || element.factura3 != null || element.factura_miami"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="ver">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Factura </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">

          <button *ngIf="element.factura != null" (click)="openViewer(element)" mat-button matTooltip="Ver Facturas">
            <mat-icon>file_copy</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Estado </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.estado? element.estado : '-'}}
        </td>
      </ng-container>

      <!-- peso real Column -->
      <ng-container matColumnDef="peso_real">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Peso Real</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.peso_total? element.peso_total
          : '-'}}
        </td>
      </ng-container>

      <!-- peso volumetrico Column -->
      <ng-container matColumnDef="peso_volumetrico">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Peso Volumen </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.peso_volumetrico?
          element.peso_volumetrico
          : '-'}}
        </td>
      </ng-container>

      <!-- ruta Column -->
      <ng-container matColumnDef="ruta">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Ruta </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.ruta?
          element.ruta
          : '-'}}
        </td>
      </ng-container>

      <!-- envio Column -->
      <ng-container matColumnDef="envio">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Método de Envío </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.envio? element.envio : '-'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="creacion">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Fecha de Creación</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.creacion.toLocaleDateString('es-CR', { weekday: 'short', year: 'numeric', month: 'short', day:
          'numeric' })}}
        </td>
      </ng-container>

      <ng-container matColumnDef="modificado">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Última actualización</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.ultima_actualizacion.toLocaleDateString('es-CR', { weekday: 'short', year: 'numeric', month:
          'short', day: 'numeric' })}}&nbsp;-&nbsp;{{element.estado}}
        </td>
      </ng-container>

      <!-- tipo_cliente Column -->
      <ng-container matColumnDef="tipo_cliente">
        <th mat-header-cell mat-sort-header class="pr-pl-20" *matHeaderCellDef> Tipo de Cliente </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          <span *ngFor="let tipo of element.tipo_cliente" class="m-2 p-2 d-block"
            style="border-radius: 15px; font-size:16px !important; color: white !important;"
            [style.background-color]="tipo.color">{{
            tipo.nombre | translate}}</span>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }"></tr>
    </table>
    <div class="text-center" *ngIf="dataSource.length == 0">
      <p>No hay paquetes con este filtro</p>
    </div>
  </div>

  <div class="content-table mx-4 text-right" style="float: right;">
    <p>
      Total Peso Real: {{ totalWeigth | number:'1.2-2'}} Lbs. <br>
      Total Peso volumetrico: {{ totalWeigthVolumen | number:'1.2-2'}} Lbs.
    </p>

    <p>
      Total Paquetes Seleccionados: {{ selectedPackages?.selected.length ? selectedPackages?.selected.length : 0 }} <br>
      Total Paquetes: {{ paquetes?.length ? paquetes?.length : 0 }}
    </p>
  </div>

  <div class="paginator text-center">
    <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
      (pageBoundsCorrection)="pageIndex = $event; paginate(pageIndex)"
      (pageChange)="pageIndex = $event; paginate(pageIndex)"></pagination-controls>
  </div>
</div>

<window *ngIf="showPortal" (close)="closeViewer($event)">

  <!-- Panel Content -->
  <div class="row" *ngFor="let paquete of facturas">

    <div class="col-12 col-md-4 m-4" *ngIf="paquete.consolidado == false">
      <h4>Guia: {{paquete.guia}}</h4>
    </div>

    <div class="col-12 col-md-8 mx-2" *ngIf="paquete.consolidado == false">
      <button *ngIf="paquete.factura" style="width: auto; max-width: 200px;" class="button-archivo mx-2 mt-2"
        (click)="gotoFile(mediaUrl + paquete.factura)" mat-flat-button color="primary"
        matTooltip="{{paquete.factura.split('/')[3]}}">
        <mat-icon>download</mat-icon> Factura 1
      </button>

      <button *ngIf="paquete.factura2" style="width: auto; max-width: 200px;" class="button-archivo mx-2 mt-2"
        (click)="gotoFile(mediaUrl + paquete.factura2)" mat-flat-button color="primary"
        matTooltip="{{paquete.factura2.split('/')[3]}}">
        <mat-icon>download</mat-icon> Factura 2
      </button>

      <button *ngIf="paquete.factura3" style="width: auto; max-width: 200px;" class="button-archivo mx-2 mt-2"
        (click)="gotoFile(mediaUrl + paquete.factura3)" mat-flat-button color="primary"
        matTooltip="{{paquete.factura3.split('/')[3]}}">
        <mat-icon>download</mat-icon>Factura 3
      </button>
    </div>

    <button mat-flat-button class="button-archivo m-4" color="primary" style="position: absolute; right: 0; bottom: 0;"
      (click)="showPortal = false">Cerrar</button>
  </div>

</window>
