import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import { TiposDeCambioService } from '../../../../../services/tipos-de-cambio.service';
import { UsuariosService } from '../../../../../services/usuarios.service';

export interface CurrencyRate {
  id: number;
  fecha: Date;
  valor: number;
  user: string;
}

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  length;
  currencyRates: CurrencyRate[];
  displayedColumns: string[] = ['fecha', 'valor', 'user'];
  dataSource = new MatTableDataSource<CurrencyRate>();
  selection = new SelectionModel<CurrencyRate>(true, []);



  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.tiposDeCambioService.get().subscribe(
      (res: any) => {
        const currencyRatesList: CurrencyRate[] = [];
        res.forEach(element => {
          currencyRatesList.push({
            id: element.id,
            fecha: new Date(element.created_at),
            valor: element.value,
            user: this.usuariosService.get_fullname(element.created_by)
          });
        });
        this.length = res.length;
        this.currencyRates = currencyRatesList;
        this.dataSource.data = this.currencyRates;
      }
    );
  }

  constructor(
    public tiposDeCambioService: TiposDeCambioService,
    public usuariosService: UsuariosService
  ) { }

  public isAllSelected = (): boolean => {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  };

  public masterToggle = (): void => {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  };

  public checkboxLabel = (row?: CurrencyRate): string => {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  };
}
