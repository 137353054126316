<h2 class="h2-title p-4">Descuentos</h2>

<div class="content" fxLayout="column">
  <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones">
    <button class="botones-opciones-movil" mat-button>
      <mat-icon>more_vert</mat-icon>
    </button>
    <div class="botones-opciones">
      <button mat-button routerLink="agregar">AGREGAR DESCUENTO</button>
      <!--  <button mat-button disabled>VER INFORME DE PROMOCIÓN</button> -->
    </div>

    <div class="content-encontrados">
      <span class="texto-encontrados">{{length}} Descuento(s) Encontrados</span>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="content-table mr-4 ml-4 mb-4">
    <table mat-table
      [dataSource]="dataSource.data | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length} "
      matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Id </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="tipo">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Tipo </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.tipo == 'P'? 'Porcentaje' : 'Normal '}} </td>
      </ng-container>

      <ng-container matColumnDef="porcentaje">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Valor </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.tipo == 'P'? element.valor.toFixed(2)+'%' :
          element.valor.toFixed(2)}} </td>
      </ng-container>

      <ng-container matColumnDef="codigo">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Código Promocional</th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element">
          <a [routerLink]="['/admin/administracion/descuentos/agregar']" [queryParams]="{id: element.id}">
            {{element.codigo}}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="usados">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cuota de uso</th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element">
          <a [routerLink]="['/admin/reportes']" [queryParams]="{selectedReport: 'descuentos'}">
            {{element.usados}}/{{element.limite}}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="inicio">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha de Inicio </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.inicio | date: 'dd-MM-yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="fin">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha de Finalización </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.fin | date: 'dd-MM-yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="creado">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha de Creación </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.creado | date: 'dd-MM-yyyy'}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }"></tr>
    </table>
  </div>

  <div class="paginator text-center">
    <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
      (pageChange)="pageIndex = $event; paginate(pageIndex)"></pagination-controls>
  </div>
</div>