import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  displayedColumns: string[] = ['descripcion', 'id', 'porcentaje', 'advertencia'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  constructor() { }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

export interface PeriodicElement {
  descripcion: string;
  id: number;
  porcentaje: number;
  advertencia: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { descripcion: 'Hydrogen', id: 1, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Helium', id: 4, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Lithium', id: 6, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Beryllium', id: 9, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Boron', id: 10, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Carbon', id: 12, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Nitrogen', id: 14, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Oxygen', id: 15, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Fluorine', id: 18, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Neon', id: 20, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Sodium', id: 22, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Magnesium', id: 24, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Aluminum', id: 26, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Silicon', id: 28, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Phosphorus', id: 30, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Sulfur', id: 32, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Chlorine', id: 35, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Argon', id: 39, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Potassium', id: 39, porcentaje: 10.585, advertencia: 'advertencia 001' },
  { descripcion: 'Calcium', id: 40, porcentaje: 10.585, advertencia: 'advertencia 001' },
];
