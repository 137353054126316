import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-descuentos',
  templateUrl: './descuentos.component.html',
  styleUrls: ['./descuentos.component.scss']
})
export class DescuentosComponent implements OnInit {

  ngOnInit() {
  }

  constructor() { }
}
