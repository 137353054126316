<div class="general">
  <h3 mat-dialog-title class="modal-title text-center">{{data.title}}</h3>

  <div class="p-4" style="width: 100%;">
    <mat-form-field appearance="outline">
      <mat-label>Método de Pago</mat-label>
      <mat-select [(ngModel)]="metodo_pago" placeholder="Seleccione">
        <mat-option *ngFor="let metodo of metodosPago" [value]="metodo.id">
          {{metodo.nombre}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="metodo_pago == 2">
      <div class="mb-2">
        <!-- <label class="label">Tipo de cédula</label><br> -->
        <ng-select [items]="govTypes" bindLabel="name" bindValue="id" placeholder="Seleccione el tipo de cédula" [searchable]="false" 
        [multiple]="false" [(ngModel)]="govIdType" ></ng-select>
      </div>
      <div style="display: flex; align-items: center; width: 100%; gap: 10px;">
        <mat-form-field appearance="outline" style="flex: 1;">
          <mat-label>{{'Número de cédula' | translate}}</mat-label>
          <input matInput placeholder="{{'Número de cédula' | translate}}" [(ngModel)]="govId">
          <mat-hint>{{'Ingrese la cédula sin guiones' | translate}}</mat-hint>
        </mat-form-field>
        <button button mat-flat-button color="primary" class="button1" style="flex-shrink: 0;" (click)="verifyPayments()">Validar Pago</button>
      </div>
      <div *ngIf="dvResult && dvResult.pagos.length > 1 " class="mb-5" style="max-height: 400px; overflow-y: auto;">
        <table mat-table [dataSource]="dvResult.pagos">

          <ng-container matColumnDef="select">
            <th mat-header-cell class="pr-pl-20 text-center" *matHeaderCellDef> {{'Seleccionar' | translate}} </th>
            <td mat-cell class="pr-pl-20 text-center" *matCellDef="let row">
              <mat-checkbox color="primary" 
              (change)="changeCheck($event,row)"
              [checked]="isChecked(row)" >
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="descripcion">
            <th class="pr-pl-20" mat-header-cell *matHeaderCellDef>Descripción</th>
            <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.descripcion}} </td>
          </ng-container>

          <ng-container matColumnDef="monto">
            <th class="pr-pl-20" mat-header-cell *matHeaderCellDef>Monto</th>
            <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.monto | currency:'CRC':'symbol-narrow':'1.2-2'}} </td>
          </ng-container>

          <ng-container matColumnDef="fechaRecibido">
            <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Fecha </th>
            <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.fechaRecibido | dateFormat}} </td>
          </ng-container>

          <ng-container matColumnDef="codReferencia">
            <th class="pr-pl-20" mat-header-cell *matHeaderCellDef> Referencia </th>
            <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.codReferencia}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="dvColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: dvColumns;"></tr>
         
        </table>
        <div class="mt-3">Total para aplicar: {{this.total_crc | currency:'CRC':'symbol-narrow':'1.2-2'}}</div>
      </div>
      <div>
        <div *ngIf="dvResult && dvResult.codRespuesta == dvPaymmentResponses.paymentFound" style="color: white; background-color: green; padding: 10px 20px; border-radius: 50px; display: inline-block; font-size: 14px; font-weight: bold; margin-bottom: 6px;">Pago encontrado</div>
        <div *ngIf="dvResult && dvResult.pagos.length == 0 " style="color: white; background-color: red; padding: 10px 20px; border-radius: 50px; display: inline-block; font-size: 14px; font-weight: bold; margin-bottom: 6px;">Pago no encontrado</div>
      </div>
    </div>

    <mat-form-field style="width: 100%;" appearance="outline" class="form-field" *ngIf="data.origin == 'admin'">
      <mat-label>Referencia</mat-label>
      <input matInput [(ngModel)]="referencia">
    </mat-form-field>

    <ng-container *ngIf="this.data.origin == 'admin' && metodo_pago == 7">
      <mat-form-field style="width: 100%;" appearance="outline" class="form-field" *ngIf="data.origin == 'admin'">
        <mat-label>Detalle</mat-label>
        <input matInput [(ngModel)]="detail">
      </mat-form-field>
    </ng-container>

    <div class="col-12 pb-2 text-center" *ngIf="data.origin == 'user' && metodo_pago == 2">

      <label class="label"><strong>Adjuntar Comprobante de Pago</strong></label><br>

      <mat-form-field appearance="outline">
        <mat-label>Número de Comprobante</mat-label>
        <input matInput placeholder="Número de Comprobante" [(ngModel)]="referencia">
      </mat-form-field>

      <br>

      <img *ngIf="!viewFile1" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        #imageF1 width="150" height="150" style="border: 1px solid black;">

      <label style="background: none; border: 1px solid;" *ngIf="viewFile1 && editF1"
        class="button-archivo mt-2">{{fileLoad1.name}}</label>
      <br>
      <button class="button-archivo mt-2" (click)="imageInput1.click(); $event.preventDefault()" mat-flat-button
        color="primary"><i class="material-icons left">file_upload</i>Seleccionar</button>
      <input style="width: 100%; display: none;" type="file" class="form-control-file"
        accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .doc, .docx, .pdf"
        #imageInput1 (change)="processFile($event)">

      <div class="mx-4">
        <p class="mx-4 mt-1 mb-1 ">
          Para adjuntar su comprobante puede usar los siguiente tipos de archivos PNG, JPG, WORD, PDF y el archivo
          debe ser menor a 20MB.
        </p>
      </div>

    </div>

    <div class="row m-0 p-0" *ngIf="data.origin == 'admin' && data.tipo_cambio > 0 ">
      <div class="col-6 ml-0 pl-0">
        <mat-form-field appearance="outline" class="d-inline">
          <mat-label>Monto USD</mat-label>
          <input matInput placeholder="Monto USD" [(ngModel)]="monto_usd" (change)="convertCurrency('USD')">
        </mat-form-field>
      </div>

      <div class="col-6 mr-0 pr-0">
        <mat-form-field appearance="outline" class="d-inline">
          <mat-label>Monto CRC</mat-label>
          <input matInput placeholder="Monto CRC" [(ngModel)]="monto_crc" (change)="convertCurrency('CRC')">
        </mat-form-field>
      </div>

    </div>

    <div style=" font-size: 24px;" class="text-center m-auto" *ngIf="data.compraCorporativa == true">
      {{'Total a Pagar' | translate}}:
      <strong>{{data.paquete.factura.factura_usd.cargo_compra_corporativa +
        data.paquete.factura.factura_usd.cargo_gestion_compra_corporativa | currency:'USD':'symbol-narrow':'1.2-2'}} /
        {{data.paquete.factura.factura_crc.cargo_compra_corporativa +
        data.paquete.factura.factura_crc.cargo_gestion_compra_corporativa |
        currency:'CRC':'symbol-narrow':'1.2-2'}}</strong>
    </div>
  
    <div mat-dialog-actions style="display: flex; justify-content: space-between; margin-bottom: 2px;">
      <button mat-flat-button color="primary" class="button1" (click)="onNoClick()">Cancelar</button>
      <button mat-flat-button color="primary" class="button1" (click)="onYesClick()">Aceptar</button>
    </div>

  </div>

</div>