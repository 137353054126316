<h2 class="h2-title p-4">Facturas</h2>

<div class="content" fxLayout="column">
  <!-- <div>
    <label class="label">Paquetes Creados</label><br>
    <mat-form-field appearance="outline" class="form-field" >
      <input matInput placeholder="Paquetes Creados" type="date" class="input-field">
    </mat-form-field>
  </div> -->

  <form [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutGap="20px" class="pr-4 pl-4 pt-2 pb-2 mt-2 inputs-form"
      style="display: flex; align-items: center;">
      <div>
        <label class="label">ID</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="ID" formControlName="id" class="input-field">
        </mat-form-field>
      </div>

      <div>
        <label class="label">Desde e Incluido</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Hasta e Incluido" formControlName="date_from" type="date" class="input-field">
        </mat-form-field>
      </div>

      <div>
        <label class="label">Hasta e Incluido</label><br>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Hasta e Incluido" formControlName="date_until" type="date" class="input-field">
        </mat-form-field>
      </div>
      <div>
        <button mat-button (click)="applyFilters()">
          <img src="assets/iconos-dashboard/Lupa.png">Buscar
        </button>
      </div>
      <div>
        <button mat-button (click)="cleanFilters()">
          <img src="assets/iconos-dashboard/Facturas.png">Limpiar
        </button>
      </div>
    </div>
  </form>
  <!--
  <div>
    <label class="label">Anulada</label><br>
    <mat-form-field appearance="outline" class="form-field" >
      <input matInput placeholder="Anulada" class="input-field">
    </mat-form-field>
  </div> -->

  <mat-divider></mat-divider>

  <div class="content-table mt-0 mx-1 mb-0">
    <table mat-table
      [dataSource]="dataSource | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length}" matSort>

      <ng-container matColumnDef="numero">
        <th mat-header-cell class="pr-pl-20 table-title" *matHeaderCellDef mat-sort-header [disabled]="true">Factura
          Nro.</th>
        <td mat-cell class="pr-pl-20 text-center" mat-cell *matCellDef="let element">
          <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: element.id, factura: true}">
            {{element.factura.numero}}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Id </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.factura.id}}
        </td>
      </ng-container>

      <ng-container matColumnDef="fecha">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha de factura </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.factura.created_at}} </td>
      </ng-container>

      <ng-container matColumnDef="guia">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Guía </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.numero_de_guia ?
          element.numero_de_guia : '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="cif">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Valor CIF </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.factura.cif ? '$' +
          element.factura.cif: '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="cambio">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Tipo de cambio </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.factura.tipo_cambio ? element.factura.tipo_cambio.value:'-'}} </td>
      </ng-container>

      <ng-container matColumnDef="confeccion_guia">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Confección Guia </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.factura.cargo_confeccion_guia?'$' +element.factura.cargo_confeccion_guia.toFixed(2):'-' }} </td>
      </ng-container>

      <ng-container matColumnDef="seguro">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Seguro </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.factura.cargo_seguro?'$' +element.factura.cargo_seguro.toFixed(2):'-'}} </td>
      </ng-container>

      <ng-container matColumnDef="correspondencia">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Correspondencia </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          <mat-checkbox [checked]="element.factura.correspondencia"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="codigo">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Código promocional </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.factura.codigo_promocional?element.factura.codigo_promocional:'-'}} </td>
      </ng-container>

      <ng-container matColumnDef="impuestos">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Impuestos </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.factura.cargo_impuestos?'$' +element.factura.cargo_impuestos.toFixed(2):'-'}} </td>
      </ng-container>

      <ng-container matColumnDef="servicio">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Servicio SuperBox </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.factura.servicios_sb?'$' +element.factura.servicios_sb.toFixed(2):'-'}} </td>
      </ng-container>

      <ng-container matColumnDef="total">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Total </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.factura.total?'$'
          +element.factura.total.toFixed(2):'-'}} </td>
      </ng-container>

      <ng-container matColumnDef="usuario">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Usuario </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{usuariosService.get_fullname(element.usuario)}} </td>
      </ng-container>

      <ng-container matColumnDef="cuenta">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cuenta </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{usuariosService.get_fullaccount(element.usuario)}} </td>
      </ng-container>

      <ng-container matColumnDef="transporte">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Transporte Internacional </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.factura.cargo_transporte?'$' +element.factura.cargo_transporte.toFixed(2):'-'}} </td>
      </ng-container>

      <ng-container matColumnDef="surcharge">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fuel surcharge </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.factura.cargo_fuel?'$' +element.factura.cargo_fuel.toFixed(2):'-'}} </td>
      </ng-container>

      <ng-container matColumnDef="iva">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> IVA</th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.factura.iva?'$' +element.factura.iva.toFixed(2):'-'}} </td>
      </ng-container>

      <ng-container matColumnDef="handling">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Handling </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.factura.cargo_manejo?'$' +element.factura.cargo_manejo.toFixed(2):'-'}} </td>
      </ng-container>

      <ng-container matColumnDef="estado">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Estado </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
          {{element.estado_pago == 1 ? 'Pagada' : 'Pendiente'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="opciones">
        <th mat-header-cell class="pr-pl-20 table-title" *matHeaderCellDef>Opciones</th>
        <td mat-cell class="pr-pl-20 text-center" mat-cell *matCellDef="let element">


          <button mat-flat-button color="primary" class="button-calcular d-inline"
            style="width: min-content; min-width: 0 !important" matTooltip='Imprimir Factura'>F</button>

          <button mat-flat-button color="primary" class="button-calcular d-inline"
            style="width: min-content; min-width: 0 !important" matTooltip='Enviar por Correo Eléctronico'>E</button>

          <button mat-flat-button color="primary" class="button-calcular d-inline"
            style="width: min-content; min-width: 0 !important" matTooltip='Consolidar' *ngIf="element.factura.generado_gti == false">C
          </button>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }"></tr>
    </table>
  </div>

  <div class="pt-2 paginator text-center">
    <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
      (pageChange)="pageIndex = $event; paginate(pageIndex)"></pagination-controls>
  </div>

</div>
