import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tarifas-de-productos',
  templateUrl: './tarifas-de-productos.component.html',
  styleUrls: ['./tarifas-de-productos.component.scss']
})
export class TarifasDeProductosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
