import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { UsuariosService } from '../../../../../services/usuarios.service';
import { GeneralDialogComponent } from '../../../../common/dialogs/general-dialog/general-dialog.component';

export interface ClienteElement {
  id: number;
  name: string;
  token: string;
}

@Component({
  selector: 'app-notificaciones-push',
  templateUrl: './notificaciones-push.component.html',
  styleUrls: ['./notificaciones-push.component.scss']
})
export class NotificacionesPushComponent implements OnInit {
  @ViewChild('destinatarioInput') destinatarioInput: ElementRef<HTMLInputElement>;

  public title = '';
  public asunto = '';

  separatorKeysCodes: number[] = [ENTER, COMMA];
  destinatarioCtrl = new FormControl();
  filteredDestinatarios: Observable<ClienteElement[]>;
  destinatarios: ClienteElement[] = [];
  allDestinatarios: ClienteElement[] = [];

  timeout: any = null;

  constructor(
    private usuariosService: UsuariosService,
    private dialog: MatDialog,
    private users: UsuariosService,
  ) { }

  ngOnInit(): void {
  }

  send() {
    const data = {
      title: this.title,
      message: this.asunto,
      usuarios: this.destinatarios
    };

    this.usuariosService.sendPushNotificacions(data).subscribe((r: any) => {
      this.openDialog('Notificación enviada');
    });

    //console.log(this.destinatarios);
  }

  remove(destinatario: ClienteElement): void {
    const index = this.destinatarios.indexOf(destinatario);

    if (index >= 0) {
      this.destinatarios.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const index = this.destinatarios.indexOf(event.option.value);
    //console.log(index);

    if (index === -1) {
      this.destinatarios.push(event.option.value);
      this.destinatarioInput.nativeElement.value = '';
      this.destinatarioCtrl.setValue('');
    }
  }

  buscarListaClientes(event = { keyCode: 13 }, cuenta = null) {
    this.allDestinatarios = [];
    clearTimeout(this.timeout);
    const $this = this;
    this.timeout = setTimeout(function() {
      if (event.keyCode != 13) {
        $this.users.search(cuenta).subscribe((res: any) => {
          const datos: ClienteElement[] = [];
          datos.push({ id: -1, name: 'Todos los clientes', token: 'TODOS' });

          res.forEach(element => {
            // eslint-disable-next-line max-len
            datos.push({ id: element.id, name: $this.users.get_account_number(element) + '-' + $this.users.get_fullname(element), token: element.push_token });
          });

          $this.allDestinatarios = datos;

          $this.filteredDestinatarios = $this.destinatarioCtrl.valueChanges.pipe(
            startWith(''),
            map(user => $this._filter(user, $this.allDestinatarios)),
          );
        }
        );
      }
    }, 1000);
  }

  displayFn(value) {
    if (!value) {
      return '';
    }
    return value.name;
  }

  private _filter(value: any, options: any) {
    let filterValue: string;
    value.name ? filterValue = value.name.toLowerCase() : filterValue = value.toString().toLowerCase();
    return options.filter(user => user.name.toLowerCase().includes(filterValue));
  }

  private openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });
  }
}
