import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DescuentosService {
  private url = `${environment.apiUrl}/descuentos/`;

  constructor(
    private http: HttpClient
  ) { }

  get(page) {
    return this.http.get(this.url+ '?page=' + page);
  }

  getByID(id) {
    return this.http.get(this.url + id);
  }


  post(data) {
    return this.http.post(this.url, data);
  }

  update(id, data) {
    return this.http.put(this.url + id + '/', data);
  }

  applyPromoCode(user, code) {
    const data = {
      user,
      code
    };

    return this.http.post(this.url + 'check-code/', data);
  }


}
