/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable quote-props */
/* eslint-disable brace-style */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { StateTypeEnum } from 'src/app/core/data/stateTypes';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { SelectPackagesComponent } from 'src/app/modules/common/dialogs/selectPackages/select-packages.component';
import { AuthService } from 'src/app/services/auth.service';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { environment } from 'src/environments/environment';
import { AppPackageComponent } from '../app-package/app-package.component';
import { CameraModalComponent } from '../camera-modal/camera-modal.component';
import { ConsolidationModalComponent } from '../consolidation-modal/consolidation-modal.component';
import { PackageLabelComponent } from '../package-label/package-label.component';


export interface ClienteElement {
  id: number;
  name: string;
  tarifaA: number;
  tarifaM: number;
  ruta: number;
}

export interface DialogData {
  tracking: string;
  isNew: boolean;
  id: number;
  consolidar: boolean;
  addls: boolean;
  pendientes: any;
}

@Component({
  selector: 'app-newpackage',
  templateUrl: './newpackage.component.html',
  styleUrls: ['./newpackage.component.scss']
})

export class NewPackageComponent implements OnInit {

  @ViewChild('largo', { read: MatInput, static: true }) largo: MatInput;

  stateTypeEnum = StateTypeEnum;
  deletePackage: boolean;
  editMode: boolean;
  actual = 1;
  selectedGender: string;
  email = new FormControl('', [Validators.required, Validators.email]);
  paquetesForm: any;
  paquete: any;
  mediaUrl = environment.mediaUrl;

  cliente: ClienteElement;
  clienteOptions: ClienteElement[];
  filteredOptions: Observable<ClienteElement[]>;
  filteredOptionsStores: Observable<any>;
  tarifas: any;

  cantPaquetes = 1;
  common_stores = ['Amazon', 'Apple', 'Best Buy', 'Carters', 'Ebay', 'Shein', 'Shipping Dept', 'Walmart'];
  requiredValues = ['tracking', 'usuario', 'peso', 'remitente', 'tipo_de_paquete'];
  dialogModal = false;

  isNew = false;
  isSaved = false;
  addls = false;
  consolidar = false;
  isSICSAAccount = false;
  productCrtl = new FormControl();
  paquetes: any;

  displayedColumnsAdicionales: string[] = [
    'guia',
    'tracking',
    'peso',
    'alto',
    'ancho',
    'largo',
    'peso_total',
    'peso_volumetrico',
    'numero_bulto',
    'eliminar',
  ];
  dataSourceAdicionales: any;
  adicionales = [];
  facturasConsolidadas = [];
  changePlanActive = false;

  pesoRealKg = '';
  pesoTotalKg = '';
  newTracking = '';

  selectedAccount: any;
  selectedUser: any;
  timeout: any = null;

  fileLoad1: any;
  fileLoad2: any;
  fileLoad3: any;
  fileLoad4: any;

  bultos = [];

  constructor(
    private fb: FormBuilder,
    private paquetesService: PaquetesService,
    private users: UsuariosService,
    private spinnerService: NgxSpinnerService,
    private dialog: MatDialog,
    public usuariosService: UsuariosService,
    public authService: AuthService,
    public dialogRef: MatDialogRef<NewPackageComponent>,
    private packageLabel: PackageLabelComponent,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  openModalConsolidation(data) {
    this.dialog.open(ConsolidationModalComponent, {
      data,
      width: '500',
      panelClass: 'consolidation'
    });
  }

  printButtonDisabled(): boolean {
    if (!this.paquetesForm) {
      return true;
    }
    // Return true if the package has all fields completed
    const formVals = this.paquetesForm.value;
    if (!this.isSaved) {
      return true;
    }
    for (const key of this.requiredValues) {
      if (!formVals[key]) {
        return true;
      }
    }
    return false;
  }

  changeSelect(event) {
    if (event == 'SobreBolsa') {
      this.paquetesForm.controls['precio'].setValue(10);
      this.paquetesForm.controls['ancho'].setValue(1);
      this.paquetesForm.controls['alto'].setValue(1);
      this.paquetesForm.controls['largo'].setValue(1);
      this.paquetesForm.patchValue({ descripcion: 'BOLSA' });

    } else {
      if (this.paquetesForm.value.descripcion.startsWith('BOLSA')) {
        this.paquetesForm.patchValue({ descripcion: '' });
        this.paquetesForm.controls['alto'].setValue('');
        this.paquetesForm.controls['largo'].setValue('');
        this.paquetesForm.controls['ancho'].setValue('');
        this.paquetesForm.controls['peso'].setValue('');
      }
    }
    this.calcularMedidas();
    this.calcularPesoCobrable();
  }

  ngOnInit() {
    this.loadPackages();
  }

  verifyDeletePackage() {
    if (this.paquete.estado_actual.id == this.stateTypeEnum.Eliminado) {
      this.paquetesForm.disable();
      this.deletePackage = true;
    }
  }

  loadPackages() {
    let tracking = '';
    this.dataSourceAdicionales = [];
    this.facturasConsolidadas = [];

    const pkg = this.data['paquete'];

    if (this.data && pkg) {
      tracking = this.data.tracking;
      this.isNew = this.data.isNew;
      this.consolidar = this.data.consolidar;
      this.addls = this.data.addls ? this.data.addls : false;
      this.paquetes = this.data['paquete'].paquete_contenedor;
      this.dialogModal = true;
    }

    this.editMode = false;
    this.paquete = {
      tipo_de_paquete: null,
      tracking,
      clase: 'C',
      codigo_promocional: null,
      descripcion: null,
      remitente: null,
      referencia: null,
      precio: null,
      usuario: '',
      destinatario: null,
      exonerado: false,
      estado_actual: 2,
      medida: null,
      plan: 'A',
      tarifa: 1,
      observacion: null,
      largo: null,
      alto: null,
      ancho: null,
      peso: null,
      peso_volumetrico: null,
      peso_total: null,
      factura_1: null,
      factura_2: null,
      factura_3: null,
      imagen: null,
      logged_user: null,
    };

    this.getTarifas().then(result => {
      this.inicializarForm();

      if (this.data !== null && this.data !== undefined && this.data.isNew === false) {
        this.editMode = true;

        this.paquetesService.getById(this.data.id).subscribe((res: any) => {
          res.clase == null ? res.clase = this.paquete.clase : '';
          res.largo == 0 ? res.largo = '' : res.largo;
          res.alto == 0 ? res.alto = '' : res.alto;
          res.ancho == 0 ? res.ancho = '' : res.ancho;

          this.paquete = res;
          this.verifyDeletePackage();

          this.selectedUser = this.paquete.usuario;
          this.paquete.estado_actual = this.paquete.estado_actual.id;
          this.cantPaquetes = this.consolidar ? 1 : this.paquete.paquete_contenedor.length > 0 ? this.paquete.paquete_contenedor.length : 1;
          const contenedor = this.paquete.paquete_contenedor.length > 0 ? this.paquete.paquete_contenedor : null;
          this.adicionales = contenedor != null ? contenedor[0].adicional ? contenedor : [] : [];
          this.dataSourceAdicionales = this.adicionales;
          const bultos = JSON.parse(this.paquete.bultos);

          if (bultos != null) {
            if (bultos.length > 0) {
              this.bultos = bultos;
              this.dataSourceAdicionales = this.bultos;
              this.displayedColumnsAdicionales = [
                'tracking',
                'peso',
                'alto',
                'ancho',
                'largo',
                'peso_total',
                'peso_volumetrico',
                'eliminar',
              ];
            }
          }

          this.UpdateKg(this.paquete.peso);
          this.UpdateKgTotal(this.paquete.peso_total);

          this.inicializarForm();

          if (this.paquete.usuario != null) {
            // eslint-disable-next-line max-len
            this.paquetesForm.controls.usuario.setValue({ id: this.paquete.usuario.id, name: this.usuariosService.get_fullaccount(this.paquete.usuario) });
          }

          if (this.paquete.usuario && this.paquete.usuario.cuenta == 'SJO1000') {
            this.isSICSAAccount = true;
          }

          if (this.paquete.factura_1 != null) {
            this.paquetesForm.controls.factura_1.disable();
          }
          if (this.paquete.factura_2 != null) {
            this.paquetesForm.controls.factura_2.disable();
          }
          if (this.paquete.factura_3 != null) {
            this.paquetesForm.controls.factura_3.disable();
          }
          if (this.paquete.imagen != null) {
            this.paquetesForm.controls.imagen.disable();
          }

          if (this.paquete.paquete_contenedor.length > 0) {
            this.paquete.paquete_contenedor.forEach(f => {
              if (f.factura_1 != null) {
                if (!f.factura_1.includes('.pdf') && !f.factura_1.includes('.docx') && !f.factura_1.includes('.doc')) {
                  this.facturasConsolidadas.push({ factura: f.factura_1, viewFile: false, id: f.id, key: 'factura_1' });
                } else {
                  this.facturasConsolidadas.push({ factura: f.factura_1, viewFile: true, id: f.id, key: 'factura_1' });
                }
              }

              if (f.factura_2 != null) {
                if (!f.factura_2.includes('.pdf') && !f.factura_2.includes('.docx') && !f.factura_2.includes('.doc')) {
                  this.facturasConsolidadas.push({ factura: f.factura_2, viewFile: false, id: f.id, key: 'factura_2' });
                } else {
                  this.facturasConsolidadas.push({ factura: f.factura_2, viewFile: true, id: f.id, key: 'factura_2' });
                }
              }

              if (f.factura_3 != null) {
                if (!f.factura_3.includes('.pdf') && !f.factura_3.includes('.docx') && !f.factura_3.includes('.doc')) {
                  this.facturasConsolidadas.push({ factura: + f.factura_3, viewFile: false, id: f.id, key: 'factura_3' });
                } else {
                  this.facturasConsolidadas.push({ factura: + f.factura_3, viewFile: true, id: f.id, key: 'factura_3' });
                }
              }

            });
          }
        });
      }
    });
  }

  public getTarifas() {
    return new Promise(async (resolve) => {
      this.paquetesService.getTarifas().subscribe((data: any) => {
        this.tarifas = data;
        resolve(true);
      });
    });
  }

  public getErrorMessage(control) {

    if (control.hasError('required')) {
      return 'Campo necesario';
    }
    if (control.hasError('minlength')) {
      return 'Demasiado Corto';
    }
    if (control.hasError('maxlength')) {
      return 'Demasiado Largo';
    }
    if (control.hasError('min')) {
      return 'Límite mínimo';
    }
    if (control.hasError('email')) {
      return 'No es un correo valido';
    }
    if (control.hasError('invalidCode')) {
      return 'Código Invalido';
    }
    return 'Error';
  }

  cambiarValor(valor: number) {
    this.actual = valor;
  }

  calcularMedidas() {
    const largo = this.paquetesForm.controls.largo.value;
    const alto = this.paquetesForm.controls.alto.value;
    const ancho = this.paquetesForm.controls.ancho.value;
    if (largo && alto && ancho) {
      this.paquetesForm.controls.medida.setValue(`${largo} - ${alto} - ${ancho}`);
      const peso_volumetrico = (largo * alto * ancho) / 166;
      this.paquetesForm.controls.peso_volumetrico.setValue(Math.ceil(peso_volumetrico));
    }
  }

  calcularPesoCobrable() {
    const peso = parseFloat(this.paquetesForm.controls.peso.value);
    if (!peso) {
      return;
    }
    const peso_volumen = parseFloat(this.paquetesForm.controls.peso_volumetrico.value);
    const own = this;
    const tarifa = this.tarifas.filter(p => p.id == own.paquetesForm.value.tarifa)[0].codigo;

    if (tarifa == 'A') {
      this.paquetesForm.controls.peso_total.setValue(peso);

    } else {
      this.paquetesForm.controls.peso_total.setValue(peso > peso_volumen ? peso : peso_volumen);
    }

    this.UpdateKgTotal(this.paquetesForm.value.peso_total);
  }

  inicializarForm() {
    return new Promise(async (resolve) => {
      let peso = this.consolidar ? 0 : this.paquete.peso;
      let pesoTotal = this.consolidar ? 0 : this.paquete.peso_total;
      let pesoVolumen = this.consolidar ? 0 : this.paquete.peso_volumen;
      let detail = this.paquete.observacion || '';

      // Sum all guides and add that to the observations
      const pkgGuides = this.paquete.paquete_contenedor || [];
      if (pkgGuides) {
        let newDetails = '';
        for (let i = 0; i < pkgGuides.length; i++) {
          const pkg = pkgGuides[i];
          newDetails += `${pkg.numero_de_guia} (${pkg.tracking})`;
          if (i < pkgGuides.length - 1) {
            newDetails += '\n';
          }
        }
        detail = `Consolidar Paquetes Guias \n${newDetails}`;
      }

      // Weight calculation
      if (this.addls) {
        for (const pkg of pkgGuides) {
          // As we are adding the guides, we need to add the weight of the guides
          peso += pkg.peso;
          pesoTotal += pkg.peso_total;
          pesoVolumen += pkg.peso_volumen;
        }
      }

      this.paquetesForm = this.fb.group({
        tipo_de_paquete: [this.paquete.tipo_de_paquete, [Validators.required, Validators.maxLength(250)]],
        tracking: [this.paquete.tracking ? this.paquete.tracking : this.data.tracking, [Validators.required, Validators.maxLength(250)]],
        clase: [this.paquete.clase, [Validators.required, Validators.maxLength(250)]],
        codigo_promocional: [this.paquete.codigo_promocional, [Validators.maxLength(250), this.isValidCode()]],
        descripcion: [this.paquete.descripcion == '-' ? '' : this.paquete.descripcion, [Validators.required, Validators.maxLength(1000)]],
        remitente: [this.paquete.remitente, [Validators.required, Validators.maxLength(250)]],
        referencia: [this.paquete.referencia, [Validators.maxLength(250)]],
        precio: [this.paquete.precio, [Validators.maxLength(250)]],
        usuario: ['', [Validators.required]],
        destinatario: [this.paquete.destinatario, [Validators.maxLength(250)]],
        exonerado: [this.paquete.exonerado],
        estado_actual: [this.paquete.estado_actual],
        medida: [this.paquete.medida, [Validators.maxLength(250)]],
        plan: [this.paquete.plan ? this.paquete.plan : 'A', [Validators.required, Validators.maxLength(250)]],
        tarifa: [this.paquete.tarifa.id ? this.paquete.tarifa.id : 1, [Validators.required, Validators.maxLength(250)]],
        observacion: [detail],
        largo: [this.paquete.largo],
        alto: [this.paquete.alto],
        ancho: [this.paquete.ancho],
        peso: [peso, [Validators.maxLength(250)]],
        peso_volumetrico: [pesoVolumen, [Validators.maxLength(250)]],
        peso_total: [pesoTotal, [Validators.maxLength(250)]],
        factura_1: [this.paquete.factura_1],
        factura_2: [this.paquete.factura_2],
        factura_3: [this.paquete.factura_3],
        imagen: [this.paquete.imagen],
        logged_user: [],
        factura_miami: [this.paquete.factura_miami]
      });

      this.UpdateKg(peso);
      this.UpdateKgTotal(pesoTotal);
      this.paquetesForm.value.estado_actual = 1;
      this.filteredOptionsStores = this.paquetesForm.controls.remitente.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value)),
      );
      this.buscarListaClientes();
      if (this.newTracking != '') {
        this.paquete.tracking = this.newTracking;
        this.paquetesForm.controls.tracking.setValue(this.newTracking);
      }
      resolve(true);
    });
  }

  crearPaquete() {
    this.replaceBlankWithNull();
    if (this.paquetesForm.valid) {
      this.spinnerService.show();
      this.paquetesForm.controls.usuario.enable();
      this.setUser();
      this.paquetesForm.value.estado_actual = 4;
      this.paquetesForm.controls.logged_user.setValue(this.authService.getUser().id);
      // eslint-disable-next-line max-len
      this.paquetesForm.controls.observacion.setValue(this.paquetesForm.controls.observacion.value != null ? this.paquetesForm.controls.observacion.value : '' + '\n' + new Date().toLocaleDateString('es-CR', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', }) + ': ' + 'Paquete registrado sin Prealerta');
      if (this.adicionales.length > 0) {
        this.paquetesForm.value.adicional = true;
      }

      if (this.bultos.length > 0) {
        this.paquetesForm.value.bultos = JSON.stringify(this.bultos);
      }

      this.paquetesService.post(this.paquetesForm.value).subscribe((data: any) => {
        this.resetForm();
        this.spinnerService.hide();
        this.openDialog(`Paquete creado con éxito número de guía: ${data.numero_de_guia}`);
        this.dialogRef.close(true);
        this.isSaved = true;

      }, err => {
        this.spinnerService.hide();
        this.openDialog(err.error.message);
      });
    }
  }

  actualizarPaquete() {
    this.replaceBlankWithNull();
    this.spinnerService.show();
    this.paquetesForm.controls.usuario.enable();
    this.setUser();
    this.paquetesForm.controls.logged_user.setValue(this.authService.getUser().id);
    if (this.consolidar === true) {
      this.paquetesForm.value.consolidado = true;
    }
    if (this.adicionales.length > 0 || this.addls) {
      this.paquetesForm.value.adicional = true;
    }
    if (this.bultos.length > 0) {
      this.paquetesForm.value.bultos = JSON.stringify(this.bultos);
    }
    const pesoVolumen = this.paquetesForm.controls.peso_volumetrico.value;
    const values = this.paquetesForm.value;
    if (isNaN(pesoVolumen)) {
      values.peso_volumetrico = 0;
    }
    const pesoTotal = this.paquetesForm.controls.peso_total.value;
    if (isNaN(pesoTotal)) {
      values.peso_total = 0;
    }
    this.paquetesService.put(this.paquete.id, values).subscribe((data: any) => {
      this.spinnerService.hide();
      if (this.addls) {
        this.convertirPaquete();
      } else {
        this.openDialog('Paquete Actualizado');
        this.ngOnInit();
      }
      this.isSaved = true;

    }, err => {
      this.spinnerService.hide();
      this.openDialog(`Ha ocurrido un error ${err.status}. Por favor complete todos los datos`);
      if (this.paquete.usuario != null) {
        const usuario = { id: this.selectedUser.id, name: this.usuariosService.get_fullaccount(this.selectedUser) };
        this.paquetesForm.controls.usuario.setValue(usuario);
      }
      if (err.error.plan) {
        this.openDialog(err.error.message);
        this.changePlanActive = true;
      }
    });
  }

  public setUser() {
    if (this.paquetesForm.value.usuario.id) {
      this.paquetesForm.controls.usuario.setValue(this.paquetesForm.value.usuario.id);
    }
  }

  processFile(event, id = '') {
    if (event.length > 0) {
      const file = event[0];
      const size = file.size / 1024;
      if (size <= 20480) {
        if (file.name.includes('.docx') || file.name.includes('.pdf') || file.name.includes('.doc') || file.name.includes('.jpg') || file.name.includes('.jpeg') || file.name.includes('.png')) {
          switch (id) {
            case '1':
              this.paquetesForm.controls.factura_1.setValue(file);
              this.fileLoad1 = file;
              break;
            case '2':
              this.paquetesForm.controls.factura_2.setValue(file);
              this.fileLoad2 = file;
              break;
            case '3':
              this.paquetesForm.controls.factura_3.setValue(file);
              this.fileLoad3 = file;
              break;
            case '4':
              this.paquetesForm.controls.imagen.setValue(file);
              this.fileLoad4 = file;
              break;
            default:
              break;
          }
        } else {
          this.openDialog('Formato de archivo invalido');
        }
      } else {
        this.openDialog('Archivo supera los 20 megabytes');
      }
    }
  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close(true);
      }, 3000);
    });

  }

  buscarListaClientes(event = { keyCode: 13 }, cuenta = null) {
    clearTimeout(this.timeout);
    const $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.usuariosService.searchCliente(cuenta).subscribe((res: any) => {
          const datos: ClienteElement[] = [];
          res.forEach(element => {
            // eslint-disable-next-line max-len
            datos.push({ id: element.id, name: $this.usuariosService.get_fullaccount(element), tarifaA: element.tarifaA, tarifaM: element.tarifaM, ruta: element.ruta });
          });
          $this.clienteOptions = datos;
          $this.filteredOptions = $this.paquetesForm.controls.usuario.valueChanges.pipe(
            startWith(''),
            map(user => $this._filterCliente(user, cuenta, $this.clienteOptions)),
          );
        });
      }
    }, 1000);
  }

  displayFn(value) {
    if (!value) {
      return '';
    }
    return value.name;
  }

  displayStores(value) {
    if (!value) {
      return '';
    }
    return value;
  }

  isValidCode(): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value) {
        if (control.value.length == 0 || control.value == this.paquete.codigo_promocional) {
          return null;
        }

        const own = this;
        const tarifa = this.tarifas.filter(function (el) {
          return el.id === own.paquetesForm.value.tarifa;
        })[0].codigo;
        // eslint-disable-next-line max-len
        this.paquetesService.checkCodigoPromocional(this.paquetesForm.value.usuario.id, control.value, tarifa, this.paquete.id).subscribe((data: any) => {
          if (!data.valid) { control.setErrors({ invalidCode: true }); } else { return null; }
        }, (error) => {
          control.setErrors({ invalidCode: true });
        });
      } else { return null; }
    };
  }

  openCamera(event, file) {
    const dialogRef = this.dialog.open(CameraModalComponent, {
      data: { title: 'Capturar imagen' },
      height: '400px',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        const image = this.b64toFile(result.image.imageAsDataUrl, file);
        const formFile = new File([image], file + '.png', { type: 'image/png', lastModified: new Date().getTime() });
        this.paquetesForm.get(file).setValue(formFile);
      }
    });
  }

  b64toFile(dataURI, file): File {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Create a blob that looks like a file.
    const blob = new Blob([ab], { 'type': mimeString });
    blob['lastModifiedDate'] = (new Date()).toISOString();
    blob['name'] = file;

    // Figure out what extension the file should have
    switch (blob.type) {
      case 'image/jpeg':
        blob['name'] += '.jpg';
        break;
      case 'image/png':
        blob['name'] += '.png';
        break;
    }
    // cast to a File
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return <File>blob;
  }

  printLabel() {
    const packages = [];
    let index = 1;
    const total = this.paquete.paquete_contenedor.length > 0 ? this.paquete.paquete_contenedor.filter(item => item.adicional === true).length > 0 ? this.paquete.paquete_contenedor.filter(item => item.adicional === true).length : 0 : 0;
    this.usuariosService.get_user_by_id(this.paquetesForm.value.usuario.id).subscribe(data => {
      this.paquete.remitente = this.paquetesForm.value.remitente;
      this.paquete.destinatario = this.paquetesForm.value.destinatario;
      this.paquete.precio = this.paquetesForm.value.precio;
      this.paquete.descripcion = this.paquetesForm.value.descripcion;
      this.paquete.ancho = this.paquetesForm.value.ancho;
      this.paquete.alto = this.paquetesForm.value.alto;
      this.paquete.largo = this.paquetesForm.value.largo;
      this.paquete.peso = this.paquetesForm.value.peso;
      this.paquete.medida = this.paquetesForm.value.medida;
      this.paquete.peso_volumetrico = this.paquetesForm.value.peso_volumetrico;
      this.paquete.peso_total = this.paquetesForm.value.peso_total;
      this.paquete.index = index;
      this.paquete.total = total;
      this.paquete.usuario = data;
      if (this.paquete.entrega.ruta === null) {
        this.paquete.entrega.ruta = this.paquete.usuario.ruta;
      }
      packages.push(this.paquete);
      if (this.adicionales.length > 0) {
        this.adicionales.forEach(element => {
          index = index + 1;
          element.index = index;
          element.numero_de_guia = this.paquete.numero_de_guia;
          element.total = total;
          element.usuario = data;
          packages.push(element);
        });
      }
      if (this.bultos.length > 0) {
        this.bultos.forEach(element => {
          index = index + 1;
          element.index = index;
          element.numero_de_guia = this.paquete.numero_de_guia;
          element.total = this.bultos.length;
          element.usuario = data;
          element.entrega = { ruta: this.paquete.entrega.ruta };

          packages.push(element);
        });
      }
      this.packageLabel.generatePackageLabel(packages);
      this.dialogRef.close();
    });
  }

  gotoFile(file) {
    // eslint-disable-next-line @typescript-eslint/quotes
    window.open(this.mediaUrl + file, "_blank");
    event.preventDefault();
  }

  gotoFileF(url) {
    window.open(this.mediaUrl + url, '_blank');
    event.preventDefault();
  }

  nextPackages() {
    const index = this.data.pendientes.data.map(e => e.id).indexOf(this.data.id);
    if (index <= this.data.pendientes.data.length) {
      this.data.id = this.data.pendientes.data[index + 1].id;
      this.data.tracking = this.data.pendientes.data[index + 1].tracking;
      this.data['paquete'] = this.data.pendientes.data[index + 1];
      this.loadPackages();
    }
  }

  previousPackages() {
    const index = this.data.pendientes.data.map(e => e.id).indexOf(this.data.id);
    if (index >= 0) {
      this.data.id = this.data.pendientes.data[index - 1].id;
      this.data.tracking = this.data.pendientes.data[index - 1].tracking;
      this.data['paquete'] = this.data.pendientes.data[index - 1];
      this.loadPackages();
    }
  }

  addPackagesAdditional() {
    const dialogRef = this.dialog.open(SelectPackagesComponent, {
      data: { option: 'adicionar', plan: 'A', user: this.paquete.usuario.id, paqueteid: this.paquete.id }
    });
    dialogRef.afterClosed().subscribe(result => {
      result.paquetes = result.paquetes.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
      if (result !== undefined) {
        let bulto = 2;
        result.paquetes.forEach(element => {
          const item = {
            id: element.id,
            tracking: element.tracking,
            peso: element.peso,
            alto: element.alto,
            ancho: element.ancho,
            largo: element.largo,
            peso_total: element.peso_total,
            numero_de_guia: element.guia,
            peso_volumetrico: element.peso_volumetrico,
            bulto,
          };
          this.adicionales.push(item);
          bulto = bulto + 1;
          this.paquetesForm.controls['peso'].setValue(this.paquetesForm.controls['peso'].value + element.peso);
          this.paquetesForm.controls['peso_volumetrico'].setValue(this.paquetesForm.controls['peso_volumetrico'].value + element.peso_volumetrico);
          this.paquetesForm.controls['peso_total'].setValue(this.paquetesForm.controls['peso_total'].value + element.peso_total);
        });
        this.dataSourceAdicionales = this.adicionales;
      }
    });
  }

  removePackageAdditional(id) {

    if (this.displayedColumnsAdicionales.includes('guia')) {
      const i = this.adicionales.map(function (x) { return x.id; }).indexOf(id);
      if (i >= 0) {
        this.paquetesForm.controls['peso'].setValue(this.paquetesForm.controls['peso'].value - this.adicionales[i].peso);
        this.paquetesForm.controls['peso_volumetrico'].setValue(this.paquetesForm.controls['peso_volumetrico'].value - this.adicionales[i].peso_volumetrico);
        this.paquetesForm.controls['peso_total'].setValue(this.paquetesForm.controls['peso_total'].value - this.adicionales[i].peso_total);

        this.adicionales.splice(i, 1);
      }

      let bulto = 2;
      this.adicionales.forEach(element => {
        element.bulto = bulto;
        bulto = bulto + 1;
      });

      const paquete = {
        id,
        estado_actual: 4,
        paquete_contenedor: 'False',
        bulto: null,
        adicional: false,
        logged_user: this.authService.getUser().id,
      };

      this.paquetesService.put(id, paquete).subscribe((res: any) => {
        this.dataSourceAdicionales = [...this.adicionales];
        this.paquetesService.put(this.paquete.id, this.paquetesForm.value).subscribe((data: any) => { });
      });

    } else {

      const i = this.bultos.map(function (x) { return x.id; }).indexOf(id);
      if (i >= 0) {
        this.bultos.splice(i, 1);
        const PesoBultos = this.bultos.reduce((accumulator, object) => accumulator + object.peso_total, 0);
        this.paquetesForm.controls['peso_total'].setValue(this.paquetesForm.controls['peso'].value + PesoBultos);
        this.UpdateKgTotal(this.paquetesForm.controls['peso_total'].value);
        this.dataSourceAdicionales = [...this.bultos];
      }
    }
  }


  changePlan(plan) {
    this.getTarifas().then(() => {
      this.tarifas = this.tarifas.filter(f => f.codigo === plan);
      if (plan === 'A') {
        this.paquetesForm.controls.tarifa.setValue(this.paquete.usuario?.tarifaA);
      }
      if (plan === 'M') {
        this.paquetesForm.controls.tarifa.setValue(this.paquete.usuario?.tarifaM);
      }
      if (plan === 'C') {
        this.paquetesForm.controls.tarifa.setValue(3);
      }
    });
  }

  convertirPaquete() {
    const guia_contenedor = this.paquete.numero_de_guia;
    const infoBultos = this.paquetes.map((elem: any, index: number) => ({
      id: index + 1,
      guia_original: elem.numero_de_guia,
      guia: `${guia_contenedor}-${index + 1}`,
      tracking: elem.tracking,
      peso: elem.peso,
      alto: elem.alto,
      ancho: elem.ancho,
      largo: elem.largo,
      peso_total: elem.peso_total,
      peso_volumetrico: elem.peso_volumetrico,
      adicional: true,
      bulto: index + 1,
      escaneado_embarque: false,
    }));
    let pesoVolumen = 0;
    let pesoTotal = 0;
    let pesoReal = 0;
    const resetPaquetes = new Promise((resolve, reject) => {
      this.paquetes.forEach((pkgObj: any, index, array) => {
        const paquete = {
          id: pkgObj.id,
          logged_user: this.authService.getUser().id,
          factura: 0,
          paquete_contenedor: guia_contenedor,
          adicional: true,
          consolidado: false,
        };
        pesoVolumen += pkgObj.peso_volumetrico;
        pesoTotal += pkgObj.peso_total;
        pesoReal += pkgObj.peso;
        this.paquetesService.put(pkgObj.id, paquete).subscribe();
        if (index === array.length - 1) {
          resolve(true);
        };
      });
    });
    resetPaquetes.then(() => {
      const paquete = {
        id: this.paquete.id,
        paquete_contenedor: 'False',
        bulto: 0,
        adicional: true,
        consolidado: false,
        peso_volumetrico: pesoVolumen,
        peso_total: pesoTotal,
        peso: pesoReal,
        tracking: this.paquete.numero_de_guia,
        logged_user: this.authService.getUser().id,
        factura: 0,
        bultos: JSON.stringify(infoBultos),
      };
      this.paquetesService.put(this.paquete.id, paquete).subscribe(() => {
        this.openDialog('Paquete Actualizado bajo Guía: ' + guia_contenedor);
        this.dialogRef.close(true);
      });
    });
  }

  UpdateKg(value: number) {
    const kg = (value * 0.453592).toFixed(2);
    this.pesoRealKg = kg;

  }

  UpdateKgTotal(value: number) {
    const kg = (value * 0.453592).toFixed(2);
    this.pesoTotalKg = kg;

  }

  loadGuide(guide) {
    this.paquetesService.findGuia(guide).subscribe((data: any) => {
      if (data.length > 0) {
        if (data[0]['compra_corporativa'] == true) {
          this.newTracking = this.paquete.tracking;
          this.data.id = data[0].id;
          this.ngOnInit();
        } else {
          this.openDialog('Compra Corporativa no encontrada');
        }
      } else {
        this.openDialog('Compra Corporativa no encontrada');
      }
    }, () => {
      this.openDialog('Compra Corporativa no encontrada');
    });
  }

  insertPackagesAdditional() {
    const dialogRef = this.dialog.open(AppPackageComponent, {
      data: {
        paquete: this.paquete.id,
        guia: this.paquete.numero_de_guia,
        tracking: null,
        peso: null,
        alto: null,
        ancho: null,
        largo: null,
        peso_total: null,
        numero_de_guia: null,
        peso_volumetrico: null,
        bulto: null,
        tarifa: this.paquete.tarifa.id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        const item = {
          id: this.bultos.length + 2,
          guia: this.paquete.numero_de_guia + '-' + (this.bultos.length + 2),
          tracking: result.tracking,
          peso: result.peso,
          alto: result.alto,
          ancho: result.ancho,
          largo: result.largo,
          peso_total: result.peso_total,
          peso_volumetrico: result.peso_volumetrico,
          adicional: true,
          bulto: this.bultos.length + 2,
          escaneado_embarque: false,
        };
        this.bultos.push(item);
        const PesoBultos = this.bultos.reduce((accumulator, object) => accumulator + object.peso_total, 0);
        this.paquetesForm.controls['peso_total'].setValue(this.paquetesForm.controls['peso'].value + PesoBultos);
        this.dataSourceAdicionales = [...this.bultos];
        this.displayedColumnsAdicionales = ['tracking', 'peso', 'alto', 'ancho', 'largo', 'peso_total', 'peso_volumetrico', 'eliminar'];

      } else {
        this.dataSourceAdicionales = [...this.bultos];
      }
    });
  }

  updatePackagesAdditional(element) {
    const dialogRef = this.dialog.open(AppPackageComponent, {
      data: {
        paquete: this.paquete.id,
        guia: this.paquete.numero_de_guia,
        id: element.id,
        tracking: element.tracking,
        peso: element.peso,
        alto: element.alto,
        ancho: element.ancho,
        largo: element.largo,
        peso_total: element.peso_total,
        peso_volumetrico: element.peso_volumetrico,
        bulto: element.bulto,
        tarifa: this.paquete.tarifa.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        const i = this.bultos.map(function (x) { return x.id; }).indexOf(element.id);
        if (i >= 0) {
          this.bultos[i] = result;
          this.dataSourceAdicionales = [...this.bultos];
          const PesoBultos = this.bultos.reduce((accumulator, object) => accumulator + object.peso_total, 0);
          this.paquetesForm.controls['peso_total'].setValue(this.paquetesForm.controls['peso'].value + PesoBultos);
          this.UpdateKgTotal(this.paquetesForm.controls['peso_total'].value);
        }
      } else {
        this.dataSourceAdicionales = [...this.bultos];
      }
    });
  }

  handleClientChange() {
    const userValue = this.paquetesForm.controls.usuario.value;
    if (userValue && userValue == 'SJO1000') {
      this.isSICSAAccount = true;
    }
  }
  private resetForm() {
    this.paquetesForm.reset();
    this.paquetesForm.setValue(this.paquete);
  }

  private replaceBlankWithNull() {
    this.paquetesForm.value.tracking == '' ? this.paquetesForm.controls.tracking.setValue(null) : '';
    this.paquetesForm.value.codigo_promocional == '' ? this.paquetesForm.controls.codigo_promocional.setValue(null) : '';
    this.paquetesForm.value.descripcion == '' ? this.paquetesForm.controls.descripcion.setValue(null) : '';
    this.paquetesForm.value.remitente == '' ? this.paquetesForm.controls.remitente.setValue(null) : '';
    this.paquetesForm.value.referencia == '' ? this.paquetesForm.controls.referencia.setValue(null) : '';
    this.paquetesForm.value.precio == '' ? this.paquetesForm.controls.precio.setValue(null) : '';
    this.paquetesForm.value.destinatario == '' ? this.paquetesForm.controls.destinatario.setValue(null) : '';
    this.paquetesForm.value.medida == '' ? this.paquetesForm.controls.medida.setValue(null) : '';
    this.paquetesForm.value.observacion == '' ? this.paquetesForm.controls.observacion.setValue(null) : '';
    this.paquetesForm.value.largo == '' ? this.paquetesForm.controls.largo.setValue(null) : '';
    this.paquetesForm.value.alto == '' ? this.paquetesForm.controls.alto.setValue(null) : '';
    this.paquetesForm.value.ancho == '' ? this.paquetesForm.controls.ancho.setValue(null) : '';
    this.paquetesForm.value.peso == '' ? this.paquetesForm.controls.peso.setValue(null) : '';
    this.paquetesForm.value.peso_volumetrico == '' ? this.paquetesForm.controls.peso_volumetrico.setValue(null) : 0;
    this.paquetesForm.value.peso_total == '' ? this.paquetesForm.controls.peso_total.setValue(null) : '';
    this.paquetesForm.value.factura_1 == '' ? this.paquetesForm.controls.factura_1.setValue(null) : '';
    this.paquetesForm.value.factura_2 == '' ? this.paquetesForm.controls.factura_2.setValue(null) : '';
    this.paquetesForm.value.factura_3 == '' ? this.paquetesForm.controls.factura_3.setValue(null) : '';
    this.paquetesForm.value.imagen == '' ? this.paquetesForm.controls.imagen.setValue(null) : '';
  }

  private _filterCliente(value: any, filter, options: any) {
    if (!filter) {
      return options;
    }
    let filterValue: string;
    value.name ? filterValue = value.name.toLowerCase() : filterValue = filter;
    return options.filter(user => user.name.toLowerCase().includes(filterValue.toLocaleLowerCase()));
  }

  private _filter(value: any) {
    const filterValue = value.toLowerCase();
    return this.common_stores.filter(option => option.toLowerCase().includes(filterValue));
  }


}
