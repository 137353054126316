import { Injectable } from '@angular/core';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { DatePipe } from '@angular/common';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
@Injectable({
    providedIn: 'root',
  })
export class PdfService {
    constructor(public usuariosService: UsuariosService,private datePipe: DatePipe,) { }



    generateInvoicePdf(paquetes: any, fileName: string): void {
    const pdf = new jsPDF('portrait', 'mm'); // A4, portrait, mm size page of PDF

    paquetes.forEach((paquete, index) => {
      if (index > 0) {
        pdf.addPage();
      }

    const packageCif = paquete.factura && paquete.factura.cif ? paquete.factura.cif : '--';
    const name = paquete.destinatario || this.usuariosService.get_fullname(paquete.usuario);

    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();

      pdf.setFont('Times', 'bold');
      pdf.setFontSize(18);
      pdf.text(paquete.remitente, 10, 25);
      pdf.setFont('Times', 'normal');
      pdf.text('Shipping Department', 10, 35);
      pdf.text('CONSIGNATARIO', 10, 50);
      pdf.text(name, 10, 60);
      pdf.text('San José, Costa Rica', 10, 70);

      pdf.setFontSize(18);
      pdf.setFont('Helvetica', 'normal');

      pdf.setFontSize(16);

      pdf.setFontSize(28);
      pdf.setFont('Helvetica', 'bold');
      pdf.setTextColor(0, 0, 0);
      pdf.text('Factura', width - 52, 90);
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(18);
      pdf.setFont('Helvetica', 'normal');
      pdf.text('Factura # F'+ paquete.id, width - 70, 100, { maxWidth: 100 });
      pdf.text('Fecha: ' + this.datePipe.transform(new Date(Date.now()), 'dd-MM-yyyy'), width - 70, 110);

      // Datos de ejemplo para la tabla
      const tableColumn = ['Provedor', 'Factura', 'Consignatario', 'Vías','Peso LBS', 'Cantidad'];
      const tableRows = [
        [paquete.remitente,
        'F'+ paquete.id,
        name, 'Area',
        paquete.peso_total,
        paquete.paquete_contenedor.length > 0 ? paquete.paquete_contenedor.length : 1],
      ];

      // Agregar la tabla al PDF
      autoTable(pdf, {
        head: [tableColumn],
        body: tableRows,
        startY: 130, // Coordenada Y donde empieza la tabla
        theme: 'grid', // Aplicar bordes a las celdas
        headStyles: {
          fillColor: [211, 211, 211], // Sin color de fondo
          textColor: [0, 0, 0] // Color de texto negro
        },
        styles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 3,
          lineWidth: 0.5,
          halign: 'center',
        },
        // headStyles: { fillColor: [22, 160, 133] }, // Estilo de la cabecera
        margin: { top: 10 }
      });

      const tableColumn2 = ['CANTIDAD','DESCRIPCION', 'PRECIO UNIDAD', 'TOTAL'];
      const tableRows2 = [
        [1, paquete.descripcion,'$' + packageCif, '$' + packageCif],
      ];
      while (tableRows2.length < 5) {
        tableRows2.push(['', '', '', '']);
      }

      // Agregar la tabla al PDF
      autoTable(pdf,{
        head: [tableColumn2],
        body: tableRows2,
        startY: 160, // Coordenada Y donde empieza la tabla
        theme: 'grid', // Aplicar bordes a las celdas
        headStyles: {
          fillColor: [240, 240, 240], // Sin color de fondo
          textColor: [0, 0, 0] // Color de texto negro
        },
        styles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 3,
          lineWidth: 0.5,
          halign: 'center',
        },
        margin: { top: 10 }
      });
      pdf.setFontSize(14);
      pdf.setFont('Helvetica', 'bold');

      const pageWidth = pdf.internal.pageSize.getWidth();
      const text1 = 'Internacional Shipping Department';
      const text2 = 'GRACIAS POR SU COMPRA ';

      // Calcular la posición X para centrar el texto
      const text1X = (pageWidth - pdf.getTextWidth(text1)) / 2;
      const text2X = (pageWidth - pdf.getTextWidth(text2)) / 2;

      const lastTable = (pdf as any).lastAutoTable;

      pdf.text('Total: $' + packageCif,pageWidth - 45,lastTable.finalY + 10);

      // Agregar el texto centrado
      pdf.text(text1, text1X, lastTable.finalY + 30);
      pdf.text(text2, text2X, lastTable.finalY + 40);
    });

      // // Generated PDF
      pdf.autoPrint();
      pdf.save(fileName + '.pdf', { returnPromise: true });
    }
}
