import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { NgxSpinnerService } from 'ngx-spinner';
import { TarifaModalComponent } from 'src/app/modules/admin/components/tarifa-modal/tarifa-modal.component';
import { ConfirmarComponent } from 'src/app/modules/common/dialogs/confirmar/confirmar.component';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { GeneralInputComponent } from 'src/app/modules/common/dialogs/general-input/general-input.component';
import { AuthService } from 'src/app/services/auth.service';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { RutasService } from 'src/app/services/rutas.service';
import { TarifasService } from 'src/app/services/tarifas.service';

export interface Tarifas {
  id: number;
  min_amount: number;
  max_amount: number;
  amount: number;
  is_amount_fixed: boolean;
  tarifa: number;
}

export interface Variables {
  id: number;
  name: string;
  description: string;
  info: string;
  value: number;
  unit: string;
  is_amount_fixed: boolean;
  group: number;
  tarifa: number;
}

@Component({
  selector: 'app-tarifa',
  templateUrl: './tarifa.component.html',
  styleUrls: ['./tarifa.component.scss']
})
export class TarifaComponent implements OnInit {

  selectedPlan;

  dataSourceTarifas = new MatTableDataSource<any>();
  displayedColumnsTarifas: string[];

  dataSourceFlete = new MatTableDataSource<Tarifas>();
  displayedColumnsFlete: string[];

  dataSourceManejo = new MatTableDataSource<Tarifas>();
  displayedColumnsManejo: string[];

  dataSourceBodegaje = new MatTableDataSource<Tarifas>();
  displayedColumnsBodegaje: string[];

  dataSourceEmbalaje = new MatTableDataSource<Tarifas>();
  displayedColumnsEmbalaje: string[];

  dataSourceCorrespondencia = new MatTableDataSource<Tarifas>();
  displayedColumnsCorrespondencia: string[];

  dataSourceTransporteLocal = new MatTableDataSource<Tarifas>();
  displayedColumnsTransporteLocal: string[];

  selectionFlete = new SelectionModel<Tarifas>(true, []);
  selectionManejo = new SelectionModel<Tarifas>(true, []);
  selectionBodegaje = new SelectionModel<Tarifas>(true, []);
  selectionEmbalaje = new SelectionModel<Tarifas>(true, []);
  selectionCorrespondencia = new SelectionModel<Tarifas>(true, []);
  selectionTransporteLocal = new SelectionModel<Tarifas>(true, []);

  formTarifa = this.fb.group({
    // Necesarios para el modelo
    codigo: [null, [Validators.required]],
    nombre: [null, [Validators.required]],
    descripcion: [null, [Validators.required]],
    disponible: [true],
    exonerar_combustible: [null],
    exonerar_flete: [null],
    exonerar_manejos: [null],
    exonerar_seguro: [null],
    exonerar_warehouse: [],
  });



  variablesGenerales: Variables[] = [];
  variablesPermisos: Variables[] = [];
  tarifas: any = [];
  selectedTab = 'Flete Internacional';

  isNew = false;
  changeName = false;

  empresas: any;
  selectedEmpresa: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    public tarifasService: TarifasService,
    private fb: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private paquetesService: PaquetesService,
    private rutasService: RutasService,
    public authService: AuthService,
  ) { }

  public getErrorMessage(control) {

    if (control.hasError('exists')) {
      return 'Nombre en uso';
    }
    return '';

  }

  public validateDisplayedColumns() {
    const columns = ['min', 'max', 'monto', 'fijo'];
    if (this.authService.validateRolePermission('EDIT_FEE')) {
      columns.push('opciones');
    }
    this.displayedColumnsFlete = [...columns];
    this.displayedColumnsManejo = [...columns];
    this.displayedColumnsBodegaje = [...columns];
    this.displayedColumnsEmbalaje = [...columns];
    this.displayedColumnsCorrespondencia = [...columns];
    this.displayedColumnsTransporteLocal = [...columns];
  }


  checkEmail(_nombre, field) {

    this.changeName = true;
    if (this.formTarifa.controls[field].value) {

      const tarifa = this.tarifas.some((res) => res.nombre === this.formTarifa.controls.nombre.value.trim());
      if (tarifa) {
        this.formTarifa.controls[field].setErrors({ exists: true });
      } else {
        this.formTarifa.controls[field].setErrors(null);
      }

    }
  }

  public getTarifas() {

    this.paquetesService.getTarifas().subscribe((data: any) => {
      this.tarifas = data;
    });

  }

  public getEmpresas() {

    this.tarifasService.getEmpresas().subscribe((data: any) => {
      this.empresas = data;
      this.selectedEmpresa = this.empresas[0].id;
      //console.log(this.empresas);
    });

  }

  ngOnInit(): void {


    this.validateDisplayedColumns();

    this.activatedRoute.queryParams.subscribe(params => {
      this.getTarifas();
      this.getEmpresas();

      if (params.pack) {
        this.selectedPlan = params.pack;

        this.getTarifa();
        this.getTarifasFlete();
        this.getTarifasVariables();
      }

      if (params.new) {
        this.getTarifasFlete();
        this.getTarifasManejo();
        this.getTarifasBodegaje();
        this.getTarifasEmbalaje();
        this.getTarifasCorrespondencia();
        this.getTarifasTransporteLocal();
        this.getTarifasVariables();

        this.displayedColumnsFlete = ['min', 'max', 'monto', 'fijo'];
        this.displayedColumnsManejo = ['min', 'max', 'monto', 'fijo'];
        this.displayedColumnsBodegaje = ['min', 'max', 'monto', 'fijo'];
        this.displayedColumnsEmbalaje = ['min', 'max', 'monto', 'fijo'];
        this.displayedColumnsCorrespondencia = ['min', 'max', 'monto', 'fijo'];
        this.displayedColumnsTransporteLocal = ['min', 'max', 'monto', 'fijo'];

        this.isNew = params.new;

        if (this.isNew) {
          //console.log('Patch');
          setTimeout(() => {
            this.formTarifa.get('nombre').setValue('');
            this.formTarifa.get('descripcion').setValue('');
          }, 1000);

        }
      }
    });
  }

  onSelect(data: TabDirective): void {

    this.selectedTab = data.heading;

    if (data.heading === 'Flete Internacional') {
      this.getTarifasFlete();
    }

    if (data.heading === 'Tarifas de Manejo') {
      this.getTarifasManejo();
    }

    if (data.heading === 'Bodegaje') {
      this.getTarifasBodegaje();
    }

    if (data.heading === 'Embalaje') {
      this.getTarifasEmbalaje();
    }

    if (data.heading === 'Correspondencia') {
      this.getTarifasCorrespondencia();
    }

    if (data.heading === 'Transporte Local') {
      this.getTarifasTransporteLocal();
    }
  }

  getTarifa() {
    this.tarifasService.getTarifaByPlan(this.selectedPlan).subscribe((data: any) => {
      const items = [];
      items.push(data);
      this.dataSourceTarifas.data = items;

      this.formTarifa = this.fb.group({
        // Necesarios para el modelo
        codigo: [items[0].codigo, [Validators.required]],
        nombre: [items[0].nombre, [Validators.required]],
        descripcion: [items[0].descripcion, [Validators.required]],
        disponible: [true],
        exonerar_combustible: [items[0].exonerar_combustible],
        exonerar_flete: [items[0].exonerar_flete],
        exonerar_manejos: [items[0].exonerar_manejos],
        exonerar_seguro: [items[0].exonerar_seguro],
        exonerar_warehouse: [items[0].exonerar_warehouse],
      });

      //console.log(this.formTarifa);
    });
  }

  getTarifasFlete() {
    this.tarifasService.getTarifaFleteByPlan(this.selectedPlan).subscribe(
      (res: any) => {
        const tarifas: Tarifas[] = [];

        res.forEach(element => {
          tarifas.push(
            {
              id: element.id,
              min_amount: element.min_amount.toFixed(2),
              max_amount: element.max_amount !== -1 ? element.max_amount.toFixed(2) : element.min_amount.toFixed(2) + '+',
              amount: element.amount.toFixed(2),
              is_amount_fixed: element.is_amount_fixed,
              tarifa: element.tarifa
            }
          );
        });

        this.dataSourceFlete.data = tarifas;
      }
    );
  }

  getTarifasManejo() {
    this.tarifasService.getTarifaManejoByPlan(this.selectedPlan).subscribe(
      (res: any) => {
        const tarifas: Tarifas[] = [];

        res.forEach(element => {
          tarifas.push(
            {
              id: element.id,
              min_amount: element.min_amount.toFixed(2),
              max_amount: element.max_amount !== -1 ? element.max_amount.toFixed(2) : element.min_amount.toFixed(2) + '+',
              amount: element.amount.toFixed(2),
              is_amount_fixed: element.is_amount_fixed,
              tarifa: element.tarifa
            }
          );
        });

        this.dataSourceManejo.data = tarifas;
      }
    );
  }

  getTarifasBodegaje() {
    this.tarifasService.getTarifaBodegajeByPlan(this.selectedPlan).subscribe(
      (res: any) => {
        const tarifas: Tarifas[] = [];

        res.forEach(element => {
          tarifas.push(
            {
              id: element.id,
              min_amount: element.min_amount.toFixed(2),
              max_amount: element.max_amount !== -1 ? element.max_amount.toFixed(2) : element.min_amount.toFixed(2) + '+',
              amount: element.amount.toFixed(2),
              is_amount_fixed: element.is_amount_fixed,
              tarifa: element.tarifa
            }
          );
        });

        this.dataSourceBodegaje.data = tarifas;
      }
    );
  }

  getTarifasEmbalaje() {
    this.tarifasService.getTarifaEmbalajeByPlan(this.selectedPlan).subscribe(
      (res: any) => {
        const tarifas: Tarifas[] = [];

        res.forEach(element => {
          tarifas.push(
            {
              id: element.id,
              min_amount: element.min_amount.toFixed(2),
              max_amount: element.max_amount !== -1 ? element.max_amount.toFixed(2) : element.min_amount.toFixed(2) + '+',
              amount: element.amount.toFixed(2),
              is_amount_fixed: element.is_amount_fixed,
              tarifa: element.tarifa
            }
          );
        });

        this.dataSourceEmbalaje.data = tarifas;
      }
    );
  }

  getTarifasCorrespondencia() {
    this.tarifasService.getTarifaCorrespondenciaByPlan(this.selectedPlan).subscribe(
      (res: any) => {
        const tarifas: Tarifas[] = [];

        res.forEach(element => {
          tarifas.push(
            {
              id: element.id,
              min_amount: element.min_amount.toFixed(2),
              max_amount: element.max_amount !== -1 ? element.max_amount.toFixed(2) : element.min_amount.toFixed(2) + '+',
              amount: element.amount.toFixed(2),
              is_amount_fixed: element.is_amount_fixed,
              tarifa: element.tarifa
            }
          );
        });

        this.dataSourceCorrespondencia.data = tarifas;
      }
    );
  }

  getTarifasTransporteLocal() {
    this.tarifasService.getTarifaTransporteByPlan(this.selectedPlan, this.selectedEmpresa).subscribe(
      (res: any) => {
        const tarifas: Tarifas[] = [];

        res.forEach(element => {
          tarifas.push(
            {
              id: element.id,
              min_amount: element.min_amount.toFixed(2),
              max_amount: element.max_amount !== -1 ? element.max_amount.toFixed(2) : -1,
              amount: element.amount !== -1 ? element.amount.toFixed(2) : -1,
              is_amount_fixed: element.is_amount_fixed,
              tarifa: element.tarifa
            }
          );
        });

        this.dataSourceTransporteLocal.data = tarifas;
      }
    );
  }

  getTarifasVariables() {
    this.tarifasService.getTarifasVariables(this.selectedPlan).subscribe((res: any) => {

      const variablesG: Variables[] = [];
      const variablesP: Variables[] = [];
      res.forEach(element => {

        const item = {
          id: element.id,
          name: element.name,
          description: element.description,
          info: element.info,
          value: element.value.toFixed(2),
          is_amount_fixed: element.is_amount_fixed,
          unit: element.unit,
          group: element.group,
          tarifa: element.tarifa
        };

        if (item.group === 0) {
          variablesG.push(item);
        } else {
          variablesP.push(item);
        }
      });

      this.variablesGenerales = variablesG;
      this.variablesPermisos = variablesP;
    });
  }

  saveTarifa() {
    const data = this.formTarifa.value;

    if (this.isNew) {
      if (!this.formTarifa.valid || this.changeName === false) {
        this.formTarifa.controls.nombre.setErrors({ exists: true });
        this.openDialog('Nombre de tarifa ya existente, ingrese uno nuevo');
        return;
      }

      //console.log('if');
      const variable = [];
      const flete = [];
      const manejo = [];
      const bodegaje = [];
      const embalaje = [];
      const correspondencia = [];
      const transporte = [];

      this.tarifasService.postTarifa(data).subscribe((res: any) => {
        //console.log(res);
        const tarifa = res.id;

        this.dataSourceFlete.data.forEach(element => {
          element.tarifa = res.id;
          flete.push(element);
        });
        //console.log('flete', flete);

        this.dataSourceManejo.data.forEach(element => {
          element.tarifa = res.id;
          manejo.push(element);
        });
        //console.log('manejo', manejo);

        this.dataSourceBodegaje.data.forEach(element => {
          element.tarifa = res.id;
          bodegaje.push(element);
        });
        //console.log(bodegaje);

        this.dataSourceEmbalaje.data.forEach(element => {
          element.tarifa = res.id;
          embalaje.push(element);
        });
        //console.log(embalaje);

        this.dataSourceCorrespondencia.data.forEach(element => {
          element.tarifa = res.id;
          correspondencia.push(element);
        });
        //console.log(correspondencia);

        this.dataSourceTransporteLocal.data.forEach(element => {
          element.tarifa = res.id;
          element['encomienda'] = this.selectedEmpresa;
          transporte.push(element);
        });
        //console.log(transporte);

        this.variablesGenerales.forEach(element => {
          element.tarifa = res.id;
          variable.push(element);
        });

        this.variablesPermisos.forEach(element => {
          element.tarifa = res.id;
          variable.push(element);
        });
        //console.log(variable);

        flete.forEach(item => {
          this.tarifasService.postTarifaFlete(item).subscribe((_res: any) => { });
        });

        manejo.forEach(item => {
          this.tarifasService.postTarifaManejo(item).subscribe((_res: any) => { });
        });

        bodegaje.forEach(item => {
          this.tarifasService.postTarifaBodegaje(item).subscribe((_res: any) => { });
        });

        embalaje.forEach(item => {
          this.tarifasService.postTarifaEmbalaje(item).subscribe((_res: any) => { });
        });

        correspondencia.forEach(item => {
          this.tarifasService.postTarifaCorrespondencia(item).subscribe((_res: any) => { });
        });

        transporte.forEach(item => {
          this.tarifasService.postTarifaTransporte(item).subscribe((_res: any) => { });
        });

        this.tarifasService.postTarifaVariable(variable).subscribe((_res: any) => {
          this.openDialog('Tarifa Creada');
          this.router.navigate(['administracion/tarifas'], { queryParams: { pack: tarifa, new: false } });
        });

        /*this.tarifasService.postTarifaFlete(flete).subscribe((res: any) => {
          this.tarifasService.postTarifaManejo(manejo).subscribe((res: any) => {
            this.tarifasService.postTarifaBodegaje(bodegaje).subscribe((res: any) => {
              this.tarifasService.postTarifaTransporte(transporte).subscribe((res: any) => {
                this.tarifasService.postTarifaVariable(variable).subscribe((res: any) => {
                  this.openDialog('Tarifa Creada');
                  this.router.navigate(['administracion/tarifas/tarifa'], { queryParams: { pack: tarifa, new: false } });
                });
              });
            });
          });
        });*/
      });
    } else {
      //console.log('else')
      this.tarifasService.updateTarifa(this.selectedPlan, data).subscribe((_res: any) => {
        const variables = [];

        this.variablesGenerales.forEach(element => {
          variables.push(element);
        });

        this.variablesPermisos.forEach(element => {
          variables.push(element);
        });

        this.tarifasService.updateTarifaVariable(this.selectedPlan, variables).subscribe((_res: any) => {
          this.openDialog('Tarifa Actualizada');
        });

      });
    }
  }



  public openTarifaDialog(data, tipo, values) {

    if (data === '') {
      data = {
        fijo: false,
        tipo,
        edit: false,
        tarifa: this.selectedPlan,
        empresa: this.selectedEmpresa,
        values: values.data
      };
    } else {
      data.tipo = tipo;
      data.values = values.data;
      data.edit = true;
    }

    //console.log(data);

    const dialogRef = this.dialog.open(TarifaModalComponent, {
      data,
    });

    dialogRef.afterClosed().subscribe(value => {
      //console.log(value);

      if (this.selectedTab === 'Flete Internacional') {
        this.getTarifasFlete();
      }

      if (this.selectedTab === 'Tarifas de Manejo') {
        this.getTarifasManejo();
      }

      if (this.selectedTab === 'Bodegaje') {
        this.getTarifasBodegaje();
      }

      if (this.selectedTab === 'Embalaje') {
        this.getTarifasEmbalaje();
      }

      if (this.selectedTab === 'Correspondencia') {
        this.getTarifasCorrespondencia();
      }

      if (this.selectedTab === 'Transporte Local') {
        this.getTarifasTransporteLocal();
      }
    });

  }

  deleteTarifa(id, tipo) {

    //console.log(id, tipo);
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      // eslint-disable-next-line max-len
      data: { message: 'Esta operación es definitiva y no puede reversarse. ¿Confirma que desea eliminar esta tarifa?.' },
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        if (tipo === 'flete') {
          this.tarifasService.deleteTarifaFlete(id).subscribe((_res: any) => {
            this.getTarifasFlete();
            this.openDialog('Tarifa eliminada');
          });
        }

        if (tipo === 'manejo') {
          this.tarifasService.deleteTarifaManejo(id).subscribe((_res: any) => {
            this.getTarifasManejo();
            this.openDialog('Tarifa eliminada');
          });
        }

        if (tipo === 'bodegaje') {
          this.tarifasService.deleteTarifaBodegaje(id).subscribe((_res: any) => {
            this.getTarifasBodegaje();
            this.openDialog('Tarifa eliminada');
          });
        }

        if (tipo === 'embalaje') {
          this.tarifasService.deleteTarifaEmbalaje(id).subscribe((_res: any) => {
            this.getTarifasEmbalaje();
            this.openDialog('Tarifa eliminada');
          });
        }

        if (tipo === 'correspondencia') {
          this.tarifasService.deleteTarifaCorrespondencia(id).subscribe((_res: any) => {
            this.getTarifasCorrespondencia();
            this.openDialog('Tarifa eliminada');
          });
        }

        if (tipo === 'transporte') {
          this.tarifasService.deleteTarifaTransporte(id).subscribe((_res: any) => {
            this.getTarifasTransporteLocal();
            this.openDialog('Tarifa eliminada');
          });
        }
      }
    });
  }

  cambiarEmpresa(value) {
    //console.log(value);

    if (value == -1) {
      const dialogRef = this.dialog.open(GeneralInputComponent, {
        data: { title: 'Empresa', value: '', type: 'general' },
        height: '250px',
        width: '500px',
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != null) {
          const data = {
            nombre: result.value,
            encomienda: true,
          };

          this.rutasService.post(data).subscribe(
            (res: any) => {
              this.openDialog('Empresa Creada Exitosamente');
              this.getEmpresas();
            });
        }
      });

    } else {
      this.selectedEmpresa = value;
      this.getTarifasTransporteLocal();
    }

  }

  private openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }
}
