import { environment } from '../../environments/environment';
import { BG_COLOR_DICT } from 'src/app/core';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ColorService {

  get bgColor(): string {
    return BG_COLOR_DICT[environment.type];
  }

}
