import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EntregasService {
  public deliverList;
  public selectedPackages: any = [];

  private urlEntregas = `${environment.apiUrl}/entregas/`;
  private urlEncomiendas = `${environment.apiUrl}/encomiendas/`;
  constructor(
    private http: HttpClient
  ) { }

  get(page) {
    return this.http.get(this.urlEntregas + '?per_page=10&page_no=' + page);
  }

  getSimple(page) {
    return this.http.get(`${this.urlEntregas}simple?per_page=10&page_no=${page}`);
  }

  getEntregas(id) {
    return this.http.get(this.urlEntregas + id);
  }

  post(data) {
    return this.http.post(this.urlEntregas, data);
  }

  put(id, data) {
    return this.http.put(this.urlEntregas + id, data);
  }

  setDelivered(id, data) {
    return this.http.put(this.urlEntregas + 'entregado/' + id, data);
  }

  getSummary(date) {
    return this.http.get(this.urlEntregas + 'resumen/' + date);
  }

  getConductorId(id) {
    return this.http.get(this.urlEntregas + 'conductor/' + id);
  }

  transferirPaquetes(data) {
    return this.http.post(this.urlEntregas + 'transferir', data);
  }

  setEncomiendaCorreos(data) {
    return this.http.post(this.urlEncomiendas + 'envio', data);
  }

  getTarifaEncomienda(data) {
    return this.http.post(this.urlEncomiendas + 'tarifa', data);
  }
}
