import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WebcamImage } from 'ngx-webcam';

export interface DialogData {
  title: string;
  image: any;
}

@Component({
  selector: 'app-camera-modal',
  templateUrl: './camera-modal.component.html',
  styleUrls: ['./camera-modal.component.scss']
})
export class CameraModalComponent implements OnInit {
  webcamImage: WebcamImage = null;

  constructor(
    public dialogRef: MatDialogRef<CameraModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
  }

  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
    //console.log(this.webcamImage)
  }

  public onYesClick = (): void => {
    this.data.image = this.webcamImage;
    this.dialogRef.close(this.data);
  };

  public onNoClick = (): void => {
    this.dialogRef.close();
  };
}
