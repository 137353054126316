<div class="calculadoraBx" style="text-align: center;">
  <div class="containerx">
    <form class="row " [formGroup]="control" (ngSubmit)="Calcular()">
      <div class="container-example" fxLayout="column" fxLayoutGap="10px"
        style="margin-left: auto;margin-right: auto; width:595px; box-shadow: 0px 8px 25px rgba(0, 148, 163, 0.12);">

        <div style="background-color: #0094a3; border-radius: 5px 5px 0px 0px;">
          <p style="padding-top:0;text-align: center;color:white;  font-size: 20px; font-family: 'Varela Round'; line-height: 22px; font-size: 18px; font-style: normal; height: 50px; padding-top: 15px;"
            *ngIf="!calculo"> {{'Calculadora' | translate}} </p>
          <p style="padding-top:0;text-align: center;color:white;  font-size: 20px; font-family: 'Varela Round'; line-height: 22px; font-size: 18px; font-style: normal; height: 50px; padding-top: 15px;"
            *ngIf="calculo"> {{'Resultados' | translate}} </p>
        </div>

        <div class="pt-2">
          <p class="px-2" style="color: orange; font-family: 'Varela Round'; font-weight: bold; " *ngIf="!calculo">
            {{'Obtenga tarifa premium si su paquete ingresa a bodega Miami en embalaje bolsa/sobre' | translate}}
          </p>
        </div>

        <div class="container" fxLayout="column" fxLayoutGap="5px" style="padding: 32px 105px 41px;" *ngIf="!calculo">
          <label class="label" *ngIf="!calculo">{{'Tipo de Producto' | translate}}</label>
          <mat-form-field appearance="outline" class="form-field" *ngIf="!calculo">
            <input (ngModelChange)="valueChange($event)" name="product-type" class="input-field"
              placeholder="{{'Seleccionar Producto' | translate}}" matInput formControlName="producto" [matAutocomplete]="auto">

            <mat-autocomplete (optionSelected)='selected($event.option.value)' #auto="matAutocomplete"
              [displayWith]="displayFn">
              <mat-option *ngFor="let productType of filteredOptions | async " [value]="productType">
                <span> {{ productType.viewValue | translate | titlecase }} ({{productType.value}}%)</span><br>
                <span style="color: orange; font-size: 11px;"> {{ productType.warning | translate}} </span>
              </mat-option>
            </mat-autocomplete>
            <label style="color: orange;font-size: 11px; margin-top: 20px" class="label" *ngIf="warning!=''"> {{ warning | translate}}</label>
          </mat-form-field>

          <label style="margin-top: 15px !important" class="label-top" *ngIf="!calculo">{{'Precio del producto' | translate}}</label>
          <mat-form-field appearance="outline" class="form-field" *ngIf="!calculo">
            <input matInput formControlName="precio" class="input-field" numeric decimals="2">
            <mat-label class="prefix" matPrefix>USD $</mat-label>
          </mat-form-field>

          <div class="container">
            <div class="row">

              <div class="col-6 pl-0">
                <label class="label" *ngIf="!calculo">{{'Peso en Lbs' | translate}}</label>
                <mat-form-field style="width: 100%" appearance="outline" class="form-field" *ngIf="!calculo">
                  <input matInput placeholder="" type="number" formControlName="libra" numeric decimals="2"
                    (keyup)="UpdateKg($event.target.value)" (change)="UpdateKg($event.target.value)"
                    class="input-field">
                </mat-form-field>
              </div>

              <div class="col-6 pr-0">
                <label class="label" *ngIf="!calculo">{{'Peso en Kgs' | translate}}</label>
                <mat-form-field style="width: 100%" appearance="outline" class="form-field" *ngIf="!calculo">
                  <input matInput placeholder="" type="number" formControlName="kilo" numeric decimals="2"
                    (keyup)="UpdateLb($event.target.value)" (change)="UpdateLb($event.target.value)"
                    class="input-field">
                </mat-form-field>
              </div>

            </div>
          </div>

          <div *ngIf="tamanoOn">
            <div class="row">
              <div class="col-12 col-md-4" *ngIf="!calculo">
                <label class="label">{{'Alto' | translate}}</label>
                <mat-form-field style="width: 120px" appearance="outline">
                  <input matInput placeholder="" type="number" formControlName="alto" class="input-field" numeric
                    decimals="0">
                  <mat-label matSuffix>{{'Pulgadas' | translate}}</mat-label>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-4" *ngIf="!calculo">
                <label class="label">{{'Ancho' | translate}}</label>
                <mat-form-field style="width: 120px" appearance="outline">
                  <input matInput placeholder="" type="number" formControlName="ancho" class="input-field" numeric
                    decimals="0">
                  <mat-label matSuffix>{{'Pulgadas' | translate}}</mat-label>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-4" *ngIf="!calculo">
                <label class="label">{{'Largo' | translate}}</label>
                <mat-form-field style="width: 120px;" appearance="outline">
                  <input matInput placeholder="" type="number" formControlName="largo" class="input-field" numeric
                    decimals="0">
                  <mat-label matSuffix>{{'Pulgadas' | translate}}</mat-label>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="text-center">
            <button mat-flat-button color="primary" [disabled]="!control.valid" type="submit" *ngIf="!calculo"
              class="button-calcular">{{'Calcular' | translate}}</button>
          </div>
        </div>

        <!-- RESULTADOS -->

        <div class="container text-left" style="padding: 14 px;" id="pdfTable" #pdfTable *ngIf="calculo">

          <div style="width: 50%; display: inline-block;">
            <img [src]="getLogo()" alt="logo" class="logo" width="250">
          </div>

          <div class="text-center p-4" style="color: #004481;">
            <h3> {{'Su resultado' |  translate}} </h3>
          </div>

          <table id="resultados" mat-table [dataSource]="transactions">
            <ng-container matColumnDef="rubro">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> <span style="color: #004481">{{'Rubro' | translate}}</span> </th>
              <td mat-cell class="pr-pl-20" *matCellDef="let transaction"> {{transaction.rubro}} </td>
              <td mat-footer-cell *matFooterCellDef>
                <p>{{'TOTAL' | translate}}</p>
                <!-- <p class="total-style" *ngIf=" exonerationchargeA > 0 || exonerationchargeM > 0 ">Total con Exoneración </p> -->
              </td>
            </ng-container>

            <ng-container matColumnDef="aereo">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> <span
                  style="line-height: 24px; color: #004481">{{'SuperRápido (Aéreo)' | translate}}</span> </th>
              <td mat-cell class="pr-pl-20" *matCellDef="let transaction">
                <span *ngIf="isNumber(transaction.aereo)">{{transaction.aereo | currency}}</span>
                <span *ngIf="!isNumber(transaction.aereo)">{{transaction.aereo}}</span>
              </td>
              <td mat-footer-cell *matFooterCellDef>
                <p>{{TotalAereo | currency}}</p>
                <!-- <p class="pt-4 total-style" *ngIf="TotalAereoExonerado !== 0 && exonerationchargeA > 0 ">{{TotalAereoExonerado | currency}}</p>
                <p class="pt-4 total-style" *ngIf="exonerationchargeA == 0 ">No aplica</p> -->
              </td>
            </ng-container>

            <ng-container matColumnDef="maritimo">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef><span
                  style="line-height: 24px; color: #004481">{{'SuperEconómico (Marítimo)' | translate}}</span> </th>
              <td mat-cell class="pr-pl-20" *matCellDef="let transaction" [ngClass]="{'bb-none': TotalMaritimo === 0}">

                <div *ngIf="message_m == ''; else elseTd">
                  <span *ngIf="isNumber(transaction.maritimo)">{{transaction.maritimo | currency}}</span>
                  <span *ngIf="!isNumber(transaction.maritimo)">{{transaction.maritimo}}</span>
                </div>
                <ng-template #elseTd>
                  <span>{{message_m | translate}}</span>
                </ng-template>
              </td>
              <td mat-footer-cell *matFooterCellDef>
                <p *ngIf="TotalMaritimo !== 0">{{TotalMaritimo | currency}}</p>
                <!--                 <p class="pt-4 total-style" *ngIf="TotalMaritimoExonerado !== 0 && exonerationchargeM > 0 ">{{TotalMaritimoExonerado | currency}}</p>
                <p class="pt-4 total-style" *ngIf="exonerationchargeM == 0 ">No aplica</p> -->
              </td>
            </ng-container>

            <ng-container matColumnDef="disclaimer">
              <td mat-footer-cell *matFooterCellDef colspan="4">
                <p class="info-p">
                  {{'Este cálculo está basado en un valor de $' | translate}}{{control.value.precio}}{{', un peso de ' | translate}}
                  {{control.value.libra}}lbs.

                  <span
                    *ngIf="toNumber(control.value.alto) > 0 && toNumber(control.value.ancho) > 0  && toNumber(control.value.largo) > 0">
                    {{', dimensiones de ' | translate }} {{control.value.alto}} {{'pulg. de alto,' | translate}} {{control.value.ancho}} {{'pulg. de ancho' | translate}},
                    {{control.value.largo}} {{'pulg. de largo' | translate}}
                  </span>

                  {{' y un arancel de ' | translate}} {{control.value.producto.value}}%.
                </p>

                <p class="info-p">{{'Artículos como comida y bebidas, maquillaje, medicamentos, perfumes, cremas,
                  cuchillos, armas, entre otros requieren de un proceso adicional. Consulte antes de importar.' | translate}}
                </p>

              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="['disclaimer']" class="second-footer-row"></tr>
          </table>
        </div>

        <div class="row" *ngIf="calculo">
          <div class="col-6">
            <button mat-flat-button color="secondary" (click)="downloadAsPDF()" class="button-consultar">
              {{'Exportar Cálculo' | translate}}
            </button>
          </div>

          <div class="col-6">
            <button mat-flat-button color="primary" (click)="resetCalc()" class="button-consultar">
              {{'Nuevo Cálculo' | translate}}
            </button>
          </div>

        </div>

      </div>
    </form>
  </div>
</div>
