<div class="p-3 content" fxLayout="column">
  <div class="content-title">
    <p class="title">Insertando nuevos datos</p>
  </div>

  <div [formGroup]="form" fxLayout="row wrap" fxLayoutGap="20px" class="pt-3 mb-3"
    style="display: flex; align-items: center;">

    <mat-form-field appearance="outline">
      <mat-label>Min</mat-label>
      <input matInput placeholder="Min" formControlName="min_amount" type="number" (blur)="checkRange('min_amount')">
      <mat-error *ngIf="form.controls['min_amount'].errors">
        {{getErrorMessage(form.controls['min_amount'])}}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Max</mat-label>
      <input matInput placeholder="Max" formControlName="max_amount" type="number" (blur)="checkRange('max_amount')">
      <mat-error *ngIf="form.controls['max_amount'].errors">
        {{getErrorMessage(form.controls['max_amount'])}}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Monto</mat-label>
      <input matInput placeholder="Monto" formControlName="amount" type="number">
      <mat-error *ngIf="form.controls['amount'].errors">
        {{getErrorMessage(form.controls['amount'])}}
      </mat-error>
    </mat-form-field>

    <mat-checkbox formControlName="is_amount_fixed">Monto Fijo</mat-checkbox>
    <mat-error *ngIf="form.controls['is_amount_fixed'].errors">
      {{getErrorMessage(form.controls['is_amount_fixed'])}}
    </mat-error>
  </div>

</div>

<div class="p-2" mat-dialog-actions style="display: flex; justify-content: space-between;">
  <button mat-flat-button color="primary" class="button1" (click)="onNoClick()">Cancelar</button>
  <button mat-flat-button color="primary" class="button1" (click)="save()" [disabled]="!form.valid">Aceptar</button>
</div>