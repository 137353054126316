/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable no-shadow */
import { TarifasService } from './../../../../../../services/tarifas.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';

@Component({
  selector: 'app-alianza',
  templateUrl: './alianza.component.html',
  styleUrls: ['./alianza.component.scss']
})
export class AlianzaComponent implements OnInit {

  alianzaId;

  alianzas;
  editMode: boolean;

  formAlianza = this.fb.group({
    nombre: ['', Validators.required],
    descripcion: [''],
    tarifaA: [''],
    tarifaM: [''],
    prefijo: ['', Validators.required],
    domain: ['', Validators.required],
    status: ['1'],
    vendedor: [''],
  });

  tarifasAereas;
  tarifasMaritimas;
  vendedores;

  constructor(
    public usuariosService: UsuariosService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private tarifasService: TarifasService
  ) { }

  ngOnInit(): void {

    this.getTarifas();
    this.getVendedores();

    this.usuariosService.getAlianzasAll().subscribe((res) => {
      this.alianzas = res;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.alianza) {
        this.getAlianza(params.alianza);
        this.editMode = true;
        this.alianzaId = params.alianza;
      } else {
        this.editMode = false;
      }
    });
  }

  getAlianza(id) {
    this.usuariosService.getAlianza(id).subscribe((res: any) => {
      this.formAlianza.patchValue({
        nombre: res.nombre,
        descripcion: res.descripcion,
        tarifaA: res.tarifaA,
        tarifaM: res.tarifaM,
        prefijo: res.prefijo,
        domain: res.domain,
        status: res.status ? '1' : '0',
        vendedor: res.vendedor
      });
    });

  }

  checkField(field) {

    if (this.formAlianza.controls[field].value) {

      const tarifa = this.alianzas.some((res) => res[field] === this.formAlianza.controls[field].value.trim());
      if (tarifa) {
        this.formAlianza.controls[field].setErrors({ exists: true });
      } else {
        this.formAlianza.controls[field].setErrors(null);
      }

    }
  }

  public getErrorMessage(control) {
    if (control.hasError('exists')) {
      return 'Valor en uso';
    }
    return '';
  }

  getTipoTarifaA(value) {
    if (this.tarifasAereas) {
      const item = this.tarifasAereas.filter(function (item) {
        return item.id === value;
      });
      if (item[0] !== undefined && item[0] != null) {
        return item[0].nombre;
      }
    }
    return '-';
  }

  getTipoTarifaM(value) {
    if (this.tarifasMaritimas) {
      const item = this.tarifasMaritimas.filter(function (item) {
        return item.id === value;
      });
      if (item[0] !== undefined && item[0] != null) {
        return item[0].nombre;
      }
    }
    return '-';
  }

  getVendedor(value) {
    if (this.vendedores) {
      const item = this.vendedores.filter(function (item) {
        return item.id === value;
      });
      if (item[0] !== undefined && item[0] != null) {
        return this.usuariosService.get_fullname(item[0]);
      }
    }
    return '-';
  }

  getTarifas() {
    this.tarifasService.getByCodigo('A').subscribe(data => {
      this.tarifasAereas = data;
    });

    this.tarifasService.getByCodigo('M').subscribe(data => {
      this.tarifasMaritimas = data;
    });
  }

  getVendedores() {
    this.usuariosService.get_seller_users().subscribe((data: any) => {
      //console.log(data);
      this.vendedores = data;
    });
  }

  saveAlianza() {

    if (this.formAlianza.valid) {
      if (this.formAlianza.value.vendedor === '') {
        this.formAlianza.value.vendedor = null;
      }

      if (this.editMode === false) {
        this.usuariosService.postAlianzas(this.formAlianza.value).subscribe((res) => {
          this.openDialog('Alianza creada');
          this.router.navigate(['/admin/administracion/alianzas']);
        });
      } else {
        this.usuariosService.putAlianzas(this.alianzaId, this.formAlianza.value).subscribe((res) => {
          this.openDialog('Alianza Actualizada');
        });
      }
    }
  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });


    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }
}
