<h2 class="h2-title p-4">Tarifas</h2>

<div class="content" fxLayout="column">
  <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones justify-content-center;">
    <div class="row" style="width: 100%;">
      <div class="col-sm botones-opciones">
        <!-- <button mat-button [routerLink]="['/admin/administracion']"
        style="padding: 0; padding-right: 10px; color: #0094a3; float: left;">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>-->
      </div>
      <div class="col-sm text-right botones-opciones">
        <button *ngIf = "!authService.isOperacionesCostaRica2" (click)="crearTarifa()" mat-button>AGREGAR TARIFA</button>
        <span class="texto-encontrados">{{length}} Tarifas encontrados</span>
      </div>

    </div>


    <!-- <div class="botones-opciones" (click)="crearTarifa()">
      <button mat-button>AGREGAR TARIFA</button>
    </div>-->

    <!--<div class="content-encontrados">-->
    <!-- <span class="texto-encontrados">{{length}} Tarifas encontrados</span>-->
    <!-- <button mat-flat-button color="primary" class="button-calcular">Editar</button> -->
    <!-- </div>-->
  </div>

  <mat-divider></mat-divider>

  <div class="content-table mr-4 ml-4 mb-4">
    <table mat-table [dataSource]="dataSource.data" matSort matSortDisabled>
      <ng-container matColumnDef="id">
        <th mat-header-cell class="pr-pl-20 text-center" *matHeaderCellDef mat-sort-header> Tarifa </th>
        <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> <a
            [routerLink]="['/admin/administracion/tarifas/tarifa']"
            [queryParams]="{pack: element.id}">{{element.id}}</a></td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Nombre </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.nombre}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Descripcion / Notas </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.descripcion}} </td>
      </ng-container>

      <ng-container matColumnDef="usuario">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Disponible para usuarios finales </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          <mat-checkbox></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="flete">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Exonerar Flete </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          <mat-checkbox></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="warehouse">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Exonerar Warehouse </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          <mat-checkbox></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="manejos">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Exonerar Manejos </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          <mat-checkbox></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="seguro">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Exonerar Seguro </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          <mat-checkbox></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="combustible">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Exonerar Combustible </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          <mat-checkbox></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="disponible">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Disponible Para Sucursal </th>
        <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
          <mat-checkbox [checked]="element.disponible" disabled></mat-checkbox>
        </td>
      </ng-container>
      <!--acciones-->
      <ng-container matColumnDef="acciones">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Acciones</th>
        <td mat-cell *matCellDef="let element" class="action-link" style="text-align: center;">
          <mat-icon *ngIf="authService.validateRolePermission('EDIT_RATE')" style="cursor: pointer;" (click)="deleteTarifa(element.id)">delete</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }"></tr>
    </table>
  </div>
</div>