import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mainPackageGuidePipe' })
export class MainPackageGuidePipe implements PipeTransform {

  transform(obj: any): string {
    if (obj) {
        return obj.paquete_principal[0].numero_de_guia;
    }
  }
}

@Pipe({ name: 'mainPackageIdPipe' })
export class MainPackageIdPipe implements PipeTransform {

  transform(obj: any): string {
    if (obj) {
        return obj.paquete_principal[0].id;
    }
  }
}