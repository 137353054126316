<div class="p-3 content" style="min-width: 80vw !important" fxLayout="column">

    <div class="content-title">
      <p class="title">Correos Masivos</p>
    </div>
  
    <form [formGroup]="form" class="inputs-form mt-5 pt-4 mb-4 inputs-template">
        <div class="mb-2">
            <label class="label">Seleccione el template</label><br>
            <ng-select [items]="mailgun_template" bindLabel="name" bindValue="name" formControlName="template" placeholder="Seleccione un template" [searchable]="false"></ng-select>
        </div>

        <div>
            <label class="label">Asunto</label><br>
            <mat-form-field appearance="outline">
                <input matInput formControlName="subject" placeholder="Asunto">
            </mat-form-field>
        </div>

        <div class="mb-2">
            <label class="label">Seleccione la lista de destinatarios</label><br>
            <ng-select [items]="receiver_list" bindLabel="name" bindValue="address" formControlName="mailList" placeholder="Seleccione un template" [searchable]="false"></ng-select>
        </div>
    </form>
    <div>
        <button class="mt-2" style="float: right;" mat-flat-button color="primary" (click)="sendMassEmail()">Enviar</button>
    </div>
</div>