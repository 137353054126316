import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaquetesService } from 'src/app/services/paquetes.service';

export interface DialogData {
  title: string;
  packageIds: number[];
}

@Component({
  selector: 'app-add-list-details',
  templateUrl: './add-list-details.component.html',
  styleUrls: ['./add-list-details.component.scss']
})

export class AddListDetailsComponent {
  packageIds: number[];
  detail: string;
  partida: string;
  options: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddListDetailsComponent>,
    private paquetesService: PaquetesService,) {

  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onConfirmClick(): void {
    this.paquetesService.addLineDetails(this.data.packageIds, this.detail,this.partida).subscribe(
      data => {
        this.dialogRef.close(data);
      },
      error => {
        this.dialogRef.close(error);
      }
    );
  }

  getItemsSearch(value) {
    if (value.length > 3) {
      this.paquetesService.getItemsSearch(value).subscribe(data => {
        this.options = data;
      });
    }
  }
}
