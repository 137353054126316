/* eslint-disable no-shadow */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { EmailsService } from 'src/app/services/emails.service';

export interface DialogData {
  templateid: number;
  embarqueid: number;
  paquetes: any;
}

@Component({
  selector: 'app-email-modal',
  templateUrl: './emaileditor-modal.component.html',
  styleUrls: ['./emaileditor-modal.component.scss']
})
export class EmailEditorModalComponent implements OnInit {

  public title = '';
  public asunto = '';
  public template = '';
  public destinatario = '';
  public cc = '';
  public modulo = '';

  verTemplate = false;

  public file: any = '';
  public banner: any = '';

  public activo = true;

  public emailId = 0;
  public edit = false;

  public selectedPackages;

  constructor(
    private emailsService: EmailsService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<EmailEditorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }


  ngOnInit() {

    if (this.data.templateid) {
      //console.log('TemplateId', this.data.templateid);
      this.emailsService.getByID(this.data.templateid).subscribe((data: any) => {

        this.title = data.title;
        this.asunto = data.subject;
        this.template = data.content;
        this.destinatario = data.recipient;
        this.cc = data.cc;

      });
    }

  }

  public recibirTemplate = (template) => {
    this.template = template;
    this.saveEmail();
  };

  public onUpload = (e): void => {
    this.file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => this.banner = reader.result;
  };

  public saveEmail() {

    const data = {
      title: this.asunto,
      subject: this.asunto,
      content: this.template,
      recipient: this.destinatario,
      cc: this.cc,
      module: this.modulo,
      active: this.activo,
      embarqueid: this.data.embarqueid,
      paquetes: this.data.paquetes
    };

    Object.keys(data).forEach(key => {
      if (data[key] == '' || data[key] == null) {
        delete data[key];
      }
    });

    if (this.edit) {
      this.emailsService.put(this.emailId, data).subscribe((data: any) => {
        //console.log(data);
        this.openDialog('Correo Enviado');
        this.dialogRef.close();
      });

    } else {
      this.emailsService.postEmbarque(data).subscribe((data: any) => {
        //console.log(data);
        this.edit = true;
        this.emailId = data.id;
        this.openDialog('Correo Enviado');
        this.dialogRef.close();
      });

    }

  }

  private openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }
}
