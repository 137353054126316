<div class="pt-3 pb-3" style="display: flex; align-items: center; justify-content: space-between;">
  <h2 class="h2-title p-4"></h2>
  <div style="width: 100%;">
    <button mat-button (click)="back()" style="padding: 0; padding-right: 10px; color: #0094a3; float: left;">
      <mat-icon>keyboard_arrow_left</mat-icon> EMBARQUE
    </button>

    <button mat-flat-button color="primary" class="button-calcular mr-5" style="float: right;" (click)="flattenForm()"
      *ngIf="(authService.isAdmin || authService.isOperacionesMiami || authService.isOperacionesCostaRica) ">Guardar
    </button>

  </div>
</div>
<form [formGroup]="form">
  <div class="content-general">
    <div class="content-1 pl-4 pr-4 pb-1 pt-5">
      <div class="content-title">
        <p class="title">Información de Guía Aérea</p>
      </div>

      <div class="inputs-form mt-5">
        <div>
          <label class="label">Guide Number</label><br>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Guide Number" formControlName="OrderNumber">
          </mat-form-field>
        </div>

        <div>
          <label class="label">Flight</label><br>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Flight/Vessel" formControlName="Flight">
          </mat-form-field>
        </div>

        <div>
          <label class="label">Flight Date</label><br>
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="picker" placeholder="Fecha" formControlName="FlightDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div>
        <div>
          <label class="label">Handling Information</label><br>
          <mat-form-field appearance="outline">
            <textarea class="mt-3 mb-2 p-2 w-100" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" placeholder="Handling Information"
              formControlName="HandlingInformation"></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="inputs-form">
        <div>
          <label class="label">Pieces</label><br>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Pieces" formControlName="Pieces">
          </mat-form-field>
        </div>

        <div>
          <label class="label">Gross Weight</label><br>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Gross Weight" formControlName="GrossWeight">
          </mat-form-field>
        </div>

        <div>
          <label class="label">Rate</label><br>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Rate" formControlName="Rate">
          </mat-form-field>
        </div>

        <div>
          <label class="label">Total Charge</label><br>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Total Charge" formControlName="TotalCharge">
          </mat-form-field>
        </div>
      </div>

      <div>
        <div>
          <label class="label">Nature and Quantity of Goods</label><br>
          <mat-form-field appearance="outline">
            <textarea class="mt-3 mb-2 p-2 w-100" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" placeholder="Description"
              formControlName="Description"></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="inputs-form mb-4">
        <div>
          <label class="label">Fuel Surcharge Cost</label><br>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Fuel Surcharge Cost" formControlName="Fuel">
          </mat-form-field>
        </div>

        <div>
          <label class="label">Screen fee</label><br>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Screen fee" formControlName="Screen">
          </mat-form-field>
        </div>

        <div>
          <label class="label">FWB Master</label><br>
          <mat-form-field appearance="outline">
            <input matInput placeholder="FWB Master" formControlName="FWB">
          </mat-form-field>
        </div>

        <div>
          <label class="label">Liba Surcharge</label><br>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Liba Surcharge" formControlName="Liba">
          </mat-form-field>
        </div>

        <div>
          <label class="label">Authorized Agent</label><br>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Authorized Agent" formControlName="AuthorizedAgent">
          </mat-form-field>
        </div>

        <div>
          <label class="label">Total Guide</label><br>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Liba Surcharge" formControlName="TotalPrepaid">
          </mat-form-field>
        </div>
      </div>

    </div>
  </div>
</form>