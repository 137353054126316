<div class="p-3 content" fxLayout="column">
  <div class="content-title">
    <p class="title">Tarifa de Producto - Insertando nuevos datos</p>
  </div>

  <div fxLayout="row wrap" class="pt-3" style="margin-top: 35px;">
    <button mat-button [routerLink]="['/admin/administracion/tarifa-productos']" style="padding: 0; padding-right: 10px; color: #0094a3;">
      <mat-icon>keyboard_arrow_left</mat-icon> TARIFAS DE PRODUCTO
    </button>
    <button mat-button (click)="save()" style="padding: 0; margin-right: 0px; color: #0094a3;">
      GUARDAR <mat-icon>save</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <div [formGroup]="form" fxLayout="column" class="pt-3" style="padding-left: 15%; padding-right: 15%;">

    <mat-form-field appearance="outline">
      <mat-label>Descripción</mat-label>
      <input matInput placeholder="Descripción" formControlName="description">
      <mat-error *ngIf="form.controls['description'].errors">
        {{getErrorMessage(form.controls['description'])}}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Porcentaje</mat-label>
      <input matInput placeholder="Porcentaje" formControlName="value" type="number" min="0" max="100">
      <mat-error *ngIf="form.controls['value'].errors">
        {{getErrorMessage(form.controls['value'])}}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Advertencia</mat-label>
      <input matInput placeholder="Advertencia" formControlName="warning">
      <mat-error *ngIf="form.controls['warning'].errors">
        {{getErrorMessage(form.controls['warning'])}}
      </mat-error>
    </mat-form-field>

    <!-- <mat-form-field appearance="outline">
      <mat-label>Sinónimos</mat-label>
      <input matInput placeholder="Sinónimos" formControlName="syn">
      <mat-error *ngIf="form.controls['syn'].errors">
        {{getErrorMessage(form.controls['syn'])}}
      </mat-error>
    </mat-form-field> -->

</div>
