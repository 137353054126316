<div class="general">
  <h3 mat-dialog-title class="modal-title text-center">{{data.title | translate}}</h3>

  <div class="p-4" style="width: 100%;" *ngIf="data.type == 'general'">
    <mat-form-field style="width: 100%;" appearance="outline" class="form-field">
      <input matInput [(ngModel)]="value">
    </mat-form-field>
  </div>

  <div class="p-4" style="width: 100%;" *ngIf="data.type == 'general-long'">
    <mat-form-field style="width: 100%;" appearance="outline" class="form-field">
      <textarea matInput [(ngModel)]="value" rows="5"></textarea>
    </mat-form-field>
  </div>

  <div class="p-4" style="width: 100%;" *ngIf="data.type == 'embarques'">
    <mat-form-field style="width: 100%;" appearance="outline" class="form-field">
      <mat-select [(ngModel)]="value">
        <mat-option *ngFor="let embarque of embarques" [value]="embarque.id">
          <span *ngIf="!full">Nro: {{embarque.id}} {{'Fecha' | translate}}: {{embarque.refdate}} AWB/BL:
            {{embarque.awb}}</span>

          <span *ngIf="full">Nro: {{embarque.id}} {{'Fecha' | translate}}: {{embarque.refdate}} Peso Real:
            {{embarque.charge_weight}} Lbs. Peso Volumen: {{embarque.gross_weight}} Lbs. Paquetes:
            {{embarque.packages}}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="p-4" style="width: 100%;" *ngIf="data.type == 'entregas'">
    <mat-form-field style="width: 100%;" appearance="outline" class="form-field">
      <mat-select [(ngModel)]="value">
        <mat-option *ngFor="let entrega of entregas" [value]="entrega.id">
          Nro: {{entrega.id}} {{'Fecha' | translate}}: {{entrega.date}} Conductor: {{entrega.conductor}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="p-4" style="width: 100%;" *ngIf="data.type == 'estado'">
    <mat-form-field style="width: 100%;" appearance="outline" class="form-field">
      <mat-select [(ngModel)]="value" placeholder="Seleccione Estado">
        <mat-option *ngFor="let estado of estadosPaquetes" [value]="estado.id">
          {{estado.estado}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="p-4" style="width: 100%;" *ngIf="data.type == 'tarifa'">
    <mat-form-field style="width: 100%;" appearance="outline" class="form-field">
      <mat-select [(ngModel)]="value">
        <mat-option value="A">
          Aérea
        </mat-option>
        <mat-option value="M">
          Marítima
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div mat-dialog-actions style="display: flex; justify-content: space-between;">
    <button mat-flat-button color="primary" class="button1" (click)="onNoClick()">{{'Cancelar' | translate}}</button>
    <button mat-flat-button color="primary" class="button1" (click)="onYesClick()">{{'Aceptar' | translate}}</button>
  </div>

</div>