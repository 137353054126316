import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { PaquetesService } from '../../../../../../services/paquetes.service';
import { ConfirmarComponent } from 'src/app/modules/common/dialogs/confirmar/confirmar.component';
import { MatDialog } from '@angular/material/dialog';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';

export interface PeriodicElement {
  id: number;
  accion: string;
  secuencia: number;
}

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  length: number;
  estados_paquetes: PeriodicElement[];
  displayedColumns: string[] = ['accion', 'secuencia', 'opciones'];
  dataSource = new MatTableDataSource<PeriodicElement>();
  selection = new SelectionModel<PeriodicElement>(true, []);

  constructor(
    public paquetesService: PaquetesService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    length = 0;
    this.estados_paquetes = [];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paquetesService.getEstadosPaquetes().subscribe(
      (res: any) => {
        this.length = res.length;
        res.forEach(element => {
          this.estados_paquetes.push({
            id: element.id,
            accion: element.estado,
            secuencia: element.progreso
          });
        });
        this.dataSource.data = this.estados_paquetes;
      }
    );
  }

  deleteAction(id) {

    //console.log(id);
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      data: { message: 'Esta operación es definitiva y no puede reversarse. ¿Confirma que desea eliminar esta acción?.' },
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.paquetesService.deleteEstadoPaquete(id).subscribe((res: any) => {
          this.openDialog('Acción eliminada');
          this.dataSource.data = this.dataSource.data.filter((value, key) => value.id != id);
        });
      }
    });
  }
  private openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }
}
