import { Component, OnInit } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-doughnut-pie',
  templateUrl: './doughnut-pie.component.html',
  styleUrls: ['./doughnut-pie.component.scss']
})
export class DoughnutPieComponent implements OnInit {

  public pieChartOptions: ChartOptions = {
    responsive: true
  };
  public pieChartLabels: Label[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun'];
  public pieChartData: number[] = [300, 500, 100, 10, 40, 200];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = false;
  public pieChartPlugins = [pluginDataLabels];

  constructor() { }

  ngOnInit() {

  }

}
