/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GeneralDialogComponent } from 'src/app/modules/common/dialogs/general-dialog/general-dialog.component';
import { EmailsService } from 'src/app/services/emails.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { GeneralInputComponent } from 'src/app/modules/common/dialogs/general-input/general-input.component';

export interface ClienteElement {
  id: number;
  name: string;
  email: string;
}

@Component({
  selector: 'app-nuevo',
  templateUrl: './nuevo.component.html',
  styleUrls: ['./nuevo.component.scss']
})
export class NuevoComponent implements OnInit {
  @ViewChild('destinatarioInput') destinatarioInput: ElementRef<HTMLInputElement>;

  public title = '';
  public asunto = '';
  public template = '';
  public cc = '';
  public modulo = '';

  public verTemplate = false;

  public file: any = '';
  public banner: any = '';

  public emailId = 0;
  public edit = false;

  public activo = true;

  tipo = '';
  check = false;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  destinatarioCtrl = new FormControl();
  filteredDestinatarios: Observable<ClienteElement[]>;
  destinatarios: ClienteElement[] = [];
  allDestinatarios: ClienteElement[] = [];

  timeout: any = null;

  constructor(
    private emailsService: EmailsService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private users: UsuariosService,
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.id) {
        this.edit = true;
        this.emailId = params.id;
        this.emailsService.getByID(params.id).subscribe((data: any) => {

          this.title = data.title;
          this.asunto = data.subject;
          this.template = data.content;
          this.modulo = data.module;
          this.destinatarios = data.recipient;
          this.cc = data.cc;
          this.modulo = '';
          this.tipo = data.type;

        });
      }

      if (params.tipo) {
        this.tipo = params.tipo;
        //console.log(this.tipo);
      }
    });

  }

  public recibirTemplate = (template) => {
    this.template = template;
    this.saveEmail();
  };

  public onUpload = (e): void => {
    this.file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => this.banner = reader.result;
  };

  public saveEmail() {

    const data = {
      title: this.title,
      subject: this.asunto,
      content: this.template,
      recipient: this.destinatarios,
      cc: this.cc,
      module: this.modulo,
      active: this.activo,
      type: this.tipo,
      send: this.check
    };

    //console.log('data', data);

    Object.keys(data).forEach(key => {
      if (data[key] === '' || data[key] === null) {
        delete data[key];
      }
    });

    if (this.edit) {
      this.emailsService.put(this.emailId, data).subscribe((data: any) => {
        //console.log(data);
        this.openDialog('Correo Actualizado');
      });

    } else {
      this.emailsService.post(data).subscribe((data: any) => {
        //console.log(data);
        this.edit = true;
        this.emailId = data.id;
        this.openDialog('Correo Creado');
      });

    }
  }

  remove(destinatario: ClienteElement): void {
    const index = this.destinatarios.indexOf(destinatario);

    if (index >= 0) {
      this.destinatarios.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const index = this.destinatarios.indexOf(event.option.value);
    //console.log(index);

    if (index === -1) {
      this.destinatarios.push(event.option.value);
      this.destinatarioInput.nativeElement.value = '';
      this.destinatarioCtrl.setValue('');
    }
  }

  buscarListaClientes(event = { keyCode: 13 }, cuenta = null) {
    this.allDestinatarios = [];
    clearTimeout(this.timeout);
    const $this = this;
    this.timeout = setTimeout(function() {
      if (event.keyCode != 13) {
        $this.users.search(cuenta).subscribe((res: any) => {
          const datos: ClienteElement[] = [];
          datos.push({ id: -1, name: 'Todos los clientes', email: 'TODOS' });

          res.forEach(element => {
            // eslint-disable-next-line max-len
            datos.push({ id: element.id, name: $this.users.get_account_number(element) + '-' + $this.users.get_fullname(element), email: element.email_2 ? element.email_2 : element.email });
          });

          $this.allDestinatarios = datos;

          $this.filteredDestinatarios = $this.destinatarioCtrl.valueChanges.pipe(
            startWith(''),
            map(user => $this._filter(user, $this.allDestinatarios)),
          );
        }
        );
      }
    }, 1000);
  }

  displayFn(value) {
    if (!value) {
      return '';
    }
    return value.name;
  }

  checkSend(event) {
    this.check = event;
  }

  public searchUsersEmbarque() {
    const dialogRef = this.dialog.open(GeneralInputComponent, {
      data: { title: 'Seleccione Embarque', value: 0, type: 'embarques' },
      height: '250px',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        //console.log(result);

        this.allDestinatarios = [];
        this.users.get_clients_embarque(result.value).subscribe(
          (r: any) => {
            const datos: ClienteElement[] = [];

            r.forEach(element => {
              // eslint-disable-next-line max-len
              datos.push({ id: element.id, name: this.users.get_account_number(element) + '-' + this.users.get_fullname(element), email: element.email_2 ? element.email_2 : element.email });
            });

            this.allDestinatarios = datos;
            this.destinatarios = datos;

            this.filteredDestinatarios = this.destinatarioCtrl.valueChanges.pipe(
              startWith(''),
              map(user => this._filter(user, this.allDestinatarios)),
            );
          });
      }
    });
  }

  public searchUsersStatus() {
    const dialogRef = this.dialog.open(GeneralInputComponent, {
      data: { title: 'Seleccione Estado', value: 0, type: 'estado' },
      height: '250px',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        //console.log(result);

        this.allDestinatarios = [];
        this.users.get_clients_status(result.value).subscribe(
          (r: any) => {
            const datos: ClienteElement[] = [];

            r.forEach(element => {
              // eslint-disable-next-line max-len
              datos.push({ id: element.id, name: this.users.get_account_number(element) + '-' + this.users.get_fullname(element), email: element.email_2 ? element.email_2 : element.email });
            });

            this.allDestinatarios = datos;
            this.destinatarios = datos;

            this.filteredDestinatarios = this.destinatarioCtrl.valueChanges.pipe(
              startWith(''),
              map(user => this._filter(user, this.allDestinatarios)),
            );

          });
      }
    });
  }

  private _filter(value: any, options: any) {
    let filterValue: string;
    value.name ? filterValue = value.name.toLowerCase() : filterValue = value.toString().toLowerCase();
    return options.filter(user => user.name.toLowerCase().includes(filterValue));
  }

  private openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });
  }


}
