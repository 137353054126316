export enum StateTypeEnum {
  Prealerta = 1,
  EnBodegaMiamiGuíaPorCompletar = 2,
  DentroDeConsolidado = 3,
  RecibidoEnMiamiGuíaCompletada = 4,
  Consolidado = 5,
  Guía_en_Tramite_Individual_Exoneración = 6,
  EnTransitoHaciaCostaRica = 7,
  VueloAtrasado = 8,
  ProcesoDeAduanas = 9,
  EnRevisionPorParteDeAduanas = 10,
  EnTransitoACentroDeDistribucion = 11,
  RecibidoEnCentroDeDistribucion = 12,
  EnTransitoALimon = 13,
  RecibidoEnSucursalLimon = 14,
  RecibidoEnSucursalGuadalupe = 15,
  PaqueteDevueltoAlCentroDeDistribucion = 16,
  EnTransitoAlCliente = 17,
  Entregado = 18,
  PasadoACuentaCarga = 19,
  PerdidoEnMiami = 20,
  PaqueteEnAbandono = 21,
  PaqueteEnAbandonoMiami = 22,
  DevueltoRMA = 23,
  ClienteNoDesalmacenaConSICSA = 24,
  RetiradoPorClienteEnMiami = 25,
  PaquetePerdido = 26, 
  Eliminado = 90,
}

export const StateTypeDict = {
  1: 'Prealerta',
  2: 'En Bodega Miami Guía Por Completar',
  3: 'Dentro de Consolidado',
  4: 'Recibido en Miami Guía Completada',
  5: 'Consolidado',
  6: 'Guía en Trámite Individual Exoneración',
  7: 'En Tránsito Hacia Costa Rica',
  8: 'Vuelo Atrasado',
  9: 'Proceso de Aduanas',
  10: 'En Revisión Por Parte de Aduanas',
  11: 'En Tránsito a Centro de Distribución',
  12: 'Recibido en Centro de Distribución',
  13: 'En Tránsito a Limón',
  14: 'Recibido en Sucursal Limón',
  15: 'Recibido en Sucursal Guadalupe',
  16: 'Paquete Devuelto al Centro de Distribución',
  17: 'En Tránsito al Cliente',
  18: 'Entregado',
  19: 'Pasado a Cuenta Carga',
  20: 'Perdido en Miami',
  21: 'Paquete en Abandono',
  22: 'Paquete en Abandono Miami',
  23: 'Devuelto RMA',
  24: 'Cliente No Desalmacena con SICSA',
  25: 'Retirado por Cliente en Miami',
  26: 'Paquete Perdido',
  90: 'Eliminado',
};