<div class="content-general">
  <div class="content-1 pl-4 pr-4 pb-4 pt-5">
    <div class="content-title">
      <p class="title">Paquetes</p>
    </div>

    <div class="content-table mt-4">

      <table mat-table [dataSource]="guias" style="width: 100%;">

        <ng-container matColumnDef="seleccionar">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Seleccionar </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element; let i = index">
            <mat-radio-button (click)="close(i)"></mat-radio-button>
          </td>
        </ng-container>

        <!-- guia Column -->
        <ng-container matColumnDef="guia">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Guía </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
            <a [routerLink]="['/admin/paquetes/paquete']" [queryParams]="{pack: element.id}">
              {{element.numero_de_guia ? element.numero_de_guia: '-'}}
            </a>
          </td>
        </ng-container>


        <!-- cuenta Column -->
        <ng-container matColumnDef="cuenta">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Cuenta </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
            <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: element.usuarioid}">
              {{usuariosService.get_fullaccount(element.usuario)}}
            </a>
          </td>
        </ng-container>

        <!-- tracking Column -->
        <ng-container matColumnDef="tracking">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Tracking </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.tracking? element.tracking :
            '-'}} </td>
        </ng-container>

        <!-- descripcion Column -->
        <ng-container matColumnDef="descripcion">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Descripción </th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
            {{element.descripcion? element.descripcion : '-'}}
          </td>
        </ng-container>


        <ng-container matColumnDef="modificado">
          <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Última actualización</th>
          <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
            {{element.updated_at | date: 'fullDate' : '' : 'es-CR'  }} - {{element.estado_actual.estado}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </div>

    <div class="col-12 mt-4">
      <div class="row">
        <div class="col-6 text-center">
          <button mat-flat-button color="primary" class="button-calcular" (click)="close(-1)"><span style="color: white;">Nueva Guía</span></button>
        </div>

        <div class="col-6 text-center">
          <button mat-flat-button color="primary" class="button-calcular" (click)="close(-2)"><span style="color: white;">Cancelar</span></button>
        </div>
      </div>
    </div>
  </div>

</div>

