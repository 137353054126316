<h2 class="h2-title p-4">Productos Calculadora</h2>

<div class="content" fxLayout="column">
  <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones">
    <button class="botones-opciones-movil" mat-button>
      <mat-icon>more_vert</mat-icon>
    </button>
    <div class="botones-opciones">
      <button mat-button routerLink="tarifa">AGREGAR PRODUCTOS CALCULADORA</button>
    </div>

    <div class="content-encontrados">
      <span class="texto-encontrados">{{length}} Productos Calculadora Encontradas</span>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="content-table mr-4 ml-4 mb-4">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="opciones">
        <th mat-header-cell class="pr-pl-20 table-title" *matHeaderCellDef mat-sort-header [disabled]="true">Opciones
        </th>
        <td style="display: flex;height: inherit;" mat-cell class=" text-center" mat-cell *matCellDef="let element">
          <a mat-button [routerLink]="['tarifa']" [queryParams]="{id: element.id}">
            <mat-icon>edit</mat-icon>
          </a>
          <a mat-button>
            <mat-icon (click)="deleteAction(element.id)">delete</mat-icon>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="descripcion">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Descripción </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.descripcion}} </td>
      </ng-container>

      <ng-container matColumnDef="porcentaje">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Porcentaje</th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.porcentaje.toFixed(2)}}% </td>
      </ng-container>

      <ng-container matColumnDef="advertencia">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Advertencia </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.advertencia == null? '-' :
          element.advertencia}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }"></tr>
    </table>
  </div>
</div>
