<h2 class="h2-title p-4">Acciones</h2>

<div class="content" fxLayout="column">
  <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones">
   <!-- <button class="botones-opciones-movil" mat-button>
      <mat-icon>more_vert</mat-icon>
    </button>
    <div class="botones-opciones">
      <button mat-button routerLink="accion">AGREGAR ACCIÓN</button>
    </div>

    <div class="content-encontrados">
      <span class="texto-encontrados">{{length}} Acciones Encontradas</span>
    </div>
  </div>-->
<!---->
<div class="row" style="width: 100%;">
  <div class="col-sm botones-opciones">
   <!-- <button mat-button [routerLink]="['/admin/administracion']"
    style="padding: 0; padding-right: 10px; color: #0094a3; float: left;">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>-->
  </div>
  <div class="col-sm text-right botones-opciones">
    <button routerLink="accion" mat-button>AGREGAR ACCIÓN</button>
    <span class="texto-encontrados">{{length}} Acciones encontrados</span>
  </div>

</div>
  </div>
<!---->
  <mat-divider></mat-divider>

  <div class="content-table mr-4 ml-4 mb-4">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="accion">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Acción </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.accion}} </td>
      </ng-container>

      <ng-container matColumnDef="secuencia">
        <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Secuencia </th>
        <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.secuencia}} </td>
      </ng-container>

      <ng-container matColumnDef="opciones">
        <th mat-header-cell class="pr-pl-20 table-title" *matHeaderCellDef mat-sort-header [disabled]="true"> <i
            class="fas fa-cog" title="Opciones"></i></th>
       <!-- <td mat-cell class="pr-pl-20 text-center" mat-cell *matCellDef="let element">
          <a mat-button [routerLink]="['accion']" [queryParams]="{id: element.id}">
            <i class="fas fa-pen" title="Editar"></i>
          </a>
        </td>-->
        <td mat-cell *matCellDef="let element" class="action-link" style="text-align: center;">
          <mat-icon [routerLink]="['accion']" [queryParams]="{id: element.id}" style="cursor: pointer;">edit</mat-icon>
          <mat-icon (click)="deleteAction(element.id)" style="cursor: pointer;">delete</mat-icon>
        </td>



      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'highlight-top-header': true }"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd; " [ngClass]="{'highlight': odd }"></tr>
    </table>
  </div>
</div>
